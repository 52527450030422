import {
  Component,
  ElementRef,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { passwordValidator } from './auth.types';

@Component({
  selector: 'qa-reset-password-dialog',
  templateUrl: './reset-password-dialog.component.html',
  styleUrls: ['./reset-password-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ResetPasswordDialogComponent {
  errorMessage: string;
  form: UntypedFormGroup;
  @ViewChild('verificationCodeInput')
  verificationCodeInput: ElementRef<HTMLInputElement>;

  constructor(
    private dialogRef: MatDialogRef<ResetPasswordDialogComponent>,
    elementRef: ElementRef<HTMLElement>,
    formBuilder: UntypedFormBuilder
  ) {
    elementRef.nativeElement.classList.add('qa-reset-password-dialog');
    this.dialogRef.updateSize('312px', '480px');
    this.form = formBuilder.group({
      verificationCode: ['', [Validators.required]],
      newPassword: ['', [Validators.required, passwordValidator]],
    });
  }

  submit() {
    this.form.disable();
    const rawValue = this.form.getRawValue();
    this.dialogRef.close(rawValue);
  }
}
