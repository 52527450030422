import { createAction, props } from '@ngrx/store';
import { QuestionModel } from '../api/models';
import * as Quiz from './quiz.types';

export namespace QuizActions {
  export const closeExplanationPanel = createAction(
    '[Quiz] Close Explanation Panel',
    props<{ questionId: number }>()
  );

  export const completeQuestion = createAction(
    '[Quiz] Complete Question',
    props<{ questionId: number }>()
  );

  export const destroy = createAction('[Quiz] Destroy');

  export const init = createAction(
    '[Quiz] Init',
    props<{
      answersDisabled?: boolean;
      book?: boolean;
      isAnswerRetractionDisabled?: boolean;
      questions: QuestionModel[];
    }>()
  );

  export const openExplanationPanel = createAction(
    '[Quiz] Open Explanation Panel',
    props<{ questionId: number }>()
  );

  export const retractAnswer = createAction(
    '[Quiz] Retract Answer',
    props<{ questionId: number }>()
  );

  export const setEndTime = createAction(
    '[Quiz] Set End Time',
    props<{ questionId: number }>()
  );

  export const setStartTime = createAction(
    '[Quiz] Set Start Time',
    props<{ questionId: number }>()
  );

  export const showQuestion = createAction(
    '[Quiz] Show Question',
    props<{ questionId: number; useCountdown?: boolean }>()
  );

  export const startCountdown = createAction(
    '[Quiz] Start Countdown',
    props<{ questionId: number }>()
  );

  export const stopCountdown = createAction(
    '[Quiz] Stop Countdown',
    props<{ questionId: number }>()
  );

  export const storeAnswer = createAction(
    '[Quiz] Store Answer',
    props<{
      isAnsweredCorrectly: boolean;
      questionId: number;
      dontCallApi?: boolean;
    }>()
  );

  export const submitAnswer = createAction(
    '[Quiz] Submit Answer',
    props<{ questionId: number }>()
  );

  export const updateAnswerSelection = createAction(
    '[Quiz] Update Answer Selection',
    props<{ questionId: number; selection: Quiz.AnswerSelection }>()
  );

  export const updateQuestion = createAction(
    '[Quiz] Update Question',
    props<{ question: Quiz.Question }>()
  );
}
