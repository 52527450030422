import { Component, Inject } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PanelEntityType } from 'src/app/cms/panel/panel.types';
import { copyTextToClipboard } from 'src/app/utility/app.utils';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';

export interface ChatgptImportDialogComponentData {
  type:
    | PanelEntityType.Quiz
    | PanelEntityType.Event
    | PanelEntityType.FlashCardStack
    | PanelEntityType.Exam;
  name: string;
  onImport: () => void;
}

export type ChatGPTPanelType =
  | PanelEntityType.Quiz
  | PanelEntityType.Exam
  | PanelEntityType.Event
  | PanelEntityType.FlashCardStack;

enum Difficulty {
  EASY,
  MEDIUM,
  HARD,
}

@Component({
  selector: 'qa-chatgpt-import-dialog',
  templateUrl: './chatgpt-import-dialog.component.html',
  styleUrls: ['./chatgpt-import-dialog.component.scss'],
})
export class ChatgptImportDialogComponent {
  form = new FormGroup({
    prompt: new FormControl(),
    count: new FormControl(10),
    toggleExplanation: new FormControl(true),
    difficulty: new FormControl(Difficulty.MEDIUM),
    topic: new FormControl(this.translator.instant('chatgptImports.topic')),
  });

  difficultyOptions = [Difficulty.EASY, Difficulty.MEDIUM, Difficulty.HARD];

  constructor(
    public dialogRef: MatDialogRef<ChatgptImportDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ChatgptImportDialogComponentData,
    private matSnackBar: MatSnackBar,
    private translator: TranslateService
  ) {
    this.form.get('prompt').setValue(this.buildPrompt());

    this.form.get('count').valueChanges.subscribe(() => {
      this.form.get('prompt').setValue(this.buildPrompt());
    });

    this.form.get('topic').valueChanges.subscribe(() => {
      this.form.get('prompt').setValue(this.buildPrompt());
    });
  }

  private buildPrompt(): string {
    if (this.data.type === PanelEntityType.FlashCardStack) {
      return this.translator
        .instant('chatgptImports.flashPromptTemplate')
        .replace(/%topic/gm, this.name)
        .replace(
          /%difficulty/gm,
          this.translator.instant(
            this.getDifficulty(this.form.get('difficulty').value as Difficulty)
          )
        )
        .replace(/%count/gm, String(this.form.get('count').value));
    } else {
      return this.translator
        .instant('chatgptImports.questionPromptTemplate')
        .replace(/%topic/gm, this.name)
        .replace(
          /%difficulty/gm,
          this.translator.instant(
            this.getDifficulty(this.form.get('difficulty').value as Difficulty)
          )
        )
        .replace(/%count/gm, String(this.form.get('count').value));
    }
  }

  getDifficulty(diff: Difficulty) {
    switch (diff) {
      case Difficulty.EASY:
        return 'chatgptImports.difficultyOptions.simple';
      case Difficulty.MEDIUM:
        return 'chatgptImports.difficultyOptions.mediumDifficulty';
      case Difficulty.HARD:
        return 'chatgptImports.difficultyOptions.complex';
    }
  }

  get name() {
    return this.data.name ?? this.form.get(`topic`).value;
  }

  updateDifficulty(event) {
    this.form.get('difficulty').setValue(event);
    this.form.get('prompt').setValue(this.buildPrompt());
  }

  goToImport() {
    this.data.onImport();
  }

  openChatGpt() {
    window.open(`https://chat.openai.com`, '_blank');
  }

  copyText() {
    try {
      copyTextToClipboard(this.form.get('prompt').value);
      this.matSnackBar.open(
        this.translator.instant('recommending.copiedToClipboard')
      );
    } catch (error) {
      this.matSnackBar.open(
        this.translator.instant('recommending.notCopiedToClipboard')
      );
    }
  }
}
