<div class="dialog-header">
  <h1 mat-dialog-title>
    {{ 'comment.title' | translate }}
  </h1>
  <div class="closeBtn" mat-dialog-close>
    <mat-icon>close</mat-icon>
  </div>
</div>
<mat-dialog-content>
  <qa-grid-data [config]="config" *ngIf="config"></qa-grid-data>

  <!-- <div class="comments">
    <div class="comments-headers">
      <div class="header-item">Comment</div>
      <div class="header-item">Date</div>
      <div class="header-item"></div>
    </div>
    <div class="comments-body">
      <div class="body-item" *ngFor="let comment of comments">
        <div class="comment">
          {{ comment.comment }}
        </div>
        <div class="date">
          {{ comment.created_at | date : 'short' }}
        </div>
        <div class="action">
          <mat-icon>chat</mat-icon>
        </div>
      </div>
    </div>
  </div> -->
</mat-dialog-content>
