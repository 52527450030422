import { Overlay } from '@angular/cdk/overlay';
import { ComponentPortal, PortalInjector } from '@angular/cdk/portal';
import { Injectable, Injector } from '@angular/core';
import { Store } from '@ngrx/store';
import { first } from 'rxjs/operators';
import { AppState } from 'src/app/app.types';
import { AuthService } from 'src/app/auth/auth.service';
import { environment } from 'src/environments/environment';
import {
  FEEDBACK_PORTAL_DATA,
  QuickFeedbackOverlayComponent,
  QuickFeedbackQuestion,
} from './quick-feedback-overlay.component';
import { UserConfigService } from '../../user-config.service';

@Injectable()
export class QuickFeedbackOverlayService {
  alreadyOpen = false;

  overlayRef = null;

  constructor(
    private overlay: Overlay,
    private auth: AuthService,
    private store: Store,
    private injector: Injector,
    private userConfig: UserConfigService
  ) {}

  closeCurrentOverview() {
    window.setTimeout(() => {
      this.overlayRef.dispose();
      this.alreadyOpen = false;
    }, 1000);
  }

  async open(questions: QuickFeedbackQuestion) {
    const skip = await this.userConfig.get('settings.help.disableFeedback');
    if (skip || this.alreadyOpen) {
      return;
    }
    this.alreadyOpen = true;
    this.overlayRef = this.overlay.create();
    const tokens = new WeakMap<any, any>([[FEEDBACK_PORTAL_DATA, questions]]);
    const injector = new PortalInjector(this.injector, tokens);
    const filePreviewPortal = new ComponentPortal(
      QuickFeedbackOverlayComponent,
      null,
      injector
    );

    this.overlayRef.attach(filePreviewPortal);
  }

  close() {
    if (this.alreadyOpen) {
      this.alreadyOpen = false;
      this.overlayRef.dispose();
    }
  }

  submitFeedback(
    works: { works: boolean; message: string; id: string; process?: string },
    mode: 'live-event' | 'exam'
  ) {
    // Submit answer to remote tracking service
    this.store.pipe(first()).subscribe((state: AppState) => {
      const fbData = {
        email:
          this.auth.currentUserInfo$ && this.auth.currentUserInfo$.value
            ? this.auth.currentUserInfo$.value.email
            : '',
        works: works.works,
        edition: environment.edition,
        feedback: works.message,
      };
      switch (mode) {
        case 'live-event':
          fbData['quizid'] = state?.cmsLiveEvent?.liveEvent?.id || 'unknown';
          fbData['users'] =
            state.cmsLiveEvent?.liveEvent?.users?.length || 'unknown';
          break;
        case 'exam':
          fbData['quizid'] = state?.exam?.exam?.id || 'unknown';
          fbData['users'] = state?.exam?.exam?.users || 'unknown';
          break;
        default:
          return;
      }
      const formData = new FormData();
      formData.append('action', 'track_user');
      formData.append('id', works.id);
      formData.append('data', JSON.stringify(fbData));
      fetch(environment.wordpress, {
        method: 'post',
        body: formData,
      });
    });
  }

  usedRecommend() {
    const formData = new FormData();
    formData.append('action', 'track_user');
    formData.append(
      'id',
      '2d365ac6597daacec739cd1bfaeebdd33681e853e44f0e46246a39b075b038bd'
    );
    formData.append(
      'data',
      JSON.stringify({
        email:
          this.auth.currentUserInfo$ && this.auth.currentUserInfo$.value
            ? this.auth.currentUserInfo$.value.email
            : '',
        edition: environment.edition,
      })
    );
    fetch(environment.wordpress, {
      method: 'post',
      body: formData,
    });
  }

  downloadedCertificate() {
    const formData = new FormData();
    formData.append('action', 'track_user');
    formData.append(
      'id',
      'a6151a73623c6706586772e4fcac4cbe4f4b833a036899564dfa57db9105a624'
    );
    formData.append(
      'data',
      JSON.stringify({
        email:
          this.auth.currentUserInfo$ && this.auth.currentUserInfo$.value
            ? this.auth.currentUserInfo$.value.email
            : '',
        edition: environment.edition,
      })
    );
    fetch(environment.wordpress, {
      method: 'post',
      body: formData,
    });
  }

  interestInMarket(type: 'exam' | 'event', interested: boolean) {
    const formData = new FormData();
    formData.append('action', 'track_user');
    formData.append(
      'id',
      type === 'exam'
        ? '94731312d1ba1f225bb354011ffef3d23094c8738a2475f23a7182d7957cf942'
        : '47f29f19da77d461135bf9373fbde6da62cc1293aa45de9a5283638147aa4ae3'
    );
    formData.append(
      'data',
      JSON.stringify({
        interested,
        email:
          this.auth.currentUserInfo$ && this.auth.currentUserInfo$.value
            ? this.auth.currentUserInfo$.value.email
            : '',
      })
    );
    fetch(environment.wordpress, {
      method: 'post',
      body: formData,
    });
  }

  requestAccountDelete(reason: 'string') {
    const formData = new FormData();
    formData.append('action', 'track_user');
    formData.append(
      'id',
      'affc301e756fdb9903342aa02034bdcdd3a524bfffa5943fccf484761c2946f3'
    );
    formData.append(
      'data',
      JSON.stringify({
        reason,
        email:
          this.auth.currentUserInfo$ && this.auth.currentUserInfo$.value
            ? this.auth.currentUserInfo$.value.email
            : '',
      })
    );
    fetch(environment.wordpress, {
      method: 'post',
      body: formData,
    });
  }
}
