import { Injectable } from '@angular/core';
import { CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { EMPTY, Observable } from 'rxjs';
import { catchError, concatMap, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { UsersService } from '../api/services';
import { AuthService } from './auth.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class CanActivateCms implements CanActivate {
  constructor(
    public users: UsersService,
    private authService: AuthService,
    private matSnackBar: MatSnackBar,
    private translator: TranslateService,
    private router: Router
  ) {}

  canActivate(_, state: RouterStateSnapshot): Observable<boolean> | boolean {
    if (!this.authService.isLoggedIn()) {
      sessionStorage.setItem(`cms-after-login-target`, state.url);
      this.router.navigate(['/login']);
      return false;
    } else {
      const pathUrl = window.location?.pathname ?? '/login';
      return this.authService.currentUserInfo$.pipe(
        concatMap((resP) =>
          this.users.getEditionsForUser({ id: resP.sub }).pipe(
            map((res) => {
              const editions = res;
              const editionsName = res.map((x) => x.name.toLowerCase());
              if (
                editionsName.includes(
                  environment.production
                    ? environment.edition.replace('go', 'business')
                    : 'development'
                )
              ) {
                return true;
              } else {
                if (editionsName.length === 0) {
                  this.matSnackBar.open(
                    this.translator.instant('login.User is disabled.')
                  );
                  sessionStorage.setItem(`cms-after-login-target`, state.url);
                  this.authService.logout();
                } else if (editionsName.length === 1) {
                  window.location.href =
                    editions[0].base_url_ui.replace('business', 'go') +
                    '/' +
                    pathUrl;
                } else {
                  this.authService.selectEdition(editions, pathUrl);
                }
                return false;
              }
            }),
            catchError((error: HttpErrorResponse) => {
              const errorMessage = 'Request contains no cognito user role.';
              if (
                error.status === HttpStatusCode.Forbidden &&
                error.error.message.includes(errorMessage)
              ) {
                this.matSnackBar.open(this.translator.instant('login.noRole'));
                this.router.navigate(['/login'], {
                  queryParams: { noRole: true },
                });
              }
              return EMPTY;
            })
          )
        )
      );
    }
  }
}
