import { Component } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { filter, map, switchMap } from 'rxjs/operators';
import {
  Exam,
  QuestionModel,
  QuestionQuery,
  TagQuery,
  TagQueryFilters,
} from 'src/app/api/models';
import {
  ExamsService,
  LiveEventsService,
  QuestionsService,
  QuizzesService,
  TagsService,
} from 'src/app/api/services';
import { questionTypeDisplayFn } from 'src/app/app.types';
import { DialogService } from 'src/app/cms/common/dialog.service';
import {
  ListActionMeta,
  ListConfig,
  ListQuery,
  MatTabGroupConfig,
} from './list.types';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import * as dayjs from 'dayjs';
import { QuizModel } from 'src/app/api/models/quiz-model';
import { NavigationBreadcrumb } from '../common/navigation-breadcrumbs/navigation-breadcrumbs.component';
import { AuthService } from 'src/app/auth/auth.service';
import { convertQuizToEvent } from '../common/convertQuizToEvent';
import * as _ from 'lodash';
import { PanelService } from '../panel/panel.service';
import { getQuestionImports } from '../panel/panels/question-panel';
import { QuestionType } from 'src/app/app-constants';
import { cloneDeep } from 'lodash';
import { PanelEntityType } from '../panel/panel.types';
import * as fileSaver from 'file-saver';
import { multiSelectAction } from './grid-data/grid-data.component';

@Component({
  selector: 'qa-question-list',
  templateUrl: './question-list.component.html',
  styleUrls: ['./question-list.component.scss'],
})
export class QuestionListComponent {
  config: ListConfig<QuestionModel>;
  questionTypeDisplayFn = questionTypeDisplayFn;

  quiz: QuizModel = null;
  exam: Exam = null;
  navigationBreadcrumb: NavigationBreadcrumb;

  matTabConfig: MatTabGroupConfig;
  selectedTabIndex = 0;

  constructor(
    private dialog: DialogService,
    private router: Router,
    private matSnackBar: MatSnackBar,
    private questions: QuestionsService,
    private translator: TranslateService,
    private matDialog: MatDialog,
    private tagsService: TagsService,
    private activeRoute: ActivatedRoute,
    private quizzesService: QuizzesService,
    private panelService: PanelService,
    private examsService: ExamsService,
    private eventsService: LiveEventsService,
    private auth: AuthService
  ) {
    activeRoute.queryParams.subscribe((params) => {
      if (params.showPollQuestion) {
        this.selectedTabIndex = 1;
        this.initTable();
      } else {
        this.initTable();
      }
    });
  }

  async initTable() {
    if (this.activeRoute.snapshot.params.quizId) {
      this.quiz = await this.quizzesService
        .getQuizById({ id: this.activeRoute.snapshot.params.quizId })
        .toPromise();
      this.navigationBreadcrumb = {
        homeUrl: '/cms/quizzes',
        back: {
          handleBack: () => {
            this.router.navigateByUrl('/cms/quizzes');
          },
        },
        currentPage: this.translator.instant('question.title'),
        breadcrumbs: [
          {
            text: 'Quiz',
            route: '/cms/quizzes',
          },
        ],
      };
    }
    if (
      this.activeRoute.snapshot.params.examId &&
      !isNaN(this.activeRoute.snapshot.params.examId)
    ) {
      this.exam = await this.examsService
        .getExamById({ id: this.activeRoute.snapshot.params.examId })
        .toPromise();
      this.navigationBreadcrumb = {
        homeUrl: '/cms/exams',
        back: {
          handleBack: () => {
            this.router.navigateByUrl('/cms/exams');
          },
        },
        currentPage: this.translator.instant('question.title'),
        breadcrumbs: [
          {
            text: this.translator.instant('menu.exams'),
            route: '/cms/exams',
          },
        ],
      };
    }
    const config = getQuestionListConfig(
      this.questions,
      this.dialog,
      this.matSnackBar,
      this.router,
      this.translator,
      this.matDialog,
      (this.quiz &&
        this.quiz.created_by !== this.auth?.currentUserInfo$.value.sub) ||
        (this.exam &&
          this.exam.created_by !== this.auth?.currentUserInfo$.value.sub)
        ? false
        : true,
      this.tagsService,
      this.quiz,
      this.quizzesService,
      this.eventsService,
      this.auth,
      this.exam,
      this.examsService
    );

    if (this.quiz) {
      config.title = `${this.translator.instant(
        'question.title'
      )}${` in "${this.quiz.name}"`}`;
      this.config = config;
    } else if (this.exam) {
      config.title = `${this.translator.instant(
        'question.title'
      )}${` in "${this.exam.name}"`}`;
      this.config = config;
    } else {
      this.setImports(config, false);
      config.title = `${this.translator.instant('question.title')}`;
      this.config = config;
    }
  }

  setImports(config, isPoll = false) {
    let imports = getQuestionImports(
      this.translator,
      this.panelService,
      null,
      this.matDialog,
      false,
      this.auth.currentUserHasGroup('Admin') ||
        this.auth.currentUserHasGroup('ContentManager')
    );
    imports = imports.filter((q) => q.id !== 'chat-gpt');
    imports = imports.filter((q) => q.id !== 'import-json');
    const idxJson = imports.findIndex((imp) => imp.id === 'import-json');
    if (idxJson !== -1) {
      imports[idxJson].action.click = async (dataSource) => {
        const item = (await this.panelService.promptFile(
          'json'
        )) as QuestionModel;
        this.matDialog.closeAll();
        if (item.type === QuestionType.TrueFalse) {
          this.matSnackBar.open(
            this.translator.instant('question.message.pollQuestionImportError')
          );
          return;
        }
        item.is_poll_question = !!isPoll;
        dataSource.loading = true;
        this.questions
          .createQuestion({
            body: item,
          })
          .subscribe(
            () => {
              dataSource.refresh();
            },
            () => (dataSource.loading = false)
          );
      };
    }
    const idxCsv = imports.findIndex((imp) => imp.id === 'import-as-csv');
    if (idxCsv !== -1) {
      imports[idxCsv].action.click = () => {
        this.matDialog.closeAll();
        const queryParams = {
          type: 'question',
          isPoll,
        };
        if (!isPoll) {
          delete queryParams.isPoll;
        }
        this.router.navigate([`/cms/csv-imports/question`], {
          queryParams,
        });
      };
    }
    config.imports = imports;
  }
}

export function getQuestionListConfig(
  questions: QuestionsService,
  dialog: DialogService,
  matSnackBar: MatSnackBar,
  router: Router,
  translator: TranslateService,
  matDialog: MatDialog,
  showMultiselect: boolean,
  tagsService: TagsService,
  quiz?: QuizModel,
  quizzesService?: QuizzesService,
  eventsService?: LiveEventsService,
  auth?: AuthService,
  exam?: Exam,
  examsService?: ExamsService
): ListConfig<any> {
  function onEdit(meta: ListActionMeta<QuestionModel>) {
    if (meta.row) {
      router.navigateByUrl(
        `/cms/edit/question/${meta.row.id}${exam ? `?exam=${exam.id}` : ''}${
          quiz ? `?quiz=${quiz.id}` : ''
        }${!quiz && !exam ? `?contentQuestionId=${meta.row.id}` : ''}`
      );
    } else {
      router.navigate(['/cms/add/question']);
    }
  }
  async function exportAsJson(meta: ListActionMeta<QuestionModel>) {
    meta.dataSource.loading = true;
    let fullItem: QuestionModel;
    fullItem = cloneDeep(meta.row);
    fullItem = await questions
      .getQuestionById({
        id: fullItem.id,
        embed_answers: true,
        embed_tags: true,
      })
      .toPromise();
    delete fullItem.id;
    fullItem['meta:importType'] = PanelEntityType.Question;
    const file = `${JSON.stringify(fullItem)}\n`;
    const blob = new Blob([file], { type: 'application/json' });
    fileSaver.saveAs(
      blob,
      `qa-${PanelEntityType.Question}-${fullItem.text}.json`
    );
    meta.dataSource.loading = false;
  }
  const ret: ListConfig<any> = {
    actions: [
      {
        click: ({ row, dataSource }) => {
          dialog
            .confirm({
              title: translator.instant('question.tooltip.delete'),
              text: translator.instant('liveEvent.message.deleteMessage'),
              buttons: [
                {
                  color: 'primary',
                  text: translator.instant('common.button.cancel'),
                },
                {
                  color: 'warn',
                  text: translator.instant('common.button.clear'),
                  value: true,
                },
              ],
            })
            .pipe(
              filter((isConfirmed) => isConfirmed),
              switchMap(() => questions.deleteQuestion({ id: row.id }))
            )
            .subscribe({
              next: () => {
                matSnackBar.open(
                  translator.instant('content.question.deleteItem.success')
                );
                dataSource.refresh();
              },
              error: () => {
                matSnackBar.open(
                  translator.instant('content.question.deleteItem.error')
                );
              },
            });
        },
        icon: 'delete',
        name: 'delete',
        isDisabled: (meta) =>
          auth && meta.row.created_by !== auth?.currentUserInfo$.value.sub,
        tooltip: 'question.tooltip.delete',
        type: 'button',
      },
      {
        click: ({ row, dataSource }) => {
          dialog
            .confirm({
              title: translator.instant(
                exam
                  ? 'content.examQuestion.unlinkItem.title'
                  : 'liveEvent.label.removeQuestion'
              ),
              text: translator.instant(
                exam
                  ? 'content.examQuestion.unlinkItem.text'
                  : 'liveEvent.message.removeQuestionText'
              ),
              buttons: [
                {
                  color: 'primary',
                  text: translator.instant('common.button.cancel'),
                },
                {
                  color: 'warn',
                  text: translator.instant(
                    exam
                      ? 'content.examQuestion.selectedItem.label'
                      : 'liveEvent.label.removeQuestion'
                  ),
                  value: true,
                },
              ],
            })
            .pipe(
              filter((isConfirmed) => isConfirmed),
              switchMap(() => {
                const questionIds = [row.id];
                if (exam) {
                  return examsService.removeQuestionFromExam({
                    id: exam.id,
                    question_ids: questionIds.join(','),
                  });
                } else {
                  return quizzesService.removeQuestionFromQuiz({
                    id: quiz.id,
                    question_ids: questionIds.join(','),
                  });
                }
              })
            )
            .subscribe({
              next: () => {
                matSnackBar.open(
                  translator.instant('content.question.deleteItem.success')
                );
                dataSource.refresh();
              },
              error: () => {
                matSnackBar.open(
                  translator.instant('content.question.deleteItem.error')
                );
              },
            });
        },
        icon: 'link_off',
        name: 'unlink',
        isDisabled: (meta) =>
          auth && meta.row.created_by !== auth?.currentUserInfo$.value.sub,
        tooltip: exam
          ? 'content.examQuestion.actionMenu.item3'
          : 'liveEvent.label.removeQuestion',
        type: 'button',
        hide: () =>
          window.location.href.indexOf('cms/content/questions') !== -1
            ? true
            : false,
      },
    ],
    actionsMenu: [
      {
        icon: 'save_alt',
        name: 'download',
        label: translator.instant('content.question.actionMenu.item2'),
        type: 'button',
        click: (meta) => exportAsJson(meta),
      },
    ],
    activeActionNames: ['edit', 'delete', 'unlink'],
    activeColumnNames: ['id', 'text', 'type', 'has_weblink', 'has_explanation'],
    columns: [
      {
        label: 'ID',
        name: 'id',
        sortable: !(quizzesService && quiz) && !(examsService && exam),
        width: '64px',
        click: onEdit,
        filter: (value) => {
          return {
            id: Number(value),
          };
        },
      },
      {
        click: onEdit,
        label: 'Text',
        name: 'text',
        sortable: !(quizzesService && quiz) && !(examsService && exam),
        primary: true,
        icon: (meta) => {
          if (auth && meta.row.created_by !== auth.currentUserInfo$.value.sub) {
            return 'shopping_cart';
          } else {
            return null;
          }
        },
        filter: (value) => {
          return {
            text: value,
          };
        },
      },
      {
        accessor: (question) => {
          const prefix = question.is_poll_question
            ? translator.instant('question.label.surveyQuestionShort')
            : translator.instant('question.label.quizQuestionShort');
          return (
            prefix + ' (' + questionTypeDisplayFn(question.type, true) + ')'
          );
        },
        label: 'question.table.type',
        name: 'type',
        sortable: false,
        click: onEdit,
        tooltip: ({ row }) => questionTypeDisplayFn(row.type) ?? '?',
        width: '120px',
        filter:
          !quiz && !exam
            ? (value) => {
                return {
                  type: value,
                };
              }
            : null,
        filterOptionsByGroup: [
          {
            name: 'common.label.question',
            id: 'question_type',
            group: [
              {
                name: 'Multiple Choice',
                value: 0,
                slug: `${translator.instant(
                  'question.label.quizQuestionShort'
                )}(${questionTypeDisplayFn(0, true)})`,
              },
              {
                name: 'True / False',
                value: 1,
                slug: `${translator.instant(
                  'question.label.quizQuestionShort'
                )}(${questionTypeDisplayFn(1, true)})`,
              },
              {
                name: 'Single Choice',
                value: 2,
                slug: `${translator.instant(
                  'question.label.quizQuestionShort'
                )}(${questionTypeDisplayFn(2, true)})`,
              },
              {
                name: 'Free Text',
                value: 3,
                slug: `${translator.instant(
                  'question.label.quizQuestionShort'
                )}(${questionTypeDisplayFn(3, true)})`,
              },
            ],
          },
          {
            name: 'common.label.survey',
            id: 'poll_type',
            group: [
              {
                name: 'Multiple Choice',
                value: 0,
                slug: `${translator.instant(
                  'question.label.surveyQuestionShort'
                )}(${questionTypeDisplayFn(0, true)})`,
              },
              {
                name: 'Single Choice',
                value: 2,
                slug: `${translator.instant(
                  'question.label.surveyQuestionShort'
                )}(${questionTypeDisplayFn(2, true)})`,
              },
              {
                name: 'Free Text',
                value: 3,
                slug: `${translator.instant(
                  'question.label.surveyQuestionShort'
                )}(${questionTypeDisplayFn(3, true)})`,
              },
            ],
          },
        ],
        filtersCanStack: true,
      },
      {
        accessor: (question) => (question.has_weblink ? 'Ja' : 'Nein'),
        label: 'Weblink',
        name: 'has_weblink',
        sortable: !(quizzesService && quiz) && !(examsService && exam),
        width: '72px',
        click: onEdit,
        hideOnMobile: true,
        filter:
          !quiz && !exam
            ? (value) => {
                return {
                  has_weblink: value,
                };
              }
            : null,
        filterOptions: [
          {
            name: 'common.label.yes',
            value: true,
          },
          {
            name: 'common.label.no',
            value: false,
          },
        ],
      },
      {
        accessor: (question) => (question.has_explanation ? 'Ja' : 'Nein'),
        label: 'question.table.explanation',
        name: 'has_explanation',
        click: onEdit,
        sortable: !(quizzesService && quiz) && !(examsService && exam),
        width: '88px',
        hideOnMobile: true,
        filter:
          !quiz && !exam
            ? (value) => {
                return {
                  has_explanation: value,
                };
              }
            : null,
        filterOptions: [
          {
            name: 'common.label.yes',
            value: true,
          },
          {
            name: 'common.label.no',
            value: false,
          },
        ],
      },
      {
        label: 'Tag',
        name: 'tags',
        click: onEdit,
        alwaysHide: true,
        filter:
          !quiz && !exam
            ? (value) => {
                return {
                  tags: value.map((v) => ({ name: v.name })),
                };
              }
            : null,
        filterOptionsMultiSelect: ({ name, page }) => {
          const filters = {
            show_only_user_tags: true,
          } as Partial<TagQueryFilters> as TagQueryFilters;
          if (name) {
            filters.name = name;
          }
          return tagsService.tagControllerGetStats({
            body: {
              filters,
              paging: {
                page,
                page_size: 50,
              },
              sorting: [],
            } as Partial<TagQuery> as TagQuery,
          });
        },
        filterValue: (value) => {
          return value ? value.map((v) => v.name) : null;
        },
      },
      {
        label: 'common.label.lastChanged',
        name: 'updated_at',
        sortable: false,
        width: '160px',
        click: onEdit,
        accessor: (row) => {
          return dayjs(row.updated_at).format('DD.MM.YYYY HH:mm');
        },
      },
      {
        label: 'importItemDialog.filter.picture',
        name: 'has_image',
        click: onEdit,
        alwaysHide: true,
        filter:
          !quiz && !exam
            ? (value) => ({
                has_image: value,
              })
            : null,
        filterOptions: [
          {
            name: 'common.label.yes',
            value: true,
          },
          {
            name: 'common.label.no',
            value: false,
          },
        ],
      },
    ],
    fetchRowItemById: (id: number) => {
      return questions.getQuestionById({ id });
    },
    fetch: (queryParams?: ListQuery<QuestionModel>) => {
      if (quizzesService && quiz) {
        return quizzesService.getQuestionsForQuiz({ id: quiz.id }).pipe(
          map((response) => {
            const filteredResponse = response.filter((question) => {
              if (
                queryParams.filters?.text &&
                !question.text.includes(queryParams.filters.text)
              ) {
                return false;
              }
              if (
                queryParams.filters?.id &&
                question.id !== queryParams.filters.id
              ) {
                return false;
              }
              return true;
            });
            return {
              data: filteredResponse,
              pagination: {
                has_next_page: false,
                has_previous_page: false,
                page: 0,
                page_size: filteredResponse.length,
                total: filteredResponse.length,
              },
              sorting: queryParams.sorting,
            };
          })
        );
      } else if (examsService && exam) {
        return examsService.getQuestionsForExam({ id: exam.id }).pipe(
          map((response) => {
            const filteredResponse = response.filter((question) => {
              if (
                queryParams.filters?.text &&
                !question.text.includes(queryParams.filters.text)
              ) {
                return false;
              }
              if (
                queryParams.filters?.id &&
                question.id !== queryParams.filters.id
              ) {
                return false;
              }
              return true;
            });
            return {
              data: filteredResponse,
              pagination: {
                has_next_page: false,
                has_previous_page: false,
                page: 0,
                page_size: filteredResponse.length,
                total: filteredResponse.length,
              },
              sorting: queryParams.sorting,
            };
          })
        );
      } else {
        const type = queryParams.filters.type;
        if (type) {
          delete queryParams.filters.type;
          queryParams.filters = Object.assign(queryParams.filters, type);
        }
        return questions
          .queryQuestions({
            body: {
              ...{ embedAnswers: true },
              ...(queryParams as unknown as QuestionQuery),
            },
          })
          .pipe(
            map((response) => {
              localStorage.setItem(
                `content-question-list`,
                JSON.stringify(response)
              );
              return response;
            })
          );
      }
    },
    query: {
      initial: {
        sorting: [
          {
            option: 'id',
            direction: 'DESC',
          },
        ],
      },
      searchColumnName: 'text',
    },
    name: 'questionList',
    showNumberOfItems: !!quiz || !!exam,
    title: ``,
  };

  if (quiz && quizzesService) {
    ret.hidePaginator = true;
    ret.onPositionDrag = (el: QuestionModel, position: number) => {
      return quizzesService.repositionQuestionInQuiz({
        id: quiz?.id,
        question_id: el?.id,
        body: {
          position,
        },
      });
    };
  }

  if (exam && examsService) {
    ret.hidePaginator = true;
    ret.onPositionDrag = (el: QuestionModel, position: number) => {
      return examsService.repositionQuestionInExam({
        id: exam?.id,
        question_id: el?.id,
        body: {
          position,
        },
      });
    };
  }

  if (quiz && quiz.created_by === auth.currentUserInfo$.value.sub) {
    ret.createButton = {
      options: [
        {
          icon: 'add',
          click: () =>
            router.navigateByUrl(`/cms/add/question?quiz=${quiz.id}`),
          text: translator.instant('content.question.addMenu.item1'),
        },
        {
          icon: 'move_to_inbox',
          click: () =>
            router.navigateByUrl(
              `/cms/import-items?parentId=${quiz.id}&type=question`
            ),
          text: translator.instant('content.question.addMenu.item2'),
        },
        {
          icon: 'description',
          click: () =>
            router.navigateByUrl(
              `/cms/csv-imports/question/${quiz.id}?type=question`
            ),
          text: translator.instant('content.question.addMenu.item4'),
        },
      ],
    };
    if (quiz.num_questions > 0 && eventsService) {
      ret.parentActions = [
        {
          icon: 'play_arrow',
          text: translator.instant('liveEvent.tooltip.startLiveEvent'),
          click: () => {
            dialog
              .confirm({
                title: translator.instant('liveEvents.newInfo.title'),
                text: translator.instant('liveEvents.newInfo.dialog'),
                buttons: [
                  {
                    text: translator.instant('common.button.abort'),
                    icon: 'close',
                  },
                  {
                    text: translator.instant(
                      'liveEvents.newInfo.convertToEvent'
                    ),
                    icon: 'add',
                    color: 'primary',
                    value: true,
                  },
                ],
              })
              .pipe(
                filter((convertToEvent) => !!convertToEvent),
                switchMap(() =>
                  convertQuizToEvent(quiz, quizzesService, eventsService)
                )
              )
              .subscribe(({ event }) => {
                router.navigate(['/cms/live-events', event.id]);
              });
          },
        },
      ];
    }
  }

  if (exam && exam.created_by === auth.currentUserInfo$.value.sub) {
    ret.createButton = {
      options: [
        {
          icon: 'add',
          click: () =>
            router.navigateByUrl(`/cms/add/exam-question?exam=${exam.id}`),
          text: translator.instant('content.question.addMenu.item1'),
        },
        {
          icon: 'move_to_inbox',
          click: () =>
            router.navigateByUrl(
              `/cms/import-items?parentId=${exam.id}&type=exam-question`
            ),
          text: translator.instant('content.question.addMenu.item2'),
        },
        {
          icon: 'description',
          click: () =>
            router.navigateByUrl(
              `/cms/csv-imports/question/${exam.id}?type=exam-question`
            ),
          text: translator.instant('content.question.addMenu.item4'),
        },
      ],
    };
    if (exam.num_questions > 0 && examsService) {
      ret.parentActions = [
        {
          icon: 'play_arrow',
          text: translator.instant('content.exam.actionMenu.item1'),
          click: () => {
            router.navigate(['/cms/exams/', exam.id]);
          },
        },
      ];
    }
  }

  if (!quiz && !exam) {
    ret.createButton = {
      options: [
        {
          text: translator.instant('content.question.addMenu.item1'),
          icon: 'add',
          click: () => {
            router.navigateByUrl('/cms/add/question');
          },
        },
      ],
    };
  }

  if (showMultiselect) {
    ret.multiselectActions = [
      {
        icon: 'delete',
        name: 'question.tooltip.delete',
        type: 'button',
        click: (meta) => {
          return new Promise((resolve) => {
            dialog
              .confirmDelete(
                translator.instant('question.tooltip.delete'),
                translator.instant('liveEvent.message.deleteMessage', {
                  count: meta.rows.length,
                })
              )
              .pipe(filter((isConfirmed) => isConfirmed))
              .subscribe(async () => {
                await multiSelectAction(
                  meta.rows,
                  async (item: any) => {
                    await questions.deleteQuestion({ id: item.id }).toPromise();
                    return true;
                  },
                  `${translator.instant(
                    'common.label.deleting'
                  )} ${translator.instant('common.label.question')}`,
                  matDialog
                );
                meta.dataSource.refresh();
                resolve();
              });
          });
        },
      },
      {
        icon: 'link_off',
        name: exam
          ? 'content.examQuestion.actionMenu.item3'
          : 'liveEvent.label.removeQuestion',
        type: 'button',
        hide: () =>
          window.location.href.indexOf('cms/content/questions') !== -1
            ? true
            : false,
        click: (meta) => {
          return new Promise((resolve) => {
            dialog
              .confirm({
                title: translator.instant(
                  exam
                    ? 'content.examQuestion.unlinkItem.title'
                    : 'liveEvent.label.removeQuestion'
                ),
                text: translator.instant(
                  exam
                    ? 'content.examQuestion.unlinkItem.text'
                    : 'liveEvent.message.removeQuestionText'
                ),
                buttons: [
                  {
                    color: 'primary',
                    text: translator.instant('common.button.cancel'),
                  },
                  {
                    color: 'warn',
                    text: translator.instant(
                      exam
                        ? 'content.examQuestion.selectedItem.label'
                        : 'liveEvent.label.removeQuestion'
                    ),
                    value: true,
                  },
                ],
              })
              .pipe(
                filter((isConfirmed) => isConfirmed),
                switchMap(() => {
                  const questionIds = meta.rows.length
                    ? meta.rows.map((x) => x.id)
                    : [];
                  if (exam) {
                    return examsService.removeQuestionFromExam({
                      id: exam.id,
                      question_ids: questionIds.join(','),
                    });
                  } else {
                    return quizzesService.removeQuestionFromQuiz({
                      id: quiz.id,
                      question_ids: questionIds.join(','),
                    });
                  }
                })
              )
              .subscribe({
                next: () => {
                  matSnackBar.open(
                    translator.instant('content.question.deleteItem.success')
                  );
                  meta.dataSource.refresh();
                  resolve();
                },
                error: () => {
                  matSnackBar.open(
                    translator.instant('content.question.deleteItem.error')
                  );
                },
              });
          });
        },
      },
    ];
  }

  return ret;
}
