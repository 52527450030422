import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

export interface Filter {
  forName: string;
  filters: object;
}

@Injectable({
  providedIn: 'root',
})
export class FilterService {
  filters = new BehaviorSubject<Filter>(null);
  filter$ = this.filters.asObservable();
  constructor() {}

  applyFilters(filters: Filter) {
    this.filters.next(filters);
  }
}
