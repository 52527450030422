import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatChipInputEvent } from '@angular/material/chips';
import { TagModel, TagQuery, TagQueryFilters } from 'src/app/api/models';
import { TagsService } from 'src/app/api/services';
import { FormField } from './form-field';

@Component({
  selector: 'qa-tag-input',
  templateUrl: './tag-input.component.html',
  styleUrls: ['./tag-input.component.scss'],
})
export class TagInputComponent extends FormField implements OnInit {
  @Input() tags: TagModel[] = [];
  @Input() placeholder = '';
  @Input() limitCharacters = false;
  viewControl = new UntypedFormControl('');
  blurInput: boolean;
  onHover: boolean;
  visible = true;
  selectable = true;
  removable = true;
  isRemoved = false;
  separatorKeysCodes: number[] = [ENTER, COMMA];

  availableTags = [];
  previousValue = '';
  isChipFormed = false;
  showValidation = false;

  @ViewChild('tagInput') tagInput: ElementRef<HTMLInputElement>;

  constructor(private tagService: TagsService) {
    super();
  }

  ngOnInit(): void {
    if (this.control.value && this.control.value.length) {
      this.tags = JSON.parse(JSON.stringify(this.control.value)) || [];
      this.onBlur();
    }
  }

  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;
    if ((value || '').trim()) {
      const controlVal = this.control.value || [];
      controlVal.push({ name: value });
      this.control.setValue(controlVal);
      this.tags.push({ name: value.trim() });
      this.isChipFormed = true;
    }
    if (input) {
      input.value = '';
    }
    this.control.markAsDirty();
    this.viewControl.setValue('');
  }

  onKeyDown(event: KeyboardEvent): void {
    if (this.limitCharacters) {
      const totalCharacters = this.tags.reduce(
        (total, tag) => total + tag.name.length,
        0
      );
      const inputValue = (event.target as HTMLInputElement).value.trim();
      const totalWithInput = totalCharacters;
      if (
        totalWithInput >= 100 &&
        this.isChipFormed &&
        event.key !== 'Backspace' &&
        event.key !== 'Delete'
      ) {
        event.preventDefault();
        this.showValidation = true;
        return;
      }

      if (event.key === 'Enter') {
        if (inputValue.length > 0) {
          const chipEvent: any = {
            input: undefined,
            value: inputValue,
          };
          this.add(chipEvent);
          (event.target as HTMLInputElement).value = '';
        }
        event.preventDefault();
        this.showValidation = false;
        return;
      }
      this.showValidation = false;
    }
  }

  addFromAutocomplete(value: any): void {
    if ((value || '').trim()) {
      const controlVal = this.control.value || [];
      controlVal.push({ name: value });
      this.control.setValue(controlVal);
      this.tags.push({ name: value.trim() });
    }
    if (this.tagInput) {
      this.tagInput.nativeElement.value = '';
    }
    this.control.markAsDirty();
    this.viewControl.setValue('');
  }

  remove(tag: TagModel): void {
    this.isRemoved = true;
    this.showValidation = false;
    const index = this.control.value.findIndex(
      (eachVal) => eachVal.name === tag.name
    );
    const options = [...this.control.value];
    options.splice(index, 1);
    this.control.setValue(options);
    const idx = this.tags.findIndex((eachTag) => eachTag.name === tag.name);
    if (idx >= 0) {
      this.tags.splice(idx, 1);
    }
    this.control.markAsDirty();
    setTimeout(() => {
      this.isRemoved = false;
    }, 500);
  }

  onBlur() {}

  onEdit() {
    this.blurInput = false;
    this.onHover = false;
    setTimeout(() => {
      if (this.tagInput) {
        this.tagInput.nativeElement.focus();
      }
    }, 0);
  }

  get selectedTags(): TagModel[] {
    return this.control.value.map((t) => t.name).join(': ');
  }

  updateAvailableTags(event): void {
    if (!event?.target?.value || event.target.value.length < 3) {
      this.availableTags = [];
      return;
    }
    if (event.target.value === this.previousValue) {
      return;
    }
    this.previousValue = event.target.value;
    this.tagService
      .tagControllerGetStats({
        body: {
          filters: {
            name: event.target.value,
            show_only_user_tags: true,
          } as Partial<TagQueryFilters> as TagQueryFilters,
          paging: {
            page: 1,
            page_size: 25,
          },
          sorting: [],
        } as Partial<TagQuery> as TagQuery,
      })
      .subscribe((response) => {
        this.availableTags = response.data.filter((tag) => {
          return this.tags.every(
            (existingTag) => existingTag.name !== tag.name
          );
        });
      });
  }

  getColorSchema(tag: any) {
    const from = tag && tag.name ? tag.name : '';
    let hash = 0;
    for (let i = 0; i < from.length; i++) {
      hash = from.charCodeAt(i) + ((hash << 5) - hash);
    }
    return `hsl(${hash % 360}, 100%, 35%)`;
  }
}
