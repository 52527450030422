<ng-container>
  <mat-form-field
    appearance="outline"
    subscriptSizing="dynamic"
    [floatLabel]="disableFloatingLabel ? 'never' : 'auto'"
    [ngClass]="{ 'no-label': !label }"
    tabindex="-1"
  >
    <mat-label *ngIf="label">
      {{ label }}
    </mat-label>
    <input
      matInput
      [colorPicker]="control.value"
      [(colorPicker)]="control.value"
      [formControl]="control"
      (click)="onEdit()"
      (colorPickerChange)="onColorChange($event)"
      name="color-of-course"
      autocomplete="off"
      #inputField
    />
    <div class="color-indicator" [style.background]="indicatorColor()"></div>
  </mat-form-field>
</ng-container>

<!-- <ng-template #labelView>
  <div class="label-view" [ngClass]="{ 'no-label': !label }">
    <mat-label *ngIf="label">
      {{ label }}
    </mat-label>
    <div class="color-indicator" [style.background]="indicatorColor()"></div>
    <div (click)="onEdit()" class="inp-value">{{ control.value }}</div>
  </div>
</ng-template> -->
