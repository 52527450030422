import { Component, Inject, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface CancelDialogLabels {
  messages: string[];
  icon?: string;
  buttonLabels: {
    confirm: string;
    cancel: string;
  };
}

@Component({
  selector: 'qa-cancel-confirmation',
  templateUrl: './cancel-confirmation.component.html',
  styleUrls: ['./cancel-confirmation.component.scss'],
})
export class CancelConfirmationComponent {
  constructor(
    @Optional() public dialogRef: MatDialogRef<CancelConfirmationComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: CancelDialogLabels
  ) {}

  confirm() {
    this.dialogRef.close(true);
  }
}
