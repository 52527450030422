<div mat-dialog-title>
  <div class="dialog-title-contents">
    <h1 class="embed-dialog-title">{{ 'embedDialog.title' | translate }}</h1>
    <button class="ml-20" mat-button class="iframe-btn" (click)="openLink()">
      {{ 'embedDialog.iFrame' | translate }}
      <mat-icon>school</mat-icon>
    </button>
  </div>
</div>
<mat-dialog-content>
  <div
    class="flex-row flex-justify-content-space-between"
    style="margin-bottom: 1rem; align-items: center"
  >
    <mat-slide-toggle
      [(ngModel)]="smallStyle"
      (change)="reloadCode()"
      color="primary"
    >
      {{ 'embedDialog.smallStyle' | translate }}
    </mat-slide-toggle>
    <mat-form-field appearance="outline" *ngIf="!smallStyle">
      <mat-label> Height (in px) </mat-label>
      <input
        matInput
        [(ngModel)]="height"
        type="number"
        min="250"
        max="2500"
        (change)="reloadCode()"
      />
    </mat-form-field>
  </div>
  <ng-container *ngIf="!blurInput; else labelView">
    <mat-form-field appearance="outline">
      <mat-label>
        {{ 'HTML-Code' }}
      </mat-label>
      <textarea
        (blur)="onBlur()"
        (click)="textArea.select()"
        [value]="code"
        #textArea
        matInput
        readonly
        rows="9"
      >
      </textarea>
    </mat-form-field>
  </ng-container>

  <ng-template #labelView>
    <div class="label-view">
      <mat-label>
        {{ 'HTML-Code' }}
      </mat-label>
      <br />
      <code (click)="onEdit()">{{ code }}</code>
    </div>
  </ng-template>
  <mat-form-field appearance="outline" class="link">
    <mat-label>
      {{ 'Embed-Link' }}
    </mat-label>
    <input
      [value]="getUrl()"
      (click)="linkElement.select()"
      matInput
      readonly
      #linkElement
      matTooltip="{{ 'embedDialog.linkTooltip' | translate }}"
    />
  </mat-form-field>
</mat-dialog-content>
<mat-dialog-actions class="space-between-btn">
  <a
    (click)="copyWeblink()"
    class="mat-stroked-button mat-button-base mat-primary copy-link"
    color="primary"
    target="_blank"
  >
    {{ 'course.form.copyLable' | translate }}
  </a>
  <qa-button mat-dialog-close label="common.button.shutdown"></qa-button>
</mat-dialog-actions>
