import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomTabsComponent } from './components/custom-tabs/custom-tabs.component';
import { ButtonComponent } from './components/button/button.component';
import { TranslateModule } from '@ngx-translate/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { CustomChipComponent } from './components/custom-chip/custom-chip.component';
import { SideMenuComponent } from './components/side-menu/side-menu.component';
import { MatListModule } from '@angular/material/list';
import {
  NG_SCROLLBAR_OPTIONS,
  NgScrollbarModule,
  NgScrollbarOptions,
} from 'ngx-scrollbar';

@NgModule({
  declarations: [
    CustomTabsComponent,
    ButtonComponent,
    CustomChipComponent,
    SideMenuComponent,
  ],
  providers: [
    {
      provide: NG_SCROLLBAR_OPTIONS,
      useValue: {
        visibility: 'hover',
        appearance: 'compact',
      } as NgScrollbarOptions,
    },
  ],
  imports: [
    CommonModule,
    NgScrollbarModule,
    TranslateModule,
    MatButtonModule,
    MatIconModule,
    MatListModule,
  ],
  exports: [
    CustomTabsComponent,
    ButtonComponent,
    CustomChipComponent,
    NgScrollbarModule,
    SideMenuComponent,
  ],
})
export class SharedModule {}
