<div class="nav-container">
  <div class="navigation-arrows">
    <qa-button
      [isRaisedButton]="true"
      [disabled]="currentItemPosition === 1"
      [isIconButton]="true"
      icon="arrow_back"
      (submitted)="previous()"
    ></qa-button>
    <div class="pagination-text">
      {{ currentItemNumber | number : '2.0-0' }}
      <span>{{ 'common.paginator.from' | translate }}</span>
      {{ itemCount | number : '2.0-0' }}
    </div>
    <qa-button
      [isRaisedButton]="true"
      [disabled]="currentItemPosition === itemCount && !isImport"
      [isIconButton]="true"
      icon="arrow_forward"
      (submitted)="next()"
    ></qa-button>
  </div>
</div>
