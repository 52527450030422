<div class="how-to-upload" [class.isFlashImport]="isFlashImport">
  <div class="upload-area">
    <div class="card">
      <div class="drag-drop-table" (dragenter)="enterDrag()">
        <ng-scrollbar [class.ps-show-always]="false" track="all">
          <table mat-table [dataSource]="tableData">
            <ng-container
              *ngFor="let col of columnNames; let i = index"
              [matColumnDef]="col"
              [sticky]="col === 'number'"
            >
              <th mat-header-cell *matHeaderCellDef>
                {{ col === 'number' ? '' : topRow.split(';')[i - 1] }}
              </th>
              <td
                mat-cell
                *matCellDef="let element"
                id="csv-table-cell-{{ element.number }}-{{ i }}"
                (keydown)="onKeyDown($event, i, element.number)"
                (click)="col === 'number' ? null : editCell($event)"
                (blur)="
                  col === 'number' ? null : saveCell($event, element, col)
                "
                (paste)="
                  col === 'number' ? null : onPaste($event, element.number, i)
                "
              >
                {{ element[col] }}
              </td>
            </ng-container>

            <tr
              mat-header-row
              *matHeaderRowDef="columnNames; sticky: true"
            ></tr>
            <tr mat-row *matRowDef="let row; columns: columnNames"></tr>
          </table>
        </ng-scrollbar>
        <div
          class="dropzone"
          #drop
          ngx-dropzone
          [multiple]="false"
          [class.isVisible]="isDragging"
          (dragleave)="leaveDrag()"
          [accept]="'.csv, .xlsx'"
          (change)="onSelectFile($event.addedFiles)"
        >
          <ngx-dropzone-label>
            <div class="drag-content">
              <div class="drag-drop">
                {{ 'csvImports.dragDrop' | translate }}
              </div>
              <span class="file-format">{{
                'csvImports.fileFormat' | translate
              }}</span>
            </div>
          </ngx-dropzone-label>
        </div>
      </div>

      <div class="btn-actions">
        <qa-button
          label="common.button.cancel"
          (submitted)="cancelConfirm()"
        ></qa-button>
        <qa-button
          [disabled]="!isReady()"
          [isRaisedButton]="true"
          label="common.button.next"
          (submitted)="next()"
        ></qa-button>
      </div>
    </div>
    <div class="buttons">
      <qa-button
        label="dashboardNew.liveEventCardDefault.video"
        (submitted)="openHelp()"
        [showIconAtEnd]="true"
        icon="play_circle"
      ></qa-button>
      <qa-button
        [isRaisedButton]="true"
        label="csvImports.chooseFile"
        (submitted)="drop.showFileSelector()"
      ></qa-button>
    </div>
  </div>
</div>
