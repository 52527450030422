import {
  Component,
  ElementRef,
  Inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  DomSanitizer,
  SafeResourceUrl,
  SafeUrl,
} from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { UserConfigService } from '../user-config.service';
import YouTubePlayer from 'youtube-player';
import { DestroyNotifier } from './destroy-notifier';
import { distinct, filter, map, skip, switchMap, takeUntil, timer } from 'rxjs';
import PlayerStates from 'youtube-player/dist/constants/PlayerStates';
import { UserJourneyService } from '../pages/onboarding-steps/user-journey.service';

export interface HelpVideoDialogData {
  skipIfAlreadyShown?: boolean;
  startAt?: number; // in seconds
  videoName: string;
  extraText?: string;
}

type HelpVideoDefinitions = {
  [videoName: string]: {
    title: string;
    youtubeId: string;
    academyUrl?: string;
  };
};

@Component({
  selector: 'qa-help-video-dialog',
  template: `
    <h1 mat-dialog-title>
      {{ title }}
    </h1>
    <mat-dialog-content>
      <p *ngIf="data.extraText" class="extra-text">
        {{ data.extraText | translate }}
      </p>
      <!--<iframe
        [src]="videoUrl"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      ></iframe>-->
      <div #videoPlayer></div>
    </mat-dialog-content>
    <mat-dialog-actions>
      <div class="padder" *ngIf="!academyUrl"></div>
      <a mat-button target="_blank" [href]="academyUrl" *ngIf="academyUrl">
        <mat-icon>school</mat-icon>
        {{ 'common.button.toAcademy' | translate }}
      </a>
      <a
        mat-button
        href="/profile?tab=settings"
        *ngIf="data?.skipIfAlreadyShown"
      >
        {{ 'liveEvent.feedback2.tooltip3' | translate }}
      </a>
      <button color="primary" mat-button mat-dialog-close type="button">
        {{ 'common.button.understood' | translate }}
      </button>
    </mat-dialog-actions>
  `,
  styles: [
    `
      :host {
        display: block;
        width: 800px;
        max-width: 100%;

        .mat-mdc-dialog-title {
          margin: 0 0 5px;
          font-size: 18px;
          color: #00000099;
        }

        .mat-mdc-dialog-content {
          padding-bottom: 0;
        }

        .extra-text {
          margin-top: -0.5rem;
          margin-bottom: 2rem;
          font-weight: 600;
          color: black;
        }
      }

      ::ng-deep iframe {
        border: 0;
        display: block;
        height: 400px;
        width: 100%;

        @media only screen and (max-width: $mobile-breakpoint) {
          height: 40%;
        }
      }

      /* TODO(mdc-migration): The following rule targets internal classes of dialog that may no longer apply for the MDC version. */
      mat-dialog-actions {
        justify-content: space-between;
        min-height: 68px;
        padding: 8px 24px;
      }
    `,
  ],
})
export class HelpVideoDialogComponent
  extends DestroyNotifier
  implements OnInit
{
  videos: HelpVideoDefinitions = {
    content: {
      title: this.translator.instant('videoHelp.content.title'),
      youtubeId: 'V4Pe7_A-3t0',
      academyUrl: 'https://quizacademy.de/akademie/allgemein/',
    },
    exam: {
      title: this.translator.instant('videoHelp.exam.title'),
      youtubeId: 'NZz6TEil6lo',
      academyUrl: 'https://quizacademy.de/akademie/examen/',
    },
    accessOptions: {
      title: this.translator.instant('course.accessOptions'),
      youtubeId: 'qstCGXmP3dc',
    },
    accessLearners: {
      title: this.translator.instant('liveEvents.setup.label.accessLearners'),
      youtubeId: 'I59JLAwaUSQ',
      // https://www.youtube.com/watch?v=I59JLAwaUSQ&t=318s
    },
    liveEvent: {
      title: 'Live-Event',
      youtubeId: 'I59JLAwaUSQ',
      academyUrl: 'https://quizacademy.de/akademie/live-event/',
    },
    dash_content: {
      title: this.translator.instant('videoHelp.content.alternativeTitle'),
      youtubeId: '82SjD_In68Q',
    },
    teaser: {
      title: this.translator.instant('videoHelp.teaser.title'),
      youtubeId: 'f1SsUDqnU-U',
      academyUrl: 'https://quizacademy.de/akademie/erste-schritte/',
    },
    examForStudents: {
      title: this.translator.instant('exam.videoTutorial'),
      youtubeId: 'Wh1QjldJwXQ',
    },
    collaboration: {
      title: this.translator.instant('course.collaboration.title'),
      youtubeId: '2iokvlJOcN8',
    },
    bookTutorial: {
      title: this.translator.instant('course.collaboration.title'),
      youtubeId: 'V4Pe7_A-3t0',
    },
  };
  youtubeEmbedUrl = 'https://www.youtube-nocookie.com/embed';

  title: string;
  videoUrl: SafeResourceUrl;
  academyUrl: SafeUrl | undefined;

  @ViewChild('videoPlayer', { static: true })
  videoPlayerRef: ElementRef<HTMLDivElement>;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: HelpVideoDialogData,
    private domSanitizer: DomSanitizer,
    private dialogRef: MatDialogRef<HelpVideoDialogComponent>,
    private translator: TranslateService,
    private userConfig: UserConfigService,
    private userJourney: UserJourneyService
  ) {
    super();
    dialogRef.updateSize('800px');
    this.title = this.videos[this.data.videoName].title;
    if (this.videos[this.data.videoName]?.academyUrl) {
      this.academyUrl = this.domSanitizer.bypassSecurityTrustUrl(
        this.videos[this.data.videoName].academyUrl
      );
    }
    if (!data.skipIfAlreadyShown) return;
    this.setSeen();
  }

  ngOnInit(): void {
    this.startVideo();
  }

  private startVideo() {
    const player = YouTubePlayer(this.videoPlayerRef.nativeElement, {
      host: 'https://www.youtube-nocookie.com',
      playerVars: {
        origin: window.location.host,
      },
    });
    player.loadVideoById(
      this.videos[this.data.videoName].youtubeId,
      this.data?.startAt ?? 0
    );

    if (this.data.videoName === 'teaser') {
      // the user has to pass these timestamps to get the user journey flag
      const milestones: number[] = [0, 30, 60, 120, 160, 200];
      timer(0, 50)
        .pipe(
          takeUntil(this.destroy$),
          switchMap(() => player.getPlayerState()),
          filter((state) => state === PlayerStates.PLAYING),
          switchMap(() => player.getCurrentTime()),
          map((time) =>
            milestones.findIndex(
              (milestone) => time > milestone && time - milestone < 2
            )
          ),
          filter((milestoneIndex) => milestoneIndex !== -1),
          distinct(),
          skip(milestones.length - 1)
        )
        .subscribe({
          next: () => {
            this.userJourney.set('fullTeaserVideoWatched');
          },
        });
    }
  }

  private async setSeen() {
    if (!!(await this.userConfig.get('settings.help.disableHelp'))) {
      this.dialogRef.close();
      return;
    }
    const alreadyShownKey = `HelpVideoDialogComponent.alreadyShown.${this.data.videoName}`;
    if ((await this.userConfig.get(alreadyShownKey)) === 'yes') {
      this.dialogRef.close();
    } else {
      await this.userConfig.set(alreadyShownKey, 'yes');
    }
  }
}
