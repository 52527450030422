<div class="edit-question" [class.isLiveEvent]="questionId">
  <form [formGroup]="form">
    <qa-content-spinner [active]="loading" [class.preventOverflow]="questionId">
      <ng-container *ngTemplateOutlet="title"> </ng-container>
      <div class="content-question">
        <ng-container *ngTemplateOutlet="main"> </ng-container>
      </div>
    </qa-content-spinner>
    <ng-template #title> </ng-template>
    <ng-template #main>
      <div class="main-flex flex-row flex-auto-margin" *ngIf="showForm">
        <mat-card appearance="outlined" *ngIf="!showMobilePreview">
          <div class="question-header">
            <h1 *ngIf="!question && !duplicateQuestionId" class="create-title">
              {{
                (form.value.id
                  ? 'question.editTitle'
                  : flashCardId
                  ? 'common.button.convertToQuestion'
                  : 'question.addTitle'
                ) | translate
              }}
            </h1>
            <h1 *ngIf="question && duplicateQuestionId" class="create-title">
              {{ 'question.duplicateQuestionTitle' | translate }}
            </h1>
            <div
              class="template-select"
              *ngIf="!question && !form.value.id && !flashCardId"
            >
              <button mat-button [matMenuTriggerFor]="templateSelectMenu">
                {{ 'common.button.applyTemplate' | translate }}
                <img src="assets/icons/vorlage.png" alt="template" />
              </button>
              <mat-menu #templateSelectMenu="matMenu">
                <button
                  mat-menu-item
                  *ngFor="let template of templates"
                  (click)="applyTemplate(template.template)"
                  [matTooltip]="template.title"
                >
                  {{ template.title }}
                </button>
              </mat-menu>
            </div>
          </div>
          <p class="explanation" *ngIf="flashCardId">
            {{ 'common.message.conversionHint.question' | translate }}
            <b>{{ 'common.message.conversionHint.question2' | translate }}</b>
            {{ 'common.message.conversionHint.question3' | translate }}
            <a
              target="_blank"
              href="https://quizacademy.de/tipps/content-konvertieren/"
            >
              {{ 'common.message.conversionHint.moreInfoLink' | translate }}.
            </a>
          </p>
          <p class="explanation" *ngIf="duplicateQuestionId">
            {{ 'common.message.duplicateHint.question' | translate }}
          </p>
          <ng-scrollbar>
            <div class="question-form" [ngClass]="{ create: !courseId }">
              <h2 *ngIf="question">
                {{
                  (isPollQuestion
                    ? 'question.label.surveyQuestionShort'
                    : 'question.label.quizQuestionShort'
                  ) | translate
                }}
                ({{ QuestionTypeDisplayFn(question.type, true) }})
              </h2>
              <div class="flex-row type">
                <div class="flex-50">
                  <qa-custom-tabs
                    [tabs]="questionsTypes"
                    [defaultSelected]="form?.value?.type"
                    (tabSelected)="getTypeVal($event)"
                  ></qa-custom-tabs>
                </div>
                <div class="flex-50">
                  <div class="preview" *ngIf="questionId">
                    <qa-form-field-toggle
                      (valueChanged)="onValueChange($event)"
                      [label]="
                        'exam.users.accessRestriction.generateDialog.preview'
                          | translate
                      "
                    >
                    </qa-form-field-toggle>
                  </div>
                </div>
              </div>
              <div class="flex-100">
                <qa-media-input
                  [control]="form.get('media')"
                  [beforeUploadCallback]="editImagePreUpload"
                ></qa-media-input>
              </div>
              <qa-form-field-input
                [control]="form.get('text')"
                label="{{
                  (form.value.type === questionTypeEnum.FREETEXT
                    ? 'question.label.questionText'
                    : 'question.label.questionLabel'
                  ) | translate
                }}"
                [markdownHint]="true"
                [multi]="true"
                required="true"
                class="name-input"
              >
              </qa-form-field-input>
              <qa-form-field-input
                [control]="form.get('sample_answer')"
                *ngIf="
                  form.value.type === questionTypeEnum.FREETEXT &&
                  !isPollQuestion
                "
                label="{{ 'question.label.sampleAnswer' | translate }}"
                [markdownHint]="true"
                [multi]="true"
                class="name-input"
              >
              </qa-form-field-input>
              <ng-content *ngTemplateOutlet="answerForm"> </ng-content>
              <qa-form-field-input
                [control]="form.get('explanation')"
                label="{{ 'question.form.explanation' | translate }}"
                [suggestion]="explanationSuggestion()"
                [markdownHint]="true"
                [multi]="true"
              >
              </qa-form-field-input>
              <qa-form-field-input
                [control]="form.get('weblink')"
                label="{{ 'question.form.webLink' | translate }}"
                *ngIf="!isPollQuestion"
              >
              </qa-form-field-input>
              <qa-form-field-input
                [control]="form.get('source')"
                label="{{ 'question.form.source' | translate }}"
                *ngIf="!isPollQuestion"
              >
              </qa-form-field-input>
              <qa-tag-input
                [control]="form.get('tags')"
                label="{{ 'Tags' | translate }}"
              >
              </qa-tag-input>
              <div
                class="hint-text"
                [innerHTML]="'question.form.tagHint.hintText' | translate"
              ></div>
              <div class="save-hint">
                <p [innerHTML]="'common.message.saveHintText' | translate"></p>
              </div>
            </div>
          </ng-scrollbar>
          <div
            class="navigations"
            *ngIf="
              courseId &&
              question &&
              form.value.id &&
              !this.parentExamId &&
              !this.parentQuizId &&
              !this.liveEventId
            "
          >
            <button
              mat-mini-fab
              type="button"
              class="navigate-back"
              [disabled]="currentItemIndex == 0"
              (click)="onNavigate(currentItemIndex - 1)"
            >
              <mat-icon>arrow_back</mat-icon>
            </button>
            <button
              mat-mini-fab
              type="button"
              [disabled]="currentItemIndex == allQuestions?.length - 1"
              class="navigate-forward"
              (click)="onNavigate(currentItemIndex + 1)"
            >
              <mat-icon>arrow_forward</mat-icon>
            </button>
          </div>
        </mat-card>

        <div
          class="mock-phone-container"
          [class.showMobilePreview]="showMobilePreview"
          *ngIf="!questionId || (questionId && showMobilePreview)"
        >
          <div class="preview mobile" *ngIf="questionId">
            <qa-form-field-toggle
              (valueChanged)="onValueChange($event)"
              [checked]="true"
              [label]="
                'exam.users.accessRestriction.generateDialog.preview'
                  | translate
              "
            >
            </qa-form-field-toggle>
          </div>
          <ng-scrollbar>
            <div
              class="mock-phone"
              [ngClass]="{ create: !question || duplicateQuestionId }"
              *ngIf="!loading"
            >
              <qa-question-mock-phone
                [currentItemPosition]="currentItemIndex + 1"
                [itemCount]="allQuestions?.length"
                [question]="form.value"
                [isPollQuestion]="isPollQuestion"
                [isExam]="parentExamId"
              ></qa-question-mock-phone>
            </div>
          </ng-scrollbar>
          <div
            class="flex-column flex-align-items-center flex-justify-content-center"
            *ngIf="!showMobilePreview"
          >
            <qa-import-csv-navigation
              [currentItemPosition]="currentItemIndex + 1"
              [itemCount]="allQuestions?.length"
              (navigation)="onNavigate($event)"
              [isImport]="false"
              *ngIf="
                (courseId || questionIdFromContent) && question && form.value.id
              "
            >
            </qa-import-csv-navigation>
          </div>
        </div>
      </div>
    </ng-template>
    <div class="actions" *ngIf="!loading">
      <div class="buttons">
        <ng-content *ngTemplateOutlet="actions"></ng-content>
      </div>
    </div>
    <ng-template #answerForm>
      <div
        class="answers-container"
        [class.freetext]="form.value.type === questionTypeEnum.FREETEXT"
        [class.poll-question]="isPollQuestion"
      >
        <qa-form-field-toggle
          [control]="form.get('is_right')"
          label="{{ 'question.form.correct' | translate }}"
          prefixLabel="{{ 'question.form.inCorrect' | translate }}"
          *ngIf="
            form.value.type === questionTypeEnum.TRUE_FALSE;
            else answerInputs
          "
        >
        </qa-form-field-toggle>
        <ng-template #answerInputs>
          <ng-container
            *ngIf="
              form.value.type === questionTypeEnum.FREETEXT;
              else answersFields
            "
          >
            <qa-form-field-input
              *ngIf="!isPollQuestion"
              [control]="form.get('max_score')"
              [step]="1"
              required="true"
              label="{{ 'question.form.maxScore' | translate }}"
              type="number"
            ></qa-form-field-input>
            <qa-form-field-input
              *ngIf="isPollQuestion"
              [control]="form.get('max_answer_submissions')"
              [step]="1"
              required="true"
              label="{{
                (!form.get('max_answer_submissions')?.value
                  ? 'question.form.arbitraryAnswerSubmissions'
                  : 'question.form.maxAnswerSubmissions'
                ) | translate
              }}"
              type="number"
            ></qa-form-field-input>
            <qa-form-field-input
              [control]="form.get('max_num_characters')"
              label="{{ 'question.form.maxCharacters' | translate }}"
              [step]="1"
              type="number"
              hint="{{ 'question.form.maxCharactersDetails' | translate }}"
            ></qa-form-field-input>
          </ng-container>
        </ng-template>
        <ng-template #answersFields>
          <mat-radio-group
            [formControl]="radioRightAnswerControl"
            (change)="onChangeRadio($event)"
          >
            <div
              class="answer"
              *ngFor="
                let answer of form.get('answers')['controls'];
                let i = index
              "
            >
              <div class="flex-row flex-align-items-center flex-auto-margin">
                <mat-radio-button
                  [value]="i"
                  [checked]="i == rightAnswerIndex"
                  *ngIf="form.value.type === questionTypeEnum.SINGLE_CHOICE"
                  color="primary"
                >
                </mat-radio-button>
                <mat-checkbox
                  [formControl]="
                    form.get('answers')['controls'][i].get('is_right')
                  "
                  *ngIf="form.value.type === questionTypeEnum.MULTIPLE_CHOICE"
                  color="primary"
                >
                </mat-checkbox>
                <qa-form-field-input
                  [ngStyle]="{ margin: 0 }"
                  [control]="form.get('answers')['controls'][i].get('text')"
                  placeholder="{{ 'question.form.answer' | translate }}"
                  [multi]="true"
                  [markdownHint]="true"
                  class="flex-grow"
                >
                </qa-form-field-input>
                <div class="remove-ans" (click)="onRemoveAnswer(i)">
                  <mat-icon>delete</mat-icon>
                </div>
              </div>
            </div>
          </mat-radio-group>
          <div class="more-info">
            <button
              (click)="onAddAnswer()"
              class="add-more"
              mat-button
              type="button"
            >
              <mat-icon>add</mat-icon>
              {{ 'question.label.addMore' | translate }}
            </button>
            <div
              class="checkbox-container"
              *ngIf="form?.value.type !== questionTypeEnum.TRUE_FALSE"
            >
              <mat-checkbox
                [formControl]="form.get('display_in_order')"
                color="primary"
              >
                {{ 'question.form.fixAnswer' | translate }}
              </mat-checkbox>
              <mat-icon class="info-tool-tip" (click)="openDialog()"
                >info</mat-icon
              >
            </div>
          </div>
        </ng-template>
      </div>
    </ng-template>
    <ng-template #actions>
      <div class="left-actions">
        <ng-container *ngIf="!questionId">
          <qa-button
            *ngIf="question && !duplicateQuestionId"
            label="common.button.duplicate"
            (submitted)="duplicatedQuestion()"
          ></qa-button>
          <qa-button
            *ngIf="question && !duplicateQuestionId && !isPollQuestion"
            label="common.button.convertToFlashCard"
            (submitted)="convertToFlashcard()"
          ></qa-button>
        </ng-container>
        <qa-button
          *ngIf="id"
          [matMenuTriggerFor]="moreMenu"
          [isIconButton]="true"
          icon="more_vert"
        ></qa-button>
        <mat-menu
          class="material-menu"
          [overlapTrigger]="true"
          #moreMenu="matMenu"
        >
          <ng-container *ngIf="questionId">
            <button
              *ngIf="question && !duplicateQuestionId"
              (click)="duplicatedQuestion()"
              mat-menu-item
              type="button"
            >
              <mat-icon>copy</mat-icon>
              {{ 'common.button.duplicate' | translate }}
            </button>
            <button
              *ngIf="question && !duplicateQuestionId && !isPollQuestion"
              (click)="convertToFlashcard()"
              mat-menu-item
              type="button"
            >
              <mat-icon>crop</mat-icon>
              {{ 'common.button.convertToFlashCard' | translate }}
            </button>
          </ng-container>
          <button
            (click)="showComments()"
            *ngIf="hasComments"
            mat-menu-item
            type="button"
          >
            <mat-icon>comment</mat-icon>
            {{ 'comment.label.showComment' | translate }}
          </button>
          <button (click)="exportAsJson()" mat-menu-item type="button">
            <mat-icon>download</mat-icon>
            {{ 'content.course.actionMenu.item4' | translate }}
          </button>
          <button
            (click)="onDelete()"
            *ngIf="form.value.id"
            mat-menu-item
            type="button"
          >
            <mat-icon>delete</mat-icon>
            {{ 'common.button.clear' | translate }}
          </button>
        </mat-menu>
      </div>
      <div class="flex-align-right">
        <qa-button
          label="common.button.shutdown"
          (submitted)="onClose()"
        ></qa-button>
        <ng-container *ngIf="question || flashCardId">
          <qa-button
            *ngIf="userId === question?.created_by || !question"
            [disabled]="
              form.invalid || form.pristine || (form && isAllAnswerUnchecked())
            "
            [isRaisedButton]="true"
            label="common.button.save"
            (submitted)="onSubmit()"
          ></qa-button>
        </ng-container>
        <qa-button
          *ngIf="!question && !flashCardId"
          [matMenuTriggerFor]="addMenu"
          [disabled]="
            form.invalid || form.pristine || (form && isAllAnswerUnchecked())
          "
          [isRaisedButton]="true"
          label="common.button.save"
          #addButton
        ></qa-button>
        <mat-menu
          class="material-menu"
          [overlapTrigger]="true"
          #addMenu="matMenu"
        >
          <button
            [disabled]="form.invalid || form.pristine"
            *ngIf="!form.value.id && !flashCardId && !duplicateQuestionId"
            (click)="onSubmit(true)"
            mat-menu-item
            type="button"
          >
            <mat-icon>add</mat-icon>
            {{ 'common.button.saveAndNew' | translate }}
          </button>
          <button
            [disabled]="form.invalid || form.pristine"
            *ngIf="!form.value.id && !flashCardId && !duplicateQuestionId"
            (click)="onSubmit(false, true)"
            mat-menu-item
            type="button"
          >
            <mat-icon>copy</mat-icon>
            {{ 'common.button.saveAndDuplicate' | translate }}
          </button>
          <button
            [disabled]="form.invalid || form.pristine"
            *ngIf="userId === question?.created_by || !question"
            (click)="onSubmit()"
            mat-menu-item
            type="button"
          >
            <mat-icon>check</mat-icon>
            {{ 'common.button.saveAndClose' | translate }}
          </button>
          <button
            [disabled]="form.invalid || form.pristine"
            *ngIf="question && userId !== question?.created_by"
            matTooltipClass="preline"
            matTooltip="{{ 'question.form.unlinkSaveTooltip' | translate }}"
            (click)="unlinkQuestion()"
            mat-menu-item
            type="button"
          >
            <mat-icon class="material-icons">shopping_cart</mat-icon>
            {{ 'question.form.unlinkSave' | translate }}
          </button>
        </mat-menu>
      </div>
    </ng-template>
  </form>
</div>
