import {
  ConnectedPosition,
  Overlay,
  OverlayConfig,
  OverlayRef,
} from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  OnDestroy,
  Output,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { FormField } from './form-field';
import { findNestedByName } from 'src/app/utility/app.utils';
import { Subscription, debounceTime } from 'rxjs';
import { NgScrollbar } from 'ngx-scrollbar';

export interface TreeNode {
  id: any;
  name: string;
  value: any;
  children: TreeNode[];
  icon?: string;
  openedChildren?: boolean;
}

@Component({
  selector: 'qa-tree-select',
  templateUrl: './tree-select.component.html',
  styleUrls: ['./tree-select.component.scss'],
})
export class TreeSelectComponent
  extends FormField
  implements OnInit, OnDestroy
{
  @Input() tree: TreeNode[];
  allTreeItems: TreeNode[];

  displayControl = new UntypedFormControl();

  private overlayRef: OverlayRef;

  @ViewChild('formElement', { read: ElementRef }) formElementRef: ElementRef;
  @ViewChild('treeOverlayTemplate') treeOverlayTemplate: TemplateRef<unknown>;
  @ViewChild(NgScrollbar) scrollbarRef: NgScrollbar;
  @Output() valueChanged = new EventEmitter<any>();
  searchControlSub: Subscription;
  parentCategoryId = 1;

  constructor(
    private overlay: Overlay,
    private viewContainerRef: ViewContainerRef
  ) {
    super();
  }

  @HostListener('document:pointerdown', ['$event']) clickedOutside(event) {
    this.close(event);
  }

  close(event) {
    const isInputClicked = event.srcElement.id === 'search';
    if (this.overlayRef && !isInputClicked) {
      this.tree = this.allTreeItems;
      this.displayControl.reset();
      this.displayControl.setValue(this.control?.value?.name || '');
      this.overlayRef.dispose();
      this.overlayRef = null;
      this.valueChanged.emit();
      this.tree.forEach((element) => {
        if (element.id === this.parentCategoryId) {
          element.openedChildren = true;
        }
      });
    }
  }

  @HostListener('pointerdown', ['$event']) clickedInside(event) {
    event.preventDefault();
    event.stopPropagation();
  }

  ngOnInit(): void {
    this.allTreeItems = JSON.parse(JSON.stringify(this.tree));
    this.updatePreviewName();
    this.searchControlSub = this.displayControl.valueChanges
      .pipe(debounceTime(500))
      .subscribe((value) => {
        if (this.overlayRef) {
          this.searchItemByName(value);
        }
      });
  }

  toggleCategory(event, node) {
    event.stopPropagation();
    node.openedChildren = !node.openedChildren;
    // required timeout to update scrollbar after
    // options expanded in view
    setTimeout(() => {
      this.scrollbarRef.update();
    }, 10);
  }

  closeDropDown(event) {
    if (this.overlayRef) {
      event.stopPropagation();
      this.close(event);
    }
  }

  ngOnDestroy(): void {
    this.searchControlSub.unsubscribe();
  }

  showSelectOverlay() {
    if (this.overlayRef) {
      return;
    }
    this.displayControl.reset();
    const positionStrategy = this.overlay
      .position()
      .flexibleConnectedTo(this.formElementRef)
      .withPositions([
        {
          originX: 'start',
          originY: 'bottom',
          overlayX: 'start',
          overlayY: 'top',
        } as ConnectedPosition,
      ])
      .withPush(true);

    const overlayConfig = new OverlayConfig({
      scrollStrategy: this.overlay.scrollStrategies.block(),
      positionStrategy,
      minWidth: this.formElementRef.nativeElement.offsetWidth,
    });
    this.overlayRef = this.overlay.create(overlayConfig);
    const portal = new TemplatePortal(
      this.treeOverlayTemplate,
      this.viewContainerRef
    );
    this.overlayRef.attach(portal);
  }

  private updatePreviewName() {
    if (this.control?.value?.id && this.tree && this.tree.length > 0) {
      const node = this.recFind(this.control?.value?.id);
      if (node !== false) {
        this.displayControl.setValue(node.name);
      }
    }
  }

  private recFind(id: any, node?: TreeNode): TreeNode | false {
    if (id === 'all') {
      return this.tree[0];
    }
    if (!node) {
      node = this.tree?.length > 1 ? this.tree[1] : this.tree[0];
    }
    if (node.id === id) {
      return node;
    }
    const childAnswers = node.children.map((child) => this.recFind(id, child));
    const ans = childAnswers.find((a) => a !== false);
    if (ans) {
      return ans;
    }
    return false;
  }

  searchItemByName(value) {
    if (!value) {
      this.tree = this.allTreeItems;
      return;
    }
    const items =
      this.allTreeItems.length > 1
        ? JSON.parse(JSON.stringify(this.allTreeItems[1]))
        : JSON.parse(JSON.stringify(this.allTreeItems[0]));
    const filteredItems = [];
    items.children.forEach((child) => {
      const f = findNestedByName(value)([child]);
      if (f && f.length) {
        filteredItems.push(f[0]);
      }
    });
    if (filteredItems?.length) {
      items.children = filteredItems;
      this.openChildren(filteredItems);
      this.tree = [items];
    } else {
      this.tree = [];
    }
  }

  openChildren(items?: TreeNode[]) {
    items.forEach((item) => {
      if (item && item.children?.length) {
        item.openedChildren = true;
        this.openChildren(item.children);
      }
    });
  }

  selectElement(node: TreeNode) {
    this.displayControl.reset();
    this.displayControl.setValue(node.name);
    this.control.setValue(node.value);
    this.control.markAsDirty();
    this.updatePreviewName();
    this.tree = this.allTreeItems;
    this.overlayRef.dispose();
    this.overlayRef = null;
    this.valueChanged.emit(node);
  }
}
