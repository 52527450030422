<div class="side-menu-container">
  <ng-scrollbar>
    <div class="sidebar-header" *ngIf="sideMenuTitle">
      <h1 class="sidebar-title">{{ sideMenuTitle | translate }}</h1>
    </div>
    <div class="sidebar-tab">
      <mat-nav-list>
        <ng-container *ngFor="let list of tabList; let i = index">
          <div
            class="tab-list"
            [class.active]="active === list?.paramName"
            *ngIf="list?.hideIf === true ? false : true"
          >
            <a mat-list-item (click)="onMenuClick(i, list)">
              <span class="nav-list-contents">
                <mat-icon *ngIf="list?.icon">{{ list?.icon }}</mat-icon>
                <span mat-line class="tab-name">{{
                  list?.name | translate
                }}</span>
                <span *ngIf="list?.number">&nbsp;({{ list?.number() }})</span>
              </span>
            </a>
          </div>
        </ng-container>
      </mat-nav-list>
    </div>
  </ng-scrollbar>
</div>
