import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { CmsCommonModule } from '../common/common.module';
import { CounterStatisticsComponent } from './counter-statistics.component';
import { CoursesStatisticsComponent } from './courses-statistics.component';
import { UsageStatisticsComponent } from './usage-statistics.component';
import { RouterModule } from '@angular/router';
import { ListModule } from '../list/list.module';
import { CourseStatisticsComponent } from './course-statistics/course-statistics.component';
import { QuizStatisticsComponent } from './quiz-statistics/quiz-statistics.component';
import { QuizStatsComponent } from './course-statistics/quiz-stats/quiz-stats.component';
import { StackStatsComponent } from './course-statistics/stack-stats/stack-stats.component';
import { MarkdownKatexModule } from 'src/app/widget/markdown-katex/markdown-katex.module';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { StackStatisticsComponent } from './stack-statistics/stack-statistics.component';
import { SharedModule } from 'src/app/shared/shared.module';

@NgModule({
  declarations: [
    CounterStatisticsComponent,
    CoursesStatisticsComponent,
    UsageStatisticsComponent,
    CourseStatisticsComponent,
    QuizStatisticsComponent,
    QuizStatsComponent,
    StackStatsComponent,
    StackStatisticsComponent,
  ],
  imports: [
    CmsCommonModule,
    CommonModule,
    SharedModule,
    MatButtonModule,
    MatDialogModule,
    MatIconModule,
    MatListModule,
    MatNativeDateModule,
    MarkdownKatexModule,
    MatCheckboxModule,
    RouterModule,
    ListModule,
  ],
  exports: [
    CounterStatisticsComponent,
    CourseStatisticsComponent,
    CoursesStatisticsComponent,
    UsageStatisticsComponent,
    QuizStatisticsComponent,
    QuizStatsComponent,
    StackStatsComponent,
    StackStatisticsComponent,
  ],
})
export class StatisticsModule {}
