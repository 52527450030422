import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { asShortLink, editionPrefix } from 'src/app/app.component';
import {
  copyImageToClipboard,
  copyTextToClipboard,
} from 'src/app/utility/app.utils';
import { QrData, QrModalComponent } from 'src/app/widget/qr-model.component';
import { QrService } from 'src/app/widget/qr.service';
import {
  EmbedDialogComponent,
  EmbedDialogData,
} from '../../panel/embed-dialog.component';
import {
  HelpVideoDialogComponent,
  HelpVideoDialogData,
} from '../help-video-dialog.component';

@Component({
  selector: 'qa-share-section',
  templateUrl: './share-section.component.html',
  styleUrls: ['./share-section.component.scss'],
})
export class ShareSectionComponent implements OnInit {
  @Output() inviteAction = new EventEmitter();
  @Output() generateFlyer = new EventEmitter();
  shortLinkPrefix = asShortLink('', false);
  asShortLink = asShortLink;
  @Input() pinCode: string;
  @Input() openInNewTabText: string;
  @Input() generatingFlyer: boolean;
  @Input() canDownLoadFlyer: boolean;
  @Input() embedDialogData: EmbedDialogData;
  @Input() helpVideoDialogData: HelpVideoDialogData;
  qrSvg: SafeHtml;
  qrDataUrl: string;

  supportsClipboard = !!navigator.clipboard;

  editionPrefix = editionPrefix();

  constructor(
    private translator: TranslateService,
    private matSnackBar: MatSnackBar,
    private sanitizer: DomSanitizer,
    private matDialog: MatDialog,
    private qr: QrService
  ) {}

  async ngOnInit() {
    await this.initialize(this.pinCode);
  }

  async initialize(pin: string) {
    const qrSVG = await this.qr.getSVG(asShortLink(pin));
    this.qrSvg = this.sanitizer.bypassSecurityTrustHtml(qrSVG);
    this.qr
      .getDataURL('' + `${asShortLink(pin)}`)
      .then((qrDataUrl) => (this.qrDataUrl = qrDataUrl));
  }

  copyWebLink() {
    try {
      copyTextToClipboard(asShortLink(this.pinCode));
      this.matSnackBar.open(
        this.translator.instant('exam.message.copiedToClipboard')
      );
    } catch (error) {
      this.matSnackBar.open(
        this.translator.instant('exam.message.notCopiedToClipboard')
      );
    }
  }

  async copyQR() {
    try {
      await copyImageToClipboard(this.qrDataUrl);
      this.matSnackBar.open(
        this.translator.instant('course.message.copiedToClipboard')
      );
    } catch (error) {
      this.matSnackBar.open(
        this.translator.instant('course.message.notCopiedToClipboard')
      );
    }
  }

  openQrModel() {
    const data: QrData = {
      qr: this.qrSvg,
      pin: this.pinCode,
      qrDataUrl: this.qrDataUrl,
      invitationLink: {
        click: () => this.inviteAction.emit(),
      },
    };
    this.matDialog.open(QrModalComponent, {
      data,
    });
  }

  embedCourse() {
    this.matDialog.open<unknown, EmbedDialogData>(EmbedDialogComponent, {
      data: this.embedDialogData as EmbedDialogData,
      autoFocus: false,
    });
  }

  openVideo() {
    this.matDialog.open<HelpVideoDialogComponent, HelpVideoDialogData>(
      HelpVideoDialogComponent,
      {
        data: this.helpVideoDialogData,
      }
    );
  }
}
