import {
  Component,
  ElementRef,
  Input,
  OnChanges,
  ViewEncapsulation,
  OnInit,
  HostBinding,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { QuestionModel } from 'src/app/api/models';
import { AnswerSelection } from 'src/app/quiz/quiz.types';
import { QuestionType } from '../../app-constants';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'qa-question-answers',
  templateUrl: './question-answers.component.html',
  styleUrls: ['./question-answers.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class QuestionAnswersComponent implements OnChanges, OnInit {
  @Input() compact: boolean;
  @Input() control: UntypedFormControl;
  @Input() disabled: boolean;
  @Input() question: QuestionModel;
  @Input() showCorrectAnswers: boolean;
  @Input() trueFalseOrderInverted: boolean;
  hint: string;
  questionType = QuestionType;
  singleChoiceAnswer: boolean | number;
  singleChoiceAnswers: Array<{
    isRight: boolean;
    text: string;
    value: boolean | number;
  }>;

  @HostBinding('class.isPoll')
  isPollQuestion = false;

  constructor(
    private elementRef: ElementRef<HTMLElement>,
    private translator: TranslateService
  ) {
    elementRef.nativeElement.classList.add('qa-question-answers');
  }

  ngOnInit() {
    if (this.question) {
      if (this.question.type === QuestionType.TrueFalse) {
        this.singleChoiceAnswer = this.question.is_right;
      } else if (this.question.type === QuestionType.SingleChoice) {
        for (const answer of this.question.answers) {
          if (answer.is_right) {
            this.singleChoiceAnswer = answer.id;
          }
        }
      }
      this.isPollQuestion = this.question
        ? !!this.question.is_poll_question
        : false;
    }
    if (this.compact) {
      this.elementRef.nativeElement.classList.add('compact');
    }
  }

  questionAnswer(_, answer) {
    return answer.text;
  }

  ngOnChanges() {
    if (!this.question) return;
    if (this.question.type === QuestionType.SingleChoice) {
      this.hint = this.translator.instant(
        this.question.is_poll_question
          ? 'question.hint.yourAnswer'
          : 'question.hint.chooseAns'
      );
      this.singleChoiceAnswers = this.question.answers.map((answer) => {
        return {
          isRight: answer.is_right,
          text: answer ? answer.text : '',
          value: answer.id,
        };
      });
    }
    if (this.question.type === QuestionType.TrueFalse) {
      this.hint = this.translator.instant('question.hint.chooseStatement');
      this.singleChoiceAnswers = [
        {
          isRight: this.question.is_right,
          text: this.translator.instant('question.label.correct'),
          value: true,
        },
        {
          isRight: !this.question.is_right,
          text: this.translator.instant('question.label.inCorrect'),
          value: false,
        },
      ];
      if (this.trueFalseOrderInverted) {
        this.singleChoiceAnswers = this.singleChoiceAnswers.reverse();
      }
    }
    if (this.question.type === QuestionType.MultipleChoice) {
      this.hint = this.translator.instant(
        this.question.is_poll_question
          ? 'question.hint.yourAnswer'
          : 'question.hint.chooseAll'
      );
    }
  }

  isAnswerSelected(id: number): boolean {
    return (
      this.control &&
      this.control.value &&
      (this.control.value as number[]).includes(id)
    );
  }

  updateSelection(isRightOrAnswerId: boolean | number, enable?: boolean) {
    let selection: AnswerSelection;
    if (this.question.type !== QuestionType.MultipleChoice) {
      selection = isRightOrAnswerId;
    } else {
      const selectedAnswerIds = new Set(this.control.value as number[]);
      if (enable) {
        selectedAnswerIds.add(isRightOrAnswerId as number);
      } else {
        selectedAnswerIds.delete(isRightOrAnswerId as number);
      }
      selection = Array.from(selectedAnswerIds);
    }
    this.control.setValue(selection);
  }
}
