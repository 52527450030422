import {
  Component,
  HostBinding,
  Inject,
  InjectionToken,
  Optional,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

export interface QuickFeedbackQuestion {
  text: string;
  answers?: QuickFeedbackAnswer[];
  textInput?: QuickFeedbackTextInput;
  finished?: boolean;
}

export interface QuickFeedbackTextInput {
  name: string;
  placeholder?: string;
  target: QuickFeedbackQuestion;
  action?: (response: string) => void;
}

export interface QuickFeedbackAnswer {
  text?: string;
  icon?: string;
  tooltip?: string;
  target: QuickFeedbackQuestion;
  action?: () => void;
  warn?: boolean;
}

export const FEEDBACK_PORTAL_DATA = new InjectionToken<{}>('PortalData');

@Component({
  selector: 'qa-quick-feedback-overlay',
  templateUrl: './quick-feedback-overlay.component.html',
  styleUrls: ['./quick-feedback-overlay.component.scss'],
})
export class QuickFeedbackOverlayComponent {
  @HostBinding('class.closing')
  isClosing = false;

  feedbackText = new UntypedFormControl();

  questionsUnwrapped: QuickFeedbackQuestion[] = [];
  activeQuestion: QuickFeedbackQuestion = null;

  constructor(
    @Optional() @Inject(FEEDBACK_PORTAL_DATA) public data: QuickFeedbackQuestion
  ) {
    this.getAllQuestionTexts(data);
    this.showQuestion(this.questionsUnwrapped[0]);
  }

  getAllQuestionTexts(question: QuickFeedbackQuestion) {
    this.questionsUnwrapped.push(question);
    if (question.answers) {
      for (const ans of question.answers) {
        this.getAllQuestionTexts(ans.target);
      }
    } else if (question.textInput) {
      this.getAllQuestionTexts(question.textInput.target);
    }
  }

  showQuestion(question: QuickFeedbackQuestion) {
    this.activeQuestion = question;
    this.feedbackText.setValue('');
    this.activeQuestion.finished = false;
  }

  logInResp(answer: QuickFeedbackAnswer) {
    if (!answer.target.answers && !answer.target.textInput) {
      setTimeout(() => {
        this.isClosing = true;
        if (answer.action) answer.action();
      }, 3500);
    } else {
      if (answer.action) answer.action();
    }
    this.showQuestion(answer.target);
  }

  logInText() {
    if (
      this.activeQuestion &&
      this.activeQuestion.textInput &&
      !this.activeQuestion.textInput.target.answers &&
      !this.activeQuestion.textInput.target.textInput
    ) {
      const actionRef = this.activeQuestion?.textInput.action;
      const textRef = this.feedbackText.value;
      setTimeout(() => {
        this.isClosing = true;
        if (actionRef) {
          actionRef(textRef);
        }
      }, 3500);
    } else {
      if (this.activeQuestion?.textInput?.action) {
        this.activeQuestion.textInput?.action(
          this.feedbackText.value as string
        );
      }
    }
    this.showQuestion(this.activeQuestion.textInput.target);
  }
}
