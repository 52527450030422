import { Component, HostBinding, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { take } from 'rxjs';
import { AppState } from '../app.types';

@Component({
  selector: 'qa-poll-announcement',
  template: `
    <qa-countdown-wrapper [isPollQuestion]="true">
      <div class="icon">
        <mat-icon>question_answer</mat-icon>
        <span translate>common.label.survey</span>
      </div>
    </qa-countdown-wrapper>
  `,
  styleUrls: ['poll-announcement.component.scss'],
})
export class PollAnnouncementComponent implements OnInit {
  @HostBinding('class.disabled')
  done = false;
  state: AppState;

  constructor(store: Store<AppState>) {
    store.pipe(take(1)).subscribe((state) => {
      this.state = state;
    });
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.done = true;
    }, 3500);
  }
}
