<div class="content-listing">
  <div class="breadcrumb" *ngIf="showSidebar">
    <button
      mat-icon-button
      *ngIf="mobileQuery?.matches"
      (click)="toggleSidenav()"
    >
      <mat-icon>menu</mat-icon>
    </button>
    <qa-navigation-breadcrumbs
      [navigationBreadcrumb]="navigationBreadcrumb"
    ></qa-navigation-breadcrumbs>
  </div>
  <mat-drawer-container
    class="mat-nav-container"
    [hasBackdrop]="mobileQuery?.matches ? true : false"
  >
    <mat-drawer
      #sidenav
      [opened]="!mobileQuery?.matches"
      [mode]="mobileQuery?.matches ? 'over' : 'side'"
      *ngIf="showSidebar"
    >
      <div class="sidebar-container">
        <div class="sidebar-header">
          <h1 class="sidebar-title">
            {{ 'common.label.content' | translate }}
          </h1>
          <p class="sidebar-content">
            {{ 'content-desc.description' | translate }}
          </p>
        </div>
        <div class="sidebar-tab">
          <mat-nav-list>
            <ng-container *ngFor="let group of navGroups">
              <ng-container *ngFor="let item of group.items">
                <div class="tab-list">
                  <a
                    [routerLink]="item.link"
                    [routerLinkActive]="item.link ? 'active' : ''"
                    [routerLinkActiveOptions]="{ exact: true }"
                    mat-list-item
                  >
                    <span mat-line
                      >{{ item.label | translate }}
                      <span *ngIf="item.itemCount"
                        >({{ item.itemCount }})</span
                      ></span
                    >
                  </a>
                </div>
              </ng-container>
            </ng-container>
          </mat-nav-list>
        </div>
      </div>
    </mat-drawer>
    <mat-drawer-content>
      <div class="tab-container">
        <router-outlet></router-outlet>
      </div>
    </mat-drawer-content>
  </mat-drawer-container>
</div>
