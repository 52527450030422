import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';

@Component({
  selector: 'qa-edit-block',
  templateUrl: './edit-block.component.html',
  styleUrls: ['./edit-block.component.scss'],
})
export class EditBlockComponent implements OnChanges {
  @Output() editBlockValue = new EventEmitter<string>();
  @Input() label: string;
  @Input() value: string;
  @Input() icon: string;
  @Input() disabled: boolean;

  saveField(event) {
    if (this.disabled) {
      return;
    }
    let value = event.target.innerText;
    value = value.trim();
    this.value = value;
    this.editBlockValue.emit(value);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.disabled) {
      this.disabled = changes.disabled.currentValue;
    }
  }
}
