import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import Auth, { CognitoUser } from '@aws-amplify/auth';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { UserInfo } from './auth.types';
import {
  NewPasswordDialogComponent,
  NewPasswordDialogData,
} from './new-password-dialog.component';
import { ResetPasswordDialogComponent } from './reset-password-dialog.component';
import { TranslateService } from '@ngx-translate/core';
import { SelectEditionDialogComponent } from './select-edition-dialog.component';
import { EditionModel } from '../api/models';

@Injectable()
export class AuthService {
  currentUserInfo$ = new BehaviorSubject<UserInfo>(null);

  constructor(
    private matDialog: MatDialog,
    private matSnackBar: MatSnackBar,
    private router: Router,
    private translator: TranslateService
  ) {
    const isLocalhost = window.location.host.startsWith('localhost');
    Auth.configure({
      Auth: {
        region: environment.cognito.region,
        userPoolId: environment.cognito.userPoolId,
        userPoolWebClientId: environment.cognito.clientId,
        identityPoolId: 'eu-central-1:a9c038dc-ab16-47ca-90d0-c007299dc781',
      },
      cookieStorage: {
        domain: isLocalhost ? 'localhost' : 'quizacademy.io',
        path: '/',
        expires: 365,
        secure: !isLocalhost,
      },
    });
    this.updateCurrentUserInfo();
  }

  currentUserHasGroup(groupName: string) {
    if (
      !this.currentUserInfo$.value ||
      !this.currentUserInfo$.value['cognito:groups']
    ) {
      return false;
    }
    return this.currentUserInfo$.value['cognito:groups'].includes(groupName);
  }

  async getJwtToken() {
    try {
      const session = await Auth.currentSession();
      const idToken = session.getIdToken();
      return idToken.getJwtToken();
    } catch {
      return null;
    }
  }

  isLoggedIn() {
    return !!this.currentUserInfo$.value;
  }

  async login(email: string, password: string) {
    try {
      const user = await Auth.signIn(email, password);
      if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
        await this.setNewPassword(email, password, user);
      }
      const targetUrl = sessionStorage.getItem(`cms-after-login-target`);
      await this.updateCurrentUserInfo();
      if (targetUrl) {
        sessionStorage.removeItem(`cms-after-login-target`);
        this.router.navigateByUrl(targetUrl);
      } else {
        this.router.navigateByUrl('/cms');
      }
    } catch (error) {
      if (error.code === 'PasswordResetRequiredException') {
        this.forgotPassword(email);
      } else {
        throw error;
      }
    }
  }

  async logout() {
    await Auth.signOut();
    await this.updateCurrentUserInfo();
    this.router.navigate(['/login']);
  }

  async forgotPassword(email: string) {
    const snackBarRef = this.matSnackBar.open(
      this.translator.instant('auth.message.sendCode'),
      null,
      { duration: null }
    );
    try {
      await Auth.forgotPassword(email);
    } catch (error) {
      this.matSnackBar.open(error.message);
      return;
    } finally {
      snackBarRef.dismiss();
    }
    const dialogResult = await this.matDialog
      .open(ResetPasswordDialogComponent, { disableClose: true })
      .afterClosed()
      .toPromise();
    if (!dialogResult) return;
    const { verificationCode, newPassword } = dialogResult;
    try {
      await Auth.forgotPasswordSubmit(email, verificationCode, newPassword);
      this.matSnackBar.open(this.translator.instant('auth.message.newPassSet'));
    } catch (error) {
      this.matSnackBar.open(error.message);
    }
  }

  async setNewPassword(email: string, password: string, user: CognitoUser) {
    const dialogConfig: MatDialogConfig<NewPasswordDialogData> = {
      data: { email, password },
      disableClose: true,
    };
    const { newPassword } = await this.matDialog
      .open(NewPasswordDialogComponent, dialogConfig)
      .afterClosed()
      .toPromise();
    await Auth.completeNewPassword(user, newPassword, { email });
    this.matSnackBar.open(this.translator.instant('auth.message.newPassSet'));
  }

  async selectEdition(editions: EditionModel[], pathUrl: string) {
    const dialogConfig: MatDialogConfig<any> = {
      data: { editions, pathUrl },
      disableClose: true,
    };
    await this.matDialog
      .open(SelectEditionDialogComponent, dialogConfig)
      .afterClosed()
      .toPromise();
  }

  private async updateCurrentUserInfo() {
    let userInfo: UserInfo = null;
    try {
      const session = await Auth.currentSession();
      userInfo = session.getIdToken().decodePayload() as UserInfo;
    } catch {}
    this.currentUserInfo$.next(userInfo);
  }
}
