<div class="container">
  <div class="main-header-box">
    <h1 mat-dialog-title class="main-title">
      {{ 'profile.subscription.limits.title' | translate }}
    </h1>
  </div>

  <mat-dialog-content>
    <div class="confirm-end-exam">
      <div class="import-info">
        <p>{{ data.message }}</p>
      </div>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions>
    <div class="secondary-actions">
      <qa-button
        class="mr-button"
        label="profile.subscription.limits.button.understood"
        (submitted)="onUnderstood()"
      ></qa-button>
      <qa-button
        class="mr-button"
        label="profile.subscription.limits.button.contact"
        (submitted)="onRequestContact()"
      ></qa-button>
    </div>
    <qa-button
      [isRaisedButton]="true"
      label="profile.subscription.limits.button.upgrade"
      (submitted)="onUpgradeToStandard()"
    ></qa-button>
  </mat-dialog-actions>
</div>
