<div class="no-entity-info-container">
  <div class="no-entity-info-wrapper">
    <div class="no-entity-info-video">
      <div class="no-entity-info-video-wrapper">
        <iframe
          [src]="videoUrl"
          frameborder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </div>
    </div>
    <div class="find-no-entity-info">
      <ng-scrollbar>
        <h3 translate class="find-no-entity-info-title">
          course.form.description
        </h3>
        <div
          class="find-no-entity-info-wrapper"
          [innerHTML]="listInfo.content | translate"
        >
          content
        </div>
      </ng-scrollbar>
    </div>
    <div class="learn-about-no-entity-info">
      <ng-scrollbar>
        <div class="learn-about-no-entity-info-wrapper">
          <h3
            class="find-no-entity-info-header learn-about-no-entity-info-header"
          >
            {{ listInfo.learnMoreText | translate }}
            <mat-icon>school</mat-icon>
          </h3>
          <div class="learn-no-entity-info-list">
            <mat-nav-list>
              <div class="tab-list" *ngFor="let item of listInfo.links">
                <div mat-list-item>
                  <span class="nav-list-contents">
                    <a
                      mat-line
                      [href]="item.url"
                      target="_blank"
                      class="tab-name"
                      >{{ item.label | translate }}
                      <mat-icon>keyboard_arrow_right</mat-icon></a
                    >
                  </span>
                </div>
              </div>
            </mat-nav-list>
          </div>
        </div>
      </ng-scrollbar>
    </div>
  </div>
</div>
