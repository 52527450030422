import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';
import {
  ShowInfoDialogComponent,
  ShowInfoItem,
} from '../show-info-dialog/show-info-dialog.component';
import { FormField } from './form-field';

/**
 * Form field toggle component
 */
@Component({
  selector: 'qa-form-field-toggle',
  templateUrl: './toggle.component.html',
  styleUrls: ['./toggle.component.scss'],
})
export class FormToggleComponent extends FormField {
  @Output() valueChanged = new EventEmitter<any>();
  @Input() prefixLabel: string;
  @Input() tooltip: string;
  @Input() checked: boolean;
  @Input() isDisabled: boolean;
  @Input() tip?: string;

  constructor(
    private translator: TranslateService,
    private matDialog: MatDialog
  ) {
    super();
  }

  toggle(event) {
    event.stopPropagation();
    this.valueChanged.emit(event.target.checked);
  }

  openDialog() {
    this.matDialog.open<ShowInfoDialogComponent, ShowInfoItem>(
      ShowInfoDialogComponent,
      {
        data: {
          infoData: this.translator.instant(this.tooltip),
          tipData: this.tip ? this.translator.instant(this.tip) : '',
        },
        width: '576px',
        height: 'auto',
        panelClass: 'tooltip-modal',
      }
    );
  }
}
