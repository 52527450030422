import {
  animate,
  group,
  query,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { interval, Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { COUNTDOWN_BEFORE_QUESTION_DURATION } from '../app-constants';
import * as _ from 'lodash';

@Component({
  selector: 'qa-countdown',
  templateUrl: './countdown.component.html',
  styleUrls: ['./countdown.component.scss'],
  animations: [
    trigger('countdown', [
      transition('* => *', [
        group([
          query(
            ':leave',
            [
              animate(
                '.2s ease-out',
                style({
                  opacity: 0,
                  transform: 'translateY(-64px) scale(.5)',
                })
              ),
            ],
            { optional: true }
          ),
          query(
            ':enter',
            [
              style({
                opacity: 0,
                transform: 'translateY(64px) scale(.5)',
              }),
              animate(
                '.2s ease-out',
                style({
                  opacity: 1,
                  transform: 'translateY(0) scale(1)',
                })
              ),
            ],
            { optional: true }
          ),
        ]),
      ]),
    ]),
  ],
})
export class CountdownComponent implements OnInit, OnDestroy {
  countdownSubscription: Subscription;
  currentNumber: number;
  numbers: number[];

  constructor() {}

  ngOnDestroy() {
    if (this.countdownSubscription) {
      this.countdownSubscription.unsubscribe();
    }
  }

  ngOnInit() {
    this.currentNumber = COUNTDOWN_BEFORE_QUESTION_DURATION;
    this.numbers = new Array(COUNTDOWN_BEFORE_QUESTION_DURATION)
      .fill(null)
      .map((_, index) => COUNTDOWN_BEFORE_QUESTION_DURATION - index);
    this.countdownSubscription = interval(1000)
      .pipe(take(COUNTDOWN_BEFORE_QUESTION_DURATION - 1))
      .subscribe(() => (this.currentNumber -= 1));
  }
}
