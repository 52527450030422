import { Injectable } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import * as katex from 'katex';
import { parse } from 'marked';
import * as _sanitizeHTML from 'sanitize-html';
import { ALLOWED_ATTRIBUTES, ALLOWED_TAGS } from './markdown-katex.types';

const sanitizeHTML = (_sanitizeHTML as any).default || _sanitizeHTML;

@Injectable()
export class MarkdownKatexService {
  constructor(private domSanitizer: DomSanitizer) {}

  compile(input: string): SafeHtml {
    let precompiled = this.trimIndentation(input);
    //const mathRegex = /(?:^|[^\\])\$([^\s][^$]*?[^\s])\$/g;
    const mathRegex = /\$([^\s$]*[^$]*?[^\s$])\$/g;
    precompiled = precompiled.replace(mathRegex, (_, math) => {
      const katexLocal: any = katex;
      const compiledKatex = katexLocal.default.renderToString(math, {
        throwOnError: false,
      });
      return compiledKatex;
    });
    let sanitized;
    if (precompiled !== '>') {
      precompiled = parse(precompiled, { breaks: true });
    }

    sanitized = this.sanitize(precompiled);
    return this.domSanitizer.bypassSecurityTrustHtml(sanitized);
  }

  private trimIndentation(markdown: string): string {
    if (!markdown) {
      return '';
    }
    let indentStart: number;
    return markdown
      .split('\n')
      .map((line) => {
        let lineIndentStart = indentStart;
        if (line.length > 0) {
          lineIndentStart = isNaN(lineIndentStart)
            ? line.search(/\S|$/)
            : Math.min(line.search(/\S|$/), lineIndentStart);
        }
        if (isNaN(indentStart)) {
          indentStart = lineIndentStart;
        }
        return !!lineIndentStart ? line.substring(lineIndentStart) : line;
      })
      .join('\n');
  }

  private sanitize(input: string): string {
    return sanitizeHTML(input, {
      allowedTags: ALLOWED_TAGS,
      allowedAttributes: {
        '*': ALLOWED_ATTRIBUTES,
        svg: ['*'],
        path: ['*'],
      },
    });
  }
}
