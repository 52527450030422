import { Component } from '@angular/core';

@Component({
  selector: 'qa-mock-phone',
  templateUrl: './mock-phone.component.html',
  styleUrls: ['./mock-phone.component.scss'],
})
export class MockPhoneComponent {
  date = new Date();

  constructor() {}
}
