import { Injectable } from '@angular/core';
import * as qrcode from 'qrcode';

@Injectable({
  providedIn: 'root',
})
export class QrService {
  constructor() {}

  async getDataURL(text: string): Promise<string> {
    return (await qrcode.toDataURL(text)) as string;
  }

  async getSVG(text: string): Promise<string> {
    const svg: string = await qrcode.toString(text);
    return svg.replace('#000000', 'rgba(0, 0, 0, .65)');
  }
}
