import {
  Component,
  OnDestroy,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { timer, Subscription } from 'rxjs';
import { UtilityService } from 'src/app/utility/utility.service';

@Component({
  selector: 'qa-countdown-timer',
  template: '<mat-icon>timelapse</mat-icon>{{formattedTime}}',
})
export class CountdownTimerComponent implements OnChanges, OnDestroy {
  @Input() minutes = 0;
  @Output() timeOut = new EventEmitter<boolean>();
  counter = 0;
  countDown: Subscription;
  tick = 1000;

  startTime?: number = undefined;

  constructor(private utilityService: UtilityService) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (
      (changes.minutes && changes.minutes.currentValue) ||
      changes.minutes.currentValue === 0
    ) {
      this.minutes = changes.minutes.currentValue;
      this.resetTimer();
    }
  }

  get formattedTime() {
    return this.counter > 0
      ? this.utilityService.toHHMMSS(this.counter)
      : '00:00';
  }

  resetTimer() {
    if (this.countDown) {
      this.countDown.unsubscribe();
    }
    this.countDown = null;
    this.counter = 0;
    this.startTimer();
  }

  startTimer() {
    this.startTime = Math.round(Date.now() / 1000);
    const target = this.startTime + this.minutes * 60;
    this.counter = this.minutes * 60;
    this.countDown = timer(0, this.tick).subscribe(() => {
      if (this.counter < 1) {
        this.counter = 0;
        this.timeOut.emit(true);
        if (this.countDown) {
          this.countDown.unsubscribe();
        }
        return;
      }
      const currentSeconds = Math.round(Date.now() / 1000);
      this.counter = target - currentSeconds;
    });
  }

  ngOnDestroy() {
    if (this.countDown) {
      this.countDown.unsubscribe();
    }
    this.countDown = null;
  }
}
