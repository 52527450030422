import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { FormField } from './form-field';
import { errorDisplayFunction } from 'src/app/app.types';

@Component({
  selector: 'qa-form-field-enum',
  templateUrl: './enum.component.html',
  styleUrls: ['./enum.component.scss'],
})
export class FormSelectEnumComponent extends FormField implements OnInit {
  @Input()
  options: {};

  @Input()
  displayFn: (instance: {}) => string;

  displayControl = new UntypedFormControl();

  displayOptions;
  displayOptionsKeys;

  optionsKeys: string[];

  errorDisplayFunction = errorDisplayFunction;

  constructor() {
    super();
  }

  defaultDisplayFn(input: {}) {
    return '' + input;
  }

  updateDisplayValue() {
    this.displayControl.setValue(this.control.value, { emitEvent: false });
  }

  ngOnInit() {
    this.displayFn = this.displayFn || this.defaultDisplayFn;

    this.displayOptions = this.options;
    this.displayOptionsKeys = Object.keys(this.displayOptions).filter(
      (key, index) => {
        return +key !== index;
      }
    );

    this.control.valueChanges.subscribe(() => this.updateDisplayValue());

    this.displayControl.valueChanges.subscribe((value) => {
      this.control.reset();
      this.control.patchValue(value);
      this.control.markAsTouched();
      this.control.markAsDirty();
    });

    this.updateDisplayValue();
  }
}
