<mat-dialog-content>
  <h2>{{ 'flashCard.attachMedia.title' | translate }}</h2>
  <ng-container *ngIf="!youtubeLink && !link.value">
    <div
      class="dropzone"
      [class.selected]="selectedImage"
      #drop
      ngx-dropzone
      [multiple]="false"
      [accept]="'.png,.jpg,.jpeg,.gif'"
      (change)="onSelectFile($event.addedFiles)"
    >
      <ngx-dropzone-label *ngIf="!selectedImage">
        <div class="drag-content">
          <div class="chooseFile">
            {{ 'flashCard.attachMedia.dropzone.message1' | translate }}
          </div>
          <span>{{ 'common.label.or' | translate }}</span>
          <div class="drag-drop">
            {{ 'flashCard.attachMedia.dropzone.message2' | translate }}
          </div>
          <span class="file-format"
            >{{ 'flashCard.attachMedia.dropzone.message3' | translate }}: png,
            jpeg, gif</span
          >
        </div>
      </ngx-dropzone-label>
      <div class="preview-image" *ngIf="selectedImage">
        <ngx-dropzone-image-preview [file]="selectedImage" [removable]="false">
        </ngx-dropzone-image-preview>
        <div class="file-info">
          <div class="name">{{ selectedImage.name }}</div>
          <span>{{
            'flashCard.attachMedia.dropzone.uploaded' | translate
          }}</span>
        </div>
      </div>
    </div>
    <div class="seperator" [class.disable]="selectedImage || link.value">
      <hr />
      <span>{{ 'findContent.or' | translate }}</span>
      <hr />
    </div>
  </ng-container>
  <div class="youtube" [class.disable]="selectedImage">
    <p>{{ 'flashCard.attachMedia.pasteLink' | translate }}</p>
    <qa-form-field-input
      (enterEvent)="onEnterLink($event)"
      (keyUpEvent)="checkIfLinkCorrect($event)"
      [control]="link"
      [label]="'flashCard.attachMedia.link' | translate"
    >
    </qa-form-field-input>
    <mat-error *ngIf="validateMessage">{{ validateMessage }}</mat-error>
    <div class="selected-video" *ngIf="youtubeLink && !validateMessage">
      <iframe
        [src]="youtubeLink"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      ></iframe>
      <div class="select-time" *ngIf="videoLengthInSec">
        <div class="title">Einsatzmöglichkeiten von Quizacademy</div>
        <form [formGroup]="formGroup">
          <div class="from_to">
            <div>
              <div class="label-view" [matMenuTriggerFor]="menu">
                <mat-label>{{ 'common.label.from' | translate }}</mat-label>
                <div>{{ getTime(formGroup.get('from').value) }}</div>
              </div>
              <mat-menu
                (closed)="onMenuClose()"
                class="time-picker"
                #menu="matMenu"
              >
                <ngx-mat-timepicker
                  [showSeconds]="true"
                  [defaultTime]="defaultStartTime"
                  (click)="$event.stopPropagation()"
                  formControlName="from"
                ></ngx-mat-timepicker>
              </mat-menu>
            </div>
            <div class="seperator">
              <hr />
            </div>
            <div>
              <div class="label-view" [matMenuTriggerFor]="menu1">
                <mat-label>{{ 'common.label.to' | translate }}</mat-label>
                <div>{{ getTime(formGroup.get('to').value) }}</div>
              </div>
              <mat-menu
                (closed)="onMenuClose()"
                class="time-picker"
                #menu1="matMenu"
              >
                <ngx-mat-timepicker
                  [showSeconds]="true"
                  (click)="$event.stopPropagation()"
                  formControlName="to"
                  [defaultTime]="defaultToTime"
                ></ngx-mat-timepicker>
              </mat-menu>
            </div>
          </div>
        </form>
        <div class="errors-msg">
          <mat-error *ngIf="fromErrorMsg">{{ fromErrorMsg }}</mat-error>
          <mat-error *ngIf="startTimeErrorMsg">{{
            startTimeErrorMsg
          }}</mat-error>
          <mat-error *ngIf="endTimeErrorMsg">{{ endTimeErrorMsg }}</mat-error>
        </div>
      </div>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <div class="flex-align-right actions">
    <qa-button mat-dialog-close label="common.button.shutdown"></qa-button>
    <button
      (click)="save()"
      [disabled]="isInvalid || isError"
      color="primary"
      mat-raised-button
      type="submit"
    >
      {{ 'common.button.save' | translate }}
      <mat-icon *ngIf="isLoading"
        ><mat-spinner color="primary" diameter="20"> </mat-spinner
      ></mat-icon>
    </button>
  </div>
</mat-dialog-actions>
