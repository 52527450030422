<div class="label">
  {{ label }}
</div>

<ng-container
  *ngIf="!control?.value?.id && !imageUploadProgress; else fileInfo"
>
  <button
    class="upload-button"
    color="primary"
    mat-button
    tabIndex="-1"
    type="button"
    *ngIf="!processingImage"
  >
    <span class="btn-wrapper" (click)="handleFileUploadAttempt()">
      <mat-icon>add_photo_alternate</mat-icon>
      <span class="btn-text">{{ 'common.label.uploadImage' | translate }}</span>
    </span>
  </button>
  <span *ngIf="processingImage" class="imageProcessing">{{
    'common.label.processImage' | translate
  }}</span>
  <input
    (change)="uploadImage()"
    #fileInput
    accept="image/png, image/jpeg, image/gif"
    hidden
    type="file"
  />
</ng-container>

<ng-template #fileInfo>
  <div class="flex-row flex-align-items-center image-preview">
    <div class="image-name flex-grow">
      <ng-container *ngIf="imageUploadProgress?.name; else image">
        {{ imageUploadProgress.name }}
      </ng-container>
      <ng-template #image>
        <img
          src="https://s3.{{ 's3.mediaBucket.region' | env }}.amazonaws.com/{{
            's3.mediaBucket.name' | env
          }}/{{ imageUrl }}"
          *ngIf="imageUrl"
          class="image"
        />
      </ng-template>
    </div>
    <button
      (click)="removeImage()"
      [matTooltip]="'Löschen'"
      *ngIf="!imageUploadProgress"
      mat-icon-button
      tabindex="-1"
      type="button"
    >
      <mat-icon class="material-icons">delete_outline</mat-icon>
    </button>
    <button
      (click)="showImage()"
      *ngIf="!imageUploadProgress"
      mat-icon-button
      tabindex="-1"
      type="button"
    >
      <mat-icon class="material-icons">zoom_out_map</mat-icon>
    </button>
  </div>
</ng-template>

<ng-container *ngIf="imageUploadProgress">
  <mat-progress-bar [value]="imageUploadProgress.percentage"></mat-progress-bar>
  <div class="progress-label label">{{ imageUploadProgress.percentage }}%</div>
</ng-container>
