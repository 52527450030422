<form (ngSubmit)="submit()" [formGroup]="form">
  <h1 mat-dialog-title>
    {{ 'resetPassword.title' | translate }}
  </h1>
  <mat-dialog-content>
    <p>
      {{ 'resetPassword.text' | translate }}
    </p>
    <mat-form-field>
      <mat-label>
        {{ 'resetPassword.label.code' | translate }}
      </mat-label>
      <input
        #verificationCodeInput
        autocomplete="new-password"
        formControlName="verificationCode"
        matInput
        name="verification-code"
      />
    </mat-form-field>
    <mat-form-field>
      <mat-label>
        {{ 'resetPassword.label.newPassword' | translate }}
      </mat-label>
      <input
        #passwordInput
        autocomplete="new-password"
        formControlName="newPassword"
        matInput
        type="password"
      />
      <button
        (click)="
          passwordInput.type =
            passwordInput.type === 'text' ? 'password' : 'text'
        "
        matTooltip="{{
          (passwordInput.type === 'text'
            ? 'resetPassword.tooltip.hidePassword'
            : 'resetPassword.tooltip.showPassword'
          ) | translate
        }}"
        color="primary"
        mat-icon-button
        matSuffix
        type="button"
      >
        <mat-icon>
          {{ passwordInput.type === 'text' ? 'visibility_off' : 'visibility' }}
        </mat-icon>
      </button>
      <mat-hint>
        {{ 'resetPassword.hint' | translate }}
      </mat-hint>
      <mat-error
        *ngIf="
          form.get('newPassword').invalid && form.get('newPassword').touched
        "
      >
        {{ 'resetPassword.error' | translate }}
      </mat-error>
    </mat-form-field>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button [mat-dialog-close] color="primary" mat-button type="button">
      {{ 'common.button.abort' | translate }}
    </button>
    <button [disabled]="form.invalid" color="primary" mat-button type="submit">
      {{ 'resetPassword.button.changePassword' | translate }}
    </button>
  </mat-dialog-actions>
</form>
