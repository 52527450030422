import {
  Component,
  ViewEncapsulation,
  ElementRef,
  Output,
  EventEmitter,
  Input,
} from '@angular/core';

@Component({
  selector: 'qa-print-help',
  templateUrl: './print-help.component.html',
  styleUrls: ['./print-help.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class PrintHelpComponent {
  @Input() isBrowserSupport = true;
  @Output() print = new EventEmitter();

  constructor(elementRef: ElementRef<HTMLElement>) {
    elementRef.nativeElement.classList.add('qa-print-help');
  }
}
