import { Injectable } from '@angular/core';
import { S3 } from 'aws-sdk';
import {
  CognitoIdentityCredentials,
  config as AWSConfig,
} from 'aws-sdk/global';
import { Progress } from 'aws-sdk/lib/request';
import { Observable, of, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { Media } from 'src/app/api/models';
import { UploadProgress } from 'src/app/app.types';
import { environment } from 'src/environments/environment';
import { v1 as uuid } from 'uuid';
import { MediaService as MediaApiService } from '../../api/services';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class MediaService {
  s3: S3;

  constructor(
    private api: MediaApiService,
    private matSnackBar: MatSnackBar,
    private translator: TranslateService
  ) {
    this.init();
  }

  init() {
    AWSConfig.update({
      region: environment.s3.mediaBucket.region,
      credentials: new CognitoIdentityCredentials({
        IdentityPoolId: environment.s3.mediaBucket.identityPoolId,
      }),
    });
    this.s3 = new S3({
      apiVersion: '2006-03-01',
      params: { Bucket: environment.s3.mediaBucket.name },
      region: environment.s3.mediaBucket.region,
    });
  }

  create(file: File): Observable<UploadProgress | Media> {
    return this.upload(file).pipe(
      switchMap((progress) => {
        progress = progress as UploadProgress;
        if (progress.percentage !== 100 || !progress.data) {
          return of(progress);
        }
        const media: Media = {
          file_ext: progress.fileExt,
          uuid: progress.key,
        };
        return this.api.createMedia({ body: media });
      }),
      catchError((error) => {
        this.matSnackBar.open(
          this.translator.instant('common.message.uploadError')
        );
        return throwError(error);
      })
    ) as Observable<UploadProgress | Media>;
  }

  private upload(file: File): Observable<UploadProgress> {
    const key = uuid();
    const fileExt = file.name.replace(/^.*\.([^.]+)$/, '$1');
    return new Observable((observer) => {
      const upload = this.s3.upload(
        {
          Body: file,
          Key: `${key}.${fileExt}`,
          Bucket: environment.s3.mediaBucket.name,
        },
        (err, data) => {
          if (err) {
            return observer.error(err);
          }
          observer.next({
            key,
            name: file.name,
            fileExt,
            progress: {
              loaded: file.size,
              total: file.size,
            },
            data,
            percentage: 100,
          });
          observer.complete();
        }
      );
      upload.on('httpUploadProgress', (progress: Progress) => {
        observer.next({
          key,
          name: file.name,
          fileExt,
          progress,
          data: null,
          percentage: Math.ceil((100 / progress.total) * progress.loaded),
        });
      });
    });
  }
}
