import {
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Subscription, forkJoin } from 'rxjs';
import {
  CoursesService,
  QuestionsService,
  QuizzesService,
} from 'src/app/api/services';
import { DateRangePickerService } from '../../common/form-field/date-range-picker.service';
import { TranslateService } from '@ngx-translate/core';
import * as dayjs from 'dayjs';
import { ChartComponent } from '../../common/ui/charts/chart.component';
import { environment } from 'src/environments/environment';
import {
  DailyUsageStatistic,
  QuestionStats,
  QuizModel,
  QuizStatistics,
} from 'src/app/api/models';
import { DateRange } from 'src/app/app.types';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { QuestionsOverview } from '../../common/questions-overview/question-overview-list/question-overview-list.component';
const EXCEL_TYPE =
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';
import * as utc from 'dayjs/plugin/utc';

@Component({
  selector: 'qa-quiz-statistics',
  templateUrl: './quiz-statistics.component.html',
  styleUrls: ['./quiz-statistics.component.scss'],
})
export class QuizStatisticsComponent implements OnInit, OnDestroy {
  @Input() courseId: number = null;
  @Input() quiz: QuizModel = null;
  chartData: any;
  chartOptions: {};
  questionStatistics: QuestionStats[];
  questionsOverview: QuestionsOverview[] = [];
  dateRangeSubscription: Subscription;
  loading = false;
  rows: Array<{ x: string; y: number; id: number }> = [];
  dateRange: DateRange;
  showQuestionStats: boolean;
  currentItemIndex = 0;
  question: QuestionsOverview;
  exportData = [];

  @ViewChild('chart') chartRef: ChartComponent;

  constructor(
    private questionsService: QuestionsService,
    private quizzes: QuizzesService,
    private courses: CoursesService,
    private dateRangePickerService: DateRangePickerService,
    elementRef: ElementRef<HTMLElement>,
    private translator: TranslateService
  ) {
    dayjs.extend(utc);
    elementRef.nativeElement.classList.add('qa-quiz-statistics');
  }

  ngOnInit() {
    this.dateRangeSubscription =
      this.dateRangePickerService.dateRange.subscribe((dateRange) => {
        this.dateRange = dateRange;
        this.fetchData();
      });
  }

  fetchData() {
    if (!this.courseId && !this.quiz) {
      return null;
    }
    this.getStats();
  }

  async getStats() {
    let request$;
    const range = {
      end: this.dateRange?.to
        ? dayjs.utc(this.dateRange.to).format('YYYY-MM-DD') + 'T23:59:59'
        : null,
      start: this.dateRange?.from
        ? dayjs.utc(this.dateRange.from).utc().format('YYYY-MM-DD') +
          'T00:00:00'
        : null,
    };
    if (this.courseId) {
      request$ = this.courses.getQuizStatisticsInCourse({
        id: this.courseId,
        quiz_id: this.quiz.id,
        ...range,
      });
    } else if (this.quiz && this.quiz?.id) {
      request$ = this.quizzes.getQuizStatistics({
        id: this.quiz.id,
        ...range,
      });
    }
    if (request$) {
      this.loading = true;
      const stats = await request$.toPromise();
      this.questionStatistics = stats.question_stats;
      this.questionsOverview = this.questionStatistics.map((qs) => {
        return {
          ...qs.question,
          num_unanswered: 0,
          num_correct_answers: qs.num_correct,
          num_incorrect_answer: qs.num_answers - qs.num_correct,
          total_result: qs.num_answers,
        };
      });
      this.generateExportData();
      await this.updateChart(stats, this.dateRange);
      this.loading = false;
    }
  }

  generateExportData() {
    const data = [];
    if (this.questionStatistics) {
      this.questionStatistics.forEach((stat) => {
        let ratio = '0.00%/0.00%';
        if (stat.num_answers) {
          ratio = `${((stat.num_correct * 100) / stat.num_answers).toFixed(
            2
          )}%/${(100 - (stat.num_correct * 100) / stat.num_answers).toFixed(
            2
          )}%`;
        }
        const item = {
          id: stat.question.id,
          question: stat.question.text,
          total_answers: stat.num_answers,
          correct: stat.num_correct,
          incorrect: stat.num_answers - stat.num_correct,
          ratio,
        };
        item.incorrect = stat.num_answers - stat.num_correct;
        data.push(item);
      });
    }
    this.exportData = data;
  }

  downloadExcel() {
    const fileName = `${this.quiz.name}_Statistik_${dayjs(
      this.dateRange.from
    ).format('YYYY-MM-DD')}_${dayjs(this.dateRange.to).format('YYYY-MM-DD')}`;
    this.exportAsExcelFile(this.exportData, fileName);
  }

  public exportAsExcelFile(json: any[], excelFileName: string): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    const wscols = [
      { wch: 8 },
      { wch: 100 },
      { wch: 12 },
      { wch: 10 },
      { wch: 10 },
      { wch: 15 },
    ];
    worksheet['!cols'] = wscols;
    const workbook: XLSX.WorkBook = {
      Sheets: { data: worksheet },
      SheetNames: ['data'],
    };
    const excelBuffer: any = XLSX.write(workbook, {
      bookType: 'xlsx',
      type: 'buffer',
    });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }

  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE,
    });
    FileSaver.saveAs(data, fileName + EXCEL_EXTENSION);
  }

  ngOnDestroy() {
    this.dateRangeSubscription.unsubscribe();
  }

  async updateChart(statistics: QuizStatistics, dateRange: DateRange) {
    const fromDate = dayjs(dateRange.from);
    const toDate = dayjs(dateRange.to).add(1, 'days');
    const table = {};
    const difference = toDate.diff(fromDate, 'days');
    for (let i = 0; i < difference; i++) {
      const date = fromDate.clone().add(i, 'days').format('YYYY-MM-DD');
      table[date] = 0;
    }
    statistics.usage.forEach((row: DailyUsageStatistic) => {
      const date = dayjs.utc(row.day).format('YYYY-MM-DD');
      table[date] = row.value;
    });
    const rows = Object.keys(table).map((date) => {
      return {
        x: dayjs(date),
        y: table[date],
      };
    });

    this.chartOptions = {
      legend: { display: false },
      scales: {
        x: {
          ticks: {
            autoSkip: true,
            maxRotation: 0,
            minRotation: 0,
            maxTicksLimit: 11,
          },
          grid: {
            display: false,
            z: -1,
          },
        },
        y: {
          scaleLabel: {
            display: false,
            labelString: this.translator.instant('statistics.graph.title'),
          },
          ticks: {
            min: 0,
            precision: 0,
            beginAtZero: true,
          },
          gridLines: {
            drawBorder: false,
          },
        },
      },
      maintainAspectRatio: false,
    };
    this.chartData = {
      labels: rows.map((r) => this.formatDate(r.x)),
      datasets: [
        {
          label: this.translator.instant('question.title'),
          backgroundColor: environment.colors.primary,
          borderColor: environment.colors.primary,
          hoverBackgroundColor: environment.colors.primaryLight,
          data: rows.map((r) => r.y),
          borderWidth: 2,
          pointBorderWidth: 0,
          pointHoverBackgroundColor: '#fff',
          pointBackgroundColor: environment.colors.primary,
          pointBorderColor: environment.colors.primary,
          fill: false,
          hidden: false,
        },
      ],
    };
  }

  private formatDate(date: dayjs.Dayjs): string {
    return dayjs(date || '01.01.1970').format('DD MMM YYYY');
  }

  getCorrectRatio(stat: QuestionStats) {
    return stat && stat.num_answers
      ? `${((stat.num_correct * 100) / stat.num_answers).toFixed(2)}%`
      : '0%';
  }

  getCorrectAnswers(ratio: number) {
    return ratio ? Math.floor(ratio) : 0;
  }

  getQuestionStatistic(questionResult: {
    result: QuestionsOverview;
    index: number;
  }) {
    this.loading = true;
    const requests = [
      this.courses.getQuestionsStatisticsInCourse({
        id: this.courseId,
        quiz_id: this.quiz.id,
        question_id: questionResult.result.id,
        end: dayjs(this.dateRange.to).format('YYYY-MM-DD') + 'T23:59:59',
        start: dayjs(this.dateRange.from).format('YYYY-MM-DD') + 'T00:00:00',
      }),
      this.questionsService.getQuestionById({ id: questionResult.result.id }),
    ];
    forkJoin(requests).subscribe(
      (response: any) => {
        const stat = response[0];
        const question = {
          ...response[1],
          num_unanswered: 0,
          num_correct_answers: stat.num_correct,
          num_incorrect_answer: stat.num_answers - stat.num_correct,
          total_result: stat.num_answers,
        };
        question.answers = stat.answer_stats.map((as) => {
          return {
            ...as.answer,
            num_selected: as.num_selections,
          };
        });
        this.question = question;
        this.currentItemIndex = questionResult.index;
        this.showQuestionStats = true;
        this.loading = false;
      },
      () => (this.loading = false)
    );
  }
}
