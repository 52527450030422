import { Injectable } from '@angular/core';
import { Exam } from 'src/app/api/models';
import { CoursesService, ExamsService } from 'src/app/api/services';
import { Edit } from '../edit.types';
import { MatDialog } from '@angular/material/dialog';
import { MarkdownInfoDialogComponent } from '../../common/form-field/markdown-info-dialog.component';
import { TranslateService } from '@ngx-translate/core';
import { Observable, map, tap } from 'rxjs';
import { UserJourneyService } from '../../pages/onboarding-steps/user-journey.service';
import { SubscriptionLimit } from '../../common/dialogs/subscription-limit-warning/subscription-limit-warning.component';
import { SubscriptionLimitService } from '../../common/dialogs/subscription-limit-warning/subscription-limit.service';

@Injectable()
export class ExamService extends Edit.ConfigService<Exam> {
  constructor(
    examsService: ExamsService,
    matDialog: MatDialog,
    translator: TranslateService,
    coursesService: CoursesService,
    userJourney: UserJourneyService,
    subscriptionLimit: SubscriptionLimitService
  ) {
    super({
      itemName: translator.instant('exam.titleChallenge'),
      id: 'exam',
      deleteConfirmText: translator.instant('exam.deleteConfirmText'),
      deleteConfirmTitle: translator.instant('exam.deleteConfirmTitle'),
      fields: [
        {
          key: 'name',
          label: 'Name',
          required: true,
        },
        {
          key: 'description',
          label: translator.instant('exam.form.description'),
          multiLine: true,
          hideIf: () => true,
          suffix: {
            icon: 'code',
            text: translator.instant('exam.form.descSuffix'),
            handler: () => {
              matDialog.open(MarkdownInfoDialogComponent, { width: '640px' });
            },
          },
        },
        {
          key: 'submission_message',
          label: translator.instant('exam.form.message'),
          multiLine: true,
          hideIf: () => true,
          suffix: {
            icon: 'code',
            text: translator.instant('exam.form.descSuffix'),
            handler: () => {
              matDialog.open(MarkdownInfoDialogComponent, { width: '640px' });
            },
          },
        },
      ],
      handlers: {
        update: (newValue) => {
          return examsService
            .updateExam({
              id: newValue.id,
              body: newValue,
            })
            .toPromise();
        },
        create: (newValue) => {
          const courseId = this.config?.params?.courseId;
          let saveRequest$: Observable<Exam>;
          if (!courseId) {
            saveRequest$ = examsService.createExam({ body: newValue });
          } else {
            const query = {
              id: courseId,
              body: newValue,
            };
            saveRequest$ = coursesService.createExamInCourse(query).pipe(
              map((exam) => {
                return exam;
              })
            );
          }
          return saveRequest$
            .pipe(
              tap(() => {
                userJourney.set('createdExam');
              }),
              map((quiz) => {
                return quiz;
              })
            )
            .toPromise();
        },
        delete: (newValue) => {
          return examsService.deleteExam({ id: newValue.id }).toPromise();
        },
      },
      afterOpened: () =>
        subscriptionLimit.openDialog(SubscriptionLimit.EXAM_COUNT),
    });
  }
}
