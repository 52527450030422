<h1 mat-dialog-title>{{ data.title }}</h1>
<mat-dialog-content>
  {{ data.text }}
  <qa-form-field-input [control]="data.control"></qa-form-field-input>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button
    *ngFor="let button of data.buttons"
    [color]="button.color"
    mat-button
    [mat-dialog-close]="button.value"
  >
    <mat-icon *ngIf="button.icon">{{ button.icon }}</mat-icon>
    {{ button.text }}
  </button>
</mat-dialog-actions>
