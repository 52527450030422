import {
  Component,
  ElementRef,
  Inject,
  ViewEncapsulation,
} from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'qa-select-edition-dialog',
  templateUrl: './select-edition-dialog.component.html',
  styleUrls: ['./select-edition-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SelectEditionDialogComponent {
  edition = environment.edition;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    elementRef: ElementRef<HTMLElement>
  ) {
    elementRef.nativeElement.classList.add('qa-select-edition-dialog');
  }

  get pathUrl(): string {
    return this.data?.pathUrl ?? 'login';
  }
}
