import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import {
  BarController,
  BarElement,
  CategoryScale,
  Chart,
  ChartEvent,
  ChartType,
  LinearScale,
  Tooltip,
} from 'chart.js';

@Component({
  selector: 'qa-chart',
  templateUrl: './chart.component.html',
  styleUrls: ['./chart.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ChartComponent {
  @Input()
  chartType: ChartType = 'line';

  @Input()
  data: any;

  @Input()
  options: any = {};

  @Input()
  chartHeight = '100%';

  @Output() chartClick = new EventEmitter<{
    event?: ChartEvent;
    active?: {}[];
  }>();

  constructor(elementRef: ElementRef<HTMLElement>) {
    elementRef.nativeElement.classList.add('qa-chart');
    Chart.register(
      CategoryScale,
      LinearScale,
      BarController,
      BarElement,
      Tooltip
    );
    Chart.defaults.plugins.legend.display = false;

    Chart.defaults.plugins.tooltip.enabled = true;
    Chart.defaults.plugins.tooltip.backgroundColor = '#ffffff';
    Chart.defaults.plugins.tooltip.titleColor = 'rgba(149, 193, 31)';
    Chart.defaults.plugins.tooltip.titleFont = {
      family: 'Lato',
      size: 12,
      style: 'normal',
      weight: 'bold',
      lineHeight: 1,
    };
    Chart.defaults.plugins.tooltip.titleAlign = 'center';
    Chart.defaults.plugins.tooltip.bodyAlign = 'center';
    Chart.defaults.plugins.tooltip.titleMarginBottom = 12;
    Chart.defaults.plugins.tooltip.bodyColor = '#000000';
    Chart.defaults.plugins.tooltip.cornerRadius = 3;
    Chart.defaults.plugins.tooltip.borderColor = 'rgba(0, 0, 0, 0.12)';
    Chart.defaults.plugins.tooltip.borderWidth = 1;
    Chart.defaults.plugins.tooltip.padding = 8;
    Chart.defaults.plugins.tooltip.displayColors = false;
  }

  public chartClicked(event: { event?: ChartEvent; active?: {}[] }): void {
    this.chartClick.emit(event);
  }
}
