<div class="slider-info" [class.disabled]="isDisabled">
  <div class="form-group">
    <span *ngIf="prefixLabel" class="prefix-label">
      {{ prefixLabel }}
    </span>
    <div class="form-field true-false-toggle">
      <label class="switch">
        <input
          *ngIf="control; else noControl"
          [formControl]="control"
          type="checkbox"
          (change)="toggle($event)"
        />
        <ng-template #noControl>
          <input
            (change)="toggle($event)"
            [checked]="checked"
            type="checkbox"
            [disabled]="isDisabled"
          />
        </ng-template>
        <span class="slider round"></span>
      </label>
    </div>
    <div class="field-label true-false-label">
      {{ label }}
    </div>
    <mat-icon *ngIf="tooltip" class="info-icon" (click)="openDialog()">
      info
    </mat-icon>
  </div>
</div>
