import { Component, Input } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { FlashCardModel, Media, QuestionModel } from 'src/app/api/models';
import { DialogService } from 'src/app/cms/common/dialog.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'qa-image-display',
  templateUrl: './image-display.component.html',
  styleUrls: ['./image-display.component.scss'],
})
export class ImageDisplayComponent {
  @Input() model: QuestionModel | FlashCardModel;

  mediaBucket = environment.s3.mediaBucket;

  constructor(private dialog: DialogService, private sanitizer: DomSanitizer) {}

  showImage(media: Media): void {
    const url = `https://s3.${environment.s3.mediaBucket.region}.amazonaws.com/${environment.s3.mediaBucket.name}/${media.uuid}.${media.file_ext}`;
    this.dialog.showImage(url);
  }

  get youtubeLink(): SafeUrl | undefined {
    const url = (this.model as FlashCardModel)?.youtube_link_question;
    if (!url) return undefined;
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
}
