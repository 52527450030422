<div class="header">
  <div class="header-inner">
    <div class="header-inner-top">
      <div class="flex-row flex-align-items-center">
        <div class="logo-container" *ngIf="logoUrl()">
          <img class="logo" [src]="logoUrl()" />
        </div>
        <div class="nav-breadcrumbs">
          <!-- <mat-icon
            class="home-icon"
            [routerLink]="navigationBreadcrumb?.homeUrl"
            >home</mat-icon>
          <mat-icon
            *ngIf="
              !navigationBreadcrumb?.breadcrumbs.length &&
              !navigationBreadcrumb?.currentPage
            "
            >chevron_right
          </mat-icon> -->
          <ng-container
            *ngFor="
              let breadcrumb of navigationBreadcrumb?.breadcrumbs;
              let i = index
            "
          >
            <mat-icon
              class="cursor-pointer"
              (click)="redirect(breadcrumb?.route)"
              >keyboard_backspace</mat-icon
            >
            <div class="anchor" (click)="redirect(breadcrumb?.route)">
              {{ 'common.label.backTo' | translate }}
              {{ breadcrumb.text | truncate : 30 }}
            </div>
          </ng-container>
          <mat-icon class="rightarr" *ngIf="navigationBreadcrumb?.currentPage"
            >chevron_right</mat-icon
          >
          <div class="last">
            {{ navigationBreadcrumb?.currentPage }}
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
