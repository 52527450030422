<div [class.faded]="loading" class="chart-container">
  <qa-chart
    [data]="chartData"
    [options]="chartOptions"
    (chartClick)="chartClicked($event)"
    *ngIf="chartData"
    chartType="bar"
    #chart
  >
  </qa-chart>
</div>
