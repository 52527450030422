import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { AbstractEditComponent } from './abstract-edit.component';
import { DialogReturnTypeAction, DialogReturnType } from 'src/app/app.types';

@Component({
  selector: 'qa-can-deactivate-dialog',
  templateUrl: './can-deactivate-dialog.component.html',
  styleUrls: ['./can-deactivate-dialog.component.scss'],
})
export class CanDeactivateDialogComponent {
  dialogReturnTypeAction = DialogReturnTypeAction;

  constructor(
    public dialogRef: MatDialogRef<
      AbstractEditComponent,
      DialogReturnType<void>
    >
  ) {}
}
