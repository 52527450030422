import { TranslateService } from '@ngx-translate/core';
import html2canvas from 'html2canvas';
import autoTable from 'jspdf-autotable';
import { FlashCardModel } from 'src/app/api/models';
import { environment } from 'src/environments/environment';
import { PDFGenerator } from '../../cms/common/pdf-generator/pdf-generator';
import { MarkdownKatexService } from '../markdown-katex/markdown-katex.service';

export class FlashCardPDFGenerator extends PDFGenerator {
  constructor(
    private flashCards: FlashCardModel[],
    private markdownKatex: MarkdownKatexService,
    private format: 'a6' | 'glossar' | 'table',
    private translator: TranslateService
  ) {
    super();
  }

  preloadData() {}

  async generate() {
    this.qaLogo = await this.loadImage('/assets/logo.png');
    switch (this.format) {
      case 'a6':
        await this.a6Format();
        break;
      case 'glossar':
        await this.glossarFormat();
        break;
      case 'table':
        await this.tableFormat();
        break;
      default:
        break;
    }
    return this.doc;
  }

  private async glossarFormat() {
    const markdownBuffer = [];
    autoTable(this.doc, {
      tableWidth: this.WIDTH - 2 * this.margin[1],
      rowPageBreak: 'avoid',
      headStyles: {
        fillColor: this.colorCode || environment.colors.primary,
        textColor: 'white',
      },
      columnStyles: {
        0: { textColor: '#5a5a5a', fillColor: '#ffffff', minCellWidth: 15 },
        1: { textColor: '#ffffff', fillColor: '#ffffff' },
        2: { textColor: '#ffffff', fillColor: '#ffffff' },
      },
      bodyStyles: { font: 'Lato-Regular', fillColor: '#ffffff' },
      head: [
        [
          'Nr.',
          this.translator.instant('flashCard.form.question'),
          this.translator.instant('flashCard.form.answer'),
        ],
      ],
      didDrawCell: (data) => {
        if (data.section === 'body' && data.column.index > 0) {
          const page = this.doc.getCurrentPageInfo().pageNumber;
          markdownBuffer.push(
            new Promise(async (resolve) => {
              const canv = await this.generateMarkdown(
                data.cell.raw as string,
                data.cell.width - data.cell.padding('horizontal'),
                0
              );
              resolve({
                page,
                width: data.cell.width - data.cell.padding('horizontal'),
                x: data.cell.getTextPos().x,
                y: data.cell.getTextPos().y,
                canvas: canv,
              });
            })
          );
        }
      },
      body: this.flashCards.map((card, i) => {
        return [i + 1, card.text, card.answer];
      }),
    });
    const rendered = await Promise.all(markdownBuffer);
    rendered.forEach((md) => {
      this.doc.setPage(md.page);
      const height = md.width / (md.canvas.width / md.canvas.height);
      this.doc.addImage(md.canvas, 'image/png', md.x, md.y, md.width, height);
    });
  }

  private async a6Format() {
    let top = true;
    for (const card of this.flashCards) {
      await this.printFlashCard(card);
      if (top) {
        this.doc.line(5, this.HEIGHT / 2, this.WIDTH - 5, this.HEIGHT / 2);
        this.lastPos = this.HEIGHT / 2;
      } else {
        this.break();
        this.lastPos = 0;
      }
      top = !top;
    }
  }

  private async printFlashCard(card: FlashCardModel) {
    const imageWidth = 50;
    let image = null;
    if (card.youtube_link_question) {
      image = await this.loadImage('/assets/images/yt-video-placeholder@1.png');
    } else if (card.media?.uuid) {
      image = await this.loadImage(
        `https://s3.${environment.s3.mediaBucket.region}.amazonaws.com/${environment.s3.mediaBucket.name}/` +
          card.media.uuid +
          '.' +
          card.media.file_ext
      );
    }
    const textConfig = {
      align: 'left',
      baseline: 'bottom',
      maxWidth:
        !card.video_question_or_answer && image
          ? this.HEIGHT / 2 - 25 - imageWidth
          : this.HEIGHT / 2 - 25,
    };
    this.doc.setDrawColor('#efefef');
    this.doc.line(
      this.WIDTH / 2,
      this.lastPos + 5,
      this.WIDTH / 2,
      this.lastPos + this.HEIGHT / 2 - 5
    );
    this.doc.addImage(
      this.qaLogo,
      'image/png',
      this.WIDTH / 2 + 3.5,
      this.lastPos + this.HEIGHT / 2 - 26,
      15,
      15 / (this.qaLogo.width / this.qaLogo.height),
      '',
      'MEDIUM',
      -90
    );
    this.doc.addImage(
      this.qaLogo,
      'image/png',
      this.WIDTH / 2 - 3.5,
      this.lastPos + this.HEIGHT / 2 - 11,
      15,
      15 / (this.qaLogo.width / this.qaLogo.height),
      '',
      'MEDIUM',
      90
    );
    this.adaptiveSize(card.text, textConfig);
    const textDims = this.doc.getTextDimensions(card.text, textConfig);
    this.doc.setTextColor('#ffffff');
    this.doc.text(
      card.text,
      this.WIDTH / 4 + this.doc.getTextDimensions('A').h - textDims.h / 2,
      this.lastPos +
        this.HEIGHT / 2 -
        5 -
        (!card.video_question_or_answer && image ? imageWidth : 0) -
        10,
      { ...textConfig, angle: 90 } as any
    );
    await this.a6Markdown(
      card.text,
      this.WIDTH / 4,
      this.lastPos +
        this.HEIGHT / 2 -
        5 -
        (!card.video_question_or_answer && image ? imageWidth : 0) -
        10,
      textConfig.maxWidth,
      textDims.h,
      -90
    );
    const answerConfig = {
      align: 'left',
      baseline: 'bottom',
      maxWidth:
        !card.video_question_or_answer || !image
          ? this.HEIGHT / 2 - 25
          : this.HEIGHT / 2 - 25 - imageWidth,
    };
    this.adaptiveSize(card.answer, answerConfig);
    const answerDims = this.doc.getTextDimensions(card.answer, answerConfig);
    this.doc.text(
      card.answer,
      this.WIDTH * (3 / 4) -
        this.doc.getTextDimensions('A').h +
        answerDims.h / 2,
      this.lastPos +
        15 +
        (card.video_question_or_answer && image ? imageWidth : 0),
      { ...answerConfig, angle: -90 } as any
    );
    await this.a6Markdown(
      card.answer,
      this.WIDTH * (3 / 4),
      this.lastPos +
        answerConfig.maxWidth +
        15 +
        (card.video_question_or_answer && image ? imageWidth : 0),
      answerConfig.maxWidth,
      answerDims.h,
      90
    );

    if (image) {
      const scale = Math.min(
        imageWidth / image.width,
        (this.WIDTH / 2 - 30) / image.height
      );
      const width = image.width * scale;
      const height = image.height * scale;
      const x = card.video_question_or_answer
        ? this.WIDTH * (3 / 4) - height / 2
        : this.WIDTH / 4 + height / 2;
      const y = card.video_question_or_answer
        ? this.lastPos + 10 - height
        : this.lastPos + this.HEIGHT / 2 - 10 - height;
      this.doc.addImage(
        image,
        'image/jpeg',
        x,
        y,
        width,
        height,
        '',
        'MEDIUM',
        card.video_question_or_answer ? -90 : 90
      );
      if (card.youtube_link_question) {
        this.doc.link(
          card.video_question_or_answer
            ? this.WIDTH * (3 / 4) - height / 2
            : this.WIDTH / 4 - height / 2,
          card.video_question_or_answer
            ? this.lastPos + 10
            : this.lastPos + this.HEIGHT / 2 - 10 - width,
          height,
          width,
          {
            url: card.youtube_link_question,
          }
        );
      }
    }
  }

  private async a6Markdown(text, x, y, width, height, rotation: 90 | -90) {
    const canvas = await this.generateMarkdown(text, width, rotation);
    this.doc.addImage(
      canvas,
      'image/png',
      x - height / 2,
      y - width,
      height,
      width,
      null,
      'MEDIUM'
    );
  }

  private async tableFormat() {
    // Prerender images
    const images = await Promise.all(
      this.flashCards.map((flash) => {
        return new Promise<any>(async (resolve) => {
          if (flash.media?.uuid) {
            resolve(
              await this.loadImage(
                `https://s3.${environment.s3.mediaBucket.region}.amazonaws.com/${environment.s3.mediaBucket.name}/` +
                  flash.media.uuid +
                  '.' +
                  flash.media.file_ext
              )
            );
          } else {
            resolve(null);
          }
        });
      })
    );

    // Prerender markdown
    this.doc.setFontSize(10.2);
    const markdown = await Promise.all(
      this.flashCards.map((flash, i) => {
        return new Promise<any>(async (resolve) => {
          const question = await this.generateMarkdown(
            flash.text,
            this.WIDTH -
              2 * this.margin[1] -
              (images[i] && !flash.video_question_or_answer ? 50 : 0),
            0
          );
          const answer = await this.generateMarkdown(
            flash.answer,
            this.WIDTH -
              2 * this.margin[1] -
              30 -
              (images[i] && flash.video_question_or_answer ? 50 : 0),
            0
          );
          const explanation = await this.generateMarkdown(
            flash.explanation,
            this.WIDTH - 2 * this.margin[1] - 30,
            0,
            '#f5f5f5'
          );
          resolve({
            width: this.WIDTH - 2 * this.margin[1] - 30 - 5,
            canvas: [question, answer, explanation],
          });
        });
      })
    );
    autoTable(this.doc, {
      tableWidth: this.WIDTH - 2 * this.margin[1],
      rowPageBreak: 'avoid',
      columnStyles: {
        0: {
          textColor: '#ffffff',
          fillColor: this.colorCode || environment.colors.primary,
          cellWidth: 30,
        },
      },
      bodyStyles: { font: 'Lato-Regular', fillColor: '#ffffff' },
      didParseCell: (data) => {
        if (data.row.index % 5 === 0) {
          data.row.cells[0].styles.fillColor = '#ffffff';
          data.row.cells[0].styles.cellPadding = { top: 10, bottom: 10 };
          data.row.cells[0].colSpan = 2;
        }
        if (data.section === 'body' && Array.isArray(data.cell.raw)) {
          data.cell.text =
            data.cell &&
            data.cell.raw &&
            data.cell.raw.length > 1 &&
            data.cell.raw[1]
              ? data.cell.raw[1].split(/\n/gm)
              : [];
          if (data.cell.raw[0]) {
            data.cell.styles.cellPadding = { right: 55 };
          }
        }
      },
      willDrawCell: (data) => {
        if (
          data.section === 'body' &&
          data.column.index === 0 &&
          [0, 1, 2].includes(data.row.index % 5)
        ) {
          const width =
            data.row.cells[data.row.index % 5 === 0 ? 0 : 1].width -
            (Array.isArray(data.cell.raw) && data.cell.raw[0] ? 55 : 0);
          const md =
            data.row.index % 5 === 0
              ? markdown[Math.floor(data.row.index / 5)].canvas[0]
              : data.row.index % 5 === 1
              ? markdown[Math.floor(data.row.index / 5)].canvas[1]
              : markdown[Math.floor(data.row.index / 5)].canvas[2];
          const height = width / (md.width / md.height);
          data.row.height = Math.max(
            height + data.cell.padding('vertical'),
            data.row.height
          );
        }
      },
      didDrawCell: (data) => {
        if (
          (data.section === 'body' &&
            data.column.index > 0 &&
            [1, 2].includes(data.row.index % 5)) ||
          (data.column.index === 0 && data.row.index % 5 === 0)
        ) {
          const x = data.cell.getTextPos().x;
          const y = data.cell.getTextPos().y;
          const md =
            data.column.index === 0
              ? markdown[Math.floor(data.row.index / 5)].canvas[0]
              : data.row.index % 5 === 1
              ? markdown[Math.floor(data.row.index / 5)].canvas[1]
              : markdown[Math.floor(data.row.index / 5)].canvas[2];
          const width =
            data.cell.width -
            (Array.isArray(data.cell.raw) && data.cell.raw[0] ? 55 : 0);
          const height = width / (md.width / md.height);
          try {
            this.doc.addImage(md, 'image/png', x, y, width, height);
          } catch (err) {
            console.warn(err);
          }
        }
        if (Array.isArray(data.cell.raw) && data.cell.raw[0]) {
          const img = data.cell.raw[0] as unknown as HTMLCanvasElement;
          const scale = Math.min(
            50 / img.width,
            (data.row.height - 2) / img.height
          );
          this.doc.addImage(
            img,
            'image/jpeg',
            data.cell.x + data.cell.width - scale * img.width,
            data.cell.y + 2 + data.row.height / 2 - (img.height * scale) / 2,
            scale * img.width,
            scale * img.height,
            '',
            'MEDIUM'
          );
        }
      },
      body: this.flashCards.reduce((acc, card, i) => {
        return acc.concat([
          [
            [
              card.video_question_or_answer ? null : images[i] || null,
              `${card.text}`,
            ],
            '',
          ],
          [
            this.translator.instant('flashCard.form.answer'),
            [card.video_question_or_answer ? images[i] : null, card.answer],
          ],
          [
            this.translator.instant('flashCard.form.explanation'),
            ['', card.explanation],
          ],
          ['Weblink', card.weblink],
          [this.translator.instant('flashCard.form.source'), card.source],
        ]);
      }, []),
    });
    this.pageDecoration();
  }

  private generateMarkdown(
    text: string,
    width: number,
    rotation: 0 | -90 | 90 = 0,
    backgroundColor?: string
  ): Promise<HTMLCanvasElement> {
    return new Promise<HTMLCanvasElement>((resolve) => {
      const container = document.createElement('div');
      const parsed = this.markdownKatex.compile(text);
      container.innerHTML = parsed['changingThisBreaksApplicationSecurity'];
      if (backgroundColor) {
        container.style.background = backgroundColor;
      }
      container.style.fontFamily = 'Lato';
      container.style.fontSize = this.doc.getFontSize() + 'pt';
      container.style.width = `${width}mm`;
      container.style.marginTop = '-100%';
      container.style.transform =
        'translate(-300vw, -300vh) rotate(' + rotation + 'deg)';
      document.body.appendChild(container);
      html2canvas(container, {
        scale: 2,
        logging: false,
      }).then(async (canvas) => {
        container.remove();
        resolve(canvas);
      });
    });
  }

  private adaptiveSize(text, config) {
    this.doc.setFontSize(12);
    let dims = this.doc.getTextDimensions(text, config);
    while (dims.h > this.WIDTH / 2 - 30 || this.doc.getFontSize() < 2) {
      this.doc.setFontSize(this.doc.getFontSize() - 1);
      dims = this.doc.getTextDimensions(text, config);
    }
  }
}
