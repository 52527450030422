import {
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import dayjs from 'dayjs';
import { Subscription } from 'rxjs';
import {
  DailyUsageStatistic,
  FlashCardStackModel,
  FlashCardStats,
  StackStatistics,
} from 'src/app/api/models';
import { CoursesService } from 'src/app/api/services';
import { DateRange } from 'src/app/app.types';
import { DateRangePickerService } from '../../common/form-field/date-range-picker.service';
import { ChartComponent } from '../../common/ui/charts/chart.component';
import { environment } from 'src/environments/environment';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
const EXCEL_TYPE =
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

@Component({
  selector: 'qa-stack-statistics',
  templateUrl: './stack-statistics.component.html',
  styleUrls: ['./stack-statistics.component.scss'],
})
export class StackStatisticsComponent implements OnInit, OnDestroy {
  @Input() courseId: number = null;
  @Input() stack: FlashCardStackModel = null;
  chartData: any;
  chartOptions: {};
  cardStatistics: FlashCardStats[];
  dateRangeSubscription: Subscription;
  loading = false;
  rows: Array<{ x: string; y: number; id: number }> = [];
  dateRange: DateRange;
  exportData = [];

  @ViewChild('chart') chartRef: ChartComponent;

  constructor(
    private courses: CoursesService,
    private dateRangePickerService: DateRangePickerService,
    elementRef: ElementRef<HTMLElement>,
    private translator: TranslateService
  ) {
    elementRef.nativeElement.classList.add('qa-stack-statistics');
  }

  async ngOnInit() {
    this.dateRangeSubscription =
      this.dateRangePickerService.dateRange.subscribe(async (dateRange) => {
        this.loading = true;
        this.dateRange = dateRange;
        const stats = await this.courses
          .getStackStatisticsInCourse({
            id: this.courseId,
            stack_id: this.stack.id,
            end: dateRange?.to
              ? dayjs.utc(dateRange.to).format('YYYY-MM-DD') + 'T23:59:59'
              : null,
            start: dateRange?.from
              ? dayjs.utc(dateRange.from).utc().format('YYYY-MM-DD') +
                'T00:00:00'
              : null,
          })
          .toPromise();
        this.cardStatistics = stats.card_stats;
        this.generateExportData();
        await this.updateChart(stats, dateRange);
        this.loading = false;
      });
  }

  generateExportData() {
    const data = [];
    this.cardStatistics.forEach((stat) => {
      let ratio = '0.00%/0.00%';
      if (stat.num_answers) {
        ratio = `${((stat.num_correct * 100) / stat.num_answers).toFixed(
          2
        )}%/${(100 - (stat.num_correct * 100) / stat.num_answers).toFixed(2)}%`;
      }
      const item = {
        id: stat.card.id,
        card: stat.card.text,
        total_answers: stat.num_answers,
        correct: stat.num_correct,
        incorrect: stat.num_answers - stat.num_correct,
        ratio,
      };
      item.incorrect = stat.num_answers - stat.num_correct;
      data.push(item);
    });
    this.exportData = data;
  }

  downloadExcel() {
    const fileName = `${this.stack.name}_Statistik_${dayjs(
      this.dateRange.from
    ).format('YYYY-MM-DD')}_${dayjs(this.dateRange.to).format('YYYY-MM-DD')}`;
    this.exportAsExcelFile(this.exportData, fileName);
  }

  public exportAsExcelFile(json: any[], excelFileName: string): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    const wscols = [
      { wch: 8 },
      { wch: 100 },
      { wch: 12 },
      { wch: 10 },
      { wch: 10 },
      { wch: 15 },
    ];
    worksheet['!cols'] = wscols;
    const workbook: XLSX.WorkBook = {
      Sheets: { data: worksheet },
      SheetNames: ['data'],
    };
    const excelBuffer: any = XLSX.write(workbook, {
      bookType: 'xlsx',
      type: 'buffer',
    });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }

  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE,
    });
    FileSaver.saveAs(data, fileName + EXCEL_EXTENSION);
  }

  ngOnDestroy() {
    this.dateRangeSubscription.unsubscribe();
  }

  async updateChart(statistics: StackStatistics, dateRange: DateRange) {
    const fromDate = dayjs(dateRange.from);
    const toDate = dayjs(dateRange.to).add(1, 'days');
    const table = {};
    const difference = toDate.diff(fromDate, 'days');
    for (let i = 0; i < difference; i++) {
      const date = fromDate.clone().add(i, 'days').format('YYYY-MM-DD');
      table[date] = 0;
    }
    statistics.usage.forEach((row: DailyUsageStatistic) => {
      const date = dayjs.utc(row.day).format('YYYY-MM-DD');
      table[date] = row.value;
    });
    const rows = Object.keys(table).map((date) => {
      return {
        x: dayjs(date),
        y: table[date],
      };
    });

    this.chartOptions = {
      legend: { display: false },
      scales: {
        x: {
          ticks: {
            autoSkip: true,
            maxRotation: 0,
            minRotation: 0,
            maxTicksLimit: 11,
          },
          grid: {
            display: false,
            z: -1,
          },
        },
        y: {
          scaleLabel: {
            display: false,
            labelString: this.translator.instant('statistics.graph.title'),
          },
          ticks: {
            min: 0,
            precision: 0,
            beginAtZero: true,
          },
          gridLines: {
            drawBorder: false,
          },
        },
      },
      maintainAspectRatio: false,
    };
    this.chartData = {
      labels: rows.map((r) => this.formatDate(r.x)),
      datasets: [
        {
          label: this.translator.instant('question.title'),
          backgroundColor: environment.colors.primary,
          borderColor: environment.colors.primary,
          hoverBackgroundColor: environment.colors.primaryLight,
          data: rows.map((r) => r.y),
          borderWidth: 2,
          pointBorderWidth: 0,
          pointHoverBackgroundColor: '#fff',
          pointBackgroundColor: environment.colors.primary,
          pointBorderColor: environment.colors.primary,
          fill: false,
          hidden: false,
        },
      ],
    };
  }

  private formatDate(date: dayjs.Dayjs): string {
    return dayjs(date || '01.01.1970').format('DD MMM YYYY');
  }

  getCorrectRatio(stat: FlashCardStats) {
    return stat && stat.num_answers
      ? `${((stat.num_correct * 100) / stat.num_answers).toFixed(2)}%`
      : '0%';
  }

  getCorrectAnswers(ratio: number) {
    return ratio ? Math.floor(ratio) : 0;
  }
}
