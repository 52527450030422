import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { MarkdownKatexComponent } from './markdown-katex.component';
import { MarkdownKatexService } from './markdown-katex.service';

@NgModule({
  declarations: [MarkdownKatexComponent],
  providers: [MarkdownKatexService],
  imports: [CommonModule, TranslateModule],
  exports: [MarkdownKatexComponent],
})
export class MarkdownKatexModule {}
