import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { BookQuery, CourseModel, CourseQuery } from 'src/app/api/models';
import { BooksService, CoursesService } from 'src/app/api/services';
import * as turndown from 'turndown';
import { Panel, PanelEntityType } from '../panel.types';
import { TranslateService } from '@ngx-translate/core';
import { DialogService } from '../../common/dialog.service';
import * as _ from 'lodash';
import { CourseBook } from '../../common/course-book';
import { Router } from '@angular/router';
import { UserJourneyService } from '../../pages/onboarding-steps/user-journey.service';

@Injectable()
export class CoursePanel extends Panel<CourseBook> {
  turndown: turndown;

  newCoursesShown = false;
  isBook = false;

  constructor(
    private coursesService: CoursesService,
    private booksService: BooksService,
    private dialog: DialogService,
    private translator: TranslateService,
    private router: Router,
    private userJourney: UserJourneyService
  ) {
    super();
    this.initializePanel();
  }

  initializePanel() {
    this.isBook =
      location?.pathname?.split('/')?.[3] === 'book' ||
      this.router.url.indexOf('/book') !== -1;
    this.turndown = new turndown['default']();
    super.init({
      addMenuItems: [],
      childTypes: [
        PanelEntityType.Quiz,
        PanelEntityType.FlashCardStack,
        ...(this.isBook ? [] : [PanelEntityType.Exam]),
        PanelEntityType.Event,
      ],
      createItemsFn: ([item]) =>
        this.coursesService.createCourse({ body: item }).pipe(
          map((savedItem) => [savedItem]),
          tap(() => {
            this.userJourney.set('createdCourse');
          })
        ),
      editDialogClass: 'course-edit-dialog',
      editDialogInput: (course) => ({ course }),
      fetchItem: (id) => {
        if (id) {
          return this.isBook
            ? this.booksService.getBookById({ id, full: false })
            : this.coursesService.getCourseById({ id, full: false });
        } else {
          return of(null);
        }
      },
      exportItems: (id) =>
        this.isBook
          ? of(null)
          : this.coursesService.exportCourses({
              export_exam: true,
              export_events: true,
              body: { courses: [{ id }] },
            }),
      fetchItems: () => {
        const query = {
          sorting: [
            {
              option: 'id',
              direction: 'DESC',
            },
          ],
          paging: {
            page_size: 10000,
          },
        } as Partial<CourseQuery | BookQuery> as CourseQuery | BookQuery;
        return (
          this.isBook
            ? this.booksService.queryBooks({ body: query })
            : this.coursesService.queryCourses({ body: query })
        ).pipe(
          map((response) => {
            if (!this.isBook) {
              // If new courses have been shared with this user
              const newCourses =
                (response.data as []).filter(
                  (c) => !(c as CourseModel).is_watched
                ) || [];
              if (newCourses?.length > 0 && !this.newCoursesShown) {
                this.newCoursesShown = true;
                this.dialog.confirm({
                  title: this.translator.instant(
                    'course.collaboration.label.newCourses'
                  ),
                  text: `${this.translator.instant(
                    'course.collaboration.message.newCourses'
                  )}\n\n${newCourses.reduce(
                    (p, c) => `${p} •\t${(c as CourseModel).name}\n`,
                    ''
                  )}\n${this.translator.instant(
                    'course.collaboration.message.newCoursesHint'
                  )}`,
                  buttons: [
                    {
                      color: 'primary',
                      text: this.translator.instant('common.button.understood'),
                    },
                  ],
                });
                newCourses.forEach((course: any) => {
                  this.coursesService
                    .updateCourse({
                      id: course.id,
                      body: { is_watched: true } as CourseModel,
                    })
                    .subscribe();
                });
              }
            }
            return response.data as [];
          })
        );
      },
      headerIcon: 'collections_bookmark',
      itemActions: [],
      itemTextProperty: 'name',
      translations: {
        addItem: this.translator.instant('content.course.tooltip'),
        deleteItemError: this.translator.instant(
          'content.course.deleteItem.error'
        ),
        deleteItemSuccess: this.translator.instant(
          'content.course.deleteItem.success'
        ),
        deleteItemText: this.translator.instant(
          'content.course.deleteItem.text'
        ),
        deleteItemTitle: this.translator.instant(
          'content.course.deleteItem.title'
        ),
        header: this.translator.instant('common.label.courses'),
        emptyCourse: this.translator.instant('content.course.emptyCourse'),
      },
      type: PanelEntityType.Course,
    });
  }
}
