<ng-container *ngIf="!blurInput || hideLabel; else labelView">
  <mat-form-field
    appearance="outline"
    [floatLabel]="disableFloatingLabel ? 'never' : 'auto'"
    [ngClass]="{ 'no-label': !label }"
    [class.markdownInput]="markdownHint"
    subscriptSizing="dynamic"
    [class.suggestion]="showSuggestion"
  >
    <mat-label *ngIf="label">
      {{ label }}
    </mat-label>
    <input
      [readonly]="readonly"
      [errorStateMatcher]="errorStateMatcher"
      [formControl]="control"
      [required]="required"
      [type]="type"
      [max]="type === 'number' ? max : null"
      [min]="type === 'number' ? min : null"
      [step]="type === 'number' ? step : null"
      [placeholder]="placeholder"
      (blur)="onBlur()"
      (keyup.enter)="onEnterInput()"
      (keyup)="inputKeyUp($event)"
      (paste)="inputPaste($event)"
      (keypress)="keyPress($event)"
      #inputField="matInput"
      matInput
      *ngIf="multi === false; else textarea"
    />
    <div *ngIf="suffix" matSuffix>{{ suffix }}</div>
    <ng-template #textarea>
      <textarea
        [readonly]="readonly"
        [formControl]="control"
        [cdkAutosizeMaxRows]="+maxRows"
        [cdkAutosizeMinRows]="+minRows"
        [required]="required"
        [placeholder]="placeholder"
        (blur)="onBlur()"
        (focus)="handleSuggestion($event)"
        (pointerdown)="onPointerDown($event)"
        (input)="onInput()"
        (keydown)="onKeyDown($event)"
        #textArea="matInput"
        matInput
        cdkTextareaAutosize
      >
      </textarea>
    </ng-template>
    <mat-error *ngFor="let errorName of control.errors | keys">
      {{ errorDisplayFunction(errorName) | translate }}
    </mat-error>
    <div class="help-btns" *ngIf="!blurInput && markdownHint">
      <mat-label
        (mousedown)="openMarkdownDialog()"
        [matTooltip]="'Formatierungshilfe'"
        *ngIf="markdownHint"
      >
        <mat-icon>code</mat-icon>
        {{ 'common.label.formattingHelp' | translate }}
      </mat-label>
    </div>
    <mat-hint *ngIf="hint">{{ hint }}</mat-hint>
  </mat-form-field>
</ng-container>

<ng-template #labelView>
  <mat-form-field
    appearance="outline"
    class="label-view"
    [floatLabel]="disableFloatingLabel ? 'never' : 'auto'"
    [ngClass]="{ 'no-label': !label }"
    [class.markdownInput]="markdownHint"
    subscriptSizing="dynamic"
    [class.suggestion]="showSuggestion"
  >
    <mat-label *ngIf="label">
      {{ label }}
    </mat-label>
    <input
      hidden
      class="hidden"
      [readOnly]="readonly"
      [required]="required"
      [formControl]="control"
      matInput
    />
    <div *ngIf="!markdownHint" (click)="onEdit()">{{ control.value }}</div>
    <qa-markdown-katex
      *ngIf="markdownHint"
      [data]="control.value"
      (click)="onEdit()"
    >
    </qa-markdown-katex>
  </mat-form-field>
</ng-template>

<div
  class="formulaOverlay"
  *ngIf="markdownHint"
  [class.open]="!!insertFormulaAt"
>
  <div #formulaEditorContainer></div>
</div>
