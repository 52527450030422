import { Edit } from '../edit.types';
import { User } from 'src/app/api/models';
import { Injectable } from '@angular/core';
import { UsersService } from 'src/app/api/services';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class ProfileEditConfigService extends Edit.ConfigService<User> {
  constructor(users: UsersService, tranlator: TranslateService) {
    super({
      itemName: tranlator.instant('profile.title'),
      id: 'profile',
      fields: [
        {
          key: 'email',
          label: tranlator.instant('profile.form.email'),
          inputType: 'email',
          required: true,
        },
        {
          key: 'firstname',
          label: tranlator.instant('profile.form.firstName'),
        },
        {
          key: 'lastname',
          label: tranlator.instant('profile.form.lastName'),
        },
      ],
      handlers: {
        init: async (initialValue) => {
          if (initialValue && initialValue.id) {
            initialValue = await users
              .getUserById({ id: initialValue.id })
              .toPromise();
          }
          return initialValue;
        },
        update: (profile: any) => {
          if (profile.email) {
            profile.email = profile.email.toLowerCase();
          }
          return users
            .updateUser({
              id: profile.id,
              body: profile,
              validate_email: profile.validateEmail,
            })
            .toPromise();
        },
      },
    });
  }
}
