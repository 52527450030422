<div class="custom-tabs">
  <ng-container *ngFor="let tab of tabs; let i = index">
    <div
      (click)="selectTab(tab)"
      [class.active]="selectedTab?.value === tab.value"
      class="custom-tab border-right"
    >
      {{ tab.name | translate }}
    </div>
  </ng-container>
</div>
