import { Component, Inject, Optional, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CropperPosition, ImageCropperComponent } from 'ngx-image-cropper';

export interface ImageEditData {
  file: Blob;
  name: string;
}

@Component({
  selector: 'qa-image-edit-dialog',
  templateUrl: './image-edit-dialog.component.html',
  styleUrls: ['./image-edit-dialog.component.scss'],
})
export class ImageEditDialogComponent {
  croppedImage = '';

  aspectLocked = true;
  cropper: CropperPosition;

  lockedCropperAspect = 4 / 3;
  baseCropperAspect = 4 / 3;
  cropperAspect = { x: 0, y: 0 };

  @ViewChild('cropperElement') cropperElement: ImageCropperComponent;

  constructor(
    @Optional() public dialogRef: MatDialogRef<ImageEditDialogComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: ImageEditData
  ) {}

  crop() {
    fetch(this.croppedImage)
      .then((res) => res.arrayBuffer())
      .then(
        (buf) => new File([buf], this.data.name + '.png', { type: 'image/png' })
      )
      .then((file) => this.dialogRef.close(file));
  }

  setCropperAspect(): void {
    const width = this.cropper
      ? Math.round(Math.abs(this.cropper.x2 - this.cropper.x1))
      : 0;
    const height = this.cropper
      ? Math.round(Math.abs(this.cropper.y2 - this.cropper.y1))
      : 0;
    const calcgcd = function gcd(a, b) {
      return b ? calcgcd(b, a % b) : a;
    };
    const gcd = calcgcd(width, height);
    this.lockedCropperAspect = width / gcd / (height / gcd);
    this.cropperAspect = { x: width / gcd, y: height / gcd };
  }

  lockAspect(): void {
    this.baseCropperAspect = this.lockedCropperAspect;
    this.aspectLocked = !this.aspectLocked;
  }

  dontCrop(): void {
    this.dialogRef.close(this.data.file);
  }
}
