import { ComponentType } from '@angular/cdk/portal';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { filter, switchMap, map } from 'rxjs/operators';
import { PanelService } from './panel.service';
import {
  CourseModel,
  QuizModel,
  QuestionModel,
  FlashCardStackModel,
  FlashCardModel,
  Exam,
  SortOption,
  LiveEvent,
  BookModel,
} from 'src/app/api/models';
import { ImportEntity } from '../list/list.types';
import { SubscriptionLimit } from '../common/dialogs/subscription-limit-warning/subscription-limit-warning.component';

export class Panel<T = PanelEntity> {
  addMenuItems?: MenuItem[];
  imports?: Array<ImportEntity<T>>;
  changeItemPosition: (
    entityId: number,
    parentId: number,
    position: number
  ) => Observable<unknown>;
  changeItemStatus: (
    entityId: number,
    parentId: number,
    active: boolean
  ) => Observable<unknown>;
  childTypes: PanelEntityType[];
  subscriptionLimitType?: SubscriptionLimit;
  createItemsFn: (items: T[], parentId?: number) => Observable<PanelEntity[]>;
  deleteItem: (entityId: number) => Observable<unknown>;
  editConfigService?: any;
  editConfigServiceParams?: (
    entity: T,
    parentId: number
  ) => { [key: string]: any };
  editDialogComponent?: ComponentType<{}>;
  editDialogClass?: string;
  editDialogInput: (entity: T, parentId: number) => object;
  fetchItem: (id: number) => Observable<T>;
  exportItems?: (id: number) => Observable<T[]>;
  fetchItems: (parentId?: number, additionalData?: boolean) => Observable<T[]>;
  createEntity?: (panelEntiry: T, parentId: number) => Observable<PanelEntity>;
  linkChildToEntity?: (
    id: number,
    childData: PanelEntity[]
  ) => Observable<PanelEntity[]>;
  fetchChild?: (id: number) => Observable<PanelEntity[]>;
  headerIcon: string;
  isItemActive: (item: T) => boolean;
  activateItemAction?: PanelItemAction<T>;
  itemActions: Array<PanelItemAction<T>>;
  itemOnHoverActions: Array<PanelItemAction<T>>;
  itemSuffixes?: (item: T) => SuffixItem[];
  itemPrefixes?: (item: T) => SuffixItem[];
  itemTextProperty: string;
  list: T[] = [];
  open$ = new Subject<void>();
  parentKey: string;
  parentType: PanelEntityType;
  selectedItemActions: Array<PanelItemAction<number[]>>;
  selectedItemIds = new Set<number>();
  translations: PanelTranslations =
    {} as Partial<PanelTranslations> as PanelTranslations;
  type: PanelEntityType;
  unlinkItems: (entityIds: number[], parentId: number) => Observable<unknown>;
  headerActions: HeaderAction[];
  sortingActions?: SortAction[];
  selectedSortAction?: SortAction;
  isFilteredListVisible?: boolean;
  filterTopListBy?: (item: T) => boolean;
  filterBottomListBy?: (item: T) => boolean;
  filteredList: T[] = [];
  filteredListTitle: string;
  doNotshowAdd?: boolean;
  showTotalItems?: boolean;
  filteredItemAction: Array<PanelItemAction<T>>;
  showChatGptAction?: boolean;

  constructor(config?: Partial<Panel<T>>) {
    if (config) {
      this.init(config);
    }
  }

  init(config: Partial<Panel<T>>) {
    Object.keys(config).forEach((key) => {
      const value = config[key];
      if (value !== undefined && value !== null) {
        this[key] = value;
      }
    });
  }

  onDestroy() {
    this.open$.unsubscribe();
  }
}

export interface MenuItem {
  icon: string;
  onClick: () => void;
  text: string;
  coming_soon?: boolean;
  beta?: boolean;
}

export interface SortAction {
  icon: string;
  text: string;
  sort: SortOption;
}

export interface HeaderAction {
  icon: string;
  onClick: (
    fullPath: {
      quiz?: number;
      course?: number;
      exam?: number;
      stack?: number;
    },
    data: any
  ) => void;
  text: string;
}

export interface SuffixItem {
  text: string;
  icon: string;
}

interface PanelTranslations {
  addItem: string;
  deleteItemError: string;
  deleteItemSuccess: string;
  header: string;
  changeItemPositionError?: string;
  changeItemPositionSuccess?: string;
  changeItemStatusDisabled?: string;
  changeItemStatusEnabled?: string;
  deleteItemText?: string;
  deleteItemTitle?: string;
  unlinkItemConfirmText?: string;
  unlinkItemConfirmTitle?: string;
  unlinkItemError?: string;
  unlinkItemSuccess?: string;
  emptyCourse?: string;
  duplicateEntity?: string;
  duplicateEntitySuccess?: string;
  linkChild?: string;
}

export interface PanelItemAction<T> {
  disabled?: (item: T) => boolean;
  hidden?: (item: T) => boolean;
  icon: string;
  label: string;
  onClick: (item: T) => void;
  tooltip?: (item: T) => string;
}

export enum PanelItemActionEnum {
  Delete,
  Disable,
  Edit,
  Embed,
  Enable,
  Export,
  Import,
  ImportJSON,
  ImportMoodleXML,
  Unlink,
  Open,
  Refresh,
}

export class PanelGroup {
  currentPanelIndex$ = new BehaviorSubject<number>(0);
  collapsed = false;

  constructor(public panels: Panel[], panelService: PanelService) {
    this.currentPanelIndex$
      .pipe(
        switchMap((newIndex) => {
          return panelService.initialized$.pipe(
            map((initialized) => ({ newIndex, initialized }))
          );
        }),
        filter(({ initialized }) => initialized)
      )
      .subscribe(({ newIndex }) => {
        this.panels.forEach((panel, index) => {
          if (index !== newIndex) {
            panelService.removeParam(panel.type);
            panel.selectedItemIds.clear();
          }
        });
      });
    this.currentPanelIndex$.next(panels.length - 1);
  }

  onDestroy() {
    this.currentPanelIndex$.unsubscribe();
  }
}

export interface PanelLevel {
  collapsed: boolean;
  groups: PanelGroup[];
}

export type PanelEntity =
  | CourseModel
  | QuizModel
  | QuestionModel
  | FlashCardStackModel
  | FlashCardModel
  | Exam
  | LiveEvent
  | BookModel;
export enum PanelEntityType {
  Course = 'course',
  Quiz = 'quiz',
  Question = 'question',
  FlashCardStack = 'flash-card-stack',
  FlashCard = 'flash-card',
  Exam = 'exam',
  ExamQuestion = 'exam-question',
  Event = 'events',
  EventQuestion = 'event-question',
  Book = 'book',
}
