import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { cloneDeep, sortBy } from 'lodash';
import { forkJoin, Observable, Subscription } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';
import { CourseModel, FlashCardModel, QuestionModel } from 'src/app/api/models';
import {
  FlashCardsService,
  FlashCardStacksService,
  CoursesService,
  QuestionsService,
  BooksService,
} from 'src/app/api/services';
import { DialogService } from 'src/app/cms/common/dialog.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { CancelConfirmationComponent } from '../common/dialogs/cancel-confirmation.component';
import { AuthService } from 'src/app/auth/auth.service';
import { QuestionType } from 'src/app/app-constants';
import { Location } from '@angular/common';
import { AttachMediaDialogComponent } from './attach-media-dialog/attach-media-dialog.component';
import * as CardTemplate from './templates/flash-card/qa-flash-card-template.json';
import { UtilityService } from 'src/app/utility/utility.service';
import { DestroyNotifier } from '../common/destroy-notifier';
import { environment } from 'src/environments/environment';
import { PanelEntityType } from '../panel/panel.types';
import * as fileSaver from 'file-saver';
import {
  CommentListDialogComponent,
  CommentListDialogData,
} from '../list/comment-list-dialog.component';
import { MatDialogConfig } from '@angular/material/dialog';
import {
  ShowInfoDialogComponent,
  ShowInfoItem,
} from '../common/show-info-dialog/show-info-dialog.component';

export interface FlashCardEditDialogInput {
  enableNavigation?: boolean;
  flashCard: FlashCardModel;
  flashCardStackId?: number;
}

@Component({
  selector: 'qa-flash-card-edit',
  templateUrl: './flash-card-edit.component.html',
  styleUrls: ['./flash-card-edit.component.scss'],
})
export class FlashCardEditComponent
  extends DestroyNotifier
  implements OnInit, OnDestroy, OnChanges
{
  @Output() updatedCard = new EventEmitter<FlashCardModel>();
  @Output() closeSideMenu = new EventEmitter<boolean>();
  @Input() flashcardId: number;
  flashCardStackId: number;
  courseId: number;
  bookId: number;
  commentId: number;
  form: UntypedFormGroup;
  loading = true;
  id: number;
  duplicateFlashCardId: number;
  questionId;
  allFlashcards: FlashCardModel[] = [];
  currentItemIndex = 0;
  courseData: CourseModel;
  flashCard: FlashCardModel;
  userId: string;
  nonAcceptedCards = 0;
  cardIdFromContent: number;
  showForm = false;
  totalAcceptedFlashCards = 0;
  formValueChanges: Subscription;
  hasChange: boolean = false;
  hasComments = false;
  templates = [
    {
      title: this.translator.instant('common.label.indexCard'),
      template: CardTemplate,
    },
  ];
  showMobilePreview = false;

  constructor(
    private dialog: DialogService,
    route: ActivatedRoute,
    private matDialog: MatDialog,
    private router: Router,
    private coursesService: CoursesService,
    private booksService: BooksService,
    private location: Location,
    private questions: QuestionsService,
    private flashCards: FlashCardsService,
    private flashCardStacks: FlashCardStacksService,
    private utilityService: UtilityService,
    private matSnackBar: MatSnackBar,
    auth: AuthService,
    private translator: TranslateService
  ) {
    super();
    utilityService.nonAcceptedCards$
      .pipe(takeUntil(this.destroy$))
      .subscribe((count) => {
        this.nonAcceptedCards = count && count > 0 ? count : 0;
      });
    this.userId = auth.currentUserInfo$.value.sub;
    route.queryParams.subscribe((params) => {
      if (params.course) {
        this.courseId = params.course;
      }
      if (params.book) {
        this.bookId = params.book;
      }
      if (params.commentId) {
        this.commentId = params.commentId;
      }
      if (params['flash-card-stack']) {
        this.flashCardStackId = params['flash-card-stack'];
      }
      if (params['total-accepted-flashCards']) {
        this.totalAcceptedFlashCards = Number(
          params['total-accepted-flashCards']
        );
      }
      if (params.questionId) {
        this.questionId = Number(params.questionId);
        this.getQuestion(this.questionId);
      }
      if (params.flashCardId) {
        this.duplicateFlashCardId = Number(params.flashCardId);
        this.getAllFlashCards(this.duplicateFlashCardId);
      }
      if (params.contentCardId && !(params.course || params.courseId)) {
        if (localStorage.getItem('content-card-list')) {
          this.cardIdFromContent = Number(params.contentCardId);
          const cards = localStorage.getItem('content-card-list');
          this.allFlashcards = JSON.parse(cards).data;
          const idx = this.allFlashcards.findIndex(
            (card) => card.id === Number(params.contentCardId)
          );
          this.currentItemIndex = idx > -1 ? idx : 0;
        }
      }
    });
    route.params.subscribe((params) => {
      if (params.id) {
        this.id = params.id;
        this.getAllFlashCards(params.id);
      } else if (!this.questionId && !this.duplicateFlashCardId) {
        this.loading = false;
      }
      if (params.course) {
        this.courseId = params.course;
      }
      if (params['flash-card-stack']) {
        this.flashCardStackId = params['flash-card-stack'];
      }
    });
  }

  ngOnInit() {
    this.getFlashCardInfo();
    this.form =
      this.form ||
      new UntypedFormGroup({
        id: new UntypedFormControl(),
        text: new UntypedFormControl(),
        answer: new UntypedFormControl(),
        explanation: new UntypedFormControl(),
        weblink: new UntypedFormControl(),
        source: new UntypedFormControl(),
        tags: new UntypedFormControl([]),
        accepted: new UntypedFormControl(true),
        video_question_or_answer: new UntypedFormControl(),
        youtube_link_question: new UntypedFormControl(),
        media: new UntypedFormGroup({
          id: new UntypedFormControl(),
          file_ext: new UntypedFormControl(),
          uuid: new UntypedFormControl(),
        }),
      });
    if (!this.id && !this.questionId && !this.duplicateFlashCardId) {
      setTimeout(() => {
        this.showForm = true;
      }, 100);
    }
    if (!this.flashcardId) {
      this.toggleSidebar(false);
    }
    if (this.id) {
      this.getComments();
    }
  }

  getComments() {
    this.flashCards
      .getCommentsForFlashCard({
        id: this.id,
      })
      .subscribe((response) => (this.hasComments = !!response.length));
  }

  showComments() {
    const id = this.flashCard?.id || this.flashcardId;
    this.matDialog.open(CommentListDialogComponent, {
      data: {
        flashCardId: id,
      },
      autoFocus: false,
    } as MatDialogConfig<CommentListDialogData>);
  }

  openDuplicateSuccessfulDialog() {
    const buttons = [
      {
        color: 'primary',
        text: this.translator.instant('flashCard.backToStack'),
        value: false,
      },
      {
        color: 'primary',
        text: this.translator.instant('flashCard.goToFlashcardOverview'),
        value: true,
      },
    ];
    if (!this.flashCardStackId) {
      buttons.splice(0, 1);
    } else {
      buttons.splice(1, 1);
    }
    this.dialog
      .confirm(
        {
          title: this.translator.instant('flashCard.duplicatedTitle'),
          text: this.translator.instant('flashCard.duplicatedMessage'),
          buttons,
        },
        {
          autoFocus: false,
          disableClose: true,
        }
      )
      .subscribe((val) => {
        if (val) {
          this.router.navigate(['/cms/content/flash-cards']);
        } else if (val === false) {
          if (this.courseId) {
            this.router.navigate([
              `/cms/courses/edit/${this.courseId}`,
              {
                course: this.courseId,
                'flash-card-stack': this.flashCardStackId,
              },
            ]);
          } else if (this.bookId) {
            this.router.navigate([
              `/cms/books/edit/${this.bookId}`,
              {
                book: this.courseId,
                'flash-card-stack': this.flashCardStackId,
              },
            ]);
          } else {
            this.onClose();
          }
        }
      });
  }

  openConvertedFlashcardDialog() {
    this.dialog
      .confirm(
        {
          title: this.translator.instant('flashCard.convertedTitle'),
          text: this.translator.instant('flashCard.convertedMessage'),
          buttons: [
            {
              color: 'primary',
              text: this.translator.instant('flashCard.goToFlashcardOverview'),
              value: false,
            },
          ],
        },
        {
          autoFocus: false,
          disableClose: true,
        }
      )
      .subscribe(() => {
        this.router.navigate(['/cms/content/flash-cards']);
      });
  }

  onValueChange(e) {
    setTimeout(() => {
      this.showMobilePreview = e;
    }, 100);
  }

  onCreateGroupFormValueChange() {
    const initialValue = this.form.value;
    this.formValueChanges = this.form.valueChanges.subscribe(() => {
      this.hasChange = Object.keys(initialValue).some(
        (key) => this.form.value[key] != initialValue[key]
      );
    });
  }

  confirmSave(changes) {
    this.dialog
      .confirm({
        title: '',
        text: this.translator.instant('common.message.confirmEdit'),
        buttons: [
          { text: this.translator.instant('common.button.abort') },
          {
            color: 'primary',
            text: this.translator.instant('common.button.save'),
            value: true,
          },
        ],
      })
      .subscribe((val) => {
        if (!val) {
          this.loadCard(changes);
        } else {
          this.hasChange = false;
          this.onSubmit();
        }
      });
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.toggleSidebar(true);
  }

  async ngOnChanges(changes: SimpleChanges) {
    if (changes && changes.flashcardId.currentValue) {
      if (this.hasChange) {
        await this.confirmSave(changes);
      } else {
        this.loadCard(changes);
      }
    }
  }

  loadCard(changes) {
    this.showForm = false;
    this.showMobilePreview = false;
    if (this.formValueChanges) {
      this.formValueChanges.unsubscribe();
    }
    this.flashcardId = changes.flashcardId.currentValue;
    this.id = changes.flashcardId.currentValue;
    this.getAllFlashCards(this.id);
  }

  toggleSidebar(show: boolean) {
    const sidebar = document.getElementsByClassName('qa-sidebar-inner');
    if (sidebar && sidebar.length) {
      const sidenav = sidebar[0] as HTMLElement;
      sidenav.style.display = show ? 'flex' : 'none';
    }
  }

  duplicatedFlashCard() {
    const obj = {};
    if (this.flashCard) {
      obj['flashCardId'] = this.flashCard.id.toString();
    }
    if (this.courseId) {
      obj['course'] = this.courseId;
    }
    if (this.flashCardStackId) {
      obj['flash-card-stack'] = this.flashCardStackId;
    }
    if (this.flashCard) {
      this.router.navigate(['/cms/add/flash-card'], { queryParams: obj });
    }
  }

  getAllFlashCards(flashCardId) {
    if (this.flashCardStackId) {
      this.loading = true;
      let getFlashCards$: Observable<FlashCardModel[]>;
      getFlashCards$ = this.flashCardStacks.getCardsInFlashCardStack({
        id: this.flashCardStackId,
      });
      getFlashCards$.subscribe(
        (response) => {
          const allFlashcards = response;
          this.flashCards
            .getFlashCardById({ id: Number(flashCardId) })
            .subscribe((flashCard) => {
              if (this.duplicateFlashCardId) {
                delete flashCard.id;
              }
              this.flashCard = flashCard;
              this.allFlashcards = allFlashcards.filter(
                (x) => x.accepted === flashCard.accepted
              );
              const idx = this.allFlashcards.findIndex(
                (card) => card.id === Number(flashCardId)
              );
              this.currentItemIndex = idx > -1 ? idx : 0;
              this.form.reset();
              this.form.patchValue(this.flashCard);
              setTimeout(() => {
                this.showForm = true;
                this.loading = false;
                if (this.duplicateFlashCardId) {
                  this.form.markAsDirty();
                }
              }, 200);
            });
        },
        () => (this.loading = false)
      );
    } else {
      this.loading = true;
      this.flashCards.getFlashCardById({ id: flashCardId }).subscribe(
        (flashcard: FlashCardModel) => {
          if (this.duplicateFlashCardId) {
            delete flashcard.id;
          }
          this.flashCard = flashcard;
          this.form.reset();
          this.form.patchValue(this.flashCard);
          setTimeout(() => {
            this.showForm = true;
            this.hasChange = false;
            this.loading = false;
            if (this.duplicateFlashCardId) {
              this.form.markAsDirty();
            }
            this.onCreateGroupFormValueChange();
          }, 200);
        },
        () => (this.loading = false)
      );
    }
  }

  setFormValues() {
    this.form.patchValue({
      text: this.flashCard.text ? this.flashCard.text : '',
      answer: this.flashCard.answer ? this.flashCard.answer : '',
      explanation: this.flashCard.explanation ? this.flashCard.explanation : '',
      weblink: this.flashCard.weblink ? this.flashCard.weblink : '',
      source: this.flashCard.source ? this.flashCard.source : '',
      media: this.flashCard.media
        ? this.flashCard.media
        : { id: null, file_ext: '', uuid: '' },
      youtube_link_question: this.flashCard.youtube_link_question
        ? this.flashCard.youtube_link_question
        : null,
      video_question_or_answer: this.flashCard.video_question_or_answer
        ? true
        : false,
      tags: this.flashCard.tags ? this.flashCard.tags : null,
      accepted: this.flashCard.accepted ? this.flashCard.accepted : false,
    });
  }

  getQuestion(id) {
    this.questions
      .getQuestionById({
        id,
      })
      .subscribe((question: QuestionModel) => {
        this.convertFlashCard(question);
      });
  }

  showImage(): void {
    const url = `https://s3.${environment.s3.mediaBucket.region}.amazonaws.com/${environment.s3.mediaBucket.name}/${this.form?.value?.media?.uuid}.${this.form?.value?.media?.file_ext}`;
    this.dialog.showImage(url);
  }

  convertFlashCard(question: QuestionModel) {
    const card: FlashCardModel = {
      text: question.text,
      media: question.media
        ? question.media
        : { id: null, file_ext: '', uuid: '' },
      tags: question.tags ? question.tags : null,
      explanation: question.explanation ? question.explanation : null,
      weblink: question.weblink ? question.weblink : null,
      source: question.source ? question.source : null,
      answer: '',
    };
    if (question.type === QuestionType.SingleChoice) {
      const idx = question.answers.findIndex(
        (answer) => answer.is_right === true
      );
      card.answer = question.answers[idx].text;
    } else if (question.type === QuestionType.MultipleChoice) {
      const answers = question.answers.filter((answer) => answer.is_right);
      if (answers.length > 1) {
        let answer = '';
        answers.forEach((ans) => {
          ans.text = ans.text.replace(/\n/, ' ');
          ans.text = ans.text.replace(/\*/g, '  *');
          answer += '* ' + ans.text + '\n';
        });
        card.answer = answer;
      } else {
        card.answer = answers[0] ? answers[0].text : '';
      }
    }
    this.form.patchValue(card);
    if (this.form.valid && question.type !== QuestionType.TrueFalse) {
      this.form.markAsDirty();
    }
    setTimeout(() => {
      this.showForm = true;
      this.loading = false;
    }, 300);
  }

  getFlashCardInfo() {
    const requestArray = [];
    if (this.courseId) {
      requestArray.push(
        this.coursesService.getCourseById({ id: this.courseId, full: false })
      );
    }
    if (this.bookId) {
      requestArray.push(
        this.booksService.getBookById({ id: this.bookId, full: false })
      );
    }
    if (this.flashCardStackId) {
      requestArray.push(
        this.flashCardStacks.getFlashCardStackById({
          id: this.flashCardStackId,
        })
      );
    }
    forkJoin(requestArray).subscribe((results) => {
      if (results && results.length) {
        results.map((result) => {
          if (result.type === 'course') {
            this.courseData = result;
          }
        });
      }
    });
  }

  onDelete(): void {
    this.dialog
      .confirmDelete()
      .pipe(filter((isConfirmed) => isConfirmed))
      .subscribe(() => {
        this.loading = true;
        const flashCardId = this.form.value.id;
        this.flashCards.deleteFlashCard({ id: flashCardId }).subscribe({
          next: () => {
            this.form.markAsUntouched();
            this.matSnackBar.open(
              this.translator.instant('content.flashCard.deleteItem.success')
            );
            this.onClose(true);
          },
          error: () => {
            this.loading = false;
            this.matSnackBar.open(
              this.translator.instant('content.flashCard.deleteItem.error')
            );
          },
        });
      });
  }

  onNavigate(flashcardIndex: number) {
    this.showForm = false;
    if (!this.allFlashcards) {
      return;
    }
    if (flashcardIndex < this.allFlashcards.length) {
      this.flashCard = this.allFlashcards[flashcardIndex];
      if (this.flashCardStackId && this.courseId) {
        this.router.navigate(['/cms/edit/flash-card', this.flashCard.id], {
          queryParams: {
            course: this.courseId,
            'flash-card-stack': this.flashCardStackId,
          },
        });
      } else if (this.flashCardStackId && this.bookId) {
        this.router.navigate(['/cms/edit/flash-card', this.flashCard.id], {
          queryParams: {
            book: this.bookId,
            'flash-card-stack': this.flashCardStackId,
          },
        });
      } else if (this.cardIdFromContent) {
        this.router.navigate(['/cms/edit/flash-card', this.flashCard.id], {
          queryParams: { contentCardId: this.flashCard.id },
        });
      }
      this.form.patchValue(this.flashCard);
      this.setFormValues();
      setTimeout(() => {
        this.showForm = true;
      }, 200);
    }
  }

  onSubmit(
    createAnother = false,
    makeDuplicate = false,
    isPublicCard = false
  ): void {
    this.loading = true;
    const newFlashCard: FlashCardModel = this.form.getRawValue();
    if (newFlashCard.media && !newFlashCard.media.id) {
      newFlashCard.media = null;
    }
    let saveFlashCard$: Observable<FlashCardModel>;
    if (newFlashCard.id && this.userId === this.flashCard.created_by) {
      saveFlashCard$ = this.flashCards.updateFlashCard({
        id: newFlashCard.id,
        body: newFlashCard,
      });
    } else if (
      this.flashCardStackId &&
      !(this.courseData && this.courseData.ref_course_id && newFlashCard.id)
    ) {
      delete newFlashCard.id;
      saveFlashCard$ = this.flashCardStacks
        .copyCardsToFlashCardStack({
          id: this.flashCardStackId,
          body: [newFlashCard],
        })
        .pipe(
          map((flashCards) => {
            sortBy(flashCards, 'id');
            return flashCards[flashCards.length - 1];
          })
        );
    } else {
      saveFlashCard$ = this.flashCards.createFlashCard({
        body: newFlashCard,
      });
    }

    saveFlashCard$.subscribe({
      next: (card) => {
        this.hasChange = false;
        this.form.markAsUntouched();
        this.form.markAsPristine();
        if (
          newFlashCard.id &&
          !(this.courseData && this.courseData.ref_course_id)
        ) {
          if (!isPublicCard) {
            this.matSnackBar.open(
              this.translator.instant('flashCard.message.edit')
            );
            this.updatedCard.emit(card);
          } else {
            this.matSnackBar.open(
              this.translator.instant('flashCard.table.flshCardAccepted')
            );
            this.utilityService.setNonAcceptedCards(this.nonAcceptedCards - 1);
            if (this.flashCardStackId) {
              this.updatePositionAfterAccepted();
            } else {
              this.onClose();
            }
          }
        } else {
          if (!this.duplicateFlashCardId) {
            this.matSnackBar.open(
              this.translator.instant('flashCard.message.create')
            );
          }
          if (createAnother) {
            this.flashCard = undefined;
            this.showForm = false;
            if (!makeDuplicate) {
              this.form.reset();
            }
            setTimeout(() => {
              this.ngOnInit();
              this.loading = false;
            }, 10);
            return;
          } else if (!makeDuplicate) {
            if (this.duplicateFlashCardId) {
              this.openDuplicateSuccessfulDialog();
            } else if (this.questionId) {
              this.openConvertedFlashcardDialog();
            } else {
              this.onClose();
            }
          }
        }
        this.loading = false;
      },
      error: () => {
        this.loading = false;
        this.matSnackBar.open(
          this.translator.instant('flashCard.message.editFail')
        );
      },
    });
  }

  updatePositionAfterAccepted() {
    this.flashCardStacks
      .repositionCardsInFlashCardStack({
        id: this.flashCardStackId,
        card_id: this.id,
        body: { position: this.totalAcceptedFlashCards + 1 },
      })
      .subscribe(() => {
        this.onClose();
      });
  }

  addFlashCardToStack() {
    this.form.patchValue({ accepted: true });
    this.onSubmit(false, false, true);
  }

  unlinkCard() {
    this.matDialog
      .open(CancelConfirmationComponent, {
        autoFocus: false,
        data: {
          messages: [
            this.translator.instant('flashCard.form.unlinkSaveDialog.message1'),
            this.translator.instant('flashCard.form.unlinkSaveDialog.message2'),
          ],
          buttonLabels: {
            confirm: this.translator.instant(
              'flashCard.form.unlinkSaveDialog.copyFlashCard'
            ),
            cancel: this.translator.instant('common.button.abort'),
          },
        },
      })
      .afterClosed()
      .subscribe((isConfirmed) => {
        if (isConfirmed) {
          this.onSubmit();
        }
      });
  }

  onClose(removeCardFromList = false) {
    if (this.flashcardId) {
      this.closeSideMenu.emit(removeCardFromList);
      return;
    }
    if (this.courseId) {
      this.router.navigate([
        `/cms/courses/edit/${this.courseId}`,
        { course: this.courseId, 'flash-card-stack': this.flashCardStackId },
      ]);
    } else if (this.bookId) {
      this.router.navigate([
        `/cms/books/edit/${this.bookId}`,
        { book: this.bookId, 'flash-card-stack': this.flashCardStackId },
      ]);
    } else if (this.questionId || this.duplicateFlashCardId) {
      this.location.back();
    } else if (this.flashCardStackId) {
      this.router.navigateByUrl(
        `/cms/content/${this.flashCardStackId}/flash-cards?from=stack`
      );
    } else if (this.commentId) {
      this.router.navigate(['/cms/content/comments'], {
        queryParams: { isFlashcard: true },
      });
    } else {
      if (!this.flashCard || this.flashCard.accepted) {
        this.router.navigate(['/cms/content/flash-cards']);
      } else {
        this.router.navigate(['/cms/content/flash-cards'], {
          queryParams: { showSuggestion: true },
        });
      }
    }
  }

  exportAsJson() {
    let fullItem: FlashCardModel;
    fullItem = cloneDeep(this.flashCard);
    delete fullItem.id;
    fullItem['meta:importType'] = PanelEntityType.FlashCard;
    const file = `${JSON.stringify(fullItem)}\n`;
    const blob = new Blob([file], { type: 'application/json' });
    fileSaver.saveAs(
      blob,
      `qa-${PanelEntityType.FlashCard}-${fullItem.text}.json`
    );
  }

  convertToQuestion() {
    this.router.navigate(['/cms/add/question'], {
      queryParams: { flashCardId: this.flashCard.id.toString() },
    });
  }

  openDialog() {
    this.matDialog
      .open(AttachMediaDialogComponent, {
        autoFocus: false,
        width: '45vw',
        panelClass: 'attach-media-dialog-container',
      })
      .afterClosed()
      .subscribe((res) => {
        if (res) {
          if (res.file_ext) {
            this.form.patchValue({ media: res });
          } else if (typeof res === 'string') {
            this.form.patchValue({ youtube_link_question: res });
          }
        }
        this.form.markAsDirty();
        this.form.markAllAsTouched();
      });
  }

  removeImage() {
    this.dialog
      .confirmDelete()
      .pipe(filter((isConfirmed) => isConfirmed))
      .subscribe(() => {
        this.form.controls.youtube_link_question.reset();
        this.form.controls.media.reset();
        this.form.controls.youtube_link_question.markAsDirty();
        this.form.controls.media.markAsDirty();
      });
  }

  applyTemplate(template: any): void {
    this.showForm = false;
    this.form.patchValue(template.default);
    window.setTimeout(() => {
      this.showForm = true;
      this.form.markAsDirty();
    }, 0);
  }

  openInfoDialog() {
    this.matDialog.open<ShowInfoDialogComponent, ShowInfoItem>(
      ShowInfoDialogComponent,
      {
        data: {
          infoData: this.translator.instant(
            'flashCard.form.imageSelectionTooltip'
          ),
        },
        width: '576px',
        height: 'auto',
        panelClass: 'tooltip-modal',
      }
    );
  }
}
