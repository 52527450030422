import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SafeHtml } from '@angular/platform-browser';
import { QrData, QrModalComponent } from '../qr-model.component';
import { MatDialog } from '@angular/material/dialog';
import { editionPrefix } from 'src/app/app.component';

@Component({
  selector: 'qa-qr-code-box',
  templateUrl: './qr-code-box.component.html',
  styleUrls: ['./qr-code-box.component.scss'],
})
export class QrCodeBoxComponent {
  @Input() pinCode: string;
  @Input() qrSvg: SafeHtml;
  @Input() qrData: QrData;

  @Output() openCodeBox = new EventEmitter<void>();

  editionPrefix = editionPrefix();

  constructor(private matDialog: MatDialog) {}

  openQrModel() {
    if (this.openCodeBox.observed) {
      this.openCodeBox.emit();
      return;
    }
    this.matDialog.open(QrModalComponent, {
      data: this.qrData,
    });
  }
}
