<div class="stack-statistics">
  <div class="stack-statistics-container">
    <div class="stack-statistics-title">
      {{ 'course.statistics.stackStatistic' | translate }}
    </div>
    <div class="stack-statistics-table">
      <div class="table">
        <div class="tab-header list">
          <div class="header-item">{{ 'course.table.name' | translate }}</div>
          <div class="header-item">
            {{ 'course.statistics.played' | translate }}
          </div>
        </div>
        <div
          class="list table-border"
          *ngFor="let stat of stats; let i = index"
          (click)="onStackClick(stat)"
        >
          <div class="cell-item">{{ stat?.stack.name }}</div>
          <div class="cell-item">{{ stat?.total_answers }}</div>
        </div>
      </div>
    </div>
  </div>
</div>
