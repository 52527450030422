import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

export interface CustomSideTab {
  name: string;
  value: number;
  number?: number;
  icon?: string;
  paramName?: string;
  externalLink?: string;
}

@Component({
  selector: 'qa-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.scss'],
})
export class SideMenuComponent implements OnInit {
  @Input() tabList: CustomSideTab[] = [];
  @Input() sideMenuTitle: string;
  @Output() selectedTab = new EventEmitter();
  active = '';

  constructor(route: ActivatedRoute) {
    route.queryParams.subscribe((params) => {
      if (params?.tab) {
        this.active = params?.tab;
      }
    });
  }

  ngOnInit(): void {
    if (!this.active) {
      this.active = this.tabList[0].paramName;
    }
  }

  onMenuClick(i: number, tab: CustomSideTab) {
    if (tab.externalLink) {
      window.open(tab.externalLink, '_blank');
      return;
    }
    this.active = tab.paramName;
    let obj = {
      index: i,
      tab: tab.paramName,
    };
    this.selectedTab.emit(obj);
  }
}
