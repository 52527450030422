import { Edit } from '../edit.types';
import { map, tap } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { UserJourneyService } from '../../pages/onboarding-steps/user-journey.service';
import { BooksService } from '../../../api/services';
import { BookModel } from '../../../api/models';
import { Injectable } from '@angular/core';

@Injectable()
export class BookEditConfigService extends Edit.ConfigService<BookModel> {
  constructor(
    books: BooksService,
    translator: TranslateService,
    userJourney: UserJourneyService
  ) {
    super({
      itemName: translator.instant('common.label.book'),
      id: 'book',
      deleteConfirmText: translator.instant('book.deleteConfirmText'),
      deleteConfirmTitle: translator.instant('book.deleteConfirmTitle'),
      fields: [
        {
          key: 'name',
          label: translator.instant('book.form.name'),
          required: true,
        },
        {
          key: 'description',
          label: translator.instant('book.form.description'),
          multiLine: true,
          hideIf: () => true,
        },
      ],
      handlers: {
        update: (c) => {
          return books
            .updateBooks({ id: c.id, body: c })
            .pipe(
              map((c) => {
                return c;
              })
            )
            .toPromise();
        },
        create: (c) => {
          return books
            .createBook({ body: c })
            .pipe(
              tap(() => {
                userJourney.set('createdBook');
              }),
              map((c) => {
                return c;
              })
            )
            .toPromise();
        },
        delete: (c) => books.deleteBooks({ id: c.id }).toPromise(),
        init: async (initialValue) => {
          return initialValue;
        },
      },
    });
  }
}
