import {
  Component,
  ElementRef,
  Input,
  OnChanges,
  AfterViewInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import * as _ from 'lodash';
import { areListsTheSame, asWordList } from './helpers';
import wordCloud, { WordCloudWord } from './generate-word-cloud';

@Component({
  selector: 'qa-word-cloud-visualization',
  templateUrl: './word-cloud-visualization.component.html',
  styleUrls: ['./word-cloud-visualization.component.scss'],
})
export class WordCloudVisualizationComponent
  implements AfterViewInit, OnChanges
{
  @ViewChild('wordCloud') wordCloudContainer: ElementRef<HTMLDivElement>;

  @Input() words: string[];

  private wordList: WordCloudWord[];

  private cloudRef;

  constructor() {}

  ngAfterViewInit(): void {
    this.cloudRef = wordCloud(this.wordCloudContainer.nativeElement);

    this.buildWordCloud(this.words);
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.buildWordCloud(changes.words.currentValue);
  }

  buildWordCloud(words: string[]) {
    if (!this.wordCloudContainer?.nativeElement) {
      return;
    }
    const rect = this.wordCloudContainer?.nativeElement.getBoundingClientRect();
    const list = asWordList(words, rect.width, rect.height);
    if (areListsTheSame(this.wordList ?? [], list)) {
      return;
    }
    this.wordList = list;
    this.drawCloud();
  }

  private drawCloud() {
    if (!this.wordList?.length || !this.cloudRef) {
      return;
    }
    this.cloudRef.update(_.cloneDeep(this.wordList));
  }
}
