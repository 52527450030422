import { Input, Directive } from '@angular/core';
import { AbstractControl } from '@angular/forms';

@Directive()
export abstract class FormField {
  @Input()
  control: AbstractControl;

  @Input()
  hint: string;

  @Input()
  label: string;

  @Input()
  required: boolean;
}
