import {
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable()
export class ApiKeyInterceptor implements HttpInterceptor {
  intercept(request: HttpRequest<unknown>, next: HttpHandler) {
    return next.handle(
      request.url.indexOf('googleapis') === -1
        ? request.clone({
            setHeaders: {
              'x-api-key': environment.api.key,
            },
          })
        : request
    );
  }
}
