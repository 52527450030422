<div class="question-overview-list">
  <table class="table">
    <tr class="tab-header list">
      <td class="position">Nr.</td>
      <td class="question text-style" (click)="sort('alphabetical')">
        <div class="td-content">
          {{ 'question.label.question' | translate }}
          <ng-container *ngIf="currentSorting === 'alphabetical'">
            <mat-icon *ngIf="sortDir === 'ASC'">arrow_drop_up</mat-icon>
            <mat-icon *ngIf="sortDir === 'DESC'">arrow_drop_down</mat-icon>
          </ng-container>
        </div>
      </td>
      <td class="score right-or-wrong text-style" (click)="sort('rightWrong')">
        <div class="td-content">
          {{ 'question.label.rightWrongUnanswered' | translate }}
          <ng-container *ngIf="currentSorting === 'rightWrong'">
            <mat-icon *ngIf="sortDir === 'ASC'">arrow_drop_up</mat-icon>
            <mat-icon *ngIf="sortDir === 'DESC'">arrow_drop_down</mat-icon>
          </ng-container>
        </div>
      </td>
      <td class="result text-style" (click)="sort('result')" colspan="2">
        <div class="td-content">
          {{ 'exam.result.result' | translate }}
          <ng-container *ngIf="currentSorting === 'result'">
            <mat-icon *ngIf="sortDir === 'ASC'">arrow_drop_up</mat-icon>
            <mat-icon *ngIf="sortDir === 'DESC'">arrow_drop_down</mat-icon>
          </ng-container>
        </div>
      </td>
    </tr>
    <ng-container *ngIf="questions?.length">
      <tr
        class="list table-border"
        (click)="getQuestionResult(question, i)"
        *ngFor="let question of questions; let i = index"
      >
        <td class="position table-data-style">{{ i + 1 }}</td>
        <td class="question table-data-style">{{ question?.text }}</td>
        <td class="score table-data-style">
          <div class="td-content">
            <ng-container
              *ngIf="
                question.type === questionType.FREETEXT &&
                question.openQuestionAnsweredCount > 0
              "
            >
              <qa-custom-chip
                [label]="
                  'exam.result.ungradedAnswers'
                    | translate : { count: question.openQuestionAnsweredCount }
                "
              ></qa-custom-chip>
            </ng-container>
          </div>
        </td>
        <td class="score right-or-wrong">
          <div class="td-content">
            <ng-container
              *ngIf="
                question?.type !== questionType.FREETEXT &&
                  !question?.is_poll_question;
                else freeTextHint
              "
            >
              <div class="bar">
                <div
                  [style.width]="getRightResult(question)"
                  class="right-ans"
                ></div>
                <div
                  [style.width]="getWrongResult(question)"
                  class="wrong-ans"
                ></div>
              </div>
              <span
                >{{ question.num_correct_answers }}/{{
                  question.total_result - question.num_correct_answers
                }}{{
                  !hideUnAnswered ? '/' + question.num_unanswered : ''
                }}</span
              >
            </ng-container>
            <ng-template #freeTextHint>
              <span class="open-question-indicator">
                {{
                  'exam.result.numOfAnswer'
                    | translate : { count: question.total_num_answers }
                }}
              </span>
            </ng-template>
          </div>
        </td>
        <td class="result table-data-style">
          {{
            question?.is_poll_question ||
            question.type === questionType.FREETEXT
              ? '--'
              : getCorrectRatio(question)
          }}
        </td>
      </tr>
    </ng-container>
  </table>
</div>
