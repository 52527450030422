<div class="counters">
  <a
    [routerLink]="counter.link"
    *ngFor="let counter of counters"
    class="counter"
  >
    <mat-icon>
      {{ counter.icon }}
    </mat-icon>
    <div class="descr">
      <div [class.small]="counter.count > 9999" class="count">
        {{ counter.count }}
      </div>
      <div class="text">
        {{ counter.text }}
      </div>
    </div>
  </a>
</div>
