/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { BookModel } from '../models/book-model';
import { BookQuery } from '../models/book-query';
import { BookQueryResponse } from '../models/book-query-response';
import { FlashCardStackModel } from '../models/flash-card-stack-model';
import { LiveEvent } from '../models/live-event';
import { PositionChangeModel } from '../models/position-change-model';
import { QuizModel } from '../models/quiz-model';

@Injectable({
  providedIn: 'root',
})
export class BooksService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation createBook
   */
  static readonly CreateBookPath = '/books';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createBook()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createBook$Response(params: {
    cognitouserid?: string;
    cognitousergroup?: Array<string>;
    context?: HttpContext
    body: BookModel
  }
): Observable<StrictHttpResponse<BookModel>> {

    const rb = new RequestBuilder(this.rootUrl, BooksService.CreateBookPath, 'post');
    if (params) {
      rb.header('cognitouserid', params.cognitouserid, {});
      rb.header('cognitousergroup', params.cognitousergroup, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BookModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `createBook$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createBook(params: {
    cognitouserid?: string;
    cognitousergroup?: Array<string>;
    context?: HttpContext
    body: BookModel
  }
): Observable<BookModel> {

    return this.createBook$Response(params).pipe(
      map((r: StrictHttpResponse<BookModel>) => r.body as BookModel)
    );
  }

  /**
   * Path part for operation queryBooks
   */
  static readonly QueryBooksPath = '/books/query';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `queryBooks()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  queryBooks$Response(params: {
    cognitouserid?: string;
    cognitousergroup?: Array<string>;
    context?: HttpContext
    body: BookQuery
  }
): Observable<StrictHttpResponse<BookQueryResponse>> {

    const rb = new RequestBuilder(this.rootUrl, BooksService.QueryBooksPath, 'post');
    if (params) {
      rb.header('cognitouserid', params.cognitouserid, {});
      rb.header('cognitousergroup', params.cognitousergroup, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BookQueryResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `queryBooks$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  queryBooks(params: {
    cognitouserid?: string;
    cognitousergroup?: Array<string>;
    context?: HttpContext
    body: BookQuery
  }
): Observable<BookQueryResponse> {

    return this.queryBooks$Response(params).pipe(
      map((r: StrictHttpResponse<BookQueryResponse>) => r.body as BookQueryResponse)
    );
  }

  /**
   * Path part for operation getBookById
   */
  static readonly GetBookByIdPath = '/books/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getBookById()` instead.
   *
   * This method doesn't expect any request body.
   */
  getBookById$Response(params: {
    id: number;
    cognitouserid?: string;
    full: boolean;
    cognitousergroup?: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<BookModel>> {

    const rb = new RequestBuilder(this.rootUrl, BooksService.GetBookByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.header('cognitouserid', params.cognitouserid, {});
      rb.query('full', params.full, {});
      rb.header('cognitousergroup', params.cognitousergroup, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BookModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getBookById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getBookById(params: {
    id: number;
    cognitouserid?: string;
    full: boolean;
    cognitousergroup?: string;
    context?: HttpContext
  }
): Observable<BookModel> {

    return this.getBookById$Response(params).pipe(
      map((r: StrictHttpResponse<BookModel>) => r.body as BookModel)
    );
  }

  /**
   * Path part for operation updateBooks
   */
  static readonly UpdateBooksPath = '/books/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateBooks()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateBooks$Response(params: {
    id: number;
    cognitouserid?: string;
    cognitousergroup?: Array<string>;
    context?: HttpContext
    body: BookModel
  }
): Observable<StrictHttpResponse<BookModel>> {

    const rb = new RequestBuilder(this.rootUrl, BooksService.UpdateBooksPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.header('cognitouserid', params.cognitouserid, {});
      rb.header('cognitousergroup', params.cognitousergroup, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BookModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateBooks$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateBooks(params: {
    id: number;
    cognitouserid?: string;
    cognitousergroup?: Array<string>;
    context?: HttpContext
    body: BookModel
  }
): Observable<BookModel> {

    return this.updateBooks$Response(params).pipe(
      map((r: StrictHttpResponse<BookModel>) => r.body as BookModel)
    );
  }

  /**
   * Path part for operation deleteBooks
   */
  static readonly DeleteBooksPath = '/books/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteBooks()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteBooks$Response(params: {
    id: number;
    cognitouserid?: string;
    cognitousergroup?: Array<string>;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<BookModel>> {

    const rb = new RequestBuilder(this.rootUrl, BooksService.DeleteBooksPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
      rb.header('cognitouserid', params.cognitouserid, {});
      rb.header('cognitousergroup', params.cognitousergroup, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BookModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteBooks$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteBooks(params: {
    id: number;
    cognitouserid?: string;
    cognitousergroup?: Array<string>;
    context?: HttpContext
  }
): Observable<BookModel> {

    return this.deleteBooks$Response(params).pipe(
      map((r: StrictHttpResponse<BookModel>) => r.body as BookModel)
    );
  }

  /**
   * Path part for operation getQuizForBook
   */
  static readonly GetQuizForBookPath = '/books/{id}/quizzes';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getQuizForBook()` instead.
   *
   * This method doesn't expect any request body.
   */
  getQuizForBook$Response(params: {
    id: number;
    cognitouserid?: string;
    cognitousergroup?: Array<string>;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<QuizModel>>> {

    const rb = new RequestBuilder(this.rootUrl, BooksService.GetQuizForBookPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.header('cognitouserid', params.cognitouserid, {});
      rb.header('cognitousergroup', params.cognitousergroup, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<QuizModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getQuizForBook$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getQuizForBook(params: {
    id: number;
    cognitouserid?: string;
    cognitousergroup?: Array<string>;
    context?: HttpContext
  }
): Observable<Array<QuizModel>> {

    return this.getQuizForBook$Response(params).pipe(
      map((r: StrictHttpResponse<Array<QuizModel>>) => r.body as Array<QuizModel>)
    );
  }

  /**
   * Path part for operation linkQuizToBook
   */
  static readonly LinkQuizToBookPath = '/books/{id}/quizzes';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `linkQuizToBook()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  linkQuizToBook$Response(params: {
    id: number;
    cognitouserid?: string;
    cognitousergroup?: Array<string>;
    context?: HttpContext
    body: Array<QuizModel>
  }
): Observable<StrictHttpResponse<Array<QuizModel>>> {

    const rb = new RequestBuilder(this.rootUrl, BooksService.LinkQuizToBookPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.header('cognitouserid', params.cognitouserid, {});
      rb.header('cognitousergroup', params.cognitousergroup, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<QuizModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `linkQuizToBook$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  linkQuizToBook(params: {
    id: number;
    cognitouserid?: string;
    cognitousergroup?: Array<string>;
    context?: HttpContext
    body: Array<QuizModel>
  }
): Observable<Array<QuizModel>> {

    return this.linkQuizToBook$Response(params).pipe(
      map((r: StrictHttpResponse<Array<QuizModel>>) => r.body as Array<QuizModel>)
    );
  }

  /**
   * Path part for operation copyQuizInBook
   */
  static readonly CopyQuizInBookPath = '/books/{id}/quizzes';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `copyQuizInBook()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  copyQuizInBook$Response(params: {
    id: number;
    cognitouserid?: string;
    cognitousergroup?: Array<string>;
    context?: HttpContext
    body: Array<QuizModel>
  }
): Observable<StrictHttpResponse<Array<QuizModel>>> {

    const rb = new RequestBuilder(this.rootUrl, BooksService.CopyQuizInBookPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.header('cognitouserid', params.cognitouserid, {});
      rb.header('cognitousergroup', params.cognitousergroup, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<QuizModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `copyQuizInBook$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  copyQuizInBook(params: {
    id: number;
    cognitouserid?: string;
    cognitousergroup?: Array<string>;
    context?: HttpContext
    body: Array<QuizModel>
  }
): Observable<Array<QuizModel>> {

    return this.copyQuizInBook$Response(params).pipe(
      map((r: StrictHttpResponse<Array<QuizModel>>) => r.body as Array<QuizModel>)
    );
  }

  /**
   * Path part for operation removeQuizFromBook
   */
  static readonly RemoveQuizFromBookPath = '/books/{id}/quizzes';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `removeQuizFromBook()` instead.
   *
   * This method doesn't expect any request body.
   */
  removeQuizFromBook$Response(params: {
    id: number;
    quiz_ids?: string;
    cognitouserid?: string;
    cognitousergroup?: Array<string>;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<QuizModel>>> {

    const rb = new RequestBuilder(this.rootUrl, BooksService.RemoveQuizFromBookPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('quiz_ids', params.quiz_ids, {});
      rb.header('cognitouserid', params.cognitouserid, {});
      rb.header('cognitousergroup', params.cognitousergroup, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<QuizModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `removeQuizFromBook$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  removeQuizFromBook(params: {
    id: number;
    quiz_ids?: string;
    cognitouserid?: string;
    cognitousergroup?: Array<string>;
    context?: HttpContext
  }
): Observable<Array<QuizModel>> {

    return this.removeQuizFromBook$Response(params).pipe(
      map((r: StrictHttpResponse<Array<QuizModel>>) => r.body as Array<QuizModel>)
    );
  }

  /**
   * Path part for operation repositionQuizInBook
   */
  static readonly RepositionQuizInBookPath = '/books/{id}/quizzes/{quiz_id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `repositionQuizInBook()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  repositionQuizInBook$Response(params: {
    id: number;
    quiz_id: number;
    cognitouserid?: string;
    cognitousergroup?: Array<string>;
    context?: HttpContext
    body: PositionChangeModel
  }
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, BooksService.RepositionQuizInBookPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('quiz_id', params.quiz_id, {});
      rb.header('cognitouserid', params.cognitouserid, {});
      rb.header('cognitousergroup', params.cognitousergroup, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `repositionQuizInBook$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  repositionQuizInBook(params: {
    id: number;
    quiz_id: number;
    cognitouserid?: string;
    cognitousergroup?: Array<string>;
    context?: HttpContext
    body: PositionChangeModel
  }
): Observable<void> {

    return this.repositionQuizInBook$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation getEventsInBook
   */
  static readonly GetEventsInBookPath = '/books/{id}/live_events';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getEventsInBook()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEventsInBook$Response(params: {
    id: number;
    cognitouserid?: string;
    cognitousergroup?: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<LiveEvent>>> {

    const rb = new RequestBuilder(this.rootUrl, BooksService.GetEventsInBookPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.header('cognitouserid', params.cognitouserid, {});
      rb.header('cognitousergroup', params.cognitousergroup, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<LiveEvent>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getEventsInBook$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEventsInBook(params: {
    id: number;
    cognitouserid?: string;
    cognitousergroup?: string;
    context?: HttpContext
  }
): Observable<Array<LiveEvent>> {

    return this.getEventsInBook$Response(params).pipe(
      map((r: StrictHttpResponse<Array<LiveEvent>>) => r.body as Array<LiveEvent>)
    );
  }

  /**
   * Path part for operation addEventToBook
   */
  static readonly AddEventToBookPath = '/books/{id}/live_events';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addEventToBook()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addEventToBook$Response(params: {
    id: number;
    cognitouserid?: string;
    cognitousergroup?: string;
    context?: HttpContext
    body: LiveEvent
  }
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, BooksService.AddEventToBookPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.header('cognitouserid', params.cognitouserid, {});
      rb.header('cognitousergroup', params.cognitousergroup, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `addEventToBook$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addEventToBook(params: {
    id: number;
    cognitouserid?: string;
    cognitousergroup?: string;
    context?: HttpContext
    body: LiveEvent
  }
): Observable<void> {

    return this.addEventToBook$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation removeEventFromBook
   */
  static readonly RemoveEventFromBookPath = '/books/{id}/live_events/{event_id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `removeEventFromBook()` instead.
   *
   * This method doesn't expect any request body.
   */
  removeEventFromBook$Response(params: {
    id: number;
    event_id: number;
    cognitouserid?: string;
    cognitousergroup?: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, BooksService.RemoveEventFromBookPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('event_id', params.event_id, {});
      rb.header('cognitouserid', params.cognitouserid, {});
      rb.header('cognitousergroup', params.cognitousergroup, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `removeEventFromBook$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  removeEventFromBook(params: {
    id: number;
    event_id: number;
    cognitouserid?: string;
    cognitousergroup?: string;
    context?: HttpContext
  }
): Observable<void> {

    return this.removeEventFromBook$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation getCardStacksInBook
   */
  static readonly GetCardStacksInBookPath = '/books/{id}/flash_card_stacks';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCardStacksInBook()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCardStacksInBook$Response(params: {
    id: number;
    cognitouserid?: string;
    cognitousergroup?: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<FlashCardStackModel>>> {

    const rb = new RequestBuilder(this.rootUrl, BooksService.GetCardStacksInBookPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.header('cognitouserid', params.cognitouserid, {});
      rb.header('cognitousergroup', params.cognitousergroup, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<FlashCardStackModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getCardStacksInBook$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCardStacksInBook(params: {
    id: number;
    cognitouserid?: string;
    cognitousergroup?: string;
    context?: HttpContext
  }
): Observable<Array<FlashCardStackModel>> {

    return this.getCardStacksInBook$Response(params).pipe(
      map((r: StrictHttpResponse<Array<FlashCardStackModel>>) => r.body as Array<FlashCardStackModel>)
    );
  }

  /**
   * Path part for operation linkCardStackToBook
   */
  static readonly LinkCardStackToBookPath = '/books/{id}/flash_card_stacks';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `linkCardStackToBook()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  linkCardStackToBook$Response(params: {
    id: number;
    cognitouserid?: string;
    cognitousergroup?: string;
    context?: HttpContext
    body: Array<FlashCardStackModel>
  }
): Observable<StrictHttpResponse<Array<FlashCardStackModel>>> {

    const rb = new RequestBuilder(this.rootUrl, BooksService.LinkCardStackToBookPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.header('cognitouserid', params.cognitouserid, {});
      rb.header('cognitousergroup', params.cognitousergroup, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<FlashCardStackModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `linkCardStackToBook$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  linkCardStackToBook(params: {
    id: number;
    cognitouserid?: string;
    cognitousergroup?: string;
    context?: HttpContext
    body: Array<FlashCardStackModel>
  }
): Observable<Array<FlashCardStackModel>> {

    return this.linkCardStackToBook$Response(params).pipe(
      map((r: StrictHttpResponse<Array<FlashCardStackModel>>) => r.body as Array<FlashCardStackModel>)
    );
  }

  /**
   * Path part for operation copyCardStackToBook
   */
  static readonly CopyCardStackToBookPath = '/books/{id}/flash_card_stacks';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `copyCardStackToBook()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  copyCardStackToBook$Response(params: {
    id: number;
    cognitouserid?: string;
    copyCards: boolean;
    cognitousergroup?: string;
    context?: HttpContext
    body: Array<FlashCardStackModel>
  }
): Observable<StrictHttpResponse<Array<FlashCardStackModel>>> {

    const rb = new RequestBuilder(this.rootUrl, BooksService.CopyCardStackToBookPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.header('cognitouserid', params.cognitouserid, {});
      rb.query('copyCards', params.copyCards, {});
      rb.header('cognitousergroup', params.cognitousergroup, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<FlashCardStackModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `copyCardStackToBook$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  copyCardStackToBook(params: {
    id: number;
    cognitouserid?: string;
    copyCards: boolean;
    cognitousergroup?: string;
    context?: HttpContext
    body: Array<FlashCardStackModel>
  }
): Observable<Array<FlashCardStackModel>> {

    return this.copyCardStackToBook$Response(params).pipe(
      map((r: StrictHttpResponse<Array<FlashCardStackModel>>) => r.body as Array<FlashCardStackModel>)
    );
  }

  /**
   * Path part for operation removeCardStackFromBook
   */
  static readonly RemoveCardStackFromBookPath = '/books/{id}/flash_card_stacks';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `removeCardStackFromBook()` instead.
   *
   * This method doesn't expect any request body.
   */
  removeCardStackFromBook$Response(params: {
    id: number;
    stack_ids?: string;
    cognitouserid?: string;
    cognitousergroup?: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, BooksService.RemoveCardStackFromBookPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('stack_ids', params.stack_ids, {});
      rb.header('cognitouserid', params.cognitouserid, {});
      rb.header('cognitousergroup', params.cognitousergroup, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `removeCardStackFromBook$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  removeCardStackFromBook(params: {
    id: number;
    stack_ids?: string;
    cognitouserid?: string;
    cognitousergroup?: string;
    context?: HttpContext
  }
): Observable<void> {

    return this.removeCardStackFromBook$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation repositionCardStackInBook
   */
  static readonly RepositionCardStackInBookPath = '/books/{id}/flash_card_stacks/{stack_id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `repositionCardStackInBook()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  repositionCardStackInBook$Response(params: {
    id: number;
    stack_id: number;
    cognitouserid?: string;
    cognitousergroup?: string;
    context?: HttpContext
    body: PositionChangeModel
  }
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, BooksService.RepositionCardStackInBookPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('stack_id', params.stack_id, {});
      rb.header('cognitouserid', params.cognitouserid, {});
      rb.header('cognitousergroup', params.cognitousergroup, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `repositionCardStackInBook$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  repositionCardStackInBook(params: {
    id: number;
    stack_id: number;
    cognitouserid?: string;
    cognitousergroup?: string;
    context?: HttpContext
    body: PositionChangeModel
  }
): Observable<void> {

    return this.repositionCardStackInBook$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

}
