<h1 mat-dialog-title>
  {{ data.title }}
</h1>

<mat-dialog-content *ngIf="data?.text">
  <ng-container *ngFor="let mes of data.text">
    <div [innerHTML]="parse(mes)"></div>
  </ng-container>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button
    [color]="button.color"
    [mat-dialog-close]="button.value"
    *ngFor="let button of data.buttons"
    color="primary"
    mat-button
  >
    <mat-icon *ngIf="button.icon">
      {{ button.icon }}
    </mat-icon>
    {{ button.text }}
  </button>
</mat-dialog-actions>
