<div class="edit-block" [class.disabled]="disabled">
  <mat-icon class="material-icons" *ngIf="icon">{{ icon }}</mat-icon>
  <ng-container *ngIf="label"> {{ label | translate }}:&nbsp; </ng-container>
  <div
    #editable
    [attr.title]="value"
    class="edit-name"
    [attr.contenteditable]="disabled ? false : true"
    (blur)="saveField($event)"
  >
    {{ value }}
  </div>
  <mat-icon (click)="editable.focus()" class="edit-action" *ngIf="!disabled"
    >edit</mat-icon
  >
</div>
