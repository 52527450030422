import { Component } from '@angular/core';
import { CategoryModel } from 'src/app/api/models';
import { CategoriesService } from 'src/app/api/services';
import { CategoryEditConfigService } from '../edit/configs/category.service';
import { EditService } from '../edit/edit.service';

@Component({
  selector: 'qa-category-list',
  templateUrl: './category-list.component.html',
  styleUrls: ['./category-list.component.scss'],
})
export class CategoryListComponent {
  categoryList: CategoryModel[];
  openPanels: number[] = [1];

  constructor(
    private categories: CategoriesService,
    private editService: EditService
  ) {
    this.refresh();
  }

  findEntryFor(entryId: number) {
    return this.categoryList?.find((cat) => cat.id === entryId) ?? null;
  }

  findChildrenFor(entryId: number) {
    return this.categoryList?.filter((cat) => cat.parent === entryId) ?? [];
  }

  refresh() {
    this.categories
      .getCategories({
        as_tree: false,
      })
      .subscribe((cats) => {
        this.categoryList = cats;
      });
  }

  selectPanel(entry: CategoryModel, level: number) {
    if (this.openPanels[level]) {
      this.openPanels[level] = entry.id;
    } else {
      this.openPanels.push(entry.id);
    }
    this.openPanels = this.openPanels.slice(0, level + 1);
  }

  async edit(event, element: CategoryModel) {
    event.preventDefault();
    event.stopPropagation();
    await this.editService.open(CategoryEditConfigService, element);
    this.refresh();
  }

  async createCategory(parent: number) {
    await this.editService.open(CategoryEditConfigService, null, null, {
      parent,
    });
    this.refresh();
  }
}
