import {
  Component,
  OnInit,
  ViewChild,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import dayjs from 'dayjs/esm';
import {
  DaterangepickerDirective,
  LocaleConfig,
} from 'ngx-daterangepicker-material';
import * as localization from 'dayjs/locale/de';
import * as utc from 'dayjs/plugin/utc';

@Component({
  selector: 'qa-custom-date-range',
  templateUrl: './custom-date-range.component.html',
  styleUrl: './custom-date-range.component.scss',
})
export class CustomDateRangeComponent implements OnInit, OnChanges {
  @ViewChild(DaterangepickerDirective, { static: true })
  daterangepicker: DaterangepickerDirective;
  @Output() dateSelected = new EventEmitter<any>();
  @Input() label = 'exam.setup.startTime';
  @Input() minDate: dayjs.Dayjs;
  @Input() maxDate: dayjs.Dayjs;
  @Input() value;
  @Input() disabled = false;

  options = {
    alwaysShowCalendars: false,
    showCancel: true,
    showClearButton: false,
    linkedCalendars: true,
    singleDatePicker: true,
    showWeekNumbers: false,
    showISOWeekNumbers: false,
    customRangeDirection: false,
    lockStartDate: false,
    closeOnAutoApply: true,
  };

  locale: LocaleConfig;

  opens: string;
  drops: string;
  timePicker: boolean;
  dateLimit: number;
  selected = { start: dayjs(), end: dayjs() };

  constructor(private translator: TranslateService) {
    dayjs.extend(utc);
    this.timePicker = true;
    this.opens = 'right';
    this.drops = 'down';
    this.dateLimit = 30;
    if (this.translator.currentLang === 'de') {
      this.locale = {
        daysOfWeek: localization.weekdaysMin,
        monthNames: localization.monthsShort,
        firstDay: localization.weekStart,
        cancelLabel: this.translator.instant('calendar.cancelLabel'),
        applyLabel: this.translator.instant('calendar.applyLabel'),
        format: 'DD.MM.YYYY',
        displayFormat: 'DD.MM.YYYY HH.mm.ss',
      };
    } else {
      this.locale = {
        cancelLabel: this.translator.instant('calendar.cancelLabel'),
        applyLabel: this.translator.instant('calendar.applyLabel'),
        format: 'DD.MM.YYYY',
        displayFormat: 'DD.MM.YYYY HH.mm.ss',
      };
    }
  }

  ngOnInit(): void {
    if (this.value) {
      this.selected = { start: this.value, end: this.value };
    }
    if (!this.minDate) {
      this.minDate = dayjs();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.minDate) {
      this.minDate = dayjs(changes.minDate.currentValue);
    }
    if (changes && changes.maxDate) {
      this.maxDate = changes.maxDate.currentValue;
    }
    if (changes && changes.value) {
      this.value = changes.value.currentValue;
      if (dayjs(this.value).isValid()) {
        this.selected = { start: this.value, end: this.value };
      }
    }
  }

  click(): void {}

  clear(): void {
    this.daterangepicker.clear();
  }

  openDatepicker(): void {
    this.daterangepicker.open();
  }

  eventClicked(e): void {
    e.startDate = dayjs(e.startDate).subtract(dayjs().utcOffset(), 'minute');
    e.endDate = dayjs(e.endDate).subtract(dayjs().utcOffset(), 'minute');
    this.dateSelected.emit(e);
  }

  eventCleared(): void {
    // eslint-disable-next-line no-console
  }
}
