import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  AnswerModel,
  BaseQueryResponse,
  CategoryModel,
  CourseModel,
  CourseQuery,
  FlashCardStackModel,
  QuizModel,
} from 'src/app/api/models';
import {
  AccessRequestsService,
  CategoriesService,
  CoursesService,
} from 'src/app/api/services';
import { ListConfig, ListQuery } from './list.types';
import { Router } from '@angular/router';
import { DialogService } from '../common/dialog.service';
import { filter, switchMap, tap } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import {
  HelpVideoDialogComponent,
  HelpVideoDialogData,
} from '../common/help-video-dialog.component';
import { PanelService } from '../panel/panel.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as _ from 'lodash';
import * as TurnDown from 'turndown';
import { QuestionType } from 'src/app/app.types';
import { CoursePanel } from '../panel/panels/course-panel';
import { ExamPanel } from '../panel/panels/exam-panel';
import { ExamQuestionPanel } from '../panel/panels/exam-question-panel';
import { FlashCardPanel } from '../panel/panels/flash-card-panel';
import { FlashCardStackPanel } from '../panel/panels/flash-card-stack-panel';
import { QuestionPanel } from '../panel/panels/question-panel';
import { QuizPanel } from '../panel/panels/quiz-panel';
import { PanelEntityType } from '../panel/panel.types';
import { EventsPanel } from '../panel/panels/events-panel';
import { ListDataSource } from './list-data-source';
import { EventQuestionPanel } from '../panel/panels/event-question-panel';
import { AuthService } from 'src/app/auth/auth.service';
import { isArray } from 'lodash';
import {
  ImportContentDialogComponent,
  ImportContentDialogData,
} from '../common/import-content-dialog/import-content-dialog.component';
import { multiSelectAction } from './grid-data/grid-data.component';
import { ListInfo } from './no-entity-info/no-entity-info.component';
import { EditService } from '../edit/edit.service';
import { CourseEditConfigService } from '../edit/configs/course.service';
import { ChipType } from 'src/app/shared/components/custom-chip/custom-chip.component';
import { TreeNode } from '../common/form-field/tree-select.component';
import { parseCategoryTree } from 'src/app/utility/app.utils';
import { SubscriptionLimitService } from '../common/dialogs/subscription-limit-warning/subscription-limit.service';
import { SubscriptionLimit } from '../common/dialogs/subscription-limit-warning/subscription-limit-warning.component';

@Component({
  selector: 'qa-course-list',
  templateUrl: './course-list.component.html',
  styleUrls: ['./course-list.component.scss'],
})
export class CourseListComponent implements OnInit {
  config: ListConfig<CourseModel>;
  isCourseAvailable = false;
  listInfo: ListInfo = {
    videoUrl: 'https://www.youtube-nocookie.com/embed/82SjD_In68Q',
    learnMoreText: 'courses.Learn more at the Course Academy',
    content: 'courses.courseContent',
    links: [
      {
        label: 'courses.courseLinks.link1',
        url: 'https://quizacademy.de/akademie/kurse/#Einsatzm%C3%B6glichkeiten',
      },
      {
        label: 'courses.courseLinks.link2',
        url: 'https://quizacademy.de/akademie/kurse/#Kurs+erstellen',
      },
      {
        label: 'courses.courseLinks.link3',
        url: 'https://quizacademy.de/akademie/kurse/#Kurse+hinzuf%C3%BCgen',
      },
      {
        label: 'courses.courseLinks.link4',
        url: 'https://quizacademy.de/akademie/kurse/#Kurs+freigeben',
      },
      {
        label: 'courses.courseLinks.link5',
        url: 'https://quizacademy.de/akademie/kurse/',
      },
    ],
  };

  constructor(
    private accessRequestsService: AccessRequestsService,
    private courses: CoursesService,
    private matDialog: MatDialog,
    private router: Router,
    private dialog: DialogService,
    private translator: TranslateService,
    private categories: CategoriesService,
    private panelService: PanelService,
    private snackBar: MatSnackBar,
    private coursePanel: CoursePanel,
    private examPanel: ExamPanel,
    private examQuestionPanel: ExamQuestionPanel,
    private flashCardPanel: FlashCardPanel,
    private flashCardStackPanel: FlashCardStackPanel,
    private questionPanel: QuestionPanel,
    private quizPanel: QuizPanel,
    private eventsPanel: EventsPanel,
    private eventsQuestionPanel: EventQuestionPanel,
    private editService: EditService,
    private auth: AuthService,
    private subscriptionLimit: SubscriptionLimitService
  ) {}

  ngOnInit() {
    this.openHelp(true);
    this.initPanels();
    this.categories
      .getCategories({ as_tree: true })
      .subscribe((categories: CategoryModel[]) => {
        const categoriesList = parseCategoryTree(categories);
        categoriesList[0].openedChildren = true;
        this.config = getCourseListConfig(
          this.courses,
          this.matDialog,
          this.router,
          this.dialog,
          this.translator,
          true,
          categoriesList,
          this.panelService,
          this.snackBar,
          this.auth,
          this.accessRequestsService,
          this.editService,
          this.subscriptionLimit
        );
        this.config.isDisabled = (course: CourseModel) => {
          return course?.is_pending_access ?? false;
        };
        this.config.showMessageInActionColumn = (course: CourseModel) => {
          return course?.is_pending_access ? 'courseMarket.waitingAccess' : '';
        };
      });
  }

  initPanels() {
    this.coursePanel.isBook = false;
    this.quizPanel.isBook = false;
    this.flashCardStackPanel.isBook = false;
    this.eventsPanel.isBook = false;
    const panels = [
      this.coursePanel,
      this.quizPanel,
      this.questionPanel,
      this.flashCardStackPanel,
      this.flashCardPanel,
      this.examPanel,
      this.examQuestionPanel,
      this.eventsPanel,
      this.eventsQuestionPanel,
    ];
    this.panelService.init(panels);

    this.coursePanel.initializePanel();
    this.quizPanel.initializePanel();
    this.eventsPanel.initializePanel();
    this.flashCardStackPanel.initializePanel();
  }

  openHelp(skipIfAlreadyShown?: boolean) {
    this.matDialog.open<HelpVideoDialogComponent, HelpVideoDialogData>(
      HelpVideoDialogComponent,
      {
        data: {
          skipIfAlreadyShown,
          videoName: 'dash_content',
        },
      }
    );
  }

  async createCourse() {
    const course = await this.editService.open(CourseEditConfigService);
    this.router.navigateByUrl(`/cms/courses/edit/${course.id}`);
  }

  importCourseContent() {
    this.matDialog.open<ImportContentDialogComponent, ImportContentDialogData>(
      ImportContentDialogComponent,
      {
        data: {
          imports: this.config.imports,
          dataSource: null,
          title: this.translator.instant('common.label.course'),
        },
      }
    );
  }
}

export function getCourseListConfig(
  courses: CoursesService,
  matDialog: MatDialog,
  router: Router,
  dialog: DialogService,
  translator: TranslateService,
  showMultiselect: boolean,
  categories: TreeNode[],
  panelService: PanelService,
  snackBar: MatSnackBar,
  auth: AuthService,
  accessRequestsService: AccessRequestsService,
  editService: EditService,
  subscriptionLimit: SubscriptionLimitService
): ListConfig<CourseModel> {
  function importFromJson(
    panelService: PanelService,
    meta: ListDataSource,
    entity?: CourseModel
  ) {
    const panel = panelService.getPanel(PanelEntityType.Course);
    panelService.importItemAsJSON(panel, entity, meta);
  }

  async function importCourseFromRepiticoJSON(
    matSnackBar: MatSnackBar,
    panelService: PanelService,
    translator: TranslateService,
    meta: ListDataSource,
    importFn: any
  ) {
    const contents = (await panelService.promptFile('json')) as any[];
    matSnackBar.open(translator.instant('content.message.preparingImport'));
    const turndown = new TurnDown['default']();
    const quiz = {
      name: translator.instant('content.message.queFromRepitico'),
    } as Partial<QuizModel> as QuizModel;
    const flashCardStack = {
      name: translator.instant('content.message.flashCardFromRepitico'),
    } as Partial<FlashCardStackModel> as FlashCardStackModel;
    const course: Partial<CourseModel> = {
      name: translator.instant('content.message.courseFromRepitico'),
      category: { id: 1 } as Partial<CategoryModel> as CategoryModel,
      card_stacks: [flashCardStack],
      quizzes: [quiz],
    };
    const trueRegex = /^(richtig|ja)\.?$/i;
    const falseRegex = /^(falsch|nein)\.?$/i;
    quiz.questions =
      contents && _.isArray(contents)
        ? contents
            .filter((content) => Boolean(content.mchoice))
            .map((repiticoQuestion) => {
              const explanation =
                turndown.turndown(repiticoQuestion.answer) +
                '\n\n' +
                translator.instant('common.label.categories') +
                ':\n' +
                repiticoQuestion.categories.join('\n');
              const answers: AnswerModel[] = (
                repiticoQuestion.mchoice as any[]
              ).map((answer) => {
                return {
                  text: turndown.turndown(answer.answer),
                  is_right: Boolean(answer.correct),
                } as Partial<AnswerModel> as AnswerModel;
              });
              const isTrueFalse =
                answers.length === 2 &&
                ((trueRegex.test(answers[0].text) &&
                  falseRegex.test(answers[1].text)) ||
                  (trueRegex.test(answers[1].text) &&
                    falseRegex.test(answers[0].text)));
              return {
                text: turndown.turndown(repiticoQuestion.question),
                explanation,
                type: isTrueFalse
                  ? QuestionType.TRUE_FALSE
                  : QuestionType.MULTIPLE_CHOICE,
                answers: isTrueFalse ? null : answers,
                is_right: isTrueFalse
                  ? trueRegex.test(answers[0].text)
                    ? answers[0].is_right
                    : !answers[0].is_right
                  : null,
              };
            })
        : [];
    flashCardStack.cards =
      contents && isArray(contents) && contents.length
        ? contents
            .filter((content) => !content.mchoice)
            .map((repiticoCard) => {
              return {
                text: turndown.turndown(repiticoCard.question),
                answer: turndown.turndown(repiticoCard.answer),
                explanation:
                  translator.instant('common.label.categories') +
                  ':\n' +
                  repiticoCard.categories.join('\n'),
              };
            })
        : [];
    importFn(panelService, meta, course);
  }

  function exportItemAsJSON(panelService: PanelService, courseId: number) {
    const panel = panelService.getPanel(PanelEntityType.Course);
    panelService.exportItemAsJSON(panel, courseId);
  }

  const ret: ListConfig<CourseModel> = {
    chip: {
      label: (meta) => {
        return meta.row.is_active ? 'course.published' : 'course.unPublished';
      },
      type: (meta) => {
        return meta.row.is_active ? ChipType.PRIMARY_LIGHT : ChipType.LIGHT;
      },
    },
    actions: [
      {
        icon: 'play_circle',
        name: 'edit',
        label: 'course.tooltip.select',
        click: (meta) => {
          router.navigateByUrl(`/cms/courses/edit/${meta.row.id}`);
        },
        tooltip: 'course.tooltip.select',
        type: 'button',
      },
      {
        icon: 'timeline',
        name: 'stats',
        label: translator.instant('content.quiz.actionMenu.item3'),
        click: (meta) => {
          router.navigateByUrl(
            `/cms/courses/edit/${meta.row.id}?showStatistics=true`
          );
        },
        tooltip: 'content.quiz.actionMenu.item3',
        type: 'button',
      },
    ],
    actionsMenu: [
      {
        icon: 'save_alt',
        name: 'download',
        click: (meta) => exportItemAsJSON(panelService, meta.row.id),
        label: 'content.course.actionMenu.item4',
        type: 'button',
      },
      {
        click: ({ row, dataSource }) => {
          dialog
            .confirmDelete()
            .pipe(
              filter((isConfirmed) => isConfirmed),
              switchMap(() => courses.deleteCourse({ id: row.id }))
            )
            .subscribe({
              next: () => {
                snackBar.open(
                  translator.instant('content.course.deleteItem.success')
                );
                dataSource.refresh();
              },
              error: () => {
                snackBar.open(
                  translator.instant('content.course.deleteItem.error')
                );
              },
            });
        },
        icon: 'delete',
        label: 'common.button.clear',
        name: 'delete',
        type: 'button',
      },
      {
        icon: 'code',
        name: 'Embed',
        click: (row) => {
          const data = {
            coursePin: row.row.pin_code,
            entity: 'course',
          };
          dialog.openEmbedModel(data);
        },
        label: 'content.course.actionMenu.item2',
        type: 'button',
      },
    ],
    disabledActionsMenu: [
      {
        icon: 'undo',
        name: 'revoke',
        click: (meta) => {
          accessRequestsService
            .deleteCourseAccessRequest({ id: meta.row.access_request.id })
            .subscribe(() => {
              snackBar.open(translator.instant('course.tooltip.revokeAccess'));
              meta.dataSource.refresh();
            });
        },
        label: 'course.revokeAccess',
        type: 'button',
      },
    ],
    activeActionNames: ['edit', 'stats', 'chip'],
    activeColumnNames: ['id', 'name', 'category', 'num_questions'],
    columns: [
      {
        label: 'ID',
        name: 'id',
        sortable: true,
        width: '64px',
        click: (meta) => {
          !meta.row?.is_pending_access
            ? router.navigateByUrl(`/cms/courses/edit/${meta.row.id}`)
            : null;
        },
        filter: (value) => {
          return {
            id: Number(value),
          };
        },
      },
      {
        label: 'course.table.name',
        click: (meta) => {
          !meta.row?.is_pending_access
            ? router.navigateByUrl(`/cms/courses/edit/${meta.row.id}`)
            : null;
        },
        name: 'name',
        sortable: true,
        primary: true,
        filter: (value) => {
          return {
            name: value,
          };
        },
        icon: (meta) => {
          if (meta.row?.is_pending_access) {
            return null;
          }
          if (
            meta.row.ref_course_id ||
            (auth && meta.row.created_by !== auth.currentUserInfo$.value.sub)
          ) {
            return 'shopping_cart';
          } else if (meta.row.available_for_coursemarket) {
            return 'ios_share';
          } else {
            return null;
          }
        },
      },
      {
        accessor: (row) => row.category && row.category.name,
        label: 'course.table.category',
        name: 'category',
        sortable: false,
        showTreeSelect: true,
        width: '244px',
        click: (meta) => {
          !meta.row?.is_pending_access
            ? router.navigateByUrl(`/cms/courses/edit/${meta.row.id}`)
            : null;
        },
        filter: (value) => {
          return {
            category: value,
          };
        },
        filterOptions: categories,
      },
    ],
    showSubscriptionIndicator: true,
    subscriptionLimit: SubscriptionLimit.COURSE_COUNT,
    imports: [
      {
        title: translator.instant('content.course.addMenu.item2'),
        description: translator.instant('content.course.description.item2'),
        action: {
          icon: 'code',
          click: (dataSource) => importFromJson(panelService, dataSource, null),
          text: translator.instant('content.course.addMenu.item2'),
        },
      },
      {
        title: translator.instant('content.course.addMenu.item3'),
        description: translator.instant('content.course.description.item3'),
        action: {
          icon: 'code',
          click: (dataSource) =>
            importCourseFromRepiticoJSON(
              snackBar,
              panelService,
              translator,
              dataSource,
              importFromJson
            ),
          text: translator.instant('content.course.addMenu.item3'),
        },
      },
      {
        title: translator.instant('content.course.addMenu.item4'),
        description: translator.instant('content.course.description.item4'),
        action: {
          icon: 'shopping_cart',
          text: translator.instant('content.course.addMenu.item4'),
          click: () => {
            matDialog.closeAll();
            router.navigateByUrl('/cms/course-market');
          },
        },
      },
      {
        title: translator.instant('content.course.addMenu.item5'),
        description: translator.instant('content.course.description.item5'),
        action: {
          text: translator.instant('content.course.addMenu.extra'),
          icon: 'keyboard_arrow_right',
          click: () => {
            window.open(
              'https://quizacademy.de/preise/anfrage-content/',
              '_blank'
            );
          },
        },
      },
    ],
    createButton: {
      options: [
        {
          text: translator.instant('common.button.createNew'),
          icon: 'add',
          click: async () => {
            const course = await editService.open(CourseEditConfigService);
            if (course) {
              router.navigateByUrl(`/cms/courses/edit/${course.id}`);
            }
          },
        },
      ],
    },
    fetch: (queryParams?: ListQuery<CourseModel>) => {
      const query = queryParams as unknown as CourseQuery;
      if (query && query.filters) {
        query.filters = {
          ...{ include_pending_for_access: true },
          ...query.filters,
        };
      }
      return courses.queryCourses({ body: query }).pipe(
        tap((response: BaseQueryResponse) => {
          subscriptionLimit.subscriptionLimit.COURSE_COUNT =
            response.pagination.total;
        })
      );
    },
    query: {
      initial: {
        sorting: [
          {
            option: 'id',
            direction: 'DESC',
          },
        ],
      },
      searchColumnName: 'name',
    },
    name: 'courseList',
    title: 'course.title',
    tutorial: 'dash_content',
    extraTutorial: 'https://quizacademy.de/akademie/kurse/',
    tutorialLabel: 'dashboardNew.courseCard.videoButton',
    extraTutorialLabel: 'dashboardNew.courseCard.academicButton',
    showChip: true,
  };

  if (showMultiselect) {
    ret.multiselectActions = [
      {
        icon: 'delete',
        name: 'question.tooltip.delete',
        type: 'button',
        click: (meta) => {
          const nonPendingAccess = meta.rows.filter(
            (m) => !m.is_pending_access
          );
          if (!nonPendingAccess.length && meta.rows.length) {
            snackBar.open(
              translator.instant("Can't delete course which can not accessed")
            );
            return Promise.resolve(null);
          }
          return new Promise((resolve) => {
            dialog
              .confirmDelete(
                null,
                translator.instant('common.message.deleteMulti', {
                  count: nonPendingAccess.length,
                })
              )
              .pipe(filter((isConfirmed) => isConfirmed))
              .subscribe(async () => {
                await multiSelectAction(
                  nonPendingAccess,
                  async (item: any) => {
                    await courses.deleteCourse({ id: item.id }).toPromise();
                    return true;
                  },
                  `${translator.instant(
                    'common.label.deleting'
                  )} ${translator.instant('common.label.course')}`,
                  matDialog
                );
                meta.dataSource.refresh();
                resolve();
              });
          });
        },
      },
    ];
  }

  return ret;
}
