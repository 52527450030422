import { createAction, props } from '@ngrx/store';
import { FlashCardStackModel } from 'src/app/api/models';

export const destroy = createAction('[Flash Card] Destroy');

export const flipCard = createAction(
  '[Flash Card] Flip Card',
  props<{ id: number }>()
);

export const cardSubmitted = createAction(
  '[Flash Card] Card Submitted',
  props<{ id: number; knowAnswer: boolean }>()
);

export const init = createAction(
  '[Flash Card] Init',
  props<{ id: number; book: boolean }>()
);

export const storeStack = createAction(
  '[Flash Card] Store Stack',
  props<{ stack: FlashCardStackModel; book: boolean }>()
);

export const stackFailed = createAction('[Flash Card] Stack failed');
