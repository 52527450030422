export const qaTheme = {
  name: 'QuizAcademy',
  bpm: 100,
  resources: [
    {
      id: 'start01',
      path: 'theme03_start01.mp3',
      length: 1,
    },
    {
      id: 'start02',
      path: 'theme03_start02.mp3',
      length: 1,
    },
    {
      id: 'idle01',
      path: 'theme03_idle01.mp3',
      loop: true,
      length: 4,
    },
    {
      id: 'idle02',
      path: 'theme03_idle02.mp3',
      loop: true,
      length: 4,
    },
    {
      id: 'idle03',
      path: 'theme03_idle03.mp3',
      loop: true,
      length: 8,
    },
    {
      id: 'idle04',
      path: 'theme03_idle04.mp3',
      loop: true,
      length: 8,
    },
    {
      id: 'interlude',
      path: 'theme03_interlude01.mp3',
      length: 2,
    },
    {
      id: 'end01',
      path: 'theme03_end01.mp3',
      length: 3,
    },
    {
      id: 'end02',
      path: 'theme03_end02.mp3',
      length: 3,
    },
  ],
  timelines: [
    [
      {
        id: '$FILL',
        pos: 0,
        options: [
          {
            id: 'start01',
            minInARow: 1,
            maxInARow: 1,
          },
          {
            id: 'start02',
            minInARow: 1,
            maxInARow: 1,
          },
        ],
      },
      {
        id: '$FILL',
        pos: 1,
        options: [
          {
            id: 'idle01',
            minInARow: 1,
            maxInARow: 1,
          },
          {
            id: 'idle02',
            minInARow: 1,
            maxInARow: 1,
          },
          {
            id: 'idle03',
            minInARow: 1,
            maxInARow: 1,
          },
          {
            id: 'idle04',
            minInARow: 1,
            maxInARow: 1,
          },
          {
            id: 'interlude',
            minInARow: 1,
            maxInARow: 1,
          },
        ],
      },
      {
        id: '$FILL',
        pos: -2,
        options: [
          {
            id: 'end01',
            minInARow: 1,
            maxInARow: 1,
          },
          {
            id: 'end02',
            minInARow: 1,
            maxInARow: 1,
          },
        ],
      },
    ],
  ],
};
