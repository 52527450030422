import {
  OnInit,
  Component,
  HostBinding,
  Input,
  OnDestroy,
  Output,
  ViewChild,
  ViewEncapsulation,
  ElementRef,
  EventEmitter,
  ChangeDetectorRef,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import {
  ConfigOption,
  DEFAULT_PAGE_SIZE,
  ListAction,
  ListColumn,
  ListConfig,
} from '../../list.types';
import { ListDataSource } from '../../list-data-source';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Filter, FilterService } from '../../../common/filter.service';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { MatSelectChange } from '@angular/material/select';
import { TreeNode } from 'src/app/cms/common/form-field/tree-select.component';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'qa-course-market-list',
  templateUrl: './course-market-list.component.html',
  styleUrls: ['./course-market-list.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CourseMarketListComponent implements OnInit, OnDestroy {
  config: ListConfig;
  @Input() providers: ConfigOption[] = [];
  @Input() categoriesList: ConfigOption[] = [];
  @Output() selectedConfig = new EventEmitter<ListConfig>();
  @Output() showFindOutContent = new EventEmitter<boolean>();
  @Output() courseMarketListSource = new EventEmitter<ListDataSource>();
  @ViewChild(MatSort)
  private matSort: MatSort;
  @ViewChild(MatPaginator)
  private matPaginator: MatPaginator;
  @ViewChild('addButton', { read: ElementRef }) addButton: ElementRef;
  possibleFilters: Array<ListColumn<any>> = [];
  dragDisabled = true;
  dataSource: ListDataSource;
  DEFAULT_PAGE_SIZE = DEFAULT_PAGE_SIZE;
  searchControl = new UntypedFormControl();
  categoryControl = new UntypedFormControl({
    id: 'all',
    parent: null,
    name: this.translator.instant('courseMarket.allCategories'),
    icon_code: 'category',
  });
  providersControl = new UntypedFormControl('');
  subscription: Subscription;

  @HostBinding('class.hide-wrapper')
  get hideWrapper(): boolean {
    return this.config && this.config.hideWrapper;
  }
  get activeActions(): ListAction[] {
    return this.config.actions.filter((action) =>
      this.config.activeActionNames.includes(action.name)
    );
  }
  get actionsColumnWidth(): number {
    return (
      this.activeActions.filter((eachData) => {
        return !eachData.hide;
      }).length * 300
    );
  }
  get displayedColumnNames(): string[] {
    let showColumns = [];
    if (this.config?.activeColumnNames) {
      showColumns = this.config.activeColumnNames;
    }
    const displayedColumnNames = (
      this.config.columns.map((col) => col.name) as string[]
    ).filter((col) => showColumns.includes(col));
    if (this.config.actions && !displayedColumnNames.includes('actions')) {
      displayedColumnNames.push('actions');
    }
    if (
      !this.config.multiselectActions &&
      displayedColumnNames.includes('multiselectActions')
    ) {
      displayedColumnNames.shift();
    }
    if (!this.config.actions && displayedColumnNames.includes('actions')) {
      displayedColumnNames.pop();
    }
    if (this.config?.onPositionDrag) {
      displayedColumnNames.unshift('drag');
    }
    return displayedColumnNames;
  }

  constructor(
    private filterService: FilterService,
    private matSnackBar: MatSnackBar,
    private cdkRef: ChangeDetectorRef,
    elementRef: ElementRef<HTMLElement>,
    private translator: TranslateService
  ) {
    elementRef.nativeElement.classList.add('qa-course-market-list');
  }

  ngOnInit(): void {
    if (this.providers?.length) {
      this.config = this.providers[0].config;
      this.selectedConfig.emit(this.config);
    }
    sessionStorage.removeItem('courseMarketListQuery');
    setTimeout(() => {
      this.init();
    });
  }

  ngOnDestroy(): void {
    this.filterService.applyFilters(null);
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  isFunction(inp: any): boolean {
    return typeof inp === 'function';
  }

  private init() {
    if (!this.matPaginator || !this.matSort) {
      return;
    }
    this.dataSource = new ListDataSource(
      this.config.fetch,
      {
        ...{ initial: {}, isLocal: false, searchColumnName: null },
        ...(this.config.query || {}),
      },
      'courseMarketListQuery',
      this.matPaginator,
      this.matSort,
      this.matSnackBar
    );
    this.cdkRef.detectChanges();
    this.courseMarketListSource.emit(this.dataSource);
    this.subscription = this.filterService.filter$.subscribe({
      next: (filters: Filter) => {
        if (filters) {
          if (this.config.name === filters.forName) {
            this.dataSource.filter.next(filters.filters);
          }
        }
      },
    });
    this.possibleFilters = this.config.columns.filter((col) => col.filter);
  }

  searchByName() {
    const value = this.searchControl.value;
    if (!value) {
      this.removeFilter('search_query');
      this.showFindOutContent.emit(false);
    } else {
      const filter = {
        search_query: value,
      };
      this.showFindOutContent.emit(true);
      this.applyFilters(filter);
    }
  }

  clearSearch() {
    this.searchControl.reset();
    this.searchByName();
  }

  changeCategory(select: TreeNode) {
    if (!select) {
      return;
    }
    const value = [select.value];
    if (value && value.find((v) => !v.id || v.id === 'all')) {
      this.removeFilter('category');
    } else {
      const filter = {
        category: value,
      };
      this.applyFilters(filter);
    }
  }

  changeProvider(select: MatSelectChange) {
    if (!select.value) {
      this.config = this.providers[0].config;
    } else {
      const provider = this.providers.find((p) => p.value === select.value);
      if (provider) {
        this.config = provider.config;
      }
    }
    this.searchControl.reset();
    this.categoryControl.reset();
    this.selectedConfig.emit(this.config);
    this.init();
  }

  applyFilters(filters) {
    if (!filters) {
      return;
    }
    const appliedFilters = this.dataSource.filter.value || {};
    this.filterService.applyFilters({
      forName: this.config.name,
      filters: { ...appliedFilters, ...filters },
    });
    this.matPaginator.firstPage();
  }

  removeFilter(filterKey): void {
    const filters = this.dataSource.filter.value || {};
    delete filters[filterKey];
    this.filterService.applyFilters({
      forName: this.config.name,
      filters,
    });
    this.matPaginator.firstPage();
  }

  afterItemDropped(event: CdkDragDrop<any>) {
    if (!this.config?.onPositionDrag) {
      return;
    }
    const list = this.dataSource.getData().value;
    moveItemInArray(list, event.previousIndex, event.currentIndex);
    this.dataSource.getData().next(list);
    this.config
      .onPositionDrag(event.item.data, event.currentIndex + 1)
      .subscribe(() => {
        this.dataSource.refresh();
      });
  }
}
