import {
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { UserConfigService } from 'src/app/cms/user-config.service';

@Component({
  selector: 'qa-embedded-video',
  templateUrl: './embedded-video.component.html',
  styleUrls: ['./embedded-video.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class EmbeddedVideoComponent implements OnInit {
  @Input() videoid: string;
  @Input() description: string;
  @Input() autoplay = false;
  videolink: SafeResourceUrl;
  isBookmarked = false;

  constructor(
    private domSanitizer: DomSanitizer,
    private elementRef: ElementRef<HTMLElement>,
    private matSnackBar: MatSnackBar,
    private translator: TranslateService,
    private userConfig: UserConfigService
  ) {
    this.elementRef.nativeElement.classList.add('qa-embedded-video');
  }

  async ngOnInit() {
    this.isBookmarked =
      (await this.userConfig.get(`videos.watchLater.${this.videoid}`)) ===
      'yes';
    this.videolink = this.domSanitizer.bypassSecurityTrustResourceUrl(
      `https://www.youtube-nocookie.com/embed/${this.videoid}${
        this.autoplay ? '?autoplay=1' : ''
      }`
    );
  }

  async bookmark() {
    await this.userConfig.set(`videos.watchLater.${this.videoid}`, `yes`);
    this.isBookmarked = true;
    this.matSnackBar.open(
      this.translator.instant('embeddedVideo.scheduleInfo')
    );
  }

  async unbookmark() {
    await this.userConfig.remove(`videos.watchLater.${this.videoid}`);
    this.isBookmarked = false;
  }

  watchLater() {
    return this.isBookmarked;
  }
}
