/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { EmailRequest } from '../models/email-request';
import { Exam } from '../models/exam';
import { ExamEvaluation } from '../models/exam-evaluation';
import { ExamQuery } from '../models/exam-query';
import { ExamQueryResponse } from '../models/exam-query-response';
import { ExamResultModel } from '../models/exam-result-model';
import { ExamUser } from '../models/exam-user';
import { PositionChangeModel } from '../models/position-change-model';
import { QuestionModel } from '../models/question-model';

@Injectable({
  providedIn: 'root',
})
export class ExamsService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation createExam
   */
  static readonly CreateExamPath = '/exams';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createExam()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createExam$Response(params: {
    cognitouserid?: string;
    cognitousergroup?: string;
    context?: HttpContext
    body: Exam
  }
): Observable<StrictHttpResponse<Exam>> {

    const rb = new RequestBuilder(this.rootUrl, ExamsService.CreateExamPath, 'post');
    if (params) {
      rb.header('cognitouserid', params.cognitouserid, {});
      rb.header('cognitousergroup', params.cognitousergroup, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Exam>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `createExam$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createExam(params: {
    cognitouserid?: string;
    cognitousergroup?: string;
    context?: HttpContext
    body: Exam
  }
): Observable<Exam> {

    return this.createExam$Response(params).pipe(
      map((r: StrictHttpResponse<Exam>) => r.body as Exam)
    );
  }

  /**
   * Path part for operation queryExams
   */
  static readonly QueryExamsPath = '/exams/query';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `queryExams()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  queryExams$Response(params: {
    cognitouserid?: string;
    cognitousergroup?: string;
    context?: HttpContext
    body: ExamQuery
  }
): Observable<StrictHttpResponse<ExamQueryResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ExamsService.QueryExamsPath, 'post');
    if (params) {
      rb.header('cognitouserid', params.cognitouserid, {});
      rb.header('cognitousergroup', params.cognitousergroup, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ExamQueryResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `queryExams$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  queryExams(params: {
    cognitouserid?: string;
    cognitousergroup?: string;
    context?: HttpContext
    body: ExamQuery
  }
): Observable<ExamQueryResponse> {

    return this.queryExams$Response(params).pipe(
      map((r: StrictHttpResponse<ExamQueryResponse>) => r.body as ExamQueryResponse)
    );
  }

  /**
   * Path part for operation getExamById
   */
  static readonly GetExamByIdPath = '/exams/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getExamById()` instead.
   *
   * This method doesn't expect any request body.
   */
  getExamById$Response(params: {
    cognitouserid?: string;
    cognitousergroup?: string;
    id: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Exam>> {

    const rb = new RequestBuilder(this.rootUrl, ExamsService.GetExamByIdPath, 'get');
    if (params) {
      rb.header('cognitouserid', params.cognitouserid, {});
      rb.header('cognitousergroup', params.cognitousergroup, {});
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Exam>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getExamById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getExamById(params: {
    cognitouserid?: string;
    cognitousergroup?: string;
    id: number;
    context?: HttpContext
  }
): Observable<Exam> {

    return this.getExamById$Response(params).pipe(
      map((r: StrictHttpResponse<Exam>) => r.body as Exam)
    );
  }

  /**
   * Path part for operation updateExam
   */
  static readonly UpdateExamPath = '/exams/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateExam()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateExam$Response(params: {
    cognitouserid?: string;
    cognitousergroup?: string;
    id: number;
    context?: HttpContext
    body: Exam
  }
): Observable<StrictHttpResponse<Exam>> {

    const rb = new RequestBuilder(this.rootUrl, ExamsService.UpdateExamPath, 'put');
    if (params) {
      rb.header('cognitouserid', params.cognitouserid, {});
      rb.header('cognitousergroup', params.cognitousergroup, {});
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Exam>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateExam$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateExam(params: {
    cognitouserid?: string;
    cognitousergroup?: string;
    id: number;
    context?: HttpContext
    body: Exam
  }
): Observable<Exam> {

    return this.updateExam$Response(params).pipe(
      map((r: StrictHttpResponse<Exam>) => r.body as Exam)
    );
  }

  /**
   * Path part for operation deleteExam
   */
  static readonly DeleteExamPath = '/exams/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteExam()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteExam$Response(params: {
    cognitouserid?: string;
    cognitousergroup?: string;
    id: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Exam>> {

    const rb = new RequestBuilder(this.rootUrl, ExamsService.DeleteExamPath, 'delete');
    if (params) {
      rb.header('cognitouserid', params.cognitouserid, {});
      rb.header('cognitousergroup', params.cognitousergroup, {});
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Exam>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteExam$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteExam(params: {
    cognitouserid?: string;
    cognitousergroup?: string;
    id: number;
    context?: HttpContext
  }
): Observable<Exam> {

    return this.deleteExam$Response(params).pipe(
      map((r: StrictHttpResponse<Exam>) => r.body as Exam)
    );
  }

  /**
   * Path part for operation getQuestionsForExam
   */
  static readonly GetQuestionsForExamPath = '/exams/{id}/questions';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getQuestionsForExam()` instead.
   *
   * This method doesn't expect any request body.
   */
  getQuestionsForExam$Response(params: {
    cognitouserid?: string;
    cognitousergroup?: string;
    id: number;
    embed_answers?: boolean;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<QuestionModel>>> {

    const rb = new RequestBuilder(this.rootUrl, ExamsService.GetQuestionsForExamPath, 'get');
    if (params) {
      rb.header('cognitouserid', params.cognitouserid, {});
      rb.header('cognitousergroup', params.cognitousergroup, {});
      rb.path('id', params.id, {});
      rb.query('embed_answers', params.embed_answers, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<QuestionModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getQuestionsForExam$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getQuestionsForExam(params: {
    cognitouserid?: string;
    cognitousergroup?: string;
    id: number;
    embed_answers?: boolean;
    context?: HttpContext
  }
): Observable<Array<QuestionModel>> {

    return this.getQuestionsForExam$Response(params).pipe(
      map((r: StrictHttpResponse<Array<QuestionModel>>) => r.body as Array<QuestionModel>)
    );
  }

  /**
   * Path part for operation linkQuestionToExam
   */
  static readonly LinkQuestionToExamPath = '/exams/{id}/questions';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `linkQuestionToExam()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  linkQuestionToExam$Response(params: {
    cognitouserid?: string;
    cognitousergroup?: string;
    id: number;
    context?: HttpContext
    body: Array<QuestionModel>
  }
): Observable<StrictHttpResponse<Array<QuestionModel>>> {

    const rb = new RequestBuilder(this.rootUrl, ExamsService.LinkQuestionToExamPath, 'put');
    if (params) {
      rb.header('cognitouserid', params.cognitouserid, {});
      rb.header('cognitousergroup', params.cognitousergroup, {});
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<QuestionModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `linkQuestionToExam$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  linkQuestionToExam(params: {
    cognitouserid?: string;
    cognitousergroup?: string;
    id: number;
    context?: HttpContext
    body: Array<QuestionModel>
  }
): Observable<Array<QuestionModel>> {

    return this.linkQuestionToExam$Response(params).pipe(
      map((r: StrictHttpResponse<Array<QuestionModel>>) => r.body as Array<QuestionModel>)
    );
  }

  /**
   * Path part for operation copyQuestionToExam
   */
  static readonly CopyQuestionToExamPath = '/exams/{id}/questions';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `copyQuestionToExam()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  copyQuestionToExam$Response(params: {
    cognitouserid?: string;
    cognitousergroup?: string;
    id: number;
    context?: HttpContext
    body: Array<QuestionModel>
  }
): Observable<StrictHttpResponse<Array<QuestionModel>>> {

    const rb = new RequestBuilder(this.rootUrl, ExamsService.CopyQuestionToExamPath, 'post');
    if (params) {
      rb.header('cognitouserid', params.cognitouserid, {});
      rb.header('cognitousergroup', params.cognitousergroup, {});
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<QuestionModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `copyQuestionToExam$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  copyQuestionToExam(params: {
    cognitouserid?: string;
    cognitousergroup?: string;
    id: number;
    context?: HttpContext
    body: Array<QuestionModel>
  }
): Observable<Array<QuestionModel>> {

    return this.copyQuestionToExam$Response(params).pipe(
      map((r: StrictHttpResponse<Array<QuestionModel>>) => r.body as Array<QuestionModel>)
    );
  }

  /**
   * Path part for operation removeQuestionFromExam
   */
  static readonly RemoveQuestionFromExamPath = '/exams/{id}/questions';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `removeQuestionFromExam()` instead.
   *
   * This method doesn't expect any request body.
   */
  removeQuestionFromExam$Response(params: {
    cognitouserid?: string;
    cognitousergroup?: string;
    id: number;
    question_ids?: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ExamsService.RemoveQuestionFromExamPath, 'delete');
    if (params) {
      rb.header('cognitouserid', params.cognitouserid, {});
      rb.header('cognitousergroup', params.cognitousergroup, {});
      rb.path('id', params.id, {});
      rb.query('question_ids', params.question_ids, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `removeQuestionFromExam$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  removeQuestionFromExam(params: {
    cognitouserid?: string;
    cognitousergroup?: string;
    id: number;
    question_ids?: string;
    context?: HttpContext
  }
): Observable<void> {

    return this.removeQuestionFromExam$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation repositionQuestionInExam
   */
  static readonly RepositionQuestionInExamPath = '/exams/{id}/questions/{question_id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `repositionQuestionInExam()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  repositionQuestionInExam$Response(params: {
    cognitouserid?: string;
    cognitousergroup?: string;
    id: number;
    question_id: number;
    context?: HttpContext
    body: PositionChangeModel
  }
): Observable<StrictHttpResponse<QuestionModel>> {

    const rb = new RequestBuilder(this.rootUrl, ExamsService.RepositionQuestionInExamPath, 'patch');
    if (params) {
      rb.header('cognitouserid', params.cognitouserid, {});
      rb.header('cognitousergroup', params.cognitousergroup, {});
      rb.path('id', params.id, {});
      rb.path('question_id', params.question_id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<QuestionModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `repositionQuestionInExam$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  repositionQuestionInExam(params: {
    cognitouserid?: string;
    cognitousergroup?: string;
    id: number;
    question_id: number;
    context?: HttpContext
    body: PositionChangeModel
  }
): Observable<QuestionModel> {

    return this.repositionQuestionInExam$Response(params).pipe(
      map((r: StrictHttpResponse<QuestionModel>) => r.body as QuestionModel)
    );
  }

  /**
   * Path part for operation getAllUsersForExam
   */
  static readonly GetAllUsersForExamPath = '/exams/{id}/users';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllUsersForExam()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllUsersForExam$Response(params: {
    cognitouserid?: string;
    cognitousergroup?: string;
    id: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<ExamUser>>> {

    const rb = new RequestBuilder(this.rootUrl, ExamsService.GetAllUsersForExamPath, 'get');
    if (params) {
      rb.header('cognitouserid', params.cognitouserid, {});
      rb.header('cognitousergroup', params.cognitousergroup, {});
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ExamUser>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAllUsersForExam$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllUsersForExam(params: {
    cognitouserid?: string;
    cognitousergroup?: string;
    id: number;
    context?: HttpContext
  }
): Observable<Array<ExamUser>> {

    return this.getAllUsersForExam$Response(params).pipe(
      map((r: StrictHttpResponse<Array<ExamUser>>) => r.body as Array<ExamUser>)
    );
  }

  /**
   * Path part for operation addUsersToExam
   */
  static readonly AddUsersToExamPath = '/exams/{id}/users';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addUsersToExam()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addUsersToExam$Response(params: {
    cognitouserid?: string;
    cognitousergroup?: string;
    id: number;
    context?: HttpContext
    body: Array<ExamUser>
  }
): Observable<StrictHttpResponse<ExamUser>> {

    const rb = new RequestBuilder(this.rootUrl, ExamsService.AddUsersToExamPath, 'post');
    if (params) {
      rb.header('cognitouserid', params.cognitouserid, {});
      rb.header('cognitousergroup', params.cognitousergroup, {});
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ExamUser>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `addUsersToExam$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addUsersToExam(params: {
    cognitouserid?: string;
    cognitousergroup?: string;
    id: number;
    context?: HttpContext
    body: Array<ExamUser>
  }
): Observable<ExamUser> {

    return this.addUsersToExam$Response(params).pipe(
      map((r: StrictHttpResponse<ExamUser>) => r.body as ExamUser)
    );
  }

  /**
   * Path part for operation removeAllUsers
   */
  static readonly RemoveAllUsersPath = '/exams/{id}/users';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `removeAllUsers()` instead.
   *
   * This method doesn't expect any request body.
   */
  removeAllUsers$Response(params: {
    cognitouserid?: string;
    cognitousergroup?: string;
    id: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ExamsService.RemoveAllUsersPath, 'delete');
    if (params) {
      rb.header('cognitouserid', params.cognitouserid, {});
      rb.header('cognitousergroup', params.cognitousergroup, {});
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `removeAllUsers$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  removeAllUsers(params: {
    cognitouserid?: string;
    cognitousergroup?: string;
    id: number;
    context?: HttpContext
  }
): Observable<void> {

    return this.removeAllUsers$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation updateUserFromExam
   */
  static readonly UpdateUserFromExamPath = '/exams/{id}/users/{user_id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateUserFromExam()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateUserFromExam$Response(params: {
    cognitouserid?: string;
    cognitousergroup?: string;
    id: number;
    user_id: string;
    context?: HttpContext
    body: ExamUser
  }
): Observable<StrictHttpResponse<Array<ExamUser>>> {

    const rb = new RequestBuilder(this.rootUrl, ExamsService.UpdateUserFromExamPath, 'put');
    if (params) {
      rb.header('cognitouserid', params.cognitouserid, {});
      rb.header('cognitousergroup', params.cognitousergroup, {});
      rb.path('id', params.id, {});
      rb.path('user_id', params.user_id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ExamUser>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateUserFromExam$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateUserFromExam(params: {
    cognitouserid?: string;
    cognitousergroup?: string;
    id: number;
    user_id: string;
    context?: HttpContext
    body: ExamUser
  }
): Observable<Array<ExamUser>> {

    return this.updateUserFromExam$Response(params).pipe(
      map((r: StrictHttpResponse<Array<ExamUser>>) => r.body as Array<ExamUser>)
    );
  }

  /**
   * Path part for operation removeUserFromExam
   */
  static readonly RemoveUserFromExamPath = '/exams/{id}/users/{user_id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `removeUserFromExam()` instead.
   *
   * This method doesn't expect any request body.
   */
  removeUserFromExam$Response(params: {
    cognitouserid?: string;
    cognitousergroup?: string;
    id: number;
    user_id: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<ExamUser>> {

    const rb = new RequestBuilder(this.rootUrl, ExamsService.RemoveUserFromExamPath, 'delete');
    if (params) {
      rb.header('cognitouserid', params.cognitouserid, {});
      rb.header('cognitousergroup', params.cognitousergroup, {});
      rb.path('id', params.id, {});
      rb.path('user_id', params.user_id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ExamUser>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `removeUserFromExam$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  removeUserFromExam(params: {
    cognitouserid?: string;
    cognitousergroup?: string;
    id: number;
    user_id: string;
    context?: HttpContext
  }
): Observable<ExamUser> {

    return this.removeUserFromExam$Response(params).pipe(
      map((r: StrictHttpResponse<ExamUser>) => r.body as ExamUser)
    );
  }

  /**
   * Path part for operation sendEmailInvitationToExamUsers
   */
  static readonly SendEmailInvitationToExamUsersPath = '/exams/{id}/send-email-invite';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `sendEmailInvitationToExamUsers()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  sendEmailInvitationToExamUsers$Response(params: {
    cognitouserid?: string;
    cognitousergroup?: string;
    id: number;
    context?: HttpContext
    body: EmailRequest
  }
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ExamsService.SendEmailInvitationToExamUsersPath, 'post');
    if (params) {
      rb.header('cognitouserid', params.cognitouserid, {});
      rb.header('cognitousergroup', params.cognitousergroup, {});
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `sendEmailInvitationToExamUsers$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  sendEmailInvitationToExamUsers(params: {
    cognitouserid?: string;
    cognitousergroup?: string;
    id: number;
    context?: HttpContext
    body: EmailRequest
  }
): Observable<void> {

    return this.sendEmailInvitationToExamUsers$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation createExamResultOffline
   */
  static readonly CreateExamResultOfflinePath = '/exams/{id}/result';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createExamResultOffline()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createExamResultOffline$Response(params: {
    cognitouserid?: string;
    cognitousergroup?: string;
    id: number;
    user_id?: string;
    context?: HttpContext
    body: Array<ExamResultModel>
  }
): Observable<StrictHttpResponse<Array<ExamResultModel>>> {

    const rb = new RequestBuilder(this.rootUrl, ExamsService.CreateExamResultOfflinePath, 'post');
    if (params) {
      rb.header('cognitouserid', params.cognitouserid, {});
      rb.header('cognitousergroup', params.cognitousergroup, {});
      rb.path('id', params.id, {});
      rb.query('user_id', params.user_id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ExamResultModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `createExamResultOffline$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createExamResultOffline(params: {
    cognitouserid?: string;
    cognitousergroup?: string;
    id: number;
    user_id?: string;
    context?: HttpContext
    body: Array<ExamResultModel>
  }
): Observable<Array<ExamResultModel>> {

    return this.createExamResultOffline$Response(params).pipe(
      map((r: StrictHttpResponse<Array<ExamResultModel>>) => r.body as Array<ExamResultModel>)
    );
  }

  /**
   * Path part for operation getExamEvaluation
   */
  static readonly GetExamEvaluationPath = '/exams/{id}/evaluation';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getExamEvaluation()` instead.
   *
   * This method doesn't expect any request body.
   */
  getExamEvaluation$Response(params: {
    cognitouserid?: string;
    cognitousergroup?: string;
    id: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<ExamEvaluation>> {

    const rb = new RequestBuilder(this.rootUrl, ExamsService.GetExamEvaluationPath, 'get');
    if (params) {
      rb.header('cognitouserid', params.cognitouserid, {});
      rb.header('cognitousergroup', params.cognitousergroup, {});
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ExamEvaluation>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getExamEvaluation$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getExamEvaluation(params: {
    cognitouserid?: string;
    cognitousergroup?: string;
    id: number;
    context?: HttpContext
  }
): Observable<ExamEvaluation> {

    return this.getExamEvaluation$Response(params).pipe(
      map((r: StrictHttpResponse<ExamEvaluation>) => r.body as ExamEvaluation)
    );
  }

  /**
   * Path part for operation updateExamResults
   */
  static readonly UpdateExamResultsPath = '/exams/{id}/results/{result_id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateExamResults()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateExamResults$Response(params: {
    cognitouserid?: string;
    cognitousergroup?: string;
    id: number;
    result_id: number;
    context?: HttpContext
    body: ExamResultModel
  }
): Observable<StrictHttpResponse<ExamResultModel>> {

    const rb = new RequestBuilder(this.rootUrl, ExamsService.UpdateExamResultsPath, 'put');
    if (params) {
      rb.header('cognitouserid', params.cognitouserid, {});
      rb.header('cognitousergroup', params.cognitousergroup, {});
      rb.path('id', params.id, {});
      rb.path('result_id', params.result_id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ExamResultModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateExamResults$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateExamResults(params: {
    cognitouserid?: string;
    cognitousergroup?: string;
    id: number;
    result_id: number;
    context?: HttpContext
    body: ExamResultModel
  }
): Observable<ExamResultModel> {

    return this.updateExamResults$Response(params).pipe(
      map((r: StrictHttpResponse<ExamResultModel>) => r.body as ExamResultModel)
    );
  }

}
