<div class="top-bar">
  <div class="top-content">
    <img class="logo" src="../assets/logo.png" />
  </div>
</div>

<div class="main-split">
  <div class="padding left"></div>
  <div class="content">
    <div class="left">
      <div class="left-content">
        <ng-content select="[left]"></ng-content>
      </div>
      <div class="links">
        <a target="_blank" href="https://quizacademy.de/agb/">AGB</a>
        <a target="_blank" href="https://quizacademy.de/datenschutz/"
          >Datenschutz</a
        >
        <a target="_blank" href="https://quizacademy.de/impressum/"
          >Impressum</a
        >
      </div>
    </div>
    <div class="right">
      <div class="right-content">
        <ng-content select="[right]"></ng-content>
      </div>
    </div>
  </div>
  <div class="padding right"></div>
</div>
