<strong>Edition:</strong>
<div class="btns">
  <button
    type="button"
    mat-button
    *ngFor="let edition of allEditions"
    [class.selected]="currentEdition == edition.name"
    (click)="selectEdition(edition)"
  >
    <span>{{ 'login.editions.' + edition.name | translate }}</span>
    <mat-icon>{{ edition.icon }}</mat-icon>
  </button>
</div>
