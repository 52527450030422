/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { CommentModel } from '../models/comment-model';
import { FlashCardModel } from '../models/flash-card-model';
import { FlashCardQuery } from '../models/flash-card-query';
import { FlashCardQueryResponse } from '../models/flash-card-query-response';

@Injectable({
  providedIn: 'root',
})
export class FlashCardsService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation createFlashCard
   */
  static readonly CreateFlashCardPath = '/flash_cards';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createFlashCard()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createFlashCard$Response(params: {
    cognitouserid?: string;
    cognitousergroup?: string;
    context?: HttpContext
    body: FlashCardModel
  }
): Observable<StrictHttpResponse<FlashCardModel>> {

    const rb = new RequestBuilder(this.rootUrl, FlashCardsService.CreateFlashCardPath, 'post');
    if (params) {
      rb.header('cognitouserid', params.cognitouserid, {});
      rb.header('cognitousergroup', params.cognitousergroup, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<FlashCardModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `createFlashCard$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createFlashCard(params: {
    cognitouserid?: string;
    cognitousergroup?: string;
    context?: HttpContext
    body: FlashCardModel
  }
): Observable<FlashCardModel> {

    return this.createFlashCard$Response(params).pipe(
      map((r: StrictHttpResponse<FlashCardModel>) => r.body as FlashCardModel)
    );
  }

  /**
   * Path part for operation queryFlashCards
   */
  static readonly QueryFlashCardsPath = '/flash_cards/query';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `queryFlashCards()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  queryFlashCards$Response(params: {
    cognitouserid?: string;
    cognitousergroup?: string;
    context?: HttpContext
    body: FlashCardQuery
  }
): Observable<StrictHttpResponse<FlashCardQueryResponse>> {

    const rb = new RequestBuilder(this.rootUrl, FlashCardsService.QueryFlashCardsPath, 'post');
    if (params) {
      rb.header('cognitouserid', params.cognitouserid, {});
      rb.header('cognitousergroup', params.cognitousergroup, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<FlashCardQueryResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `queryFlashCards$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  queryFlashCards(params: {
    cognitouserid?: string;
    cognitousergroup?: string;
    context?: HttpContext
    body: FlashCardQuery
  }
): Observable<FlashCardQueryResponse> {

    return this.queryFlashCards$Response(params).pipe(
      map((r: StrictHttpResponse<FlashCardQueryResponse>) => r.body as FlashCardQueryResponse)
    );
  }

  /**
   * Path part for operation getFlashCardById
   */
  static readonly GetFlashCardByIdPath = '/flash_cards/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getFlashCardById()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFlashCardById$Response(params: {
    id: number;
    cognitouserid?: string;
    cognitousergroup?: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<FlashCardModel>> {

    const rb = new RequestBuilder(this.rootUrl, FlashCardsService.GetFlashCardByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.header('cognitouserid', params.cognitouserid, {});
      rb.header('cognitousergroup', params.cognitousergroup, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<FlashCardModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getFlashCardById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFlashCardById(params: {
    id: number;
    cognitouserid?: string;
    cognitousergroup?: string;
    context?: HttpContext
  }
): Observable<FlashCardModel> {

    return this.getFlashCardById$Response(params).pipe(
      map((r: StrictHttpResponse<FlashCardModel>) => r.body as FlashCardModel)
    );
  }

  /**
   * Path part for operation updateFlashCard
   */
  static readonly UpdateFlashCardPath = '/flash_cards/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateFlashCard()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateFlashCard$Response(params: {
    id: number;
    cognitouserid?: string;
    cognitousergroup?: string;
    context?: HttpContext
    body: FlashCardModel
  }
): Observable<StrictHttpResponse<FlashCardModel>> {

    const rb = new RequestBuilder(this.rootUrl, FlashCardsService.UpdateFlashCardPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.header('cognitouserid', params.cognitouserid, {});
      rb.header('cognitousergroup', params.cognitousergroup, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<FlashCardModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateFlashCard$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateFlashCard(params: {
    id: number;
    cognitouserid?: string;
    cognitousergroup?: string;
    context?: HttpContext
    body: FlashCardModel
  }
): Observable<FlashCardModel> {

    return this.updateFlashCard$Response(params).pipe(
      map((r: StrictHttpResponse<FlashCardModel>) => r.body as FlashCardModel)
    );
  }

  /**
   * Path part for operation deleteFlashCard
   */
  static readonly DeleteFlashCardPath = '/flash_cards/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteFlashCard()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteFlashCard$Response(params: {
    id: number;
    cognitouserid?: string;
    cognitousergroup?: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<FlashCardModel>> {

    const rb = new RequestBuilder(this.rootUrl, FlashCardsService.DeleteFlashCardPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
      rb.header('cognitouserid', params.cognitouserid, {});
      rb.header('cognitousergroup', params.cognitousergroup, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<FlashCardModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteFlashCard$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteFlashCard(params: {
    id: number;
    cognitouserid?: string;
    cognitousergroup?: string;
    context?: HttpContext
  }
): Observable<FlashCardModel> {

    return this.deleteFlashCard$Response(params).pipe(
      map((r: StrictHttpResponse<FlashCardModel>) => r.body as FlashCardModel)
    );
  }

  /**
   * Path part for operation getCommentsForFlashCard
   */
  static readonly GetCommentsForFlashCardPath = '/flash_cards/{id}/comments';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCommentsForFlashCard()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCommentsForFlashCard$Response(params: {
    cognitouserid?: string;
    cognitousergroup?: string;
    id: number;
    done?: boolean;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<CommentModel>>> {

    const rb = new RequestBuilder(this.rootUrl, FlashCardsService.GetCommentsForFlashCardPath, 'get');
    if (params) {
      rb.header('cognitouserid', params.cognitouserid, {});
      rb.header('cognitousergroup', params.cognitousergroup, {});
      rb.path('id', params.id, {});
      rb.query('done', params.done, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<CommentModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getCommentsForFlashCard$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCommentsForFlashCard(params: {
    cognitouserid?: string;
    cognitousergroup?: string;
    id: number;
    done?: boolean;
    context?: HttpContext
  }
): Observable<Array<CommentModel>> {

    return this.getCommentsForFlashCard$Response(params).pipe(
      map((r: StrictHttpResponse<Array<CommentModel>>) => r.body as Array<CommentModel>)
    );
  }

}
