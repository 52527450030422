<div class="quiz-stats">
  <div class="quiz-statistics-container">
    <div class="quiz-statistics-title">
      {{ 'course.statistics.quizStatistics' | translate }}
    </div>
    <div class="quiz-statistics-table">
      <div class="table">
        <div class="tab-header list">
          <div class="header-item">{{ 'course.table.name' | translate }}</div>
          <div class="header-item right-or-wrong">
            {{ 'question.label.rightWrong' | translate }}
          </div>
          <div class="header-item">
            {{ 'exam.result.result' | translate }}
          </div>
          <div class="header-item">
            {{ 'course.statistics.played' | translate }}
          </div>
        </div>
        <div
          class="list table-border"
          *ngFor="let stat of stats; let i = index"
          (click)="onQuizClick(stat)"
        >
          <div class="cell-item">{{ stat?.quiz.name }}</div>
          <div
            class="cell-item right-or-wrong flex-row flex-justify-content-space-between flex-align-items-center"
          >
            <div class="bar">
              <div
                [style.width]="getCorrectRatio(stat.correct_ratio)"
                class="right-ans"
              ></div>
            </div>
          </div>
          <div class="cell-item">
            {{ getCorrectRatio(stat.correct_ratio) }}
          </div>
          <div class="cell-item">{{ stat?.total_answers }}</div>
        </div>
      </div>
    </div>
  </div>
</div>
