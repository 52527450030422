import { Injectable } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class PaginatorIntlService extends MatPaginatorIntl {
  constructor(private translator: TranslateService) {
    super();
  }
  itemsPerPageLabel = this.translator.instant('common.paginator.itemPerPage');
  nextPageLabel = this.translator.instant('common.paginator.nextPage');
  previousPageLabel = this.translator.instant('common.paginator.previousPage');

  getRangeLabel = (page, pageSize, length) => {
    // const ofText = this.translator.instant('common.paginator.from');
    const ofText = ' / ';
    if (length === 0 || pageSize === 0) {
      return `0 ${ofText} ${length}`;
    }
    length = Math.max(length, 0);
    const startIndex = page * pageSize;
    // If the start index exceeds the list length, do not try and fix the end index to the end.
    const endIndex =
      startIndex < length
        ? Math.min(startIndex + pageSize, length)
        : startIndex + pageSize;
    return `${startIndex + 1} - ${endIndex} ${ofText} ${length}`;
  };
}
