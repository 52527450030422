import { Component, Inject, OnInit, Optional } from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TagModel } from '../../api/models';
import { DialogReturnType } from '../../app.types';
import { AbstractEditComponent } from 'src/app/cms/common/abstract-edit.component';
import { TagsService } from '../../api/services';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'qa-tag-edit',
  templateUrl: './tag-edit.component.html',
  styleUrls: ['./tag-edit.component.scss'],
})
export class TagEditComponent extends AbstractEditComponent implements OnInit {
  loading = true;

  constructor(
    @Optional()
    public dialogRef: MatDialogRef<
      TagEditComponent,
      DialogReturnType<TagModel>
    >,
    @Optional() @Inject(MAT_DIALOG_DATA) public input: TagModel,
    private tags: TagsService,
    private matSnackBar: MatSnackBar,
    private translator: TranslateService
  ) {
    super();
  }

  ngOnInit() {
    this.dialogRef.updateSize('600px');
    const tag: TagModel = this.input;
    this.form = new UntypedFormGroup({
      id: new UntypedFormControl(),
      name: new UntypedFormControl('', Validators.required),
    });
    this.form.reset();
    this.form.patchValue(tag);
    this.loading = false;
  }

  onSubmit(): void {
    this.loading = true;
    const tag: TagModel = this.form.getRawValue();
    this.tags
      .updateUserTags({
        body: tag,
      })
      .subscribe({
        next: () => {
          this.form.markAsUntouched();
          this.form.markAsPristine();
          if (tag.id) {
            this.matSnackBar.open(this.translator.instant('tag.message.edit'));
          }
          this.loading = false;
        },
        error: () => {
          this.loading = false;
          this.matSnackBar.open(
            this.translator.instant('tag.message.editFail')
          );
        },
      });
  }
}
