<ng-container *ngIf="entity; else spinner">
  <h1 mat-dialog-title>
    {{ 'comment.detail.title' | translate }}
  </h1>
  <mat-dialog-content>
    <img
      *ngIf="entity.media"
      src="https://s3.{{ 's3.mediaBucket.region' | env }}.amazonaws.com/{{
        's3.mediaBucket.name' | env
      }}/{{ entity.media.uuid }}.{{ entity.media.file_ext }}"
    />
    <p class="mat-caption">{{ 'Text' | translate }}: {{ entity?.text }}</p>
    <p>{{ 'comment.table.comment' | translate }}: {{ data.comment.comment }}</p>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button
      class="flex-align-right"
      color="primary"
      mat-button
      mat-dialog-close
      type="button"
    >
      Ok
    </button>
  </mat-dialog-actions>
</ng-container>
<ng-template #spinner>
  <div class="spinner-container">
    <mat-spinner [diameter]="32"> </mat-spinner>
  </div>
</ng-template>
