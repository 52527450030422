import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface ShowInfoItem {
  infoData: string;
  tipData?: string;
  closeButton?: string;
}

@Component({
  selector: 'qa-show-info-dialog',
  templateUrl: './show-info-dialog.component.html',
  styleUrls: ['./show-info-dialog.component.scss'],
})
export class ShowInfoDialogComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ShowInfoItem,
    public dialogRef: MatDialogRef<ShowInfoDialogComponent>
  ) {}
}
