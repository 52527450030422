<div class="centered-container">
  <ng-container *ngIf="isBrowserSupport">
    <h1>
      {{ 'printHelp.printDoc' | translate }}
    </h1>
    <p>
      {{ 'printHelp.text' | translate }}
    </p>
    <qa-button
      [isRaisedButton]="true"
      (submitted)="print.emit()"
      label="printHelp.print"
    ></qa-button>
  </ng-container>
  <h3 *ngIf="!isBrowserSupport">
    {{ 'common.courseFlyer' | translate }}
  </h3>
</div>
