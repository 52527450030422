import {
  Component,
  ElementRef,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  FormGroupDirective,
  NgForm,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { Edition } from '../edition-selector/edition-selector.component';
import {
  Observable,
  debounceTime,
  distinctUntilChanged,
  from,
  map,
  of,
  startWith,
  switchMap,
  tap,
} from 'rxjs';
import { ErrorStateMatcher } from '@angular/material/core';
import { TranslateService } from '@ngx-translate/core';
import _ from 'lodash';
import { passwordValidator } from '../auth.types';

interface Organization {
  id: number;
  name: string;
  email?: string;
  address?: string;
  city?: string;
  state?: string;
  country?: string;
  zipcode?: string;
  weblink?: string;
  logo_url?: string;
  color_code?: string;
  phone?: string;
  is_education?: boolean;
  num_users?: number;
  preferred_editions?: Edition[];
}

const checkRepeatPassword: ValidatorFn = (
  group: AbstractControl
): ValidationErrors | null => {
  const pass = group.get('password').value;
  const confirmPass = group.get('repeatPassword').value;
  return pass === confirmPass ? null : { notSamePassword: true };
};

const checkRepeatMail: ValidatorFn = (
  group: AbstractControl
): ValidationErrors | null => {
  const pass = group.get('email').value;
  const confirmPass = group.get('repeatEmail').value;
  return pass === confirmPass ? null : { notSameMail: true };
};

export class CustomErrorStateMatcher implements ErrorStateMatcher {
  constructor(private validationName: string) {}

  isErrorState(
    control: FormControl | null,
    _: FormGroupDirective | NgForm | null
  ): boolean {
    const invalidCtrl = !!(control?.invalid && control?.parent?.dirty);
    const invalidParent = !!(
      control?.parent?.invalid &&
      control?.parent?.dirty &&
      control?.parent?.hasError(this.validationName)
    );

    return invalidCtrl || invalidParent;
  }
}

@Component({
  selector: 'qa-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class RegisterComponent implements OnInit {
  @ViewChild('orgNameInput') orgNameInput: ElementRef<HTMLInputElement>;

  errorMessage: string;

  targetEditionName: string = environment.edition;
  targetEdition: Edition = undefined;

  loadingRegistration = false;
  registered = false;

  NEW_ORGANIZATION: Organization = {
    id: 44696,
    name: 'Lehreinrichtung muss geändert werden',
    is_education: false,
  };

  mailMatcher = new CustomErrorStateMatcher('notSameMail');
  passwordMatcher = new CustomErrorStateMatcher('notSamePassword');

  manualOrganizationInput = false;
  suggestedEditionApplied = false;

  private checkOrganizationPresent: ValidatorFn = (
    group: AbstractControl
  ): ValidationErrors | null => {
    const hasOrganization = group.get('organization').value;
    const orgName = group.get('organizationName')?.value;
    const isValid =
      hasOrganization ||
      (this.manualOrganizationInput && (orgName?.name ?? orgName)?.length);
    return isValid ? null : { mustSelectOrg: true };
  };

  form = new FormGroup(
    {
      firstName: new FormControl<string>('', [Validators.required]),
      lastName: new FormControl<string>('', [Validators.required]),
      organizationName: new FormControl<string>('', [Validators.required]),
      organization: new FormControl<Organization | undefined>(undefined),
      organizationWebsite: new FormControl<string>(''),
      organizationCity: new FormControl<string>(''),
      phone: new FormControl<string>('', [
        Validators.pattern(/(\(?([\d \-\)\–\+\/\(]+)\)?([ .\-–\/]?)([\d]+))/),
      ]),
      email: new FormControl<string>('', [
        Validators.required,
        Validators.email,
      ]),
      repeatEmail: new FormControl<string>('', [
        Validators.required,
        Validators.email,
      ]),
      password: new FormControl<string>('', [
        Validators.required,
        passwordValidator,
      ]),
      repeatPassword: new FormControl<string>('', [Validators.required]),
    },
    {
      validators: [
        checkRepeatPassword,
        checkRepeatMail,
        this.checkOrganizationPresent,
      ],
    }
  );

  filteredOptions: Observable<Organization[]>;
  organizationsLoading = false;

  constructor(private router: Router, private translator: TranslateService) {
    if (sessionStorage.getItem('qa-registered') === 'true') {
      this.registered = true;
    }
  }
  ngOnInit(): void {
    this.filteredOptions = this.form.get('organizationName').valueChanges.pipe(
      distinctUntilChanged(),
      tap(() => (this.organizationsLoading = true)),
      startWith(''),
      debounceTime(1000),
      switchMap((value) => this.queryOrganizations(value)),
      tap(() => (this.organizationsLoading = false))
    );
  }

  getSearchEdition(option: Organization): string {
    return option?.preferred_editions[0].name
      .toLowerCase()
      ?.replace('business', 'QuizAcademy');
  }

  displayFn(org: Organization): string {
    return org && org.name ? org.name : '';
  }

  queryOrganizations(searchTerm: string) {
    if (!searchTerm?.length) return of([]);
    return from(
      fetch(environment.crmUI.url + 'public/organizations/query', {
        method: 'POST',
        body: JSON.stringify({
          pagination: {
            page_size: 10,
          },
          filters: {
            name: searchTerm,
          },
        }),
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': environment.crmUI.key,
        },
      })
    ).pipe(
      switchMap((resp) => resp.json()),
      map((resp) => resp.data),
      map((data) => _.uniqBy(data, (e: Organization) => e.id))
    );
  }

  async register() {
    if (this.loadingRegistration || this.registered) return;
    this.loadingRegistration = true;
    this.errorMessage = undefined;
    this.form.disable();
    let orgName = this.form.get('organizationName').value as any;
    orgName = orgName?.name ?? orgName;
    let organization: Partial<Organization>;
    if (this.form.get('organization').value) {
      organization = {
        id: this.form.get('organization').value.id,
      };
    } else {
      organization = {
        name: orgName,
        weblink: this.form.get('organizationWebsite').value ?? '',
        city: this.form.get('organizationCity').value ?? '',
      };
    }
    let phoneNumber = null;
    if (this.form.get('phone').value?.length) {
      phoneNumber = this.form.get('phone').value.trim();

      if (phoneNumber.startsWith('0')) {
        phoneNumber = phoneNumber.replace(/^0+/, '+49');
      }
    }
    const resp = await fetch(`${environment.crmUI.url}public/register`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': environment.crmUI.key,
      },
      body: JSON.stringify({
        email: this.form.get('email').value.toLowerCase(),
        firstname: this.form.get('firstName').value,
        lastname: this.form.get('lastName').value,
        phone_number: phoneNumber,
        organization,
        initial_password: this.form.get('password').value,
        edition: {
          id: this.targetEdition?.id,
        },
      }),
    }).then((resp) => resp.json());
    if (resp?.error) {
      this.setErrorMessage(resp?.message);
    } else {
      // Navigate to post-register screen
      this.registered = true;
      sessionStorage.setItem('qa-registered', 'true');
    }
    this.loadingRegistration = false;
    this.form.enable();
    if (!this.manualOrganizationInput) {
      this.form.get('organizationName').disable();
    }
  }

  private setErrorMessage(msg?: string) {
    if (msg === 'A user with that email is already registered.') {
      msg = this.translator.instant('register.error.userAlreadyExists');
    }
    if (!msg) {
      msg = this.translator.instant('register.error.generic');
    }
    this.errorMessage = msg;
  }

  toLogin() {
    this.router.navigateByUrl('/login');
  }

  organizationSelected(org: Organization) {
    if (!org.id) {
      this.manualOrganizationInput = true;
      return;
    }
    this.manualOrganizationInput = false;
    this.form.get('organization').setValue(org);
    this.form.get('organizationName').disable();
    if (org.preferred_editions?.length) {
      this.updateTargetEditionName(
        org.preferred_editions[0].name?.toLowerCase()?.replace('business', 'go')
      );
    }
    return undefined;
  }

  private updateTargetEditionName(newName: string) {
    this.targetEditionName = newName;
    this.suggestedEditionApplied = true;
  }

  removeOrganization() {
    this.form.get('organization').setValue(undefined);
    this.form.get('organizationName').setValue('');
    this.form.get('organizationName').enable();
    this.suggestedEditionApplied = false;
    this.manualOrganizationInput = false;
  }

  selectEdition(targetEdition: Edition) {
    this.targetEdition = targetEdition;
    const preferredEditions = this.form.get('organization')?.value;
    let suggestedEdition = '';
    if (preferredEditions && preferredEditions?.preferred_editions?.length) {
      suggestedEdition = preferredEditions?.preferred_editions[0].name
        ?.toLowerCase()
        ?.replace('business', 'go');
    }
    if (suggestedEdition && suggestedEdition == targetEdition.name) {
      this.suggestedEditionApplied = true;
    } else {
      this.suggestedEditionApplied = false;
    }
  }
}
