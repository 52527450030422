<div class="ios-model">
  <div class="close-wrapper" (click)="setSession()">
    <mat-icon class="material-icons">close</mat-icon>
  </div>
  <img src="../../assets/images/Group_2.svg" alt="" class="svg-logo" />
  <h2 mat-dialog-title>{{ 'findContent.ios-model.header' | translate }}</h2>
  <div mat-dialog-content class="contant">
    <p>{{ 'findContent.ios-model.contant1' | translate }}</p>
    <p>
      {{ 'findContent.ios-model.contant2' | translate }}
      <b>
        {{ 'findContent.ios-model.boldContant' | translate }}
      </b>
      {{ 'findContent.ios-model.contant3' | translate }}
    </p>
    <p class="footer-contant">
      {{ 'findContent.ios-model.contant4' | translate }}
    </p>
  </div>
  <div class="buttons">
    <button mat-button class="btn-app" (click)="openLink()">
      <mat-icon class="material-icons">shopping_bag_black</mat-icon> &nbsp;
      {{ 'findContent.ios-model.button-app' | translate }}
    </button>
    <button mat-button class="btn-continue" (click)="setSession()">
      {{ 'findContent.ios-model.button-continue' | translate }}
    </button>
  </div>
</div>
