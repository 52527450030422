import {
  Component,
  Inject,
  ViewEncapsulation,
  ElementRef,
} from '@angular/core';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';

export interface ImportSnackBarData {
  progress: { current: number; total: number };
  message: string;
}

@Component({
  selector: 'qa-import-snack-bar',
  template: `
    <div>
      {{
        (data.message ? data.message : 'common.message.jsonImported')
          | translate
      }}
      {{ data.progress.current / data.progress.total | percent }}
    </div>
    <mat-progress-bar
      [value]="(data.progress.current / data.progress.total) * 100"
      mode="determinate"
    >
    </mat-progress-bar>
  `,
  styles: [
    `
      .qa-import-snack-bar .mat-mdc-progress-bar {
        background: rgba(255, 255, 255, 0.2);
        margin-top: 8px;
      }
      .qa-import-snack-bar .mat-mdc-progress-bar svg {
        display: none;
      }
    `,
  ],
  encapsulation: ViewEncapsulation.None,
})
export class ImportSnackBarComponent {
  constructor(
    @Inject(MAT_SNACK_BAR_DATA) public data: ImportSnackBarData,
    elementRef: ElementRef
  ) {
    elementRef.nativeElement.classList.add('qa-import-snack-bar');
  }
}
