<ng-container *ngIf="showLargeTimer">
  <div class="cms-live-event">
    <div class="remaining-seconds">
      {{ remainingSeconds }}
    </div>
    <svg height="216" width="216">
      <circle
        [attr.r]="100"
        cx="108"
        cy="108"
        fill="transparent"
        stroke-width="16"
        class="default-arc"
      />
      <circle
        [style.strokeDasharray]="circumference"
        [style.strokeDashoffset]="dashoffset"
        [attr.r]="100"
        cx="108"
        cy="108"
        fill="transparent"
        stroke-width="16"
      />
    </svg>
  </div>
</ng-container>

<div class="student" *ngIf="!showLargeTimer && isActive">
  <div class="remaining-seconds">
    {{ remainingSeconds }}
  </div>
  <svg height="40" width="40">
    <circle
      [attr.r]="16"
      cx="20"
      cy="20"
      fill="transparent"
      stroke-width="4"
      class="default-arc"
    />
    <circle
      [style.strokeDasharray]="circumference"
      [style.strokeDashoffset]="dashoffset"
      [attr.r]="16"
      cx="20"
      cy="20"
      fill="transparent"
      stroke-width="4"
    />
  </svg>
</div>
