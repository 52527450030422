import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
  Renderer2,
} from '@angular/core';
import { BehaviorSubject, fromEvent } from 'rxjs';
import { first } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import {
  CancelDialogLabels,
  CancelConfirmationComponent,
} from './dialogs/cancel-confirmation.component';
import {
  HelpVideoDialogComponent,
  HelpVideoDialogData,
} from './help-video-dialog.component';
import * as _sanitizeHTML from 'sanitize-html';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { CSVReader } from './import-helpers/csvReader';
import { XLSXReader } from './import-helpers/xlsxReader';
import { QuizletImporter } from './import-helpers/quizletImporter';
import { KahootImporter } from './import-helpers/kahootImporter';
import {
  FlashColumnNames,
  FlashTopRow,
  QuestionColumnNames,
  QuestionTopRow,
} from 'src/app/utility/app.utils';

const sanitizeHTML = (_sanitizeHTML as any).default || _sanitizeHTML;

export interface UploadAreaParameters {
  csv: string;
  excel: string;
  csvError: boolean;
}

@Component({
  selector: 'qa-csv-import-upload-area',
  templateUrl: './csv-import-upload-area.component.html',
  styleUrls: ['./csv-import-upload-area.component.scss'],
})
export class CsvImportUploadAreaComponent implements OnInit, OnChanges {
  @Input() uploadAreaParameters: UploadAreaParameters;
  @Input() isFlashImport: boolean;
  @Input() cancelUploadLabels: CancelDialogLabels;
  @Output() cancel = new EventEmitter<boolean>();
  @Output() continueUpload = new EventEmitter<any[]>();
  columnNames: string[];
  columnNamesQuestion: string[] = QuestionColumnNames;
  topRowQuestion = QuestionTopRow;
  columnNamesFlash: string[] = FlashColumnNames;
  topRowFlash = FlashTopRow;
  tableData = new BehaviorSubject([]);
  isDragging = false;
  topRow: string;

  constructor(
    private matDialog: MatDialog,
    private renderer: Renderer2,
    private matSnackBar: MatSnackBar,
    private translator: TranslateService
  ) {}

  ngOnInit(): void {
    if (!this.isFlashImport) {
      this.columnNames = this.columnNamesQuestion;
      this.topRow = this.topRowQuestion;
    } else {
      this.columnNames = this.columnNamesFlash;
      this.topRow = this.topRowFlash;
    }
    this.padRows(10);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes.uploadAreaParameters &&
      changes.uploadAreaParameters.currentValue
    ) {
      this.uploadAreaParameters = changes.uploadAreaParameters.currentValue;
      if (this.uploadAreaParameters.csvError) {
        this.tableData.next([]);
        this.padRows(10);
      }
    }
  }

  async onSelectFile(files: File[]) {
    if (files && files.length) {
      const file = files[0];
      let extension = file.name.substr(file.name.lastIndexOf('.') + 1);
      extension = extension.toLowerCase();
      switch (extension) {
        case 'csv':
          this.onSelectCsv(files);
          break;
        case 'xlsx':
          this.onSelectXLSX(files);
          break;
        default:
          return;
      }
      this.isDragging = false;
    }
  }

  async onSelectCsv(files: File[]) {
    const reader = new FileReader();
    const readerResult = fromEvent(reader, 'load').pipe(first());
    reader.readAsText(files[0], 'ISO-8859-4');
    await readerResult.toPromise();
    CSVReader.readCSVFromString(
      reader.result as string,
      this.columnNames,
      this.isFlashImport
    ).subscribe((data) => {
      this.tableData.next(data);
      this.padRows(10);
    });
  }

  async onSelectXLSX(files: File[]) {
    const reader = new FileReader();
    const readerResult = fromEvent(reader, 'load').pipe(first());
    reader.readAsArrayBuffer(files[0]);
    await readerResult.toPromise();
    let data = this.arrayFromHTMLData(XLSXReader.excelToHTML(reader.result));
    data = this.convertKahoot(data);
    this.padRows(data.length);
    this.insertDataFromSource(data);
  }

  cancelConfirm() {
    if (this.isReady()) {
      this.matDialog
        .open(CancelConfirmationComponent, {
          autoFocus: false,
          data: this.cancelUploadLabels,
          panelClass: 'csv-cancel-confirmation-dialog',
        })
        .afterClosed()
        .subscribe((response) => {
          if (response) {
            this.cancel.emit(true);
          }
        });
    } else {
      this.cancel.emit(true);
    }
  }

  next() {
    this.continueUpload.emit(
      this.tableData
        .getValue()
        .map((row) => {
          if (
            Object.keys(row).every((key) => key === 'number' || row[key] === '')
          ) {
            return null;
          }
          return row;
        })
        .filter((row) => row !== null)
    );
  }

  padRows(newSize: number) {
    const tableheight = this.tableData.getValue().length;
    if (newSize <= tableheight) {
      return;
    }
    for (let i = tableheight; i < newSize; i++) {
      if (this.columnNames && this.columnNames.length) {
        const buffer = {};
        for (const cell of this.columnNames) {
          if (cell === 'number') {
            buffer[cell] = i + 1;
          } else {
            buffer[cell] = '';
          }
        }
        this.tableData.next([...this.tableData.getValue(), buffer]);
      }
    }
  }

  openHelp(skipIfAlreadyShown?: boolean) {
    this.matDialog.open<HelpVideoDialogComponent, HelpVideoDialogData>(
      HelpVideoDialogComponent,
      {
        data: {
          skipIfAlreadyShown,
          videoName: 'teaser',
        },
      }
    );
  }

  editCell(event) {
    this.renderer.setAttribute(event.target, 'contenteditable', 'true');
    event.target.focus();
    this.renderer.addClass(event.target, 'cell-edit');
  }

  setTableValue(rownum: number, colname: string, value: any) {
    const curdata = this.tableData.getValue();
    if (
      this.columnNames &&
      this.columnNames.length &&
      this.tableData.getValue().length > rownum
    ) {
      if (this.columnNames.includes(colname)) {
        curdata[rownum][colname] = value;
      }
    }
    this.tableData.next(curdata);
  }

  saveCell(event, rowdata, colname: string) {
    event.preventDefault();
    event.target.blur();
    this.renderer.setAttribute(event.target, 'contenteditable', null);
    this.renderer.removeClass(event.target, 'cell-edit');
    this.setTableValue(rowdata.number - 1, colname, event.target.innerText);
  }

  convertKahoot(csvArr) {
    if (KahootImporter.isKahoot(csvArr)) {
      this.matSnackBar.open(
        this.translator.instant('csvImports.detectedKahoot')
      );
      csvArr = KahootImporter.importKahoot(csvArr);
    }
    return csvArr;
  }

  arrayFromHTMLData(html: string) {
    const data = new DOMParser().parseFromString(html, 'text/html');
    const tabledata = data.querySelector('table');
    if (!tabledata) {
      return null;
    }
    const out = [];
    Array.prototype.forEach.call(tabledata.querySelectorAll('tr'), (row) => {
      const buffer = [];
      Array.prototype.forEach.call(row.children, (cell) => {
        const txt = document.createElement('textarea');
        cell.innerHTML = cell.innerHTML
          .replace(/[\n|\r\n]*/g, '')
          .replace(/\<br[\s]*\/?\>[\s]*/gi, '\n');
        txt.innerHTML = cell.innerHTML;
        buffer.push(txt.value);
      });
      out.push(buffer);
    });
    return out;
  }

  arrayFromPasteData(event) {
    let pastedData: any;
    const containsHTMLTable =
      event.clipboardData.getData('text/html') !== null &&
      event.clipboardData.getData('text/html') !== '' &&
      event.clipboardData.getData('text/html').includes('<table');
    if (containsHTMLTable) {
      pastedData = event.clipboardData.getData('text/html');
      pastedData = pastedData.replace(
        new RegExp('</thead></table>', 'g'),
        '</thead>'
      );
      pastedData = pastedData.replace(new RegExp('<table><tr>', 'g'), '<tr>');
      pastedData = pastedData.replace(
        new RegExp('</tr></table>', 'g'),
        '</tr>'
      );
      if (!pastedData.includes('</tbody></table>')) {
        pastedData = pastedData.replace(
          new RegExp('</tbody>', 'g'),
          '</tbody></table>'
        );
      }
      pastedData = this.arrayFromHTMLData(pastedData);
    } else {
      pastedData = event.clipboardData.getData('text/plain');
      pastedData = pastedData.replace(/\r\n/g, '\n');
      if (QuizletImporter.isQuizletData(pastedData)) {
        this.matSnackBar.open(
          this.translator.instant('csvImports.detectedQuizlet')
        );
        pastedData = QuizletImporter.importQuizlet(
          event.clipboardData.getData('text/plain')
        );
      } else {
        pastedData = pastedData.replace(/\"(.*)\"/gm, (_full, group1) => {
          return `${group1.replace(/\n/g, '<br/>')}`;
        });
        pastedData = pastedData.split('\n');
        pastedData = pastedData.map((row) => {
          return row.split('\t');
        });
      }
    }
    pastedData = this.convertKahoot(pastedData);
    this.padRows(pastedData?.length ?? 0);
    return pastedData;
  }

  onPaste(event, row, column) {
    event.preventDefault();
    event.target.blur();
    const pastedData = this.arrayFromPasteData(event);
    this.insertDataFromSource(pastedData, row, column);
    event.target.click();
  }

  insertDataFromSource(source, row = 1, column = 1) {
    source = source
      ? source.filter((row) => !row.every((cell) => cell === ''))
      : [];
    const currentcell = { c: column, r: row };
    for (const row of source) {
      if (
        row.slice(0, !this.isFlashImport ? 17 : 5).join(';') ===
        this.topRow
          .split(';')
          .slice(0, !this.isFlashImport ? 17 : 5)
          .join(';')
      ) {
        continue;
      }
      currentcell.c = column;
      for (const cell of row) {
        if (
          row.length === (!this.isFlashImport ? 18 : 6) &&
          currentcell.c === (!this.isFlashImport ? 18 : 6)
        ) {
          currentcell.c = !this.isFlashImport ? 22 : 10;
        }
        this.setTableValue(
          currentcell.r - 1,
          this.columnNames[currentcell.c],
          sanitizeHTML(cell, {
            allowedTags: ['br'],
            allowedAttributes: {
              '*': [],
            },
          })
        );
        currentcell.c += 1;
      }
      currentcell.r += 1;
    }
  }

  enterDrag() {
    this.isDragging = true;
  }

  leaveDrag() {
    this.isDragging = false;
  }

  onKeyDown(event, colnum, rownum) {
    if (event.keyCode === 9 && colnum < this.columnNames.length - 1) {
      event.preventDefault();
      document.getElementById(`csv-table-cell-${rownum}-${colnum + 1}`).click();
    }
  }

  isReady() {
    const data = this.tableData?.getValue();
    if (!data || data.length <= 0) {
      return false;
    }
    return data.some((row) =>
      Object.keys(row).some((colname) => {
        if (colname === 'number') {
          return false;
        }
        return row[colname] !== '';
      })
    );
  }
}
