export function formatUserFinishTime(time: number, longFormat?: boolean) {
  const h = Math.floor(time / (60 * 60));
  const m = Math.floor(time / 60) % 60;
  const s = time % 60;
  return `${h > 0 || longFormat ? h + ':' : ''}${('00' + m).slice(-2)}:${(
    '00' + s
  ).slice(-2)}`;
}

export function sortBy(
  array: any[],
  sortby = 'bestof',
  sortDir?: string
): any[] {
  return array.sort((a, b) => {
    switch (sortby) {
      case 'alphabetical':
        if (sortDir && sortDir === 'DESC') {
          return b.username.localeCompare(a.username);
        } else {
          return a.username.localeCompare(b.username);
        }
      case 'status':
        if (sortDir && sortDir === 'DESC') {
          return b.turned_in - a.turned_in;
        } else {
          return a.turned_in - b.turned_in;
        }
      case 'times':
        if (sortDir && sortDir === 'DESC') {
          return Date.parse(b.turned_in_time) - Date.parse(a.turned_in_time);
        } else {
          return Date.parse(a.turned_in_time) - Date.parse(b.turned_in_time);
        }
      case 'examFinishTime':
        if (sortDir && sortDir === 'DESC') {
          return b.exam_finish_time - a.exam_finish_time;
        } else {
          return a.exam_finish_time - b.exam_finish_time;
        }
      case 'totalResult':
        const as = (a.score || 0) + (a.free_text_score || 0);
        const bs = (b.score || 0) + (b.free_text_score || 0);
        if (sortDir && sortDir === 'DESC') {
          return bs - as;
        } else {
          return as - bs;
        }
      case 'freeText':
        if (sortDir && sortDir === 'DESC') {
          return (b.free_text_score || 0) - (a.free_text_score || 0);
        } else {
          return (a.free_text_score || 0) - (b.free_text_score || 0);
        }
      default:
        if (a.score === b.score) {
          if (sortDir && sortDir === 'DESC') {
            return b.exam_finish_time - a.exam_finish_time;
          } else {
            return a.exam_finish_time - b.exam_finish_time;
          }
        }
        if (sortDir && sortDir === 'DESC') {
          return (b.score || 0) - (a.score || 0);
        } else {
          return (a.score || 0) - (b.score || 0);
        }
    }
  });
}

export function sortUsersWithFreetextScore(array: any[]): any[] {
  return array.sort((a, b) => {
    const scoreA = (a.score || 0) + (a.free_text_score || 0);
    const scoreB = (b.score || 0) + (b.free_text_score || 0);
    if (scoreA === scoreB) {
      return a.exam_finish_time - b.exam_finish_time;
    }
    return (scoreB || 0) - (scoreA || 0);
  });
}

export function sortQuestionBy(
  array: any[],
  sortby = 'alphabetical',
  sortDir?: string
): any[] {
  return array.sort((a, b) => {
    switch (sortby) {
      case 'alphabetical':
        if (sortDir && sortDir === 'DESC') {
          return b.text.localeCompare(a.text);
        } else {
          return a.text.localeCompare(b.text);
        }
      case 'rightWrong':
      case 'result':
        if (sortDir && sortDir === 'DESC') {
          return (b.num_correct_answers || 0) - (a.num_correct_answers || 0);
        } else {
          return (a.num_correct_answers || 0) - (b.num_correct_answers || 0);
        }
      default:
        if (sortDir && sortDir === 'DESC') {
          return b.text.localeCompare(a.text);
        } else {
          return a.text.localeCompare(b.text);
        }
    }
  });
}

export function sortResultsBy(
  array: any[],
  sortby = 'alphabetical',
  sortDir?: string
): any[] {
  return array.sort((a, b) => {
    switch (sortby) {
      case 'alphabetical':
        if (sortDir && sortDir === 'DESC') {
          return b.question.text.localeCompare(a.question.text);
        } else {
          return a.question.text.localeCompare(b.question.text);
        }
      case 'status':
      case 'score':
        if (sortDir && sortDir === 'DESC') {
          return (b.result?.score || 0) - (a.result?.score || 0);
        } else {
          return (a.result?.score || 0) - (b.result?.score || 0);
        }
      default:
        if (sortDir && sortDir === 'DESC') {
          return b.question.text.localeCompare(a.question.text);
        } else {
          return a.question.text.localeCompare(b.question.text);
        }
    }
  });
}
