import {
  Component,
  Input,
  OnChanges,
  SimpleChanges,
  ViewEncapsulation,
  ElementRef,
  ViewChild,
} from '@angular/core';
import { SafeHtml } from '@angular/platform-browser';
import { MarkdownKatexService } from './markdown-katex.service';

@Component({
  selector: 'qa-markdown-katex',
  templateUrl: './markdown-katex.component.html',
  styleUrls: ['./markdown-katex.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class MarkdownKatexComponent implements OnChanges {
  @Input() data: string;
  @ViewChild('htmlContainer') htmlContainer: ElementRef;
  html: SafeHtml;
  showError = false;

  constructor(
    private markdownKatex: MarkdownKatexService,
    elementRef: ElementRef<HTMLElement>
  ) {
    elementRef.nativeElement.classList.add('qa-markdown-katex');
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.data && changes.data.currentValue) {
      const content = changes.data.currentValue;
      if (typeof content === 'string') {
        this.html = this.markdownKatex.compile(changes.data.currentValue);
        window.setTimeout(() => {
          this.showError =
            this.htmlContainer?.nativeElement?.innerText?.length === 0 &&
            this.data?.trim().length > 0;
        }, 0);
      }
    }
  }
}
