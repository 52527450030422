<div class="ai-import-questions">
  <qa-content-spinner
    [active]="loading"
    [loadingText]="'AIQuestionImports.loadingText' | translate"
  >
    <div class="header">
      <h1 class="dialog-title">
        {{ 'AIQuestionImports.mainHeader' | translate }}
      </h1>
      <div class="close-icon" mat-dialog-close><mat-icon>close</mat-icon></div>
    </div>
    <div *ngIf="showInfo && !showGeneratedQuestion">
      <ng-content *ngTemplateOutlet="actions"></ng-content>
    </div>
    <div *ngIf="!showInfo && showGeneratedQuestion">
      <ng-content *ngTemplateOutlet="showQuestion"></ng-content>
    </div>
  </qa-content-spinner>

  <ng-template #actions>
    <div class="generate-question-info">
      {{
        'AIQuestionImports.questionImportDetails.generateQuestoinDescriptionOne'
          | translate
      }}
    </div>
    <div class="generate-question-info">
      {{
        'AIQuestionImports.questionImportDetails.importQuestiondesc' | translate
      }}
    </div>
    <div class="dialog-info-conatiner">
      <div class="dialog-info-content" *ngIf="course">
        <div class="title-text">{{ 'course.form.category' | translate }}:</div>
        <div class="info">
          {{ course?.category?.name ? course?.category?.name : '' }}
        </div>
      </div>
      <div class="dialog-info-content" *ngIf="course">
        <div class="title-text">
          {{ 'AIQuestionImports.courseTitle' | translate }}:
        </div>
        <div class="info">
          {{ course?.name ? course?.name : '' }}
        </div>
      </div>
      <div class="dialog-info-content" *ngIf="data.type === 'quiz'">
        <div class="title-text">
          {{ 'AIQuestionImports.quizTitle' | translate }}:
        </div>
        <div class="info">
          {{ quiz?.name ? quiz?.name : '' }}
        </div>
      </div>
      <div class="dialog-info-content" *ngIf="data.type === 'event-question'">
        <div class="title-text">
          {{ 'AIQuestionImports.eventTitle' | translate }}:
        </div>
        <div class="info">
          {{ liveEventInfo?.name ? liveEventInfo?.name : '' }}
        </div>
      </div>
      <div class="dialog-info-content" *ngIf="data.type === 'exam-question'">
        <div class="title-text">
          {{ 'AIQuestionImports.examTitle' | translate }}:
        </div>
        <div class="info">
          {{ examInfo?.name ? examInfo?.name : '' }}
        </div>
      </div>
    </div>
    <div class="generate-question-info">
      {{
        'AIQuestionImports.questionImportDetails.generateQuestoinDescriptionTwo'
          | translate
      }}
    </div>
    <div class="keyword">
      <qa-tag-input
        [control]="keywords"
        label="{{ 'AIQuestionImports.keywords' | translate }}"
        [limitCharacters]="true"
      >
      </qa-tag-input>
    </div>
    <div class="generate-btn generate-question-btn">
      <qa-button
        label="{{ 'AIQuestionImports.cancel' | translate }}"
        mat-dialog-close
      ></qa-button>
      <qa-button
        [isRaisedButton]="true"
        label="{{ 'AIQuestionImports.generate' | translate }}"
        (submitted)="generateAIQuestions()"
      ></qa-button>
    </div>
  </ng-template>
  <ng-template #showQuestion>
    <div class="generated-question-container">
      <div class="question-header">
        {{ 'AIQuestionImports.proposal' | translate }}:
      </div>
      <div class="generated-question-contents">
        <div class="generated-question">
          {{ generatedQuestionResult[0]?.question }}
        </div>
        <div class="generated-answer">
          <ol>
            <li
              *ngFor="
                let ans of generatedQuestionResult[0]?.answers;
                let i = index
              "
            >
              <div class="item">
                <div>
                  {{ ans?.text }}
                  <span class="correct" *ngIf="ans?.correct">
                    <mat-icon>check</mat-icon>
                  </span>
                </div>
              </div>
            </li>
          </ol>
        </div>

        <div class="generate-question-info keyword">
          {{
            'AIQuestionImports.questionImportDetails.changeQuestionDetails'
              | translate
          }}
        </div>
        <div class="button-container import-question-section">
          <div class="cancel">
            <qa-button
              label="{{ 'AIQuestionImports.cancel' | translate }}"
              mat-dialog-close
            ></qa-button>
          </div>
          <div class="ai-question-action-btns">
            <div class="generate-questions">
              <qa-button
                [isIconButton]="true"
                icon="refresh"
                (submitted)="generateAIQuestionAgain()"
              ></qa-button>
            </div>
            <div class="">
              <qa-button
                [isRaisedButton]="true"
                label="{{ 'AIQuestionImports.retry' | translate }}"
                (submitted)="retry()"
              ></qa-button>
            </div>
            <qa-button
              [isRaisedButton]="true"
              [matMenuTriggerFor]="addMenu"
              label="{{ 'AIQuestionImports.importQuestion' | translate }}"
              #addButton
            ></qa-button>
            <mat-menu
              class="material-menu"
              [overlapTrigger]="true"
              #addMenu="matMenu"
            >
              <button
                mat-menu-item
                type="button"
                (click)="saveQuestionAndNext()"
              >
                <mat-icon>add</mat-icon>
                {{ 'AIQuestionImports.saveAndNext' | translate }}
              </button>
              <button
                [disabled]="creatingQuestion"
                (click)="importAIQuestions()"
                mat-menu-item
                type="button"
              >
                <mat-icon>check</mat-icon>
                {{ 'AIQuestionImports.save' | translate }}
              </button>
            </mat-menu>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
</div>
