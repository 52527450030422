import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthService } from './auth.service';
import { LoginComponent } from './login.component';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ReactiveFormsModule } from '@angular/forms';
import { NewPasswordDialogComponent } from './new-password-dialog.component';
import { ResetPasswordDialogComponent } from './reset-password-dialog.component';
import { CanActivateLoggedIn } from './can-activate-logged-in';
import { CanActivateHasGroup } from './can-activate-has-group';
import { CanActivateCms } from './can-activate-cms';
import { RouterModule } from '@angular/router';
import { EmailDialogComponent } from './email-dialog.component';
import { TranslateModule } from '@ngx-translate/core';
import { SelectEditionDialogComponent } from './select-edition-dialog.component';
import { SharedModule } from '../shared/shared.module';
import { EditionSelectorComponent } from './edition-selector/edition-selector.component';
import { RegisterComponent } from './register/register.component';
import { AuthFlowWrapperComponent } from './auth-flow-wrapper/auth-flow-wrapper.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@NgModule({
  declarations: [
    EmailDialogComponent,
    LoginComponent,
    NewPasswordDialogComponent,
    ResetPasswordDialogComponent,
    SelectEditionDialogComponent,
    EditionSelectorComponent,
    AuthFlowWrapperComponent,
    RegisterComponent,
  ],
  providers: [
    AuthService,
    CanActivateHasGroup,
    CanActivateCms,
    CanActivateLoggedIn,
  ],
  imports: [
    CommonModule,
    SharedModule,
    MatButtonModule,
    MatDialogModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatTooltipModule,
    ReactiveFormsModule,
    RouterModule,
    TranslateModule,
    MatAutocompleteModule,
    MatProgressSpinnerModule,
  ],
  exports: [LoginComponent],
})
export class AuthModule {}
