import { Injectable } from '@angular/core';
import { UserConfigService } from '../../user-config.service';

@Injectable({
  providedIn: 'root',
})
export class UserJourneyService {
  private storageID = 'qa.user-journey.flags';

  private flags = [];
  private initialized = false;

  constructor(private userConfig: UserConfigService) {}

  async initFlags() {
    try {
      this.flags = (await this.userConfig.get(this.storageID)) ?? [];
      this.initialized = true;
    } catch {}
  }

  async set(name: string) {
    if (!this.initialized) {
      await this.initFlags();
    }
    this.flags = [...new Set([...this.flags, name])];
    this.userConfig.set(this.storageID, this.flags);
  }

  unset(name: string) {
    const indx = this.flags?.indexOf(name) ?? -1;
    if (indx !== -1) {
      this.flags.splice(indx, 1);
    }
    this.userConfig.set(this.storageID, this.flags);
  }

  isSet(value: string) {
    return this.flags.includes(value);
  }
}
