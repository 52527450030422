import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UtilityService {
  nonAcceptedCards = new BehaviorSubject<number>(null);
  nonAcceptedCards$ = this.nonAcceptedCards.asObservable();

  openComments = new BehaviorSubject<number>(null);
  openComments$ = this.openComments.asObservable();

  constructor() {}

  setNonAcceptedCards(count: number) {
    this.nonAcceptedCards.next(count);
  }

  setOpenComments(count: number) {
    this.openComments.next(count);
  }

  formatMathTTS(text: string, locale: string): string {
    text = text.replace(/\\/gm, '');
    let replacedata = {};
    if (locale === 'de') {
      replacedata = {
        'left(': 'Klammer auf.',
        'right)': 'Klammer zu.',
        equ: 'Genau dann wenn',
        imp: 'Impliziert',
        mapsto: 'bildet ab auf',
        subset: 'Teilmenge von',
        psubset: 'Echte Teilmenge von',
        in: 'in',
        notin: 'nicht in',
        intsec: 'geschnitten mit',
        union: 'vereinigt mit',
        cap: 'geschnitten mit',
        cup: 'vereinigt mith',
        setdiff: 'minus',
        diff: 'delta',
        samplespace: 'Omega',
        defd: 'ist definiert als',
        equiv: 'entspricht',
        cong: 'ist kongruent zu',
        logand: 'und',
        logor: 'oder',
        lognot: 'nicht',
        perp: 'undefiniert',
        bottom: 'undefiniert',
        '^2': 'quadrat',
        '^': 'hoch',
        forall: 'Für alle',
        exists: 'Es existiert ein',
      };
    } else {
      replacedata = {
        'left(': 'Open bracket.',
        'right)': 'Closed bracket.',
        equ: 'equals',
        imp: 'implies',
        mapsto: 'maps to',
        psubset: 'real subset',
        in: 'is in',
        notin: 'is not in',
        intsec: 'intersected with',
        union: 'union with',
        cap: 'intersected with',
        cup: 'union with',
        setdiff: 'minus',
        diff: 'delta',
        samplespace: 'Omega',
        defd: 'defined as',
        equiv: 'is equal to',
        cong: 'is congruent to',
        logand: 'and',
        logor: 'or',
        lognot: 'not',
        perp: 'undefined',
        bottom: 'undefined',
        '^2': 'squared',
        '^3': 'cubed',
        '^': 'to the power of',
      };
    }
    Object.keys(replacedata).forEach(
      (str) => (text = text.split(str).join(` ${replacedata[str]}`))
    );
    if (locale === 'de') {
      text = text.replace(
        /frac\{(.*)\}\{(.*)\}/gm,
        (_match, group1, group2) => {
          return `${group1} ${group2}tel`;
        }
      );
    } else {
      text = text.replace(
        /frac\{(.*)\}\{(.*)\}/gm,
        (_match, group1, group2) => {
          return `${group1} divided by ${group2}`;
        }
      );
    }
    return text;
  }

  formatTTSString(text: string, locale: string): string {
    return text
      .replace(/\$(.*)\$/gm, (_match, group) =>
        this.formatMathTTS(group, locale)
      )
      .replace(/^\*(.*)$/gm, (_match, group) => {
        return group.replace('*', '');
      })
      .replace(/^[\s]*#(.*)$/gm, (_match, group) => group)
      .replace(/\*\*(.*)\*\*/gm, (_match, group) => group)
      .replace(/\_(.*)\_/gm, (_match, group) => group)
      .replace(/^[\s]*\>(.*)$/gm, (_match, group) => group);
  }

  toHHMMSS(secs) {
    const sec_num = parseInt(secs, 10);
    const hours = Math.floor(sec_num / 3600);
    const minutes = Math.floor(sec_num / 60) % 60;
    const seconds = sec_num % 60;

    return [hours, minutes, seconds]
      .map((v) => (v < 10 ? '0' + v : v))
      .filter((v, i) => v !== '00' || i > 0)
      .join(':');
  }
}
