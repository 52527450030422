import { Component, Inject, OnInit } from '@angular/core';
import { SafeHtml } from '@angular/platform-browser';
import { asShortLink, editionPrefix } from 'src/app/app.component';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import {
  copyImageToClipboard,
  copyTextToClipboard,
} from 'src/app/utility/app.utils';
export interface QrData {
  qr: SafeHtml;
  pin: string;
  qrDataUrl: string;
  invitationLink?: {
    click: () => void;
  };
}

@Component({
  selector: 'qr-model',
  templateUrl: './qr-model.component.html',
  styleUrls: ['./qr-model.component.scss'],
})
export class QrModalComponent implements OnInit {
  qrData: QrData;
  shortLinkPrefix = asShortLink('', false);
  editionPrefix = editionPrefix();

  constructor(
    private translator: TranslateService,
    private matSnackBar: MatSnackBar,
    private dialogRef: MatDialogRef<QrModalComponent>,
    @Inject(MAT_DIALOG_DATA) private data: QrData
  ) {
    this.dialogRef.addPanelClass('qr-overlay-component-panel');
  }

  ngOnInit(): void {
    this.qrData = this.data;
  }

  close() {
    this.dialogRef.close();
  }

  copyWeblink() {
    try {
      copyTextToClipboard(asShortLink(this.qrData.pin));
      this.matSnackBar.open(
        this.translator.instant('exam.message.copiedToClipboard')
      );
    } catch (error) {
      this.matSnackBar.open(
        this.translator.instant('exam.message.notCopiedToClipboard')
      );
    }
  }
  async copyQR() {
    try {
      await copyImageToClipboard(this.qrData['qrDataUrl']);
      this.matSnackBar.open(
        this.translator.instant('course.message.copiedToClipboard')
      );
    } catch (error) {
      this.matSnackBar.open(
        this.translator.instant('course.message.notCopiedToClipboard')
      );
    }
  }
  onInvitationMailUrlClick() {
    if (this.qrData.invitationLink) {
      this.qrData.invitationLink.click();
    }
  }
}
