import { Component, ElementRef, Input, NgZone, ViewChild } from '@angular/core';
import { last, tap, filter } from 'rxjs/operators';
import { FormField } from './form-field';
import { UploadProgress } from 'src/app/app.types';
import { DialogService } from '../dialog.service';
import { MediaService } from '../media.service';
import { Media } from 'src/app/api/models';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';
import {
  InfoDialogComponent,
  InfoDialogComponentData,
} from '../dialogs/info-dialog.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'qa-media-input',
  templateUrl: './media-input.component.html',
  styleUrls: ['./media-input.component.scss'],
})
export class FormSelectMediaFileComponent extends FormField {
  @ViewChild('fileInput')
  fileInput: ElementRef;

  @Input() beforeUploadCallback: (img: File) => Promise<File>;

  maxW = 1024;
  maxH = 768;

  imageUploadProgress: UploadProgress;
  processingImage = false;

  get imageUrl(): string {
    return this.control?.value?.uuid
      ? this.control.value.uuid + '.' + this.control.value.file_ext
      : null;
  }

  constructor(
    private media: MediaService,
    private matDialog: MatDialog,
    private dialog: DialogService,
    private translator: TranslateService,
    private ngZone: NgZone
  ) {
    super();
  }

  limitImageDimensions(img): Promise<Blob> {
    return new Promise((resolve) => {
      const aspect = Math.min(this.maxW / img.width, this.maxH / img.height);
      const c = document.createElement('canvas');
      const ctx = c.getContext('2d');
      c.width = aspect * img.width;
      c.height = aspect * img.height;
      ctx.drawImage(img, 0, 0, img.width, img.height, 0, 0, c.width, c.height);
      c.toBlob(resolve);
    });
  }

  imageToFile(blob: any, fileName: string): File {
    if (blob) {
      blob.lastModifiedDate = new Date();
      blob.name = fileName;
    }
    return blob as File;
  }

  async uploadImage() {
    const input: HTMLInputElement = this.fileInput.nativeElement;
    let file = input.files[0];
    let fileType;
    if (file) {
      fileType = file.type;
      if (
        ['image/png', 'image/jpeg'].includes(fileType) &&
        this.beforeUploadCallback
      ) {
        file = await this.beforeUploadCallback(file);
        if (!file) {
          this.processingImage = false;
          return;
        }
      }
      this.processingImage = true;
      const img = new Image();
      const _URL = window.URL || window.webkitURL;
      img.src = _URL.createObjectURL(file);
      img.onload = async (e: any) => {
        if (e.target) {
          if (fileType !== 'image/gif') {
            const imageBlob = await this.limitImageDimensions(e.target);
            file = this.imageToFile(imageBlob, file.name);
          }
          const messages = [];
          if (file.size > 2000000) {
            messages.push(
              this.translator.instant('question.message.imageRes_m2')
            );
            if (fileType === 'image/gif') {
              messages.push(
                this.translator.instant('question.message.imageRes_m5')
              );
            }
          }
          if (messages.length) {
            const data: InfoDialogComponentData = {
              title: this.translator.instant('question.message.imageRes_m1'),
              text: messages,
              buttons: [
                {
                  text: this.translator.instant('common.button.ok'),
                },
              ],
            };
            this.matDialog.open(InfoDialogComponent, { data, width: '30vw' });
            this.processingImage = false;
          } else {
            this.continueUpload(file);
          }
        } else {
          this.processingImage = false;
        }
      };
    }
  }

  continueUpload(file) {
    this.media
      .create(file)
      .pipe(
        tap((progress) => {
          this.ngZone.run(() => {
            this.imageUploadProgress = progress as UploadProgress;
            this.processingImage = false;
          });
        }),
        last()
      )
      .subscribe({
        next: (savedMedia: Media) => {
          this.control.reset();
          this.control.patchValue(savedMedia);
          this.control.markAsDirty();
          this.imageUploadProgress = null;
        },
        error: (error) => {
          this.imageUploadProgress = null;
          throw error;
        },
      });
  }

  removeImage() {
    this.dialog
      .confirmDelete()
      .pipe(filter((isConfirmed) => isConfirmed))
      .subscribe(() => {
        this.control.reset();
        this.control.markAsDirty();
      });
  }

  showImage(): void {
    this.dialog.showImage(
      `https://s3.${environment.s3.mediaBucket.region}.amazonaws.com/${environment.s3.mediaBucket.name}/${this.imageUrl}`
    );
  }

  isSafari() {
    return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  }

  showCopyrightHint() {
    return new Promise((resolve) => {
      this.dialog
        .confirm({
          title: this.translator.instant('common.message.copyrightHintTitle'),
          text: this.translator.instant('common.message.copyrightHintText'),
          buttons: [
            {
              color: 'primary',
              value: 'uploadFile',
              text: this.translator.instant('common.button.ok'),
            },
            {
              color: 'gray',
              value: 'pixabay',
              text: this.translator.instant('common.button.toPixabay'),
            },
          ],
        })
        .subscribe((e) => {
          if ((e as unknown) === 'pixabay') {
            window.open('https://pixabay.com/de/', '_blank');
            resolve(false);
          }
          resolve(true);
        });
    });
  }

  handleFileUploadAttempt() {
    if (this.isSafari()) {
      if (this.fileInput) {
        this.fileInput.nativeElement.click();
      }
      this.showCopyrightHint().then(() => {});
      return;
    }
    this.showCopyrightHint().then(() => {
      if (this.fileInput) {
        this.fileInput.nativeElement.click();
      }
    });
  }
}
