import {
  Component,
  ElementRef,
  HostBinding,
  OnDestroy,
  ViewEncapsulation,
} from '@angular/core';
import { Store } from '@ngrx/store';
import * as dayjs from 'dayjs';
import { filter } from 'rxjs/operators';
import { AppState } from '../app.types';

interface Time {
  hours: number;
  minutes: number;
  seconds: number;
}

@Component({
  selector: 'qa-play-time-measurement',
  templateUrl: './play-time-measurement.component.html',
  styleUrls: ['./play-time-measurement.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class PlayTimeMeasurementComponent implements OnDestroy {
  @HostBinding('class.is-active') isActive = false;
  fromMoment: dayjs.Dayjs;
  time: Time;
  tickIntervalId: number;

  constructor(elementRef: ElementRef<HTMLElement>, store: Store<AppState>) {
    elementRef.nativeElement.classList.add('qa-play-time-measurement');
    let lastFrom: string;
    let lastTo: string;
    store
      .pipe(
        filter(({ play }) => {
          return (
            play.measureTimeFrom !== lastFrom || play.measureTimeTo !== lastTo
          );
        })
      )
      .subscribe((state) => {
        lastFrom = state.play.measureTimeFrom;
        lastTo = state.play.measureTimeTo;
        this.handleStateChange(state);
      });
  }

  ngOnDestroy() {
    this.reset();
  }

  getTimePassed(sinceMoment?: any): Time {
    if (!this.fromMoment) return null;
    sinceMoment = sinceMoment || dayjs();
    const totalSeconds = sinceMoment.diff(this.fromMoment, 's');
    return {
      hours: Math.floor(totalSeconds / 3600),
      minutes: Math.floor((totalSeconds % 3600) / 60),
      seconds: totalSeconds % 60,
    };
  }

  handleStateChange({ play }: AppState) {
    this.reset();
    if (!play.measureTimeFrom) {
      return;
    }
    this.isActive = true;
    this.fromMoment = dayjs(play.measureTimeFrom);
    if (play.measureTimeTo) {
      this.time = this.getTimePassed(dayjs(play.measureTimeTo));
      return;
    }
    this.time = this.getTimePassed();
    this.tickIntervalId = window.setInterval(() => {
      this.time = this.getTimePassed();
    }, 1000);
  }

  reset() {
    this.isActive = false;
    this.fromMoment = null;
    this.time = null;
    window.clearInterval(this.tickIntervalId);
  }
}
