import { Component, Input } from '@angular/core';

export enum ChipType {
  DEFAULT,
  PRIMARY_LIGHT,
  WARN,
  LIGHT,
}

@Component({
  selector: 'qa-custom-chip',
  templateUrl: './custom-chip.component.html',
  styleUrls: ['./custom-chip.component.scss'],
})
export class CustomChipComponent {
  @Input() label: string;
  @Input() icon: string;
  @Input() chipType: ChipType;
  ChipType = ChipType;
}
