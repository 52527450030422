/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { AiAnswerProposal } from '../models/ai-answer-proposal';
import { AiAnswerQuery } from '../models/ai-answer-query';
import { AiFlashCardProposal } from '../models/ai-flash-card-proposal';
import { AiFlashCardQuery } from '../models/ai-flash-card-query';
import { AiQuestionEvaluation } from '../models/ai-question-evaluation';
import { AiQuestionEvaluationResult } from '../models/ai-question-evaluation-result';
import { AiQuestionProposal } from '../models/ai-question-proposal';
import { AiQuestionQuery } from '../models/ai-question-query';
import { CompetencyCatalog } from '../models/competency-catalog';
import { CompetencyCatalogQuery } from '../models/competency-catalog-query';
import { StudentReport } from '../models/student-report';
import { StudentReportRequest } from '../models/student-report-request';

@Injectable({
  providedIn: 'root',
})
export class AiService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getStandardsCatalogue
   */
  static readonly GetStandardsCataloguePath = '/ai/documents/student_report/subjects_standards';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getStandardsCatalogue()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getStandardsCatalogue$Response(params: {
    cognitousergroup?: string;
    cognitouserid?: string;
    context?: HttpContext
    body: CompetencyCatalogQuery
  }
): Observable<StrictHttpResponse<CompetencyCatalog>> {

    const rb = new RequestBuilder(this.rootUrl, AiService.GetStandardsCataloguePath, 'post');
    if (params) {
      rb.header('cognitousergroup', params.cognitousergroup, {});
      rb.header('cognitouserid', params.cognitouserid, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CompetencyCatalog>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getStandardsCatalogue$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getStandardsCatalogue(params: {
    cognitousergroup?: string;
    cognitouserid?: string;
    context?: HttpContext
    body: CompetencyCatalogQuery
  }
): Observable<CompetencyCatalog> {

    return this.getStandardsCatalogue$Response(params).pipe(
      map((r: StrictHttpResponse<CompetencyCatalog>) => r.body as CompetencyCatalog)
    );
  }

  /**
   * Path part for operation generateStudentReport
   */
  static readonly GenerateStudentReportPath = '/ai/documents/student_report/generate';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `generateStudentReport()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  generateStudentReport$Response(params: {
    cognitousergroup?: string;
    cognitouserid?: string;
    context?: HttpContext
    body: StudentReportRequest
  }
): Observable<StrictHttpResponse<StudentReport>> {

    const rb = new RequestBuilder(this.rootUrl, AiService.GenerateStudentReportPath, 'post');
    if (params) {
      rb.header('cognitousergroup', params.cognitousergroup, {});
      rb.header('cognitouserid', params.cognitouserid, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StudentReport>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `generateStudentReport$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  generateStudentReport(params: {
    cognitousergroup?: string;
    cognitouserid?: string;
    context?: HttpContext
    body: StudentReportRequest
  }
): Observable<StudentReport> {

    return this.generateStudentReport$Response(params).pipe(
      map((r: StrictHttpResponse<StudentReport>) => r.body as StudentReport)
    );
  }

  /**
   * Path part for operation generateAnswers
   */
  static readonly GenerateAnswersPath = '/ai/answers/generate';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `generateAnswers()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  generateAnswers$Response(params: {
    cognitousergroup?: string;
    cognitouserid?: string;
    context?: HttpContext
    body: AiAnswerQuery
  }
): Observable<StrictHttpResponse<Array<AiAnswerProposal>>> {

    const rb = new RequestBuilder(this.rootUrl, AiService.GenerateAnswersPath, 'post');
    if (params) {
      rb.header('cognitousergroup', params.cognitousergroup, {});
      rb.header('cognitouserid', params.cognitouserid, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<AiAnswerProposal>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `generateAnswers$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  generateAnswers(params: {
    cognitousergroup?: string;
    cognitouserid?: string;
    context?: HttpContext
    body: AiAnswerQuery
  }
): Observable<Array<AiAnswerProposal>> {

    return this.generateAnswers$Response(params).pipe(
      map((r: StrictHttpResponse<Array<AiAnswerProposal>>) => r.body as Array<AiAnswerProposal>)
    );
  }

  /**
   * Path part for operation generateQuestion
   */
  static readonly GenerateQuestionPath = '/ai/questions/generate';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `generateQuestion()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  generateQuestion$Response(params: {
    cognitousergroup?: string;
    cognitouserid?: string;
    context?: HttpContext
    body: AiQuestionQuery
  }
): Observable<StrictHttpResponse<Array<AiQuestionProposal>>> {

    const rb = new RequestBuilder(this.rootUrl, AiService.GenerateQuestionPath, 'post');
    if (params) {
      rb.header('cognitousergroup', params.cognitousergroup, {});
      rb.header('cognitouserid', params.cognitouserid, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<AiQuestionProposal>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `generateQuestion$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  generateQuestion(params: {
    cognitousergroup?: string;
    cognitouserid?: string;
    context?: HttpContext
    body: AiQuestionQuery
  }
): Observable<Array<AiQuestionProposal>> {

    return this.generateQuestion$Response(params).pipe(
      map((r: StrictHttpResponse<Array<AiQuestionProposal>>) => r.body as Array<AiQuestionProposal>)
    );
  }

  /**
   * Path part for operation generateFlashCard
   */
  static readonly GenerateFlashCardPath = '/ai/flash_cards/generate';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `generateFlashCard()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  generateFlashCard$Response(params: {
    cognitousergroup?: string;
    cognitouserid?: string;
    context?: HttpContext
    body: AiFlashCardQuery
  }
): Observable<StrictHttpResponse<Array<AiFlashCardProposal>>> {

    const rb = new RequestBuilder(this.rootUrl, AiService.GenerateFlashCardPath, 'post');
    if (params) {
      rb.header('cognitousergroup', params.cognitousergroup, {});
      rb.header('cognitouserid', params.cognitouserid, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<AiFlashCardProposal>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `generateFlashCard$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  generateFlashCard(params: {
    cognitousergroup?: string;
    cognitouserid?: string;
    context?: HttpContext
    body: AiFlashCardQuery
  }
): Observable<Array<AiFlashCardProposal>> {

    return this.generateFlashCard$Response(params).pipe(
      map((r: StrictHttpResponse<Array<AiFlashCardProposal>>) => r.body as Array<AiFlashCardProposal>)
    );
  }

  /**
   * Path part for operation evaluateQuestion
   */
  static readonly EvaluateQuestionPath = '/ai/questions/evaluate';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `evaluateQuestion()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  evaluateQuestion$Response(params: {
    cognitousergroup?: string;
    cognitouserid?: string;
    context?: HttpContext
    body: AiQuestionEvaluation
  }
): Observable<StrictHttpResponse<AiQuestionEvaluationResult>> {

    const rb = new RequestBuilder(this.rootUrl, AiService.EvaluateQuestionPath, 'post');
    if (params) {
      rb.header('cognitousergroup', params.cognitousergroup, {});
      rb.header('cognitouserid', params.cognitouserid, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AiQuestionEvaluationResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `evaluateQuestion$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  evaluateQuestion(params: {
    cognitousergroup?: string;
    cognitouserid?: string;
    context?: HttpContext
    body: AiQuestionEvaluation
  }
): Observable<AiQuestionEvaluationResult> {

    return this.evaluateQuestion$Response(params).pipe(
      map((r: StrictHttpResponse<AiQuestionEvaluationResult>) => r.body as AiQuestionEvaluationResult)
    );
  }

}
