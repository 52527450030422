import {
  Component,
  ViewEncapsulation,
  ElementRef,
  OnInit,
} from '@angular/core';
import {
  UntypedFormGroup,
  Validators,
  UntypedFormControl,
} from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { AuthService } from './auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import {
  EmailDialogComponent,
  EmailDialogData,
} from './email-dialog.component';
import { filter } from 'rxjs/operators';
import { AppState } from '../app.types';
import { Store } from '@ngrx/store';
import { SidebarActions } from '../sidebar/sidebar.actions';

@Component({
  selector: 'qa-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class LoginComponent implements OnInit {
  errorMessage: string;
  form: UntypedFormGroup;

  constructor(
    private store: Store<AppState>,
    private authService: AuthService,
    private matDialog: MatDialog,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    elementRef: ElementRef<HTMLElement>
  ) {
    elementRef.nativeElement.classList.add('qa-login');
    this.form = new UntypedFormGroup({
      email: new UntypedFormControl('', [
        Validators.required,
        Validators.email,
      ]),
      password: new UntypedFormControl('', [Validators.required]),
    });
  }

  forgotPassword() {
    const dialogConfig: MatDialogConfig<EmailDialogData> = {
      data: {
        email: this.form.value.email,
      },
    };
    this.matDialog
      .open(EmailDialogComponent, dialogConfig)
      .afterClosed()
      .pipe(filter((email) => !!email))
      .subscribe((email) => this.authService.forgotPassword(email));
  }

  async ngOnInit() {
    if (await this.authService.isLoggedIn()) {
      this.router.navigateByUrl('/cms');
    }
    this.activatedRoute.queryParams.subscribe((params) => {
      if (params.noRole) {
        this.errorMessage = 'noRole';
        this.router.navigate(['.'], {
          relativeTo: this.activatedRoute,
          queryParams: {},
        });
        this.form.enable();
      }
    });
  }

  async login() {
    this.form.disable();
    this.errorMessage = null;
    const { email, password } = this.form.getRawValue();
    const userEmail = email.toLowerCase();
    try {
      await this.authService.login(userEmail, password);
    } catch (error) {
      this.errorMessage = error.message;
    }
    this.form.enable();
  }

  openSideBar() {
    this.store.dispatch(SidebarActions.showSidebar());
  }

  toParticipantsView() {
    this.router.navigateByUrl('/');
  }

  toRegistration() {
    this.router.navigateByUrl('/register');
  }
}
