import * as _ from 'lodash';
import { WordCloudWord } from './generate-word-cloud';

export async function generateRandomWords(): Promise<string[]> {
  let buffer = [];
  for (let i = 0; i < 10; i++) {
    const doc = await fetch(
      'https://en.wikipedia.org/api/rest_v1/page/random/summary'
    )
      .then((r) => r.json())
      .then((e) => e.extract);
    buffer = buffer.concat(doc.split(/\s+/gm));
  }
  return buffer;
}

export function rgbToHsl(hex): { h: number; s: number; l: number } {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex.trim());
  if (result && result.length) {
    let r = parseInt(result[1], 16);
    let g = parseInt(result[2], 16);
    let b = parseInt(result[3], 16);
    (r /= 255), (g /= 255), (b /= 255);
    const max = Math.max(r, g, b),
      min = Math.min(r, g, b);
    let h, s;
    const l = (max + min) / 2;

    if (max == min) {
      h = s = 0; // achromatic
    } else {
      const d = max - min;
      s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
      switch (max) {
        case r:
          h = (g - b) / d + (g < b ? 6 : 0);
          break;
        case g:
          h = (b - r) / d + 2;
          break;
        case b:
          h = (r - g) / d + 4;
          break;
      }
      h /= 6;
    }

    return { h, s, l };
  }
  return { h: 0, s: 0, l: 0 };
}

export function areListsTheSame(
  a: WordCloudWord[],
  b: WordCloudWord[]
): boolean {
  return _.isEqual(a, b);
}

export function asWordList(
  words: string[],
  width: number,
  height: number
): WordCloudWord[] {
  const list: WordCloudWord[] = Object.entries(
    words.reduce((acc, word) => {
      if (word) {
        const normalized = word.toLowerCase();
        if (acc && _.isObject(acc)) {
          acc[normalized] = acc[normalized] ? acc[normalized] + 1 : 1;
        }
      }
      return acc;
    }, {})
  ).map((entry) => ({
    text: entry[0],
    size: entry[1] as number,
  }));
  const totalWeight = list.reduce((p, c) => p + c.size, 0);
  list.forEach(
    (e) => (e.size = (e.size / totalWeight) * (Math.min(width, height) / 2))
  );
  return list;
}
