import { createAction, props } from '@ngrx/store';
import {
  QuestionStatsModel,
  LiveEvent,
  QuestionModel,
  LiveEventUser,
} from '../../api/models';
import * as CmsLiveEventTypes from './cms-live-event.types';

export namespace CmsLiveEventActions {
  export const cleanUp = createAction('[CMS Live Event] Clean Up');

  export const destroy = createAction('[CMS Live Event] Destroy');

  export const endLiveEvent = createAction('[CMS Live Event] End Live Event');

  export const endQuestion = createAction('[CMS Live Event] End Question');

  export const showResult = createAction('[CMS Live Event] Show result');

  export const init = createAction(
    '[CMS Live Event] Init',
    props<{ id: number }>()
  );

  export const refreshLiveEvent = createAction(
    '[CMS Live Event] Refresh Live Event'
  );

  export const updateQuestions = createAction(
    '[CMS Live Event] Update Questions',
    props<{ liveEventId: number; initEvent?: boolean }>()
  );

  export const resetLiveEvent = createAction(
    '[CMS Live Event] Reset LiveEvent',
    props<{ liveEventId: number }>()
  );

  export const removeUser = createAction(
    '[CMS Live Event] Remove User',
    props<{ userId: string }>()
  );

  export const startNextQuestion = createAction(
    '[CMS Live Event] Start Next Question'
  );

  export const storeLiveEvent = createAction(
    '[CMS Live Event] Store Live Event',
    props<{ liveEvent: LiveEvent }>()
  );

  export const storeQuestions = createAction(
    '[CMS Live Event] Store Questions',
    props<{ questions: QuestionModel[] }>()
  );

  export const storeResult = createAction(
    '[CMS Live Event] Store Result',
    props<{ result: QuestionStatsModel }>()
  );

  export const updateAnswerTime = createAction(
    '[CMS Live Event] Update Answer Time',
    props<{ answerTime: number }>()
  );

  export const updateMusic = createAction(
    '[CMS Live Event] Update Music',
    props<{ music: string }>()
  );

  export const updateTheme = createAction(
    '[CMS Live Event] Update Theme',
    props<{ theme: { color_code: string; logo_url: string } }>()
  );

  export const showFastestPlayer = createAction(
    '[CMS Live Event] Show Fastest Player',
    props<{ showFastestPlayer: boolean }>()
  );

  export const showAwardCeremony = createAction(
    '[CMS Live Event] Show Award Ceremony',
    props<{ showAwardCeremony: boolean }>()
  );

  export const showHighScoreList = createAction(
    '[CMS Live Event] Show High Score List',
    props<{ showHighScoreList: boolean }>()
  );

  export const manualResult = createAction(
    '[CMS Live Event] Set manual result',
    props<{ manualResult: boolean }>()
  );

  export const generateUserAutomatically = createAction(
    '[CMS Live Event] Set generate User Automatically',
    props<{ generateUserAutomatically: boolean }>()
  );

  export const updateStatus = createAction(
    '[CMS Live Event] Update Status',
    props<{ status: CmsLiveEventTypes.Status }>()
  );

  export const refreshUsers = createAction(
    '[CMS Live Event] Update Users',
    props<{ liveEventId: number }>()
  );

  export const storeUsers = createAction(
    '[CMS Live Event] Store Users',
    props<{ users: LiveEventUser[] }>()
  );

  export const storeTotalAnswers = createAction(
    '[CMS Live Event] Store total Answers',
    props<{ answers: number }>()
  );

  export const openLargeJoinInfo = createAction(
    '[CMS Live Event] Show Large Join Info'
  );

  export const closeLargeJoinInfo = createAction(
    '[CMS Live Event] Hide Large Join Info'
  );

  export const showQuestionOverview = createAction(
    '[CMS Live Event] Show Question Overview'
  );

  export const hideQuestionOverview = createAction(
    '[CMS Live Event] Hide Question Overview'
  );

  export const cancelLiveEvent = createAction(
    '[CMS Live Event] Cancel live event',
    props<{ liveEventId: number }>()
  );
}
