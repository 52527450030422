import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { filter, switchMap } from 'rxjs/operators';
import { TagModel, TagQuery } from '../../api/models';
import { TagsService } from '../../api/services';
import { DialogService } from '../common/dialog.service';
import { NavigationBreadcrumb } from '../common/navigation-breadcrumbs/navigation-breadcrumbs.component';
import { TagEditComponent } from '../edit/tag-edit.component';
import { multiSelectAction } from './grid-data/grid-data.component';
import { ListActionMeta, ListConfig, ListQuery } from './list.types';

@Component({
  selector: 'qa-tag-list',
  templateUrl: './tag-list.component.html',
  styleUrls: ['./tag-list.component.scss'],
})
export class TagListComponent implements OnInit {
  config: ListConfig<TagModel>;

  navigationBreadcrumb: NavigationBreadcrumb = {
    homeUrl: '/cms/dashboard',
    currentPage: 'Tags',
    breadcrumbs: [],
    help: [
      {
        id: 'stepByStep',
        icon: 'insert_drive_file',
        link: 'http://quizacademy.de/tipps/tags',
        text: 'exam.stepByStep',
      },
    ],
  };

  constructor(
    private tags: TagsService,
    private matDialog: MatDialog,
    private matSnackBar: MatSnackBar,
    private dialog: DialogService,
    private translator: TranslateService
  ) {}

  ngOnInit() {
    this.config = getTagListConfig(
      this.tags,
      this.matDialog,
      this.matSnackBar,
      this.dialog,
      this.translator,
      true
    );
  }
}

export function getTagListConfig(
  tags: TagsService,
  matDialog: MatDialog,
  matSnackBar: MatSnackBar,
  dialog: DialogService,
  translator: TranslateService,
  showMultiselect: boolean
): ListConfig<TagModel> {
  function onEdit(meta: ListActionMeta<TagModel>) {
    matDialog
      .open<any, TagModel>(TagEditComponent, { data: meta.row })
      .afterClosed()
      .subscribe(() => meta.dataSource.refresh());
  }

  const ret: ListConfig<TagModel> = {
    actions: [
      {
        icon: 'edit',
        name: 'edit',
        click: onEdit,
        tooltip: 'common.tooltip.edit',
        type: 'button',
      },
      {
        click: ({ row, dataSource }) => {
          dialog
            .confirmDelete()
            .pipe(
              filter((isConfirmed) => isConfirmed),
              switchMap(() => tags.detachUserTag({ id: row.id }))
            )
            .subscribe({
              next: () => {
                matSnackBar.open(translator.instant('tag.deleteItem.success'));
                dataSource.refresh();
              },
              error: () => {
                matSnackBar.open(translator.instant('tag.deleteItem.error'));
              },
            });
        },
        icon: 'delete',
        name: 'delete',
        tooltip: 'question.tooltip.delete',
        type: 'button',
      },
    ],
    activeActionNames: ['delete'],
    activeColumnNames: ['name'],
    columns: [
      {
        label: 'course.table.name',
        name: 'name',
        sortable: true,
        primary: true,
        editableField: {
          field: 'name',
          submit: (meta) => {
            tags
              .updateUserTags({
                body: meta.row,
              })
              .subscribe({
                next: () => {
                  matSnackBar.open(translator.instant('tag.message.edit'));
                },
              });
          },
        },
        filter: (value) => {
          return {
            name: value,
          };
        },
      },
    ],
    createButton: {},
    fetch: (queryParams?: ListQuery<TagModel>) => {
      const query = queryParams as unknown as TagQuery;
      query.filters = { ...query.filters, show_only_user_tags: true };
      return tags.tagControllerGetStats({ body: query });
    },
    query: {
      initial: {
        sorting: [
          {
            option: 'name',
            direction: 'ASC',
          },
        ],
      },
      searchColumnName: 'name',
    },
    name: 'tagList',
    title: 'menu.tags',
  };

  if (showMultiselect) {
    ret.multiselectActions = [
      {
        icon: 'delete',
        name: 'question.tooltip.delete',
        type: 'button',
        click: (meta) => {
          return new Promise((resolve) => {
            dialog
              .confirmDelete(
                null,
                translator.instant('common.message.deleteMulti', {
                  count: meta.rows.length,
                })
              )
              .pipe(filter((isConfirmed) => isConfirmed))
              .subscribe(async () => {
                await multiSelectAction(
                  meta.rows,
                  async (item: any) => {
                    await tags.detachUserTag({ id: item.id }).toPromise();
                    return true;
                  },
                  `${translator.instant(
                    'common.label.deleting'
                  )} ${translator.instant('common.label.tag')}`,
                  matDialog
                );
                meta.dataSource.refresh();
                resolve();
              });
          });
        },
      },
    ];
  }

  return ret;
}
