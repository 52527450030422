<qa-content-spinner [active]="loading">
  <form (ngSubmit)="onSubmit()" [formGroup]="form">
    <ng-content *ngTemplateOutlet="title"> </ng-content>
    <mat-dialog-content>
      <ng-content *ngTemplateOutlet="main"> </ng-content>
    </mat-dialog-content>
    <mat-dialog-actions>
      <ng-content *ngTemplateOutlet="actions"> </ng-content>
    </mat-dialog-actions>
  </form>
</qa-content-spinner>
<ng-template #title>
  <h1 mat-dialog-title>
    {{ 'tag.editTitle' | translate }}
  </h1>
</ng-template>
<ng-template #main>
  <qa-form-field-input
    [control]="form.get('name')"
    [disableFloatingLabel]="true"
    label="{{ 'quiz.form.name' | translate }}"
    required="true"
  >
  </qa-form-field-input>
</ng-template>
<ng-template #actions>
  <div class="flex-align-right">
    <qa-button mat-dialog-close label="common.button.shutdown"></qa-button>
    <qa-button
      [disabled]="form.invalid || form.pristine"
      [isRaisedButton]="true"
      label="common.button.save"
      type="submit"
    ></qa-button>
  </div>
</ng-template>
