import { Component, Inject } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface SelectDialogComponentData {
  title: string;
  text: string;
  formLabel?: string;
  options: any[];
}

@Component({
  selector: 'select-dialog',
  templateUrl: './select-dialog.component.html',
  styleUrls: ['./select-dialog.component.scss'],
})
export class SelectDialogComponent {
  value = new UntypedFormControl();

  constructor(
    public dialogRef: MatDialogRef<SelectDialogComponentData>,
    @Inject(MAT_DIALOG_DATA) public data: SelectDialogComponentData
  ) {}
}
