import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import {
  Component,
  ElementRef,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { filter, takeUntil } from 'rxjs/operators';
import {
  CommentsService,
  CoursesService,
  FlashCardsService,
} from '../api/services';
import { AppState, NavigationGroup, NavigationItem } from '../app.types';
import { AuthService } from '../auth/auth.service';
import { DestroyNotifier } from '../destroy-notifier';
import { SidebarActions } from './sidebar.actions';
import { forkJoin } from 'rxjs';
import { CourseQuery, FlashCardQuery } from '../api/models';
import { UtilityService } from '../utility/utility.service';

@Component({
  selector: 'qa-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger('expandableNavGroup', [
      state(
        'open',
        style({
          height: '*',
          overflow: 'hidden',
        })
      ),
      state(
        'closed',
        style({
          height: '65px',
          overflow: 'hidden',
        })
      ),
      transition('* => *', animate('200ms ease-out')),
    ]),
  ],
})
export class SidebarComponent extends DestroyNotifier implements OnInit {
  isVisible = false;
  navGroups: NavigationGroup[] = [];
  isCms = false;
  disabledItems;

  constructor(
    public auth: AuthService,
    private comments: CommentsService,
    private router: Router,
    elementRef: ElementRef<HTMLElement>,
    private store: Store<AppState>,
    private courses: CoursesService,
    private utilityService: UtilityService,
    private flashcards: FlashCardsService
  ) {
    super();
    this.initNavMenu();
    const requests = [
      this.comments.getComments({ done: false }),
      this.courses.queryCourses({
        body: {
          filters: {
            is_watched: false,
          },
          sorting: [
            {
              option: 'id',
              direction: 'DESC',
            },
          ],
          paging: {
            page_size: 10000,
          },
        } as Partial<CourseQuery> as CourseQuery,
      }),
      this.flashcards.queryFlashCards({
        body: {
          filters: {
            is_accepted: false,
          },
          sorting: [
            {
              option: 'id',
              direction: 'DESC',
            },
          ],
          paging: {
            page_size: 1,
          },
        } as Partial<FlashCardQuery> as FlashCardQuery,
      }),
    ];
    forkJoin(requests).subscribe(
      ([comments, newCourses, nonAcceptedCards]: any) => {
        utilityService.setNonAcceptedCards(nonAcceptedCards.pagination.total);
        utilityService.setOpenComments(comments.length);
        this.disabledItems = {
          comments: comments.length,
          importedCourses: newCourses.data.length,
          nonAcceptedCards: nonAcceptedCards.pagination.total,
        };
        this.initNavMenu();
      }
    );
    elementRef.nativeElement.classList.add('qa-sidebar');
    store.pipe(takeUntil(this.destroy$)).subscribe((state) => {
      this.isVisible = state.sidebar.isVisible;
    });
    router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        if (router.url.indexOf('dashboard') !== -1) {
          this.isVisible = true;
        } else {
          this.isVisible = false;
        }
        this.isCms =
          router.url.indexOf('/cms') !== -1 &&
          router.url.indexOf('/cms/organizations') === -1 &&
          router.url.indexOf('/cms/users') === -1
            ? true
            : false;
      }
    });
  }

  ngOnInit() {
    if (window.innerWidth < 768) {
      this.store.dispatch(SidebarActions.hideSidebar());
    }

    this.utilityService.nonAcceptedCards$
      .pipe(takeUntil(this.destroy$))
      .subscribe((count) => {
        if (this.disabledItems) {
          this.disabledItems.nonAcceptedCards = count && count > 0 ? count : 0;
          this.initNavMenu();
        }
      });

    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => {
        this.navGroups.forEach((group) => {
          group.open = group.items.some(
            (item) =>
              (item.link && this.router.isActive(item.link, true)) ?? false
          );
        });
      });
  }

  isActive(item: NavigationItem) {
    return this.router.url.indexOf(item.link) != -1;
  }

  initNavMenu() {
    this.navGroups = [
      {
        active: () => this.auth.currentUserHasGroup('Admin'),
        expandable: true,
        icon: 'admin_panel_settings',
        label: 'Admin',
        items: [
          {
            icon: 'import_export',
            label: 'menu.courseExports',
            link: '/cms/courseExports',
          },
          {
            label: 'menu.categories',
            link: '/cms/categories',
            icon: 'category',
          },
          {
            label: 'menu.statistics',
            link: '/cms/statistics',
            icon: 'timeline',
          },
          {
            label: 'Customer Support',
            link: '/cms/customer-support/',
            icon: 'support_agent',
          },
        ],
      },
      {
        active: () => this.auth.isLoggedIn(),
        items: [
          {
            label: 'Dashboard',
            link: '/cms/dashboard',
            icon: 'dashboard',
            onClick: () => this.openSideBar(),
          },
        ],
      },
      {
        active: () => this.auth.isLoggedIn(),
        items: [
          {
            label: 'menu.content',
            link: '/cms/courses',
            icon: 'collections_bookmark',
            badgeCount: this.disabledItems?.importedCourses,
            onClick: () => this.closeSideBar(),
          },
        ],
      },
      {
        active: () => this.auth.isLoggedIn(),
        items: [
          {
            label: 'Live-Events',
            link: '/cms/live-events',
            icon: 'live_tv',
            disabledTip: 'menu.cantAccess.quiz',
          },
          {
            label: 'menu.books',
            link: '/cms/books',
            icon: 'auto_stories',
            hideIf: () =>
              !this.auth.currentUserHasGroup('Admin') &&
              !this.auth.currentUserHasGroup('BookManager'),
          },
        ],
      },
      {
        active: () => this.auth.isLoggedIn(),
        items: [
          {
            label: 'menu.exams',
            link: '/cms/exams',
            icon: 'assignment',
            disabledTip: 'menu.cantAccess.course',
          },
        ],
      },
      {
        active: () => this.auth.isLoggedIn(),
        items: [
          {
            label: 'menu.allContent',
            icon: 'view_list',
            link: '/cms/content',
            exact: false,
          },
        ],
      },
      {
        active: () => false,
        items: [
          {
            label: 'menu.featureDevelopment',
            link: '/cms/feature-requests',
            icon: 'developer_board',
          },
        ],
      },
      {
        active: () => this.auth.isLoggedIn(),
        items: [
          {
            label: 'menu.courseMarket',
            link: '/cms/course-market',
            icon: 'shopping_cart',
          },
        ],
      },
      {
        active: () => this.auth.isLoggedIn(),
        items: [
          {
            label: 'menu.help&contact',
            link: '/cms/help-contact',
            icon: 'help_outline',
          },
        ],
      },
      {
        active: () => this.auth.isLoggedIn(),
        items: [
          {
            label:
              this.auth.currentUserInfo$.value.given_name +
              ' ' +
              this.auth.currentUserInfo$.value.family_name,
            icon: 'account_circle',
            link: '/profile',
          },
        ],
      },
    ];
  }

  getCurrentDate(): Date {
    return new Date();
  }

  closeSideBar() {
    this.store.dispatch(SidebarActions.hideSidebar());
  }

  openSideBar() {
    this.store.dispatch(SidebarActions.showSidebar());
  }

  toggleMenu(close = false) {
    if (this.isVisible || close) {
      this.navGroups.forEach((group) => {
        if (group.expandable) {
          group.open = false;
        }
      });
      this.store.dispatch(SidebarActions.hideSidebar());
    } else {
      this.store.dispatch(SidebarActions.showSidebar());
    }
  }

  toggleGroup(group) {
    group.open = !group.open;
  }
}
