import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot } from '@angular/router';
import { AuthService } from './auth.service';

@Injectable()
export class CanActivateHasGroup implements CanActivate {
  constructor(private authService: AuthService, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot) {
    if (!this.authService.isLoggedIn()) {
      return this.router.createUrlTree(['/login']);
    }
    const group = route.data.group;
    if (!group) return false;
    const hasGroup = this.authService.currentUserHasGroup(group);
    return hasGroup || this.router.createUrlTree(['/cms']);
  }
}
