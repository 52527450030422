<div
  class="button"
  [class.icon-button]="isIconButton"
  [class.isRoundedButton]="isRoundedButton"
  [class.showIconAtEnd]="showIconAtEnd"
>
  <ng-container *ngIf="anchorLink; else buttonTemplate">
    <a
      *ngIf="isRaisedButton"
      [style.min-width.px]="minWidth ? minWidth : null"
      [disabled]="disabled"
      [href]="anchorLink"
      [target]="target"
      [color]="color"
      mat-raised-button
      [type]="type"
      [ngClass]="buttonClass"
    >
      <mat-icon [class.material-icons]="isFillIcon" *ngIf="icon">{{
        icon
      }}</mat-icon>
      <span class="button-label" *ngIf="!isIconButton">
        {{ label | translate }}
      </span>
    </a>
    <a
      *ngIf="!isRaisedButton"
      [style.min-width.px]="minWidth ? minWidth : null"
      [disabled]="disabled"
      [href]="anchorLink"
      [target]="target"
      [color]="color"
      mat-button
      [type]="type"
      [ngClass]="buttonClass"
    >
      <mat-icon [class.material-icons]="isFillIcon" *ngIf="icon">{{
        icon
      }}</mat-icon>
      <span class="button-label" *ngIf="!isIconButton">
        {{ label | translate }}
      </span>
    </a>
  </ng-container>
  <ng-template #buttonTemplate>
    <ng-container *ngIf="isRaisedButton; else normal">
      <button
        [style.min-width.px]="minWidth ? minWidth : null"
        [disabled]="disabled"
        (click)="performAction($event)"
        [color]="color"
        mat-raised-button
        [type]="type"
        [ngClass]="buttonClass"
      >
        <mat-icon [class.material-icons]="isFillIcon" *ngIf="icon">{{
          icon
        }}</mat-icon>
        <span class="button-label" *ngIf="!isIconButton">
          {{ label | translate }}
        </span>
      </button>
    </ng-container>
    <ng-template #normal>
      <button
        [style.min-width.px]="minWidth ? minWidth : null"
        [disabled]="disabled"
        (click)="performAction($event)"
        [ngClass]="buttonClass"
        mat-button
        [type]="type"
      >
        <mat-icon [class.material-icons]="isFillIcon" *ngIf="icon">{{
          icon
        }}</mat-icon>
        <span class="button-label" *ngIf="!isIconButton">
          {{ label | translate }}
        </span>
      </button>
    </ng-template>
  </ng-template>
</div>
