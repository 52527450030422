<ng-container *ngIf="question">
  <qa-countdown *ngIf="question.isCountingDown"> </qa-countdown>
  <div *ngIf="!question.isCountingDown" class="inner">
    <div class="left flex-column">
      <ng-scrollbar data-check="true" class="text">
        <img
          src="https://s3.{{ 's3.mediaBucket.region' | env }}.amazonaws.com/{{
            's3.mediaBucket.name' | env
          }}/{{ question.model.media.uuid }}.{{
            question.model.media.file_ext
          }}"
          *ngIf="question.model.media?.uuid"
          class="image"
        />
        <qa-markdown-katex [data]="question.model.text"> </qa-markdown-katex>
        <div class="answers">
          <qa-question-answers
            [disabled]="
              answersDisabled ||
              question.isAnswered ||
              question.isCompleted ||
              question.isDisabled
            "
            [control]="answerSelectionControl"
            [question]="question.model"
            [showCorrectAnswers]="question.isCompleted"
            [trueFalseOrderInverted]="question.trueFalseOrderInverted"
          >
          </qa-question-answers>
          <ng-container *ngIf="!answersDisabled">
            <button
              (click)="submitAnswer()"
              [disabled]="isSubmitAnswerDisabled"
              *ngIf="!question.isAnswered && !question.isCompleted"
              class="submit-answer-button"
              color="primary"
              mat-button
              type="button"
            >
              <mat-icon> done </mat-icon>
              {{ 'question.label.submitAns' | translate }}
            </button>
            <button
              (click)="retractAnswer()"
              *ngIf="
                !isAnswerRetractionDisabled &&
                question.isAnswered &&
                !question.isCompleted
              "
              class="retract-answer-button"
              color="primary"
              mat-button
              type="button"
            >
              <mat-icon> edit </mat-icon>
              {{ 'question.label.editAns' | translate }}
            </button>
          </ng-container>
        </div>
      </ng-scrollbar>
    </div>
    <div class="right">
      <ng-scrollbar>
        <qa-play-timer [showLargeTimer]="true"></qa-play-timer>
        <qa-play-timer class="small-mobile-only"></qa-play-timer>
        <div class="submitted-answers">
          <div class="progress">
            <div class="top">
              <div class="position">
                {{ answerSubmitted | number : '2.0-0' }} /
                {{ numOfUsers | number : '2.0-0' }}
                {{ 'question.label.answersSubmitted' | translate }}
              </div>
            </div>
            <mat-progress-bar
              [value]="getProgressAnswer(answerSubmitted, numOfUsers)"
              color="primary"
            >
            </mat-progress-bar>
          </div>
        </div>
      </ng-scrollbar>
    </div>
    <div *ngIf="isCompleted" class="explanation-panel qa-theme-inverted">
      <ng-scrollbar class="scroll-container">
        <div class="correct-incorrect">
          <mat-icon>
            {{ isAnsweredCorrectly ? 'done' : 'clear' }}
          </mat-icon>
          {{
            (isAnsweredCorrectly
              ? 'question.label.correct'
              : isAnsweredIncorrectly
              ? 'question.label.inCorrect'
              : 'question.label.notAnswered'
            ) | translate
          }}
        </div>
        <ng-container *ngIf="question.isExplanationPanelOpen">
          <div class="correct-answer-text">{{ getCorrectAnswerText() }}</div>
          <ng-container *ngIf="question.model.type !== questionType.TrueFalse">
            <ul>
              <li *ngFor="let correctAnswer of getCorrectAnswers()">
                <qa-markdown-katex [data]="correctAnswer.text">
                </qa-markdown-katex>
              </li>
            </ul>
          </ng-container>
          <qa-markdown-katex [data]="question.model.explanation">
          </qa-markdown-katex>
        </ng-container>
      </ng-scrollbar>
      <div class="actions">
        <a
          *ngIf="!data.hideWeblink && getWeblink()"
          [href]="getWeblink()"
          color="primary"
          mat-button
          target="_blank"
          type="button"
        >
          <mat-icon>open_in_new</mat-icon>
          {{ 'question.openWebLink' | translate }}
        </a>
        <button
          (click)="
            question.isExplanationPanelOpen = !question.isExplanationPanelOpen
          "
          class="toggle-explanation-button"
          color="primary"
          mat-button
          type="button"
        >
          <mat-icon>arrow_back_ios</mat-icon>
          <span class="bttntext">{{
            (question.isExplanationPanelOpen
              ? 'question.label.showQuestion'
              : 'question.label.showExplanation'
            ) | translate
          }}</span>
        </button>
      </div>
    </div>
  </div>
  <div [matRippleDisabled]="true" #submitAnswerRipple matRipple></div>
</ng-container>
