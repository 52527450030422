import { Component, OnDestroy } from '@angular/core';
import { Card } from '../flash-card.types';
import { AppState } from 'src/app/app.types';
import { Store } from '@ngrx/store';
import { filter } from 'rxjs';
import { CardResultModel } from 'src/app/api/models';
import * as FlashCardActions from '../flash-card.actions';
import { PublicService } from 'src/app/api/services';

@Component({
  selector: 'qa-flash-card-submit-action',
  templateUrl: './flash-card-submit-action.component.html',
  styleUrls: ['./flash-card-submit-action.component.scss'],
})
export class FlashCardSubmitActionComponent implements OnDestroy {
  card: Card;
  didKnowAnswer: boolean = false;
  state: AppState;
  cardSubmitted = false;
  submitInterval;

  constructor(
    private publicService: PublicService,
    private store: Store<AppState>
  ) {
    store
      .pipe(filter((state) => !!state.flashCard.cards))
      .subscribe((state) => this.handleStateChange(state));
  }

  handleStateChange(state: AppState) {
    this.state = state;
    this.card = state.flashCard.cards[state.play.progressCurrent - 1];
    if (this.card && this.card.model) {
      this.didKnowAnswer = this.card?.cardSubmitted?.knowAnswer;
    }
  }

  onIconClick(value: boolean) {
    let result: number = 0;
    this.didKnowAnswer = value;
    result = value ? 1 : 0;
    if (!this.cardSubmitted) {
      this.cardSubmitted = true;
      this.submitCard(result);
      this.submitInterval = setTimeout(() => {
        this.cardSubmitted = false;
      }, 12000);
    }
  }

  submitCard(result: number) {
    if (!this.card?.model) {
      return;
    }
    const body: CardResultModel = {
      course_id: this.state.course.course.id,
      stack_id: this.state.flashCard.stack.id,
      card_id: this.card.model.id,
      result: result,
    };
    this.publicService.createPublicFlashCardResult({ body }).subscribe(() => {
      this.store.dispatch(
        FlashCardActions.cardSubmitted({
          id: this.card.model.id,
          knowAnswer: this.didKnowAnswer,
        })
      );
    });
  }

  get currentCard() {
    return this.state?.flashCard?.cards[this.state?.play?.progressCurrent - 1];
  }

  ngOnDestroy(): void {
    if (this.submitInterval) {
      clearInterval(this.submitInterval);
    }
  }
}
