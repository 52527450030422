import { DatePipe } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { filter, map, switchMap } from 'rxjs/operators';
import { CommentModel, CommentQuery } from 'src/app/api/models';
import {
  CommentsService,
  FlashCardsService,
  QuestionsService,
} from 'src/app/api/services';
import { DialogService } from 'src/app/cms/common/dialog.service';
import { CommentDetailsDialogComponent } from './comment-details-dialog.component';
import { ListConfig, ListQuery, MatTabGroupConfig } from './list.types';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'qa-comment-list',
  templateUrl: './comment-list.component.html',
  styleUrls: ['./comment-list.component.scss'],
})
export class CommentListComponent {
  @Input() hideWrapper = false;
  @Input() questionId: number;
  @Input() flashCardId: number;
  @Input() title: string;
  matTabConfig: MatTabGroupConfig<CommentModel>;
  selectedTabIndex = 0;

  constructor(
    private comments: CommentsService,
    private date: DatePipe,
    private dialog: DialogService,
    private matDialog: MatDialog,
    private matSnackBar: MatSnackBar,
    private questions: QuestionsService,
    private translator: TranslateService,
    private router: Router,
    route: ActivatedRoute,
    private flashCards: FlashCardsService
  ) {
    route.queryParams.subscribe((params) => {
      if (params.isFlashcard) {
        this.selectedTabIndex = 1;
        this.initTable();
      } else {
        this.initTable();
      }
    });
  }

  initTable() {
    const questionConfig = getCommentListConfig(
      this.comments,
      this.questions,
      this.matDialog,
      this.dialog,
      this.matSnackBar,
      this.date,
      this.hideWrapper,
      this.questionId,
      this.title,
      this.translator,
      this.router
    );

    let flashCardConfig = getCommentListConfig(
      this.comments,
      this.questions,
      this.matDialog,
      this.dialog,
      this.matSnackBar,
      this.date,
      this.hideWrapper,
      this.questionId,
      this.title,
      this.translator,
      this.router,
      true,
      this.flashCards,
      this.flashCardId
    );

    flashCardConfig = {
      ...flashCardConfig,
      fetch: (queryParams?: ListQuery) => {
        if (this.flashCardId) {
          return this.flashCards.getCommentsForFlashCard({
            id: this.flashCardId,
          });
        }
        const query = queryParams as unknown as CommentQuery;
        query.filters.comment_type = 1;
        return this.comments.queryComments({ body: query });
      },
      name: 'flashcard-comment',
    };

    this.matTabConfig = {
      title: 'comment.title',
      selectedTabIndex: this.selectedTabIndex,
      tabs: [
        {
          label: 'question.title',
          config: questionConfig,
        },
        {
          label: 'flashCard.title',
          config: flashCardConfig,
        },
      ],
    };
  }
}

export function getCommentListConfig(
  comments: CommentsService,
  questions: QuestionsService,
  matDialog: MatDialog,
  dialog: DialogService,
  matSnackBar: MatSnackBar,
  date: DatePipe,
  hideWrapper?: boolean,
  questionId?: number,
  title?: string,
  translator?: TranslateService,
  router?: Router,
  isFlashCardComment?: boolean,
  flashCards?: FlashCardsService,
  flashCardId?: number
): ListConfig<CommentModel> {
  function openDetails(comment: CommentModel) {
    matDialog.open(CommentDetailsDialogComponent, {
      data: { comment },
      width: '512px',
    });
  }

  function markAsDone(comment: CommentModel) {
    comment.done = !comment.done;
    comments
      .updateComment({
        id: comment.id,
        body: comment,
      })
      .subscribe();
  }

  function markAsOpen(comment: CommentModel) {
    comment.done = false;
    comments
      .updateComment({
        id: comment.id,
        body: comment,
      })
      .subscribe();
  }

  return {
    actions: [
      {
        click: ({ row }) => openDetails(row),
        icon: 'info',
        name: 'openDetails',
        tooltip: 'comment.tooltip.commentDetail',
        type: 'button',
      },
      {
        click: ({ row }) => markAsDone(row),
        icon: 'done',
        isDisabled: ({ row }) => row.done,
        name: 'markAsDone',
        tooltip: 'comment.tooltip.markAsDone',
        type: 'button',
      },
      {
        click: ({ row }) => markAsOpen(row),
        icon: 'markunread_mailbox',
        isDisabled: ({ row }) => !row.done,
        name: 'markAsOpen',
        tooltip: 'comment.tooltip.markAsOpen',
        type: 'button',
      },
      {
        name: 'edit',
        icon: 'edit',
        type: 'button',
        click: (meta) => {
          if (isFlashCardComment) {
            router.navigate([`/cms/edit/flash-card`, meta.row.flash_card_id], {
              queryParams: { commentId: meta.row.id },
            });
          } else {
            router.navigate([`/cms/edit/question`, meta.row.question_id], {
              queryParams: { commentId: meta.row.id },
            });
          }
        },
      },
      {
        click: ({ row, dataSource }) => {
          dialog
            .confirmDelete()
            .pipe(
              filter((isConfirmed) => isConfirmed),
              switchMap(() => comments.deleteComment({ id: row.id }))
            )
            .subscribe({
              next: () => {
                matSnackBar.open(
                  translator.instant('comment.message.deleteSuccess')
                );
                dataSource.refresh(true);
              },
              error: () => {
                matSnackBar.open(
                  translator.instant('comment.message.deleteFail')
                );
              },
            });
        },
        icon: 'delete',
        name: 'delete',
        tooltip: 'comment.tooltip.delete',
        type: 'button',
      },
    ],
    activeActionNames: ['edit', 'delete'],
    activeColumnNames: ['done', 'comment', 'created_at'],
    columns: [
      {
        label: 'comment.label.done',
        hideLabel: true,
        toggle: {
          action: (_, meta) => {
            markAsDone(meta.row);
          },
          isCheckToggle: true,
        },
        width: '50px',
        name: 'done',
        filter: (value) => ({
          done: value,
        }),
        filterOptions: [
          {
            name: 'common.label.yes',
            value: true,
          },
          {
            name: 'common.label.no',
            value: false,
          },
        ],
      },
      {
        label: 'comment.table.comment',
        name: 'comment',
        sortable: true,
        filter: (value) => {
          return {
            comment: value,
          };
        },
        click: (meta) => {
          if (isFlashCardComment) {
            router.navigate([`/cms/edit/flash-card`, meta.row.flash_card_id], {
              queryParams: { commentId: meta.row.id },
            });
          } else {
            router.navigate([`/cms/edit/question`, meta.row.question_id], {
              queryParams: { commentId: meta.row.id },
            });
          }
        },
      },
      {
        accessor: (comment) => {
          return comment.created_at
            ? date.transform(new Date(comment.created_at), 'short')
            : '';
        },
        label: 'comment.table.created',
        name: 'created_at',
        sortable: true,
        width: '150px',
      },
    ],
    fetch: (queryParams?: ListQuery) => {
      if (questionId) {
        return questions
          .getCommentsForQuestion({
            id: questionId,
          })
          .pipe(
            map((response) => {
              return {
                data: response,
                pagination: {
                  has_next_page: false,
                  has_previous_page: false,
                  page: 0,
                  page_size: response.length,
                  total: response.length,
                },
                sorting: queryParams.sorting,
              };
            })
          );
      }
      if (flashCardId) {
        return flashCards
          .getCommentsForFlashCard({
            id: flashCardId,
          })
          .pipe(
            map((response) => {
              return {
                data: response,
                pagination: {
                  has_next_page: false,
                  has_previous_page: false,
                  page: 0,
                  page_size: response.length,
                  total: response.length,
                },
                sorting: queryParams.sorting,
              };
            })
          );
      }
      const query = queryParams as unknown as CommentQuery;
      query.filters.comment_type = 0;
      return comments.queryComments({ body: query });
    },
    hideWrapper,
    query: {
      initial: {
        sorting: [
          {
            option: 'comment',
            direction: 'DESC',
          },
        ],
      },
      searchColumnName: 'comment',
    },
    name: 'commentList',
    title: title || '',
  };
}
