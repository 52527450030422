import {
  Component,
  Input,
  ElementRef,
  OnChanges,
  ViewEncapsulation,
  SimpleChanges,
  HostBinding,
  OnInit,
} from '@angular/core';
import { cloneDeep, isNil } from 'lodash';
import { environment } from 'src/environments/environment';
export interface DonutSection {
  label: string;
  value: number;
  color?: string;
  featured?: boolean;
  shortLabel?: string;
  click?: () => void;
}

const AVAILABLE_COLORS = [
  environment.colors.primary,
  '#2EC229',
  '#4832C2',
  '#F40320',
  '#C20C70',
  '#C23D30',
  '#C149A1',
];

@Component({
  selector: 'qa-donut-chart',
  templateUrl: './donut-chart.component.html',
  styleUrls: ['./donut-chart.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DonutChartComponent implements OnChanges, OnInit {
  @Input() sections: DonutSection[];
  @Input() sort: boolean;

  @HostBinding('class.large')
  @Input()
  large?: boolean;

  dataDone = false;

  dashoffsets = [];

  activeSections: DonutSection[] = [];

  valueSum = 0;

  featureValue = undefined;
  featureLabel = undefined;

  dim = {
    w: 216,
    r: 100,
    s: 16,
  };

  constructor(elementRef: ElementRef<HTMLElement>) {
    elementRef.nativeElement.classList.add('qa-donut-chart');
  }

  ngOnInit(): void {
    this.dataDone = true;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.sections) {
      this.activeSections = cloneDeep(changes.sections.currentValue);
      if (!this.activeSections) {
        this.activeSections = [];
      }
      if (this.sort && this.activeSections) {
        this.activeSections.sort((a, b) => b.value - a.value);
      }
      this.valueSum = this.activeSections.reduce((p, c) => {
        return p + c.value;
      }, 0);
      let acc = 0;
      this.dashoffsets = this.activeSections.map((section) => {
        const percentage = (section.value + acc) / this.valueSum;
        acc += section.value;
        return this.getDashoffset(percentage);
      });
      const featuredSection =
        this.activeSections.find((section) => section.featured) ?? undefined;
      const featuredValue = featuredSection?.value ?? undefined;
      const featuredLabel = featuredSection?.shortLabel ?? undefined;
      if (!isNil(featuredValue)) {
        this.featureValue = Math.round((featuredValue / this.valueSum) * 100);
        this.featureLabel = featuredLabel;
      } else {
        this.featureValue = undefined;
        this.featureLabel = undefined;
      }
    }
    this.dim = {
      w: this.large ? 512 : 216,
      r: this.large ? 160 : 100,
      s: this.large ? 64 : 16,
    };
  }

  getDashoffset(percentage: number) {
    return -(1 - percentage) * (this.large ? 1006 : 628);
  }

  max(a: number, b: number): number {
    return Math.max(a, b);
  }

  color(section: DonutSection): string {
    const idx = this.activeSections.indexOf(section);
    return AVAILABLE_COLORS[idx % AVAILABLE_COLORS.length];
  }
}
