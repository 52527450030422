import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { filter, switchMap } from 'rxjs/operators';
import {
  FlashCardModel,
  FlashCardStackModel,
  FlashCardStackQuery,
} from 'src/app/api/models';
import { FlashCardStacksService } from 'src/app/api/services';
import { DialogService } from 'src/app/cms/common/dialog.service';
import { ListActionMeta, ListConfig, ListQuery } from './list.types';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { PanelService } from '../panel/panel.service';
import * as fileSaver from 'file-saver';
import { cloneDeep } from 'lodash';
import { PanelEntityType } from '../panel/panel.types';
import { multiSelectAction } from './grid-data/grid-data.component';
import { EditService } from '../edit/edit.service';
import { StackEditConfigService } from '../edit/configs/stack.service';

@Component({
  selector: 'qa-flash-card-stack-list',
  templateUrl: './flash-card-stack-list.component.html',
  styleUrls: ['./flash-card-stack-list.component.scss'],
})
export class FlashCardStackListComponent implements OnInit {
  config: ListConfig<FlashCardStackModel>;

  constructor(
    private flashCardStacks: FlashCardStacksService,
    private dialog: DialogService,
    private panelService: PanelService,
    private matDialog: MatDialog,
    private matSnackBar: MatSnackBar,
    private translator: TranslateService,
    private editService: EditService,
    private router: Router
  ) {}

  ngOnInit() {
    const config = getFlashCardStackListConfig(
      this.flashCardStacks,
      this.matDialog,
      this.dialog,
      this.matSnackBar,
      this.translator,
      this.router,
      true,
      this.editService,
      this.panelService
    );
    this.config = config;
  }
}

export function getFlashCardStackListConfig(
  flashCardStacks: FlashCardStacksService,
  matDialog: MatDialog,
  dialog: DialogService,
  matSnackBar: MatSnackBar,
  translator: TranslateService,
  router: Router,
  showMultiselectActions: boolean,
  editService: EditService,
  panelService?: PanelService
): ListConfig<FlashCardStackModel> {
  async function importCards(
    items: FlashCardModel[],
    parentId: number,
    dataSource
  ) {
    if (items?.length) {
      await flashCardStacks
        .copyCardsToFlashCardStack({ id: parentId, body: items })
        .toPromise();
    }
    dataSource.refresh();
  }

  async function exportAsJson(meta: ListActionMeta<FlashCardStackModel>) {
    meta.dataSource.loading = true;
    let fullItem: FlashCardStackModel;
    fullItem = cloneDeep(meta.row);
    const cards = await flashCardStacks
      .getCardsInFlashCardStack({ id: fullItem.id })
      .toPromise();
    cards.forEach((c) => delete c.id);
    delete fullItem.id;
    fullItem.cards = cards;
    fullItem['meta:importType'] = PanelEntityType.FlashCardStack;
    const file = `${JSON.stringify(fullItem)}\n`;
    const blob = new Blob([file], { type: 'application/json' });
    fileSaver.saveAs(
      blob,
      `qa-${PanelEntityType.FlashCardStack}-${fullItem.name}.json`
    );
    meta.dataSource.loading = false;
  }

  const ret: ListConfig<FlashCardStackModel> = {
    actions: [
      {
        click: ({ row, dataSource }) => {
          dialog
            .confirmDelete()
            .pipe(
              filter((isConfirmed) => isConfirmed),
              switchMap(() =>
                flashCardStacks.deleteFlashCardStack({ id: row.id })
              )
            )
            .subscribe({
              next: () => {
                matSnackBar.open(
                  translator.instant('flashCardStack.message.deleteSuccess')
                );
                dataSource.refresh();
              },
              error: () => {
                matSnackBar.open(
                  translator.instant('flashCardStack.message.deleteFail')
                );
              },
            });
        },
        icon: 'delete',
        name: 'delete',
        tooltip: 'flashCardStack.tooltip.delete',
        type: 'button',
      },
    ],
    actionsMenu: [
      {
        icon: 'save_alt',
        name: 'download',
        label: translator.instant('content.flashCardStack.actionMenu.item3'),
        type: 'button',
        click: (meta) => exportAsJson(meta),
      },
    ],
    activeActionNames: ['edit', 'cards', 'delete'],
    activeColumnNames: ['id', 'name', 'num_cards'],
    columns: [
      {
        label: 'ID',
        name: 'id',
        sortable: true,
        width: '64px',
        click: (meta) =>
          router.navigateByUrl(`/cms/content/${meta.row.id}/flash-cards`),
        filter: (value) => {
          return {
            id: Number(value),
          };
        },
      },
      {
        click: (meta) =>
          router.navigateByUrl(`/cms/content/${meta.row.id}/flash-cards`),
        label: 'Name',
        name: 'name',
        sortable: true,
        primary: true,
        filter: (value) => {
          return {
            name: value,
          };
        },
      },
      {
        label: 'common.label.quizFlashCard',
        name: 'num_cards',
        sortable: true,
        width: '200px',
        click: (meta) =>
          router.navigateByUrl(`/cms/content/${meta.row.id}/flash-cards`),
        filter: (value) => {
          return {
            num_cards: Number(value),
          };
        },
      },
    ],
    createButton: {
      options: [
        {
          text: translator.instant('content.flashCardStack.addMenu.item1'),
          icon: 'add',
          click: async (dataSource) => {
            await editService.open(StackEditConfigService);
            dataSource?.refresh();
          },
        },
      ],
    },
    imports: [
      {
        title: translator.instant('content.flashCardStack.title.item2'),
        description: translator.instant(
          'content.flashCardStack.description.item2'
        ),
        action: {
          icon: 'code',
          click: async (dataSource) => {
            const item = (await panelService.promptFile(
              'json'
            )) as FlashCardStackModel;
            matDialog.closeAll();
            dataSource.loading = true;
            flashCardStacks
              .createFlashCardStack({
                body: item,
              })
              .subscribe(
                (stack) => {
                  importCards(item?.cards, stack.id, dataSource);
                },
                () => (dataSource.loading = false)
              );
          },
          text: translator.instant('content.flashCardStack.addMenu.item3'),
        },
      },
    ],
    fetch: (queryParams?: ListQuery<FlashCardStackModel>) => {
      const query = queryParams as unknown as FlashCardStackQuery;
      return flashCardStacks.queryFlashCardStack({ body: query });
    },
    query: {
      initial: {
        sorting: [
          {
            option: 'id',
            direction: 'DESC',
          },
        ],
      },
      searchColumnName: 'name',
    },
    name: 'flashCardStackList',
    title: 'common.label.flashCardStack',
  };

  if (showMultiselectActions) {
    ret.multiselectActions = [
      {
        icon: 'delete',
        name: 'question.tooltip.delete',
        type: 'button',
        click: (meta) => {
          return new Promise((resolve) => {
            dialog
              .confirmDelete(
                null,
                translator.instant('common.message.deleteMulti', {
                  count: meta.rows.length,
                })
              )
              .pipe(filter((isConfirmed) => isConfirmed))
              .subscribe(async () => {
                await multiSelectAction(
                  meta.rows,
                  async (item: any) => {
                    await flashCardStacks
                      .deleteFlashCardStack({ id: item.id })
                      .toPromise();
                    return true;
                  },
                  `${translator.instant(
                    'common.label.deleting'
                  )} ${translator.instant('common.label.flashCardStack')}`,
                  matDialog
                );
                meta.dataSource.refresh();
                resolve();
              });
          });
        },
      },
    ];
  }

  return ret;
}
