import {
  Component,
  Input,
  SimpleChanges,
  OnChanges,
  Inject,
  OnInit,
} from '@angular/core';
import { DOCUMENT, Location } from '@angular/common';
import {
  HelpVideoDialogComponent,
  HelpVideoDialogData,
} from '../help-video-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { AppState } from 'src/app/app.types';
import { Store } from '@ngrx/store';
import { DynamicCourseStyleService } from 'src/app/styles/dynamic-course-style.service';
import { Router } from '@angular/router';

export interface Breadcrumb {
  text: string;
  icon?: string;
  route?: string;
}

export interface HelpCrumb {
  id: string;
  link?: string;
  text?: string;
  icon?: string;
}

export interface NavigationBreadcrumb {
  homeUrl: string;
  currentPage: string;
  breadcrumbs: Breadcrumb[];
  back?: {
    handleBack: () => void;
  };
  help?: HelpCrumb[];
  allowFullScreen?: boolean;
}

@Component({
  selector: 'qa-navigation-breadcrumbs',
  templateUrl: './navigation-breadcrumbs.component.html',
  styleUrls: ['./navigation-breadcrumbs.component.scss'],
})
export class NavigationBreadcrumbsComponent implements OnInit, OnChanges {
  @Input() navigationBreadcrumb: NavigationBreadcrumb;
  state: AppState;

  elem: any;

  constructor(
    private matDialog: MatDialog,
    private location: Location,
    @Inject(DOCUMENT) private document: any,
    private store: Store<AppState>,
    private router: Router,
    private dynamicStyle: DynamicCourseStyleService
  ) {
    this.store.subscribe((state) => {
      this.state = state;
    });
  }

  redirect(route: string) {
    if (route) {
      this.router.navigateByUrl(route);
    }
  }

  get noHistory(): boolean {
    return !(this.navigationBreadcrumb?.back || window?.history?.length > 1);
  }

  onBack() {
    this.navigationBreadcrumb && this.navigationBreadcrumb.back
      ? this.navigationBreadcrumb.back.handleBack()
      : this.handleBackClick();
  }

  handleBackClick() {
    this.location.back();
  }

  openHelp(helpcrumb: HelpCrumb) {
    if (helpcrumb?.link) {
      window.open(helpcrumb.link, '_blank');
      return;
    }
    this.matDialog.open<HelpVideoDialogComponent, HelpVideoDialogData>(
      HelpVideoDialogComponent,
      {
        data: {
          videoName: helpcrumb.id,
        },
      }
    );
  }

  ngOnInit() {
    this.elem = document.documentElement;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes) {
      if (
        changes.navigationBreadcrumb &&
        changes.navigationBreadcrumb.currentValue
      ) {
        this.navigationBreadcrumb = changes.navigationBreadcrumb.currentValue;
      }
    }
  }

  isFullScreen() {
    return (
      (window as any)?.fullScreen ||
      (window.innerWidth === screen.width &&
        window.innerHeight === screen.height)
    );
  }

  get isIpadChrome() {
    const isChrome = /CriOS/.test(navigator.userAgent);
    const isIpad = navigator.userAgent.match(/iPad/i) != null;
    return isChrome && isIpad;
  }

  toggleFullscreen() {
    if (this.isFullScreen()) {
      this.closeFullscreen();
    } else {
      this.openFullscreen();
    }
  }

  openFullscreen() {
    if (this.elem.requestFullscreen) {
      this.elem.requestFullscreen();
    } else if (this.elem.mozRequestFullScreen) {
      /* Firefox */
      this.elem.mozRequestFullScreen();
    } else if (this.elem.webkitRequestFullscreen) {
      /* Chrome, Safari and Opera */
      this.elem.webkitRequestFullscreen();
    } else if (this.elem.msRequestFullscreen) {
      /* IE/Edge */
      this.elem.msRequestFullscreen();
    }
  }
  /* Close fullscreen */
  closeFullscreen() {
    if (this.document.exitFullscreen) {
      this.document.exitFullscreen();
    } else if (this.document.mozCancelFullScreen) {
      /* Firefox */
      this.document.mozCancelFullScreen();
    } else if (this.document.webkitExitFullscreen) {
      /* Chrome, Safari and Opera */
      this.document.webkitExitFullscreen();
    } else if (this.document.msExitFullscreen) {
      /* IE/Edge */
      this.document.msExitFullscreen();
    }
  }

  logoUrl(): string | null {
    if (this.dynamicStyle.isCms) {
      return null;
    }
    return (
      this.state?.course?.course?.logo_url ||
      this.state?.liveEvent?.logo_url ||
      this.state?.exam?.exam?.logo_url ||
      null
    );
  }
}
