import { Component, Input, Output, EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { QuestionType } from 'src/app/app.types';
import { QuestionsOverview } from '../question-overview-list/question-overview-list.component';
import { AiQuestionEvaluation, ExamResultModel } from 'src/app/api/models';
import { DonutSection } from 'src/app/widget/donut-chart.component';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { ImportAiExplanationComponent } from '../../dialogs/import-ai-explanation/import-ai-explanation.component';
import { AuthService } from 'src/app/auth/auth.service';

@Component({
  selector: 'qa-question-overview-details',
  templateUrl: './question-overview-details.component.html',
  styleUrls: ['./question-overview-details.component.scss'],
})
export class QuestionOverviewDetailsComponent {
  @Input() loading: boolean;
  @Input() getQuestionResult;
  @Input() hideUnAnswered = false;
  @Input() currentItemIndex;
  @Input() question: QuestionsOverview;
  @Input() allQuestions: QuestionsOverview[];
  @Input() answerSections: DonutSection[];
  @Output() goBack = new EventEmitter<void>();
  @Output() selectScore = new EventEmitter<{
    submission: ExamResultModel;
    score: number;
  }>();
  @Output() setFeedback = new EventEmitter<{ submission: ExamResultModel }>();
  explanationOpen = false;
  questionType = QuestionType;
  selectedVisualization = 'list';

  constructor(
    private translator: TranslateService,
    private matDialog: MatDialog,
    private auth: AuthService,
    private router: Router
  ) {}

  get labelAnswers() {
    return this.translator.instant('exam.result.ungradedAnswers', {
      count: this.question.openQuestionAnsweredCount,
    });
  }

  get isExam() {
    return this.router.url.indexOf('cms/exams') !== -1;
  }

  get sampleAnswer(): string {
    return this.question.answers?.length
      ? this.question.answers[0].text
      : this.translator.instant('exam.result.noSampleAnswer');
  }

  translateTrueFalse(text): string {
    if (text === 'true' || text === true) {
      return this.translator.instant('question.form.correct');
    } else if (text === 'false' || text === false) {
      return this.translator.instant('question.form.inCorrect');
    } else {
      return text;
    }
  }

  getAnswerBarWidth(amount: number): string {
    const quotient = amount / this.question.total_result || 0;
    return `${Math.round(quotient * 100)}%`;
  }

  getCorrectRatio(stat: QuestionsOverview) {
    return stat && stat.total_result > 0
      ? `${((stat.num_correct_answers * 100) / stat.total_result).toFixed(2)}%`
      : '0%';
  }

  getRightResult(question: QuestionsOverview) {
    const total =
      question.num_correct_answers +
      question.num_incorrect_answer +
      question.num_unanswered;
    const quotient = question.num_correct_answers / total || 0;
    return `${Math.round(quotient * 100)}%`;
  }

  getWrongResult(question: QuestionsOverview) {
    const total =
      question.num_correct_answers +
      question.num_incorrect_answer +
      question.num_unanswered;
    const quotient = question.num_incorrect_answer / total || 0;
    return `${Math.round(quotient * 100)}%`;
  }

  onNavigate(questionIndex: number) {
    if (questionIndex < this.allQuestions?.length) {
      this.getQuestionResult({
        result: this.allQuestions[questionIndex],
        index: questionIndex,
      });
    }
  }

  scoreSteps(maxPoints: number) {
    return new Array(maxPoints + 1).fill(0).map((_, index) => {
      return index;
    });
  }

  goBackToResult() {
    this.goBack.emit();
  }

  selectScoreQuestion(score: { submission: ExamResultModel; score: number }) {
    if (score.submission.score === score.score) {
      return;
    }
    if (
      score.score === null &&
      (score.submission.score || score.submission.score === 0)
    ) {
      this.question.openQuestionAnsweredCount++;
    }
    if (
      (score.score || score.score === 0) &&
      !score.submission.score &&
      score.submission.score !== 0
    ) {
      this.question.openQuestionAnsweredCount--;
    }
    this.selectScore.emit(score);
  }

  mapToStrings(): string[] {
    const answers = this.question.answers;
    return answers?.length
      ? answers.reduce(
          (p, i) => [...p, ...new Array(i.num_selected).fill(i.text)],
          []
        )
      : [];
  }

  generateExplanation(submission) {
    const answer = this.question.answers?.length
      ? this.question.answers[0]
      : null;
    const data: AiQuestionEvaluation = {
      answer: submission.free_text_answer,
      expected_answer: answer ? answer.text : null,
      question: this.question.text,
    };
    this.matDialog
      .open<unknown, any>(ImportAiExplanationComponent, {
        data,
        width: '600px',
      })
      .afterClosed()
      .subscribe((r) => {
        if (r) {
          submission.addReply = true;
          submission.free_text_feedback = r;
        }
      });
  }

  get isAdmin() {
    return (
      this.auth.currentUserHasGroup('Admin') ||
      this.auth.currentUserHasGroup('ContentManager')
    );
  }
}
