import { Component, OnInit } from '@angular/core';
import {
  AnswerModel,
  BookModel,
  BookQuery,
  CategoryModel,
  CourseModel,
  FlashCardStackModel,
  QuizModel,
} from 'src/app/api/models';
import { BooksService } from 'src/app/api/services';
import { ListConfig, ListQuery } from './list.types';
import { Router } from '@angular/router';
import { DialogService } from '../common/dialog.service';
import { filter, switchMap } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { NavigationBreadcrumb } from '../common/navigation-breadcrumbs/navigation-breadcrumbs.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as _ from 'lodash';
import { CoursePanel } from '../panel/panels/course-panel';
import { FlashCardStackPanel } from '../panel/panels/flash-card-stack-panel';
import { QuizPanel } from '../panel/panels/quiz-panel';
import { EventsPanel } from '../panel/panels/events-panel';
import { PanelService } from '../panel/panel.service';
import { MatDialog } from '@angular/material/dialog';
import {
  HelpVideoDialogComponent,
  HelpVideoDialogData,
} from '../common/help-video-dialog.component';
import { multiSelectAction } from './grid-data/grid-data.component';
import { EditService } from '../edit/edit.service';
import { BookEditConfigService } from '../edit/configs/book.service';
import { ListDataSource } from './list-data-source';
import { PanelEntityType } from '../panel/panel.types';
import * as TurnDown from 'turndown';
import { QuestionType } from 'src/app/app.types';
import { isArray } from 'lodash';
import { ListInfo } from './no-entity-info/no-entity-info.component';
import { SubscriptionLimit } from '../common/dialogs/subscription-limit-warning/subscription-limit-warning.component';

@Component({
  selector: 'qa-book-list',
  templateUrl: './book-list.component.html',
  styleUrls: ['./book-list.component.scss'],
})
export class BookListComponent implements OnInit {
  config: ListConfig<BookModel>;
  isBookAvailable = false;
  loading = false;
  listInfo: ListInfo = {
    videoUrl: 'https://www.youtube-nocookie.com/embed/82SjD_In68Q',
    learnMoreText: 'book.noBookEntity.learnMoreAtBookAcademy',
    content: 'book.noBookEntity.bookContent',
    links: [
      {
        label: 'book.noBookEntity.bookLinks.link1',
        url: 'https://quizacademy.de/akademie/kurse/#Einsatzm%C3%B6glichkeiten',
      },
      {
        label: 'book.noBookEntity.bookLinks.link2',
        url: 'https://quizacademy.de/akademie/kurse/#Kurs+erstellen',
      },
      {
        label: 'book.noBookEntity.bookLinks.link3',
        url: 'https://quizacademy.de/akademie/kurse/#Kurse+hinzuf%C3%BCgen',
      },
      {
        label: 'book.noBookEntity.bookLinks.link4',
        url: 'https://quizacademy.de/akademie/kurse/#Kurs+freigeben',
      },
      {
        label: 'book.noBookEntity.bookLinks.link5',
        url: 'https://quizacademy.de/akademie/kurse/',
      },
    ],
  };

  navigationBreadcrumb: NavigationBreadcrumb = {
    homeUrl: '/cms/dashboard',
    help: [{ id: 'teaser' }],
    currentPage: null,
    breadcrumbs: [],
  };

  constructor(
    private books: BooksService,
    private router: Router,
    private dialog: DialogService,
    private translator: TranslateService,
    private snackBar: MatSnackBar,
    private coursePanel: CoursePanel,
    private panelService: PanelService,
    private flashCardStackPanel: FlashCardStackPanel,
    private quizPanel: QuizPanel,
    private eventsPanel: EventsPanel,
    private matDialog: MatDialog,
    private editService: EditService
  ) {}

  ngOnInit() {
    this.config = getBookListConfig(
      this.books,
      this.router,
      this.dialog,
      this.translator,
      this.snackBar,
      true,
      this.matDialog,
      this.editService,
      this.panelService
    );

    this.initPanels();
    this.getBookList();
  }

  initPanels() {
    this.coursePanel.isBook = true;
    this.quizPanel.isBook = true;
    this.flashCardStackPanel.isBook = true;
    this.eventsPanel.isBook = true;
    const panels = [
      this.coursePanel,
      this.quizPanel,
      this.flashCardStackPanel,
      this.eventsPanel,
    ];
    this.panelService.init(panels);
    this.coursePanel.initializePanel();
    this.quizPanel.initializePanel();
    this.eventsPanel.initializePanel();
    this.flashCardStackPanel.initializePanel();
  }

  getBookList() {
    this.loading = true;
    const query = {
      filters: {},
      paging: {
        page_size: 25,
      },
      sorting: [
        {
          option: 'id',
          direction: 'DESC',
        },
      ],
    } as unknown as BookQuery;
    this.books.queryBooks({ body: query }).subscribe((response) => {
      this.isBookAvailable = !!response.data.length;
      this.loading = false;
    });
  }

  async createBook() {
    const course = await this.editService.open(BookEditConfigService);
    this.router.navigateByUrl(`/cms/courses/edit/${course.id}`);
  }

  openHelp(skipIfAlreadyShown?: boolean) {
    this.matDialog.open<HelpVideoDialogComponent, HelpVideoDialogData>(
      HelpVideoDialogComponent,
      {
        data: {
          skipIfAlreadyShown,
          videoName: 'bookTutorial',
        },
      }
    );
  }
}

export function getBookListConfig(
  books: BooksService,
  router: Router,
  dialog: DialogService,
  translator: TranslateService,
  snackBar: MatSnackBar,
  showMultiselect: boolean,
  matDialog: MatDialog,
  editService: EditService,
  panelService: PanelService
): ListConfig<BookModel> {
  function importFromJson(
    panelService: PanelService,
    meta: ListDataSource,
    entity?: BookModel
  ) {
    const panel = panelService.getPanel(PanelEntityType.Course);
    panelService.importItemAsJSON(panel, entity, meta);
  }

  async function importCourseFromRepiticoJSON(
    matSnackBar: MatSnackBar,
    panelService: PanelService,
    translator: TranslateService,
    meta: ListDataSource,
    importFn: any
  ) {
    const contents = (await panelService.promptFile('json')) as any[];
    matSnackBar.open(translator.instant('content.message.preparingImport'));
    const turndown = new TurnDown['default']();
    const quiz = {
      name: translator.instant('content.message.queFromRepitico'),
    } as Partial<QuizModel> as QuizModel;
    const flashCardStack = {
      name: translator.instant('content.message.flashCardFromRepitico'),
    } as Partial<FlashCardStackModel> as FlashCardStackModel;
    const course: Partial<CourseModel> = {
      name: translator.instant('content.message.courseFromRepitico'),
      category: { id: 1 } as Partial<CategoryModel> as CategoryModel,
      card_stacks: [flashCardStack],
      quizzes: [quiz],
    };
    const trueRegex = /^(richtig|ja)\.?$/i;
    const falseRegex = /^(falsch|nein)\.?$/i;
    quiz.questions =
      contents && _.isArray(contents)
        ? contents
            .filter((content) => Boolean(content.mchoice))
            .map((repiticoQuestion) => {
              const explanation =
                turndown.turndown(repiticoQuestion.answer) +
                '\n\n' +
                translator.instant('common.label.categories') +
                ':\n' +
                repiticoQuestion.categories.join('\n');
              const answers: AnswerModel[] = (
                repiticoQuestion.mchoice as any[]
              ).map((answer) => {
                return {
                  text: turndown.turndown(answer.answer),
                  is_right: Boolean(answer.correct),
                } as Partial<AnswerModel> as AnswerModel;
              });
              const isTrueFalse =
                answers.length === 2 &&
                ((trueRegex.test(answers[0].text) &&
                  falseRegex.test(answers[1].text)) ||
                  (trueRegex.test(answers[1].text) &&
                    falseRegex.test(answers[0].text)));
              return {
                text: turndown.turndown(repiticoQuestion.question),
                explanation,
                type: isTrueFalse
                  ? QuestionType.TRUE_FALSE
                  : QuestionType.MULTIPLE_CHOICE,
                answers: isTrueFalse ? null : answers,
                is_right: isTrueFalse
                  ? trueRegex.test(answers[0].text)
                    ? answers[0].is_right
                    : !answers[0].is_right
                  : null,
              };
            })
        : [];
    flashCardStack.cards =
      contents && isArray(contents) && contents.length
        ? contents
            .filter((content) => !content.mchoice)
            .map((repiticoCard) => {
              return {
                text: turndown.turndown(repiticoCard.question),
                answer: turndown.turndown(repiticoCard.answer),
                explanation:
                  translator.instant('common.label.categories') +
                  ':\n' +
                  repiticoCard.categories.join('\n'),
              };
            })
        : [];
    importFn(panelService, meta, course);
  }

  const ret: ListConfig<BookModel> = {
    actions: [
      {
        icon: 'play_circle',
        name: 'edit',
        click: (meta) => {
          router.navigateByUrl(`/cms/books/edit/${meta.row.id}`);
        },
        tooltip: 'common.tooltip.edit',
        type: 'button',
      },
      {
        click: ({ row, dataSource }) => {
          dialog
            .confirmDelete()
            .pipe(
              filter((isConfirmed) => isConfirmed),
              switchMap(() => books.deleteBooks({ id: row.id }))
            )
            .subscribe({
              next: () => {
                snackBar.open(
                  translator.instant('content.book.deleteItem.success')
                );
                dataSource.refresh();
              },
              error: () => {
                snackBar.open(
                  translator.instant('content.book.deleteItem.error')
                );
              },
            });
        },
        icon: 'delete',
        tooltip: 'common.button.clear',
        name: 'delete',
        type: 'button',
      },
    ],
    actionsMenu: [],
    activeActionNames: ['edit', 'delete'],
    activeColumnNames: ['id', 'name'],
    columns: [
      {
        label: 'ID',
        name: 'id',
        sortable: true,
        width: '64px',
        filter: (value) => {
          return {
            id: Number(value),
          };
        },
      },
      {
        label: 'course.table.name',
        click: (meta) => {
          router.navigateByUrl(`/cms/books/edit/${meta.row.id}`);
        },
        name: 'name',
        sortable: true,
        primary: true,
        filter: (value) => {
          return {
            name: value,
          };
        },
      },
    ],
    showSubscriptionIndicator: true,
    subscriptionLimit: SubscriptionLimit.BOOK_COUNT,
    imports: [
      {
        title: translator.instant('content.book.addMenu.item2'),
        description: translator.instant('content.book.description.item2'),
        action: {
          icon: 'code',
          click: (dataSource) => importFromJson(panelService, dataSource, null),
          text: translator.instant('content.book.addMenu.item2'),
        },
      },
      {
        title: translator.instant('content.book.addMenu.item3'),
        description: translator.instant('content.book.description.item3'),
        action: {
          icon: 'code',
          click: (dataSource) =>
            importCourseFromRepiticoJSON(
              snackBar,
              panelService,
              translator,
              dataSource,
              importFromJson
            ),
          text: translator.instant('content.book.addMenu.item3'),
        },
      },
      {
        title: translator.instant('content.book.addMenu.item5'),
        description: translator.instant('content.book.description.item5'),
        action: {
          text: translator.instant('content.book.addMenu.extra'),
          icon: 'keyboard_arrow_right',
          click: () => {
            window.open(
              'https://quizacademy.de/preise/anfrage-content/',
              '_blank'
            );
          },
        },
      },
    ],
    createButton: {
      options: [
        {
          text: translator.instant('common.button.createNew'),
          icon: 'add',
          click: async () => {
            const course = await editService.open(BookEditConfigService);
            if (course) {
              router.navigateByUrl(`/cms/books/edit/${course.id}`);
            }
          },
        },
      ],
    },

    fetch: (queryParams?: ListQuery<BookModel>) => {
      const query = queryParams as unknown as BookQuery;
      return books.queryBooks({ body: query });
    },
    query: {
      initial: {
        sorting: [
          {
            option: 'id',
            direction: 'DESC',
          },
        ],
      },
      searchColumnName: 'name',
    },
    name: 'bookList',
    title: 'book.title',
    tutorial: 'content',
    tutorialLabel: 'dashboardNew.book.video',
    extraTutorialLabel: 'dashboardNew.book.academicButton',
    showChip: true,
  };

  if (showMultiselect) {
    ret.multiselectActions = [
      {
        icon: 'delete',
        name: 'question.tooltip.delete',
        type: 'button',
        click: (meta) => {
          return new Promise((resolve) => {
            dialog
              .confirmDelete(
                null,
                translator.instant('common.message.deleteMulti', {
                  count: meta.rows.length,
                })
              )
              .pipe(filter((isConfirmed) => isConfirmed))
              .subscribe(async () => {
                await multiSelectAction(
                  meta.rows,
                  async (item: any) => {
                    await books.deleteBooks({ id: item.id }).toPromise();
                    return true;
                  },
                  `${translator.instant(
                    'common.label.deleting'
                  )} ${translator.instant('book.title')}`,
                  matDialog
                );
                meta.dataSource.refresh();
                resolve();
              });
          });
        },
      },
    ];
  }

  return ret;
}
