import { Directive, Input, ElementRef, AfterViewInit } from '@angular/core';
import * as changeCase from 'change-case';

@Directive({
  selector: '[qaEnterAnimation]',
})
export class EnterAnimationDirective implements AfterViewInit {
  @Input()
  delay = 0;

  @Input()
  direction = 'none';

  @Input()
  mode = 'fadeIn';

  constructor(private el: ElementRef) {}

  ngAfterViewInit() {
    this.applyStyle('transition', '', 'all .2s ease-out', 1);

    switch (this.mode) {
      case 'popIn':
        this.applyStyle('clipPath', 'circle(0%)', 'circle(100%)');
        this.applyStyle('opacity', '0', '1');
        break;

      default:
        this.applyStyle('position', '', 'relative', 0);
        this.applyStyle('opacity', '0', '1');
        if (this.direction === 'up') {
          this.applyStyle(
            'bottom',
            `${this.getPixelsAsNumber('bottom') - 16}px`,
            `${this.getPixelsAsNumber('bottom')}px`
          );
        } else if (this.direction === 'down') {
          this.applyStyle(
            'top',
            `${this.getPixelsAsNumber('top') - 16}px`,
            `${this.getPixelsAsNumber('top')}px`
          );
        }
        break;
    }
  }

  getPixelsAsNumber(propertyName: string) {
    return +this.getStyleValue(propertyName).replace('px', '') || 0;
  }

  getStyleValue(propertyName: string) {
    return window
      .getComputedStyle(this.el.nativeElement)
      .getPropertyValue(changeCase.paramCase(propertyName));
  }

  applyStyle(
    propertyName: string,
    initialValue: string,
    doneValue: string,
    delay = this.delay
  ) {
    this.el.nativeElement.style[propertyName] = initialValue;
    setTimeout(() => {
      this.el.nativeElement.style[propertyName] = doneValue;
    }, delay);
  }
}
