import {
  animate,
  keyframes,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { HttpErrorResponse } from '@angular/common/http';
import {
  AfterViewInit,
  Component,
  ElementRef,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { Router } from '@angular/router';
import { fromEvent, merge, Observable, of, throwError } from 'rxjs';
import { catchError, mapTo } from 'rxjs/operators';
import { PublicService } from 'src/app/api/services';
import { editionPrefix } from './app.component';

@Component({
  selector: 'qa-pin-input',
  templateUrl: './pin-input.component.html',
  styleUrls: ['./pin-input.component.scss'],
  animations: [
    trigger('invalidPinAnimation', [
      transition('false => true', [
        animate(
          '800ms ease-out',
          keyframes([
            style({ transform: 'translateX(-32px)' }),
            style({ transform: 'translateX(32px)' }),
            style({ transform: 'translateX(-24px)' }),
            style({ transform: 'translateX(24px)' }),
            style({ transform: 'translateX(-16px)' }),
            style({ transform: 'translateX(16px)' }),
            style({ transform: 'translateX(0)' }),
          ])
        ),
      ]),
    ]),
  ],
})
export class PinInputComponent implements AfterViewInit {
  @ViewChildren('input')
  inputs: QueryList<ElementRef<HTMLInputElement>>;

  pinRegex = /[SUBsub]\-[CBLEcble][a-zA-Z]{3,5}/;
  pinRegexLegacy = /[CBLEcble][a-zA-Z]{3,5}/;

  allowedCharacters = /[a-zA-Z0-9]/;
  showInvalidPinMessage = false;

  editionPrefix = editionPrefix();

  constructor(private router: Router, private api: PublicService) {}

  ngAfterViewInit() {
    const pasteEvents = this.inputs.map((input) => {
      const el = input.nativeElement;
      return fromEvent<ClipboardEvent>(el, 'paste');
    });
    merge(...pasteEvents).subscribe((event) => {
      event.preventDefault();
      const pastedText =
        event && event?.clipboardData
          ? event.clipboardData.getData('text/plain')
          : null;
      this.inputs.forEach((input, index) => {
        if (pastedText) {
          input.nativeElement.value = pastedText[index];
        }
      });
      if (pastedText && pastedText.length >= 6) {
        this.submit();
      }
    });
  }

  keyPress(event: KeyboardEvent) {
    if (!this.allowedCharacters.test(event.key)) {
      event.preventDefault();
    }
  }

  keyUp(index: number, event: KeyboardEvent) {
    if (!this.allowedCharacters.test(event.key)) {
      return;
    }
    let nextIndex: number;
    if (event.key === 'Backspace') {
      nextIndex = index - 1;
      if (nextIndex < 0) {
        return;
      }
    } else {
      nextIndex = index + 1;
      const input = this.inputs.toArray()[index].nativeElement;
      if (!input.value) {
        return;
      }
      if (nextIndex > 5) {
        this.submit();
        return;
      }
    }
    const nextInput = this.inputs.toArray()[nextIndex].nativeElement;
    this.showInvalidPinMessage = false;
    nextInput.focus();
    nextInput.select();
  }

  async redirectToPin() {
    var pin = this.inputs.reduce(
      (all, input) => all + input.nativeElement.value,
      ''
    );
    if (!this.pinRegex.test(pin) && !this.pinRegexLegacy.test(pin)) {
      throw new Error('Invalid PIN: Invalid format');
    }
    // remove edition prefix
    if (pin.includes('-')) {
      pin = pin.substring(2);
    }

    const firstChar = pin[0].toLowerCase();
    let getEntity$: Observable<any>;
    switch (firstChar) {
      case 'c':
        getEntity$ = this.api.getPublicCourseByPin({ pin });
        break;
      case 'b':
        getEntity$ = this.api.getPublicBookByPin({ pin });
        break;
      case 'e':
        getEntity$ = this.api.getPublicExamByPin({ pin });
        break;
      case 'l':
        getEntity$ = this.api.getPublicLiveEventByPin({ pin });
        break;
      default:
        throw new Error('Invalid PIN: Unsupported first letter');
    }
    const entityExists = await getEntity$
      .pipe(
        mapTo(true),
        catchError((error) => {
          if (error instanceof HttpErrorResponse) {
            if (error.status === 403) {
              return of(true);
            } else {
              return of(false);
            }
          }
          return throwError(error);
        })
      )
      .toPromise();
    if (!entityExists) throw new Error("Entity doesn't exist");
    switch (firstChar) {
      case 'c':
        this.router.navigateByUrl(`/course/${pin}`);
        break;
      case 'b':
        this.router.navigateByUrl(`/book/${pin}`);
        break;
      case 'e':
        this.router.navigateByUrl(`/exam/${pin}`);
        break;
      case 'l':
        this.router.navigateByUrl(`/live-events/${pin}`);
        break;
      default:
        throw new Error('Invalid PIN: Unsupported first letter');
    }
  }

  async submit() {
    this.inputs.forEach((input) => {
      input.nativeElement.disabled = true;
    });
    try {
      await this.redirectToPin();
    } catch (error) {
      console.error(error);
      this.showInvalidPinMessage = true;
      this.inputs.forEach((input) => {
        input.nativeElement.value = '';
        input.nativeElement.disabled = false;
      });
      this.inputs.first.nativeElement.focus();
    }
  }
}
