import {
  registerLocaleData,
  CommonModule,
  LOCATION_INITIALIZED,
} from '@angular/common';
import {
  HTTP_INTERCEPTORS,
  HttpClientModule,
  HttpClient,
} from '@angular/common/http';
import localeDe from '@angular/common/locales/de';
import {
  LOCALE_ID,
  NgModule,
  APP_INITIALIZER,
  Injector,
  ErrorHandler,
} from '@angular/core';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import {
  MAT_FORM_FIELD_DEFAULT_OPTIONS,
  MatFormFieldModule,
} from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import {
  MatSnackBarConfig,
  MAT_SNACK_BAR_DEFAULT_OPTIONS,
  MatSnackBarModule,
} from '@angular/material/snack-bar';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import * as Sentry from '@sentry/browser';
import { ApiModule } from 'src/app/api/api.module';
import { ApiKeyInterceptor } from 'src/app/api-key.interceptor';
import { environment } from 'src/environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthModule } from './auth/auth.module';
import { AuthInterceptor } from './auth/auth.interceptor';
import { HomeComponent } from './home.component';
import { PinInputComponent } from './pin-input.component';
import { SidebarModule } from './sidebar/sidebar.module';
import { ReactiveFormsModule } from '@angular/forms';
import { SentryErrorHandler } from './sentry.error-handler';
import { EditModule } from './cms/edit/edit.module';
import {
  TranslateModule,
  TranslateLoader,
  TranslateService,
} from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ShortenerComponent } from './shortener.component';
import { SharedModule } from './shared/shared.module';
import { IosInfoDialogComponent } from './ios-info-dialog/ios-info-dialog.component';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

export function appInitializerFactory(
  translate: TranslateService,
  injector: Injector
) {
  return () =>
    new Promise<any>((resolve: any) => {
      const locationInitialized = injector.get(
        LOCATION_INITIALIZED,
        Promise.resolve(null)
      );
      locationInitialized.then(() => {
        let langToSet = translate.getBrowserLang();
        translate.setDefaultLang('en');
        try {
          if (localStorage.getItem('current-language')) {
            langToSet = localStorage.getItem('current-language');
          }
        } catch (err) {}
        if (!(langToSet === 'de' || langToSet === 'en')) {
          langToSet = 'en';
        }
        translate.use(langToSet).subscribe(
          () => {
            console.info(`Successfully initialized '${langToSet}' language.'`);
          },
          () => {
            console.info(
              `Problem with '${langToSet}' language initialization.`
            );
            resolve(null);
          },
          () => {
            resolve(null);
          }
        );
      });
    });
}

if (environment.sentry.dsn && environment.production) {
  Sentry.init({
    dsn: environment.sentry.dsn,
    defaultIntegrations: false,
    integrations: Sentry.getDefaultIntegrations({}),
  });
}

registerLocaleData(localeDe, 'de');

@NgModule({
  bootstrap: [AppComponent],
  declarations: [
    AppComponent,
    HomeComponent,
    ShortenerComponent,
    PinInputComponent,
    IosInfoDialogComponent,
  ],
  providers: [
    {
      provide: ErrorHandler,
      useClass: SentryErrorHandler,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiKeyInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: LOCALE_ID,
      useValue: 'de',
    },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: { appearance: 'fill' },
    },
    {
      provide: MAT_SNACK_BAR_DEFAULT_OPTIONS,
      useValue: { duration: 5000 } as MatSnackBarConfig,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFactory,
      deps: [TranslateService, Injector],
      multi: true,
    },
  ],
  imports: [
    ApiModule.forRoot({ rootUrl: environment.api.url }),
    AppRoutingModule,
    AuthModule,
    BrowserAnimationsModule,
    BrowserModule,
    CommonModule,
    EditModule,
    SharedModule,
    EffectsModule.forRoot([]),
    HttpClientModule,
    MatBadgeModule,
    MatButtonModule,
    MatDividerModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatSnackBarModule,
    ReactiveFormsModule,
    SidebarModule,
    StoreModule.forRoot({}),
    StoreDevtoolsModule.instrument({
      logOnly: environment.production,
    }) /* Needs to be imported AFTER StoreModule */,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
  ],
})
export class AppModule {}
