import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UntypedFormControl, Validators } from '@angular/forms';
import { AiService } from 'src/app/api/services';
import {
  AiQuestionEvaluation,
  AiQuestionEvaluationResult,
} from 'src/app/api/models';

@Component({
  selector: 'qa-import-ai-explanation',
  templateUrl: './import-ai-explanation.component.html',
  styleUrls: ['./import-ai-explanation.component.scss'],
})
export class ImportAiExplanationComponent implements OnInit {
  explanation = new UntypedFormControl(null, Validators.required);
  generatedQuestionResult: AiQuestionEvaluationResult;
  loading = true;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: AiQuestionEvaluation,
    public dialogRef: MatDialogRef<AiQuestionEvaluation>,
    private aiService: AiService
  ) {}

  ngOnInit(): void {
    this.generateAIQuestions();
  }

  generateAIQuestions() {
    this.aiService
      .evaluateQuestion({
        body: this.data,
      })
      .subscribe((data) => {
        this.generatedQuestionResult = data;
        this.explanation.setValue(this.generatedQuestionResult.feedback);
        this.loading = false;
      });
  }

  importAIQuestions() {
    this.dialogRef.close(this.explanation.value);
  }
}
