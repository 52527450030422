<h1 class="mat-subtitle-1">
  {{ 'common.label.usage' | translate }}
</h1>
<div [class.faded]="loading" class="chart-container">
  <qa-chart
    [data]="chartData"
    [options]="chartOptions"
    *ngIf="chartData"
    chartType="bar"
  >
  </qa-chart>
</div>
