import {
  Component,
  Input,
  ElementRef,
  OnChanges,
  Output,
  EventEmitter,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
import { DonutSection } from './donut-chart.component';

@Component({
  selector: 'qa-correct-donut-chart',
  templateUrl: './correct-donut-chart.component.html',
  styleUrls: ['./correct-donut-chart.component.scss'],
})
export class CorrectDonutChartComponent implements OnChanges {
  @Input() correctAmount: number;
  @Input() incorrectAmount: number;
  @Input() questionsAmount: number;
  @Input() hideNoAnswer: boolean;
  @Input() enableWithoutAnswerClick?: boolean;
  @Input() correctColor: string = environment.colors.primary;

  @Input() onlyHandedIn?: boolean;

  @Output() withoutAnswerClick = new EventEmitter<void>();

  sections: DonutSection[] = [];

  constructor(
    elementRef: ElementRef<HTMLElement>,
    private translator: TranslateService
  ) {
    elementRef.nativeElement.classList.add('qa-correct-donut-chart');
  }

  ngOnChanges() {
    const correctedQuestionAmount = Math.max(this.questionsAmount, 1);
    const unanswered =
      correctedQuestionAmount - (this.correctAmount + this.incorrectAmount);
    const rightLabel = this.onlyHandedIn
      ? this.translator.instant('chart.label.handedIn')
      : this.translator.instant('chart.label.correctAns');
    this.sections = [
      {
        label: `${this.correctAmount} ${rightLabel}`,
        value: this.correctAmount,
        color: this.correctColor,
        shortLabel: this.translator.instant('chart.label.correct'),
        featured: true,
      },
      {
        label: `${this.incorrectAmount} ${this.translator.instant(
          'chart.label.inCorrectAns'
        )}`,
        value: this.incorrectAmount,
        color: environment.colors.warn,
      },
      {
        label: `${unanswered} ${this.translator.instant(
          'chart.label.withoutAns'
        )}`,
        value: unanswered,
        color: '#e4e4e4',
        click: this.enableWithoutAnswerClick
          ? () => {
              this.withoutAnswerClick.emit();
            }
          : undefined,
      },
    ];
    if (this.onlyHandedIn) {
      this.sections.splice(0, 2);
      this.sections.unshift({
        label: `${
          this.correctAmount + this.incorrectAmount
        } ${this.translator.instant('exam.result.submitted')}`,
        shortLabel: this.translator.instant('exam.result.submitted'),
        value: this.correctAmount + this.incorrectAmount,
        color: this.correctColor,
        featured: true,
      });
    }
    if (this.hideNoAnswer) {
      this.sections.splice(0, 1);
    }
  }
}
