<div class="share-contents desktop">
  <ng-scrollbar>
    <ng-container *ngTemplateOutlet="shareContent"></ng-container>
  </ng-scrollbar>
</div>
<div class="share-contents mobile">
  <ng-container *ngTemplateOutlet="shareContent"></ng-container>
</div>

<ng-template #shareContent>
  <div class="shareinfo-container">
    <div class="info-container-wrapper">
      <div class="share-info">
        {{ 'liveEvent.setup.label.share' | translate }}
      </div>
      <div class="qr-code-container">
        <div [innerHTML]="qrSvg" class="qr-code"></div>
        <div class="qr-actions">
          <div class="zoom_in" (click)="copyWebLink()">
            <mat-icon>content_copy</mat-icon>
          </div>
          <div class="zoom_in" (click)="openQrModel()">
            <mat-icon>zoom_out_map</mat-icon>
          </div>
        </div>
      </div>
      <div>
        <div class="pin-widget">
          <div class="link-part">{{ shortLinkPrefix }}</div>
          <div class="pin">
            <div class="input-container flex-row">
              <div class="pin-char-prefix">
                {{ editionPrefix }}
              </div>
              <span class="pin-char-hyphen"> - </span>
              <div class="pin-char" *ngFor="let char of pinCode?.split('')">
                {{ char }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="share flex-column">
      <div class="flex-row links">
        <mat-icon>content_copy</mat-icon>
        <span (click)="copyWebLink()">{{
          'course.form.copyLable' | translate
        }}</span>
      </div>
      <div class="flex-row links" *ngIf="supportsClipboard">
        <mat-icon>qr_code_2</mat-icon>
        <span (click)="copyQR()">{{ 'course.form.copyQR' | translate }}</span>
      </div>
      <div class="flex-row links open-new" *ngIf="openInNewTabText">
        <mat-icon>open_in_new</mat-icon>
        <span>
          <a
            style="padding: 0"
            mat-button
            [href]="asShortLink(pinCode)"
            target="_blank"
          >
            {{ openInNewTabText | translate }}
          </a>
        </span>
      </div>
      <div class="flex-row links">
        <mat-icon>email</mat-icon>
        <span (click)="inviteAction.emit()">{{
          'liveEvent.setup.label.invitationViaMail' | translate
        }}</span>
      </div>
      <div class="flex-row links" *ngIf="canDownLoadFlyer">
        <mat-icon>download</mat-icon>
        <span *ngIf="!generatingFlyer" (click)="generateFlyer.emit()">{{
          'exam.setup.examFlyer' | translate
        }}</span>
        <span *ngIf="generatingFlyer">{{
          'exam.setup.generating' | translate
        }}</span>
      </div>
      <div class="flex-row links" *ngIf="embedDialogData">
        <mat-icon>code</mat-icon>
        <span (click)="embedCourse()">{{
          'content.course.actionMenu.item2' | translate
        }}</span>
      </div>
      <div class="tutorial" *ngIf="helpVideoDialogData">
        <button class="btn-common-style" mat-button (click)="openVideo()">
          <mat-icon>play_circle</mat-icon>
          {{ 'liveEvents.setup.label.accessLearners' | translate }}
        </button>
      </div>
    </div>
  </div>
</ng-template>
