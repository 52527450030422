import { DataSource } from '@angular/cdk/table';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { cloneDeep, debounce, isNil, merge, omitBy } from 'lodash';
import {
  BehaviorSubject,
  Observable,
  of,
  Subscription,
  throwError,
} from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { ErrorResponse } from 'src/app/app.types';
import { BaseQueryResponse, SortOption } from 'src/app/api/models';
import { ListQuery, DEFAULT_PAGE_SIZE, ListResponse } from './list.types';
import { MatSnackBar } from '@angular/material/snack-bar';

export class ListDataSource implements DataSource<{}> {
  filter = new BehaviorSubject<any>({});

  loading = true;

  length: number;

  refresh: Function = debounce(
    (hardRefresh) => this._refresh(hardRefresh),
    200
  );

  private data = new BehaviorSubject<Array<{}>>([]);
  private localData: Array<{}>;

  private paginatorSubscription: Subscription;
  private sortSubscription: Subscription;

  private get query() {
    const cachedQuery =
      JSON.parse(sessionStorage.getItem(this.sessionStorageKey)) || {};
    // Session storage migration
    if (cachedQuery && cachedQuery.paging && cachedQuery.paging.pageSize) {
      cachedQuery.paging.page_size = cachedQuery.paging.pageSize;
      delete cachedQuery.paging.pageSize;
    }
    return cachedQuery;
  }
  private set query(query: ListQuery<{}>) {
    let q = query;
    q = merge(
      { paging: { page_size: DEFAULT_PAGE_SIZE } },
      this.queryInfo.initial,
      q
    );
    q = omitBy(q, isNil);

    sessionStorage.setItem(this.sessionStorageKey, JSON.stringify(q));
    this.refresh();
  }

  private get currentSearchValue() {
    if (
      !this.query ||
      !this.query.filters ||
      !this.queryInfo.searchColumnName
    ) {
      return null;
    }
    return this.query.filters[this.queryInfo.searchColumnName];
  }

  constructor(
    private fetch: (
      query: ListQuery<{}>
    ) => Observable<BaseQueryResponse | ListResponse<{}> | Array<{}>>,
    private queryInfo: {
      initial?: ListQuery<{}>;
      isLocal?: boolean;
      searchColumnName: string;
    },
    private sessionStorageKey: string,
    private paginator: MatPaginator,
    private sort: MatSort,
    private matSnackBar: MatSnackBar
  ) {}

  get empty(): boolean {
    return (
      (this.data && this.data.value && this.data.value.length <= 0) ?? true
    );
  }

  connect(): Observable<Array<{}>> {
    if (this.query.filters) {
      const filters = this.query.filters;
      setTimeout(() => {
        this.filter.next(filters);
      }, 0);
    }

    this.data.subscribe({
      next: (data) => (this.length = data ? data.length : 0),
    });

    this.paginatorSubscription = this.paginator.page.asObservable().subscribe({
      next: (event: PageEvent) => {
        const sesStorage = JSON.parse(
          sessionStorage.getItem(this.sessionStorageKey)
        );
        if (sesStorage.paging.page_size !== event.pageSize) {
          delete sesStorage.list;
          delete sesStorage.pagination_token;
          this.paginator.pageIndex = 0;
          sessionStorage.setItem(
            this.sessionStorageKey,
            JSON.stringify(sesStorage)
          );
        }
        if (sesStorage.pagination_token) {
          if (sesStorage.list) {
            if (event.pageIndex < sesStorage.list.length) {
              this.data.next(sesStorage.list[event.pageIndex]);
              return;
            }
          }
        }
        this.patchQuery({
          paging: {
            page: event.pageIndex + 1,
            page_size: event.pageSize,
          },
        });
      },
    });

    this.sortSubscription = this.sort.sortChange.subscribe({
      next: (event: Sort) => {
        if (event.direction) {
          this.patchQuery({
            sorting: [
              {
                option: event.active,
                direction: event.direction === 'asc' ? 'ASC' : 'DESC',
              },
            ],
          });
        } else {
          const q = this.query;
          delete q.sorting;
          this.query = q;
        }
      },
    });

    this.filter.asObservable().subscribe({
      next: (filters: any) => {
        if (!filters) {
          return;
        }
        const sesStorage = JSON.parse(
          sessionStorage.getItem(this.sessionStorageKey)
        );
        if (sesStorage?.filters) {
          delete sesStorage.filters;
        }
        sessionStorage.setItem(
          this.sessionStorageKey,
          JSON.stringify(sesStorage)
        );
        if (sesStorage?.pagination_token || sessionStorage?.list) {
          delete sesStorage.pagination_token;
          delete sesStorage.list;
          this.paginator.pageIndex = 0;
          sessionStorage.setItem(
            this.sessionStorageKey,
            JSON.stringify(sesStorage)
          );
        }
        this.patchQuery({
          filters,
        });
      },
    });

    this.query = this.query; // Load query from sessionStorage

    if (this.query.paging) {
      if (this.query.paging.page_size) {
        this.paginator.pageSize = this.query.paging.page_size;
      }
      if (this.query.paging.page) {
        this.paginator.pageIndex = this.query.paging.page - 1;
      }
    }
    if (this.query.sorting && this.query.sorting.length) {
      this.sort.sort({
        id: this.query.sorting[0].option,
        start: this.query.sorting[0].direction === 'ASC' ? 'asc' : 'desc',
        disableClear: null,
      });
    }

    this.refresh();

    return this.data.asObservable();
  }

  disconnect(): void {
    this.data.complete();
    this.paginatorSubscription.unsubscribe();
    this.sortSubscription.unsubscribe();
  }

  private _refresh(hardRefresh = false) {
    this.loading = true;
    if (!hardRefresh && this.localData && this.localData.length) {
      this.applyLocalQuery();
      return;
    }
    const sesStorage = JSON.parse(
      sessionStorage.getItem(this.sessionStorageKey)
    );
    if (sesStorage && sesStorage?.pagination_token) {
      if (sesStorage.list) {
        if (sesStorage.list[this.paginator.pageIndex]) {
          this.loading = false;
          this.data.next(sesStorage.list[this.paginator.pageIndex]);
          return;
        }
      }
    }
    this.fetch(this.queryInfo.isLocal ? undefined : this.query)
      .pipe(
        switchMap((response) => {
          if (
            (response as unknown as { statusCode: number }).statusCode === 400
          ) {
            return throwError(response);
          }
          return of(response);
        }),
        catchError((errorResponse: ErrorResponse) => {
          this.matSnackBar.open(errorResponse.message);
          this.loading = false;
          return throwError(errorResponse);
        })
      )
      .subscribe({
        next: (response: ListResponse | Array<{}>) => {
          if (this.queryInfo.isLocal) {
            response = response as Array<{}>;
            this.localData = this.query.sorting
              ? this.sortData(response, this.query.sorting[0])
              : response;
            this.applyLocalQuery();
          } else {
            response = response as ListResponse;
            const sesStorage = JSON.parse(
              sessionStorage.getItem(this.sessionStorageKey)
            );
            if (response?.pagination_token) {
              response.pagination.total = 3000;
              sesStorage.pagination_token = response.pagination_token;
              if (sesStorage.list) {
                sesStorage.list.push(response.data);
              } else {
                sesStorage.list = [];
                sesStorage.list.push(response.data);
              }
              sessionStorage.setItem(
                this.sessionStorageKey,
                JSON.stringify(sesStorage)
              );
            }
            if (sesStorage?.pagination_token) {
              response.pagination.total = 3000;
            }
            if (response && response.pagination) {
              this.paginator.length = response.pagination.total;
            }
            this.loading = false;
            this.data.next(response.data);
          }
        },
      });
  }

  private applyLocalQuery() {
    let queriedData: Array<{}> = cloneDeep(this.localData);

    this.paginator.length = queriedData.length;

    if (this.currentSearchValue) {
      queriedData = queriedData.filter((row: {}) => {
        const rowValue = row[this.queryInfo.searchColumnName];
        if (
          typeof rowValue !== 'string' ||
          typeof this.currentSearchValue !== 'string'
        ) {
          return false;
        }
        return rowValue
          .toLocaleLowerCase()
          .includes(this.currentSearchValue.toLocaleLowerCase().trim());
      });
      this.paginator.length = queriedData.length;
    }

    if (this.query.paging) {
      const start =
        ((this.query.paging.page || 1) - 1) * this.query.paging.page_size;
      queriedData = queriedData.slice(
        start,
        start + this.query.paging.page_size
      );
    }

    if (this.query.sorting && this.query.sorting.length) {
      queriedData = this.sortData(queriedData, this.query.sorting[0]);
    }

    this.loading = false;
    this.data.next(queriedData);
  }

  private sortData(data: Array<{}>, sort: SortOption): Array<{}> {
    return data.sort((a, b) => {
      const aValue = a[sort.option];
      const bValue = b[sort.option];
      let compareResult = 0;
      if (typeof aValue === 'number') {
        compareResult = aValue - bValue;
      } else if (
        aValue !== undefined &&
        aValue !== null &&
        bValue !== undefined &&
        bValue !== null
      ) {
        compareResult = aValue.localeCompare(bValue);
      }
      return compareResult * (sort.direction === 'ASC' ? 1 : -1);
    });
  }

  private patchQuery(query: Partial<ListQuery>) {
    this.query = merge({}, this.query, query);
  }

  getData(): BehaviorSubject<Array<{}>> {
    return this.data;
  }
}
