<ng-container *ngIf="auth.isLoggedIn()">
  <div
    class="qa-sidebar-inner"
    [class.collapse]="!isVisible"
    [attr.lockmobile]="isCms ? 'true' : null"
    [attr.open]="isVisible ? true : null"
  >
    <div
      class="flex-row flex-align-items-center logo-container"
      *ngIf="isVisible"
    >
      <div
        *ngIf="isVisible"
        [routerLink]="['/']"
        (click)="closeSideBar()"
        class="logo flex-grow"
      ></div>
    </div>
    <div class="logo-mini" *ngIf="!isVisible">
      <img [routerLink]="['/']" src="assets/icons/logo-mini.png" alt="" />
    </div>
    <ng-scrollbar>
      <div class="sidebar-items">
        <div>
          <ng-container *ngFor="let group of navGroups; let nIdx = index">
            <ng-container
              *ngIf="nIdx < 6"
              [ngTemplateOutlet]="sideMenuItems"
              [ngTemplateOutletContext]="{
                group: group
              }"
            >
            </ng-container>
          </ng-container>
        </div>
        <div>
          <ng-container *ngFor="let group of navGroups; let nIdx = index">
            <ng-container
              *ngIf="nIdx > 5"
              [ngTemplateOutlet]="sideMenuItems"
              [ngTemplateOutletContext]="{
                group: group
              }"
            >
            </ng-container>
          </ng-container>
        </div>
      </div>
      <ng-template #sideMenuItems let-group="group">
        <div
          class="menu-item"
          [@expandableNavGroup]="
            group.expandable && (group.open ? 'open' : 'closed')
          "
          *ngIf="!group.active || group.active()"
        >
          <mat-divider *ngIf="!group.no_seperator"></mat-divider>
          <mat-nav-list>
            <h3 *ngIf="!group.expandable && group.label" matSubheader>
              {{ group.label | translate }}
            </h3>
            <button
              (click)="toggleGroup(group)"
              *ngIf="group.expandable"
              class="expandable-nav-group-toggle"
              mat-list-item
              type="button"
              [matTooltip]="group.label | translate"
              matTooltipPosition="right"
            >
              <mat-icon matListIcon>{{ group.icon }}</mat-icon>
              <div
                *ngIf="isVisible"
                [class.with_new]="group.is_new || group.coming_soon"
                [attr.data-cy]="group.label | translate"
              >
                {{ group.label | translate }}
              </div>
              <div
                [matBadge]="group.badgeCount"
                *ngIf="group.badgeCount && group.badgeCount > 0"
              ></div>
              <div class="newicon" *ngIf="group.is_new && isVisible">
                {{ 'menu.new' | translate }}
              </div>
              <div class="newicon" *ngIf="group.coming_soon && isVisible">
                {{ 'menu.soon' | translate }}
              </div>
              <mat-icon *ngIf="isVisible">
                {{ group.open ? 'keyboard_arrow_up' : 'keyboard_arrow_down' }}
              </mat-icon>
            </button>
            <ng-container *ngFor="let item of group.items">
              <div
                class="tooltip-cont"
                [matTooltip]="item.label | translate"
                matTooltipPosition="right"
              >
                <a
                  (click)="item?.onClick ? item.onClick() : null"
                  [routerLink]="item.link && [item.link]"
                  [routerLinkActive]="item.link ? 'active' : ''"
                  [routerLinkActiveOptions]="{
                    exact: item?.exact !== undefined ? item?.exact : false
                  }"
                  [disabled]="item.disabled"
                  *ngIf="!item.externalLink && (!item.hideIf || !item.hideIf())"
                  mat-list-item
                  [attr.data-cy]="item.label | translate"
                >
                  <mat-icon
                    [class.material-icons]="isActive(item)"
                    matListIcon
                    >{{ item.icon }}</mat-icon
                  >
                  <span mat-line *ngIf="isVisible">{{
                    item.label | translate
                  }}</span>
                  <div
                    [matBadge]="item.badgeCount"
                    *ngIf="item.badgeCount && item.badgeCount > 0"
                  ></div>
                  <div class="newicon" *ngIf="item.is_new && isVisible">
                    {{ 'menu.new' | translate }}
                  </div>
                  <div class="newicon" *ngIf="item.coming_soon && isVisible">
                    {{ 'menu.soon' | translate }}
                  </div>
                </a>
              </div>
              <a
                (click)="item?.onClick ? item.onClick() : null"
                [href]="item.externalLink"
                *ngIf="item.externalLink && (!item.hideIf || !item.hideIf())"
                mat-list-item
                target="_blank"
                [attr.data-cy]="item.label | translate"
                [matTooltip]="item.label | translate"
                matTooltipPosition="right"
              >
                <mat-icon matListIcon>{{ item.icon }}</mat-icon>
                <span mat-line *ngIf="isVisible">{{
                  item.label | translate
                }}</span>
              </a>
            </ng-container>
          </mat-nav-list>
        </div>
      </ng-template>
    </ng-scrollbar>
  </div>
</ng-container>
