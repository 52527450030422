import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ListDataSource } from '../../list/list-data-source';
import { ImportEntity } from '../../list/list.types';
import { PanelEntity } from '../../panel/panel.types';

export interface ImportContentDialogData {
  imports: Array<ImportEntity<PanelEntity>>;
  dataSource: ListDataSource;
  title: string;
}

@Component({
  selector: 'qa-import-content-dialog',
  templateUrl: './import-content-dialog.component.html',
  styleUrls: ['./import-content-dialog.component.scss'],
})
export class ImportContentDialogComponent implements OnInit {
  imports: Array<ImportEntity<PanelEntity>>;
  title: string;
  dataSource: ListDataSource;
  constructor(@Inject(MAT_DIALOG_DATA) private data: ImportContentDialogData) {}

  ngOnInit(): void {
    this.imports = this.data.imports;
    this.dataSource = this.data.dataSource;
    this.title = this.data.title;
  }
}
