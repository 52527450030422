<h1 mat-dialog-title>{{ data.title }}</h1>
<mat-dialog-content>
  {{ data.text }}
  <qa-form-field-select
    [control]="value"
    label="{{ data.formLabel }}"
    [options]="data.options"
    required="true"
  >
  </qa-form-field-select>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button color="warn" mat-button [mat-dialog-close]="false">
    {{ 'common.button.abort' | translate }}
  </button>
  <button
    color="primary"
    mat-button
    [mat-dialog-close]="value?.value"
    [disabled]="!value?.value"
  >
    {{ 'common.button.ok' | translate }}
  </button>
</mat-dialog-actions>
