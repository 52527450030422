import { Component, Input } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { FlashCardModel } from 'src/app/api/models';
import { MarkdownKatexService } from '../markdown-katex/markdown-katex.service';
import { FlashCardPDFGenerator } from './flash-card-pdf-generator';

@Component({
  selector: 'qa-print-flash-card-stack',
  templateUrl: './print-flash-card-stack.component.html',
  styleUrls: ['./print-flash-card-stack.component.scss'],
})
export class PrintFlashCardStackComponent {
  pdfDataUrl = null;

  generator: FlashCardPDFGenerator;

  @Input() flashCards: FlashCardModel[];

  format: 'a6' | 'glossar' | 'table' = 'a6';

  constructor(
    private domSanitizer: DomSanitizer,
    private markdown: MarkdownKatexService,
    private translator: TranslateService
  ) {}

  private generatePDF() {
    this.generator = new FlashCardPDFGenerator(
      this.flashCards,
      this.markdown,
      this.format,
      this.translator
    );
    this.generator.generate().then((doc) => {
      const url = doc.output('bloburl').toString();
      this.pdfDataUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(url);
      this.generator = null;
    });
  }

  submitSettings() {
    this.generator = null;
    this.pdfDataUrl = null;
    window.setTimeout(() => this.generatePDF(), 0);
  }

  back() {
    window.history.back();
  }
}
