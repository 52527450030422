import { Component, Input } from '@angular/core';
import { QuizSummaryStatistics } from 'src/app/api/models';
import { PanelService } from 'src/app/cms/panel/panel.service';
import { PanelEntityType } from 'src/app/cms/panel/panel.types';

@Component({
  selector: 'qa-quiz-stats',
  templateUrl: './quiz-stats.component.html',
  styleUrls: ['./quiz-stats.component.scss'],
})
export class QuizStatsComponent {
  @Input() stats: QuizSummaryStatistics[];
  @Input() courseId;

  constructor(private panelService: PanelService) {}

  getCorrectRatio(ratio: number) {
    return ratio ? `${(ratio * 100).toFixed(2)}%` : '0%';
  }
  onQuizClick(stat: QuizSummaryStatistics) {
    this.panelService.removeParam(PanelEntityType.FlashCardStack);
    this.panelService.addParam(PanelEntityType.Quiz, stat.quiz.id);
  }
}
