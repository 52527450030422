/* tslint:disable */
/* eslint-disable */
import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiConfiguration, ApiConfigurationParams } from './api-configuration';

import { ApiService } from './services/api.service';
import { AiService } from './services/ai.service';
import { BooksService } from './services/books.service';
import { CategoriesService } from './services/categories.service';
import { CommentsService } from './services/comments.service';
import { CoursesService } from './services/courses.service';
import { AccessRequestsService } from './services/access-requests.service';
import { ExamsService } from './services/exams.service';
import { FlashCardsService } from './services/flash-cards.service';
import { FlashCardStacksService } from './services/flash-card-stacks.service';
import { LiveEventsService } from './services/live-events.service';
import { MediaService } from './services/media.service';
import { QuestionsService } from './services/questions.service';
import { QuizzesService } from './services/quizzes.service';
import { PublicService } from './services/public.service';
import { SearchService } from './services/search.service';
import { StatsService } from './services/stats.service';
import { TagsService } from './services/tags.service';
import { UsersService } from './services/users.service';

/**
 * Module that provides all services and configuration.
 */
@NgModule({
  imports: [],
  exports: [],
  declarations: [],
  providers: [
    ApiService,
    AiService,
    BooksService,
    CategoriesService,
    CommentsService,
    CoursesService,
    AccessRequestsService,
    ExamsService,
    FlashCardsService,
    FlashCardStacksService,
    LiveEventsService,
    MediaService,
    QuestionsService,
    QuizzesService,
    PublicService,
    SearchService,
    StatsService,
    TagsService,
    UsersService,
    ApiConfiguration
  ],
})
export class ApiModule {
  static forRoot(params: ApiConfigurationParams): ModuleWithProviders<ApiModule> {
    return {
      ngModule: ApiModule,
      providers: [
        {
          provide: ApiConfiguration,
          useValue: params
        }
      ]
    }
  }

  constructor( 
    @Optional() @SkipSelf() parentModule: ApiModule,
    @Optional() http: HttpClient
  ) {
    if (parentModule) {
      throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
    }
    if (!http) {
      throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
      'See also https://github.com/angular/angular/issues/20575');
    }
  }
}
