<div
  class="custom-range-picker"
  [ngClass]="{ 'german-lang': translate.currentLang === 'de' }"
>
  <input
    type="text"
    name="daterangeInput"
    id="daterangeInput"
    readonly
    [autoApply]="false"
    [alwaysShowCalendars]="true"
    [showCancel]="true"
    [(ngModel)]="selected"
    [keepCalendarOpeningWithRange]="true"
    drops="auto"
    ngxDaterangepickerMd
    [locale]="locale"
    [ranges]="ranges"
    [maxDate]="maxDate"
    (datesUpdated)="calendarApplied($event)"
  />
  <mat-icon class="cursor-pointer ngx-daterangepicker-action" (click)="open()"
    >calendar_today</mat-icon
  >
</div>
