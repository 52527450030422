<div class="sidebar">
  <qa-button
    [isRaisedButton]="true"
    (submitted)="back()"
    label="common.button.back"
    icon="arrow_back"
  ></qa-button>
  <h1>{{ 'flashCardStack.print.title' | translate }}</h1>
  <mat-form-field appearance="fill">
    <mat-label><mat-icon>view_quilt</mat-icon>Format</mat-label>
    <mat-select [(value)]="format">
      <mat-option value="a6">
        {{ 'flashCardStack.print.a6' | translate }}
      </mat-option>
      <mat-option value="table">
        {{ 'flashCardStack.print.table' | translate }}
      </mat-option>
      <mat-option value="glossar">
        {{ 'flashCardStack.print.glossar' | translate }}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <qa-button
    [isRaisedButton]="true"
    (submitted)="submitSettings()"
    label="flashCardStack.print.generatePDF"
    icon="picture_as_pdf"
  ></qa-button>
</div>
<div class="print-preview">
  <iframe
    *ngIf="pdfDataUrl"
    [attr.src]="pdfDataUrl"
    type="application/pdf"
  ></iframe>
  <div class="spinner-container" *ngIf="generator && !pdfDataUrl">
    <mat-spinner [diameter]="48"> </mat-spinner>
    <span>{{ 'flashCardStack.print.generatingPDF' | translate }}</span>
  </div>
  <div class="expl" *ngIf="!generator && !pdfDataUrl">
    <span>{{ 'flashCardStack.print.noPdfYet' | translate }}</span>
    <qa-button
      [isRaisedButton]="true"
      (submitted)="submitSettings()"
      label="flashCardStack.print.generatePDF"
      icon="picture_as_pdf"
    ></qa-button>
  </div>
</div>
