import { Component, Inject, Optional } from '@angular/core';
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogConfig,
} from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

export enum SubscriptionLimit {
  COURSE_COUNT,
  BOOK_COUNT,
  LIVE_EVENT_COUNT,
  LIVE_EVENT_PARTICIPANTS,
  EXAM_COUNT,
  EXAM_PARTICIPANTS,
}

export const PARTICIPANTS_LIMIT = 10;

export class SubscriptionLimitWarning {
  private warningType?: SubscriptionLimit;

  constructor(
    private translator: TranslateService,
    private matDialog: MatDialog
  ) {}

  dialog(matDialog: MatDialog): SubscriptionLimitWarning {
    this.matDialog = matDialog;
    return this;
  }

  type(type: SubscriptionLimit): SubscriptionLimitWarning {
    this.warningType = type;
    return this;
  }

  build(): MatDialogRef<SubscriptionLimitWarningComponent> {
    let dialogConfig: MatDialogConfig = {
      disableClose: true,
      autoFocus: false,
    };
    switch (this.warningType) {
      case SubscriptionLimit.COURSE_COUNT: {
        return this.matDialog.open(SubscriptionLimitWarningComponent, {
          ...dialogConfig,
          data: {
            ...this.dataCourseCount(this.translator),
          },
        });
      }
      case SubscriptionLimit.LIVE_EVENT_COUNT: {
        return this.matDialog.open(SubscriptionLimitWarningComponent, {
          ...dialogConfig,
          data: {
            ...this.dataLiveEventCount(this.translator),
          },
        });
      }
      case SubscriptionLimit.LIVE_EVENT_PARTICIPANTS: {
        return this.matDialog.open(SubscriptionLimitWarningComponent, {
          ...dialogConfig,
          data: {
            ...this.dataLiveEventNumParticipants(this.translator),
          },
        });
      }
      case SubscriptionLimit.EXAM_COUNT: {
        return this.matDialog.open(SubscriptionLimitWarningComponent, {
          ...dialogConfig,
          data: {
            ...this.dataExamCount(this.translator),
          },
        });
      }
      case SubscriptionLimit.EXAM_PARTICIPANTS: {
        return this.matDialog.open(SubscriptionLimitWarningComponent, {
          ...dialogConfig,
          data: {
            ...this.dataExamNumParticipants(this.translator),
          },
        });
      }
      default:
        throw new Error('Wrong limit type');
    }
  }

  private dataCourseCount(
    translator: TranslateService
  ): SubscriptionLimitWarningData {
    return {
      message: translator.instant(
        'profile.subscription.limits.course.numCourses'
      ),
    };
  }

  private dataLiveEventCount(
    translator: TranslateService
  ): SubscriptionLimitWarningData {
    return {
      message: translator.instant(
        'profile.subscription.limits.liveEvent.numEvents'
      ),
    };
  }

  private dataLiveEventNumParticipants(
    translator: TranslateService
  ): SubscriptionLimitWarningData {
    return {
      message: translator.instant(
        'profile.subscription.limits.liveEvent.numParticipants'
      ),
    };
  }

  private dataExamCount(
    translator: TranslateService
  ): SubscriptionLimitWarningData {
    return {
      message: translator.instant('profile.subscription.limits.exam.numExams'),
    };
  }

  private dataExamNumParticipants(
    translator: TranslateService
  ): SubscriptionLimitWarningData {
    return {
      message: translator.instant(
        'profile.subscription.limits.exam.numParticipants'
      ),
    };
  }
}

interface SubscriptionLimitWarningData {
  message: string;
}

@Component({
  selector: 'qa-subscription-limit-warning',
  templateUrl: './subscription-limit-warning.component.html',
  styleUrl: './subscription-limit-warning.component.css',
})
export class SubscriptionLimitWarningComponent {
  constructor(
    @Optional()
    public dialogRef: MatDialogRef<SubscriptionLimitWarningComponent>,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: SubscriptionLimitWarningData,
    private matDialog: MatDialog,
    private router: Router
  ) {}

  onUnderstood() {
    this.matDialog.closeAll();
  }

  onRequestContact() {
    window.open('https://quizacademy.de/kontakt/', '_blank');
  }

  onUpgradeToStandard() {
    this.router
      .navigate(['/profile'], {
        queryParams: { tab: 'subscription', license: 'true' },
      })
      .then(() => {
        this.matDialog.closeAll();
      });
  }
}
