import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { StatsService } from 'src/app/api/services';
import { TranslateService } from '@ngx-translate/core';

interface Counter {
  count: number;
  icon: string;
  link: string;
  text: string;
}

@Component({
  selector: 'qa-counter-statistics',
  templateUrl: './counter-statistics.component.html',
  styleUrls: ['./counter-statistics.component.scss'],
})
export class CounterStatisticsComponent implements OnInit {
  @Input() global = false;
  @Output() ready = new EventEmitter<void>();
  counters: Counter[];

  constructor(
    private stats: StatsService,
    elementRef: ElementRef<HTMLElement>,
    private translator: TranslateService
  ) {
    elementRef.nativeElement.classList.add('qa-counter-statistics');
  }

  async ngOnInit() {
    const stats = await this.stats
      .statsControllerGetStats({ global: this.global })
      .toPromise();
    this.counters = [
      {
        count: stats.num_courses,
        icon: 'collections_bookmark',
        link: '/cms/courses',
        text:
          stats.num_courses === 1
            ? this.translator.instant('common.label.course')
            : this.translator.instant('common.label.courses'),
      },
      {
        count: stats.num_quizzes,
        icon: 'library_books',
        link: '/cms/quizzes',
        text: 'Quiz',
      },
      {
        count: stats.num_questions,
        icon: 'question_answers',
        link: '/cms/questions',
        text:
          stats.num_questions === 1
            ? this.translator.instant('common.label.question')
            : this.translator.instant('common.label.questions'),
      },
      {
        count: stats.num_flash_card_stacks,
        icon: 'layers',
        link: '/cms/flash-card-stacks',
        text: this.translator.instant('common.label.flashCardStack'),
      },
      {
        count: stats.num_flash_cards,
        icon: 'crop_portrait',
        link: '/cms/flash-cards',
        text:
          stats.num_flash_cards === 1
            ? this.translator.instant('common.label.flashCard')
            : this.translator.instant('common.label.flashCards'),
      },
    ];
    this.ready.next();
    this.ready.complete();
  }
}
