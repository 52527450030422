import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatBadgeModule } from '@angular/material/badge';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { StoreModule } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { EditModule } from '../cms/edit/edit.module';
import { SidebarComponent } from './sidebar.component';
import { sidebarReducer } from './sidebar.reducer';
import { Sidebar } from './sidebar.types';
import { CmsCommonModule } from '../cms/common/common.module';

@NgModule({
  declarations: [SidebarComponent],
  imports: [
    CommonModule,
    EditModule,
    MatBadgeModule,
    MatDividerModule,
    CmsCommonModule,
    MatIconModule,
    MatListModule,
    MatTooltipModule,
    StoreModule.forFeature(Sidebar.key, sidebarReducer),
    RouterModule,
    TranslateModule,
  ],
  exports: [SidebarComponent],
})
export class SidebarModule {}
