import { Injectable } from '@angular/core';
import { CategoriesService } from 'src/app/api/services';
import { Edit } from '../edit.types';
import { CategoryModel } from 'src/app/api/models';
import { map } from 'rxjs/operators';
import { convertCategoryTreesToList } from 'src/app/utility/app.utils';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class CategoryEditConfigService extends Edit.ConfigService<CategoryModel> {
  constructor(api: CategoriesService, translator: TranslateService) {
    super({
      itemName: translator.instant('category.form.title'),
      id: 'category',
      fields: [
        {
          key: 'icon_code',
          label: 'Icon',
          required: true,
          iconSelector: true,
        },
        {
          key: 'name',
          label: translator.instant('category.form.name'),
          required: true,
        },
        {
          key: 'parent',
          label: translator.instant('category.form.parentDepartment'),
          required: true,
          select: {
            getOptions: () => {
              return api
                .getCategories({ as_tree: true })
                .pipe(
                  map((categoryTrees) =>
                    convertCategoryTreesToList(categoryTrees)
                  ),
                  map((categories) => {
                    return categories.map<Edit.Option<number>>((c) => {
                      return {
                        value: c.id,
                        text: c.name,
                      };
                    });
                  })
                )
                .toPromise();
            },
          },
        },
      ],
      handlers: {
        create: (c) =>
          api
            .createCategory({ body: c })
            .pipe(map((r) => ({ ...r, icon: '' })))
            .toPromise(),
        delete: (c) =>
          api
            .deleteCategory({ id: c.id })
            .pipe(map((r) => ({ ...r, icon: '' })))
            .toPromise(),
        update: (c) =>
          api
            .updateCategory({ id: c.id, body: c })
            .pipe(map((r) => ({ ...r, icon: '' })))
            .toPromise(),
      },
    });
  }
}
