/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { BaseQueryResponse } from '../models/base-query-response';
import { BookModel } from '../models/book-model';
import { CardResultModel } from '../models/card-result-model';
import { CategoryModel } from '../models/category-model';
import { CommentModel } from '../models/comment-model';
import { CompetencyCatalog } from '../models/competency-catalog';
import { CompetencyCatalogQuery } from '../models/competency-catalog-query';
import { CourseModel } from '../models/course-model';
import { Exam } from '../models/exam';
import { ExamResultModel } from '../models/exam-result-model';
import { ExamUser } from '../models/exam-user';
import { FlashCardModel } from '../models/flash-card-model';
import { LiveEvent } from '../models/live-event';
import { LiveEventResult } from '../models/live-event-result';
import { LiveEventUser } from '../models/live-event-user';
import { QuestionModel } from '../models/question-model';
import { QuestionResultModel } from '../models/question-result-model';
import { SearchQuery } from '../models/search-query';
import { StudentReport } from '../models/student-report';
import { StudentReportRequest } from '../models/student-report-request';

@Injectable({
  providedIn: 'root',
})
export class PublicService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getPublicSearch
   */
  static readonly GetPublicSearchPath = '/public/search';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPublicSearch()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getPublicSearch$Response(params: {
    context?: HttpContext
    body: SearchQuery
  }
): Observable<StrictHttpResponse<BaseQueryResponse>> {

    const rb = new RequestBuilder(this.rootUrl, PublicService.GetPublicSearchPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BaseQueryResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getPublicSearch$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getPublicSearch(params: {
    context?: HttpContext
    body: SearchQuery
  }
): Observable<BaseQueryResponse> {

    return this.getPublicSearch$Response(params).pipe(
      map((r: StrictHttpResponse<BaseQueryResponse>) => r.body as BaseQueryResponse)
    );
  }

  /**
   * Path part for operation getPublicCourse
   */
  static readonly GetPublicCoursePath = '/public/courses/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPublicCourse()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPublicCourse$Response(params: {
    id: number;
    password?: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<CourseModel>> {

    const rb = new RequestBuilder(this.rootUrl, PublicService.GetPublicCoursePath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('password', params.password, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CourseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getPublicCourse$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPublicCourse(params: {
    id: number;
    password?: string;
    context?: HttpContext
  }
): Observable<CourseModel> {

    return this.getPublicCourse$Response(params).pipe(
      map((r: StrictHttpResponse<CourseModel>) => r.body as CourseModel)
    );
  }

  /**
   * Path part for operation getPublicCourseByPin
   */
  static readonly GetPublicCourseByPinPath = '/public/courses/pin/{pin}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPublicCourseByPin()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPublicCourseByPin$Response(params: {
    pin: string;
    password?: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<CourseModel>> {

    const rb = new RequestBuilder(this.rootUrl, PublicService.GetPublicCourseByPinPath, 'get');
    if (params) {
      rb.path('pin', params.pin, {});
      rb.query('password', params.password, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CourseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getPublicCourseByPin$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPublicCourseByPin(params: {
    pin: string;
    password?: string;
    context?: HttpContext
  }
): Observable<CourseModel> {

    return this.getPublicCourseByPin$Response(params).pipe(
      map((r: StrictHttpResponse<CourseModel>) => r.body as CourseModel)
    );
  }

  /**
   * Path part for operation createPublicFlashCard
   */
  static readonly CreatePublicFlashCardPath = '/public/courses/{id}/flash_cards';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createPublicFlashCard()` instead.
   *
   * This method doesn't expect any request body.
   */
  createPublicFlashCard$Response(params: {
    id: number;
    stack_id?: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<FlashCardModel>>> {

    const rb = new RequestBuilder(this.rootUrl, PublicService.CreatePublicFlashCardPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('stack_id', params.stack_id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<FlashCardModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `createPublicFlashCard$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  createPublicFlashCard(params: {
    id: number;
    stack_id?: number;
    context?: HttpContext
  }
): Observable<Array<FlashCardModel>> {

    return this.createPublicFlashCard$Response(params).pipe(
      map((r: StrictHttpResponse<Array<FlashCardModel>>) => r.body as Array<FlashCardModel>)
    );
  }

  /**
   * Path part for operation getPublicBookById
   */
  static readonly GetPublicBookByIdPath = '/public/books/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPublicBookById()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPublicBookById$Response(params: {
    id: number;
    password?: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<BookModel>> {

    const rb = new RequestBuilder(this.rootUrl, PublicService.GetPublicBookByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('password', params.password, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BookModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getPublicBookById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPublicBookById(params: {
    id: number;
    password?: string;
    context?: HttpContext
  }
): Observable<BookModel> {

    return this.getPublicBookById$Response(params).pipe(
      map((r: StrictHttpResponse<BookModel>) => r.body as BookModel)
    );
  }

  /**
   * Path part for operation getPublicBookByPin
   */
  static readonly GetPublicBookByPinPath = '/public/books/pin/{pin}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPublicBookByPin()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPublicBookByPin$Response(params: {
    pin: string;
    password?: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<BookModel>> {

    const rb = new RequestBuilder(this.rootUrl, PublicService.GetPublicBookByPinPath, 'get');
    if (params) {
      rb.path('pin', params.pin, {});
      rb.query('password', params.password, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BookModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getPublicBookByPin$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPublicBookByPin(params: {
    pin: string;
    password?: string;
    context?: HttpContext
  }
): Observable<BookModel> {

    return this.getPublicBookByPin$Response(params).pipe(
      map((r: StrictHttpResponse<BookModel>) => r.body as BookModel)
    );
  }

  /**
   * Path part for operation createPublicBookFlashCard
   */
  static readonly CreatePublicBookFlashCardPath = '/public/books/{id}/flash_cards';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createPublicBookFlashCard()` instead.
   *
   * This method doesn't expect any request body.
   */
  createPublicBookFlashCard$Response(params: {
    id: number;
    stack_id?: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<FlashCardModel>>> {

    const rb = new RequestBuilder(this.rootUrl, PublicService.CreatePublicBookFlashCardPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('stack_id', params.stack_id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<FlashCardModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `createPublicBookFlashCard$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  createPublicBookFlashCard(params: {
    id: number;
    stack_id?: number;
    context?: HttpContext
  }
): Observable<Array<FlashCardModel>> {

    return this.createPublicBookFlashCard$Response(params).pipe(
      map((r: StrictHttpResponse<Array<FlashCardModel>>) => r.body as Array<FlashCardModel>)
    );
  }

  /**
   * Path part for operation getPublicCategories
   */
  static readonly GetPublicCategoriesPath = '/public/categories';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPublicCategories()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPublicCategories$Response(params?: {
    parent_id?: number;
    as_tree?: boolean;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<CategoryModel>>> {

    const rb = new RequestBuilder(this.rootUrl, PublicService.GetPublicCategoriesPath, 'get');
    if (params) {
      rb.query('parent_id', params.parent_id, {});
      rb.query('as_tree', params.as_tree, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<CategoryModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getPublicCategories$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPublicCategories(params?: {
    parent_id?: number;
    as_tree?: boolean;
    context?: HttpContext
  }
): Observable<Array<CategoryModel>> {

    return this.getPublicCategories$Response(params).pipe(
      map((r: StrictHttpResponse<Array<CategoryModel>>) => r.body as Array<CategoryModel>)
    );
  }

  /**
   * Path part for operation createPublicResult
   */
  static readonly CreatePublicResultPath = '/public/results';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createPublicResult()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createPublicResult$Response(params: {
    context?: HttpContext
    body: QuestionResultModel
  }
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, PublicService.CreatePublicResultPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `createPublicResult$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createPublicResult(params: {
    context?: HttpContext
    body: QuestionResultModel
  }
): Observable<void> {

    return this.createPublicResult$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation createPublicFlashCardResult
   */
  static readonly CreatePublicFlashCardResultPath = '/public/results/cards';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createPublicFlashCardResult()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createPublicFlashCardResult$Response(params: {
    context?: HttpContext
    body: CardResultModel
  }
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, PublicService.CreatePublicFlashCardResultPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `createPublicFlashCardResult$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createPublicFlashCardResult(params: {
    context?: HttpContext
    body: CardResultModel
  }
): Observable<void> {

    return this.createPublicFlashCardResult$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation createPublicComment
   */
  static readonly CreatePublicCommentPath = '/public/comments';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createPublicComment()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createPublicComment$Response(params: {
    context?: HttpContext
    body: CommentModel
  }
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, PublicService.CreatePublicCommentPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `createPublicComment$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createPublicComment(params: {
    context?: HttpContext
    body: CommentModel
  }
): Observable<void> {

    return this.createPublicComment$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation getPublicExamByPin
   */
  static readonly GetPublicExamByPinPath = '/public/exams/pin/{pin}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPublicExamByPin()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPublicExamByPin$Response(params: {
    pin: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Exam>> {

    const rb = new RequestBuilder(this.rootUrl, PublicService.GetPublicExamByPinPath, 'get');
    if (params) {
      rb.path('pin', params.pin, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Exam>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getPublicExamByPin$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPublicExamByPin(params: {
    pin: string;
    context?: HttpContext
  }
): Observable<Exam> {

    return this.getPublicExamByPin$Response(params).pipe(
      map((r: StrictHttpResponse<Exam>) => r.body as Exam)
    );
  }

  /**
   * Path part for operation getPublicExamById
   */
  static readonly GetPublicExamByIdPath = '/public/exams/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPublicExamById()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPublicExamById$Response(params: {
    id: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Exam>> {

    const rb = new RequestBuilder(this.rootUrl, PublicService.GetPublicExamByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Exam>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getPublicExamById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPublicExamById(params: {
    id: number;
    context?: HttpContext
  }
): Observable<Exam> {

    return this.getPublicExamById$Response(params).pipe(
      map((r: StrictHttpResponse<Exam>) => r.body as Exam)
    );
  }

  /**
   * Path part for operation registerPublicExamUser
   */
  static readonly RegisterPublicExamUserPath = '/public/exams/{id}/users';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `registerPublicExamUser()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  registerPublicExamUser$Response(params: {
    id: number;
    context?: HttpContext
    body: ExamUser
  }
): Observable<StrictHttpResponse<ExamUser>> {

    const rb = new RequestBuilder(this.rootUrl, PublicService.RegisterPublicExamUserPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ExamUser>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `registerPublicExamUser$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  registerPublicExamUser(params: {
    id: number;
    context?: HttpContext
    body: ExamUser
  }
): Observable<ExamUser> {

    return this.registerPublicExamUser$Response(params).pipe(
      map((r: StrictHttpResponse<ExamUser>) => r.body as ExamUser)
    );
  }

  /**
   * Path part for operation getPublicUserInExam
   */
  static readonly GetPublicUserInExamPath = '/public/exams/{id}/users/{user_id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPublicUserInExam()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPublicUserInExam$Response(params: {
    id: number;
    user_id: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<ExamUser>> {

    const rb = new RequestBuilder(this.rootUrl, PublicService.GetPublicUserInExamPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('user_id', params.user_id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ExamUser>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getPublicUserInExam$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPublicUserInExam(params: {
    id: number;
    user_id: string;
    context?: HttpContext
  }
): Observable<ExamUser> {

    return this.getPublicUserInExam$Response(params).pipe(
      map((r: StrictHttpResponse<ExamUser>) => r.body as ExamUser)
    );
  }

  /**
   * Path part for operation getPublicExamQuestions
   */
  static readonly GetPublicExamQuestionsPath = '/public/exams/{id}/questions';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPublicExamQuestions()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPublicExamQuestions$Response(params: {
    id: number;
    user_id?: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<QuestionModel>>> {

    const rb = new RequestBuilder(this.rootUrl, PublicService.GetPublicExamQuestionsPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('user_id', params.user_id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<QuestionModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getPublicExamQuestions$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPublicExamQuestions(params: {
    id: number;
    user_id?: string;
    context?: HttpContext
  }
): Observable<Array<QuestionModel>> {

    return this.getPublicExamQuestions$Response(params).pipe(
      map((r: StrictHttpResponse<Array<QuestionModel>>) => r.body as Array<QuestionModel>)
    );
  }

  /**
   * Path part for operation createPublicExamResult
   */
  static readonly CreatePublicExamResultPath = '/public/exams/{id}/result';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createPublicExamResult()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createPublicExamResult$Response(params: {
    id: number;
    user_id?: string;
    context?: HttpContext
    body: Array<ExamResultModel>
  }
): Observable<StrictHttpResponse<Array<ExamResultModel>>> {

    const rb = new RequestBuilder(this.rootUrl, PublicService.CreatePublicExamResultPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('user_id', params.user_id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ExamResultModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `createPublicExamResult$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createPublicExamResult(params: {
    id: number;
    user_id?: string;
    context?: HttpContext
    body: Array<ExamResultModel>
  }
): Observable<Array<ExamResultModel>> {

    return this.createPublicExamResult$Response(params).pipe(
      map((r: StrictHttpResponse<Array<ExamResultModel>>) => r.body as Array<ExamResultModel>)
    );
  }

  /**
   * Path part for operation getPublicLiveEventByPin
   */
  static readonly GetPublicLiveEventByPinPath = '/public/live_events/pin/{pin}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPublicLiveEventByPin()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPublicLiveEventByPin$Response(params: {
    pin: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<LiveEvent>> {

    const rb = new RequestBuilder(this.rootUrl, PublicService.GetPublicLiveEventByPinPath, 'get');
    if (params) {
      rb.path('pin', params.pin, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<LiveEvent>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getPublicLiveEventByPin$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPublicLiveEventByPin(params: {
    pin: string;
    context?: HttpContext
  }
): Observable<LiveEvent> {

    return this.getPublicLiveEventByPin$Response(params).pipe(
      map((r: StrictHttpResponse<LiveEvent>) => r.body as LiveEvent)
    );
  }

  /**
   * Path part for operation getPublicLiveEventById
   */
  static readonly GetPublicLiveEventByIdPath = '/public/live_events/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPublicLiveEventById()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPublicLiveEventById$Response(params: {
    id: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<LiveEvent>> {

    const rb = new RequestBuilder(this.rootUrl, PublicService.GetPublicLiveEventByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<LiveEvent>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getPublicLiveEventById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPublicLiveEventById(params: {
    id: number;
    context?: HttpContext
  }
): Observable<LiveEvent> {

    return this.getPublicLiveEventById$Response(params).pipe(
      map((r: StrictHttpResponse<LiveEvent>) => r.body as LiveEvent)
    );
  }

  /**
   * Path part for operation createPublicLiveEventUser
   */
  static readonly CreatePublicLiveEventUserPath = '/public/live_events/{id}/users';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createPublicLiveEventUser()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createPublicLiveEventUser$Response(params: {
    id: number;
    context?: HttpContext
    body: LiveEventUser
  }
): Observable<StrictHttpResponse<LiveEventUser>> {

    const rb = new RequestBuilder(this.rootUrl, PublicService.CreatePublicLiveEventUserPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<LiveEventUser>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `createPublicLiveEventUser$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createPublicLiveEventUser(params: {
    id: number;
    context?: HttpContext
    body: LiveEventUser
  }
): Observable<LiveEventUser> {

    return this.createPublicLiveEventUser$Response(params).pipe(
      map((r: StrictHttpResponse<LiveEventUser>) => r.body as LiveEventUser)
    );
  }

  /**
   * Path part for operation getPublicLiveEventQuestions
   */
  static readonly GetPublicLiveEventQuestionsPath = '/public/live_events/{id}/question';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPublicLiveEventQuestions()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPublicLiveEventQuestions$Response(params: {
    id: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<QuestionModel>> {

    const rb = new RequestBuilder(this.rootUrl, PublicService.GetPublicLiveEventQuestionsPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<QuestionModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getPublicLiveEventQuestions$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPublicLiveEventQuestions(params: {
    id: number;
    context?: HttpContext
  }
): Observable<QuestionModel> {

    return this.getPublicLiveEventQuestions$Response(params).pipe(
      map((r: StrictHttpResponse<QuestionModel>) => r.body as QuestionModel)
    );
  }

  /**
   * Path part for operation createPublicLiveEventResult
   */
  static readonly CreatePublicLiveEventResultPath = '/public/live_events/{id}/results';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createPublicLiveEventResult()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createPublicLiveEventResult$Response(params: {
    id: number;
    context?: HttpContext
    body: LiveEventResult
  }
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, PublicService.CreatePublicLiveEventResultPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `createPublicLiveEventResult$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createPublicLiveEventResult(params: {
    id: number;
    context?: HttpContext
    body: LiveEventResult
  }
): Observable<void> {

    return this.createPublicLiveEventResult$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation getStandardsCatalogue_1
   */
  static readonly GetStandardsCatalogue_1Path = '/public/documents/student_report/subjects_standards';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getStandardsCatalogue_1()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getStandardsCatalogue_1$Response(params: {
    cognitousergroup?: string;
    cognitouserid?: string;
    context?: HttpContext
    body: CompetencyCatalogQuery
  }
): Observable<StrictHttpResponse<CompetencyCatalog>> {

    const rb = new RequestBuilder(this.rootUrl, PublicService.GetStandardsCatalogue_1Path, 'post');
    if (params) {
      rb.header('cognitousergroup', params.cognitousergroup, {});
      rb.header('cognitouserid', params.cognitouserid, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CompetencyCatalog>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getStandardsCatalogue_1$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getStandardsCatalogue_1(params: {
    cognitousergroup?: string;
    cognitouserid?: string;
    context?: HttpContext
    body: CompetencyCatalogQuery
  }
): Observable<CompetencyCatalog> {

    return this.getStandardsCatalogue_1$Response(params).pipe(
      map((r: StrictHttpResponse<CompetencyCatalog>) => r.body as CompetencyCatalog)
    );
  }

  /**
   * Path part for operation generateStudentReport_1
   */
  static readonly GenerateStudentReport_1Path = '/public/documents/student_report/generate';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `generateStudentReport_1()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  generateStudentReport_1$Response(params: {
    cognitousergroup?: string;
    cognitouserid?: string;
    context?: HttpContext
    body: StudentReportRequest
  }
): Observable<StrictHttpResponse<StudentReport>> {

    const rb = new RequestBuilder(this.rootUrl, PublicService.GenerateStudentReport_1Path, 'post');
    if (params) {
      rb.header('cognitousergroup', params.cognitousergroup, {});
      rb.header('cognitouserid', params.cognitouserid, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StudentReport>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `generateStudentReport_1$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  generateStudentReport_1(params: {
    cognitousergroup?: string;
    cognitouserid?: string;
    context?: HttpContext
    body: StudentReportRequest
  }
): Observable<StudentReport> {

    return this.generateStudentReport_1$Response(params).pipe(
      map((r: StrictHttpResponse<StudentReport>) => r.body as StudentReport)
    );
  }

}
