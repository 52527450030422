import {
  Component,
  ElementRef,
  HostBinding,
  Inject,
  ViewChild,
  ViewEncapsulation,
  OnDestroy,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatRipple } from '@angular/material/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { filter } from 'rxjs/operators';
import { cloneDeep } from 'lodash';
import { TranslateService } from '@ngx-translate/core';
import { DestroyNotifier } from './destroy-notifier';
import { QuestionType } from 'src/app/app-constants';
import { Question } from 'src/app/quiz/quiz.types';
import { PLAY_VIEW_DATA } from 'src/app/play/play.types';
import { AppState } from 'src/app/app.types';
import { QuizActions } from 'src/app/quiz/quiz.actions';

export interface QuestionComponentData {
  questionId: number;
  hideWeblink?: boolean;
}

@Component({
  selector: 'qa-question-cms',
  templateUrl: './question-cms.component.html',
  styleUrls: ['./question-cms.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class QuestionCmsComponent extends DestroyNotifier implements OnDestroy {
  @HostBinding('class.is-explanation-panel-open') get isExplanationPanelOpen() {
    return this.question && this.question.isExplanationPanelOpen;
  }
  @HostBinding('class.is-completed') get isCompleted() {
    return (
      this.question &&
      this.question.isCompleted &&
      !this.isSubmitAnswerRippleEntering
    );
  }
  @HostBinding('class.is-answered-correctly') get isAnsweredCorrectly() {
    return this.question && this.question.isAnsweredCorrectly === true;
  }
  @HostBinding('class.is-answered-incorrectly') get isAnsweredIncorrectly() {
    return this.question && this.question.isAnsweredCorrectly === false;
  }

  @ViewChild('submitAnswerRipple', { read: MatRipple })
  submitAnswerRipple: MatRipple;

  answersDisabled: boolean;
  answerSelectionControl = new UntypedFormControl();
  initialSelected: number | { [answerId: number]: boolean };
  isAnswerRetractionDisabled: boolean;
  questionType = QuestionType;
  isSubmitAnswerRippleEntering: boolean;
  question: Question;
  numOfUsers = 0;
  answerSubmitted = 0;
  state: AppState;
  stateInterval;

  get isSubmitAnswerDisabled() {
    if (!this.question) return true;
    return (
      this.question.model.type !== QuestionType.MultipleChoice &&
      this.question.selection === null
    );
  }

  constructor(
    @Inject(PLAY_VIEW_DATA) public data: QuestionComponentData,
    private domSanitizer: DomSanitizer,
    private elementRef: ElementRef<HTMLElement>,
    private store: Store<AppState>,
    actions$: Actions,
    private translator: TranslateService
  ) {
    super();
    elementRef.nativeElement.classList.add('qa-question');
    actions$
      .pipe(ofType(QuizActions.completeQuestion))
      .subscribe(() => this.complete());
    store.pipe(filter((state) => !!state.quiz.questions)).subscribe((state) => {
      this.state = state;
      this.answersDisabled = state.quiz.answersDisabled;
      this.isAnswerRetractionDisabled = state.quiz.isAnswerRetractionDisabled;
      const question = state.quiz.questions.find((q) => {
        return q.model.id === this.data.questionId;
      });
      if (!question) return;
      this.question = cloneDeep(question);
      this.answerSelectionControl.setValue(this.question.selection, {
        emitEvent: false,
      });
    });
    this.answerSelectionControl.valueChanges.subscribe((selection) => {
      this.store.dispatch(
        QuizActions.updateAnswerSelection({
          questionId: this.question.model.id,
          selection,
        })
      );
    });
  }

  ngOnDestroy() {
    if (this.stateInterval) {
      clearInterval(this.stateInterval);
    }
  }

  complete() {
    if (this.submitAnswerRipple) {
      this.isSubmitAnswerRippleEntering = true;
      const hostRect = this.elementRef.nativeElement.getBoundingClientRect();
      const x = hostRect.width + hostRect.left;
      const y = hostRect.height + hostRect.top;
      const enterDuration = 400;
      this.submitAnswerRipple.launch(x, y, {
        animation: { enterDuration, exitDuration: 400 },
      });
      setTimeout(() => {
        this.isSubmitAnswerRippleEntering = false;
      }, enterDuration);
    }
  }

  getCorrectAnswers() {
    return this.question.model.answers.filter((a) => a.is_right);
  }

  getCorrectAnswerText(): string {
    switch (this.question.model.type) {
      case QuestionType.MultipleChoice:
        const correctAnswers = this.question.model.answers.filter(
          (a) => a.is_right
        );
        if (!correctAnswers.length)
          return this.translator.instant('question.message.noneCorrectAns');
        return this.translator.instant('question.message.correctMultiAns');
      case QuestionType.TrueFalse:
        return this.question.model.is_right
          ? this.translator.instant('question.message.correctStatement')
          : this.translator.instant('question.message.wrongStatement');
      case QuestionType.SingleChoice:
        return this.translator.instant('question.message.correctSingleAns');
      default:
        throw new Error('Unsupported question type.');
    }
  }

  getWeblink(): SafeUrl {
    let weblink = this.question.model.weblink;
    if (!weblink) {
      return null;
    }
    if (!weblink.includes('http')) {
      weblink = `http://${weblink}`;
    }
    return this.domSanitizer.bypassSecurityTrustUrl(weblink);
  }

  retractAnswer() {
    this.store.dispatch(
      QuizActions.retractAnswer({
        questionId: this.question.model.id,
      })
    );
  }

  getProgressAnswer(submittedAnswer, totalUser) {
    return (submittedAnswer * 100) / totalUser;
  }

  submitAnswer() {
    this.store.dispatch(
      QuizActions.submitAnswer({
        questionId: this.question.model.id,
      })
    );
  }
}
