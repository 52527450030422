import { Component, Input } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { FlashCardModel } from 'src/app/api/models';

@Component({
  selector: 'qa-flash-card-mock-phone',
  templateUrl: './flash-card-mock-phone.component.html',
  styleUrls: ['./flash-card-mock-phone.component.scss'],
})
export class FlashCardMockPhoneComponent {
  @Input() itemCount: number;
  @Input() currentItemPosition = 1;
  @Input()
  flashCard: FlashCardModel;
  isExplanationPanelOpen = false;

  showAnswer = false;

  constructor(private domSanitizer: DomSanitizer) {}

  openExplainPanel(event, open): void {
    if (open) {
      event.stopPropagation();
      this.isExplanationPanelOpen = !this.isExplanationPanelOpen;
    }
  }

  getWeblink(): SafeUrl {
    let weblink = this.flashCard.weblink;
    if (!weblink) {
      return null;
    }
    if (!weblink.includes('http')) {
      weblink = `http://${weblink}`;
    }
    return this.domSanitizer.bypassSecurityTrustUrl(weblink);
  }
}
