import { Component, OnInit, Inject } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface EmailDialogData {
  email?: string;
  isReset?: boolean;
}

@Component({
  selector: 'qa-email-dialog',
  template: `
    <h1 mat-dialog-title *ngIf="!isReset">
      {{ 'common.dialogTitle.forgotPass' | translate }}
    </h1>
    <h1 mat-dialog-title *ngIf="isReset">
      {{ 'common.dialogTitle.resetPass' | translate }}
    </h1>
    <mat-dialog-content>
      <mat-form-field>
        <mat-label> E-Mail </mat-label>
        <input [formControl]="emailControl" matInput type="email" />
      </mat-form-field>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
      <button color="primary" mat-button mat-dialog-close type="button">
        {{ 'common.button.abort' | translate }}
      </button>
      <button
        [disabled]="!emailControl.valid"
        [mat-dialog-close]="emailControl.value"
        color="primary"
        mat-button
        type="button"
      >
        {{ 'common.button.continue' | translate }}
      </button>
    </mat-dialog-actions>
  `,
  styles: [``],
})
export class EmailDialogComponent implements OnInit {
  emailControl: UntypedFormControl;
  isReset = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) data: EmailDialogData,
    private dialogRef: MatDialogRef<EmailDialogComponent>
  ) {
    const initialValue = (data && data.email) || '';
    this.emailControl = new UntypedFormControl(initialValue, [
      Validators.required,
      Validators.email,
    ]);
    this.isReset = data.isReset ? data.isReset : false;
  }

  ngOnInit() {
    this.dialogRef.updateSize('320px');
  }
}
