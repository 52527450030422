import { ValidatorFn } from '@angular/forms';
import { Observable } from 'rxjs';

export namespace Edit {
  export abstract class ConfigService<M = any> {
    constructor(public config: Config<M>) {}
  }

  export interface Action {
    disableIf?: (formValue: any) => boolean;
    handler: (formValue: any, initialFormValue: any) => void;
    hideIf?: (formValue: any) => boolean;
    icon: string;
    text: string;
  }

  export interface Config<F = any> {
    itemName: string;
    id: string;
    deleteConfirmText?: string;
    deleteConfirmTitle?: string;
    fields: Array<Field<any, F>>;
    handlers: {
      update: (newValue: F, initialValue: F) => Promise<F>;
      create?: (newValue: F, initialValue: F) => Promise<F>;
      delete?: (newValue: F) => Promise<any>;
      init?: (initialValue: F) => Promise<F>;
    };
    afterOpened?: () => void;
    params?: { [key: string]: any };
  }

  export interface Field<T = any, F = any> {
    key: keyof F;
    label: string;
    disabled?: boolean;
    enableDatepicker?: boolean;
    hideIf?: (initialValue: F, currentValue: F) => boolean;
    inputType?: string;
    multiLine?: boolean;
    suggestion?: string;
    required?: boolean;
    suffix?: Action;
    validators?: ValidatorFn[];
    errorMsg?: string;
    hint?: string;
    initialValue?: any;
    autocomplete?: {
      getAutoCompleteOptions: (val?: T) => Observable<Array<Option<T>>>;
      getInitialValue: (val?: T) => {};
    };
    select?: {
      getOptions: () => Promise<Array<Option<T>>>;
      allowMultiple?: boolean;
      compareWith?: (value1: T, value2: T) => boolean;
    };
    iconSelector?: boolean;
  }

  export interface Option<T = any> {
    hint?: string;
    text?: string;
    value: T;
  }
}
