import { Component, Inject } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConfirmDialogComponentDataButton } from './confirm-dialog.component';

export interface TextInputDialogComponentData {
  title: string;
  text: string;
  control: UntypedFormControl;
  buttons: ConfirmDialogComponentDataButton[];
}

@Component({
  selector: 'qa-text-input-dialog',
  templateUrl: './text-input-dialog.component.html',
  styleUrls: ['./text-input-dialog.component.scss'],
})
export class TextInputDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<TextInputDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: TextInputDialogComponentData
  ) {}
}
