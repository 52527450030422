<qa-content-spinner class="qa-course-market" [active]="loading">
  <ng-scrollbar data-check="true">
    <div class="browse">
      <div class="list" *ngIf="!loading">
        <qa-course-market-list
          (selectedConfig)="selectedConfig($event)"
          (showFindOutContent)="showFindOutMore = $event"
          (courseMarketListSource)="courseMarketListSource($event)"
          [providers]="configs"
          [categoriesList]="categoriesList"
        >
        </qa-course-market-list>
        <div
          *ngIf="
            showFindOutMore &&
            !selectedItems?.length &&
            !courseMarketSource?.loading &&
            courseMarketSource?.length < 7
          "
        >
          <qa-find-out-course></qa-find-out-course>
        </div>
      </div>
      <div class="actions flex-column flex-justify-space-between">
        <qa-find-out-course
          *ngIf="
            showFindOutMore &&
            !selectedItems?.length &&
            courseMarketSource?.length > 7
          "
        ></qa-find-out-course>
        <div class="table" *ngIf="selectedItems?.length">
          <h3>{{ 'courseMarket.importToCourse' | translate }}</h3>
          <mat-table
            [dataSource]="selectedItems"
            class="mat-elevation-z8"
            *ngIf="activeListConfig"
          >
            <ng-container
              [matColumnDef]="column?.name"
              *ngFor="let column of activeListConfig.columns"
            >
              <mat-header-cell
                [style.flex]="column?.width ? '0 0 ' + column.width : null"
                *matHeaderCellDef
              >
                {{ column?.label }}
              </mat-header-cell>
              <mat-cell
                [style.flex]="column?.width ? '0 0 ' + column.width : null"
                *matCellDef="let row"
              >
                <mat-icon
                  *ngIf="
                    column.icon &&
                    column.icon({ row: row, index: i, dataSource: dataSource })
                  "
                  >{{
                    column.icon({ row: row, index: i, dataSource: dataSource })
                  }}</mat-icon
                >
                {{ column.accessor ? column.accessor(row) : row[column.name] }}
              </mat-cell>
            </ng-container>
            <ng-container [matColumnDef]="'actions'">
              <mat-header-cell
                [style.flex]="'0 0 48px'"
                *matHeaderCellDef
              ></mat-header-cell>
              <mat-cell
                [style.flex]="'0 0 300px'"
                *matCellDef="let row; let i = index"
              >
                <div class="disabled-message" *ngIf="row?.is_user_restricted">
                  {{ 'courseMarket.noPreview' | translate }}
                </div>
                <button
                  (click)="removeSelection(row)"
                  mat-icon-button
                  class="mat-close-button"
                  type="button"
                >
                  <mat-icon> delete </mat-icon>
                </button>
              </mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="columnNames"></mat-header-row>
            <mat-row *matRowDef="let row; columns: columnNames"></mat-row>
          </mat-table>
        </div>
        <div class="import-actions">
          <div class="flex-align-right">
            <qa-button
              (submitted)="goBack()"
              label="common.button.cancel"
            ></qa-button>
            <qa-button
              [disabled]="!selectedItems?.length"
              [isRaisedButton]="true"
              label="common.button.import"
              (submitted)="checkForOrganization()"
            ></qa-button>
          </div>
        </div>
      </div>
    </div>
  </ng-scrollbar>
</qa-content-spinner>
