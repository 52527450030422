import { NgModule, inject } from '@angular/core';
import { Router, RouterModule, Routes } from '@angular/router';
import { CanActivateCms } from './auth/can-activate-cms';
import { LoginComponent } from './auth/login.component';
import { HomeComponent } from './home.component';
import { ShortenerComponent } from './shortener.component';
import { RegisterComponent } from './auth/register/register.component';

const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
  },
  {
    path: 'cms',
    loadChildren: () => import('./cms/cms.module').then((m) => m.CmsModule),
    canActivate: [CanActivateCms],
  },
  {
    path: 'course',
    loadChildren: () =>
      import('./course/course.module').then((m) => m.CourseModule),
  },
  {
    path: 'book',
    loadChildren: () =>
      import('./course/course.module').then((m) => m.CourseModule),
  },
  {
    path: 'exam',
    loadChildren: () => import('./exam/exam.module').then((m) => m.ExamModule),
  },
  {
    path: 'profile',
    loadChildren: () =>
      import('./profile/profile.module').then((m) => m.ProfileModule),
  },
  {
    path: 'live-events',
    loadChildren: () =>
      import('./live-event/live-event.module').then((m) => m.LiveEventModule),
  },
  {
    path: 'prototype/transcript',
    loadChildren: () =>
      import('./prototype/transcript/transcript.module').then(
        (m) => m.TranscriptModule
      ),
    canActivate: [
      () => {
        // Only make this route available in dev environments
        const router: Router = inject(Router);
        const origin = window.location.origin;
        const canActivate =
          origin.includes('dev.quizacademy.io') || origin.includes('localhost');
        if (!canActivate) {
          router.navigate(['/login']);
        }
        return canActivate;
      },
    ],
  },
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'register',
    component: RegisterComponent,
  },
  // Link shortening
  {
    path: ':anyid',
    component: ShortenerComponent,
  },
  {
    path: '**',
    redirectTo: '',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      onSameUrlNavigation: 'reload',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
