<mat-dialog-content>
  <h2>Your JSON has got following errors :</h2>
  <ng-container *ngIf="data?.quizzes?.length">
    <div class="entity" *ngFor="let item of data.quizzes">
      <h4>Quiz {{ item.quiz + 1 }} :</h4>
      <div class="pb-10" *ngFor="let que of item.questions">
        Question {{ que.index + 1 }} : {{ que.message }}
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="data?.exams?.length">
    <div class="entity" *ngFor="let item of data.exams">
      <h4>Exam {{ item.exam + 1 }} :</h4>
      <div class="pb-10" *ngFor="let que of item.questions">
        Question {{ que.index + 1 }} : {{ que.message }}
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="data?.card_stacks?.length">
    <div class="entity" *ngFor="let item of data.card_stacks">
      <h4>Flashcard Stack {{ item.exam + 1 }} :</h4>
      <div class="pb-10" *ngFor="let card of item.cards">
        Flashcard {{ card.index + 1 }} : {{ card.message }}
      </div>
    </div>
  </ng-container>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button color="primary" mat-button mat-dialog-close type="button">
    {{ 'common.button.abort' | translate }}
  </button>
</mat-dialog-actions>
