import { Injectable } from '@angular/core';
import { FlashCardModel } from 'src/app/api/models';
import {
  FlashCardsService,
  FlashCardStacksService,
} from 'src/app/api/services';
import { PanelService } from '../panel.service';
import { Panel, PanelEntityType } from '../panel.types';
import { TranslateService } from '@ngx-translate/core';
import { ImportEntity } from '../../list/list.types';
import { MatDialog } from '@angular/material/dialog';
import { ImportAiFlashcardComponent } from '../../common/dialogs/import-ai-flashcard/import-ai-flashcard.component';
import { AuthService } from 'src/app/auth/auth.service';

export const getFlashCardImports = (
  translator: TranslateService,
  panelService: PanelService,
  matDialog: MatDialog,
  panel: Panel<FlashCardModel>,
  courseContext = false,
  isAdmin = false
): ImportEntity<FlashCardModel>[] =>
  (
    [
      isAdmin
        ? {
            id: 'chat-gpt',
            title: translator.instant('StackAIQuestion.generateStackTitle'),
            description: translator.instant(
              'StackAIQuestion.generateStackDescription'
            ),
            highlight: true,
            action: {
              icon: 'code',
              click: () => {
                matDialog
                  .open<unknown, any>(ImportAiFlashcardComponent, {
                    data: {},
                    width: '600px',
                  })
                  .afterClosed()
                  .subscribe((r) => {
                    if (r) {
                      matDialog.closeAll();
                      panelService.fetchItems(panel);
                    }
                  });
              },
              text: translator.instant('content.flashCard.addMenu.item7'),
            },
          }
        : null,
      {
        title: translator.instant('content.flashCard.addMenu.item6'),
        description: translator.instant('content.flashCard.description.item6'),
        action: {
          icon: 'code',
          click: () => {
            if (courseContext && panel) {
              panelService.importFromChatGPT(panel);
            } else {
              const callback = () => {
                const queryParams = {
                  type: PanelEntityType.FlashCard,
                };
                panelService.redirectToImportItems(
                  `/cms/csv-imports/flash-card`,
                  queryParams,
                  true
                );
              };
              panelService.importFromChatGPT(
                null,
                () => callback(),
                PanelEntityType.FlashCardStack
              );
            }
          },
          text: translator.instant('content.flashCard.addMenu.item8'),
        },
      },
      courseContext
        ? {
            title: translator.instant('content.flashCard.title.item1'),
            description: translator.instant(
              'content.flashCard.description.item1'
            ),
            action: {
              icon: 'move_to_inbox',
              click: () => panelService.importItems(panel),
              text: translator.instant('content.flashCard.addMenu.item2'),
            },
          }
        : null,
      {
        id: 'import-json',
        title: translator.instant('content.flashCard.title.item2'),
        description: translator.instant('content.flashCard.description.item2'),
        action: {
          icon: 'code',
          click: () => panelService.importItemAsJSON(panel),
          text: translator.instant('content.flashCard.addMenu.item3'),
        },
      },
      {
        id: 'import-as-csv',
        title: translator.instant('content.flashCard.title.item3'),
        description: translator.instant('content.flashCard.description.item3'),
        action: {
          icon: 'description',
          click: () => panelService.importItemAsCSV(panel),
          text: translator.instant('content.flashCard.addMenu.item4'),
        },
        extra: {
          name: translator.instant('content.question.title.extra'),
          type: 'link',
          icon: 'download',
          click: () => {
            window.open(
              'assets/import-csv-templates/Sample-flash-card-import.csv',
              '_blank'
            );
          },
        },
      },
    ] as ImportEntity<FlashCardModel>[]
  ).filter((n) => n);

@Injectable()
export class FlashCardPanel extends Panel<FlashCardModel> {
  constructor(
    flashCardStacks: FlashCardStacksService,
    flashCards: FlashCardsService,
    panelService: PanelService,
    translator: TranslateService,
    matDialog: MatDialog,
    auth: AuthService
  ) {
    super();
    super.init({
      addMenuItems: [
        {
          icon: 'add',
          onClick: () => panelService.editItem(this),
          text: translator.instant('content.flashCard.addMenu.item1'),
        },
      ],
      showChatGptAction:
        auth.currentUserHasGroup('Admin') ||
        auth.currentUserHasGroup('ContentManager'),
      imports: getFlashCardImports(
        translator,
        panelService,
        matDialog,
        this,
        true,
        auth.currentUserHasGroup('Admin') ||
          auth.currentUserHasGroup('ContentManager')
      ),
      changeItemPosition: (flashCardId, courseId, position) => {
        return flashCardStacks.repositionCardsInFlashCardStack({
          id: courseId,
          card_id: flashCardId,
          body: { position },
        });
      },
      createItemsFn: (items, parentId) =>
        flashCardStacks.copyCardsToFlashCardStack({
          id: parentId,
          body: items,
        }),
      deleteItem: (flashCardId) =>
        flashCards.deleteFlashCard({ id: flashCardId }),
      editDialogInput: (flashCard, flashCardStackId) => ({
        enableNavigation: true,
        flashCard,
        flashCardStackId,
      }),
      showTotalItems: true,
      fetchItem: (id) => flashCards.getFlashCardById({ id }),
      fetchItems: (flashCardStackId) =>
        flashCardStacks.getCardsInFlashCardStack({ id: flashCardStackId }),
      isFilteredListVisible: true,
      filterTopListBy: (flashCard) => flashCard.accepted,
      filterBottomListBy: (flashCard) => !flashCard.accepted,
      filteredListTitle: translator.instant('content.flashCard.suggestedCards'),
      itemActions: [
        {
          icon: 'code',
          label: translator.instant('content.flashCard.actionMenu.item1'),
          onClick: (item) => panelService.exportItemAsJSON(this, item.id),
        },
        {
          icon: 'link_off',
          label: translator.instant('content.flashCard.actionMenu.item2'),
          disabled: (card) => card['isImported'],
          tooltip: (card) =>
            card['isImported']
              ? translator.instant('content.quiz.notYourFlashcard')
              : null,
          onClick: (flashCard) => panelService.unlinkItems(this, flashCard.id),
        },
        {
          icon: 'delete',
          label: translator.instant('content.flashCard.actionMenu.item3'),
          disabled: (card) => card['isImported'],
          tooltip: (card) =>
            card['isImported']
              ? translator.instant('content.quiz.notYourFlashcard')
              : null,
          onClick: (flashCard) => panelService.deleteItem(this, flashCard.id),
        },
      ],
      headerIcon: 'crop_portrait',
      filteredItemAction: [
        {
          icon: 'delete',
          label: translator.instant('content.flashCard.actionMenu.item3'),
          disabled: (card) => card['isImported'],
          tooltip: (card) =>
            card['isImported']
              ? translator.instant('content.quiz.notYourFlashcard')
              : null,
          onClick: (flashCard) => panelService.deleteItem(this, flashCard.id),
        },
        {
          icon: 'add',
          label: translator.instant('common.tooltip.add'),
          onClick: (flashCard) => panelService.editItem(this, flashCard),
        },
      ],
      itemTextProperty: 'text',
      parentKey: 'cards',
      parentType: PanelEntityType.FlashCardStack,
      selectedItemActions: [
        {
          icon: 'link_off',
          label: translator.instant('content.flashCard.selectedItem.label'),
          onClick: (selectedItemIds) =>
            panelService.unlinkItems(this, selectedItemIds),
        },
      ],
      translations: {
        addItem: translator.instant('content.flashCard.tooltip'),
        deleteItemError: translator.instant(
          'content.flashCard.deleteItem.error'
        ),
        deleteItemSuccess: translator.instant(
          'content.flashCard.deleteItem.success'
        ),
        deleteItemText: translator.instant('content.flashCard.deleteItem.text'),
        deleteItemTitle: translator.instant(
          'content.flashCard.deleteItem.title'
        ),
        header: translator.instant('common.label.quizFlashCard'),
        unlinkItemConfirmText: translator.instant(
          'content.flashCard.unlinkItem.text'
        ),
        unlinkItemConfirmTitle: translator.instant(
          'content.flashCard.unlinkItem.title'
        ),
        unlinkItemError: translator.instant(
          'content.flashCard.unlinkItem.error'
        ),
        unlinkItemSuccess: translator.instant(
          'content.flashCard.unlinkItem.success'
        ),
      },
      type: PanelEntityType.FlashCard,
      unlinkItems: (flashCardIds, flashCardStackId) => {
        return flashCardStacks.removeCardsFromFlashCardStack({
          id: flashCardStackId,
          card_ids: flashCardIds.join(','),
        });
      },
    });
  }
}
