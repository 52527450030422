<div class="qr-code-box">
  <div class="qr-code-box-container" (click)="openQrModel()">
    <div class="pin-char-container">
      <div class="pin-char-prefix">
        {{ editionPrefix }}
      </div>
      <span class="pin-char-hyphen"> - </span>
      <div class="pin-char" *ngFor="let char of pinCode?.split('')">
        {{ char }}
      </div>
    </div>
    <div class="qr" [innerHTML]="qrSvg"></div>
  </div>
</div>
