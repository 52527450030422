import { Injectable } from '@angular/core';
import { NavigationEnd } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { filter, first } from 'rxjs/operators';
import { AppState } from '../app.types';
import { AuthService } from '../auth/auth.service';

@Injectable({
  providedIn: 'root',
})
export class DynamicCourseStyleService {
  private _isCms = true;

  private teaserInterval;

  constructor(
    private store: Store<AppState>,
    private translate: TranslateService,
    private auth: AuthService
  ) {}

  get isCms() {
    return this._isCms;
  }

  stopTeaserAnimation() {
    window.clearInterval(this.teaserInterval);
    document.documentElement.style.removeProperty('--primary-color');
    this.teaserInterval = null;
  }

  teaserAnimation() {
    if (this.teaserInterval) {
      return;
    }
    const colors = [
      '#ff0000',
      '#00aa00',
      '#0000ff',
      '#fcba03',
      '#b008bf',
      '#7d8000',
      '#e60bc8',
    ];
    document.documentElement.style.setProperty(
      '--primary-color',
      colors[Math.floor(Math.random() * colors.length)]
    );
    this.teaserInterval = window.setInterval(() => {
      document.documentElement.style.setProperty(
        '--primary-color',
        colors[Math.floor(Math.random() * colors.length)]
      );
    }, 1500);
  }

  apply(val: NavigationEnd): void {
    const isCms = val.url.indexOf('/cms') !== -1;
    this._isCms = isCms;
    if (!isCms) {
      this.store
        .pipe(filter((state) => !!state.course?.course))
        .pipe(first())
        .subscribe((state) => {
          this.applyFrom(state.course.course.color_code);
        });
    } else {
      document.documentElement.style.removeProperty('--primary-color');
      this.store
        .pipe(filter((state) => !!state.cmsLiveEvent?.liveEvent?.id))
        .pipe(first())
        .subscribe((state) => {
          this.applyFrom(state.cmsLiveEvent?.liveEvent?.color_code);
        });
    }
    this.store
      .pipe(filter((state) => !!state.liveEvent?.id))
      .pipe(first())
      .subscribe((state) => {
        this.applyFrom(state.liveEvent?.color_code);
      });
    this.store
      .pipe(filter((state) => !!state.exam?.exam))
      .pipe(first())
      .subscribe((state) => {
        this.applyFrom(state.exam.exam?.color_code);
      });
  }

  applyFrom(color_code: string) {
    document.documentElement.style.removeProperty('--primary-color');
    if (color_code) {
      document.documentElement.style.setProperty('--primary-color', color_code);
    } else {
      document.documentElement.style.removeProperty('--primary-color');
    }
  }

  get courseBrandingMail() {
    return `mailto:kontakt@quizacademy.de?subject=${this.translate.instant(
      'course.dynamicStyle.email.subject'
    )}&body=${encodeURIComponent(
      this.translate
        .instant('course.dynamicStyle.email.text')
        .replace(
          /\%name\%/gm,
          this.auth.currentUserInfo$ && this.auth.currentUserInfo$.value
            ? `${this.auth.currentUserInfo$.value.given_name} ${this.auth.currentUserInfo$.value.family_name}`
            : ''
        )
    )}`;
  }
}
