<div class="chart-wrapper" [style.height]="chartHeight">
  <canvas
    baseChart
    height="400"
    width="600"
    [data]="data"
    [options]="options"
    [type]="chartType"
    (chartClick)="chartClicked($event)"
  >
  </canvas>
</div>
