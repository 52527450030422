import {
  Component,
  ViewEncapsulation,
  ElementRef,
  OnInit,
} from '@angular/core';
import { FormGroup, UntypedFormControl } from '@angular/forms';
import { DestroyNotifier } from 'src/app/cms/common/destroy-notifier';
import { DateRangePickerService } from './date-range-picker.service';
import * as dayjs from 'dayjs';
import * as minMax from 'dayjs/plugin/minMax';
import { debounceTime, filter, takeUntil } from 'rxjs';

@Component({
  selector: 'qa-date-range-picker',
  templateUrl: './date-range-picker.component.html',
  styleUrls: ['./date-range-picker.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class FormDateRangePickerComponent
  extends DestroyNotifier
  implements OnInit
{
  rangeControl: FormGroup;
  oneYearAfterFromDate = new Date();
  oneYearBeforeDate = new Date();

  constructor(
    private dateRangePickerService: DateRangePickerService,
    elementRef: ElementRef<HTMLElement>
  ) {
    super();
    dayjs.extend(minMax);
    elementRef.nativeElement.classList.add('qa-date-range-picker');
    this.rangeControl = new FormGroup({
      start: new UntypedFormControl(null),
      end: new UntypedFormControl(null),
    });
    this.rangeControl
      .get('start')
      .valueChanges.pipe(
        takeUntil(this.destroy$),
        filter((from) => Boolean(from)),
        debounceTime(500)
      )
      .subscribe((startDate) => {
        if (startDate) {
          this.oneYearAfterFromDate = dayjs
            .min(dayjs(this.rangeControl.value.start).add(364, 'd'), dayjs())
            .toDate();
        }
      });
    this.rangeControl.valueChanges
      .pipe(
        takeUntil(this.destroy$),
        filter((from) => Boolean(from)),
        debounceTime(500)
      )
      .subscribe((selectedRange) => {
        if (selectedRange.start && selectedRange.end) {
          const fromFormat = dayjs(this.rangeControl.value.start);
          const toFormat = dayjs(this.rangeControl.value.end);
          if (
            fromFormat.diff(toFormat, 'days') > -365 &&
            fromFormat.diff(toFormat, 'days') <= 0
          ) {
            this.dateRangePickerService.updateDateRange({
              ...this.dateRangePickerService.dateRange.value,
              from: dayjs(selectedRange.start).toDate().toISOString(),
              to: dayjs(selectedRange.end).toDate().toISOString(),
            });
          }
        }
      });
  }

  ngOnInit() {
    this.dateRangePickerService.dateRange.subscribe((dateRange) => {
      this.rangeControl.get('start').setValue(dateRange.from, {
        emitEvent: false,
        onlySelf: true,
      });
      this.rangeControl.get('end').setValue(dateRange.to, {
        emitEvent: false,
        onlySelf: true,
      });
      if (this.rangeControl.value.start) {
        this.oneYearAfterFromDate = dayjs
          .min(dayjs(this.rangeControl.value.start).add(364, 'd'), dayjs())
          .toDate();
        this.oneYearBeforeDate = dayjs(this.rangeControl.value.end)
          .subtract(364, 'd')
          .toDate();
      }
    });
  }
}
