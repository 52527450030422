import * as csv from 'csvtojson';
import { BehaviorSubject } from 'rxjs';

export class CSVReader {
  static readCSVFromString(
    src: string,
    columnNames: string[],
    isFlashImport?: boolean
  ): BehaviorSubject<string[]> {
    const out = new BehaviorSubject([]);
    csv({
      noheader: true,
      delimiter: 'auto',
      output: 'csv',
    })
      .fromString(src)
      .then((jsonArray) => {
        jsonArray.splice(0, 1);
        let i = 0;
        let data = [];
        jsonArray.forEach((row) => {
          if (columnNames && columnNames.length) {
            const newData = {};
            let c = 0;
            for (const cname of columnNames) {
              if (cname === 'number') {
                newData[cname] = i + 1;
              } else if (
                cname === 'tags' &&
                row.length === (!isFlashImport ? 18 : 6)
              ) {
                const tagIndex = !isFlashImport ? 17 : 5;
                newData[cname] = row[tagIndex] ? row[tagIndex] : '';
                newData['media_id'] = '';
              } else {
                newData[cname] = row[c - 1] ? row[c - 1] : '';
              }
              c++;
            }
            i++;
            data = data.concat(newData);
          }
        });
        out.next(data);
      });
    return out;
  }
}
