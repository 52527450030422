<div class="quiz-statistics" *ngIf="courseId || quiz">
  <div class="quiz-statistics-container" *ngIf="!showQuestionStats">
    <ng-scrollbar>
      <div class="stat-container">
        <div class="datepicker date-picker-container">
          <qa-button
            (submitted)="downloadExcel()"
            label="content.quiz.questionStatistics.downloadAsExcel"
          ></qa-button>
          <qa-custom-range-picker> </qa-custom-range-picker>
        </div>
        <div [class.faded]="loading" class="chart-container">
          <qa-chart
            [data]="chartData"
            [options]="chartOptions"
            *ngIf="chartData"
            chartType="line"
            #chart
          >
          </qa-chart>
          <div class="quiz-stat-container">
            <div class="quiz-statistics-title">
              {{
                'content.quiz.questionStatistics.questionStatisticTitle'
                  | translate
              }}
            </div>
            <div class="quiz-statistics-table" *ngIf="questionsOverview">
              <qa-question-overview-list
                [questions]="questionsOverview"
                [hideUnAnswered]="true"
                (selectQuestionResult)="getQuestionStatistic($event)"
              ></qa-question-overview-list>
            </div>
          </div>
        </div>
      </div>
    </ng-scrollbar>
  </div>
  <div
    class="question-stats-container"
    *ngIf="showQuestionStats && questionStatistics"
  >
    <qa-question-overview-details
      [question]="question"
      [loading]="loading"
      [hideUnAnswered]="true"
      [allQuestions]="questionsOverview"
      [getQuestionResult]="getQuestionStatistic.bind(this)"
      [currentItemIndex]="currentItemIndex"
      (goBack)="showQuestionStats = false"
    >
    </qa-question-overview-details>
  </div>
</div>
