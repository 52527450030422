<div class="close-icon" (click)="close()"><mat-icon>close</mat-icon></div>
<div class="qr-model">
  <div [innerHTML]="qrData?.qr" class="qr-code"></div>
  <div class="pin-widget">
    <div class="link-part">{{ shortLinkPrefix }}</div>
    <div class="pin">
      <div class="input-container flex-row">
        <div class="pin-char-prefix">
          {{ editionPrefix }}
        </div>
        <span class="pin-char-hyphen"> - </span>
        <div class="pin-char" *ngFor="let char of qrData?.pin?.split('')">
          {{ char }}
        </div>
      </div>
    </div>
    <div class="share flex-column">
      <div class="flex-row links">
        <mat-icon>content_copy</mat-icon>
        <span (click)="copyWeblink()">{{
          'course.form.copyLable' | translate
        }}</span>
      </div>
      <div class="flex-row links">
        <mat-icon>content_copy</mat-icon>
        <span (click)="copyQR()">{{ 'course.form.copyQR' | translate }}</span>
      </div>
      <div class="flex-row links" *ngIf="qrData?.invitationLink">
        <mat-icon>email</mat-icon>
        <span (click)="onInvitationMailUrlClick()">{{
          'liveEvent.setup.label.invitationViaMail' | translate
        }}</span>
      </div>
    </div>
  </div>
</div>
