import {
  animate,
  query,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { debounceTime, filter } from 'rxjs/operators';
import { CourseModel, SearchQuery } from 'src/app/api/models';
import { PublicService } from 'src/app/api/services';
import { SidebarActions } from './sidebar/sidebar.actions';
import { AppState } from './app.types';
import { Store } from '@ngrx/store';
import { AuthService } from './auth/auth.service';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { MatDialog } from '@angular/material/dialog';
import {
  EmbedDialogComponent,
  EmbedDialogData,
} from './cms/panel/embed-dialog.component';

@Component({
  selector: 'qa-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  animations: [
    trigger('courseCards', [
      transition('-1 => *, 0 => *', [
        style({
          opacity: 0,
          transform: 'translateY(100px)',
        }),
        animate(
          '200ms cubic-bezier(0.23, 1, 0.32, 1)',
          style({
            opacity: 1,
            transform: 'translateY(0)',
          })
        ),
      ]),
      transition('* => 0', [
        animate(
          '200ms cubic-bezier(0.23, 1, 0.32, 1)',
          style({
            opacity: 0,
            transform: 'translateY(100px)',
          })
        ),
      ]),
      transition(':increment', [
        query(
          ':enter',
          [
            style({
              height: 0,
              opacity: 0,
            }),
            animate(
              '200ms cubic-bezier(0.23, 1, 0.32, 1)',
              style({
                height: '*',
                opacity: 1,
              })
            ),
          ],
          { optional: true }
        ),
      ]),
      transition(':decrement', [
        query(
          ':leave',
          [
            animate(
              '200ms cubic-bezier(0.23, 1, 0.32, 1)',
              style({
                height: 0,
                opacity: 0,
              })
            ),
          ],
          { optional: true }
        ),
      ]),
    ]),
  ],
})
export class HomeComponent implements OnInit {
  courses: CourseModel[];
  courseSearchControl = new UntypedFormControl();

  appLinks = {
    android: environment.playStoreLink,
    apple: environment.appStoreLink,
  };

  constructor(
    private store: Store<AppState>,
    private api: PublicService,
    public auth: AuthService,
    private router: Router,
    private matDialog: MatDialog
  ) {}

  ngOnInit() {
    this.courseSearchControl.valueChanges
      .pipe(
        debounceTime(200),
        filter((input: string) => {
          if (input && input.length >= 3) {
            return true;
          }
          this.courses = [];
          return false;
        })
      )
      .subscribe(() => {});
  }

  getCourseId(_: any, item: CourseModel): number {
    return item.id;
  }

  openSideBar() {
    this.store.dispatch(SidebarActions.showSidebar());
  }

  submit() {
    const input = this.courseSearchControl.value;
    if (input && input.length >= 3) {
      const query = {
        filters: {
          content_type: 'COURSE',
          search_term: input,
        },
      } as Partial<SearchQuery> as SearchQuery;
      this.api.getPublicSearch({ body: query }).subscribe((response) => {
        this.courses = response.data as [];
      });
    } else {
      this.courses = [];
      return false;
    }
    return true;
  }

  jumpToLogin(): void {
    this.router.navigateByUrl('/login');
  }

  embedHome(): void {
    this.matDialog.open<unknown, EmbedDialogData>(EmbedDialogComponent, {
      data: {} as EmbedDialogData,
    });
  }
}
