import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { MarkdownKatexModule } from 'src/app/widget/markdown-katex/markdown-katex.module';
import { CmsCommonModule } from '../common/common.module';
import { MockPhoneComponent } from '../common/mock-phone.component';
import { CategoryEditConfigService } from './configs/category.service';
import { CourseEditConfigService } from './configs/course.service';
import { ExamService } from './configs/exam.service';
import { QuizEditConfigService } from './configs/quiz.service';
import { LiveEventEditConfigService } from './configs/live-event.service';
import { ProfileEditConfigService } from './configs/profile.service';
import { EditConfirmCloseDialogComponent } from './edit-confirm-close-dialog.component';
import { EditDialogComponent } from './edit-dialog.component';
import { EditService } from './edit.service';
import { FlashCardEditComponent } from './flash-card-edit.component';
import { FlashCardMockPhoneComponent } from './flash-card-mock-phone.component';
import { QuestionEditComponent } from './question-edit.component';
import { QuestionMockPhoneComponent } from './question-mock-phone.component';
import { MatCardModule } from '@angular/material/card';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { AttachMediaDialogComponent } from './attach-media-dialog/attach-media-dialog.component';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatListModule } from '@angular/material/list';
import { StatisticsModule } from '../statistics/statistics.module';
import { ImageCropperModule } from 'ngx-image-cropper';
import { ImageEditDialogComponent } from './image-edit-dialog/image-edit-dialog.component';
import { TagEditComponent } from './tag-edit.component';
import { LiveQuestionSetupComponent } from './live-question-setup/live-question-setup.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { SharedModule } from 'src/app/shared/shared.module';
import { BookEditConfigService } from './configs/book.service';
import { CommonModule } from '@angular/common';

@NgModule({
  declarations: [
    EditConfirmCloseDialogComponent,
    EditDialogComponent,
    FlashCardEditComponent,
    FlashCardMockPhoneComponent,
    MockPhoneComponent,
    QuestionEditComponent,
    QuestionMockPhoneComponent,
    AttachMediaDialogComponent,
    ImageEditDialogComponent,
    TagEditComponent,
    LiveQuestionSetupComponent,
  ],
  providers: [
    CategoryEditConfigService,
    CourseEditConfigService,
    EditService,
    ExamService,
    QuizEditConfigService,
    LiveEventEditConfigService,
    ProfileEditConfigService,
    BookEditConfigService,
  ],
  imports: [
    CommonModule,
    CmsCommonModule,
    SharedModule,
    MarkdownKatexModule,
    MatAutocompleteModule,
    MatButtonModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDatepickerModule,
    MatDialogModule,
    MatFormFieldModule,
    MatCardModule,
    MatIconModule,
    MatInputModule,
    MatMenuModule,
    MatNativeDateModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatSelectModule,
    MatSnackBarModule,
    NgxDropzoneModule,
    MatTooltipModule,
    ReactiveFormsModule,
    RouterModule,
    StatisticsModule,
    ImageCropperModule,
    MatListModule,
    DragDropModule,
  ],
  exports: [
    EditDialogComponent,
    FlashCardEditComponent,
    FlashCardMockPhoneComponent,
    MockPhoneComponent,
    QuestionEditComponent,
    QuestionMockPhoneComponent,
    LiveQuestionSetupComponent,
  ],
})
export class EditModule {}
