import { Component, Inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatDialog,
} from '@angular/material/dialog';
import { UntypedFormControl } from '@angular/forms';
import { organizationDisplayFn } from 'src/app/app.types';
import { OrganizationModel } from 'src/app/api/models';
import {
  ShowInfoDialogComponent,
  ShowInfoItem,
} from 'src/app/cms/common/show-info-dialog/show-info-dialog.component';

@Component({
  selector: 'qa-organization-for-course',
  templateUrl: './organization-for-course.component.html',
  styleUrls: ['./organization-for-course.component.scss'],
})
export class OrganizationForCourseComponent {
  organizationControl = new UntypedFormControl(null);
  organizationDisplayFn = organizationDisplayFn;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: OrganizationModel[],
    public dialogRef: MatDialogRef<OrganizationForCourseComponent>,
    private matDialog: MatDialog,
    private translator: TranslateService
  ) {}

  openDialog() {
    this.matDialog.open<ShowInfoDialogComponent, ShowInfoItem>(
      ShowInfoDialogComponent,
      {
        data: {
          infoData: this.translator.instant('courseMarket.organizationInfo'),
        },
        width: '576px',
        height: 'auto',
        panelClass: 'tooltip-modal',
      }
    );
  }

  selectCourse() {
    this.dialogRef.close(this.organizationControl.value);
  }
}
