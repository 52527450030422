<div class="display-container">
  <div class="status-bar">
    <div class="time">
      {{ date | date : 'HH:mm' }}
    </div>
    <div class="icons">
      <mat-icon>signal_wifi_4_bar</mat-icon>
      <mat-icon>network_locked</mat-icon>
      <mat-icon>battery_std</mat-icon>
    </div>
  </div>
  <ng-content></ng-content>
</div>
