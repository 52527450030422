import { CommonModule, DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatChipsModule } from '@angular/material/chips';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { NgScrollbarModule } from 'ngx-scrollbar';
import {
  CategoriesService,
  CommentsService,
  CoursesService,
  FlashCardsService,
  FlashCardStacksService,
  MediaService,
  QuestionsService,
  QuizzesService,
  StatsService,
  UsersService,
} from '../../api/services';
import { DialogService } from './dialog.service';
import { ConfirmDialogComponent } from './dialogs/confirm-dialog.component';
import { TextInputDialogComponent } from './dialogs/text-input-dialog.component';
import { ObjectKeysPipe } from './object-keys.pipe';
import { TruncatePipe } from './truncate.pipe';
import { ChartComponent } from './ui/charts/chart.component';
import { ContentSpinnerComponent } from './ui/content-spinner.component';
import { EnterAnimationDirective } from './ui/enter-animation.directive';
import { FormDateRangePickerComponent } from './form-field/date-range-picker.component';
import { FormInputComponent } from './form-field/input.component';
import { ColorPickerComponent } from './form-field/color-picker.component';
import { FormSelectComponent } from './form-field/select.component';
import { CustomDateRangeComponent } from './form-field/custom-date-range/custom-date-range.component';
import { FormSelectEnumComponent } from './form-field/enum.component';
import { FormSelectMediaFileComponent } from './form-field/media-input.component';
import { FormToggleComponent } from './form-field/toggle.component';
import { CanDeactivateDialogComponent } from './can-deactivate-dialog.component';
import { MarkdownInfoDialogComponent } from './form-field/markdown-info-dialog.component';
import { DateRangePickerService } from './form-field/date-range-picker.service';
import { WidgetModule } from 'src/app/widget/widget.module';
import { HelpVideoDialogComponent } from './help-video-dialog.component';
import { TranslateModule } from '@ngx-translate/core';
import { ImportCsvNavigationComponent } from './import-csv-navigation.component';
import { ImportCsvErrorDialogComponent } from './dialogs/import-csv-error-dialog.component';
import { CancelConfirmationComponent } from './dialogs/cancel-confirmation.component';
import { SubscriptionLimitWarningComponent } from './dialogs/subscription-limit-warning/subscription-limit-warning.component';
import { CsvImportUploadAreaComponent } from './csv-import-upload-area.component';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { MarkdownKatexModule } from 'src/app/widget/markdown-katex/markdown-katex.module';
import { NavigationBreadcrumbsComponent } from './navigation-breadcrumbs/navigation-breadcrumbs.component';
import { CsvImportProgressDialogComponent } from './dialogs/csv-import-progress-dialog.component';
import { QuestionCmsComponent } from './question-cms.component';
import { PlayTimerComponent } from 'src/app/play/play-timer.component';
import { PlayTimeMeasurementComponent } from 'src/app/play/play-time-measurement.component';
import { CountdownComponent } from 'src/app/quiz/countdown.component';
import { FilterService } from './filter.service';
import { TagInputComponent } from './form-field/tag-input.component';
import { InvitationTextDialogComponent } from './invitationtext-dialog.component';
import { CountdownTimerComponent } from './countdown-timer.component';
import { MatTableModule } from '@angular/material/table';
import { ImageDialogComponent } from './dialogs/image-dialog.component';
import { InfoDialogComponent } from './dialogs/info-dialog.component';
import { SelectDialogComponent } from './dialogs/select-dialog.component';
import { ColorPickerModule } from 'ngx-color-picker';
import { CSVExportService } from './csv-export.service';
import { MatMenuModule } from '@angular/material/menu';
import { InteractiveAudioService } from './audio/interactive-audio.service';
import { AudioVolumeComponent } from './audio/audio-volume.component';
import { MatSliderModule } from '@angular/material/slider';
import { QuickFeedbackOverlayComponent } from './quick-feedback-overlay/quick-feedback-overlay.component';
import { QuickFeedbackOverlayService } from './quick-feedback-overlay/quick-feedback-overlay.service';
import { TreeSelectComponent } from './form-field/tree-select.component';
import { NgChartsModule } from 'ng2-charts';
import { QuestionCmsEventComponent } from './question-cms-event.component';
import { PollAnnouncementComponent } from 'src/app/event/poll-announcement.component';
import { WordCloudVisualizationComponent } from './word-cloud-visualization/word-cloud-visualization.component';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { EnvPipe } from 'src/app/utility/env.pipe';
import { HintToastComponent } from './hint-toast/hint-toast.component';
import { EditBlockComponent } from './form-field/edit-block/edit-block.component';
import { ImportContentDialogComponent } from './import-content-dialog/import-content-dialog.component';
import { OutsideClickDirective } from './click-outside.directive';
import { ChatgptImportDialogComponent } from './dialogs/chatgpt-import-dialog/chatgpt-import-dialog.component';
import { ShowInfoDialogComponent } from './show-info-dialog/show-info-dialog.component';
import { ImportExamNavigationComponent } from './import-exam-navigation.component';
import { SummaryEndComponent } from './ui/summary-end/summary-end.component';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { FlashCardSubmitActionComponent } from 'src/app/course/flash-card/flash-card-submit-action/flash-card-submit-action.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { ConfirmConvertFromQuizComponent } from './dialogs/confirm-convert-from-quiz/confirm-convert-from-quiz.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { ShareSectionComponent } from './share-section/share-section.component';
import { QuestionOverviewListComponent } from './questions-overview/question-overview-list/question-overview-list.component';
import { QuestionOverviewDetailsComponent } from './questions-overview/question-overview-details/question-overview-details.component';
import { CountdownWrapperComponent } from './countdown-wrapper/countdown-wrapper.component';
import { CustomRangePickerComponent } from './form-field/custom-range-picker/custom-range-picker.component';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { ImportAiQuestionComponent } from './dialogs/import-ai-question/import-ai-question.component';
import { ImportAiExplanationComponent } from './dialogs/import-ai-explanation/import-ai-explanation.component';
import { ImportAiFlashcardComponent } from './dialogs/import-ai-flashcard/import-ai-flashcard.component';
import { ExpandableInfoComponent } from './expandable-info/expandable-info.component';
import { SubscriptionViewDataService } from 'src/app/profile/subscription/viewdata/subscription-view-data.service';
import { MatSelectInfiniteScrollDirective } from 'src/app/cms/common/mat-select-infinite-scroll.directive';
import { SubscriptionIndicatorComponent } from './subscription/subscription-indicator/subscription-indicator.component';

@NgModule({
  declarations: [
    CanDeactivateDialogComponent,
    EnvPipe,
    ChartComponent,
    ConfirmDialogComponent,
    ImageDialogComponent,
    ContentSpinnerComponent,
    EnterAnimationDirective,
    FormDateRangePickerComponent,
    FormInputComponent,
    ColorPickerComponent,
    FormSelectComponent,
    FormSelectEnumComponent,
    FormSelectMediaFileComponent,
    FormToggleComponent,
    NavigationBreadcrumbsComponent,
    HelpVideoDialogComponent,
    MarkdownInfoDialogComponent,
    ObjectKeysPipe,
    TextInputDialogComponent,
    SelectDialogComponent,
    TruncatePipe,
    PlayTimeMeasurementComponent,
    PlayTimerComponent,
    CountdownComponent,
    PollAnnouncementComponent,
    ImportCsvNavigationComponent,
    ImportCsvErrorDialogComponent,
    CancelConfirmationComponent,
    SubscriptionLimitWarningComponent,
    CsvImportUploadAreaComponent,
    CsvImportProgressDialogComponent,
    QuestionCmsComponent,
    TagInputComponent,
    InvitationTextDialogComponent,
    CountdownTimerComponent,
    InfoDialogComponent,
    AudioVolumeComponent,
    QuickFeedbackOverlayComponent,
    TreeSelectComponent,
    QuestionCmsEventComponent,
    WordCloudVisualizationComponent,
    HintToastComponent,
    EditBlockComponent,
    ImportContentDialogComponent,
    OutsideClickDirective,
    ChatgptImportDialogComponent,
    ShowInfoDialogComponent,
    ImportExamNavigationComponent,
    SummaryEndComponent,
    FlashCardSubmitActionComponent,
    ConfirmConvertFromQuizComponent,
    ShareSectionComponent,
    QuestionOverviewListComponent,
    QuestionOverviewDetailsComponent,
    CountdownWrapperComponent,
    CustomRangePickerComponent,
    ImportAiQuestionComponent,
    ImportAiExplanationComponent,
    ImportAiFlashcardComponent,
    ExpandableInfoComponent,
    CustomDateRangeComponent,
    MatSelectInfiniteScrollDirective,
    SubscriptionIndicatorComponent,
  ],
  providers: [
    CategoriesService,
    FilterService,
    CommentsService,
    CoursesService,
    DatePipe,
    DateRangePickerService,
    DialogService,
    FlashCardsService,
    FlashCardStacksService,
    MediaService,
    QuestionsService,
    QuizzesService,
    StatsService,
    TruncatePipe,
    UsersService,
    CSVExportService,
    InteractiveAudioService,
    QuickFeedbackOverlayService,
    SubscriptionViewDataService,
  ],
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    NgxDropzoneModule,
    MatAutocompleteModule,
    MarkdownKatexModule,
    MatButtonModule,
    MatChipsModule,
    MatDatepickerModule,
    MatDialogModule,
    MatFormFieldModule,
    NgChartsModule,
    MatIconModule,
    MatInputModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatTooltipModule,
    NgScrollbarModule,
    ReactiveFormsModule,
    RouterModule,
    WidgetModule,
    TranslateModule,
    MatTableModule,
    ColorPickerModule,
    MatMenuModule,
    MatSliderModule,
    MatButtonToggleModule,
    ScrollingModule,
    MatCheckboxModule,
    NgxDaterangepickerMd.forRoot(),
  ],
  exports: [
    ChartComponent,
    ConfirmDialogComponent,
    EnvPipe,
    ImageDialogComponent,
    InfoDialogComponent,
    CsvImportUploadAreaComponent,
    ImportCsvNavigationComponent,
    ContentSpinnerComponent,
    EnterAnimationDirective,
    FormDateRangePickerComponent,
    FormInputComponent,
    ColorPickerComponent,
    FormSelectComponent,
    TagInputComponent,
    FormSelectEnumComponent,
    FormSelectMediaFileComponent,
    FormsModule,
    FormToggleComponent,
    HelpVideoDialogComponent,
    PlayTimeMeasurementComponent,
    PlayTimerComponent,
    CountdownComponent,
    PollAnnouncementComponent,
    ObjectKeysPipe,
    NgScrollbarModule,
    ReactiveFormsModule,
    TextInputDialogComponent,
    SelectDialogComponent,
    QuestionCmsComponent,
    TruncatePipe,
    NavigationBreadcrumbsComponent,
    WidgetModule,
    NgxDropzoneModule,
    CountdownTimerComponent,
    TranslateModule,
    TreeSelectComponent,
    QuestionCmsEventComponent,
    WordCloudVisualizationComponent,
    EditBlockComponent,
    ImportContentDialogComponent,
    OutsideClickDirective,
    ShowInfoDialogComponent,
    ImportExamNavigationComponent,
    SummaryEndComponent,
    ScrollingModule,
    FlashCardSubmitActionComponent,
    ConfirmConvertFromQuizComponent,
    ShareSectionComponent,
    QuestionOverviewListComponent,
    QuestionOverviewDetailsComponent,
    CustomRangePickerComponent,
    ImportAiFlashcardComponent,
    ExpandableInfoComponent,
    CustomDateRangeComponent,
    NgxDaterangepickerMd,
    MatSelectInfiniteScrollDirective,
    SubscriptionIndicatorComponent,
  ],
})
export class CmsCommonModule {}
