<img
  src="https://s3.{{ mediaBucket.region }}.amazonaws.com/{{
    mediaBucket.name
  }}/{{ model.media.uuid }}.{{ model.media.file_ext }}"
  class="image"
  *ngIf="model.media?.uuid && !youtubeLink"
/>
<iframe
  *ngIf="youtubeLink"
  [src]="youtubeLink"
  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
  allowfullscreen
></iframe>
<button
  mat-icon-button
  (click)="showImage(model.media)"
  class="zoom-button"
  *ngIf="model.media?.uuid"
>
  <mat-icon>zoom_out_map</mat-icon>
</button>
