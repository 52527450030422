/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { HighScoreEntryModel } from '../models/high-score-entry-model';
import { LiveEvent } from '../models/live-event';
import { LiveEventQuery } from '../models/live-event-query';
import { LiveEventQueryResponse } from '../models/live-event-query-response';
import { LiveEventUser } from '../models/live-event-user';
import { PositionChangeModel } from '../models/position-change-model';
import { QuestionModel } from '../models/question-model';
import { QuestionStatsModel } from '../models/question-stats-model';

@Injectable({
  providedIn: 'root',
})
export class LiveEventsService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation createLiveEvent
   */
  static readonly CreateLiveEventPath = '/live_events';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createLiveEvent()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createLiveEvent$Response(params: {
    cognitouserid?: string;
    cognitousergroup?: string;
    context?: HttpContext
    body: LiveEvent
  }
): Observable<StrictHttpResponse<LiveEvent>> {

    const rb = new RequestBuilder(this.rootUrl, LiveEventsService.CreateLiveEventPath, 'post');
    if (params) {
      rb.header('cognitouserid', params.cognitouserid, {});
      rb.header('cognitousergroup', params.cognitousergroup, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<LiveEvent>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `createLiveEvent$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createLiveEvent(params: {
    cognitouserid?: string;
    cognitousergroup?: string;
    context?: HttpContext
    body: LiveEvent
  }
): Observable<LiveEvent> {

    return this.createLiveEvent$Response(params).pipe(
      map((r: StrictHttpResponse<LiveEvent>) => r.body as LiveEvent)
    );
  }

  /**
   * Path part for operation queryEvents
   */
  static readonly QueryEventsPath = '/live_events/query';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `queryEvents()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  queryEvents$Response(params: {
    cognitouserid?: string;
    cognitousergroup?: string;
    context?: HttpContext
    body: LiveEventQuery
  }
): Observable<StrictHttpResponse<LiveEventQueryResponse>> {

    const rb = new RequestBuilder(this.rootUrl, LiveEventsService.QueryEventsPath, 'post');
    if (params) {
      rb.header('cognitouserid', params.cognitouserid, {});
      rb.header('cognitousergroup', params.cognitousergroup, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<LiveEventQueryResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `queryEvents$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  queryEvents(params: {
    cognitouserid?: string;
    cognitousergroup?: string;
    context?: HttpContext
    body: LiveEventQuery
  }
): Observable<LiveEventQueryResponse> {

    return this.queryEvents$Response(params).pipe(
      map((r: StrictHttpResponse<LiveEventQueryResponse>) => r.body as LiveEventQueryResponse)
    );
  }

  /**
   * Path part for operation liveEventControllerLiveEventDiconnect
   */
  static readonly LiveEventControllerLiveEventDiconnectPath = '/live_events/disconnect';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `liveEventControllerLiveEventDiconnect()` instead.
   *
   * This method doesn't expect any request body.
   */
  liveEventControllerLiveEventDiconnect$Response(params?: {
    cognitousergroup?: string;
    cognitouserid?: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, LiveEventsService.LiveEventControllerLiveEventDiconnectPath, 'post');
    if (params) {
      rb.header('cognitousergroup', params.cognitousergroup, {});
      rb.header('cognitouserid', params.cognitouserid, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `liveEventControllerLiveEventDiconnect$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  liveEventControllerLiveEventDiconnect(params?: {
    cognitousergroup?: string;
    cognitouserid?: string;
    context?: HttpContext
  }
): Observable<void> {

    return this.liveEventControllerLiveEventDiconnect$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation liveEventControllerLiveEventRegister
   */
  static readonly LiveEventControllerLiveEventRegisterPath = '/live_events/register';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `liveEventControllerLiveEventRegister()` instead.
   *
   * This method doesn't expect any request body.
   */
  liveEventControllerLiveEventRegister$Response(params?: {
    cognitousergroup?: string;
    cognitouserid?: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<LiveEvent>> {

    const rb = new RequestBuilder(this.rootUrl, LiveEventsService.LiveEventControllerLiveEventRegisterPath, 'post');
    if (params) {
      rb.header('cognitousergroup', params.cognitousergroup, {});
      rb.header('cognitouserid', params.cognitouserid, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<LiveEvent>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `liveEventControllerLiveEventRegister$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  liveEventControllerLiveEventRegister(params?: {
    cognitousergroup?: string;
    cognitouserid?: string;
    context?: HttpContext
  }
): Observable<LiveEvent> {

    return this.liveEventControllerLiveEventRegister$Response(params).pipe(
      map((r: StrictHttpResponse<LiveEvent>) => r.body as LiveEvent)
    );
  }

  /**
   * Path part for operation getLiveEventById
   */
  static readonly GetLiveEventByIdPath = '/live_events/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getLiveEventById()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLiveEventById$Response(params: {
    cognitouserid?: string;
    cognitousergroup?: string;
    id: number;
    include_users?: boolean;
    include_questions?: boolean;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<LiveEvent>> {

    const rb = new RequestBuilder(this.rootUrl, LiveEventsService.GetLiveEventByIdPath, 'get');
    if (params) {
      rb.header('cognitouserid', params.cognitouserid, {});
      rb.header('cognitousergroup', params.cognitousergroup, {});
      rb.path('id', params.id, {});
      rb.query('include_users', params.include_users, {});
      rb.query('include_questions', params.include_questions, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<LiveEvent>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getLiveEventById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLiveEventById(params: {
    cognitouserid?: string;
    cognitousergroup?: string;
    id: number;
    include_users?: boolean;
    include_questions?: boolean;
    context?: HttpContext
  }
): Observable<LiveEvent> {

    return this.getLiveEventById$Response(params).pipe(
      map((r: StrictHttpResponse<LiveEvent>) => r.body as LiveEvent)
    );
  }

  /**
   * Path part for operation updateLiveEvent
   */
  static readonly UpdateLiveEventPath = '/live_events/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateLiveEvent()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateLiveEvent$Response(params: {
    cognitouserid?: string;
    cognitousergroup?: string;
    id: number;
    context?: HttpContext
    body: LiveEvent
  }
): Observable<StrictHttpResponse<LiveEvent>> {

    const rb = new RequestBuilder(this.rootUrl, LiveEventsService.UpdateLiveEventPath, 'put');
    if (params) {
      rb.header('cognitouserid', params.cognitouserid, {});
      rb.header('cognitousergroup', params.cognitousergroup, {});
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<LiveEvent>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateLiveEvent$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateLiveEvent(params: {
    cognitouserid?: string;
    cognitousergroup?: string;
    id: number;
    context?: HttpContext
    body: LiveEvent
  }
): Observable<LiveEvent> {

    return this.updateLiveEvent$Response(params).pipe(
      map((r: StrictHttpResponse<LiveEvent>) => r.body as LiveEvent)
    );
  }

  /**
   * Path part for operation deleteLiveEvent
   */
  static readonly DeleteLiveEventPath = '/live_events/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteLiveEvent()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteLiveEvent$Response(params: {
    cognitouserid?: string;
    cognitousergroup?: string;
    id: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<LiveEvent>> {

    const rb = new RequestBuilder(this.rootUrl, LiveEventsService.DeleteLiveEventPath, 'delete');
    if (params) {
      rb.header('cognitouserid', params.cognitouserid, {});
      rb.header('cognitousergroup', params.cognitousergroup, {});
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<LiveEvent>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteLiveEvent$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteLiveEvent(params: {
    cognitouserid?: string;
    cognitousergroup?: string;
    id: number;
    context?: HttpContext
  }
): Observable<LiveEvent> {

    return this.deleteLiveEvent$Response(params).pipe(
      map((r: StrictHttpResponse<LiveEvent>) => r.body as LiveEvent)
    );
  }

  /**
   * Path part for operation resetEvents
   */
  static readonly ResetEventsPath = '/live_events/{id}/reset';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `resetEvents()` instead.
   *
   * This method doesn't expect any request body.
   */
  resetEvents$Response(params: {
    cognitouserid?: string;
    cognitousergroup?: string;
    id: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<LiveEvent>> {

    const rb = new RequestBuilder(this.rootUrl, LiveEventsService.ResetEventsPath, 'get');
    if (params) {
      rb.header('cognitouserid', params.cognitouserid, {});
      rb.header('cognitousergroup', params.cognitousergroup, {});
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<LiveEvent>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `resetEvents$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  resetEvents(params: {
    cognitouserid?: string;
    cognitousergroup?: string;
    id: number;
    context?: HttpContext
  }
): Observable<LiveEvent> {

    return this.resetEvents$Response(params).pipe(
      map((r: StrictHttpResponse<LiveEvent>) => r.body as LiveEvent)
    );
  }

  /**
   * Path part for operation getQuestionsForEvent
   */
  static readonly GetQuestionsForEventPath = '/live_events/{id}/questions';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getQuestionsForEvent()` instead.
   *
   * This method doesn't expect any request body.
   */
  getQuestionsForEvent$Response(params: {
    cognitouserid?: string;
    cognitousergroup?: string;
    id: number;
    embed_answers?: boolean;
    embed_tags?: boolean;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<QuestionModel>>> {

    const rb = new RequestBuilder(this.rootUrl, LiveEventsService.GetQuestionsForEventPath, 'get');
    if (params) {
      rb.header('cognitouserid', params.cognitouserid, {});
      rb.header('cognitousergroup', params.cognitousergroup, {});
      rb.path('id', params.id, {});
      rb.query('embed_answers', params.embed_answers, {});
      rb.query('embed_tags', params.embed_tags, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<QuestionModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getQuestionsForEvent$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getQuestionsForEvent(params: {
    cognitouserid?: string;
    cognitousergroup?: string;
    id: number;
    embed_answers?: boolean;
    embed_tags?: boolean;
    context?: HttpContext
  }
): Observable<Array<QuestionModel>> {

    return this.getQuestionsForEvent$Response(params).pipe(
      map((r: StrictHttpResponse<Array<QuestionModel>>) => r.body as Array<QuestionModel>)
    );
  }

  /**
   * Path part for operation linkQuestionToEvent
   */
  static readonly LinkQuestionToEventPath = '/live_events/{id}/questions';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `linkQuestionToEvent()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  linkQuestionToEvent$Response(params: {
    cognitouserid?: string;
    cognitousergroup?: string;
    id: number;
    context?: HttpContext
    body: Array<QuestionModel>
  }
): Observable<StrictHttpResponse<Array<QuestionModel>>> {

    const rb = new RequestBuilder(this.rootUrl, LiveEventsService.LinkQuestionToEventPath, 'put');
    if (params) {
      rb.header('cognitouserid', params.cognitouserid, {});
      rb.header('cognitousergroup', params.cognitousergroup, {});
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<QuestionModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `linkQuestionToEvent$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  linkQuestionToEvent(params: {
    cognitouserid?: string;
    cognitousergroup?: string;
    id: number;
    context?: HttpContext
    body: Array<QuestionModel>
  }
): Observable<Array<QuestionModel>> {

    return this.linkQuestionToEvent$Response(params).pipe(
      map((r: StrictHttpResponse<Array<QuestionModel>>) => r.body as Array<QuestionModel>)
    );
  }

  /**
   * Path part for operation copyQuestionToEvent
   */
  static readonly CopyQuestionToEventPath = '/live_events/{id}/questions';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `copyQuestionToEvent()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  copyQuestionToEvent$Response(params: {
    cognitouserid?: string;
    cognitousergroup?: string;
    id: number;
    context?: HttpContext
    body: Array<QuestionModel>
  }
): Observable<StrictHttpResponse<Array<QuestionModel>>> {

    const rb = new RequestBuilder(this.rootUrl, LiveEventsService.CopyQuestionToEventPath, 'post');
    if (params) {
      rb.header('cognitouserid', params.cognitouserid, {});
      rb.header('cognitousergroup', params.cognitousergroup, {});
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<QuestionModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `copyQuestionToEvent$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  copyQuestionToEvent(params: {
    cognitouserid?: string;
    cognitousergroup?: string;
    id: number;
    context?: HttpContext
    body: Array<QuestionModel>
  }
): Observable<Array<QuestionModel>> {

    return this.copyQuestionToEvent$Response(params).pipe(
      map((r: StrictHttpResponse<Array<QuestionModel>>) => r.body as Array<QuestionModel>)
    );
  }

  /**
   * Path part for operation removeQuestionFromEvent
   */
  static readonly RemoveQuestionFromEventPath = '/live_events/{id}/questions';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `removeQuestionFromEvent()` instead.
   *
   * This method doesn't expect any request body.
   */
  removeQuestionFromEvent$Response(params: {
    cognitouserid?: string;
    cognitousergroup?: string;
    id: number;
    question_ids?: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, LiveEventsService.RemoveQuestionFromEventPath, 'delete');
    if (params) {
      rb.header('cognitouserid', params.cognitouserid, {});
      rb.header('cognitousergroup', params.cognitousergroup, {});
      rb.path('id', params.id, {});
      rb.query('question_ids', params.question_ids, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `removeQuestionFromEvent$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  removeQuestionFromEvent(params: {
    cognitouserid?: string;
    cognitousergroup?: string;
    id: number;
    question_ids?: string;
    context?: HttpContext
  }
): Observable<void> {

    return this.removeQuestionFromEvent$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation getLiveEventStats
   */
  static readonly GetLiveEventStatsPath = '/live_events/{id}/questions/stats';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getLiveEventStats()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLiveEventStats$Response(params: {
    cognitouserid?: string;
    cognitousergroup?: string;
    id: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<QuestionStatsModel>>> {

    const rb = new RequestBuilder(this.rootUrl, LiveEventsService.GetLiveEventStatsPath, 'get');
    if (params) {
      rb.header('cognitouserid', params.cognitouserid, {});
      rb.header('cognitousergroup', params.cognitousergroup, {});
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<QuestionStatsModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getLiveEventStats$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLiveEventStats(params: {
    cognitouserid?: string;
    cognitousergroup?: string;
    id: number;
    context?: HttpContext
  }
): Observable<Array<QuestionStatsModel>> {

    return this.getLiveEventStats$Response(params).pipe(
      map((r: StrictHttpResponse<Array<QuestionStatsModel>>) => r.body as Array<QuestionStatsModel>)
    );
  }

  /**
   * Path part for operation getQuestionInEvent
   */
  static readonly GetQuestionInEventPath = '/live_events/{id}/questions/{question_id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getQuestionInEvent()` instead.
   *
   * This method doesn't expect any request body.
   */
  getQuestionInEvent$Response(params: {
    cognitouserid?: string;
    cognitousergroup?: string;
    id: number;
    question_id: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<QuestionModel>> {

    const rb = new RequestBuilder(this.rootUrl, LiveEventsService.GetQuestionInEventPath, 'get');
    if (params) {
      rb.header('cognitouserid', params.cognitouserid, {});
      rb.header('cognitousergroup', params.cognitousergroup, {});
      rb.path('id', params.id, {});
      rb.path('question_id', params.question_id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<QuestionModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getQuestionInEvent$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getQuestionInEvent(params: {
    cognitouserid?: string;
    cognitousergroup?: string;
    id: number;
    question_id: number;
    context?: HttpContext
  }
): Observable<QuestionModel> {

    return this.getQuestionInEvent$Response(params).pipe(
      map((r: StrictHttpResponse<QuestionModel>) => r.body as QuestionModel)
    );
  }

  /**
   * Path part for operation repositionQuestionInEvent
   */
  static readonly RepositionQuestionInEventPath = '/live_events/{id}/questions/{question_id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `repositionQuestionInEvent()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  repositionQuestionInEvent$Response(params: {
    cognitouserid?: string;
    cognitousergroup?: string;
    id: number;
    question_id: number;
    context?: HttpContext
    body: PositionChangeModel
  }
): Observable<StrictHttpResponse<QuestionModel>> {

    const rb = new RequestBuilder(this.rootUrl, LiveEventsService.RepositionQuestionInEventPath, 'patch');
    if (params) {
      rb.header('cognitouserid', params.cognitouserid, {});
      rb.header('cognitousergroup', params.cognitousergroup, {});
      rb.path('id', params.id, {});
      rb.path('question_id', params.question_id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<QuestionModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `repositionQuestionInEvent$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  repositionQuestionInEvent(params: {
    cognitouserid?: string;
    cognitousergroup?: string;
    id: number;
    question_id: number;
    context?: HttpContext
    body: PositionChangeModel
  }
): Observable<QuestionModel> {

    return this.repositionQuestionInEvent$Response(params).pipe(
      map((r: StrictHttpResponse<QuestionModel>) => r.body as QuestionModel)
    );
  }

  /**
   * Path part for operation getQuestionsStatisticsInLiveEvent
   */
  static readonly GetQuestionsStatisticsInLiveEventPath = '/live_events/{id}/questions/{question_id}/stats';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getQuestionsStatisticsInLiveEvent()` instead.
   *
   * This method doesn't expect any request body.
   */
  getQuestionsStatisticsInLiveEvent$Response(params: {
    cognitouserid?: string;
    cognitousergroup?: string;
    id: number;
    question_id: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<QuestionStatsModel>> {

    const rb = new RequestBuilder(this.rootUrl, LiveEventsService.GetQuestionsStatisticsInLiveEventPath, 'get');
    if (params) {
      rb.header('cognitouserid', params.cognitouserid, {});
      rb.header('cognitousergroup', params.cognitousergroup, {});
      rb.path('id', params.id, {});
      rb.path('question_id', params.question_id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<QuestionStatsModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getQuestionsStatisticsInLiveEvent$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getQuestionsStatisticsInLiveEvent(params: {
    cognitouserid?: string;
    cognitousergroup?: string;
    id: number;
    question_id: number;
    context?: HttpContext
  }
): Observable<QuestionStatsModel> {

    return this.getQuestionsStatisticsInLiveEvent$Response(params).pipe(
      map((r: StrictHttpResponse<QuestionStatsModel>) => r.body as QuestionStatsModel)
    );
  }

  /**
   * Path part for operation getLiveEventHighscore
   */
  static readonly GetLiveEventHighscorePath = '/live_events/{id}/highscore';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getLiveEventHighscore()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLiveEventHighscore$Response(params: {
    cognitouserid?: string;
    cognitousergroup?: string;
    id: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<HighScoreEntryModel>>> {

    const rb = new RequestBuilder(this.rootUrl, LiveEventsService.GetLiveEventHighscorePath, 'get');
    if (params) {
      rb.header('cognitouserid', params.cognitouserid, {});
      rb.header('cognitousergroup', params.cognitousergroup, {});
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<HighScoreEntryModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getLiveEventHighscore$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLiveEventHighscore(params: {
    cognitouserid?: string;
    cognitousergroup?: string;
    id: number;
    context?: HttpContext
  }
): Observable<Array<HighScoreEntryModel>> {

    return this.getLiveEventHighscore$Response(params).pipe(
      map((r: StrictHttpResponse<Array<HighScoreEntryModel>>) => r.body as Array<HighScoreEntryModel>)
    );
  }

  /**
   * Path part for operation getLiveEventUserStat
   */
  static readonly GetLiveEventUserStatPath = '/live_events/{id}/user/stat';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getLiveEventUserStat()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLiveEventUserStat$Response(params: {
    cognitouserid?: string;
    cognitousergroup?: string;
    id: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<LiveEventUser>>> {

    const rb = new RequestBuilder(this.rootUrl, LiveEventsService.GetLiveEventUserStatPath, 'get');
    if (params) {
      rb.header('cognitouserid', params.cognitouserid, {});
      rb.header('cognitousergroup', params.cognitousergroup, {});
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<LiveEventUser>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getLiveEventUserStat$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLiveEventUserStat(params: {
    cognitouserid?: string;
    cognitousergroup?: string;
    id: number;
    context?: HttpContext
  }
): Observable<Array<LiveEventUser>> {

    return this.getLiveEventUserStat$Response(params).pipe(
      map((r: StrictHttpResponse<Array<LiveEventUser>>) => r.body as Array<LiveEventUser>)
    );
  }

  /**
   * Path part for operation getUsersInLiveEvent
   */
  static readonly GetUsersInLiveEventPath = '/live_events/{id}/users';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getUsersInLiveEvent()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUsersInLiveEvent$Response(params: {
    cognitouserid?: string;
    cognitousergroup?: string;
    id: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<LiveEventUser>>> {

    const rb = new RequestBuilder(this.rootUrl, LiveEventsService.GetUsersInLiveEventPath, 'get');
    if (params) {
      rb.header('cognitouserid', params.cognitouserid, {});
      rb.header('cognitousergroup', params.cognitousergroup, {});
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<LiveEventUser>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getUsersInLiveEvent$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUsersInLiveEvent(params: {
    cognitouserid?: string;
    cognitousergroup?: string;
    id: number;
    context?: HttpContext
  }
): Observable<Array<LiveEventUser>> {

    return this.getUsersInLiveEvent$Response(params).pipe(
      map((r: StrictHttpResponse<Array<LiveEventUser>>) => r.body as Array<LiveEventUser>)
    );
  }

  /**
   * Path part for operation removeUserFromLiveEvent
   */
  static readonly RemoveUserFromLiveEventPath = '/live_events/{id}/users/{user_id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `removeUserFromLiveEvent()` instead.
   *
   * This method doesn't expect any request body.
   */
  removeUserFromLiveEvent$Response(params: {
    cognitouserid?: string;
    cognitousergroup?: string;
    id: number;
    user_id: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<LiveEventUser>>> {

    const rb = new RequestBuilder(this.rootUrl, LiveEventsService.RemoveUserFromLiveEventPath, 'delete');
    if (params) {
      rb.header('cognitouserid', params.cognitouserid, {});
      rb.header('cognitousergroup', params.cognitousergroup, {});
      rb.path('id', params.id, {});
      rb.path('user_id', params.user_id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<LiveEventUser>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `removeUserFromLiveEvent$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  removeUserFromLiveEvent(params: {
    cognitouserid?: string;
    cognitousergroup?: string;
    id: number;
    user_id: string;
    context?: HttpContext
  }
): Observable<Array<LiveEventUser>> {

    return this.removeUserFromLiveEvent$Response(params).pipe(
      map((r: StrictHttpResponse<Array<LiveEventUser>>) => r.body as Array<LiveEventUser>)
    );
  }

}
