import * as cloud from 'd3-cloud';
import * as d3 from 'd3';
import { rgbToHsl } from './helpers';

export interface WordCloudWord {
  text: string;
  size: number;
}

const FONT = 'Lato';

function wordCloud(selector: HTMLDivElement, color?: string) {
  const rect = selector.getBoundingClientRect();
  const WIDTH = rect.width;
  const HEIGHT = rect.height;
  const svg = d3
    .select(selector)
    .append('svg')
    .attr('width', WIDTH)
    .attr('height', HEIGHT)
    .append('g')
    .attr('transform', `translate(${WIDTH / 2},${HEIGHT / 2})`);

  function draw(words) {
    return new Promise<void>((resolve) => {
      if (!words?.length) {
        resolve();
      }
      const cloud = svg.selectAll('g text').data(words, function (d) {
        return d.text;
      });

      cloud
        .enter()
        .append('text')
        .style('font-family', FONT)
        .style('fill', function () {
          const randomColor = Math.floor(Math.random() * 16777215).toString(16);
          const base = rgbToHsl(color ? color : `#${randomColor}`);
          const variation = Math.random() * 0.15;
          return `hsl(${((base.h + variation) * 360).toFixed(
            2
          )}deg, ${(base.s * 100).toFixed(2)}%, ${(base.l * 100).toFixed(2)}%)`;
        })
        .attr('text-anchor', 'middle')
        .attr('dominant-baseline', 'middle')
        .attr('font-weight', '900')
        .text(function (d) {
          return d.text;
        })
        .transition()
        .duration(600)
        .on('end', () => {
          resolve();
        })
        .style('font-size', function (d) {
          return d.size + 'px';
        })
        .attr('transform', function (d) {
          return 'translate(' + [d.x, d.y] + ')rotate(' + d.rotate + ')';
        })
        .style('fill-opacity', 1);

      cloud
        .transition()
        .duration(600)
        .style('font-size', function (d) {
          return d.size + 'px';
        })
        .attr('transform', function (d) {
          return 'translate(' + [d.x, d.y] + ')rotate(' + d.rotate + ')';
        })
        .style('fill-opacity', 1);

      cloud
        .exit()
        .transition()
        .duration(200)
        .style('fill-opacity', 1e-6)
        .attr('font-size', 1)
        .remove();

      if (words?.length) {
        zoomToFitBounds(words, svg, WIDTH, HEIGHT);
      }
    });
  }

  return {
    update: function (words) {
      return new Promise<void>((resolve) => {
        const sizeScale = d3
          .scaleLinear()
          .domain([
            0,
            d3.max(words, function (d) {
              return d.size;
            }),
          ])
          .range([10, 40]);
        svg.selectAll('g text');
        cloud()
          .size([WIDTH, HEIGHT])
          .words(words)
          .padding(10)
          .rotate(function () {
            return ~~(Math.random() * 2) * 90;
          })
          .font(FONT)
          .fontSize(function (d) {
            return sizeScale(d.size);
          })
          .on('end', async (words) => {
            await draw(words);
            resolve();
          })
          .start();
      });
    },
  };
}

function zoomToFitBounds(words, cloud, width, height) {
  const X0 = d3.min(words, function (d) {
      return d.x - d.width / 2;
    }),
    X1 = d3.max(words, function (d) {
      return d.x + d.width / 2;
    });

  const Y0 = d3.min(words, function (d) {
      return d.y - d.height / 2;
    }),
    Y1 = d3.max(words, function (d) {
      return d.y + d.height / 2;
    });

  const scaleX = (X1 - X0) / width;
  const scaleY = (Y1 - Y0) / height;

  const scale = 1 / Math.max(scaleX, scaleY);

  const translateX = width / 2 - X0 * scale - ((X1 - X0) / 2) * scale;
  const translateY = height / 2 - Y0 * scale - ((Y1 - Y0) / 2) * scale;

  cloud.attr(
    'transform',
    'translate(' + translateX + ',' + translateY + ')' + ' scale(' + scale + ')'
  );
}

export default wordCloud;
