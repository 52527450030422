<qa-auth-flow-wrapper>
  <ng-container left>
    <strong>{{ 'register.benefits.header' | translate }}</strong>
    <div class="checklist">
      <div class="entry">
        <mat-icon>check</mat-icon>
        <span>{{ 'register.benefits.part1' | translate }}</span>
      </div>
      <div class="entry">
        <mat-icon>check</mat-icon>
        <span>{{ 'register.benefits.part2' | translate }}</span>
      </div>
      <div class="entry">
        <mat-icon>check</mat-icon>
        <span>{{ 'register.benefits.part3' | translate }}</span>
      </div>
    </div>
    <h2 style="margin-top: 2rem">
      {{ 'register.areStudentTitle' | translate }}
    </h2>
    <p [innerHTML]="'register.areStundentContent' | translate"></p>
    <a [href]="'/'" target="_blank" mat-button color="primary">
      <mat-icon>open_in_new</mat-icon>
      {{ 'register.toPIN' | translate }}
    </a>
    <h2 style="margin-top: 2rem">{{ 'register.helpTitle' | translate }}</h2>
    <a
      href="https://quizacademy.de/registrierung-hilfen/"
      target="_blank"
      mat-button
      color="primary"
    >
      <mat-icon>open_in_new</mat-icon>
      {{ 'register.helpText' | translate }}
    </a>
  </ng-container>
  <ng-container right>
    <form (ngSubmit)="register()" [formGroup]="form" *ngIf="!registered">
      <h1>{{ 'register.title' | translate }}</h1>
      <div class="row">
        <mat-form-field appearance="outline">
          <mat-label> {{ 'register.firstName' | translate }} </mat-label>
          <input formControlName="firstName" matInput type="string" />
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label> {{ 'register.lastName' | translate }} </mat-label>
          <input formControlName="lastName" matInput type="string" />
        </mat-form-field>
      </div>
      <div class="row">
        <mat-form-field
          appearance="outline"
          [class.selected]="!!form.get('organization')?.value"
        >
          <mat-label> {{ 'register.organization' | translate }} </mat-label>
          <input
            formControlName="organizationName"
            matInput
            type="string"
            [matAutocomplete]="organizationAuto"
            [tabindex]="!!form.get('organization')?.value ? -1 : null"
            required
            #orgNameInput
          />
        </mat-form-field>
        <button
          mat-icon-button
          class="remove-org-btn"
          (click)="removeOrganization()"
          *ngIf="!!form.get('organization')?.value"
        >
          <mat-icon>close</mat-icon>
        </button>
        <mat-autocomplete
          #organizationAuto="matAutocomplete"
          [displayWith]="displayFn"
          (optionSelected)="organizationSelected($event.option.value)"
        >
          <mat-option *ngIf="organizationsLoading" class="_no_click_option">
            <mat-spinner diameter="20"></mat-spinner>
          </mat-option>
          <mat-option
            [value]="{ name: form.get('organizationName')?.value }"
            class="organization-autocomplete-option manual"
            *ngIf="
              form.get('organizationName').value?.length &&
              !organizationsLoading
            "
          >
            <div class="image">
              <mat-icon>question_mark</mat-icon>
            </div>
            <div class="info">
              <span class="name">{{ 'register.orgNotFound' | translate }}</span>
              <span class="address">{{
                'register.insertOrgInfo' | translate
              }}</span>
            </div>
          </mat-option>
          <mat-option
            [value]="option"
            class="organization-autocomplete-option"
            *ngFor="let option of filteredOptions | async"
          >
            <div class="image">
              <mat-icon *ngIf="!option.logo_url?.length">business</mat-icon>
              <img *ngIf="!!option.logo_url?.length" [src]="option.logo_url" />
            </div>
            <div class="info">
              <span class="name">{{ option.name }}</span>
              <span class="address"
                >{{ option.address
                }}{{
                  option.city?.length &&
                  option.zipcode?.length &&
                  option.address?.length
                    ? ', '
                    : ''
                }}{{ option.zipcode }} {{ option.city }}</span
              >
            </div>
            <div class="edition" *ngIf="option?.preferred_editions?.length">
              {{ getSearchEdition(option) }}
            </div>
          </mat-option>
        </mat-autocomplete>
      </div>
      <div
        *ngIf="
          form.invalid &&
          form.hasError('mustSelectOrg') &&
          !form.get('organizationName').pristine
        "
        class="org-error"
      >
        <mat-icon>warning</mat-icon
        >{{ 'register.error.mustSelectOrganization' | translate }}
      </div>
      <div class="row" *ngIf="manualOrganizationInput">
        <mat-form-field appearance="outline">
          <mat-label>
            {{ 'register.organizationWebsite' | translate }}
          </mat-label>
          <input formControlName="organizationWebsite" matInput type="string" />
        </mat-form-field>
      </div>
      <div class="row" *ngIf="manualOrganizationInput">
        <mat-form-field appearance="outline">
          <mat-label> {{ 'register.organizationCity' | translate }} </mat-label>
          <input formControlName="organizationCity" matInput type="string" />
        </mat-form-field>
      </div>
      <qa-edition-selector
        [(editionName)]="targetEditionName"
        (editionSelect)="selectEdition($event)"
      ></qa-edition-selector>
      <p class="edition-autoselect-hint" *ngIf="suggestedEditionApplied">
        <mat-icon>info</mat-icon>
        <span>{{ 'register.suggestedEditionHint' | translate }}</span>
      </p>
      <div class="row">
        <mat-form-field
          appearance="outline"
          matTooltip="{{ 'register.emailHint' | translate }}"
        >
          <mat-label> {{ 'register.email' | translate }} </mat-label>
          <input
            formControlName="email"
            matInput
            type="email"
            (keyup)="$event.target.value = $event.target.value.toLowerCase()"
          />
        </mat-form-field>
      </div>
      <div class="row">
        <mat-form-field appearance="outline">
          <mat-label> {{ 'register.repeatEmail' | translate }} </mat-label>
          <input
            formControlName="repeatEmail"
            matInput
            type="email"
            (keyup)="$event.target.value = $event.target.value.toLowerCase()"
            [errorStateMatcher]="mailMatcher"
          />
          <mat-error *ngIf="form.hasError('notSameMail')">
            {{ 'register.error.repeatMail' | translate }}
          </mat-error>
        </mat-form-field>
      </div>
      <div class="row">
        <mat-form-field
          appearance="outline"
          matTooltip="{{ 'register.phoneHint' | translate }}"
        >
          <mat-label> {{ 'register.phone' | translate }} </mat-label>
          <input formControlName="phone" matInput type="text" />
        </mat-form-field>
      </div>
      <div
        class="row"
        [style.marginBottom]="
          form.get('password').invalid && form.get('password').touched
            ? '35px'
            : '15px'
        "
      >
        <mat-form-field appearance="outline">
          <mat-label> {{ 'register.password' | translate }} </mat-label>
          <input formControlName="password" matInput type="password" />
          <mat-hint style="margin-top: 5px">
            <mat-icon
              *ngIf="
                !form.get('password').invalid && form.get('password').touched
              "
              >check</mat-icon
            >{{ 'newPassword.hint' | translate }}
          </mat-hint>
          <mat-error
            *ngIf="form.get('password').invalid && form.get('password').touched"
            style="margin-top: 5px"
          >
            {{ 'newPassword.error' | translate }}
            {{ 'newPassword.hint' | translate }}
          </mat-error>
        </mat-form-field>
      </div>
      <div class="row">
        <mat-form-field appearance="outline">
          <mat-label> {{ 'register.repeatPassword' | translate }} </mat-label>
          <input
            formControlName="repeatPassword"
            matInput
            type="password"
            [errorStateMatcher]="passwordMatcher"
          />
          <mat-error *ngIf="form.hasError('notSamePassword')">
            {{ 'register.error.repeatPassword' | translate }}
          </mat-error>
        </mat-form-field>
      </div>
      <span
        class="data-protection-hint"
        [innerHTML]="'register.dataProtectionHint' | translate"
      ></span>
      <div
        *ngIf="errorMessage"
        [innerHTML]="errorMessage"
        class="mat-error error-box"
      ></div>
      <div class="actions">
        <qa-button
          [disabled]="form.invalid || form.disabled || loadingRegistration"
          [isRaisedButton]="true"
          [label]="'register.button' | translate"
          data-cy="register-submit"
          type="submit"
        ></qa-button>
      </div>
      <p class="login-part">
        <span>{{ 'register.alreadyHaveAnAccount' | translate }}</span>
        <button mat-button color="primary" (click)="toLogin()">
          {{ 'register.logIn' | translate }}
        </button>
      </p>
    </form>
    <div class="success-container" *ngIf="registered">
      <mat-icon>email</mat-icon>
      <h1>{{ 'register.success.title' | translate }}</h1>
      <p>{{ 'register.success.instructions' | translate }}</p>
      <hr />
      <p
        class="help-text"
        [innerHTML]="'register.success.noMailReceived' | translate"
      ></p>
      <div class="to-login">
        <p>{{ 'register.success.finishText' | translate }}</p>
        <a color="primary" mat-raised-button routerLink="/login">
          <span>{{ 'register.success.toLogin' | translate }}</span>
        </a>
      </div>
    </div>
  </ng-container>
</qa-auth-flow-wrapper>
