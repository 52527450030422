import { CategoryModel, QuestionModel } from '../api/models';
import {
  UntypedFormArray,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { QuestionType } from '../app.types';
import { filter, startWith } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { ANSWERS_CONTROL_AMOUNT } from '../app-constants';
import * as _ from 'lodash';
import { TreeNode } from '../cms/common/form-field/tree-select.component';

export function convertCategoryTreeToList(
  tree: CategoryModel,
  level = 0
): CategoryModel[] {
  const indentation = new Array(level).fill('—').join('');
  tree.name = `${indentation} ${tree.name}`;
  if (!tree.children) {
    return [tree];
  }
  const convertedChildren = (tree.children as []).reduce((children, child) => {
    return [...children, ...convertCategoryTreeToList(child, level + 1)];
  }, []);
  delete tree.children;
  return [tree, ...convertedChildren];
}

export function convertCategoryTreesToList(
  trees: CategoryModel[]
): CategoryModel[] {
  return trees.reduce((list, tree) => {
    return [...list, ...convertCategoryTreeToList(tree)];
  }, []);
}

function fallbackCopyTextToClipboard(text: string): Promise<void> {
  return new Promise((resolve, reject) => {
    const textArea = document.createElement('textarea');
    textArea.value = text;
    textArea.style.top = '0';
    textArea.style.left = '0';
    textArea.style.position = 'fixed';
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    try {
      document.execCommand('copy')
        ? resolve()
        : reject('Copy command unsuccessful');
    } catch (error) {
      reject(error);
    }
    document.body.removeChild(textArea);
  });
}

export function copyTextToClipboard(text: string): Promise<void> {
  if (!navigator.clipboard) {
    return fallbackCopyTextToClipboard(text);
  }
  return navigator.clipboard.writeText(text);
}

export async function copyImageToClipboard(src: string): Promise<void> {
  const data = await fetch(src);
  const blob = await data.blob();
  return navigator.clipboard.write([
    new ClipboardItem({
      [blob.type]: blob,
    }),
  ]);
}

export function isValidDate(date: any): boolean {
  const d = new Date(date);
  return d.toString() !== 'Invalid Date' && d !== null;
}

export function generatingQuestionForm(
  question: Partial<QuestionModel>,
  form: UntypedFormGroup,
  radioCtrl: UntypedFormControl,
  radioVal: Subscription,
  typeVal: Subscription
) {
  question.type =
    typeof question.type === 'number'
      ? question.type
      : QuestionType.SINGLE_CHOICE;
  form.setValidators((control) => {
    const value: QuestionModel = control.value;
    if (value.type === QuestionType.SINGLE_CHOICE) {
      const hasAtLeastOneRightAnswer = value.answers.some(
        (answer) => answer.is_right
      );
      if (!hasAtLeastOneRightAnswer) {
        return { needsAtLeastOneRightAnswer: null };
      }
    }
    return null;
  });
  // Reset answer form array
  const formArrayAnswers = form.get('answers') as UntypedFormArray;
  formArrayAnswers.clear();
  let answerControlsAmount = ANSWERS_CONTROL_AMOUNT;
  if (question.answers && question.answers.length !== 0) {
    answerControlsAmount = question.answers.length;
  }
  for (
    let eachAnswerControl = 0;
    eachAnswerControl < answerControlsAmount;
    eachAnswerControl++
  ) {
    const answerFormGroup = new UntypedFormGroup({
      is_right: new UntypedFormControl(false),
      text: new UntypedFormControl(),
      id: new UntypedFormControl(),
    });
    (form.get('answers') as UntypedFormArray).push(answerFormGroup);
  }
  form.reset();
  form.patchValue(question);
  if (
    question.type === QuestionType.SINGLE_CHOICE &&
    question.answers &&
    question.answers.length
  ) {
    // Convert all answers is_right to boolean
    (form.get('answers') as UntypedFormArray).controls.forEach((control) => {
      control.patchValue({
        is_right: !!control.value.is_right,
      });
    });
    radioCtrl.setValue(
      question.answers.findIndex((answer) => answer.is_right),
      { emitEvent: false }
    );
  }
  initializeSubscriptions(radioVal, radioCtrl, typeVal, form);
}

function initializeSubscriptions(
  radioValueChanges: Subscription,
  radioRightAnswerControl: UntypedFormControl,
  typeValueChanges: Subscription,
  form: UntypedFormGroup
) {
  radioValueChanges =
    radioValueChanges ||
    radioRightAnswerControl.valueChanges
      .pipe(filter((index) => Number.isInteger(index) && index >= 0))
      .subscribe({
        next: (index: number) => {
          const radioControls = (form.get('answers') as UntypedFormArray)
            .controls;
          const rightControlIndex = radioControls.findIndex(
            (control) => control.get('is_right').value
          );
          const rightControlChanged = rightControlIndex !== index;
          if (rightControlChanged) {
            radioControls.forEach((control, i) => {
              control.get('is_right').setValue(index === i);
              if (rightControlIndex !== -1) {
                control.get('is_right').markAsDirty();
                control.get('is_right').markAsTouched();
              }
            });
          }
        },
      });

  typeValueChanges =
    typeValueChanges ||
    form
      .get('type')
      .valueChanges.pipe(startWith(form.value.type))
      .subscribe({
        next: (newType: number) => {
          const answersControls = (form.get('answers') as UntypedFormArray)
            .controls;
          if (
            newType === QuestionType.TRUE_FALSE ||
            newType === QuestionType.FREETEXT
          ) {
            answersControls.forEach((control) => {
              control.get('text').clearValidators();
              control.get('text').updateValueAndValidity();
              control.get('is_right').clearValidators();
              control.get('is_right').updateValueAndValidity();
            });
          } else if (newType === QuestionType.MULTIPLE_CHOICE) {
            answersControls.forEach((control) => {
              control.get('text').setValidators([Validators.required]);
              control.get('text').updateValueAndValidity();
              control.get('is_right').clearValidators();
              control.get('is_right').updateValueAndValidity();
            });
          } else if (newType === QuestionType.SINGLE_CHOICE) {
            answersControls.forEach((control) => {
              control.get('text').setValidators([Validators.required]);
              control.get('text').updateValueAndValidity();
              control.get('is_right').setValidators([Validators.required]);
              control.get('is_right').updateValueAndValidity();
              radioRightAnswerControl.updateValueAndValidity();
            });
            const noRightAnswer = answersControls.reduce(
              (total, current) => total && !current.get('is_right').value,
              true
            );
            if (noRightAnswer && !form.value.id) {
              radioRightAnswerControl.setValue(0, { emitEvent: false });
              if (answersControls && answersControls.length) {
                answersControls[0]
                  .get('is_right')
                  .setValue(true, { emitEvent: false });
                answersControls[1]
                  .get('is_right')
                  .setValue(false, { emitEvent: false });
              }
            }
          }
        },
      });
}

export function clearExamState(pin) {
  if (pin) {
    let exams;
    try {
      if (sessionStorage.getItem('exams')) {
        exams = JSON.parse(sessionStorage.getItem('exams'));
        delete exams[pin];
        sessionStorage.setItem('exams', JSON.stringify(exams));
      }
    } catch {}
  }
}

/**
 * Shuffles an answer model array according to some context-dependent seed.
 * @param answers The answers array to shuffle
 * @param seed The seed by which to shuffle the answers. Should be dependent on the current context
 * (e.g. live event id, exam id, etc.)
 * @returns The shuffled array
 */
export function shuffleAnswers<T>(answers: T[], seed?: number): T[] {
  if (!answers?.length) {
    return [];
  }
  if (_.isNil(seed)) {
    return _.shuffle(answers);
  }
  const offset = seed % answers.length;
  // Shallow copy original array
  const buffer = [];
  for (let i = 0; i < answers.length; i++) {
    const newPosition = (offset + i) % answers.length;
    buffer[newPosition] = answers[i];
  }
  return buffer;
}

export function settingData(jsonArray) {
  return jsonArray
    .map((row) => {
      return Object.keys(row).reduce((prev, cur) => {
        if (cur === 'number') {
          return prev;
        }
        prev[cur.toLowerCase()] = row[cur];
        return prev;
      }, {});
    })
    .map((row) => {
      const answers = Object.keys(row)
        .filter((key) => key.match(/answer[\d]/gm) !== null)
        .reduce((prev, cur) => {
          if ((row[cur] && row[cur].trim()) || row.type === 'True/False') {
            prev.push({
              text: row[cur],
              is_right: row[cur.slice(0, -1) + 'result' + cur.slice(-1)],
            });
          }
          delete row[cur];
          delete row[cur.slice(0, -1) + 'result' + cur.slice(-1)];
          return prev;
        }, []);
      row['answers'] = answers;
      return row;
    })
    .map((data) => {
      data['tags'] = data['tags']
        ? data['tags'].split(':').map((tag) => ({ name: tag.trim() }))
        : [];
      if (data['media_id'] && data['media_url']) {
        data['media'] = {
          id: Number(data['media_id']),
          url: data['media_url'],
          file_ext: data['fileextension'],
          uuid: data['uuid'],
        };
      }
      delete data['media_id'];
      delete data['media_url'];
      delete data['fileextension'];
      delete data['uuid'];
      return data;
    });
}

export function settingAnswers(questionsArr) {
  if (questionsArr) {
    questionsArr.forEach((element) => {
      if (element.answers && element.answers.length) {
        element.answers = element.answers.filter((x) => x.text && x.is_right);
        element.answers.forEach((answer) => {
          answer.is_right =
            answer.is_right.toLowerCase() === 'correct' ? true : false;
        });
      } else {
        element.is_right =
          element.is_right.toLowerCase() === 'correct' ? true : false;
      }
    });
  }
}

export const QuestionColumnNames: string[] = [
  'number',
  'text',
  'type',
  'answer1',
  'answerResult1',
  'answer2',
  'answerResult2',
  'answer3',
  'answerResult3',
  'answer4',
  'answerResult4',
  'answer5',
  'answerResult5',
  'answer6',
  'answerResult6',
  'explanation',
  'weblink',
  'source',
  'media_id',
  'media_url',
  'fileExtension',
  'uuid',
  'tags',
];

export const QuestionTopRow =
  'Text;Type;Answer1;answerResult1;Answer2;answerResult2;Answer3;answerResult3;Answer4;answerResult4;' +
  'Answer5;answerResult5;Answer6;answerResult6;Explanation;Weblink;Source;media_id;media_url;fileExtension;uuid;Tags';

export const FlashColumnNames: string[] = [
  'number',
  'text',
  'answer',
  'explanation',
  'weblink',
  'source',
  'media_id',
  'media_url',
  'fileExtension',
  'uuid',
  'tags',
];

export const FlashTopRow =
  'Text;Answer;Explanation;Weblink;Source;media_id;media_url;fileExtension;uuid;Tags';

export const findNested = (pred) => (xs) => {
  for (let x of xs) {
    if (pred(x)) {
      return [x];
    }
    const c = findNested(pred)(x.children || []);
    if (c) return [{ ...x, children: c }];
  }
};

export const findNestedByName = (target) =>
  findNested((x) => x.name.toLowerCase().includes(target.toLowerCase()));

export interface SelectGroup {
  name: string;
  id: string;
  group: Array<{
    name: string;
    value: any;
    slug: string;
  }>;
}

export function parseCategoryTree(categories: CategoryModel[]): TreeNode[] {
  return categories.map((cat) => {
    return {
      id: cat.id,
      name: cat.name,
      icon: cat.icon_code,
      value: { id: cat.id, name: cat.name },
      children: parseCategoryTree(cat.children as CategoryModel[]).sort(
        (a, b) => {
          return a.id - b.id;
        }
      ),
    };
  });
}
