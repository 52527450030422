import { Component, ElementRef, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { UsageStatistics } from 'src/app/api/models';
import { CoursesService, StatsService } from 'src/app/api/services';
import { DateRange } from 'src/app/app.types';
import { DateRangePickerService } from '../common/form-field/date-range-picker.service';
import { TranslateService } from '@ngx-translate/core';
import * as dayjs from 'dayjs';
import * as utc from 'dayjs/plugin/utc';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'qa-usage-statistics',
  templateUrl: './usage-statistics.component.html',
  styleUrls: ['./usage-statistics.component.scss'],
})
export class UsageStatisticsComponent implements OnInit, OnDestroy {
  @Input() courseId: number;
  @Input() global = false;
  chartData: any;
  chartOptions: {};
  dateRangeSubscription: Subscription;
  loading = false;

  constructor(
    private stats: StatsService,
    private courses: CoursesService,
    private dateRangePickerService: DateRangePickerService,
    elementRef: ElementRef<HTMLElement>,
    private translator: TranslateService
  ) {
    elementRef.nativeElement.classList.add('qa-usage-statistics');
    dayjs.extend(utc);
  }

  ngOnInit() {
    this.dateRangeSubscription =
      this.dateRangePickerService.dateRange.subscribe(async (dateRange) => {
        this.loading = true;
        let stats: UsageStatistics[];
        if (this.courseId) {
          stats = await this.courses
            .getCourseUsageStatistics({
              id: this.courseId,
              end: dateRange?.to
                ? dayjs.utc(dateRange.to).format('YYYY-MM-DD') + 'T23:59:59'
                : null,
              start: dateRange?.from
                ? dayjs.utc(dateRange.from).utc().format('YYYY-MM-DD') +
                  'T00:00:00'
                : null,
            })
            .toPromise();
        } else {
          stats = await this.stats
            .statsControllerGetStatsUsage({
              global: this.global,
              start: dayjs(dateRange.from).format('YYYY-MM-DD') + 'T00:00:00',
              end: dayjs(dateRange.to).format('YYYY-MM-DD') + 'T23:59:59',
            })
            .toPromise();
        }
        await this.updateChart(stats, dateRange);
        this.loading = false;
      });
  }

  ngOnDestroy() {
    this.dateRangeSubscription.unsubscribe();
  }

  async updateChart(statistics: UsageStatistics[], dateRange: DateRange) {
    const fromDate = dayjs(dateRange.from);
    const toDate = dayjs(dateRange.to).add(1, 'days');
    const table = {};
    const difference = toDate.diff(fromDate, 'days');
    for (let i = 0; i < difference; i++) {
      const date = fromDate.clone().add(i, 'days').format('YYYY-MM-DD');
      table[date] = 0;
    }
    statistics.forEach((row: UsageStatistics) => {
      const date = dayjs.utc(row.date).format('YYYY-MM-DD');
      table[date] = row.num_played;
    });
    const rows = Object.keys(table).map((date) => {
      return {
        x: dayjs(date),
        y: table[date],
      };
    });

    this.chartOptions = {
      legend: { display: false },
      scales: {
        x: {
          ticks: {
            autoSkip: true,
            maxRotation: 0,
            minRotation: 0,
            maxTicksLimit: 11,
          },
          grid: {
            display: false,
            z: -1,
          },
        },
        y: {
          scaleLabel: {
            display: false,
            labelString: this.translator.instant('statistics.graph.title'),
          },
          ticks: {
            min: 0,
            precision: 0,
            beginAtZero: true,
          },
          gridLines: {
            drawBorder: false,
          },
        },
      },
      maintainAspectRatio: false,
    };
    this.chartData = {
      labels: rows.map((r) => this.formatDate(r.x)),
      datasets: [
        {
          label: this.translator.instant('statistics.graph.title'),
          backgroundColor: environment.colors.primary,
          hoverBackgroundColor: environment.colors.primaryLight,
          data: rows.map((r) => r.y),
        },
      ],
    };
  }

  private formatDate(date: dayjs.Dayjs): string {
    return dayjs(date || '01.01.1970').format('DD.MM.YYYY');
  }
}
