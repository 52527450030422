import { Component, Input, ElementRef } from '@angular/core';
import { Store } from '@ngrx/store';
import { takeUntil } from 'rxjs/operators';
import { Action, AppState } from '../app.types';
import { DestroyNotifier } from '../destroy-notifier';

@Component({
  selector: 'qa-action',
  templateUrl: './action.component.html',
  styleUrls: ['./action.component.scss'],
})
export class ActionComponent extends DestroyNotifier {
  @Input() config: Action;
  state: AppState;

  constructor(elementRef: ElementRef<HTMLElement>, store: Store<AppState>) {
    super();
    elementRef.nativeElement.classList.add('qa-action');
    store
      .pipe(takeUntil(this.destroy$))
      .subscribe((state) => (this.state = state));
  }
}
