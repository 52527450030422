import { Component } from '@angular/core';

@Component({
  selector: 'qa-find-out-course',
  templateUrl: './find-out-course.component.html',
  styleUrls: ['./find-out-course.component.scss'],
})
export class FindOutCourseComponent {
  constructor() {}
}
