import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Exam, LiveEvent, QuestionModel, QuizModel } from 'src/app/api/models';
import {
  BooksService,
  CoursesService,
  ExamsService,
  LiveEventsService,
  QuizzesService,
} from 'src/app/api/services';
import { PanelService } from '../panel.service';
import { Panel, PanelEntityType } from '../panel.types';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from 'src/app/auth/auth.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { forkJoin, of } from 'rxjs';
import { ImportEntity } from '../../list/list.types';
import { UserJourneyService } from '../../pages/onboarding-steps/user-journey.service';
import { MatDialog } from '@angular/material/dialog';
import {
  ConfirmConvertFromQuizComponent,
  ShowInfoItem,
} from '../../common/dialogs/confirm-convert-from-quiz/confirm-convert-from-quiz.component';
import { QuizEditConfigService } from '../../edit/configs/quiz.service';
import { settingAnswers, settingData } from 'src/app/utility/app.utils';
import { XLSXReader } from '../../common/import-helpers/xlsxReader';
import * as _ from 'lodash';

export const getQuizImports = (
  translator: TranslateService,
  panelService: PanelService,
  panel: Panel<QuizModel>,
  courseContext = false
): ImportEntity<QuizModel>[] =>
  [
    courseContext
      ? {
          title: translator.instant('content.quiz.addTitle.item2'),
          description: translator.instant('content.quiz.description.item2'),
          action: {
            icon: 'move_to_inbox',
            click: () => panelService.importItems(panel),
            text: translator.instant('content.quiz.addMenu.item2'),
          },
        }
      : null,
    {
      title: translator.instant('content.quiz.addTitle.item3'),
      description: translator.instant('content.quiz.description.item3'),
      action: {
        icon: 'code',
        click: () => panelService.importItemAsJSON(panel),
        text: translator.instant('content.quiz.addMenu.item3'),
      },
    },
    {
      title: translator.instant('content.quiz.addTitle.item4'),
      description: translator.instant('content.quiz.description.item4'),
      action: {
        icon: 'code',
        click: () => panelService.importItemAsMoodleXML(panel),
        text: translator.instant('content.quiz.addMenu.item4'),
      },
    },
    courseContext
      ? {
          title: translator.instant('content.quiz.importfromKahootTitle'),
          description: translator.instant('content.quiz.ImportFromKahootDesc'),
          action: {
            text: translator.instant('content.quiz.ImportFromKahoot'),
            icon: 'description',
            click: () => {},
          },
        }
      : null,
  ].filter((n) => n);

@Injectable()
export class QuizPanel extends Panel<QuizModel> {
  isBook = false;

  constructor(
    private courses: CoursesService,
    private books: BooksService,
    private panelService: PanelService,
    private quizzes: QuizzesService,
    private exams: ExamsService,
    private router: Router,
    private translator: TranslateService,
    private auth: AuthService,
    private matSnackbar: MatSnackBar,
    private events: LiveEventsService,
    private userJourney: UserJourneyService,
    private matDialog: MatDialog
  ) {
    super();
    this.initializePanel();
  }

  initializePanel() {
    const imports = getQuizImports(
      this.translator,
      this.panelService,
      this,
      true
    );
    imports[imports.length - 1].action.click = () => this.importFromKahoot();
    imports[imports.length - 1].extra = {
      name: this.translator.instant('content.quiz.Extra'),
      type: 'link',
      icon: 'keyboard_arrow_right',
      click: () => {
        window.open(
          'https://quizacademy.de/tipps/kahoot-inhalte-importieren/',
          '_blank'
        );
      },
    };
    this.isBook =
      location?.pathname?.split('/')?.[3] === 'book' ||
      this.router.url.indexOf('/book') !== -1;
    super.init({
      addMenuItems: [
        {
          icon: 'add',
          onClick: () => this.panelService.editItem(this),
          text: this.translator.instant('content.quiz.addMenu.item1'),
        },
      ],
      imports: imports,
      childTypes: [PanelEntityType.Question],
      changeItemPosition: (quizId, courseId, position) => {
        const query = {
          id: courseId,
          quiz_id: quizId,
          body: { position },
        };
        return this.isBook
          ? this.books.repositionQuizInBook(query)
          : this.courses.repositionQuizInCourse(query);
      },
      changeItemStatus: (quizId, courseId, active) => {
        const query = {
          id: courseId,
          quiz_id: quizId,
          body: { active },
        };
        return this.isBook
          ? this.books.repositionQuizInBook(query)
          : this.courses.repositionQuizInCourse(query);
      },
      createItemsFn: (items, parentId) => {
        if (!parentId) {
          return forkJoin(
            items.map((item) =>
              this.quizzes.createQuiz({
                body: item,
              })
            )
          );
        } else {
          const query = {
            id: parentId,
            body: items,
            copyQuestions: false,
          };
          return this.isBook
            ? this.books.copyQuizInBook(query)
            : this.courses.copyQuizToCourse(query);
        }
      },
      deleteItem: (quizId) => this.quizzes.deleteQuiz({ id: quizId }),
      editConfigService: QuizEditConfigService,
      editConfigServiceParams: (_, courseId) => ({ courseId }),
      editDialogInput: (quiz, courseId) => ({ quiz, courseId }),
      fetchItem: (id) => this.quizzes.getQuizById({ id }),
      fetchItems: (courseId) => {
        if (courseId && !_.isNaN(courseId)) {
          return this.isBook
            ? this.books.getQuizForBook({ id: courseId })
            : this.courses.getQuizInCourse({ id: courseId });
        } else {
          return of(null);
        }
      },
      headerIcon: 'library_books',
      isItemActive: (quiz) => quiz.active,
      activateItemAction: {
        icon: 'visibility',
        label: this.translator.instant('content.quiz.actionMenu.item1'),
        onClick: (quiz) =>
          this.panelService.changeItemStatus(this, quiz.id, !quiz.active),
      },
      itemOnHoverActions: [
        {
          icon: 'edit',
          hidden: () => true,
          label: this.translator.instant('common.tooltip.edit'),
          disabled: (quiz) => quiz['isImported'],
          tooltip: (quiz) =>
            quiz['isImported']
              ? this.translator.instant('content.quiz.notYourQuiz')
              : null,
          onClick: (quiz) => this.panelService.editItem(this, quiz),
        },
        {
          icon: 'timeline',
          label: this.translator.instant('content.quiz.actionMenu.item3'),
          onClick: (quiz) => {
            this.panelService.addParam(PanelEntityType.Quiz, quiz.id, {
              showStatistics: true,
            });
          },
        },
      ],
      itemActions: [
        {
          icon: 'code',
          label: this.translator.instant('content.quiz.actionMenu.item4'),
          onClick: (quiz) => this.panelService.embedItem(this, quiz),
        },
        {
          icon: 'play_arrow',
          label: this.translator.instant('content.quiz.actionMenu.item5'),
          onClick: (quiz) => {
            const dialogRef = this.matDialog.open<
              ConfirmConvertFromQuizComponent,
              ShowInfoItem
            >(ConfirmConvertFromQuizComponent, {
              data: {
                modelTitle: this.translator.instant(
                  'course.confirmAction.convertQuizToEvent'
                ),
                modelInfo: this.translator.instant(
                  'course.confirmAction.convertQuizToEventBottomText'
                ),
                modelType: 'Course',
                confirmButtonText: this.translator.instant(
                  'course.confirmAction.convertToEvent'
                ),
              },
              width: '576px',
              height: 'auto',
              autoFocus: false,
              panelClass: 'confirm-convert-quiz-modal',
            });
            dialogRef.afterClosed().subscribe((resp) => {
              if (resp) {
                const courseId = this.panelService.getParentItemId(
                  PanelEntityType.Quiz
                );
                this.convertQuizToEvent(
                  quiz,
                  this.quizzes,
                  this.events,
                  resp.convert_from_quiz ? courseId : null,
                  this.isBook
                );
              }
            });
          },
        },
        ...(this.isBook
          ? []
          : [
              {
                icon: 'transform',
                label: this.translator.instant(
                  'content.quiz.actionMenu.item10'
                ),
                onClick: (quiz) => {
                  const dialogRef = this.matDialog.open<
                    ConfirmConvertFromQuizComponent,
                    ShowInfoItem
                  >(ConfirmConvertFromQuizComponent, {
                    data: {
                      modelTitle: this.translator.instant(
                        'course.confirmAction.convertQuizToExam'
                      ),
                      modelInfo: this.translator.instant(
                        'course.confirmAction.convertQuizToExamBottomText'
                      ),
                      modelType: 'Course',
                      confirmButtonText: this.translator.instant(
                        'course.confirmAction.convertToExam'
                      ),
                    },
                    width: '576px',
                    height: 'auto',
                    autoFocus: false,
                    panelClass: 'confirm-convert-quiz-modal',
                  });
                  dialogRef.afterClosed().subscribe((resp) => {
                    if (resp) {
                      this.convertToExam(
                        quiz,
                        this.courses,
                        this.panelService,
                        this.quizzes,
                        this.exams,
                        this.matSnackbar,
                        this.translator,
                        this.router,
                        resp.convert_from_quiz
                      );
                    }
                  });
                },
              },
            ]),
        {
          icon: 'description',
          label: this.translator.instant('content.quiz.actionMenu.item9'),
          onClick: (item) => this.panelService.exportItemAsCSV(this, item.id),
        },
        {
          icon: 'save_alt',
          label: this.translator.instant('content.quiz.actionMenu.item6'),
          onClick: (item) => this.panelService.exportItemAsJSON(this, item.id),
        },
        {
          icon: 'link_off',
          label: this.isBook
            ? this.translator.instant('content.quiz.actionMenu.item7Book')
            : this.translator.instant('content.quiz.actionMenu.item7'),
          onClick: (quiz) => this.panelService.unlinkItems(this, quiz.id),
        },
        {
          icon: 'delete',
          label: this.translator.instant('content.quiz.actionMenu.item8'),
          onClick: (quiz) => this.panelService.deleteItem(this, quiz.id),
          disabled: (quiz) =>
            quiz.created_by !== this.auth.currentUserInfo$.value.sub,
          tooltip: (quiz) => {
            if (quiz.created_by !== this.auth.currentUserInfo$.value.sub) {
              return this.translator.instant('content.quiz.notYourQuiz');
            } else {
              return null;
            }
          },
        },
      ],
      itemTextProperty: 'name',
      parentKey: 'quizzes',
      parentType: PanelEntityType.Course,
      selectedItemActions: [
        {
          icon: 'link_off',
          label: this.isBook
            ? this.translator.instant('content.quiz.selectedItem.labelBook')
            : this.translator.instant('content.quiz.selectedItem.label'),
          onClick: (selectedItemIds) =>
            this.panelService.unlinkItems(this, selectedItemIds),
        },
      ],
      translations: {
        addItem: this.translator.instant('content.quiz.tooltip'),
        changeItemStatusDisabled: this.translator.instant(
          'content.quiz.changeItem.statusDisabled'
        ),
        changeItemStatusEnabled: this.translator.instant(
          'content.quiz.changeItem.statusEnabled'
        ),
        deleteItemError: this.translator.instant(
          'content.quiz.deleteItem.error'
        ),
        deleteItemSuccess: this.translator.instant(
          'content.quiz.deleteItem.success'
        ),
        deleteItemText: this.translator.instant('content.quiz.deleteItem.text'),
        deleteItemTitle: this.translator.instant(
          'content.quiz.deleteItem.title'
        ),
        header: 'Quiz',
        unlinkItemConfirmText: this.isBook
          ? this.translator.instant('content.quiz.unlinkItem.textBook')
          : this.translator.instant('content.quiz.unlinkItem.text'),
        unlinkItemConfirmTitle: this.isBook
          ? this.translator.instant('content.quiz.unlinkItem.titleBook')
          : this.translator.instant('content.quiz.unlinkItem.title'),
        unlinkItemError: this.isBook
          ? this.translator.instant('content.quiz.unlinkItem.errorBook')
          : this.translator.instant('content.quiz.unlinkItem.error'),
        unlinkItemSuccess: this.isBook
          ? this.translator.instant('content.quiz.unlinkItem.successBook')
          : this.translator.instant('content.quiz.unlinkItem.success'),
      },
      type: PanelEntityType.Quiz,
      unlinkItems: (quizIds, courseId) => {
        const query = {
          id: courseId,
          quiz_ids: quizIds.join(','),
        };
        return this.isBook
          ? this.books.removeQuizFromBook(query)
          : this.courses.removeQuizFromCourse(query);
      },
    });
  }

  async importFromKahoot() {
    const excelFile = await this.panelService.selectExcelFileAsBinary();
    const file = XLSXReader.excelToArray(excelFile);
    const name = file[0][0];
    if (!name || !name.trim()) {
      this.matSnackbar.open(
        this.translator.instant('content.quiz.importFromExcel.text1')
      );
      return;
    }
    let quiz;
    const snackbar = this.matSnackbar.open(
      this.translator.instant('content.quiz.importFromExcel.text2')
    );
    const questions = this.panelService.importQuestionsFromKahoot(excelFile);
    let questionArr = settingData(questions);
    settingAnswers(questionArr);
    questionArr.forEach((question) => {
      if (question['type'] === 'True/False') {
        question['type'] = 1;
        if (question['answers'].length && question['answers'][0]['is_right']) {
          question['is_right'] = question['answers'][0]['is_right'];
        }
        delete question['answers'];
      } else if (question['type'] === 'Multiple Choice') {
        question['type'] = 0;
      } else if (question['type'] === 'Single Choice') {
        question['type'] = 2;
      }
    });
    questionArr = questionArr.filter(
      (q) => q.type === 1 || (q.type !== 1 && q.answers?.length)
    );
    if (name) {
      const parentId =
        this.panelService.getParentItemId(PanelEntityType.Quiz) ?? null;
      const query = {
        id: parentId,
        body: [{ name: name } as QuizModel],
        copyQuestions: false,
      };

      quiz = await this.courses.copyQuizToCourse(query).toPromise();

      this.matSnackbar.open(
        this.translator.instant('content.quiz.importFromExcel.text3')
      );
      await this.quizzes
        .copyQuestionToQuiz({
          id: quiz[0].id,
          body: questionArr,
        })
        .toPromise();
      snackbar.dismiss();
      this.matSnackbar.open(
        this.translator.instant('content.quiz.importFromExcel.text4')
      );
      this.matDialog.closeAll();
      // this.router.navigate(['cms', 'content', quiz.id, 'questions']);
    }
  }

  async convertQuizToEvent(
    quiz: QuizModel,
    quizzesService: QuizzesService,
    eventsService: LiveEventsService,
    courseId?: number,
    isBook?: boolean
  ) {
    const questions = await quizzesService
      .getQuestionsForQuiz({
        id: quiz.id,
      })
      .toPromise();
    const event = await eventsService
      .createLiveEvent({
        body: {
          name: quiz.name,
        } as LiveEvent,
      })
      .toPromise();
    if (courseId) {
      const query = {
        id: courseId,
        body: event,
      };
      if (isBook) {
        await this.books.addEventToBook(query).toPromise();
      } else {
        await this.courses.addEventToCourse(query).toPromise();
      }
    }
    await eventsService
      .linkQuestionToEvent({
        id: event.id,
        body: questions.map((q) => ({ id: q.id } as QuestionModel)),
      })
      .toPromise();
    this.matSnackbar.open(
      this.translator.instant('content.quiz.convertedEvent')
    );
    if (courseId) {
      const panel = this.panelService.getPanel(PanelEntityType.Event);
      this.panelService.fetchItems(panel);
      panel.open$.next();
      this.panelService.addParam(PanelEntityType.Event, event.id);
    } else {
      this.router.navigate(['/cms/live-events', event.id]);
    }
  }

  convertToExam(
    quiz: QuizModel,
    courses: CoursesService,
    panelService: PanelService,
    quizes: QuizzesService,
    exams: ExamsService,
    matSnackBar,
    translator,
    router: Router,
    course: boolean
  ) {
    matSnackBar.open(translator.instant('content.quiz.convertingExam'));
    const courseId = panelService.getParentItemId(PanelEntityType.Quiz);
    const exam = {
      name: quiz.name,
      scoring_schema: 'DEFAULT',
    };
    let req$: any;
    if (course) {
      req$ = courses.createExamInCourse({
        id: courseId,
        body: exam as Exam,
      });
    } else {
      req$ = exams.createExam({
        body: exam as Exam,
      });
    }
    req$.subscribe(
      (response: Exam) => {
        this.userJourney.set('createdExam');
        this.linkQuestions(
          quizes,
          quiz.id,
          response.id,
          exams,
          matSnackBar,
          translator,
          panelService,
          router,
          course
        );
      },
      () => {
        matSnackBar.open(translator.instant('content.quiz.convertExamError'));
      }
    );
  }

  linkQuestions(
    quizes: QuizzesService,
    quizId: number,
    examId: number,
    exams: ExamsService,
    matSnackBar,
    translator,
    panelService,
    router: Router,
    course: boolean
  ) {
    matSnackBar.open(translator.instant('content.quiz.linkingQuestion'));
    quizes
      .getQuestionsForQuiz({ embed_answers: false, id: quizId })
      .subscribe((questions) => {
        const quetionIds = questions.map((q) => {
          return { id: q.id } as QuestionModel;
        });
        exams
          .linkQuestionToExam({
            id: examId,
            body: quetionIds,
          })
          .subscribe(
            () => {
              matSnackBar.open(
                translator.instant('content.quiz.converrtedExam')
              );
              if (course) {
                const panel = panelService.getPanel(PanelEntityType.Exam);
                panelService.fetchItems(panel);
                panel.open$.next();
                panelService.addParam(PanelEntityType.Exam, examId);
              } else {
                router.navigate(['/cms/exams', examId]);
              }
            },
            () => {
              matSnackBar.open(
                translator.instant('content.quiz.linkingQuestionsError')
              );
            }
          );
      });
  }
}
