import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { UserConfigService } from '../../user-config.service';

export interface MaintenanceHint {
  from: string;
  to: string;
  icon?: string;
  content: string;
  parsedContent?: SafeHtml;
  id: string;
}

export interface HintToastComponentData {
  hints: MaintenanceHint[];
}

@Component({
  selector: 'qa-hint-toast',
  templateUrl: './hint-toast.component.html',
  styleUrls: ['./hint-toast.component.scss'],
})
export class HintToastComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: HintToastComponentData,
    public dialogRef: MatDialogRef<HintToastComponent>,
    private sanitize: DomSanitizer,
    private userConfig: UserConfigService
  ) {
    for (const hint of this.data.hints) {
      hint.parsedContent = this.sanitize.bypassSecurityTrustHtml(hint.content);
    }
  }

  async markAsSeen(hint: MaintenanceHint) {
    const maintenanceInfo =
      (await this.userConfig.get('maintenanceInfo')) ?? {};

    if (!maintenanceInfo?.seen) {
      maintenanceInfo.seen = [];
    }

    maintenanceInfo.seen.push(hint.id);

    this.data.hints.splice(this.data.hints.indexOf(hint), 1);

    this.userConfig.set('maintenanceInfo', maintenanceInfo);
  }
}
