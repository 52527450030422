<div class="user-review-container" *ngIf="currentCard?.flipped">
  <div class="review-container">
    <div class="buttons-container" [class.submitted]="card?.cardSubmitted">
      <button
        mat-raised-button
        class="did-know-answer"
        [class.submitted]="card?.cardSubmitted && didKnowAnswer"
        (click)="onIconClick(true)"
      >
        <mat-icon>thumb_up</mat-icon>
        {{
          !card?.cardSubmitted || (card?.cardSubmitted && didKnowAnswer)
            ? ('flashCardStack.answerKnown' | translate)
            : ''
        }}
      </button>
      <button
        mat-raised-button
        class="did-not-know-answer"
        [class.submitted]="card?.cardSubmitted && !didKnowAnswer"
        (click)="onIconClick(false)"
      >
        <mat-icon>thumb_down</mat-icon>
        {{
          !card?.cardSubmitted || (card?.cardSubmitted && !didKnowAnswer)
            ? ('flashCardStack.answernotKnown' | translate)
            : ''
        }}
      </button>
    </div>
  </div>
</div>
