import { Component, Input } from '@angular/core';
import { UtilityService } from '../../utility/utility.service';
import { MatTabGroupConfig } from './list.types';

@Component({
  selector: 'qa-tab-list',
  templateUrl: './tab-list.component.html',
  styleUrls: ['./tab-list.component.scss'],
})
export class TabListComponent {
  @Input()
  matTabsConfig: MatTabGroupConfig;

  constructor(utility: UtilityService) {
    utility.nonAcceptedCards$.subscribe();
  }
}
