<form (ngSubmit)="submit()" [formGroup]="form">
  <h1 mat-dialog-title>
    {{ 'newPassword.title' | translate }}
  </h1>
  <mat-dialog-content>
    <p>
      {{ 'newPassword.text' | translate }}
    </p>
    <p *ngIf="errorMessage" class="mat-error">
      {{ errorMessage }}
    </p>
    <mat-form-field hideRequiredMarker>
      <mat-label>
        {{ 'newPassword.label.newPassword' | translate }}
      </mat-label>
      <input
        #passwordInput
        formControlName="newPassword"
        matInput
        type="password"
      />
      <button
        (click)="
          passwordInput.type =
            passwordInput.type === 'text' ? 'password' : 'text'
        "
        matTooltip="{{
          (passwordInput.type === 'text'
            ? 'newPassword.tooltip.hidePassword'
            : 'newPassword.tooltip.showPassword'
          ) | translate
        }}"
        color="primary"
        mat-icon-button
        matSuffix
        type="button"
      >
        <mat-icon>
          {{ passwordInput.type === 'text' ? 'visibility_off' : 'visibility' }}
        </mat-icon>
      </button>
      <mat-hint>
        {{ 'newPassword.hint' | translate }}
      </mat-hint>
      <mat-error
        *ngIf="
          form.get('newPassword').invalid && form.get('newPassword').touched
        "
      >
        {{ 'newPassword.error' | translate }}
      </mat-error>
    </mat-form-field>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button [mat-dialog-close] color="primary" mat-button type="button">
      {{ 'common.button.abort' | translate }}
    </button>
    <button
      [disabled]="form.get('newPassword').invalid"
      color="primary"
      mat-button
      type="submit"
    >
      {{ 'newPassword.button.changePassword' | translate }}
    </button>
  </mat-dialog-actions>
</form>
