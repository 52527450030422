<div class="banner" *ngIf="data.banner">
  <img [src]="data.banner" />
</div>

<div class="c-icon" *ngIf="data.icon">
  <mat-icon>{{ data.icon }}</mat-icon>
</div>

<h1 mat-dialog-title>
  {{ data.title }}
</h1>

<mat-dialog-content
  *ngIf="data.text"
  [innerHTML]="parse(data.text)"
></mat-dialog-content>

<mat-dialog-actions align="end">
  <button
    [attr.color]="button.color"
    [mat-dialog-close]="button.value"
    *ngFor="let button of data.buttons"
    mat-button
  >
    <mat-icon *ngIf="button.icon">
      {{ button.icon }}
    </mat-icon>
    {{ button.text }}
  </button>
</mat-dialog-actions>
