export const jazzyTheme = {
  name: 'Jazzy',
  bpm: 120,
  resources: [
    {
      id: 'start',
      path: 'theme02_start.mp3',
      length: 4,
    },
    {
      id: 'idle01',
      path: 'theme02_idle01.mp3',
      loop: true,
      length: 4,
    },
    {
      id: 'idle02',
      path: 'theme02_idle02.mp3',
      length: 2,
    },
    {
      id: 'transition',
      path: 'theme02_transition.mp3',
      length: 5,
    },
    {
      id: 'sax',
      path: 'theme02_sax.mp3',
      length: 3,
    },
  ],
  timelines: [
    [
      {
        id: 'start',
        pos: 0,
      },
      {
        id: '$FILL',
        options: [
          {
            id: 'idle01',
            minInARow: 999,
            maxInARow: 999,
          },
        ],
      },
      {
        id: 'transition',
        pos: -4,
      },
    ],
    [
      {
        id: 'start',
        notBefore: 4,
        pos: -10,
      },
      {
        id: 'idle02',
        notBefore: 8,
        pos: -6,
      },
    ],
    [
      {
        id: 'sax',
        pos: -10,
      },
    ],
  ],
};
