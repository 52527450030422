import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs';
import { QuestionType } from 'src/app/app-constants';
import { AppState } from 'src/app/app.types';
import * as _ from 'lodash';

interface CountdownHint {
  title: string;
  details: string;
}

@Component({
  selector: 'qa-countdown-wrapper',
  templateUrl: './countdown-wrapper.component.html',
  styleUrls: ['./countdown-wrapper.component.scss'],
})
export class CountdownWrapperComponent implements OnInit {
  countdownHint: CountdownHint | undefined = undefined;
  state: AppState;

  @Input() isPollQuestion = false;

  constructor(
    private store: Store<AppState>,
    private translator: TranslateService
  ) {}

  ngOnInit(): void {
    this.store.pipe(take(1)).subscribe((state) => {
      this.countdownHint = this.buildCountdownHint(state);
      this.state = state;
    });
  }

  private buildCountdownHint(state: AppState): CountdownHint | undefined {
    const event = state.liveEvent?.event ?? state.cmsLiveEvent;
    if (!event) return undefined;
    const targetSection =
      state.play?.sections?.[
        _.isNil(state.play?.sectionIndex) ? -1 : state.play.sectionIndex
      ];
    if (
      !targetSection ||
      targetSection.name !== 'questions' ||
      _.isNil(targetSection.viewIndex)
    )
      return undefined;
    const targetQuestionId =
      targetSection.views?.[targetSection.viewIndex]?.componentData?.questionId;
    if (!targetQuestionId) return undefined;
    const targetQuestion = event.questions?.find(
      (q) => q.id === targetQuestionId
    );
    if (!targetQuestion) return undefined;
    let title: string, details: string;
    switch (targetQuestion.type) {
      case QuestionType.TrueFalse:
        title = this.translator.instant('questionMockPhone.trueOrFalse');
        details = this.translator.instant(
          this.isPollQuestion
            ? 'question.hint.chooseStatementPoll'
            : 'question.hint.chooseStatement'
        );
        break;
      case QuestionType.SingleChoice:
        title = this.translator.instant('questionMockPhone.singleChoice');
        details = this.translator.instant(
          this.isPollQuestion
            ? 'question.hint.yourAnswer'
            : 'question.hint.chooseAns'
        );
        break;
      case QuestionType.MultipleChoice:
        title = this.translator.instant('questionMockPhone.multiChoice');
        details = this.translator.instant('question.hint.chooseAll');
        break;
      case QuestionType.FreeText:
        title = this.translator.instant('questionMockPhone.freeText');
        details = this.translator.instant('question.hint.typeInYour');
        break;
    }
    return { title, details };
  }
}
