import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

export interface ListInfo {
  videoUrl: string;
  learnMoreText: string;
  content: string;
  links: {
    label: string;
    url: string;
  }[];
}

@Component({
  selector: 'qa-no-entity-info',
  templateUrl: './no-entity-info.component.html',
  styleUrls: ['./no-entity-info.component.scss'],
})
export class NoEntityInfoComponent implements OnInit {
  @Input() listInfo: ListInfo;
  videoUrl: SafeResourceUrl;

  constructor(private sanitizer: DomSanitizer) {}

  ngOnInit(): void {
    if (this.listInfo && this.listInfo.videoUrl) {
      this.videoUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
        this.listInfo.videoUrl
      );
    }
  }
}
