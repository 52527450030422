export const environment = {
  appStoreLink: 'https://apps.apple.com/de/app/quizacademy/id6503623409',
  appStoreId: '1012949118',
  edition: 'school',
  playStoreLink:
    'https://play.google.com/store/apps/details?id=de.lecommsulting.abiquiz',
  production: false,
  websocketEventUrl:
    'wss://udigu9uivk.execute-api.eu-central-1.amazonaws.com/staging',
  api: {
    key: 'jpmH06jXpX1d2dx4VIJmnaOmvEpXkJaV6rkJedek',
    url: 'https://api.quizacademy.io/quiz-staging',
  },
  crmUI: {
    key: 'jpmH06jXpX1d2dx4VIJmnaOmvEpXkJaV6rkJedek',
    url: 'https://api.quizacademy.io/crm-dev/',
  },
  cognito: {
    clientId: '2ce0ne2is9v1tvi3hmvqn6f032',
    region: 'eu-central-1',
    userPoolId: 'eu-central-1_arYVjdrWI',
  },
  colors: {
    primary: '#008D36',
    primaryLight: '#a9d82c',
    primaryLighter: '#f5fbe6',
    correct: '#008D36',
    warn: '#d32f2f',
  },
  s3: {
    mediaBucket: {
      identityPoolId: 'eu-central-1:a9c038dc-ab16-47ca-90d0-c007299dc781',
      name: 'media.quizacademy.io',
      region: 'eu-central-1',
    },
  },
  sentry: {
    dsn: 'https://d138789e3f8b46b2ae08d9b9526b5540@sentry.io/1776587',
  },
  wordpress: 'https://quizacademy.de/wp-admin/admin-ajax.php',
};
