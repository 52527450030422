<div mat-dialog-title class="main-header-box">
  <h1 class="main-title">
    <ng-container *ngIf="isGerman">
      {{ config.itemName }}
      {{
        (initialValue ? 'common.label.toEdit' : 'common.label.toCreate')
          | translate
      }}
    </ng-container>
    <ng-container *ngIf="!isGerman">
      {{
        (initialValue ? 'common.label.toEdit' : 'common.label.toCreate')
          | translate
      }}
      {{ config.itemName }}
    </ng-container>
  </h1>
  <div class="cross-icon">
    <mat-icon mat-dialog-close>clear</mat-icon>
  </div>
</div>
<mat-dialog-content>
  <form [formGroup]="formGroup">
    <ng-container *ngFor="let field of config.fields; let i = index">
      <ng-container *ngIf="!field.blurInput || field.select; else labelView">
        <mat-form-field
          [ngClass]="!field.autocomplete && !field.select ? 'outline' : 'fill'"
          [appearance]="
            !field.autocomplete && !field.select ? 'outline' : 'fill'
          "
          subscriptSizing="dynamic"
          *ngIf="!field.hideIf || !field.hideIf(initialValue, currentValue)"
        >
          <mat-label>
            {{ field.label }}
          </mat-label>
          <ng-container *ngIf="field.autocomplete">
            <input
              matInput
              aria-label="State"
              attr.fieldname="{{ field.key }}"
              [required]="field.required ? 'required' : null"
              [matAutocomplete]="auto"
              [formControlName]="field.key"
            />
            <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
              <ng-container *ngIf="autoCompleteOptions?.length">
                <mat-option
                  class="edit-auto-complete"
                  *ngFor="let option of autoCompleteOptions"
                  [value]="option?.value"
                >
                  <div class="text">
                    {{ option?.text || option?.value }}
                  </div>
                  <div *ngIf="option?.hint" class="hint">
                    {{ option?.hint }}
                  </div>
                </mat-option>
              </ng-container>
            </mat-autocomplete>
          </ng-container>
          <ng-container *ngIf="field.select">
            <mat-select
              [compareWith]="field.select.compareWith || defaultCompareWith"
              [formControlName]="field.key"
              [multiple]="!!field.select.allowMultiple"
              [panelClass]="'qa-edit-dialog-select'"
              [required]="field.required ? 'required' : null"
            >
              <mat-select-trigger>
                {{ getSelectTriggerText(field) }}
              </mat-select-trigger>
              <mat-option
                [value]="option.value"
                *ngFor="let option of options[field.key]"
                [matTooltip]="option.tooltip ? option.tooltip : null"
                matTooltipClass="edit-tooltip"
                matTooltipPosition="right"
              >
                <div class="text">
                  {{ option.text || option.value }}
                </div>
                <div *ngIf="option.hint" class="hint">
                  {{ option.hint }}
                </div>
              </mat-option>
            </mat-select>
          </ng-container>
          <ng-container *ngIf="field.iconSelector">
            <input
              type="text"
              class="icon-select-field"
              matInput
              [formControlName]="field.key"
              [required]="field.required ? 'required' : null"
              autocomplete="off"
            />
            <div class="icon-select-menu">
              <ng-scrollbar data-check="true">
                <button
                  mat-icon-button
                  [class.selected]="formGroup.get(field.key).value === icon"
                  *ngFor="let icon of iconNames(formGroup.get(field.key))"
                  (click)="formGroup.get(field.key).setValue(icon)"
                  autocomplete="off"
                >
                  <mat-icon [title]="icon">{{ icon }}</mat-icon>
                </button>
              </ng-scrollbar>
            </div>
          </ng-container>
          <ng-container
            *ngIf="!field.autocomplete && !field.select && !field.iconSelector"
          >
            <input
              [formControlName]="field.key"
              [required]="field.required ? 'required' : null"
              [type]="field.inputType || 'text'"
              *ngIf="!field.multiLine && !field.enableDatepicker"
              autocomplete="new-password"
              (blur)="onBlur(field, $event)"
              [placeholder]="placeholder"
              (focus)="handleSuggestion($event, field)"
              (pointerdown)="onPointerDown($event, field)"
              (input)="onInput(field)"
              (keydown)="onKeyDown($event, field, field.key)"
              [class.suggestion]="field.showSuggestion"
              #inputField="matInput"
              (paste)="onPaste($event, field)"
              matInput
            />
            <textarea
              [formControlName]="field.key"
              (blur)="onBlur(field, $event)"
              *ngIf="field.multiLine && !field.enableDatepicker"
              matInput
            >
            </textarea>
            <ng-container *ngIf="field.enableDatepicker">
              <input
                [formControlName]="field.key"
                [matDatepicker]="datepicker"
                [required]="field.required ? 'required' : null"
                autocomplete="new-password"
                matInput
              />
              <mat-datepicker #datepicker> </mat-datepicker>
            </ng-container>
          </ng-container>
          <mat-datepicker-toggle
            [for]="datepickers[field.key]"
            *ngIf="field.enableDatepicker && datepickers[field.key]"
            matSuffix
          >
          </mat-datepicker-toggle>
          <div
            class="help-btns"
            *ngIf="!field?.blurInput && field?.suffix?.icon === 'code'"
          >
            <mat-label
              (mousedown)="field.suffix.handler(currentValue, initialValue)"
              [matTooltip]="'Formatierungshilfe'"
              *ngIf="
                field.suffix &&
                (!field.suffix.hideIf ||
                  !field.suffix.hideIf(currentValue, initialValue))
              "
            >
              <mat-icon>code</mat-icon>
              {{ 'common.label.formattingHelp' | translate }}
            </mat-label>
          </div>
          <button
            (click)="field.suffix.handler(currentValue, initialValue)"
            [matTooltip]="field.suffix.text"
            *ngIf="
              field.suffix &&
              field.suffix.icon !== 'code' &&
              (!field.suffix.hideIf ||
                !field.suffix.hideIf(currentValue, initialValue))
            "
            mat-icon-button
            matSuffix
            type="button"
          >
            <mat-icon>
              {{ field.suffix.icon }}
            </mat-icon>
          </button>
          <mat-hint [id]="null">
            {{ field.hint }}
          </mat-hint>
          <mat-error>
            {{ field.errorMsg }}
          </mat-error>
        </mat-form-field>
      </ng-container>

      <ng-template #labelView>
        <mat-form-field
          class="label-view"
          [ngClass]="!field.autocomplete && !field.select ? 'outline' : 'fill'"
          [appearance]="
            !field.autocomplete && !field.select ? 'outline' : 'fill'
          "
          subscriptSizing="dynamic"
        >
          <mat-label>
            {{ field?.label }}
          </mat-label>
          <input
            hidden
            class="hidden"
            [formControlName]="field.key"
            [required]="field.required ? 'required' : null"
            matInput
          />
          <div *ngIf="!field?.suffix?.icon" (click)="onEdit(field)">
            {{ formGroup.controls[field.key].value }}
          </div>
          <qa-markdown-katex
            *ngIf="field?.suffix?.icon"
            [data]="formGroup.controls[field.key].value"
            (click)="onEdit(field)"
          >
          </qa-markdown-katex>
        </mat-form-field>
      </ng-template>
    </ng-container>
  </form>
  <button
    *ngIf="config.id === 'profile'"
    (click)="forgotPassword()"
    color="primary"
    type="button"
    style="width: 100%"
    mat-button
  >
    {{ 'common.dialogTitle.resetPass' | translate }}
  </button>
  <qa-button
    *ngIf="config.id === 'profile'"
    [matMenuTriggerFor]="dpaMenu"
    [isRaisedButton]="true"
    label="profile.dpa.title"
    icon="receipt_long"
    style="width: 100%; margin-top: 0.5rem"
  ></qa-button>
  <mat-menu #dpaMenu="matMenu">
    <button mat-menu-item (click)="viewDPA()">
      <mat-icon>visibility</mat-icon>{{ 'profile.dpa.sampleDPA' | translate }}
    </button>
    <button mat-menu-item (click)="personalizeDPA()">
      <mat-icon>edit</mat-icon>{{ 'profile.dpa.personalizeDPA' | translate }}
    </button>
  </mat-menu>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <!-- <button
    (click)="close()"
    [disabled]="formGroup.disabled"
    color="primary"
    mat-button
    type="button"
    class="btn-common-style"
  >
    {{ 'common.button.abort' | translate }}
  </button> -->

  <div class="flex-align-right buttons">
    <button
      (click)="delete()"
      [disabled]="formGroup.disabled"
      *ngIf="config.handlers.delete && initialValue"
      class="delete-button"
      color="warn"
      mat-button
      tabindex="-1"
      type="button"
    >
      {{ 'common.button.clear' | translate }}
    </button>
    <qa-button (submitted)="close()" label="common.button.shutdown"></qa-button>
    <qa-button
      [disabled]="
        formGroup.disabled ||
        (initialValue && formGroup.pristine) ||
        formGroup.invalid
      "
      [isRaisedButton]="true"
      [label]="initialValue ? 'common.button.save' : 'common.button.create'"
      (submitted)="save()"
    ></qa-button>
  </div>
</mat-dialog-actions>
