// eslint-disable  max-len
import * as sanitizeHTML from 'sanitize-html';

const HTML_TAGS = [
  'h1',
  'h2',
  's',
  'sub',
  'sup',
  'ins',
  'mark',
  'dl',
  'dd',
  'dt',
  'abbr',
  'span',
  'svg',
  'path',
  'line',
  'del',
];
const MATHML_TAGS = [
  'math',
  'mglyph',
  'mi',
  'mn',
  'mo',
  'ms',
  'mspace',
  'mtext',
  'menclose',
  'merror',
  'mfenced',
  'mfrac',
  'mpadded',
  'mphantom',
  'mroot',
  'mrow',
  'msqrt',
  'mstyle',
  'mmultiscripts',
  'mover',
  'mprescripts',
  'msub',
  'msubsup',
  'msup',
  'munder',
  'munderover',
  'none',
  'maligngroup',
  'malignmark',
  'mlabeledtr',
  'mtable',
  'mtd',
  'mtr',
  'mlongdiv',
  'mscarries',
  'mscarry',
  'msgroup',
  'msline',
  'msrow',
  'mstack',
  'maction',
  'annotation',
  'annotation-xml',
  'semantics',
];

const HTML_ATTRIBUTES = [
  'style',
  'class',
  'aria-hidden',
  'href',
  'name',
  'target',
  'title',
  'x1',
  'y1',
  'x2',
  'y2',
  'stroke-width',
  'start',
];
const MATHML_ATTRIBUTES = [
  'accent',
  'accentunder',
  'actiontype',
  'align',
  'alignmentscope',
  'altimg',
  'altimg-width',
  'altimg-height',
  'altimg-valign',
  'alttext',
  'bevelled',
  'charalign',
  'close',
  'columnalign',
  'columnlines',
  'columnspacing',
  'columnspan',
  'columnwidth',
  'crossout',
  'decimalpoint',
  'denomalign',
  'depth',
  'dir',
  'display',
  'displaystyle',
  'edge',
  'equalcolumns',
  'equalrows',
  'fence',
  'form',
  'frame',
  'framespacing',
  'groupalign',
  'height',
  'href',
  'id',
  'indentalign',
  'indentalignfirst',
  'indentalignlast',
  'indentshift',
  'indentshiftfirst',
  'indentshiftlast',
  'indenttarget',
  'infixlinebreakstyle',
  'largeop',
  'length',
  'linebreak',
  'linebreakmultchar',
  'linebreakstyle',
  'lineleading',
  'linethickness',
  'location',
  'longdivstyle',
  'lspace',
  'lquote',
  'mathbackground',
  'mathcolor',
  'mathsize',
  'mathvariant',
  'maxsize',
  'minlabelspacing',
  'minsize',
  'movablelimits',
  'notation',
  'numalign',
  'open',
  'overflow',
  'position',
  'rowalign',
  'rowlines',
  'rowspacing',
  'rowspan',
  'rspace',
  'rquote',
  'scriptlevel',
  'scriptminsize',
  'scriptsizemultiplier',
  'selection',
  'separator',
  'separators',
  'shift',
  'side',
  'stackalign',
  'stretchy',
  'subscriptshift',
  'supscriptshift',
  'symmetric',
  'voffset',
  'width',
  'xmlns',
];

export const ALLOWED_TAGS = [
  ...HTML_TAGS,
  ...MATHML_TAGS,
  ...sanitizeHTML.defaults.allowedTags,
];

export const ALLOWED_ATTRIBUTES = [...HTML_ATTRIBUTES, ...MATHML_ATTRIBUTES];
