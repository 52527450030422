<div
  class="list-container"
  [class.no-content-list]="dataSource?.empty"
  [class.selected-list]="
    config.multiselectActions &&
    selectedRows?.length &&
    config.multiselectActions?.length
  "
>
  <div class="list-header" *ngIf="!config?.hideWrapper">
    <div class="list-header-title">
      <h1
        [class.mat-headline-4]="!config?.hideWrapper"
        [class.mat-headline-5]="config?.hideWrapper"
        direction="up"
        qaEnterAnimation
      >
        {{ config.title | translate }}
      </h1>
      <qa-subscription-indicator
        *ngIf="config?.showSubscriptionIndicator"
      ></qa-subscription-indicator>
    </div>
    <div
      class="button-wrapper"
      *ngIf="!config?.hideWrapper && config?.tutorial"
    >
      <qa-button
        *ngIf="!config?.hideWrapper && config?.tutorial"
        icon="play_circle"
        [label]="
          config.tutorialLabel
            ? config.tutorialLabel
            : 'dashboardNew.liveEventCardDefault.video'
        "
        (submitted)="openVideo(config?.tutorial)"
      ></qa-button>
      <qa-button
        *ngIf="!config?.hideWrapper && config?.extraTutorial"
        icon="school"
        [label]="
          config.extraTutorialLabel
            ? config.extraTutorialLabel
            : 'dashboardNew.liveEventCardDefault.academy'
        "
        (submitted)="openLink(config?.extraTutorial)"
      ></qa-button>
    </div>

    <div class="number-of-items" *ngIf="config?.showNumberOfItems">
      {{ dataSource?.length }} {{ 'common.list.questionsIncluded' | translate }}
    </div>
  </div>

  <div class="header" *ngIf="!config?.hideWrapper">
    <div>
      <img class="logo-mini" src="assets/icons/logo-mini.png" alt="" />
    </div>
    <div class="menu-btn">
      <mat-icon (click)="toggleMenu()">{{
        menuShow ? 'close' : 'menu'
      }}</mat-icon>
    </div>
  </div>

  <div
    [delay]="100"
    class="top-bar"
    direction="up"
    qaEnterAnimation
    [style.display]="config.hideTopBar ? 'none' : null"
  >
    <mat-form-field [appearance]="'fill'">
      <div class="search-actions" matPrefix>
        <div
          class="filter-target"
          (click)="$event.stopPropagation()"
          [matMenuTriggerFor]="filterTargetMenu"
        >
          <span class="search-text">{{
            filterTargetControl.value?.label | translate
          }}</span>
          <mat-icon class="arrow-drop-down-icon">arrow_drop_down</mat-icon>
        </div>
        <mat-menu #filterTargetMenu="matMenu">
          <button
            mat-menu-item
            *ngFor="let target of possibleFilters"
            (click)="changeFilter(target)"
          >
            {{ target.label | translate }}
          </button>
        </mat-menu>
      </div>
      <ng-container
        *ngIf="
          !filterTargetControl?.value?.filterOptions &&
          !filterTargetControl?.value?.filterOptionsMultiSelect &&
          !filterTargetControl?.value?.filterOptionsByGroup
        "
      >
        <ng-container *ngIf="!filterTargetControl?.value?.filterAutoComplete">
          <input
            [formControl]="searchControl"
            #searchInput
            (keyup.enter)="
              searchControl?.value && searchControl.value.length > 0
                ? addFilter()
                : null
            "
            matInput
            autocomplete="off"
            placeholder="{{ 'common.list.search' | translate }}"
            type="text"
            class="search-field"
          />
        </ng-container>
        <ng-container *ngIf="filterTargetControl?.value?.filterAutoComplete">
          <input
            [formControl]="searchControl"
            #searchInput
            (keyup)="
              setCurrentFilterAutocomplete(
                filterTargetControl.value.filterAutoComplete,
                $event?.target?.value
              )
            "
            matInput
            [matAutocomplete]="searchAutoComplete"
            autocomplete="off"
            placeholder="{{ 'common.list.searchFor' | translate }} {{
              filterTargetControl?.value?.label || '...' | translate
            }}"
            type="text"
          />
          <mat-autocomplete
            #searchAutoComplete="matAutocomplete"
            (optionSelected)="selectAutoCompleteFilterOption($event)"
          >
            <mat-option
              *ngFor="let option of filterAutoCompleteOptions"
              [value]="option"
            >
              {{ option }}
            </mat-option>
          </mat-autocomplete>
        </ng-container>
      </ng-container>
      <ng-container
        *ngIf="filterTargetControl?.value.showTreeSelect; else otherSelect"
      >
        <input
          name="tree-select"
          [placeholder]="'course.form.category' | translate"
          class="search-field"
          type="text"
          readonly
          matInput
          [formControl]="searchControl"
        />
        <qa-tree-select
          #treeSelect
          class="category-contents"
          [control]="treeControl"
          (valueChanged)="changeCategory($event)"
          label="{{ 'course.form.category' | translate }}"
          [tree]="filterTargetControl?.value?.filterOptions"
        >
        </qa-tree-select>
      </ng-container>
      <ng-template #otherSelect>
        <ng-container *ngIf="filterTargetControl?.value?.filterOptionsByGroup">
          <mat-select
            [formControl]="searchControl"
            [compareWith]="compareTypeObjects"
            [multiple]="true"
            disableOptionCentering
            *ngIf="filterTargetControl?.value?.filtersCanStack"
            panelClass="grid-data-group multi-select-input"
            placeholder="{{ 'common.label.chooseType' | translate }}"
            #selectInput
          >
            <mat-optgroup
              class="group-label"
              *ngFor="
                let group of filterTargetControl.value.filterOptionsByGroup
              "
              [label]="group.name | translate"
            >
              <mat-option
                *ngFor="let option of group.group"
                [value]="{ value: option.value, group: group.id }"
              >
                {{ option.name | translate }}
              </mat-option>
            </mat-optgroup>
            <footer>
              <qa-button
                [label]="'exam.button.resetExam' | translate"
                (submitted)="
                  removeMultiselectFilter(
                    filterTargetControl.value,
                    selectInput
                  )
                "
              ></qa-button>
              <qa-button
                [isRaisedButton]="true"
                (submitted)="addFilter(); selectInput.close()"
                [label]="'common.button.save' | translate"
              ></qa-button>
            </footer>
          </mat-select>
        </ng-container>
        <ng-container *ngIf="filterTargetControl?.value?.filterOptions">
          <mat-select
            [formControl]="searchControl"
            [multiple]="false"
            panelClass="select-data-grid"
            *ngIf="!filterTargetControl?.value?.filtersCanStack"
            #searchInput
          >
            <mat-option
              *ngFor="let option of filterTargetControl.value.filterOptions"
              [value]="option.value"
            >
              {{ option.name | translate }}
            </mat-option>
          </mat-select>
        </ng-container>
        <ng-container
          *ngIf="filterTargetControl?.value?.filterOptionsMultiSelect"
        >
          <mat-select
            [compareWith]="compareObjects"
            (click)="fetchMultipleOptions(filterTargetControl?.value)"
            (infiniteScroll)="getNextBatch(filterTargetControl?.value)"
            [formControl]="searchControl"
            panelClass="multi-select-input"
            placeholder="{{ 'common.list.select' | translate }}"
            [complete]="pagination && !pagination?.has_next_page"
            msInfiniteScroll
            #multiSelectInput
            multiple
          >
            <div class="search-field-input">
              <input
                #searchInput
                autocomplete="off"
                placeholder="{{ 'common.list.search' | translate }}"
                type="text"
                class="search-field-container"
                (keyup)="search(filterTargetControl?.value, $event)"
              />
            </div>
            <mat-option
              *ngFor="let option of options$ | async"
              [value]="option"
              >{{ option.name }}</mat-option
            >
            <mat-option class="loading" *ngIf="loadingOptions">{{
              'common.list.loading' | translate
            }}</mat-option>
            <mat-option
              class="loading"
              *ngIf="!(options$ | async).length && !loadingOptions"
              >{{ 'common.list.noTagsFound' | translate }}
            </mat-option>
            <footer>
              <qa-button
                [label]="'exam.button.resetExam' | translate"
                (submitted)="
                  removeMultiselectFilter(
                    filterTargetControl.value,
                    multiSelectInput
                  )
                "
              ></qa-button>
              <qa-button
                [isRaisedButton]="true"
                (submitted)="addFilter(); multiSelectInput.close()"
                [label]="'common.button.save' | translate"
              ></qa-button>
            </footer>
          </mat-select>
        </ng-container>
      </ng-template>
      <button
        matSuffix
        mat-button
        class="search-button"
        (click)="$event.stopPropagation(); addFilter()"
      >
        <mat-icon>search</mat-icon>
      </button>
    </mat-form-field>
    <div class="pagination-section">
      <button
        mat-button
        color="primary"
        (click)="action.click()"
        *ngFor="let action of config?.parentActions || []"
      >
        <mat-icon *ngIf="action?.icon">{{ action.icon }}</mat-icon>
        <span>{{ action.text }}</span>
      </button>
      <mat-paginator
        [pageSize]="DEFAULT_PAGE_SIZE"
        [pageSizeOptions]="[10, 25, 50]"
        #paginator
        [class.hidden]="config?.hidePaginator"
      >
      </mat-paginator>
      <qa-button
        (submitted)="config.createButton.click(dataSource)"
        *ngIf="config.createButton?.click"
        [isRaisedButton]="true"
        [isIconButton]="true"
        [isRoundedButton]="true"
        icon="add"
        type="button"
      ></qa-button>
      <qa-button
        [matMenuTriggerFor]="addMenu"
        *ngIf="config.createButton?.options?.length"
        [isRaisedButton]="true"
        [isIconButton]="true"
        [isRoundedButton]="true"
        icon="add"
        type="button"
        #addButton
      ></qa-button>
      <mat-menu
        class="content-menu grid"
        [overlapTrigger]="true"
        #addMenu="matMenu"
      >
        <ng-container
          *ngFor="let item of config.createButton?.options; let i = index"
        >
          <button
            (click)="item.click(dataSource)"
            *ngIf="i == 0"
            mat-menu-item
            type="button"
          >
            <mat-icon>{{ item.icon }}</mat-icon>
            {{ item.text }}
          </button>
          <button
            (click)="importContent()"
            *ngIf="config?.imports?.length"
            mat-menu-item
            type="button"
          >
            <mat-icon>file_upload</mat-icon>
            {{ 'common.button.add' | translate }}
          </button>
        </ng-container>
        <div
          class="import-list"
          mat-menu-item
          *ngIf="config.createButton?.options?.length > 1"
        >
          <mat-label>{{ 'common.label.import' | translate }}</mat-label>
          <div class="container">
            <ng-container
              [ngTemplateOutlet]="createButtonImportList"
              [ngTemplateOutletContext]="{
                options: config.createButton?.options?.slice(1)
              }"
            ></ng-container>
          </div>
        </div>
      </mat-menu>
    </div>
  </div>
  <div class="filter-area" [class.open]="activeFilters.length > 0">
    <div class="icon"><mat-icon>filter_alt</mat-icon> Filter:</div>
    <div class="filter" *ngFor="let filter of activeFilters">
      <div class="property">{{ filter.name | translate }}</div>
      <div class="value">
        <ng-container *ngIf="isStringValue(filter)">
          {{ translateFilterValue(filter.value, filter._ref) | translate }}
        </ng-container>
        <ng-container *ngIf="!isStringValue(filter)">
          <div
            *ngFor="let item of translateFilterValue(filter.value, filter._ref)"
            [ngClass]="{
              chip: translateFilterValue(filter.value, filter._ref).length > 1
            }"
          >
            {{ item }}
            <mat-icon
              *ngIf="translateFilterValue(filter.value, filter._ref).length > 1"
              (click)="removeFilterItem(item, filter)"
              >close</mat-icon
            >
          </div>
        </ng-container>
      </div>
      <mat-icon (click)="removeFilter(filter)">close</mat-icon>
    </div>
  </div>
  <div
    class="multiselect-actions"
    *ngIf="
      config.multiselectActions &&
      selectedRows?.length &&
      config.multiselectActions?.length
    "
  >
    <span class="for-all">{{ 'common.list.forAllSelected' | translate }}:</span>
    <ng-container *ngFor="let action of config.multiselectActions">
      <button
        mat-button
        *ngIf="
          !action.hide ||
          (action.hide &&
            !action.hide({
              row: row,
              index: i,
              dataSource: dataSource
            }))
        "
        (click)="multiSelectClick(action.click)"
      >
        <mat-icon *ngIf="action.icon">
          {{
            isFunction(action.icon)
              ? action.icon({ dataSource: dataSource })
              : action.icon
          }}
        </mat-icon>
        {{ action.name | translate }}
      </button>
    </ng-container>
  </div>
  <div class="loading-data" [class.loading]="!dataSource || dataSource.loading">
    <mat-spinner [diameter]="48"></mat-spinner>
  </div>
  <mat-table
    [dataSource]="dataSource"
    [delay]="200"
    direction="up"
    matSort
    qaEnterAnimation
    cdkDropList
    (cdkDropListDropped)="afterItemDropped($event)"
  >
    <ng-container
      matColumnDef="multiselectActions"
      *ngIf="config?.multiselectActions"
    >
      <mat-header-cell [style.flex]="'0 0 48px'" *matHeaderCellDef>
        <mat-checkbox
          *ngIf="!isSingleSelection"
          color="primary"
          [checked]="allChecked"
          [indeterminate]="someChecked()"
          (change)="checkAll($event.checked)"
        ></mat-checkbox>
      </mat-header-cell>
      <mat-cell *matCellDef="let row; let i = index" [style.flex]="'0 0 48px'">
        <mat-checkbox
          *ngIf="config?.isDisabled ? !config.isDisabled(row) : true"
          color="primary"
          [checked]="isChecked(row) || isDisabled(row)"
          [disabled]="isDisabled(row)"
          (change)="checkRow(row, $event)"
        ></mat-checkbox>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="drag" *ngIf="config?.onPositionDrag">
      <mat-header-cell
        [style.flex]="'0 0 32px'"
        *matHeaderCellDef
      ></mat-header-cell>
      <mat-cell *matCellDef="let row; let i = index" [style.flex]="'0 0 32px'">
        <mat-icon
          cdkDragHandle
          class="drag-indicator"
          (touchstart)="dragDisabled = false"
          (touchend)="dragDisabled = true"
          (mousedown)="dragDisabled = false"
          (mouseup)="dragDisabled = true"
          >drag_indicator</mat-icon
        >
      </mat-cell>
    </ng-container>
    <ng-container
      [matColumnDef]="column.name"
      *ngFor="let column of config.columns"
    >
      <ng-container *ngIf="column.sortable">
        <mat-header-cell
          [style.flex]="column.width ? '0 0 ' + column.width : null"
          *matHeaderCellDef
          mat-sort-header
        >
          <ng-container *ngIf="!column?.hideLabel">{{
            column.label | translate
          }}</ng-container>
        </mat-header-cell>
      </ng-container>
      <ng-container *ngIf="!column.sortable">
        <mat-header-cell
          [style.flex]="column.width ? '0 0 ' + column.width : null"
          *matHeaderCellDef
        >
          <ng-container *ngIf="!column?.hideLabel">{{
            column.label | translate
          }}</ng-container>
        </mat-header-cell>
      </ng-container>
      <mat-cell
        (click)="
          column.click &&
            column.click({ row: row, index: i, dataSource: dataSource })
        "
        [class.click-cell]="!!column.click"
        [class.link-cell]="!!column.link"
        [class.disable-cell]="
          config?.isDisabled ? config.isDisabled(row) : null
        "
        [matTooltip]="
          column.tooltip &&
          column.tooltip({ row: row, index: i, dataSource: dataSource })
        "
        [style.flex]="column.width ? '0 0 ' + column.width : null"
        *matCellDef="let row; let i = index"
        [class.primaryInfo]="column.primary"
        [class.hideOnMobile]="column.hideOnMobile"
        [class.disabled]="row.rowDisabled || false"
      >
        <ng-template #cellContent>
          <ng-container
            *ngIf="
              !column.toggle && !column.image;
              else column.image ? imageContent : toggleContent
            "
          >
            <mat-icon
              *ngIf="
                column.icon &&
                column.icon({ row: row, index: i, dataSource: dataSource })
              "
              >{{
                column.icon({ row: row, index: i, dataSource: dataSource })
              }}</mat-icon
            >
            <span class="column-name-mobile-only"
              >{{ column.label | translate }}:</span
            >
            <span class="cell-text-content" *ngIf="!column?.editableField">{{
              column.accessor ? column.accessor(row) : row[column.name]
            }}</span>
            <ng-container *ngIf="column?.editableField">
              <span
                class="cell-text-content"
                *ngIf="!row.isEdit"
                (click)="toggleEdit(row)"
                >{{
                  column.accessor ? column.accessor(row) : row[column.name]
                }}</span
              >
              <ng-container *ngIf="row.isEdit">
                <mat-form-field class="edit-field" appearance="outline">
                  <input
                    matInput
                    [ngModel]="row[column.name]"
                    [ngModelOptions]="{ updateOn: 'blur' }"
                    (blur)="
                      blurEdit({ row: row, index: i, dataSource: dataSource })
                    "
                    (ngModelChange)="
                      onChangeEdit(
                        $event,
                        { row: row, index: i, dataSource: dataSource },
                        column
                      )
                    "
                  />
                </mat-form-field>
              </ng-container>
            </ng-container>
          </ng-container>
        </ng-template>
        <ng-template #toggleContent>
          <mat-slide-toggle
            *ngIf="!column.toggle.isCheckToggle"
            (change)="
              column.toggle.action($event, {
                row: row,
                index: i,
                dataSource: dataSource
              })
            "
            [(ngModel)]="
              column.accessor ? column.accessor(row) : row[column.name]
            "
            [disabled]="
              column.toggle.isDisabled &&
              column.toggle.isDisabled({
                row: row,
                index: i,
                dataSource: dataSource
              })
            "
            color="primary"
          >
          </mat-slide-toggle>
          <ng-container *ngIf="column.toggle.isCheckToggle">
            <div class="check-container">
              <div class="round">
                <input
                  type="checkbox"
                  (change)="
                    column.toggle.action($event, {
                      row: row,
                      index: i,
                      dataSource: dataSource
                    })
                  "
                  [checked]="row.done"
                  [id]="i"
                />
                <label [for]="i"></label>
              </div>
            </div>
          </ng-container>
        </ng-template>
        <ng-template #imageContent>
          <img [src]="row[column.name]" />
        </ng-template>
        <a
          [routerLink]="
            column.link.routerLink({
              row: row,
              index: i,
              dataSource: dataSource
            })
          "
          [queryParams]="
            column.link.queryParams &&
            column.link.queryParams({
              row: row,
              index: i,
              dataSource: dataSource
            })
          "
          *ngIf="column.link && !column.click; else cellContent"
        >
          <div class="link-cell-container">
            <ng-container *ngTemplateOutlet="cellContent"> </ng-container>
          </div>
        </a>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="chip" *ngIf="config?.chip">
      <mat-header-cell
        [style.flex]="'0 0 250px'"
        *matHeaderCellDef
      ></mat-header-cell>
      <mat-cell *matCellDef="let row; let i = index" [style.flex]="'0 0 250px'">
        <ng-container
          *ngIf="
            config?.chip &&
            (!config?.chip?.hide ||
              (config?.chip?.hide &&
                !config?.chip?.hide({
                  row: row,
                  index: i,
                  dataSource: dataSource
                })))
          "
        >
          <qa-custom-chip
            [label]="
              config.chip?.label({
                row: row,
                index: i,
                dataSource: dataSource
              })
            "
            [chipType]="
              config.chip?.type({
                row: row,
                index: i,
                dataSource: dataSource
              })
            "
            [icon]="
              config.chip?.icon
                ? config.chip?.icon({
                    row: row,
                    index: i,
                    dataSource: dataSource
                  })
                : null
            "
          ></qa-custom-chip>
        </ng-container>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="actions">
      <mat-header-cell
        [style.flex]="
          '0 0 ' +
          (actionsColumnWidth +
            (config?.actionsMenu?.length ? 48 : 0) +
            (config?.actionColumnWidth ?? 0)) +
          'px'
        "
        *matHeaderCellDef
      >
      </mat-header-cell>
      <mat-cell
        [style.flex]="
          '0 0 ' +
          (actionsColumnWidth +
            (config?.actionsMenu?.length ? 48 : 0) +
            (config?.actionColumnWidth ?? 0)) +
          'px'
        "
        *matCellDef="let row; let i = index"
        style="justify-content: flex-end"
      >
        <ng-container
          *ngIf="
            config.showMessageInActionColumn
              ? config.showMessageInActionColumn(row)
              : false
          "
        >
          <div
            [matTooltip]="
              config?.showMessageInActionTooltip
                ? (config?.showMessageInActionTooltip | translate)
                : null
            "
            class="disabled-message"
          >
            {{ config.showMessageInActionColumn(row) | translate }}
          </div>
        </ng-container>
        <ng-container
          *ngIf="
            !config?.isDisabled ||
            (config?.isDisabled && !config.isDisabled(row))
          "
        >
          <ng-container *ngFor="let action of activeActions">
            <ng-container
              *ngIf="
                !action.hide ||
                (action.hide &&
                  !action.hide({
                    row: row,
                    index: i,
                    dataSource: dataSource
                  }))
              "
            >
              <ng-container *ngIf="action.name === 'addSelection'">
                <mat-checkbox
                  color="primary"
                  [disabled]="
                    action.isDisabled({
                      row: row,
                      index: i,
                      dataSource: dataSource
                    })
                  "
                  [checked]="
                    action.isChecked({
                      row: row,
                      index: i,
                      dataSource: dataSource
                    }) ||
                    action.isDisabled({
                      row: row,
                      index: i,
                      dataSource: dataSource
                    })
                  "
                  (change)="
                    action.click({ row: row, index: i, dataSource: dataSource })
                  "
                ></mat-checkbox>
              </ng-container>
              <ng-container *ngIf="action.name !== 'addSelection'">
                <a
                  [disabled]="
                    action.isDisabled &&
                    action.isDisabled({
                      row: row,
                      index: i,
                      dataSource: dataSource
                    })
                  "
                  [matTooltip]="
                    action.tooltip ? (action.tooltip | translate) : null
                  "
                  [routerLink]="
                    action.link?.routerLink({
                      row: row,
                      index: i,
                      dataSource: dataSource
                    })
                  "
                  [queryParams]="
                    action.link?.queryParams &&
                    action.link.queryParams({
                      row: row,
                      index: i,
                      dataSource: dataSource
                    })
                  "
                  *ngIf="action.type === 'link'"
                  color="primary"
                  mat-icon-button
                >
                  <mat-icon class="material-icons">
                    {{
                      isFunction(action.icon)
                        ? action.icon({
                            row: row,
                            index: i,
                            dataSource: dataSource
                          })
                        : action.icon
                    }}
                  </mat-icon>
                </a>
                <button
                  class="actions-icon"
                  (click)="
                    action.click({ row: row, index: i, dataSource: dataSource })
                  "
                  [disabled]="
                    action.isDisabled &&
                    action.isDisabled({
                      row: row,
                      index: i,
                      dataSource: dataSource
                    })
                  "
                  [matTooltip]="
                    action.tooltip ? (action.tooltip | translate) : null
                  "
                  *ngIf="action.type === 'button'"
                  mat-icon-button
                  type="button"
                >
                  <mat-icon [class]="action.class">
                    {{
                      isFunction(action.icon)
                        ? action.icon({
                            row: row,
                            index: i,
                            dataSource: dataSource
                          })
                        : action.icon
                    }}
                  </mat-icon>
                </button>
              </ng-container>
            </ng-container>
          </ng-container>
          <ng-container *ngIf="config?.actionsMenu && activeActionsMenuLength">
            <button
              (mousedown)="itemActionMenuTrigger.openMenu()"
              [matMenuTriggerFor]="itemActionMenu"
              #itemActionMenuTrigger="matMenuTrigger"
              color="primary"
              mat-icon-button
              type="button"
            >
              <mat-icon class="more-vert">more_vert</mat-icon>
            </button>
            <mat-menu class="content-menu" #itemActionMenu="matMenu">
              <ng-container
                *ngFor="let menuItem of config?.actionsMenu; let j = index"
              >
                <div
                  [matTooltip]="
                    menuItem?.tooltip ? (menuItem?.tooltip | translate) : null
                  "
                  *ngIf="
                    !menuItem.hide ||
                    (menuItem.hide &&
                      !menuItem.hide({
                        row: row,
                        index: i,
                        dataSource: dataSource
                      }))
                  "
                >
                  <button
                    (click)="
                      menuItem.click({
                        row: row,
                        index: i,
                        dataSource: dataSource
                      })
                    "
                    [disabled]="
                      menuItem.isDisabled &&
                      menuItem.isDisabled({
                        row: row,
                        index: i,
                        dataSource: dataSource
                      })
                    "
                    mat-menu-item
                    type="button"
                  >
                    <mat-icon>{{ menuItem.icon }}</mat-icon>
                    <span class="menu-label">{{
                      menuItem.label | translate
                    }}</span>
                  </button>
                </div>
              </ng-container>
            </mat-menu>
          </ng-container>
        </ng-container>
        <ng-container *ngIf="config?.isDisabled && config.isDisabled(row)">
          <ng-container
            *ngIf="
              config?.disabledActionsMenu && activeDisabledActionsMenuLength
            "
          >
            <button
              (click)="disabledItemActionMenuTrigger.openMenu()"
              [matMenuTriggerFor]="disabledItemActionMenu"
              #disabledItemActionMenuTrigger="matMenuTrigger"
              color="primary"
              mat-icon-button
              type="button"
            >
              <mat-icon class="more-vert">more_vert</mat-icon>
            </button>
            <mat-menu class="content-menu" #disabledItemActionMenu="matMenu">
              <ng-container
                *ngFor="
                  let menuItem of config?.disabledActionsMenu;
                  let j = index
                "
              >
                <div
                  [matTooltip]="
                    menuItem?.tooltip ? (menuItem?.tooltip | translate) : null
                  "
                  *ngIf="
                    !menuItem.hide ||
                    (menuItem.hide &&
                      !menuItem.hide({
                        row: row,
                        index: i,
                        dataSource: dataSource
                      }))
                  "
                >
                  <button
                    (click)="
                      menuItem.click({
                        row: row,
                        index: i,
                        dataSource: dataSource
                      })
                    "
                    [disabled]="
                      menuItem.isDisabled &&
                      menuItem.isDisabled({
                        row: row,
                        index: i,
                        dataSource: dataSource
                      })
                    "
                    mat-menu-item
                    type="button"
                  >
                    <mat-icon>{{ menuItem.icon }}</mat-icon>
                    <span class="menu-label">{{
                      menuItem.label | translate
                    }}</span>
                  </button>
                </div>
              </ng-container>
            </mat-menu>
          </ng-container>
        </ng-container>
      </mat-cell>
    </ng-container>
    <mat-header-row
      *matHeaderRowDef="displayedColumnNames"
      class="table-header"
    >
    </mat-header-row>
    <mat-row
      cdkDrag
      [cdkDragDisabled]="!config?.onPositionDrag || dragDisabled"
      [cdkDragData]="row"
      [ngClass]="{ highlight: config?.selectedEntityId == row.id }"
      (cdkDragReleased)="dragDisabled = true"
      *matRowDef="let row; columns: displayedColumnNames"
    >
    </mat-row>
  </mat-table>
  <div class="no-content-wrapper" *ngIf="dataSource?.empty">
    <div class="no-content">
      <mat-icon>manage_search</mat-icon>
      <p class="no-content-title">
        {{ 'common.list.noContent' | translate }}
        {{ config?.title | translate }}.
      </p>
      <div class="btn-container" *ngIf="config.createButton?.options?.length">
        <qa-button
          [isRaisedButton]="true"
          label="common.button.createNew"
          (submitted)="config.createButton?.options[0].click()"
          icon="add"
          [minWidth]="200"
          [showIconAtEnd]="true"
        ></qa-button>
        <qa-button
          *ngIf="config?.imports?.length"
          [isRaisedButton]="true"
          label="common.button.add"
          icon="file_upload"
          [minWidth]="200"
          [showIconAtEnd]="true"
          (submitted)="importContent()"
        ></qa-button>
      </div>
    </div>
    <ng-content select="[noContent]"></ng-content>
  </div>
</div>

<div
  class="multiselect-actions"
  [class.isContent]="isContent"
  *ngIf="
    config.multiselectActions &&
    selectedRows?.length &&
    config.multiselectActions?.length
  "
>
  <ng-container
    *ngFor="let action of config.multiselectActions"
    class="container-class"
  >
    <div class="for-all-section">
      <mat-icon class="for-all">close</mat-icon>
      <span
        >{{ selectedRows?.length }} {{ config.title | translate | lowercase }}
        {{ 'common.label.selected' | translate }}</span
      >
    </div>

    <qa-button
      (submitted)="multiSelectClick(action.click)"
      *ngIf="
        !action.hide ||
        (action.hide &&
          !action.hide({
            row: row,
            index: i,
            dataSource: dataSource
          }))
      "
      [isRaisedButton]="true"
      [icon]="
        isFunction(action.icon)
          ? action.icon({ dataSource: dataSource })
          : action.icon
      "
      type="button"
      [label]="action.name | translate"
      [showIconAtEnd]="true"
    ></qa-button>
  </ng-container>
</div>

<ng-template #createButtonImportList let-options="options">
  <div class="item-list create-list">
    <ng-container *ngFor="let item of options; let i = index">
      <div class="item" *ngIf="!item?.hidden">
        <div
          class="item-label"
          (click)="item?.click ? item.click(dataSource) : null"
        >
          <mat-icon>{{ item.icon }}</mat-icon>
          {{ item.text }}
        </div>
      </div>
      <ng-container
        *ngIf="!item.hidden && item.options"
        [ngTemplateOutlet]="createButtonImportList"
        [ngTemplateOutletContext]="{ options: item.options }"
      ></ng-container>
    </ng-container>
  </div>
</ng-template>
