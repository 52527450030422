import { UntypedFormGroup } from '@angular/forms';

export abstract class AbstractEditComponent {
  form: UntypedFormGroup;

  constructor() {}

  isDirty(): boolean {
    if (this.form.untouched) {
      return false;
    }
    return this.form.dirty;
  }
}
