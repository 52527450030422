import { Component, Input } from '@angular/core';

@Component({
  selector: 'qa-summary-end',
  templateUrl: './summary-end.component.html',
  styleUrls: ['./summary-end.component.scss'],
})
export class SummaryEndComponent {
  @Input() correctAnswers: number;
  @Input() inCorrectAnswers: number;
  @Input() totalQuestions: number;
  @Input() isFlashCard = false;
}
