import {
  Component,
  ElementRef,
  Inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { copyTextToClipboard } from 'src/app/utility/app.utils';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
import * as _sanitizeHTML from 'sanitize-html';
import { ExamsService } from 'src/app/api/services';
import { formatDate } from '@angular/common';
import { DialogService } from './dialog.service';
import { asShortLink } from 'src/app/app.component';

const sanitizeHTML = (_sanitizeHTML as any).default || _sanitizeHTML;

export interface InvitationTextDialogData {
  mode: string;
  pin_code: any;
  unsupervisedExam?: {
    startTime: any;
    endTime: any;
    timeToSolve: number;
  };
  interactive?: boolean;
  examId?: number;
  username?: string;
  onMailSent: (missingEmails: string[]) => void;
  address?: string;
  title?: string;
  text?: string;
  subject?: string;
  sendMailCallback?: (subject: string, content: string) => void;
}

@Component({
  selector: 'qa-invitationtext-dialog',
  templateUrl: './invitationtext-dialog.component.html',
  styleUrls: ['./invitationtext-dialog.component.scss'],
})
export class InvitationTextDialogComponent implements OnInit {
  text: string;
  blurInput = true;
  @ViewChild('textArea') textArea: ElementRef;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: InvitationTextDialogData,
    private matSnackBar: MatSnackBar,
    private translator: TranslateService,
    private dialogRef: MatDialogRef<InvitationTextDialogComponent>,
    private examsService: ExamsService,
    private dialog: DialogService
  ) {}

  ngOnInit() {
    this.dialogRef.updateSize('800px');
    if (!this.data.text) {
      this.initText();
    } else {
      this.text = this.data.text;
    }
  }

  private initText(): void {
    this.text = this.translator.instant(
      `${
        this.data.mode === 'live-event' ? 'liveEvent' : 'exam'
      }.setup.invitationTextDialog.text1`,
      {
        greeting: this.translator.instant(
          `common.label.greeting.${environment.edition}`
        ),
        pinCode: this.data.pin_code,
      }
    );

    this.text += this.translator.instant(
      `${
        this.data.mode === 'live-event' ? 'liveEvent' : 'exam'
      }.setup.invitationTextDialog.text2`,
      {
        url: asShortLink('%_PIN_CODE_%'),
        playlink: `https://play.google.com/store/apps/details?id=de.lecommsulting.${
          environment.edition === 'school'
            ? 'abiquiz'
            : environment.edition === 'university'
            ? 'uniquiz'
            : 'proquiz'
        }`,
        ioslink: `https://apps.apple.com/de/app/${
          environment.edition === 'school'
            ? 'quizacademy-school-edition/id1012949118'
            : environment.edition === 'university'
            ? 'quizacademy-university-edition/id1012962148'
            : 'quizacademy-business-edition/id1512823709'
        }`,
        forStudents:
          environment.edition === 'school'
            ? 'fuer-schueler'
            : 'fuer-studierende',
      }
    );
    if (this.data.mode === 'exam' && this.data.unsupervisedExam) {
      this.text += `${this.translator.instant(
        `exam.setup.invitationTextDialog.unsupInfo.unsupTitle`
      )}\n`;
      this.text += `${this.translator.instant(
        `exam.setup.invitationTextDialog.unsupInfo.startTime`
      )}\n`;
      this.text += `${this.translator.instant(
        `exam.setup.invitationTextDialog.unsupInfo.endTime`
      )}\n`;
      this.text += `${this.translator.instant(
        `exam.setup.invitationTextDialog.unsupInfo.timeToSolve`
      )}\n\n`;
    }
    if (
      this.data.mode === 'exam' &&
      (this.data.interactive || this.data.username)
    ) {
      this.text += this.translator.instant(
        `exam.setup.invitationTextDialog.username`
      );
    }

    this.text += this.translator.instant(
      `${
        this.data.mode === 'live-event' ? 'liveEvent' : 'exam'
      }.setup.invitationTextDialog.tipps`,
      {
        androidLink: environment.playStoreLink,
        iosLink: environment.appStoreLink,
      }
    );
    if (this.data.mode === 'exam' && this.data.unsupervisedExam) {
      this.text += this.translator.instant(
        `exam.setup.invitationTextDialog.tippsUnsupervised`
      );
    }
  }

  parseText(text) {
    text = text.replace(
      /(https?:\/\/(?:[^,\)\n\s]*))([\n|,|\)|\s])/gi,
      (_, group, suffix) => {
        return `<a href="${group}">${group}</a>${suffix}`;
      }
    );
    text = text.replace(/%\_(.*)\_%/gim, (match) => {
      return `<span class='placeholder'>${match}</span>`;
    });
    return this.sanitize(text);
  }

  private sanitize(input: string): string {
    return sanitizeHTML(input, {
      allowedTags: ['a', 'span'],
      allowedAttributes: {
        '*': ['href', 'title', 'class'],
      },
    });
  }

  onBlur() {
    this.text = this.textArea.nativeElement.value;
    this.blurInput = this.text ? true : false;
  }

  onEdit() {
    this.blurInput = false;
    setTimeout(() => {
      if (this.textArea) {
        this.textArea.nativeElement.focus();
      }
    }, 100);
  }

  replaceLocally(): string {
    return this.text.replace(/%\_(.*)\_%/gm, (_, group) => {
      switch (group) {
        case 'PIN_CODE':
          return `${this.data.pin_code}`;
        case `USER_NAME`:
          return this.data.username ?? '[USER NAME]';
        case `START_TIME`:
          return `${
            this.data.unsupervisedExam.startTime
              ? formatDate(
                  this.data.unsupervisedExam.startTime,
                  'd.M.yyyy HH:mm:ss',
                  'en-US'
                )
              : 'N/A'
          }`;
        case 'LAST_START_TIME':
          return `${
            this.data.unsupervisedExam.endTime
              ? formatDate(
                  this.data.unsupervisedExam.endTime,
                  'd.M.yyyy HH:mm:ss',
                  'en-US'
                )
              : 'N/A'
          }`;
        case 'DURATION':
          return `${
            this.data.unsupervisedExam.timeToSolve
              ? this.data.unsupervisedExam.timeToSolve
              : 0
          }`;
        default:
          return `[${group}]`;
      }
    });
  }

  copyWeblink() {
    if (this.text) {
      try {
        copyTextToClipboard(this.replaceLocally());
        this.matSnackBar.open(
          this.translator.instant(
            'liveEvent.setup.invitationTextDialog.copiedToClipboard'
          )
        );
      } catch (error) {
        this.matSnackBar.open(
          this.translator.instant(
            'liveEvent.setup.invitationTextDialog.notCopiedToClipboard'
          )
        );
      }
    } else {
      this.matSnackBar.open(
        this.translator.instant(
          'liveEvent.setup.invitationTextDialog.notCopiedToClipboard'
        )
      );
    }
  }

  parseToMailLink() {
    return `mailto:${this.data?.address || ''}?subject=${encodeURIComponent(
      this.data?.subject ??
        this.translator.instant('common.label.invitationTo', {
          name: this.translator.instant(
            this.data.mode === 'live-event'
              ? 'liveEvent.setup.aLiveEvent'
              : 'exam.setup.anExam'
          ),
        })
    )}&body=${encodeURIComponent(this.replaceLocally())}`;
  }

  sendMail() {
    const subject =
      this.data?.subject ??
      this.translator.instant('common.label.invitationTo', {
        name: this.translator.instant(
          this.data.mode === 'live-event'
            ? 'liveEvent.setup.aLiveEvent'
            : 'exam.setup.anExam'
        ),
      });
    const content = this.text;
    if (this.data.sendMailCallback) {
      this.data.sendMailCallback(subject, content);
      return;
    }
    if (this.data.interactive) {
      this.examsService
        .sendEmailInvitationToExamUsers({
          id: this.data.examId,
          body: {
            email: {
              content,
              subject,
            },
            mail_list: [],
          },
        })
        .subscribe((val: any) => {
          const resp = JSON.parse(val);
          if (resp.missing_emails && resp.missing_emails.length) {
            this.dialog.error(
              'Error',
              this.translator.instant('exam.setup.noMails', {
                missing_emails: resp.missing_emails
                  .map((mail) => ` -  ${mail}`)
                  .join('\n'),
              })
            );
          } else {
            this.matSnackBar.open(
              this.translator.instant('exam.setup.sentInvitation')
            );
          }
          if (this.data.onMailSent) {
            this.data.onMailSent(resp?.missing_emails || []);
          }
        });
    } else {
      window.open(this.parseToMailLink());
      if (this.data.onMailSent && this.data?.username) {
        this.data.onMailSent([]);
      }
    }
  }
}
