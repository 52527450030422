<div class="error-msg">
  <div class="concrete-error" *ngIf="parseError() !== null">
    {{ parseError() }}
  </div>
  {{ data?.text }}
  <div class="files">
    <a download target="_blank" [href]="data?.templateUrl?.link1.href">
      {{ data?.templateUrl?.link1.title }}
    </a>
    <a download target="_blank" [href]="data?.templateUrl?.link2.href">
      {{ data?.templateUrl?.link2.title }}
    </a>
  </div>
</div>
<mat-dialog-actions>
  <div class="flex-align-right">
    <button color="primary" mat-button mat-dialog-close type="button">
      {{ 'common.button.cancel' | translate }}
    </button>
  </div>
</mat-dialog-actions>
