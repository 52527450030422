import {
  Component,
  ElementRef,
  HostBinding,
  Inject,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatRipple } from '@angular/material/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { filter, takeUntil } from 'rxjs/operators';
import { cloneDeep } from 'lodash';
import { TranslateService } from '@ngx-translate/core';
import { DestroyNotifier } from './destroy-notifier';
import { QuestionType } from 'src/app/app-constants';
import { Question } from 'src/app/event/event.types';
import { PLAY_VIEW_DATA } from 'src/app/play/play.types';
import { AppState } from 'src/app/app.types';
import { EventActions } from 'src/app/event/event.actions';
import { CmsLiveEventActions } from '../live-event/cms-live-event.actions';
import { LiveEventsService } from 'src/app/api/services';
import { AnswerStatsModel } from 'src/app/api/models';
import { Visualization } from '../live-event/cms-live-event-result.component';
import { DonutSection } from 'src/app/widget/donut-chart.component';
import { shuffleAnswers } from 'src/app/utility/app.utils';
import { timer } from 'rxjs';

export interface QuestionComponentData {
  questionId: number;
  hideWeblink?: boolean;
}

@Component({
  selector: 'qa-question-cms-event',
  templateUrl: './question-cms-event.component.html',
  styleUrls: ['./question-cms-event.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class QuestionCmsEventComponent extends DestroyNotifier {
  @HostBinding('class.is-explanation-panel-open') get isExplanationPanelOpen() {
    return this.question && this.question.isExplanationPanelOpen;
  }
  @HostBinding('class.is-completed') get isCompleted() {
    return (
      this.question &&
      this.question.isCompleted &&
      !this.isSubmitAnswerRippleEntering
    );
  }
  @HostBinding('class.is-answered-correctly') get isAnsweredCorrectly() {
    return this.question && this.question.isAnsweredCorrectly === true;
  }
  @HostBinding('class.is-answered-incorrectly') get isAnsweredIncorrectly() {
    return this.question && this.question.isAnsweredCorrectly === false;
  }

  @ViewChild('submitAnswerRipple', { read: MatRipple })
  submitAnswerRipple: MatRipple;

  answersDisabled: boolean;
  answerSelectionControl = new UntypedFormControl();
  initialSelected: number | { [answerId: number]: boolean };
  isAnswerRetractionDisabled: boolean;
  questionType = QuestionType;
  isSubmitAnswerRippleEntering: boolean;
  question: Question;
  numOfUsers = 0;
  answerSubmitted = 0;
  state: AppState;
  answersShuffled = false;
  displayedAnswerStats: AnswerStatsModel[] = [];

  answerSections: DonutSection[];

  selectedVisualization: Visualization = 'list';

  get isSubmitAnswerDisabled() {
    if (!this.question) return true;
    return (
      this.question.model.type !== QuestionType.MultipleChoice &&
      this.question.selection === null
    );
  }

  constructor(
    @Inject(PLAY_VIEW_DATA) public data: QuestionComponentData,
    private domSanitizer: DomSanitizer,
    private elementRef: ElementRef<HTMLElement>,
    private liveEventService: LiveEventsService,
    private store: Store<AppState>,
    actions$: Actions,
    private translator: TranslateService
  ) {
    super();
    elementRef.nativeElement.classList.add('qa-question-cms-event');
    actions$
      .pipe(ofType(EventActions.completeQuestion))
      .subscribe(() => this.complete());
    store
      .pipe(filter((state) => !!state.event.questions))
      .subscribe((state) => {
        this.state = state;
        if (
          state.cmsLiveEvent &&
          state?.cmsLiveEvent?.liveEvent &&
          state?.cmsLiveEvent?.liveEvent.users
        ) {
          this.numOfUsers = state?.cmsLiveEvent?.liveEvent.users.length;
        }
        this.answersDisabled = state.event.answersDisabled;
        this.isAnswerRetractionDisabled =
          state.event.isAnswerRetractionDisabled;
        const question = state.event.questions.find((q) => {
          return q.model.id === this.data.questionId;
        });
        if (!question) return;
        if (
          !this.question &&
          question.model.is_poll_question &&
          question.model.type === QuestionType.FreeText
        ) {
          this.selectedVisualization = 'cloud';
        }
        this.question = cloneDeep(question);
        if (
          this.question.model.answers &&
          !this.answersShuffled &&
          this.question.model?.type !== QuestionType.TrueFalse
        ) {
          this.answersShuffled = true;
          this.question.model.answers = this.question.model.display_in_order
            ? this.question.model.answers
            : shuffleAnswers(
                this.question.model.answers,
                this.question?.model?.id ?? 99
              );
        }
        this.answerSelectionControl.setValue(this.question.selection, {
          emitEvent: false,
        });
      });
    setTimeout(() => {
      this.getStats();
    }, 3000);
    this.answerSelectionControl.valueChanges.subscribe((selection) => {
      this.store.dispatch(
        EventActions.updateAnswerSelection({
          questionId: this.question.model.id,
          selection,
        })
      );
    });
  }

  complete() {
    if (this.submitAnswerRipple) {
      this.isSubmitAnswerRippleEntering = true;
      const hostRect = this.elementRef.nativeElement.getBoundingClientRect();
      const x = hostRect.width + hostRect.left;
      const y = hostRect.height + hostRect.top;
      const enterDuration = 400;
      this.submitAnswerRipple.launch(x, y, {
        animation: { enterDuration, exitDuration: 400 },
      });
      setTimeout(() => {
        this.isSubmitAnswerRippleEntering = false;
      }, enterDuration);
    }
  }

  getCorrectAnswers() {
    return this.question.model.answers.filter((a) => a.is_right);
  }

  getCorrectAnswerText(): string {
    switch (this.question.model.type) {
      case QuestionType.MultipleChoice:
        const correctAnswers = this.question.model.answers.filter(
          (a) => a.is_right
        );
        if (!correctAnswers.length)
          return this.translator.instant('question.message.noneCorrectAns');
        return this.translator.instant('question.message.correctMultiAns');
      case QuestionType.TrueFalse:
        return this.question.model.is_right
          ? this.translator.instant('question.message.correctStatement')
          : this.translator.instant('question.message.wrongStatement');
      case QuestionType.SingleChoice:
        return this.translator.instant('question.message.correctSingleAns');
      default:
        throw new Error('Unsupported question type.');
    }
  }

  getWeblink(): SafeUrl {
    let weblink = this.question.model.weblink;
    if (!weblink) {
      return null;
    }
    if (!weblink.includes('http')) {
      weblink = `http://${weblink}`;
    }
    return this.domSanitizer.bypassSecurityTrustUrl(weblink);
  }

  retractAnswer() {
    this.store.dispatch(
      EventActions.retractAnswer({
        questionId: this.question.model.id,
      })
    );
  }

  getStats() {
    if (this.state?.cmsLiveEvent?.liveEvent) {
      timer(20, 4000)
        .pipe(takeUntil(this.destroy$))
        .subscribe(() => {
          if (
            !this.state?.cmsLiveEvent?.liveEvent ||
            !this.state?.cmsLiveEvent?.liveEvent.active_question_id
          ) {
            this.ngOnDestroy();
          }
          this.liveEventService
            .getQuestionsStatisticsInLiveEvent({
              id: this.state?.cmsLiveEvent?.liveEvent
                ? this.state?.cmsLiveEvent?.liveEvent.id
                : null,
              question_id: this.state?.cmsLiveEvent?.liveEvent
                ? this.state?.cmsLiveEvent?.liveEvent.active_question_id
                : null,
            })
            .subscribe((questionStat) => {
              this.answerSubmitted = questionStat.answers_total;
              this.store.dispatch(
                CmsLiveEventActions.storeTotalAnswers({
                  answers: this.answerSubmitted,
                })
              );
              this.store.dispatch(
                CmsLiveEventActions.storeResult({
                  result: questionStat,
                })
              );
              this.displayedAnswerStats = questionStat.answer_stats;
              if (
                this.question.model.is_poll_question &&
                this.displayedAnswerStats &&
                this.displayedAnswerStats.length
              ) {
                this.answerSections = this.displayedAnswerStats.map((stat) => ({
                  label: stat.answer.text,
                  value: stat.answers_total,
                }));
              }
            });
        });
    }
  }

  getProgressAnswer(submittedAnswer, totalUser) {
    return (submittedAnswer * 100) / totalUser;
  }

  submitAnswer() {
    this.store.dispatch(
      EventActions.submitAnswer({
        questionId: this.question.model.id,
      })
    );
  }

  getAnswerBarWidth(amount: number): string {
    const total = this.displayedAnswerStats.reduce(
      (acc, c) => acc + c.answers_total,
      0
    );
    const quotient = amount / total || 0;
    return `${Math.round(quotient * 100)}%`;
  }

  mapToStrings(input: AnswerStatsModel[]): string[] {
    return input
      ? input.reduce(
          (p, i) => [...p, ...new Array(i.answers_total).fill(i.answer.text)],
          []
        )
      : [];
  }
}
