<div class="ai-stack-import-questions">
  <qa-content-spinner
    [active]="loading"
    [loadingText]="'StackAIQuestion.loadingText' | translate"
  >
    <div class="header">
      <h1 class="dialog-title">
        {{ 'StackAIQuestion.mainHeader' | translate }}
      </h1>
      <div class="close-icon" mat-dialog-close><mat-icon>close</mat-icon></div>
    </div>
    <div *ngIf="showInfo && !showGeneratedStack">
      <ng-content *ngTemplateOutlet="actions"></ng-content>
    </div>
    <div *ngIf="!showInfo && showGeneratedStack">
      <ng-content *ngTemplateOutlet="showCard"></ng-content>
    </div>
  </qa-content-spinner>

  <ng-template #actions>
    <div class="generate-question-info">
      {{
        'StackAIQuestion.questionImportDetails.generateQuestoinDescriptionOne'
          | translate
      }}
    </div>
    <div class="generate-question-info">
      {{
        'StackAIQuestion.questionImportDetails.importQuestiondesc' | translate
      }}
    </div>
    <div class="dialog-info-conatiner">
      <ng-container *ngIf="course">
        <div class="dialog-info-content">
          <div class="title-text">
            {{ 'course.form.category' | translate }}:
          </div>
          <div class="info">
            {{ course?.category?.name ? course?.category?.name : '' }}
          </div>
        </div>
        <div class="dialog-info-content">
          <div class="title-text">
            {{ 'StackAIQuestion.courseTitle' | translate }}:
          </div>
          <div class="info">
            {{ course?.name ? course?.name : '' }}
          </div>
        </div>
        <div class="dialog-info-content">
          <div class="title-text">
            {{ 'StackAIQuestion.stackTitle' | translate }}:
          </div>
          <div class="info">
            {{ stack?.name ? stack?.name : '' }}
          </div>
        </div>
      </ng-container>
    </div>
    <div class="generate-question-info">
      {{
        'StackAIQuestion.questionImportDetails.generateQuestoinDescriptionTwo'
          | translate
      }}
    </div>
    <div class="keyword">
      <qa-tag-input
        [control]="keywords"
        label="{{ 'StackAIQuestion.keywords' | translate }}"
        [limitCharacters]="true"
      >
      </qa-tag-input>
    </div>
    <div class="generate-btn generate-question-btn">
      <qa-button
        label="{{ 'StackAIQuestion.cancel' | translate }}"
        mat-dialog-close
      ></qa-button>
      <qa-button
        [isRaisedButton]="true"
        [disabled]="keywords.invalid"
        label="{{ 'StackAIQuestion.generate' | translate }}"
        (submitted)="generateStackAICard()"
      ></qa-button>
    </div>
  </ng-template>
  <ng-template #showCard>
    <div class="generated-question-container">
      <div class="question-header">
        {{ 'StackAIQuestion.proposal' | translate }}:
      </div>
      <div class="generated-question-contents">
        <div class="item-container">
          <label>Text</label>
          <div class="item-value">{{ generatedStackResult?.question }}</div>
        </div>
        <div class="item-container">
          <label>Answer</label>
          <div class="item-value">{{ generatedStackResult?.answer }}</div>
        </div>
        <div class="item-container">
          <label>Explanation</label>
          <div class="item-value">{{ generatedStackResult?.explanation }}</div>
        </div>
        <div class="generate-question-info keyword">
          {{
            'StackAIQuestion.questionImportDetails.changeQuestionDetails'
              | translate
          }}
        </div>
        <div class="button-container import-question-section">
          <div class="cancel">
            <qa-button
              label="{{ 'StackAIQuestion.cancel' | translate }}"
              mat-dialog-close
            ></qa-button>
          </div>
          <div class="ai-question-action-btns">
            <div class="generate-questions">
              <qa-button
                [isIconButton]="true"
                icon="refresh"
                (submitted)="generateAICardAgain()"
              ></qa-button>
            </div>
            <div class="">
              <qa-button
                [isRaisedButton]="true"
                label="{{ 'StackAIQuestion.retry' | translate }}"
                (submitted)="retry()"
              ></qa-button>
            </div>
            <qa-button
              [isRaisedButton]="true"
              [matMenuTriggerFor]="addMenu"
              label="{{ 'StackAIQuestion.importQuestion' | translate }}"
              #addButton
            ></qa-button>
            <mat-menu
              class="material-menu"
              [overlapTrigger]="true"
              #addMenu="matMenu"
            >
              <button mat-menu-item type="button" (click)="saveCardAndNext()">
                <mat-icon>add</mat-icon>
                {{ 'StackAIQuestion.saveAndNext' | translate }}
              </button>
              <button
                [disabled]="creatingCard"
                (click)="importAICard()"
                mat-menu-item
                type="button"
              >
                <mat-icon>check</mat-icon>
                {{ 'StackAIQuestion.save' | translate }}
              </button>
            </mat-menu>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
</div>
