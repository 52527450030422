<div class="can-not-find-container">
  <span class="title">{{ 'courseMarket.cantFind.title' | translate }}</span>
  <div class="can-not-find-body">
    <div>
      {{ 'courseMarket.cantFind.text' | translate }}
      <a
        mat-button
        color="primary"
        target="_blank"
        href="https://quizacademy.de/business/anfrage-content/"
      >
        {{ 'courseMarket.cantFind.button' | translate }}
        <mat-icon>chevron_right</mat-icon>
      </a>
    </div>
  </div>
</div>
