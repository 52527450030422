import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { DestroyNotifier } from 'src/app/destroy-notifier';
import { DateRange } from 'src/app/app.types';
import * as dayjs from 'dayjs';

@Injectable()
export class DateRangePickerService extends DestroyNotifier {
  dateRange: BehaviorSubject<DateRange>;

  constructor() {
    super();
    let initialRange: DateRange = {
      from: dayjs().subtract(29, 'd').toDate().toISOString(),
      to: dayjs().toISOString(),
    };
    // const storedDateRange: DateRange = JSON.parse(
    //   localStorage.getItem('DateRangePickerService.dateRange')
    // );
    // if (storedDateRange && storedDateRange.from && storedDateRange.to) {
    //   initialRange = storedDateRange;
    // }
    this.dateRange = new BehaviorSubject<DateRange>(initialRange);
  }

  updateDateRange(range: DateRange) {
    if (
      range.from !== this.dateRange.value.from ||
      range.to !== this.dateRange.value.to
    ) {
      // localStorage.setItem(
      //   'DateRangePickerService.dateRange',
      //   JSON.stringify(range)
      // );
      this.dateRange.next(range);
    }
  }
}
