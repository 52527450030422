import { Injectable } from '@angular/core';
import { FlashCardStackModel } from 'src/app/api/models';
import {
  BooksService,
  CoursesService,
  FlashCardStacksService,
} from 'src/app/api/services';
import { PanelService } from '../panel.service';
import { Panel, PanelEntityType } from '../panel.types';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from 'src/app/auth/auth.service';
import { Router } from '@angular/router';
import { StackEditConfigService } from '../../edit/configs/stack.service';
import { of } from 'rxjs';
import * as _ from 'lodash';

export const getStackImports = (
  translator: TranslateService,
  panelService: PanelService,
  panel: Panel<FlashCardStackModel>,
  courseContext = false
) =>
  [
    courseContext
      ? {
          title: translator.instant('content.flashCardStack.title.item1'),
          description: translator.instant(
            'content.flashCardStack.description.item1'
          ),
          action: {
            icon: 'move_to_inbox',
            click: () => panelService.importItems(panel),
            text: translator.instant('content.flashCardStack.addMenu.item2'),
          },
        }
      : null,
    {
      title: translator.instant('content.flashCardStack.title.item2'),
      description: translator.instant(
        'content.flashCardStack.description.item2'
      ),
      action: {
        icon: 'code',
        click: () => panelService.importItemAsJSON(panel),
        text: translator.instant('content.flashCardStack.addMenu.item3'),
      },
    },
  ].filter((n) => n);

@Injectable()
export class FlashCardStackPanel extends Panel<FlashCardStackModel> {
  isBook = false;

  constructor(
    private courses: CoursesService,
    private flashCardStacks: FlashCardStacksService,
    private panelService: PanelService,
    private translator: TranslateService,
    private router: Router,
    private books: BooksService,
    private auth: AuthService
  ) {
    super();
    this.initializePanel();
  }

  initializePanel() {
    this.isBook =
      location?.pathname?.split('/')?.[3] === 'book' ||
      this.router.url.indexOf('/book') !== -1;
    super.init({
      addMenuItems: [
        {
          icon: 'add',
          onClick: () => this.panelService.editItem(this),
          text: this.translator.instant('content.flashCardStack.addMenu.item1'),
        },
      ],
      imports: getStackImports(this.translator, this.panelService, this, true),
      childTypes: [PanelEntityType.FlashCard],
      changeItemPosition: (flashCardStackId, courseId, position) => {
        const query = {
          id: courseId,
          stack_id: flashCardStackId,
          body: { position },
        };
        return this.isBook
          ? this.books.repositionCardStackInBook(query)
          : this.courses.repositionCardStackInCourse(query);
      },
      changeItemStatus: (flashCardStackId, courseId, active) => {
        const query = {
          id: courseId,
          stack_id: flashCardStackId,
          body: { active },
        };
        return this.isBook
          ? this.books.repositionCardStackInBook(query)
          : this.courses.repositionCardStackInCourse(query);
      },
      createItemsFn: (items, parentId) => {
        const query = { id: parentId, body: items, copyCards: false };
        return this.isBook
          ? this.books.copyCardStackToBook(query)
          : this.courses.copyCardStackToCourse(query);
      },
      deleteItem: (flashCardStackId) =>
        this.flashCardStacks.deleteFlashCardStack({ id: flashCardStackId }),
      editConfigService: StackEditConfigService,
      editConfigServiceParams: (_, courseId) => ({ courseId }),
      editDialogInput: (flashCardStack, courseId) => ({
        flashCardStack,
        courseId,
      }),
      fetchItem: (id) =>
        id ? this.flashCardStacks.getFlashCardStackById({ id }) : of(null),
      fetchItems: (courseId) => {
        if (courseId && !_.isNaN(courseId)) {
          return this.isBook
            ? this.books.getCardStacksInBook({ id: courseId })
            : this.courses.getCardStacksInCourse({ id: courseId });
        } else {
          return of(null);
        }
      },
      headerIcon: 'layers',
      isItemActive: (flashCardStack) => flashCardStack.active,
      activateItemAction: {
        icon: 'visibility',
        label: this.translator.instant('content.quiz.actionMenu.item1'),
        onClick: (flashCardStack) => {
          return this.panelService.changeItemStatus(
            this,
            flashCardStack.id,
            !flashCardStack.active
          );
        },
      },
      itemActions: [
        {
          icon: 'print',
          label: this.translator.instant('flashCardStack.print.title'),
          onClick: (flashCard) =>
            this.router.navigateByUrl(`/cms/flash-cards/print/${flashCard.id}`),
        },
        {
          icon: 'code',
          label: this.translator.instant(
            'content.flashCardStack.actionMenu.item7'
          ),
          onClick: (stack) => this.panelService.embedItem(this, stack),
        },
        {
          icon: 'description',
          label: this.translator.instant(
            'content.flashCardStack.actionMenu.item6'
          ),
          onClick: (item) => this.panelService.exportItemAsCSV(this, item.id),
        },
        {
          icon: 'code',
          label: this.translator.instant(
            'content.flashCardStack.actionMenu.item3'
          ),
          onClick: (item) => this.panelService.exportItemAsJSON(this, item.id),
        },
        {
          icon: 'link_off',
          label: this.isBook
            ? this.translator.instant(
                'content.flashCardStack.actionMenu.item4Book'
              )
            : this.translator.instant(
                'content.flashCardStack.actionMenu.item4'
              ),
          onClick: (flashCardStack) =>
            this.panelService.unlinkItems(this, flashCardStack.id),
        },
        {
          icon: 'delete',
          label: this.translator.instant(
            'content.flashCardStack.actionMenu.item5'
          ),
          onClick: (flashCardStack) =>
            this.panelService.deleteItem(this, flashCardStack.id),
          disabled: (stack) =>
            stack.created_by !== this.auth.currentUserInfo$.value.sub,
          tooltip: (stack) => {
            if (stack.created_by !== this.auth.currentUserInfo$.value.sub) {
              return this.translator.instant('content.quiz.notYourStack');
            } else {
              return null;
            }
          },
        },
      ],
      itemTextProperty: 'name',
      parentKey: 'card_stacks',
      parentType: PanelEntityType.Course,
      selectedItemActions: [
        {
          icon: 'link_off',
          label: this.isBook
            ? this.translator.instant(
                'content.flashCardStack.selectedItem.labelBook'
              )
            : this.translator.instant(
                'content.flashCardStack.selectedItem.label'
              ),
          onClick: (selectedItemIds) =>
            this.panelService.unlinkItems(this, selectedItemIds),
        },
      ],
      translations: {
        addItem: this.translator.instant('content.flashCardStack.tooltip'),
        changeItemStatusDisabled: this.translator.instant(
          'content.flashCardStack.changeItem.statusDisabled'
        ),
        changeItemStatusEnabled: this.translator.instant(
          'content.flashCardStack.changeItem.statusEnabled'
        ),
        deleteItemError: this.translator.instant(
          'content.flashCardStack.deleteItem.error'
        ),
        deleteItemSuccess: this.translator.instant(
          'content.flashCardStack.deleteItem.success'
        ),
        deleteItemText: this.translator.instant(
          'content.flashCardStack.deleteItem.text'
        ),
        deleteItemTitle: this.translator.instant(
          'content.flashCardStack.deleteItem.title'
        ),
        header: this.translator.instant('common.label.flashCardStack'),
        unlinkItemConfirmText: this.isBook
          ? this.translator.instant(
              'content.flashCardStack.unlinkItem.textBook'
            )
          : this.translator.instant('content.flashCardStack.unlinkItem.text'),
        unlinkItemConfirmTitle: this.isBook
          ? this.translator.instant(
              'content.flashCardStack.unlinkItem.titleBook'
            )
          : this.translator.instant('content.flashCardStack.unlinkItem.title'),
        unlinkItemError: this.isBook
          ? this.translator.instant(
              'content.flashCardStack.unlinkItem.errorBook'
            )
          : this.translator.instant('content.flashCardStack.unlinkItem.error'),
        unlinkItemSuccess: this.isBook
          ? this.translator.instant(
              'content.flashCardStack.unlinkItem.successBook'
            )
          : this.translator.instant(
              'content.flashCardStack.unlinkItem.success'
            ),
      },
      type: PanelEntityType.FlashCardStack,
      unlinkItems: (flashCardStackIds, courseId) => {
        const query = {
          id: courseId,
          stack_ids: flashCardStackIds.join(','),
        };
        return this.isBook
          ? this.books.removeCardStackFromBook(query)
          : this.courses.removeCardStackFromCourse(query);
      },
    });
  }
}
