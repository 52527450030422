<h1 mat-dialog-title>
  {{ 'markdown.title' | translate }}
</h1>
<mat-dialog-content>
  <p>
    {{ 'markdown.text1' | translate }}
  </p>
  <p>
    {{ 'markdown.text2' | translate }}
  </p>
  <p>
    {{ 'markdown.text3' | translate }}
  </p>
  <ng-container *ngFor="let link of links">
    <a [href]="link.url" color="primary" mat-button target="_blank">
      <mat-icon>open_in_new</mat-icon>
      {{ link.textKey }}
    </a>
    <br />
  </ng-container>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button color="primary" mat-button mat-dialog-close>
    {{ 'common.button.shutdown' | translate }}
  </button>
</mat-dialog-actions>
