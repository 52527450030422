import { animate, style, transition, trigger } from '@angular/animations';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import _ from 'lodash';
import { filter, Observable, tap } from 'rxjs';
import { QuestionModel } from 'src/app/api/models';
import { AppState, questionTypeDisplayFn } from 'src/app/app.types';
import { DialogService } from '../../common/dialog.service';
import { CmsLiveEventActions } from '../../live-event/cms-live-event.actions';
import { Panel } from '../../panel/panel.types';
import { MatDialog } from '@angular/material/dialog';
import {
  ImportContentDialogComponent,
  ImportContentDialogData,
} from '../../common/import-content-dialog/import-content-dialog.component';
import { EventsPanel } from '../../panel/panels/events-panel';
import { EventQuestionPanel } from '../../panel/panels/event-question-panel';
import { PanelService } from '../../panel/panel.service';
import { getEventQuestionImports } from '../../panel/panels/event-question-panel';
import {
  ExamQuestionPanel,
  getExamQuestionsImports,
} from '../../panel/panels/exam-question-panel';
import { ExamPanel } from '../../panel/panels/exam-panel';
import * as fileSaver from 'file-saver';
import { ImportEntity } from '../../list/list.types';
import { ImportSnackBarComponent } from '../../panel/import-snack-bar.component';
import {
  ExamsService,
  LiveEventsService,
  QuestionsService,
} from 'src/app/api/services';
import {
  ImportResultBottomSheetComponent,
  ImportResultBottomSheetData,
} from '../../panel/import-result-bottom-sheet.component';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { AuthService } from 'src/app/auth/auth.service';

@Component({
  selector: 'qa-live-question-setup',
  templateUrl: './live-question-setup.component.html',
  styleUrls: ['./live-question-setup.component.scss'],
  animations: [
    trigger('fade', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('100ms linear', style({ opacity: 1 })),
      ]),
      transition(':leave', [animate('100ms ease-out', style({ opacity: 0 }))]),
    ]),
  ],
})
export class LiveQuestionSetupComponent implements OnInit, OnChanges {
  @Input() questions: QuestionModel[];
  @Input() allowPollQuestions: boolean;
  @Input() queryParams: { [key: string]: any };
  @Input() repositionCallback: (
    event: CdkDragDrop<QuestionModel>
  ) => Observable<void>;
  @Input() removeCallback: (questionId: number) => Observable<void>;
  @Input() translationKey: string = 'liveEvents';

  @Output() startClick = new EventEmitter<void>();
  @Output() questionsUpdated = new EventEmitter<void>();
  @Input() isLiveEvent: boolean = false;
  questionTypeDisplayFn = questionTypeDisplayFn;
  selectedQuestion: QuestionModel;
  isLoadingLeft = true;
  foldedSidebar = true;
  selectedQuestionid;
  courseId;
  panels: Array<Panel<any>>;
  imports: ImportEntity<QuestionModel>[];

  constructor(
    private elementRef: ElementRef,
    private router: Router,
    activatedRoute: ActivatedRoute,
    private translator: TranslateService,
    private dialog: DialogService,
    private matSnackBar: MatSnackBar,
    private store: Store<AppState>,
    private matDialog: MatDialog,
    private eventsPanel: EventsPanel,
    private eventsQuestionPanel: EventQuestionPanel,
    private panelsService: PanelService,
    private examPanel: ExamPanel,
    private examQuestionsPanel: ExamQuestionPanel,
    private liveEventsService: LiveEventsService,
    private examsService: ExamsService,
    private questionsService: QuestionsService,
    private matBottomSheet: MatBottomSheet,
    private auth: AuthService
  ) {
    this.elementRef.nativeElement.classList.add('qa-live-question-setup');

    this.panels = [
      this.eventsPanel,
      this.eventsQuestionPanel,
      this.examPanel,
      this.examQuestionsPanel,
    ];
    this.panelsService.init(this.panels);

    activatedRoute.queryParams.subscribe((params) => {
      if (params.courseId || params.bookId) {
        this.courseId = params.courseId || params.bookId;
      }
    });
  }

  ngOnInit(): void {
    this.loadImports();
    this.panelsService.importChatGPT$.subscribe((r) => {
      if (r) {
        this.questionsUpdated.emit();
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.questions) {
      this.isLoadingLeft = false;
    }
  }

  foldSidebar(event) {
    event.stopPropagation();
    this.closeSideMenu();
  }

  closeSideMenu(removeQuestionFromList = false) {
    this.foldedSidebar = !this.foldedSidebar;
    if (removeQuestionFromList) {
      this.questions = this.questions.filter(
        (q) => q.id !== this.selectedQuestion.id
      );
    }
    if (this.foldedSidebar) {
      this.selectedQuestion = null;
      this.selectedQuestionid = null;
    }
  }

  afterItemDropped(event: CdkDragDrop<QuestionModel>) {
    moveItemInArray(this.questions, event.previousIndex, event.currentIndex);
    this.repositionCallback(event).subscribe(() => {});
    this.selectedQuestionid = null;
  }

  onselectQuestion(question) {
    this.selectedQuestion = question;
    this.foldedSidebar = false;
    this.selectedQuestionid = this.questions.findIndex(
      (x) => x.id === question.id
    );
  }

  addQuestion(isPoll?: boolean) {
    const query = _.cloneDeep(this.queryParams);
    if (isPoll) {
      query['isPoll'] = true;
    }
    this.router.navigate(['/cms/add/question'], { queryParams: query });
  }

  editQuestion(event, questionId: number) {
    event.stopPropagation();
    this.router.navigate(['/cms/edit/question', questionId], {
      queryParams: this.queryParams,
    });
  }

  loadImports() {
    let imports: ImportEntity<QuestionModel>[] = null;
    if (this.queryParams.liveEventId) {
      imports = getEventQuestionImports(
        this.translator,
        this.panelsService,
        this.matDialog,
        this.panels[1],
        !!this.courseId,
        this.queryParams.liveEventId,
        this.auth.currentUserHasGroup('Admin') ||
          this.auth.currentUserHasGroup('ContentManager')
      );
    } else if (this.queryParams.exam) {
      imports = getExamQuestionsImports(
        this.translator,
        this.panelsService,
        this.matDialog,
        this.panels[3],
        !!this.courseId,
        this.queryParams.exam,
        this.auth.currentUserHasGroup('Admin') ||
          this.auth.currentUserHasGroup('ContentManager')
      );
    }
    this.imports = imports;
  }

  importItem() {
    if (!this.imports?.length) return;
    const fIdx = this.imports.findIndex((im) => im.id === 'import-json');
    if (fIdx !== -1) {
      this.imports[fIdx].action.click = () => {
        this.importQuestionFromJson(!!this.queryParams.exam);
      };
    }

    const dialogRef = this.matDialog.open<
      ImportContentDialogComponent,
      ImportContentDialogData
    >(ImportContentDialogComponent, {
      data: {
        imports: this.imports,
        dataSource: null,
        title: this.translator.instant('content.exam.questionImport'),
      },
      maxHeight: '90vh',
    });
    dialogRef.afterClosed().subscribe(() => {
      this.questionsUpdated.emit();
      setTimeout(() => {
        this.panelsService.importComplete$.next(true);
      }, 1000);
    });
  }

  async importQuestionFromJson(isExam = false) {
    const jsonFile = (await this.panelsService.promptFile(
      'json'
    )) as QuestionModel;
    if (jsonFile?.text) {
      const importProgress = {
        current: 0,
        total: 1,
      };
      const snackBarRef = this.matSnackBar.openFromComponent(
        ImportSnackBarComponent,
        {
          data: {
            progress: importProgress,
            message: 'content.exam.questionImport',
          },
          duration: null,
        }
      );
      if (isExam) {
        await this.examsService
          .copyQuestionToExam({ id: this.queryParams.exam, body: [jsonFile] })
          .toPromise();
      } else {
        await this.liveEventsService
          .copyQuestionToEvent({
            id: this.queryParams.liveEventId,
            body: [jsonFile],
          })
          .toPromise();
      }
      importProgress.current += 1;
      snackBarRef.dismiss();
      this.matDialog.closeAll();
      const bottomSheetData: ImportResultBottomSheetData = {
        info: {
          current: importProgress.current,
          total: importProgress.total,
          failedItems: [],
        },
      };
      this.matBottomSheet.open(ImportResultBottomSheetComponent, {
        data: bottomSheetData,
      });
    }
  }

  startEntity() {
    if (!this.questions.length) {
      return;
    }
    this.startClick.emit();
  }

  openGPT() {
    const gptImport = this.imports.find((imp) => imp.id === 'chat-gpt');
    if (gptImport) {
      gptImport.action.click();
    }
  }

  get showChatGpt(): boolean {
    return (
      this.auth.currentUserHasGroup('Admin') ||
      this.auth.currentUserHasGroup('ContentManager')
    );
  }

  exportItemAsJSON(question: QuestionModel) {
    const exportQuestion = JSON.parse(JSON.stringify(question));
    delete exportQuestion.id;
    const panel = this.queryParams.liveEventId
      ? this.eventsQuestionPanel
      : this.examQuestionsPanel;
    exportQuestion['meta:importType'] = panel.type;
    const file = `${JSON.stringify(exportQuestion)}\n`;
    const blob = new Blob([file], { type: 'application/json' });
    fileSaver.saveAs(
      blob,
      `qa-${panel.type}-${exportQuestion[panel.itemTextProperty]}.json`
    );
  }

  removeQuestion(questionId: number) {
    this.dialog
      .confirm({
        title: this.translator.instant(
          `${this.translationKey}.label.removeQuestion`
        ),
        text: this.translator.instant(
          `${this.translationKey}.label.removeFromInfo`
        ),
        buttons: [
          {
            text: this.translator.instant('common.button.abort'),
            value: false,
            color: 'warn',
          },
          {
            text: this.translator.instant('common.button.remove'),
            value: true,
            color: 'primary',
          },
        ],
      })
      .pipe(
        filter((isConfirmed) => isConfirmed),
        tap(() => (this.isLoadingLeft = true))
      )
      .subscribe(() => {
        this.removeCallback(questionId).subscribe({
          next: () => {
            this.matSnackBar.open(
              this.translator.instant(
                `${this.translationKey}.message.deleteQuestion`
              )
            );
            this.isLoadingLeft = false;
          },
          error: () => {
            this.matSnackBar.open(
              this.translator.instant(
                `${this.translationKey}.message.deleteQuestionError`
              )
            );
          },
        });
      });
  }

  onDelete(questionId: number) {
    this.questionsService
      .deleteQuestion({
        id: questionId,
      })
      .subscribe({
        next: () => {
          this.questionsUpdated.emit();
          this.matSnackBar.open(
            this.translator.instant(
              `${this.translationKey}.message.deleteQuestion`
            )
          );
          this.isLoadingLeft = false;
        },
        error: () => {
          this.matSnackBar.open(
            this.translator.instant(
              `${this.translationKey}.message.deleteQuestionError`
            )
          );
        },
      });
  }

  deleteQuestion(questionId: number) {
    this.dialog
      .confirm({
        title: this.translator.instant(
          `${this.translationKey}.label.deleteQuestion`
        ),
        text: this.translator.instant(
          `${this.translationKey}.label.deleteFromInfo`
        ),
        buttons: [
          {
            text: this.translator.instant('common.button.abort'),
            value: false,
            color: 'warn',
          },
          {
            text: this.translator.instant('liveEvents.label.delete'),
            value: true,
            color: 'primary',
          },
        ],
      })
      .pipe(
        filter((isConfirmed) => isConfirmed),
        tap(() => (this.isLoadingLeft = true))
      )
      .subscribe(() => {
        this.onDelete(questionId);
      });
  }

  updateQuestions() {
    if (this.queryParams.liveEventId) {
      this.store.dispatch(
        CmsLiveEventActions.updateQuestions({
          liveEventId: this.queryParams.liveEventId,
          initEvent: true,
        })
      );
    } else {
      this.questionsUpdated.emit();
    }
  }
}
