import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'qa-csv-import-progress-dialog',
  templateUrl: './csv-import-progress-dialog.component.html',
  styleUrls: ['./csv-import-progress-dialog.component.scss'],
})
export class CsvImportProgressDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<CsvImportProgressDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  getPercentage(total, current) {
    return current ? (current * 100) / total : 0;
  }
}
