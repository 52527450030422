import { Component, Input } from '@angular/core';
import { StackSummaryStatistics } from 'src/app/api/models';
import { PanelService } from 'src/app/cms/panel/panel.service';
import { PanelEntityType } from 'src/app/cms/panel/panel.types';

@Component({
  selector: 'qa-stack-stats',
  templateUrl: './stack-stats.component.html',
  styleUrls: ['./stack-stats.component.scss'],
})
export class StackStatsComponent {
  @Input() stats: StackSummaryStatistics[];
  @Input() courseId;

  constructor(private panelService: PanelService) {}

  onStackClick(stat: StackSummaryStatistics) {
    this.panelService.removeParam(PanelEntityType.Quiz);
    this.panelService.addParam(PanelEntityType.FlashCardStack, stat.stack.id);
  }
}
