<div class="explanation-trigger" *ngIf="info" [class.open]="explanationOpen">
  <button
    mat-icon-button
    (click)="explanationOpen = !explanationOpen"
    [matTooltip]="title | translate"
    [matTooltipPosition]="'above'"
  >
    <mat-icon> {{ explanationOpen ? 'close' : icon }}</mat-icon>
  </button>
  <div class="explanation-content">
    <label class="title" *ngIf="explanationOpen && title">{{ title }}</label>
    <ng-scrollbar>
      <qa-markdown-katex [data]="info"> </qa-markdown-katex>
    </ng-scrollbar>
  </div>
</div>
