import {
  AfterViewInit,
  Component,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { PanelEntity } from '../../panel/panel.types';
import { animate, style, transition, trigger } from '@angular/animations';
import {
  ConfigOption,
  ListActionMeta,
  ListConfig,
  ListQuery,
} from '../list.types';
import { getCourseListConfig } from '../course-list.component';
import { CancelConfirmationComponent } from '../../common/dialogs/cancel-confirmation.component';
import {
  AccessRequestsService,
  CategoriesService,
  CoursesService,
  UsersService,
} from 'src/app/api/services';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import {
  BaseQueryResponse,
  CategoryModel,
  CourseModel,
  CourseQuery,
  OrganizationModel,
} from 'src/app/api/models';
import { Location } from '@angular/common';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { DialogService } from '../../common/dialog.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { catchError, filter, map } from 'rxjs/operators';
import { AuthService } from 'src/app/auth/auth.service';
import { UserJourneyService } from '../../pages/onboarding-steps/user-journey.service';
import { of } from 'rxjs';
import { PanelService } from '../../panel/panel.service';
import * as _ from 'lodash';
import { UserConfigService } from '../../user-config.service';
import { ListDataSource } from '../list-data-source';
import { OrganizationForCourseComponent } from './organization-for-course/organization-for-course.component';
import { EditService } from '../../edit/edit.service';
import { parseCategoryTree } from 'src/app/utility/app.utils';
import { SubscriptionLimitService } from '../../common/dialogs/subscription-limit-warning/subscription-limit.service';

@Component({
  selector: 'qa-course-market',
  templateUrl: './course-market.component.html',
  styleUrls: ['./course-market.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger('parent', [transition(':enter', [])]),
    trigger('enterAnimation', [
      transition(':enter', [
        style({ transform: 'translateX(100%)', opacity: 0 }),
        animate('300ms', style({ transform: 'translateX(0)', opacity: 1 })),
      ]),
      transition(':leave', [
        style({ transform: 'translateX(0)', opacity: 1 }),
        animate('300ms', style({ transform: 'translateX(100%)', opacity: 0 })),
      ]),
    ]),
  ],
})
export class CourseMarketComponent implements OnInit, AfterViewInit, OnDestroy {
  loading = true;
  selectedItems: CourseModel[] = [];
  categoriesList: any[] = [];
  courseListConfig: ListConfig<CourseModel>;
  klettListConfig: ListConfig<CourseModel>;
  activeListConfig?: ListConfig<CourseModel> | undefined;
  courseMarketSource: ListDataSource;
  showFindOutMore = false;
  organizations: OrganizationModel[];

  get url() {
    return `${environment.edition}.quizacademy.io/course/`;
  }

  get edition() {
    return environment.edition;
  }

  configs: ConfigOption[] = [];

  constructor(
    private location: Location,
    private courses: CoursesService,
    private matDialog: MatDialog,
    private dialog: DialogService,
    private router: Router,
    private translator: TranslateService,
    private snackbar: MatSnackBar,
    private auth: AuthService,
    private categories: CategoriesService,
    private userJourney: UserJourneyService,
    private usersService: UsersService,
    private panelService: PanelService,
    private userConfig: UserConfigService,
    private accessRequestsService: AccessRequestsService,
    private editService: EditService,
    private subscriptionLimit: SubscriptionLimitService
  ) {}

  ngOnInit(): void {
    this.initializeList();
    this.getOrganizations();
  }

  getOrganizations() {
    const userId = this.auth.currentUserInfo$.value.sub;
    this.usersService
      .getOrganizationsForUser({ id: userId })
      .subscribe((response: OrganizationModel[]) => {
        this.organizations = response;
      });
  }

  ngOnDestroy(): void {
    sessionStorage.removeItem('courseMarketListQuery');
  }

  async initializeList() {
    this.categoriesList = await new Promise((resolve) => {
      this.categories
        .getCategories({ as_tree: true })
        .subscribe((categories: CategoryModel[]) => {
          const cat = parseCategoryTree(categories);
          cat.unshift({
            id: 'all',
            name: this.translator.instant('courseMarket.allCategories'),
            icon: 'category',
            value: {
              id: 'all',
              name: this.translator.instant('courseMarket.allCategories'),
            },
            children: [],
          });
          cat[1].openedChildren = true;
          resolve(cat);
        });
    });
    const listConfig = getCourseListConfig(
      this.courses,
      this.matDialog,
      this.router,
      this.dialog,
      this.translator,
      false,
      this.categoriesList,
      this.panelService,
      this.snackbar,
      this.auth,
      this.accessRequestsService,
      this.editService,
      this.subscriptionLimit
    );
    listConfig.title = 'menu.courseMarket';
    delete listConfig.createButton;
    listConfig.activeColumnNames = [
      'id',
      'is_user_restricted',
      'name',
      'category',
    ];
    listConfig.columns = [
      {
        label: 'ID',
        name: 'id',
        sortable: false,
        width: '64px',
        isClickable: (meta) =>
          !(meta.row.is_user_restricted || meta.row.is_password_protected),
        click: (meta) =>
          !(meta.row.is_user_restricted || meta.row.is_password_protected)
            ? window.open(`https://${this.url}${meta.row.pin_code}`, '_blank')
            : null,
      },
      {
        label: 'courseMarket.lock',
        name: 'is_user_restricted',
        sortable: false,
        width: '42px',
        icon: (meta) => (meta.row.is_user_restricted ? 'lock' : ''),
        tooltip: (meta) =>
          meta.row.is_user_restricted
            ? this.translator.instant('course.collaboration.label.isRestricted')
            : '',
        accessor: () => '',
      },
      {
        label: 'course.table.name',
        isClickable: (meta) =>
          !(meta.row.is_user_restricted || meta.row.is_password_protected),
        click: (meta) =>
          !(meta.row.is_user_restricted || meta.row.is_password_protected)
            ? window.open(`https://${this.url}${meta.row.pin_code}`, '_blank')
            : null,
        name: 'name',
        sortable: true,
        filter: (value) => ({
          name: value,
        }),
      },
      {
        accessor: (row) => row.category && row.category.name,
        label: 'course.table.category',
        name: 'category',
        sortable: false,
        width: '300px',
        isClickable: (meta) =>
          !(meta.row.is_user_restricted || meta.row.is_password_protected),
        click: (meta) =>
          !(meta.row.is_user_restricted || meta.row.is_password_protected)
            ? window.open(`https://${this.url}${meta.row.pin_code}`, '_blank')
            : null,
        filter: (value) => {
          return {
            category: value,
          };
        },
      },
    ];
    listConfig.actions = [
      {
        click: (meta) => {
          !(meta.row.is_user_restricted || meta.row.is_password_protected)
            ? window.open(`https://${this.url}${meta.row.pin_code}`, '_blank')
            : null;
        },
        isDisabled: (meta) => this.checkDisabled(meta),
        hide: (meta) =>
          meta.row.is_user_restricted ||
          meta.row.is_password_protected ||
          this.checkDisabled(meta),
        icon: 'open_in_new',
        name: 'openCourse',
        type: 'button',
      },
      {
        click: (meta) => this.addSelection(meta),
        icon: 'add',
        isDisabled: (meta) => this.checkDisabled(meta),
        name: 'addSelection',
        type: 'button',
      },
    ];
    listConfig.actionsMenu = [];
    listConfig.activeActionNames = ['openCourse', 'addSelection'];
    this.initializeCourseListConfig(_.cloneDeep(listConfig));
    this.initializeKlettListConfig(_.cloneDeep(listConfig));
    this.configs = [
      {
        label: 'QuizAcademy',
        value: 'quizAcaedemy',
        config: this.courseListConfig,
      },
      {
        label: 'Klett',
        value: 'klett',
        config: this.klettListConfig,
      },
    ];
    this.loading = false;
  }

  private initializeCourseListConfig(listConfig: ListConfig<CourseModel>) {
    listConfig.fetch = (queryParams?: ListQuery<CourseModel>) => {
      if (!queryParams.filters) {
        queryParams.filters = {};
      }
      queryParams.filters.available_for_coursemarket = true;
      queryParams.sorting = [{ option: 'score', direction: 'DESC' }];
      const query = queryParams as unknown as CourseQuery;
      return this.courses.queryCourses({ body: query }).pipe(
        map((response: BaseQueryResponse) => ({
          ...response,
          data: (response.data as any[]).map((course: any) => {
            course.rowDisabled =
              course?.created_by === this.auth?.currentUserInfo$.value.sub ||
              false;
            return course;
          }),
        }))
      );
    };
    listConfig.name = 'courseMarketList';
    listConfig.showMessageInActionColumn = (course: any) => {
      return (course?.is_user_restricted || course?.is_password_protected) &&
        !course?.rowDisabled
        ? 'courseMarket.noPreview'
        : '';
    };
    listConfig.showMessageInActionTooltip = 'courseMarket.noPreviewTooltip';
    this.courseListConfig = listConfig;
  }

  private initializeKlettListConfig(listConfig: ListConfig<CourseModel>) {
    listConfig.fetch = (queryParams?: ListQuery<CourseModel>) => {
      if (!queryParams.filters) {
        queryParams.filters = {};
      }
      queryParams.filters.organization = { id: 3769 };
      queryParams.filters.available_for_coursemarket = true;
      const query = queryParams as unknown as CourseQuery;
      return this.courses.queryCourses({ body: query }).pipe(
        map((response: BaseQueryResponse) => ({
          ...response,
          data: (response.data as any[]).map((course: any) => {
            course.rowDisabled =
              course?.created_by === this.auth?.currentUserInfo$.value.sub ||
              false;
            return course;
          }),
        }))
      );
    };
    listConfig.name = 'klettCourseList';
    listConfig.showMessageInActionColumn = (course: any) => {
      return (course?.is_user_restricted || course?.is_password_protected) &&
        !course?.rowDisabled
        ? 'courseMarket.noPreview'
        : '';
    };
    listConfig.showMessageInActionTooltip = 'courseMarket.noPreviewTooltip';
    this.klettListConfig = listConfig;
  }

  async ngAfterViewInit() {
    if (!(await this.userConfig.get('courseMarketWarning'))) {
      this.openModal();
    }
  }

  openModal() {
    this.matDialog
      .open(CancelConfirmationComponent, {
        autoFocus: false,
        width: '500px',
        data: {
          messages: [
            this.translator.instant('courseMarket.inititalDialog.message1'),
            this.translator.instant('courseMarket.inititalDialog.message2'),
            this.translator.instant('courseMarket.inititalDialog.message3'),
          ],
          buttonLabels: {
            confirm: this.translator.instant(
              'courseMarket.inititalDialog.button.confirm'
            ),
            cancel: this.translator.instant(
              'courseMarket.inititalDialog.button.quit'
            ),
          },
        },
      })
      .afterClosed()
      .subscribe((isConfirmed) => {
        if (isConfirmed) {
          this.userConfig.set('courseMarketWarning', true);
        }
      });
  }

  selectedConfig(event) {
    this.activeListConfig = event;
  }

  get columnNames() {
    return [...this.activeListConfig.activeColumnNames, 'actions'];
  }

  checkDisabled(meta): boolean {
    return (
      !!this.selectedItems.find((item) => item.id === meta.row.id) ||
      meta.row?.created_by === this.auth.currentUserInfo$?.value?.sub
    );
  }

  addSelection(meta: ListActionMeta<CourseModel>) {
    this.selectedItems = [meta.row, ...this.selectedItems];
  }

  removeSelection(item: PanelEntity) {
    this.selectedItems = this.selectedItems.filter((i) => item.id !== i.id);
  }

  goBack() {
    this.location.back();
  }

  courseMarketListSource(e) {
    this.courseMarketSource = e;
  }

  importCourses() {
    this.loading = true;
    this.importCourse(0);
  }

  checkForOrganization() {
    if (this.organizations?.length > 1) {
      this.matDialog
        .open<unknown, OrganizationModel[]>(OrganizationForCourseComponent, {
          data: this.organizations,
          maxWidth: '500px',
          autoFocus: false,
        })
        .afterClosed()
        .subscribe((res: OrganizationModel) => {
          if (res) {
            this.selectedItems.forEach((item) => {
              item.organization = res;
            });
            this.importCourses();
          }
        });
    } else {
      this.selectedItems.forEach((item) => {
        item.organization = this.organizations[0];
      });
      this.importCourses();
    }
  }

  importCourse(idx: number) {
    if (this.selectedItems.length) {
      if (this.selectedItems[idx].is_user_restricted) {
        this.dialog
          .confirm({
            title: this.translator.instant(
              'course.collaboration.label.requestAccess'
            ),
            text: this.translator.instant(
              'course.collaboration.message.reallyRequest',
              {
                courseName: this.selectedItems[idx].name,
              }
            ),
            buttons: [
              { text: this.translator.instant('common.button.abort') },
              {
                color: 'primary',
                text: this.translator.instant(
                  'course.collaboration.label.requestAccess'
                ),
                value: true,
              },
            ],
          })
          .subscribe((val) => {
            if (val) {
              this.courses
                .requestCourseAccess({
                  body: {
                    course_id: this.selectedItems[idx].id,
                  },
                })
                .pipe(
                  catchError(() => {
                    this.openImportErrorDialog(idx);
                    return of(null);
                  }),
                  filter((val) => !!val)
                )
                .subscribe((value) => {
                  const text = value.is_approved
                    ? 'course.collaboration.label.alreadyApproved'
                    : 'course.collaboration.label.successfulRequest';
                  this.dialog
                    .confirm({
                      title: this.translator.instant(
                        'course.collaboration.label.requestAccess'
                      ),
                      text: this.translator.instant(text),
                      buttons: [
                        {
                          text: this.translator.instant(
                            'common.button.understood'
                          ),
                        },
                      ],
                    })
                    .subscribe(() => {
                      this.handleApiCalls(idx);
                    });
                });
            } else {
              this.handleApiCalls(idx);
            }
          });
      } else {
        this.continueImport(idx);
      }
    } else {
      this.handleApiCalls(idx);
    }
  }

  openImportErrorDialog(idx) {
    this.dialog
      .confirm({
        title: '',
        text: this.translator.instant('courseMarket.errorImport'),
        buttons: [{ text: this.translator.instant('common.button.ok') }],
      })
      .subscribe(() => {
        this.handleApiCalls(idx);
      });
  }

  private continueImport(idx: number) {
    this.courses
      .importFromMarket({
        id: this.selectedItems[idx].id,
        body: this.selectedItems[idx],
      })
      .pipe(
        catchError((error) => {
          const message = error.error.message;
          this.snackbar.open(message);
          this.handleApiCalls(idx);
          return of(null);
        }),
        filter((val) => !!val)
      )
      .subscribe((course: CourseModel) => {
        this.userJourney.set('importedCourseFromCourseMarket');
        if (course?.is_public) {
          course.is_public = false;
          this.courses
            .updateCourse({
              id: course.id,
              body: course,
            })
            .subscribe(() => {
              this.handleApiCalls(idx);
            });
        } else {
          this.handleApiCalls(idx);
        }
      });
  }

  handleApiCalls(idx: number) {
    idx++;
    if (idx < this.selectedItems.length) {
      this.importCourse(idx);
    } else {
      setTimeout(() => {
        this.loading = false;
        this.router.navigate(['/cms/courses']);
      }, 500);
    }
  }
}
