import { Injectable } from '@angular/core';
import {
  SubscriptionLimit,
  SubscriptionLimitWarning,
} from './subscription-limit-warning.component';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from 'src/app/auth/auth.service';
import { SubscriptionViewDataService } from 'src/app/profile/subscription/viewdata/subscription-view-data.service';

@Injectable({
  providedIn: 'root',
})
export class SubscriptionLimitService {
  openSubscribe = false;
  subscriptionLimit = {
    COURSE_COUNT: 0,
    LIVE_EVENT_COUNT: 0,
    EXAM_COUNT: 0,
  };

  constructor(
    private subViewDataService: SubscriptionViewDataService,
    private translator: TranslateService,
    private auth: AuthService,
    private matDialog: MatDialog
  ) {}

  openDialog(type: SubscriptionLimit) {
    let userDetails: any = this.auth.currentUserInfo$.value;
    const subViewData = this.subViewDataService.getSubscriptionViewData({
      stripe_status: userDetails.name,
    });
    if (!subViewData.free) {
      return;
    }
    if (this.canOpenDialog(type)) {
      const limit = new SubscriptionLimitWarning(
        this.translator,
        this.matDialog
      );
      limit.type(type);
      limit.build();
    }
  }

  openLiveEventDialog(id: number) {
    this.checkSession(SubscriptionLimit.LIVE_EVENT_PARTICIPANTS, id);
  }

  openExamDialog(id: number) {
    this.checkSession(SubscriptionLimit.EXAM_PARTICIPANTS, id);
  }

  checkSession(type: SubscriptionLimit, id: number) {
    if (!this.isInSession(type, id)) {
      this.openDialog(type);
      const session = sessionStorage.getItem(SubscriptionLimit[type]);
      if (session) {
        const item = JSON.parse(session);
        item[id] = true;
        sessionStorage.setItem(SubscriptionLimit[type], JSON.stringify(item));
      } else {
        const item = {
          [id]: true,
        };
        sessionStorage.setItem(SubscriptionLimit[type], JSON.stringify(item));
      }
    }
  }

  removeSession(type: SubscriptionLimit, id: number) {
    const session = sessionStorage.getItem(SubscriptionLimit[type]);
    if (session) {
      const item = JSON.parse(session);
      delete item[id];
      sessionStorage.setItem(SubscriptionLimit[type], JSON.stringify(item));
    }
  }

  isInSession(type: SubscriptionLimit, id: number): boolean {
    const session = sessionStorage.getItem(SubscriptionLimit[type]);
    if (session) {
      const item = JSON.parse(session);
      return item[id] ?? false;
    }
    return !!session;
  }

  canOpenDialog(type: SubscriptionLimit): boolean {
    let shouldOpenDialog = false;
    const count = this.subscriptionLimit[SubscriptionLimit[type]];
    switch (type) {
      case SubscriptionLimit.EXAM_COUNT:
      case SubscriptionLimit.COURSE_COUNT:
      case SubscriptionLimit.LIVE_EVENT_COUNT:
        shouldOpenDialog = count >= 1;
        break;
      case SubscriptionLimit.EXAM_PARTICIPANTS:
      case SubscriptionLimit.LIVE_EVENT_PARTICIPANTS:
        shouldOpenDialog = true;
        break;
      default:
        break;
    }
    return shouldOpenDialog;
  }
}
