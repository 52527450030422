<div
  class="custom-chip"
  [ngClass]="{
    'primary-light': chipType === ChipType.PRIMARY_LIGHT,
    warn: chipType === ChipType.WARN,
    light: chipType === ChipType.LIGHT
  }"
>
  <label>{{ label | translate }}</label>
  <mat-icon *ngIf="icon">{{ icon }}</mat-icon>
</div>
