<h1 mat-dialog-title>
  {{ data?.title || 'liveEvent.setup.invitationTextDialog.title' | translate }}
</h1>
<mat-dialog-content>
  <ng-container *ngIf="!blurInput; else labelView">
    <mat-form-field appearance="outline">
      <mat-label>
        {{ 'Text' }}
      </mat-label>
      <textarea (blur)="onBlur()" [value]="text" #textArea matInput rows="20">
      </textarea>
    </mat-form-field>
  </ng-container>

  <ng-template #labelView>
    <div class="label-view">
      <mat-label>
        {{ 'Text' }}
      </mat-label>
      <br />
      <code
        class="invtext-render"
        (click)="onEdit()"
        [innerHTML]="parseText(text)"
      ></code>
    </div>
  </ng-template>
</mat-dialog-content>
<mat-dialog-actions class="space-between-btn">
  <button color="primary" mat-button mat-dialog-close type="button">
    {{ 'common.button.shutdown' | translate }}
  </button>
  <div class="text-actions">
    <a
      (click)="copyWeblink()"
      class="mat-button mat-button-base mat-primary copy-link"
      color="primary"
      target="_blank"
    >
      <mat-icon>content_copy</mat-icon>
      {{ 'liveEvent.setup.label.copyInvitationText' | translate }}
    </a>
    <button
      mat-stroked-button
      color="primary"
      target="_blank"
      (click)="sendMail()"
    >
      <mat-icon>email</mat-icon>
      {{ 'liveEvent.setup.invitationTextDialog.sendViaMail' | translate }}
    </button>
  </div>
</mat-dialog-actions>
