/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { CountStatistics } from '../models/count-statistics';
import { UsageStatistics } from '../models/usage-statistics';

@Injectable({
  providedIn: 'root',
})
export class StatsService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation statsControllerGetStats
   */
  static readonly StatsControllerGetStatsPath = '/stats';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `statsControllerGetStats()` instead.
   *
   * This method doesn't expect any request body.
   */
  statsControllerGetStats$Response(params?: {
    global?: boolean;
    cognitousergroup?: Array<string>;
    cognitouserid?: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<CountStatistics>> {

    const rb = new RequestBuilder(this.rootUrl, StatsService.StatsControllerGetStatsPath, 'get');
    if (params) {
      rb.query('global', params.global, {});
      rb.header('cognitousergroup', params.cognitousergroup, {});
      rb.header('cognitouserid', params.cognitouserid, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CountStatistics>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `statsControllerGetStats$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  statsControllerGetStats(params?: {
    global?: boolean;
    cognitousergroup?: Array<string>;
    cognitouserid?: string;
    context?: HttpContext
  }
): Observable<CountStatistics> {

    return this.statsControllerGetStats$Response(params).pipe(
      map((r: StrictHttpResponse<CountStatistics>) => r.body as CountStatistics)
    );
  }

  /**
   * Path part for operation statsControllerGetStatsUsage
   */
  static readonly StatsControllerGetStatsUsagePath = '/stats/usage';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `statsControllerGetStatsUsage()` instead.
   *
   * This method doesn't expect any request body.
   */
  statsControllerGetStatsUsage$Response(params?: {
    global?: boolean;
    start?: string;
    end?: string;
    cognitousergroup?: Array<string>;
    cognitouserid?: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<UsageStatistics>>> {

    const rb = new RequestBuilder(this.rootUrl, StatsService.StatsControllerGetStatsUsagePath, 'get');
    if (params) {
      rb.query('global', params.global, {});
      rb.query('start', params.start, {});
      rb.query('end', params.end, {});
      rb.header('cognitousergroup', params.cognitousergroup, {});
      rb.header('cognitouserid', params.cognitouserid, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<UsageStatistics>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `statsControllerGetStatsUsage$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  statsControllerGetStatsUsage(params?: {
    global?: boolean;
    start?: string;
    end?: string;
    cognitousergroup?: Array<string>;
    cognitouserid?: string;
    context?: HttpContext
  }
): Observable<Array<UsageStatistics>> {

    return this.statsControllerGetStatsUsage$Response(params).pipe(
      map((r: StrictHttpResponse<Array<UsageStatistics>>) => r.body as Array<UsageStatistics>)
    );
  }

}
