<mat-dialog-content>
  {{ 'Änderungen verwerfen?' }}
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button
    mat-button
    color="primary"
    [mat-dialog-close]="{ action: dialogReturnTypeAction.CANCEL }"
  >
    {{ 'Abbrechen' }}
  </button>
  <button
    mat-button
    [mat-dialog-close]="{ action: dialogReturnTypeAction.OK }"
    color="warn"
  >
    {{ 'Verwerfen' }}
  </button>
</mat-dialog-actions>
