import { Progress } from 'aws-sdk/lib/request';
import * as _ from 'lodash';
import { Subject } from 'rxjs';
import {
  CategoryModel,
  CourseModel,
  Exam,
  OrganizationModel,
  QuizModel,
} from './api/models';
import { CmsLiveEvent } from './cms/live-event/cms-live-event.types';
import * as Course from './course/course.types';
import * as FlashCard from './course/flash-card/flash-card.types';
import * as QuickExam from './course/quick-exam/quick-exam.types';
import * as SimpleQuiz from './course/simple-quiz/simple-quiz.types';
import * as ExamTypes from './exam/exam.types';
import * as LiveEventType from './live-event/live-event.types';
import * as Play from './play/play.types';
import * as Quiz from './quiz/quiz.types';
import * as Event from './event/event.types';
import { Sidebar } from './sidebar/sidebar.types';

export const CONTACT_INFO = {
  email: 'support@quizacademy.de',
  formUrl: 'https://quizacademy.de/kontakt/',
  phone: '+49 (0) 33731 32 78 18',
};
// eslint-disable-next-line max-len
export const MATHML_ATTRIBUTES = [
  'accent',
  'accentunder',
  'actiontype',
  'align',
  'alignmentscope',
  'altimg',
  'altimg-width',
  'altimg-height',
  'altimg-valign',
  'alttext',
  'bevelled',
  'charalign',
  'close',
  'columnalign',
  'columnlines',
  'columnspacing',
  'columnspan',
  'columnwidth',
  'crossout',
  'decimalpoint',
  'denomalign',
  'depth',
  'dir',
  'display',
  'displaystyle',
  'edge',
  'equalcolumns',
  'equalrows',
  'fence',
  'form',
  'frame',
  'framespacing',
  'groupalign',
  'height',
  'href',
  'id',
  'indentalign',
  'indentalignfirst',
  'indentalignlast',
  'indentshift',
  'indentshiftfirst',
  'indentshiftlast',
  'indenttarget',
  'infixlinebreakstyle',
  'largeop',
  'length',
  'linebreak',
  'linebreakmultchar',
  'linebreakstyle',
  'lineleading',
  'linethickness',
  'location',
  'longdivstyle',
  'lspace',
  'lquote',
  'mathbackground',
  'mathcolor',
  'mathsize',
  'mathvariant',
  'maxsize',
  'minlabelspacing',
  'minsize',
  'movablelimits',
  'notation',
  'numalign',
  'open',
  'overflow',
  'position',
  'rowalign',
  'rowlines',
  'rowspacing',
  'rowspan',
  'rspace',
  'rquote',
  'scriptlevel',
  'scriptminsize',
  'scriptsizemultiplier',
  'selection',
  'separator',
  'separators',
  'shift',
  'side',
  'stackalign',
  'stretchy',
  'subscriptshift',
  'supscriptshift',
  'symmetric',
  'voffset',
  'width',
  'xmlns',
];
// eslint-disable-next-line max-len
export const MATHML_TAGS = [
  'math',
  'mglyph',
  'mi',
  'mn',
  'mo',
  'ms',
  'mspace',
  'mtext',
  'menclose',
  'merror',
  'mfenced',
  'mfrac',
  'mpadded',
  'mphantom',
  'mroot',
  'mrow',
  'msqrt',
  'mstyle',
  'mmultiscripts',
  'mover',
  'mprescripts',
  'msub',
  'msubsup',
  'msup',
  'munder',
  'munderover',
  'none',
  'maligngroup',
  'malignmark',
  'mlabeledtr',
  'mtable',
  'mtd',
  'mtr',
  'mlongdiv',
  'mscarries',
  'mscarry',
  'msgroup',
  'msline',
  'msrow',
  'mstack',
  'maction',
  'annotation',
  'annotation-xml',
  'semantics',
];
export const NEW_OBJECT_ID = -1;
export const ROOT_CATEGORY_ID = 1;

export class Notification {
  subject?: Subject<NotificationStatus>;

  constructor(
    public message: string,
    public duration?: number,
    public actionLabel?: string
  ) {}
}

export interface Action {
  disableIf?: (state: AppState) => boolean;
  handler: (state: AppState, event?: Event) => void;
  hideIf?: (state: AppState) => boolean;
  icon: string;
  text: string;
  class?: string;
  raised?: boolean;
}

export interface AppState {
  cmsLiveEvent: CmsLiveEvent.State;
  course: Course.State;
  exam: ExamTypes.State;
  flashCard: FlashCard.State;
  liveEvent: LiveEventType.State;
  play: Play.State;
  quickExam: QuickExam.State;
  quiz: Quiz.State;
  event: Event.State;
  sidebar: Sidebar.State;
  simpleQuiz: SimpleQuiz.State;
}

export interface DateRange {
  from: string;
  to: string;
}

export interface ErrorResponse {
  error: string;
  message: string;
  statusCode: number;
}

export interface DialogReturnType<T> {
  action: DialogReturnTypeAction;
  data?: T;
}

export interface NavigationGroup {
  active?: () => boolean;
  expandable?: boolean;
  open?: boolean;
  label?: string;
  icon?: string;
  items: NavigationItem[];
  is_new?: boolean;
  badgeCount?: number;
  coming_soon?: boolean;
  no_seperator?: boolean;
}

export interface NavigationItem {
  badgeCount?: number;
  externalLink?: string;
  hideIf?: () => boolean;
  icon?: string;
  label: string;
  link?: string;
  onClick?: () => void;
  disabled?: boolean;
  disabledTip?: string;
  is_new?: boolean;
  coming_soon?: boolean;
  exact?: boolean;
}

export interface UploadProgress {
  key: string;
  name: string;
  fileExt: string;
  data: unknown;
  progress: Progress;
  percentage: number;
}

export enum AuthenticationResponse {
  NEW_PASSWORD_REQUIRED,
  SUCCESS,
  PASSWORD_RESET_INITIATED,
  PASSWORD_RESET_REQUIRED,
  INPUT_VERIFICATION_CODE,
}

export enum DialogReturnTypeAction {
  CANCEL,
  DELETE,
  SAVE,
  OK,
  SELECT,
}

export enum NotificationStatus {
  QUEUED,
  VISIBLE,
  ACTION_TRIGGERED,
  DISMISSED,
}

export enum QuestionType {
  SINGLE_CHOICE = 2,
  MULTIPLE_CHOICE = 0,
  TRUE_FALSE = 1,
  FREETEXT = 3,
}

export enum QuizQuestionType {
  SINGLE_CHOICE = 2,
  MULTIPLE_CHOICE = 0,
  TRUE_FALSE = 1,
}

export enum PollQuestionType {
  SINGLE_CHOICE = 2,
  MULTIPLE_CHOICE = 0,
  FREETEXT = 3,
}

export function getQuestionTypeOptions(
  types
): QuestionType[] | QuizQuestionType[] | PollQuestionType[] {
  const result = [];
  for (const [propertyKey, propertyValue] of Object.entries(types)) {
    // Ignore keys that are not numbers
    if (!Number.isNaN(Number(propertyKey))) {
      continue;
    }
    // Add keys and values to array
    result.push({ value: propertyValue, label: propertyKey });
  }
  return result;
}

export function defaultDisplayFunction(input: {
  name: string;
  text: string;
}): string {
  return (input && input.name) || (input && input.text) || '';
}

export function errorDisplayFunction(error: string) {
  switch (error) {
    case 'atLeastOneLowerCase':
      return 'common.error.atLeastOneLowerCase';
    case 'atLeastOneNumber':
      return 'common.error.atLeastOneNumber';
    case 'atLeastOneUpperCase':
      return 'common.error.atLeastOneUpperCase';
    case 'email':
      return 'common.error.email';
    case 'equalPasswords':
      return 'common.error.equalPasswords';
    case 'minlength':
      return 'common.error.minlength';
    case 'pattern':
      return 'common.error.pattern';
    case 'required':
      return 'common.error.required';
    default:
      return 'common.error.pattern';
  }
}

export function questionTypeDisplayFn(type: QuestionType, short = false) {
  if (typeof type !== 'number') {
    type = QuestionType[type] as unknown as QuestionType;
  }
  switch (type) {
    case QuestionType.MULTIPLE_CHOICE:
      return short ? 'MC' : 'Multiple Choice (MC)';
    case QuestionType.SINGLE_CHOICE:
      return short ? 'SC' : 'Single Choice (SC)';
    case QuestionType.TRUE_FALSE:
      return short ? 'TF' : 'True/False (TF)';
    case QuestionType.FREETEXT:
      return short ? 'FT' : 'Free Text (FT)';
    default:
      return undefined;
  }
}

export function organizationDisplayFn(organization: OrganizationModel) {
  return _.get(organization, 'name');
}

export function courseDisplayFn(course: CourseModel) {
  return _.get(course, 'name');
}

export function quizDisplayFn(quiz: QuizModel) {
  return _.get(quiz, 'name');
}

export function examDisplayFn(exam: Exam) {
  return _.get(exam, 'name');
}

export function categoryDisplayFn(category: CategoryModel) {
  return _.get(category, 'name');
}
