import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { QuestionModel } from 'src/app/api/models';
import { QuestionsService, QuizzesService } from 'src/app/api/services';
import {
  CommentListDialogComponent,
  CommentListDialogData,
} from 'src/app/cms/list/comment-list-dialog.component';
import { PanelService } from '../panel.service';
import { Panel, PanelEntityType } from '../panel.types';
import { TranslateService } from '@ngx-translate/core';
import { questionTypeDisplayFn } from 'src/app/app.types';
import { ImportEntity } from '../../list/list.types';
import { AuthService } from 'src/app/auth/auth.service';
import {
  ImportAiQuestionComponent,
  ImportQuestionType,
} from '../../common/dialogs/import-ai-question/import-ai-question.component';

export const getQuestionImports = (
  translator: TranslateService,
  panelService: PanelService,
  panel: Panel<QuestionModel>,
  matDialog: MatDialog,
  courseContext = false,
  isAdmin = false
): ImportEntity<QuestionModel>[] =>
  (
    [
      isAdmin
        ? {
            id: 'chat-gpt',
            title: translator.instant(
              'content.question.description.aiImortTitle'
            ),
            description: translator.instant(
              'content.question.description.aiImportDescription'
            ),
            highlight: true,
            action: {
              icon: 'code',
              click: () => {
                matDialog
                  .open<ImportAiQuestionComponent, ImportQuestionType>(
                    ImportAiQuestionComponent,
                    {
                      data: {
                        type: 'quiz',
                      },
                      width: '600px',
                      maxHeight: '90vh',
                    }
                  )
                  .afterClosed()
                  .subscribe((r) => {
                    if (r) {
                      matDialog.closeAll();
                      panelService.fetchItems(panel);
                    }
                  });
              },
              text: translator.instant('content.question.addMenu.item9'),
            },
          }
        : null,
      {
        title: translator.instant('content.examQuestion.title.item4'),
        description: translator.instant('content.question.description.item6'),
        action: {
          icon: 'code',
          click: () => {
            if (courseContext && panel) {
              panelService.importFromChatGPT(panel);
            } else {
              const callback = () => {
                const queryParams = {
                  type: PanelEntityType.Question,
                };
                panelService.redirectToImportItems(
                  `/cms/csv-imports/question`,
                  queryParams,
                  true
                );
              };
              panelService.importFromChatGPT(null, () => callback());
            }
          },
          text: translator.instant('content.question.addMenu.item9'),
        },
      },
      courseContext
        ? {
            title: translator.instant('content.examQuestion.title.item1'),
            description: translator.instant(
              'content.examQuestion.description.item1'
            ),
            action: {
              icon: 'move_to_inbox',
              click: () => panelService.importItems(panel),
              text: translator.instant('content.question.addMenu.item2'),
            },
          }
        : null,
      {
        id: 'import-as-csv',
        title: translator.instant('content.examQuestion.title.item3'),
        description: translator.instant(
          'content.examQuestion.description.item3'
        ),
        action: {
          icon: 'description',
          click: () => panelService.importItemAsCSV(panel),
          text: translator.instant('content.question.addMenu.item8'),
        },
        extra: {
          name: translator.instant('content.examQuestion.title.extra'),
          type: 'link',
          icon: 'download',
          click: () => {
            window.open(
              'assets/import-csv-templates/Sample-import-questions.csv',
              '_blank'
            );
          },
        },
      },
      {
        id: 'import-json',
        title: translator.instant('content.examQuestion.title.item2'),
        description: translator.instant(
          'content.examQuestion.description.item2'
        ),
        action: {
          icon: 'code',
          click: () => panelService.importItemAsJSON(panel),
          text: translator.instant('content.question.addMenu.item7'),
        },
      },
    ] as ImportEntity<QuestionModel>[]
  ).filter((n) => n !== null);

@Injectable()
export class QuestionPanel extends Panel<QuestionModel> {
  constructor(
    matDialog: MatDialog,
    panelService: PanelService,
    questions: QuestionsService,
    quizzes: QuizzesService,
    translator: TranslateService,
    auth: AuthService
  ) {
    super();
    super.init({
      addMenuItems: [
        {
          icon: 'add',
          onClick: () => panelService.editItem(this),
          text: translator.instant('content.question.addMenu.item1'),
        },
      ],
      showChatGptAction:
        auth.currentUserHasGroup('Admin') ||
        auth.currentUserHasGroup('ContentManager'),
      imports: getQuestionImports(
        translator,
        panelService,
        this,
        matDialog,
        true,
        auth.currentUserHasGroup('Admin') ||
          auth.currentUserHasGroup('ContentManager')
      ),
      headerActions: [],
      changeItemPosition: (questionId, quizId, position) => {
        return quizzes.repositionQuestionInQuiz({
          id: quizId,
          question_id: questionId,
          body: { position },
        });
      },
      showTotalItems: true,
      createItemsFn: (items, parentId) =>
        quizzes.copyQuestionToQuiz({
          id: parentId,
          body: items,
        }),
      deleteItem: (questionId) => questions.deleteQuestion({ id: questionId }),
      editDialogInput: (question, quizId) => ({
        enableNavigation: true,
        question,
        quizId,
      }),
      fetchItem: (id) => questions.getQuestionById({ id }),
      fetchItems: (quizId, additionalData) =>
        additionalData
          ? quizzes.getQuestionsForQuiz({
              id: quizId,
              embed_answers: true,
              embed_tags: true,
            })
          : quizzes.getQuestionsForQuiz({
              id: quizId,
            }),
      headerIcon: 'question_answers',
      itemActions: [
        {
          icon: 'question_answer',
          label: translator.instant('content.question.actionMenu.item1'),
          onClick: (question) => {
            matDialog.open(CommentListDialogComponent, {
              data: {
                questionId: question.id,
              },
              autoFocus: false,
            } as MatDialogConfig<CommentListDialogData>);
          },
        },
        {
          icon: 'code',
          label: translator.instant('content.question.actionMenu.item2'),
          onClick: (item) => panelService.exportItemAsJSON(this, item.id),
        },
        {
          icon: 'link_off',
          label: translator.instant('content.question.actionMenu.item3'),
          disabled: (question) => question['isImported'],
          tooltip: (question) =>
            question['isImported']
              ? translator.instant('content.quiz.notYourQuetion')
              : null,
          onClick: (question) => panelService.unlinkItems(this, question.id),
        },
        {
          icon: 'delete',
          label: translator.instant('content.question.actionMenu.item4'),
          disabled: (question) => question['isImported'],
          tooltip: (question) =>
            question['isImported']
              ? translator.instant('content.quiz.notYourQuetion')
              : null,
          onClick: (question) => panelService.deleteItem(this, question.id),
        },
      ],
      itemPrefixes: (question) => {
        const type = questionTypeDisplayFn(question.type, true) ?? '?';
        const suffixes = [{ text: type, icon: null }];
        return suffixes;
      },
      itemTextProperty: 'text',
      parentKey: 'questions',
      parentType: PanelEntityType.Quiz,
      selectedItemActions: [
        {
          icon: 'link_off',
          label: translator.instant('content.question.selectedItem.label'),
          onClick: (selectedItemIds) =>
            panelService.unlinkItems(this, selectedItemIds),
        },
      ],
      translations: {
        addItem: translator.instant('content.question.tooltip'),
        deleteItemError: translator.instant(
          'content.question.deleteItem.error'
        ),
        deleteItemSuccess: translator.instant(
          'content.question.deleteItem.success'
        ),
        deleteItemText: translator.instant('content.question.deleteItem.text'),
        deleteItemTitle: translator.instant(
          'content.question.deleteItem.title'
        ),
        header: translator.instant('common.label.questions'),
        unlinkItemConfirmText: translator.instant(
          'content.question.unlinkItem.text'
        ),
        unlinkItemConfirmTitle: translator.instant(
          'content.question.unlinkItem.title'
        ),
        unlinkItemError: translator.instant(
          'content.question.unlinkItem.error'
        ),
        unlinkItemSuccess: translator.instant(
          'content.question.unlinkItem.success'
        ),
      },
      type: PanelEntityType.Question,
      unlinkItems: (questionIds, quizId) =>
        quizzes.removeQuestionFromQuiz({
          id: quizId,
          question_ids: questionIds.join(','),
        }),
    });
  }
}
