<div class="quick-feedback-container">
  <div class="vote-icon">
    <mat-icon>how_to_vote</mat-icon>
  </div>
  <div class="rate">
    <div class="text">
      <span
        [class.active]="question === activeQuestion"
        *ngFor="let question of questionsUnwrapped; let i = index"
      >
        <strong *ngIf="i === 0"
          >{{ 'liveEvent.feedback.title' | translate }}:
        </strong>
        {{ question.text }}
      </span>
    </div>
    <div
      class="options"
      [class.voted]="activeQuestion.finished"
      [class.showing]="activeQuestion?.answers || false"
    >
      <div
        class="option"
        [matTooltip]="answer.tooltip || ''"
        *ngFor="let answer of activeQuestion.answers"
        (click)="logInResp(answer)"
        [class.con]="answer.warn"
        [class.isText]="answer.text"
      >
        <mat-icon *ngIf="answer.icon">{{ answer.icon }}</mat-icon>
        <span *ngIf="answer.text">{{ answer.text }}</span>
      </div>
    </div>
    <div class="textinput" [class.visible]="activeQuestion.textInput || false">
      <form (submit)="logInText()">
        <mat-form-field appearance="fill">
          <mat-label>{{ 'liveEvent.feedback.answer' | translate }}</mat-label>
          <input
            autocomplete="off"
            matInput
            [formControl]="feedbackText"
            [placeholder]="activeQuestion?.textInput?.placeholder || ''"
          />
        </mat-form-field>
        <qa-button
          [isRaisedButton]="true"
          label="liveEvent.feedback.submit"
          type="submit"
        ></qa-button>
      </form>
    </div>
  </div>
</div>
