import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewEncapsulation,
} from '@angular/core';

@Component({
  selector: 'qa-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ButtonComponent {
  @Output() submitted = new EventEmitter<MouseEvent>();
  @Input() isRaisedButton: boolean;
  @Input() anchorLink: string;
  @Input() target = '_blank';
  @Input() isIconButton: boolean;
  @Input() isRoundedButton: boolean;
  @Input() isFillIcon: boolean;
  @Input() showIconAtEnd: boolean;
  @Input() label: string;
  @Input() type = 'button';
  @Input() disabled = false;
  @Input() color = 'primary';
  @Input() icon: string;
  @Input() minWidth: number;
  @Input() buttonClass: string;

  performAction(event: MouseEvent) {
    this.submitted.emit(event);
  }
}
