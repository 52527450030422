import {
  Component,
  Input,
  OnInit,
  OnDestroy,
  Output,
  EventEmitter,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { filter, startWith } from 'rxjs/operators';
import { isObject } from 'lodash';
import { FormField } from './form-field';
import { UntypedFormControl } from '@angular/forms';
import {
  defaultDisplayFunction,
  errorDisplayFunction,
} from '../../../app.types';

@Component({
  selector: 'qa-form-field-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
})
export class FormSelectComponent
  extends FormField
  implements OnInit, OnDestroy
{
  @Output() ValueChanged = new EventEmitter<any>();
  @Input()
  options: Array<{}>;

  @Input()
  displayFn = defaultDisplayFunction;

  @Input()
  multiple = false;

  displayControl = new UntypedFormControl();

  objectKeys = Object.keys;

  statusChangesSubscription: Subscription;
  valueChangesSubscription: Subscription;
  displayValueChangesSubscription: Subscription;

  @Input()
  compareFn = (x, y): boolean => (x && y ? x.id === y.id : false);

  errorDisplayFunction = errorDisplayFunction;

  constructor() {
    super();
  }

  ngOnInit() {
    if (this.control) {
      this.valueChangesSubscription = this.control.valueChanges
        .pipe(startWith(this.control.value))
        .subscribe({
          next: (value) => {
            this.displayControl.setValue(value, { emitEvent: false });
          },
        });

      this.statusChangesSubscription = this.control.statusChanges
        .pipe(startWith(this.control.disabled ? 'DISABLED' : ''))
        .subscribe({
          next: (status: string) => {
            if (
              status.toLocaleLowerCase() === 'disabled' &&
              this.displayControl.enabled
            ) {
              this.displayControl.disable();
            }
            if (
              status.toLocaleLowerCase() !== 'disabled' &&
              this.displayControl.disabled
            ) {
              this.displayControl.enable();
            }
          },
        });
    }

    this.displayValueChangesSubscription = this.displayControl.valueChanges
      .pipe(filter((value) => isObject(value)))
      .subscribe({
        next: (value) => {
          if (this.control) {
            this.control.reset();
            this.control.patchValue(value);
            this.control.markAsDirty();
            this.control.markAsTouched();
          }
        },
      });
  }

  ngOnDestroy() {
    if (this.statusChangesSubscription) {
      this.statusChangesSubscription.unsubscribe();
    }
    if (this.valueChangesSubscription) {
      this.valueChangesSubscription.unsubscribe();
    }
    this.displayValueChangesSubscription.unsubscribe();
  }

  onChange(event) {
    this.ValueChanged.emit(event.value);
  }
}
