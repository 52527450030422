import { Component } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import * as FileSaver from 'file-saver';
import {
  CourseExportModel,
  CourseExportList,
  CourseModel,
} from 'src/app/api/models';
import { CoursesService } from 'src/app/api/services';

@Component({
  selector: 'qa-course-exports',
  templateUrl: './course-exports.component.html',
  styleUrls: ['./course-exports.component.scss'],
})
export class CourseExportsComponent {
  courseIds: string;
  loading: boolean;
  courseExportIds: CourseExportModel[] = [];
  coursesToExport: CourseModel[] = [];

  constructor(
    private courses: CoursesService,
    public matSnackBar: MatSnackBar,
    private translate: TranslateService
  ) {}

  onExport() {
    this.loading = true;
    const ids = this.courseIds.split(',');
    ids.forEach((id) => {
      this.courseExportIds.push({ id: Number(id.trim()) });
    });
    if (this.courseExportIds.length) {
      this.getCourseData(0);
    }
  }

  getCourseData(count: number) {
    const courses: CourseExportList = {
      courses: [this.courseExportIds[count]],
    };
    this.courses.exportCourses({ body: courses }).subscribe({
      next: (response) => {
        this.coursesToExport = [...this.coursesToExport, ...response];
        count++;
        if (count < this.courseExportIds.length) {
          this.getCourseData(count);
        } else {
          this.loading = false;
          this.exportCourses();
        }
      },
      error: () => {
        this.loading = false;
        this.matSnackBar.open(this.translate.instant('courseExports.error'));
      },
    });
  }

  exportCourses() {
    const file = `${JSON.stringify(this.coursesToExport)}\n`;
    const blob = new Blob([file], { type: 'application/json' });
    FileSaver.saveAs(blob, `course_export.json`);
  }
}
