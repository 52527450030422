import { Injectable } from '@angular/core';
import { Edit } from '../edit.types';
import { MatDialog } from '@angular/material/dialog';
import { MarkdownInfoDialogComponent } from '../../common/form-field/markdown-info-dialog.component';
import { TranslateService } from '@ngx-translate/core';
import { Observable, map, tap } from 'rxjs';
import { UserJourneyService } from '../../pages/onboarding-steps/user-journey.service';
import { QuizModel } from '../../../api/models';
import {
  BooksService,
  CoursesService,
  QuizzesService,
} from 'src/app/api/services';
import { Router } from '@angular/router';
import { sortBy } from 'lodash';

@Injectable()
export class QuizEditConfigService extends Edit.ConfigService<QuizModel> {
  constructor(
    quizzesService: QuizzesService,
    matDialog: MatDialog,
    translator: TranslateService,
    coursesService: CoursesService,
    booksService: BooksService,
    userJourney: UserJourneyService,
    router: Router
  ) {
    super({
      itemName: translator.instant('common.label.quiz'),
      id: 'quiz',
      deleteConfirmText: translator.instant('exam.deleteConfirmText'),
      deleteConfirmTitle: translator.instant('exam.deleteConfirmTitle'),
      fields: [
        {
          key: 'name',
          label: 'Name',
          required: true,
        },
        {
          key: 'description',
          label: translator.instant('exam.form.description'),
          multiLine: true,
          hideIf: () => true,
          suffix: {
            icon: 'code',
            text: translator.instant('exam.form.descSuffix'),
            handler: () => {
              matDialog.open(MarkdownInfoDialogComponent, { width: '640px' });
            },
          },
        },
      ],
      handlers: {
        update: (newValue) => {
          return quizzesService
            .updateQuiz({
              id: newValue.id,
              body: newValue,
            })
            .toPromise();
        },
        create: (newValue) => {
          const courseId = this.config?.params?.courseId;
          const isBook = router.url?.indexOf('book') !== -1;
          let saveQuiz$: Observable<QuizModel>;
          if (!courseId) {
            saveQuiz$ = quizzesService.createQuiz({ body: newValue });
          } else {
            const query = {
              id: courseId,
              body: [newValue],
              copyQuestions: false,
            };
            saveQuiz$ = (
              isBook
                ? booksService.copyQuizInBook(query)
                : coursesService.copyQuizToCourse(query)
            ).pipe(
              map((quizzes) => {
                sortBy(quizzes, 'id');
                return quizzes[quizzes.length - 1];
              })
            );
          }
          return saveQuiz$
            .pipe(
              tap(() => {
                userJourney.set('createdQuiz');
              }),
              map((quiz) => {
                return quiz;
              })
            )
            .toPromise();
        },
        delete: (newValue) => {
          return quizzesService.deleteQuiz({ id: newValue.id }).toPromise();
        },
      },
    });
  }
}
