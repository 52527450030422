<div
  class="count-num"
  *ngIf="!isExplanationPanelOpen && itemCount && itemCount > 0"
>
  {{ 'common.label.flashCard' | translate }}
  {{ currentItemPosition | number : '2.0-0' }} <span>/</span>
  {{ itemCount | number : '2.0-0' }}
</div>
<qa-mock-phone>
  <div class="view-container">
    <div class="app-bar">
      <mat-icon>arrow_back</mat-icon>
      <div class="title">
        {{ 'flashCardMockPhone.quizName' | translate }}
      </div>
      <div class="right-buttons">
        <mat-icon>assessment</mat-icon>
        <mat-icon>more_vert</mat-icon>
      </div>
    </div>

    <div class="left-card"></div>
    <div class="right-card"></div>
    <div
      (click)="showAnswer = !showAnswer"
      matTooltip="{{ 'flashCardMockPhone.tooltip.toggleView' | translate }}"
      class="card"
      matTooltipPosition="above"
    >
      <ng-scrollbar>
        <div class="flex-row flex-align-items-center">
          <div class="card-title">
            {{
              (showAnswer
                ? 'flashCardMockPhone.answer'
                : 'flashCardMockPhone.text'
              ) | translate
            }}
          </div>
          <mat-icon
            (click)="openExplainPanel($event, showAnswer)"
            class="card-icon flex-align-right"
            [class.showAnswer]="showAnswer"
          >
            {{ showAnswer ? 'help' : 'favorite' }}
          </mat-icon>
        </div>
        <div class="card-text">
          <img
            src="https://s3.{{ 's3.mediaBucket.region' | env }}.amazonaws.com/{{
              's3.mediaBucket.name' | env
            }}/{{ flashCard.media.uuid }}.{{ flashCard.media.file_ext }}"
            *ngIf="
              flashCard.media?.uuid &&
              ((!showAnswer && !flashCard.video_question_or_answer) ||
                (showAnswer && flashCard.video_question_or_answer))
            "
          />
          <qa-markdown-katex
            [data]="
              showAnswer ? flashCard?.answer || ' ' : flashCard?.text || ' '
            "
          >
          </qa-markdown-katex>
        </div>
      </ng-scrollbar>
    </div>

    <div
      class="explanation-panel qa-theme-inverted"
      [class.isPanelOpen]="isExplanationPanelOpen"
    >
      <div class="wrapper-exp" *ngIf="isExplanationPanelOpen">
        <div class="exapantion-title">
          {{ 'question.form.explanation' | translate }}
        </div>
        <button
          (click)="openExplainPanel($event, true)"
          class="toggle-explanation-button"
          color="primary"
          mat-button
          type="button"
        >
          <mat-icon>arrow_back_ios</mat-icon>
        </button>
        <ng-scrollbar class="scroll-container">
          <qa-markdown-katex [data]="flashCard?.explanation">
          </qa-markdown-katex>
        </ng-scrollbar>
      </div>
      <div class="actions">
        <a
          *ngIf="getWeblink()"
          [href]="getWeblink()"
          color="primary"
          mat-button
          target="_blank"
          type="button"
        >
          <mat-icon>open_in_new</mat-icon>
          {{ 'question.openWebLink' | translate }}
        </a>
      </div>
    </div>
  </div>
</qa-mock-phone>
