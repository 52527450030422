import { InjectionToken } from '@angular/core';
import { Action, AppState } from '../app.types';
import { playViewComponents } from './play-view-components';

export const key = 'play';

export const PLAY_VIEW_DATA = new InjectionToken<{}>('PLAY_VIEW_DATA');

export interface State {
  actions?: Action[];
  exitCaption?: string;
  exitText?: string;
  measureTimeFrom?: string;
  measureTimeTo?: string;
  progressCurrent?: number;
  progressPercentage?: number;
  progressTotal?: number;
  returnTo?: { sectionIndex: number; viewIndex: number };
  sectionIndex?: number;
  sections?: PlaySection[];
  timerFrom?: string;
  timerTo?: string;
  title?: string;
  titleIcon?: string;
  waiting?: boolean;
  isFrameLoading?: boolean;
}

export interface PlaySection {
  name: string;
  viewIndex?: number;
  views: PlayView[];
}

export interface PlayView {
  actions?: Action[];
  backActions?: Action[];
  component: keyof typeof playViewComponents;
  componentData?: any;
  navigationDisabledIf?: (state: AppState) => boolean;
}
