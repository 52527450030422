<div class="home-container">
  <div class="home-contents">
    <div *ngIf="auth.isLoggedIn()" class="topbar-mobile-only">
      <mat-icon (click)="openSideBar()" class="menu-open-icon">menu</mat-icon>
    </div>
    <span class="logo-container">
      <img src="assets/logo.png" alt="" />
    </span>
    <div class="container">
      <div class="title find-content-title">
        {{ 'findContent.contentTitle' | translate }}
      </div>
      <div class="embed-button" *ngIf="auth.isLoggedIn()">
        <button mat-button (click)="embedHome()">
          <mat-icon>code</mat-icon>
          {{ 'content.course.actionMenu.item2' | translate }}
        </button>
      </div>
      <qa-pin-input> </qa-pin-input>
      <div class="or-divider divider">
        <div class="left"></div>
        <div class="or">
          {{ 'findContent.or' | translate }}
        </div>
        <div class="right"></div>
      </div>
      <mat-form-field class="search-container m-top">
        <!-- <mat-icon matPrefix> search </mat-icon> -->
        <mat-label>
          {{ 'findContent.searchCourse' | translate }}
        </mat-label>
        <input
          [formControl]="courseSearchControl"
          autocomplete="off"
          matInput
          (keyup.enter)="submit()"
          type="search"
        />
        <button
          (click)="courseSearchControl.reset()"
          *ngIf="courseSearchControl.value?.trim().length"
          mat-icon-button
          matSuffix
          tabindex="-1"
          type="button"
        >
          <mat-icon>clear</mat-icon>
        </button>
        <button
          (click)="submit()"
          mat-icon-button
          matSuffix
          tabindex="-1"
          type="button"
        >
          <mat-icon>search</mat-icon>
        </button>
      </mat-form-field>
      <div class="search-button m-top">
        <button
          (click)="submit()"
          mat-raised-button
          color="primary"
          type="button"
          class="submit-btn"
        >
          {{ 'findContent.courseSearchButton' | translate }}
        </button>
      </div>
    </div>
    <div class="download">
      {{ 'findContent.downloadApp' | translate }}
    </div>
    <div class="app-links m-top">
      <a [href]="appLinks.android" class="app-link" target="_blank">
        <img src="assets/images/play-store-badge.png" />
      </a>
      <a [href]="appLinks.apple" class="app-link" target="_blank">
        <img src="assets/images/app-store-badge.png" />
      </a>
    </div>
    <div [@courseCards]="courses?.length || -1" class="course-cards">
      <a
        [routerLink]="['/course', course.pin_code]"
        *ngFor="let course of courses; trackBy: getCourseId"
        class="course-card"
        matRipple
      >
        <div class="course-card-header">
          <div class="course-card-title">
            {{ course.name }}
          </div>
          <mat-icon
            *ngIf="course.is_password_protected"
            matTooltip="{{
              'findContent.tooltip.passwordProtectedCourse' | translate
            }}"
          >
            lock
          </mat-icon>
        </div>
        <div
          *ngIf="course.responsible || course.organization?.name"
          class="course-card-footer"
        >
          {{ course.responsible }}
          <ng-container *ngIf="course.responsible && course.organization?.name">
            &mdash;
          </ng-container>
          {{ course.organization?.name }}
        </div>
      </a>
    </div>
    <button
      class="link-to-login"
      *ngIf="!auth.isLoggedIn()"
      (click)="jumpToLogin()"
    >
      <div class="click-logo">
        <mat-icon>open_in_new</mat-icon>
      </div>
      <div class="text">
        <span class="top">Du bist Lehrkraft?</span>
        <span class="bottom">Hier geht's zum Login!</span>
      </div>
    </button>
  </div>
</div>
