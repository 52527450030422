import { Component } from '@angular/core';
import { filter, map, switchMap } from 'rxjs/operators';
import {
  FlashCardModel,
  FlashCardQuery,
  FlashCardStackModel,
  TagQuery,
  TagQueryFilters,
} from 'src/app/api/models';
import {
  FlashCardsService,
  FlashCardStacksService,
  TagsService,
} from 'src/app/api/services';
import { DialogService } from 'src/app/cms/common/dialog.service';
import {
  ListActionMeta,
  ListConfig,
  ListQuery,
  MatTabGroupConfig,
} from './list.types';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { takeUntil } from 'rxjs';
import { DestroyNotifier } from '../common/destroy-notifier';
import { UtilityService } from '../../utility/utility.service';
import { NavigationBreadcrumb } from '../common/navigation-breadcrumbs/navigation-breadcrumbs.component';
import { AuthService } from 'src/app/auth/auth.service';
import { PanelService } from '../panel/panel.service';
import { getFlashCardImports } from '../panel/panels/flash-card-panel';
import { cloneDeep } from 'lodash';
import { PanelEntityType } from '../panel/panel.types';
import * as fileSaver from 'file-saver';
import { multiSelectAction } from './grid-data/grid-data.component';

@Component({
  selector: 'qa-flash-card-list',
  templateUrl: './flash-card-list.component.html',
  styleUrls: ['./flash-card-list.component.scss'],
})
export class FlashCardListComponent extends DestroyNotifier {
  matTabConfig: MatTabGroupConfig;
  config: ListConfig<FlashCardModel>;
  selectedTabIndex = 0;
  nonAcceptedCards = 0;

  stack?: FlashCardStackModel = undefined;
  navigationBreadcrumb: NavigationBreadcrumb;

  constructor(
    private flashCards: FlashCardsService,
    private dialog: DialogService,
    private router: Router,
    private matSnackBar: MatSnackBar,
    private translator: TranslateService,
    private matDialog: MatDialog,
    private tagService: TagsService,
    route: ActivatedRoute,
    private utilityService: UtilityService,
    private panelService: PanelService,
    private activeRoute: ActivatedRoute,
    private stackService: FlashCardStacksService,
    private auth: AuthService
  ) {
    super();
    route.queryParams.subscribe((params) => {
      if (params.showSuggestion) {
        this.selectedTabIndex = 1;
        this.initTable();
      } else {
        this.initTable();
      }
    });
    utilityService.nonAcceptedCards$
      .pipe(takeUntil(this.destroy$))
      .subscribe((count) => {
        this.nonAcceptedCards = count && count > 0 ? count : 0;
      });
  }

  async initTable() {
    if (this.activeRoute.snapshot.params.stackId) {
      this.stack = await this.stackService
        .getFlashCardStackById({ id: this.activeRoute.snapshot.params.stackId })
        .toPromise();
      this.navigationBreadcrumb = {
        homeUrl: '/cms/flash-card-stacks',
        back: {
          handleBack: () => {
            this.router.navigateByUrl('/cms/flash-card-stacks');
          },
        },
        currentPage: this.translator.instant('flashCard.title'),
        breadcrumbs: [
          {
            text: this.translator.instant('common.label.flashCardStack'),
            route: '/cms/flash-card-stacks',
          },
        ],
      };
    }

    const config = getFlashCardListConfig(
      this.flashCards,
      this.router,
      this.dialog,
      this.matSnackBar,
      this.translator,
      this.matDialog,
      this.stack &&
        this.stack.created_by !== this.auth.currentUserInfo$.value.sub
        ? false
        : true,
      this.tagService,
      this.auth,
      this.utilityService,
      0,
      this.stack,
      this.stackService
    );

    if (!this.stack) {
      this.setImports(config);
    }

    if (this.stack) {
      config.title = `${this.translator.instant(
        'flashCard.title'
      )}${` in "${this.stack.name}"`}`;
      config.activeActionNames = [...config.activeActionNames, 'unlink'];
      this.config = config;
    } else {
      let suggestionConfig = getFlashCardListConfig(
        this.flashCards,
        this.router,
        this.dialog,
        this.matSnackBar,
        this.translator,
        this.matDialog,
        true,
        this.tagService,
        this.auth,
        this.utilityService,
        this.nonAcceptedCards
      );

      suggestionConfig = {
        ...suggestionConfig,
        createButton: null,
        fetch: (queryParams?: ListQuery<FlashCardModel>) => {
          const query = queryParams as unknown as FlashCardQuery;
          query.filters.is_accepted = false;
          return this.flashCards.queryFlashCards({ body: query }).pipe(
            map((response) => {
              localStorage.setItem(
                `content-card-list`,
                JSON.stringify(response)
              );
              return response;
            })
          );
        },
        actions: [
          {
            click: ({ row, dataSource }) => {
              this.dialog
                .confirmDelete()
                .pipe(
                  filter((isConfirmed) => isConfirmed),
                  switchMap(() =>
                    this.flashCards.deleteFlashCard({ id: row.id })
                  )
                )
                .subscribe({
                  next: () => {
                    this.matSnackBar.open(
                      this.translator.instant(
                        'content.flashCard.deleteItem.success'
                      )
                    );
                    if (!row.accepted) {
                      this.utilityService.setNonAcceptedCards(
                        this.nonAcceptedCards - 1
                      );
                    }
                    dataSource.refresh();
                  },
                  error: () => {
                    this.matSnackBar.open(
                      this.translator.instant(
                        'content.flashCard.deleteItem.error'
                      )
                    );
                  },
                });
            },
            icon: 'delete',
            name: 'quiz.tooltip.delete',
            tooltip: 'flashCard.tooltip.delete',
            type: 'button',
          },
          {
            click: ({ row }) => {
              this.router.navigate(['/cms/edit/flash-card', row.id]);
            },
            icon: 'add',
            name: 'quiz.tooltip.accepted',
            tooltip: 'common.tooltip.add',
            type: 'button',
          },
        ],
        activeActionNames: ['delete', 'accepted'],
        activeColumnNames: ['id', 'text'],
        name: 'publicFlashCardList',
        columns: suggestionConfig.columns.slice(0, 2),
      };

      this.matTabConfig = {
        title: 'flashCard.title',
        selectedTabIndex: this.selectedTabIndex,
        tabs: [
          {
            label: 'flashCard.table.yourFlashCards',
            config,
          },
          {
            label: 'flashCard.table.suggestions',
            badge: this.utilityService.nonAcceptedCards$,
            config: suggestionConfig,
          },
        ],
      };
    }
  }

  setImports(config) {
    const imports = getFlashCardImports(
      this.translator,
      this.panelService,
      this.matDialog,
      null,
      false,
      this.auth.currentUserHasGroup('Admin') ||
        this.auth.currentUserHasGroup('ContentManager')
    );
    const idxJson = imports.findIndex((imp) => imp.id === 'import-json');
    if (idxJson !== -1) {
      imports[idxJson].action.click = async (dataSource) => {
        const item = (await this.panelService.promptFile(
          'json'
        )) as FlashCardModel;
        this.matDialog.closeAll();
        dataSource.loading = true;
        this.flashCards
          .createFlashCard({
            body: item,
          })
          .subscribe(
            () => {
              dataSource.refresh();
            },
            () => (dataSource.loading = false)
          );
      };
    }
    const idxCsv = imports.findIndex((imp) => imp.id === 'import-as-csv');
    if (idxCsv !== -1) {
      imports[idxCsv].action.click = () => {
        this.matDialog.closeAll();
        this.router.navigate([`/cms/csv-imports/flash-card`], {
          queryParams: { type: 'flash-card' },
        });
      };
    }
    config.imports = imports;
  }
}

export function getFlashCardListConfig(
  flashCards: FlashCardsService,
  router: Router,
  dialog: DialogService,
  matSnackBar: MatSnackBar,
  translator: TranslateService,
  matDialog: MatDialog,
  showMultiselect: boolean,
  tagsService: TagsService,
  auth: AuthService,
  utilityService?: UtilityService,
  nonAcceptedCards?: number,
  stack?: FlashCardStackModel,
  stackService?: FlashCardStacksService
): ListConfig<FlashCardModel> {
  function onEdit(meta: ListActionMeta<FlashCardModel>) {
    if (meta.row) {
      router.navigateByUrl(
        `/cms/edit/flash-card/${meta.row.id}${
          stack ? `?flash-card-stack=${stack.id}` : ''
        }${!stack ? `?contentCardId=${meta.row.id}` : ''}`
      );
    } else {
      router.navigate(['/cms/add/flash-card']);
    }
  }
  function exportAsJson(meta: ListActionMeta<FlashCardModel>) {
    meta.dataSource.loading = true;
    let fullItem: FlashCardModel;
    fullItem = cloneDeep(meta.row);
    delete fullItem.id;
    fullItem['meta:importType'] = PanelEntityType.FlashCard;
    const file = `${JSON.stringify(fullItem)}\n`;
    const blob = new Blob([file], { type: 'application/json' });
    fileSaver.saveAs(
      blob,
      `qa-${PanelEntityType.FlashCard}-${fullItem.text}.json`
    );
    meta.dataSource.loading = false;
  }
  const ret: ListConfig<FlashCardModel> = {
    actions: [
      {
        click: onEdit,
        icon: 'edit',
        name: 'edit',
        tooltip: 'common.tooltip.edit',
        type: 'button',
        hide: () =>
          window.location.href.indexOf('cms/content/flash-cards') !== -1,
      },
      {
        click: ({ row, dataSource }) => {
          dialog
            .confirmDelete()
            .pipe(
              filter((isConfirmed) => isConfirmed),
              switchMap(() => flashCards.deleteFlashCard({ id: row.id }))
            )
            .subscribe({
              next: () => {
                matSnackBar.open(
                  translator.instant('content.flashCard.deleteItem.success')
                );
                if (!row.accepted) {
                  utilityService.setNonAcceptedCards(nonAcceptedCards - 1);
                }
                dataSource.refresh();
              },
              error: () => {
                matSnackBar.open(
                  translator.instant('content.flashCard.deleteItem.error')
                );
              },
            });
        },
        icon: 'delete',
        name: 'delete',
        tooltip: 'flashCard.tooltip.delete',
        type: 'button',
      },
      {
        click: ({ row, dataSource }) => {
          dialog
            .confirm({
              title: translator.instant('content.flashCard.unlinkItem.title'),
              text: translator.instant('content.flashCard.unlinkItem.text'),
              buttons: [
                {
                  color: 'primary',
                  text: translator.instant('common.button.cancel'),
                },
                {
                  color: 'warn',
                  text: translator.instant(
                    'content.flashCard.selectedItem.label'
                  ),
                  value: true,
                },
              ],
            })
            .pipe(
              filter((isConfirmed) => isConfirmed),
              switchMap(() => {
                const cardIds = [row.id];
                return stackService.removeCardsFromFlashCardStack({
                  id: stack.id,
                  card_ids: cardIds.join(','),
                });
              })
            )
            .subscribe({
              next: () => {
                matSnackBar.open(
                  translator.instant('content.flashCard.unlinkItem.success')
                );
                dataSource.refresh();
              },
              error: () => {
                matSnackBar.open(
                  translator.instant('content.flashCard.unlinkItem.error')
                );
              },
            });
        },
        icon: 'link_off',
        name: 'unlink',
        isDisabled: (meta) =>
          meta.row.created_by !== auth.currentUserInfo$.value.sub,
        tooltip: 'content.flashCard.actionMenu.item2',
        type: 'button',
        hide: () =>
          window.location.href.indexOf('cms/content/flash-cards') !== -1,
      },
    ],
    actionsMenu: [
      {
        icon: 'save_alt',
        name: 'download',
        label: translator.instant('content.flashCard.actionMenu.item1'),
        type: 'button',
        click: (meta) => exportAsJson(meta),
      },
    ],
    activeActionNames: ['edit', 'delete'],
    activeColumnNames: [
      'id',
      'text',
      'has_weblink',
      'has_explanation',
      'source',
    ],
    columns: [
      {
        label: 'ID',
        name: 'id',
        click: onEdit,
        sortable: !(stackService && stack),
        width: '64px',
        filter: (value) => {
          return {
            id: Number(value),
          };
        },
      },
      {
        click: onEdit,
        label: 'Text',
        name: 'text',
        sortable: !(stackService && stack),
        primary: true,
        filter: (value) => {
          return {
            text: value,
          };
        },
      },
      {
        accessor: (flashCard) =>
          flashCard.weblink
            ? translator.instant('common.label.yes')
            : translator.instant('common.label.no'),
        label: 'Weblink',
        name: 'has_weblink',
        sortable: !(stackService && stack),
        width: '72px',
        hideOnMobile: true,
        filter: !stack
          ? (value) => ({
              has_weblink: value,
            })
          : null,
        click: onEdit,
        filterOptions: [
          {
            name: 'common.label.yes',
            value: true,
          },
          {
            name: 'common.label.no',
            value: false,
          },
        ],
      },
      {
        accessor: (flashCard) =>
          flashCard.explanation
            ? translator.instant('common.label.yes')
            : translator.instant('common.label.no'),
        label: 'flashCard.table.explanation',
        name: 'has_explanation',
        sortable: !(stackService && stack),
        width: '88px',
        hideOnMobile: true,
        filter: !stack
          ? (value) => ({
              has_explanation: value,
            })
          : null,
        click: onEdit,
        filterOptions: [
          {
            name: 'common.label.yes',
            value: true,
          },
          {
            name: 'common.label.no',
            value: false,
          },
        ],
      },
      {
        accessor: (flashCard) =>
          flashCard.source
            ? translator.instant('common.label.yes')
            : translator.instant('common.label.no'),
        label: 'flashCard.form.source',
        name: 'source',
        click: onEdit,
        sortable: false,
        width: '72px',
      },
      {
        label: 'Tag',
        name: 'tags',
        click: onEdit,
        alwaysHide: true,
        filter: !stack
          ? (value) => {
              return {
                tags: value.map((v) => ({ name: v.name })),
              };
            }
          : null,
        filterOptionsMultiSelect: ({ name, page }) => {
          const filters = {
            show_only_user_tags: true,
          } as Partial<TagQueryFilters> as TagQueryFilters;
          if (name) {
            filters.name = name;
          }
          return tagsService.tagControllerGetStats({
            body: {
              filters,
              paging: {
                page,
                page_size: 50,
              },
              sorting: [],
            } as Partial<TagQuery> as TagQuery,
          });
        },
        filterValue: (value) => {
          return value ? value.map((v) => v.name) : null;
        },
      },
    ],
    createButton: {
      click: (dataSource) => onEdit({ dataSource }),
    },
    fetchRowItemById: (id: number) => {
      return flashCards.getFlashCardById({ id });
    },
    fetch: (queryParams?: ListQuery<FlashCardModel>) => {
      if (stackService && stack) {
        return stackService.getCardsInFlashCardStack({ id: stack.id }).pipe(
          map((response) => {
            const filteredResponse = response.filter((question) => {
              if (
                queryParams.filters?.text &&
                !question.text.includes(queryParams.filters.text)
              ) {
                return false;
              }
              if (
                queryParams.filters?.id &&
                question.id !== queryParams.filters.id
              ) {
                return false;
              }
              return true;
            });
            return {
              data: filteredResponse,
              pagination: {
                has_next_page: false,
                has_previous_page: false,
                page: 0,
                page_size: filteredResponse.length,
                total: filteredResponse.length,
              },
              sorting: queryParams.sorting,
            };
          })
        );
      } else {
        const query = queryParams as unknown as FlashCardQuery;
        query.filters.is_accepted = true;
        return flashCards.queryFlashCards({ body: query }).pipe(
          map((response) => {
            localStorage.setItem(`content-card-list`, JSON.stringify(response));
            return response;
          })
        );
      }
    },
    query: {
      initial: {
        sorting: [
          {
            option: 'id',
            direction: 'DESC',
          },
        ],
      },
      searchColumnName: 'text',
    },
    hideWrapper: !stack,
    name: 'flashCardList',
    title: '',
  };

  if (stack && stackService) {
    ret.hidePaginator = true;
    ret.onPositionDrag = (el: FlashCardModel, position: number) => {
      return stackService.repositionCardsInFlashCardStack({
        id: stack?.id,
        card_id: el?.id,
        body: {
          position,
        },
      });
    };
  }
  if (stack && stack.created_by === auth.currentUserInfo$.value.sub) {
    ret.createButton = {
      options: [
        {
          icon: 'add',
          click: () =>
            router.navigateByUrl(
              `/cms/add/flash-card?flash-card-stack=${stack.id}`
            ),
          text: translator.instant('content.flashCard.addMenu.item1'),
        },
        {
          icon: 'move_to_inbox',
          click: () =>
            router.navigateByUrl(
              `/cms/import-items?parentId=${stack.id}&type=flash-card`
            ),
          text: translator.instant('content.flashCard.addMenu.item2'),
        },
        {
          icon: 'description',
          click: () =>
            router.navigateByUrl(
              `/cms/csv-imports/flash-card/${stack.id}?type=flash-card`
            ),
          text: translator.instant('content.flashCard.addMenu.item4'),
        },
      ],
    };
  }

  if (!stack) {
    ret.createButton = {
      options: [
        {
          text: translator.instant('content.flashCard.addMenu.item1'),
          icon: 'add',
          click: () => {
            router.navigateByUrl('/cms/add/flash-card');
          },
        },
      ],
    };
  }

  if (showMultiselect) {
    ret.multiselectActions = [
      {
        icon: 'delete',
        name: 'question.tooltip.delete',
        type: 'button',
        click: (meta) => {
          return new Promise((resolve) => {
            dialog
              .confirmDelete(
                null,
                translator.instant('common.message.deleteMulti', {
                  count: meta.rows.length,
                })
              )
              .pipe(filter((isConfirmed) => isConfirmed))
              .subscribe(async () => {
                await multiSelectAction(
                  meta.rows,
                  async (item: any) => {
                    await flashCards
                      .deleteFlashCard({ id: item.id })
                      .toPromise();
                    return true;
                  },
                  `${translator.instant(
                    'common.label.deleting'
                  )} ${translator.instant('common.label.flashCard')}`,
                  matDialog
                );
                meta.dataSource.refresh();
                resolve();
              });
          });
        },
      },
      {
        icon: 'link_off',
        name: 'content.flashCard.actionMenu.item2',
        type: 'button',
        hide: () =>
          window.location.href.indexOf('cms/content/flash-cards') !== -1
            ? true
            : false,
        click: (meta) => {
          return new Promise((resolve) => {
            dialog
              .confirm({
                title: translator.instant('content.flashCard.unlinkItem.title'),
                text: translator.instant('content.flashCard.unlinkItem.text'),
                buttons: [
                  {
                    color: 'primary',
                    text: translator.instant('common.button.cancel'),
                  },
                  {
                    color: 'warn',
                    text: translator.instant(
                      'content.flashCard.selectedItem.label'
                    ),
                    value: true,
                  },
                ],
              })
              .pipe(
                filter((isConfirmed) => isConfirmed),
                switchMap(() => {
                  const cardIds = meta.rows.length
                    ? meta.rows.map((x) => x.id)
                    : [];
                  return stackService.removeCardsFromFlashCardStack({
                    id: stack.id,
                    card_ids: cardIds.join(','),
                  });
                })
              )
              .subscribe({
                next: () => {
                  matSnackBar.open(
                    translator.instant('content.flashCard.unlinkItem.success')
                  );
                  meta.dataSource.refresh();
                  resolve();
                },
                error: () => {
                  matSnackBar.open(
                    translator.instant('content.flashCard.unlinkItem.error')
                  );
                  resolve();
                },
              });
          });
        },
      },
    ];
  }

  return ret;
}
