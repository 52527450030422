import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import { copyTextToClipboard } from 'src/app/utility/app.utils';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { MatInput } from '@angular/material/input';

export interface EmbedDialogData {
  coursePin?: string;
  quizId?: number;
  examPin?: string;
  entity?: string;
  cardStackId?: number;
}

@Component({
  selector: 'qa-embed-dialog',
  templateUrl: './embed-dialog.component.html',
  styleUrls: ['./embed-dialog.component.scss'],
})
export class EmbedDialogComponent implements OnInit {
  code: string;
  blurInput = true;
  smallStyle = false;
  @ViewChild('textArea') textArea: MatInput;

  height = 750;

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: EmbedDialogData,
    private matSnackBar: MatSnackBar,
    private translator: TranslateService,
    private dialogRef: MatDialogRef<EmbedDialogComponent>
  ) {}

  ngOnInit() {
    this.dialogRef.updateSize('800px');
    this.reloadCode();
  }

  smallIFrameStyle() {
    return 'max-width: 360px; min-height: 700px;margin-left: auto; margin-right: auto;';
  }

  normalIFrameStyle() {
    return `width: 100%; height: ${this.height}px; max-height: 98vh;`;
  }

  reloadCode() {
    this.code = `<div class="__qa-embed-frame" style="${
      this.smallStyle ? this.smallIFrameStyle() : this.normalIFrameStyle()
    }">\n`;
    this.code += `  <script src="https://quizacademy.de/embed?v=1.0.0&edition=${
      environment.edition
    }&path=${encodeURIComponent(this.getUrlPath())}" defer></script>\n`;
    this.code += '</div>';
  }

  getUrl(): string {
    const base = `https://${environment.edition}.quizacademy.io/`;
    const url = `${base}${this.getUrlPath()}`;
    return url;
  }

  getUrlPath(): string {
    if (
      !this.data.coursePin &&
      !this.data.examPin &&
      !this.data.cardStackId &&
      !this.data.quizId
    ) {
      return ``;
    }
    let url = `${this.data.entity}/${this.data.coursePin}`;
    if (this.data.quizId !== undefined) {
      url += `/quiz/${this.data.quizId}`;
    } else if (this.data.examPin !== undefined) {
      url = `exam/${this.data.examPin}`;
    } else if (this.data.cardStackId !== undefined) {
      url += `/flash-card-stack/${this.data.cardStackId}`;
    }
    return url;
  }

  onBlur() {
    this.blurInput = this.code ? true : false;
  }

  onEdit() {
    this.blurInput = false;
  }

  copyWeblink() {
    if (this.code) {
      try {
        copyTextToClipboard(this.code);
        this.matSnackBar.open(
          this.translator.instant('embedDialog.copiedToClipboard')
        );
      } catch (error) {
        this.matSnackBar.open(
          this.translator.instant('embedDialog.notCopiedToClipboard')
        );
      }
    } else {
      this.matSnackBar.open(
        this.translator.instant('embedDialog.notCopiedToClipboard')
      );
    }
  }
  openLink() {
    window.open(
      'https://quizacademy.de/akademie/integration/#iframe',
      '_blank'
    );
  }
}
