<div class="course-market-list-container">
  <div class="course-market-list-header">
    <h1
      [class.mat-headline-4]="!config?.hideWrapper"
      [class.mat-headline-5]="config?.hideWrapper"
      *ngIf="!config?.hideWrapper"
      direction="up"
      qaEnterAnimation
    >
      {{ config.title | translate }}
    </h1>
  </div>
  <div
    [delay]="100"
    class="top-bar"
    direction="up"
    qaEnterAnimation
    [style.display]="config?.hideTopBar ? 'none' : null"
  >
    <div class="table-filters">
      <mat-form-field
        class="form-field"
        subscriptSizing="dynamic"
        [appearance]="'outline'"
      >
        <input
          [formControl]="searchControl"
          (keyup.enter)="searchByName()"
          matInput
          autocomplete="off"
          placeholder="{{ 'common.list.search' | translate }}"
          type="text"
          class="search-field"
        />
        <div class="buttons">
          <button
            matSuffix
            mat-button
            class="search-button"
            (click)="$event.stopPropagation(); searchByName()"
          >
            <mat-icon>search</mat-icon>
          </button>
          <button
            matSuffix
            *ngIf="searchControl?.value"
            mat-button
            class="search-button"
            (click)="$event.stopPropagation(); clearSearch()"
          >
            <mat-icon>close</mat-icon>
          </button>
        </div>
      </mat-form-field>
      <qa-tree-select
        class="category-contents"
        [control]="categoryControl"
        (valueChanged)="changeCategory($event)"
        label="{{ 'course.form.category' | translate }}"
        [tree]="categoriesList"
        *ngIf="categoriesList"
      >
      </qa-tree-select>
      <mat-form-field
        subscriptSizing="dynamic"
        class="form-field"
        [appearance]="'outline'"
      >
        <mat-select
          (selectionChange)="changeProvider($event)"
          [formControl]="providersControl"
          [multiple]="false"
          [placeholder]="'courseMarket.allProviders' | translate"
        >
          <mat-option value="">{{
            'courseMarket.allProviders' | translate
          }}</mat-option>
          <mat-option *ngFor="let option of providers" [value]="option.value">
            {{ option.label | translate }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <mat-paginator
      [pageSize]="DEFAULT_PAGE_SIZE"
      [pageSizeOptions]="[10, 25, 50]"
      #paginator
      [class.hidden]="config?.hidePaginator"
    >
    </mat-paginator>
  </div>
  <div class="loading-data" [class.loading]="!dataSource || dataSource.loading">
    <mat-spinner [diameter]="48"></mat-spinner>
  </div>
  <mat-table
    [dataSource]="dataSource"
    [delay]="200"
    direction="up"
    matSort
    qaEnterAnimation
    cdkDropList
    (cdkDropListDropped)="afterItemDropped($event)"
  >
    <ng-container matColumnDef="drag" *ngIf="config?.onPositionDrag">
      <mat-header-cell
        [style.flex]="'0 0 32px'"
        *matHeaderCellDef
      ></mat-header-cell>
      <mat-cell *matCellDef="let row; let i = index" [style.flex]="'0 0 32px'">
        <mat-icon
          cdkDragHandle
          class="drag-indicator"
          (touchstart)="dragDisabled = false"
          (touchend)="dragDisabled = true"
          (mousedown)="dragDisabled = false"
          (mouseup)="dragDisabled = true"
          >drag_indicator</mat-icon
        >
      </mat-cell>
    </ng-container>
    <ng-container
      [matColumnDef]="column.name"
      *ngFor="let column of config.columns"
    >
      <ng-container *ngIf="column.sortable">
        <mat-header-cell
          [style.flex]="column.width ? '0 0 ' + column.width : null"
          *matHeaderCellDef
          mat-sort-header
        >
          {{ column.label | translate }}
        </mat-header-cell>
      </ng-container>
      <ng-container *ngIf="!column.sortable">
        <mat-header-cell
          [style.flex]="column.width ? '0 0 ' + column.width : null"
          *matHeaderCellDef
        >
          {{ column.label | translate }}
        </mat-header-cell>
      </ng-container>
      <mat-cell
        (click)="
          column.click &&
            column.click({ row: row, index: i, dataSource: dataSource })
        "
        [class.click-cell]="
          !column.isClickable ||
          (column.isClickable &&
            column.isClickable({ row: row, index: i, dataSource: dataSource }))
        "
        [class.link-cell]="!!column.link"
        [matTooltip]="
          column.tooltip &&
          column.tooltip({ row: row, index: i, dataSource: dataSource })
        "
        [style.flex]="column.width ? '0 0 ' + column.width : null"
        *matCellDef="let row; let i = index"
        [class.primaryInfo]="column.primary"
        [class.hideOnMobile]="column.hideOnMobile"
        [class.disabled]="row.rowDisabled || false"
      >
        <ng-template #cellContent>
          <ng-container
            *ngIf="
              !column.toggle && !column.image;
              else column.image ? imageContent : toggleContent
            "
          >
            <mat-icon
              *ngIf="
                column.icon &&
                column.icon({ row: row, index: i, dataSource: dataSource })
              "
              >{{
                column.icon({ row: row, index: i, dataSource: dataSource })
              }}</mat-icon
            >
            <span class="column-name-mobile-only"
              >{{ column.label | translate }}:</span
            ><span class="cell-text-content">{{
              column.accessor ? column.accessor(row) : row[column.name]
            }}</span>
          </ng-container>
        </ng-template>
        <ng-template #toggleContent>
          <mat-slide-toggle
            (change)="
              column.toggle.action($event, {
                row: row,
                index: i,
                dataSource: dataSource
              })
            "
            [(ngModel)]="
              column.accessor ? column.accessor(row) : row[column.name]
            "
            [disabled]="
              column.toggle.isDisabled &&
              column.toggle.isDisabled({
                row: row,
                index: i,
                dataSource: dataSource
              })
            "
            color="primary"
          >
          </mat-slide-toggle>
        </ng-template>
        <ng-template #imageContent>
          <img [src]="row[column.name]" />
        </ng-template>
        <a
          [routerLink]="
            column.link.routerLink({
              row: row,
              index: i,
              dataSource: dataSource
            })
          "
          [queryParams]="
            column.link.queryParams &&
            column.link.queryParams({
              row: row,
              index: i,
              dataSource: dataSource
            })
          "
          *ngIf="column.link && !column.click; else cellContent"
        >
          <div class="link-cell-container">
            <ng-container *ngTemplateOutlet="cellContent"> </ng-container>
          </div>
        </a>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="actions">
      <mat-header-cell
        [style.flex]="
          '0 0 ' +
          (actionsColumnWidth + (config?.actionsMenu?.length ? 48 : 0)) +
          'px'
        "
        *matHeaderCellDef
      >
      </mat-header-cell>
      <mat-cell
        [style.flex]="
          '0 0 ' +
          (actionsColumnWidth + (config?.actionsMenu?.length ? 48 : 0)) +
          'px'
        "
        *matCellDef="let row; let i = index"
      >
        <ng-container
          *ngIf="
            config.showMessageInActionColumn
              ? config.showMessageInActionColumn(row)
              : false
          "
        >
          <div
            [matTooltip]="
              config?.showMessageInActionTooltip
                ? (config?.showMessageInActionTooltip | translate)
                : null
            "
            class="disabled-message"
          >
            {{ config.showMessageInActionColumn(row) | translate }}
          </div>
        </ng-container>
        <ng-container *ngFor="let action of activeActions">
          <ng-container
            *ngIf="
              !action.hide ||
              (action.hide &&
                !action.hide({
                  row: row,
                  index: i,
                  dataSource: dataSource
                }))
            "
          >
            <a
              [disabled]="
                action.isDisabled &&
                action.isDisabled({
                  row: row,
                  index: i,
                  dataSource: dataSource
                })
              "
              [matTooltip]="
                action.tooltip ? (action.tooltip | translate) : null
              "
              [routerLink]="
                action.link?.routerLink({
                  row: row,
                  index: i,
                  dataSource: dataSource
                })
              "
              [queryParams]="
                action.link?.queryParams &&
                action.link.queryParams({
                  row: row,
                  index: i,
                  dataSource: dataSource
                })
              "
              *ngIf="action.type === 'link'"
              color="primary"
              mat-icon-button
            >
              <mat-icon class="material-icons">
                {{
                  isFunction(action.icon)
                    ? action.icon({
                        row: row,
                        index: i,
                        dataSource: dataSource
                      })
                    : action.icon
                }}
              </mat-icon>
            </a>
            <button
              (click)="
                action.click({ row: row, index: i, dataSource: dataSource })
              "
              [disabled]="
                action.isDisabled &&
                action.isDisabled({
                  row: row,
                  index: i,
                  dataSource: dataSource
                })
              "
              [matTooltip]="
                action.tooltip ? (action.tooltip | translate) : null
              "
              *ngIf="action.type === 'button'"
              color="primary"
              mat-icon-button
              type="button"
            >
              <mat-icon [class]="action.class">
                {{
                  isFunction(action.icon)
                    ? action.icon({
                        row: row,
                        index: i,
                        dataSource: dataSource
                      })
                    : action.icon
                }}
              </mat-icon>
            </button>
          </ng-container>
        </ng-container>
      </mat-cell>
    </ng-container>
    <mat-header-row
      *matHeaderRowDef="displayedColumnNames"
      class="table-header"
    >
    </mat-header-row>
    <mat-row
      cdkDrag
      [cdkDragDisabled]="!config?.onPositionDrag || dragDisabled"
      [cdkDragData]="row"
      (cdkDragReleased)="dragDisabled = true"
      *matRowDef="let row; columns: displayedColumnNames"
    >
    </mat-row>
  </mat-table>
</div>
