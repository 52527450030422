import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'qa-link-shortener',
  template: ``,
  styles: [``],
})
export class ShortenerComponent {
  constructor(private activatedRoute: ActivatedRoute, private router: Router) {
    const targetId = this.activatedRoute.snapshot.params.anyid;
    if (/^L[a-zA-Z]{5}$/gm.test(targetId)) {
      this.router.navigate(['live-events', targetId]);
    } else if (/^E[a-zA-Z]{5}$/gm.test(targetId)) {
      this.router.navigate(['exam', targetId]);
    } else if (/^C[a-zA-Z]{5}$/gm.test(targetId)) {
      this.router.navigate(['course', targetId]);
    } else if (/^B[a-zA-Z]{5}$/gm.test(targetId)) {
      this.router.navigate(['book', targetId]);
    } else {
      this.router.navigateByUrl('/');
    }
  }
}
