import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRippleModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { MatRadioModule } from '@angular/material/radio';
import { TruncatePipe } from 'src/app/widget/truncate.pipe';
import { ActionComponent } from './action.component';
import { DonutChartComponent } from './donut-chart.component';
import { CorrectDonutChartComponent } from './correct-donut-chart.component';
import { QuestionAnswersComponent } from './question-answers/question-answers.component';
import { EmbeddedVideoComponent } from './embedded-video/embedded-video.component';
import { MarkdownKatexModule } from './markdown-katex/markdown-katex.module';
import { PrintHelpComponent } from './print-help.component';
import { TranslateModule } from '@ngx-translate/core';
import { PrintFlashCardStackComponent } from './print-flash-card-stack/print-flash-card-stack.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { TtsButtonComponent } from './tts-button/tts-button.component';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { QrModalComponent } from './qr-model.component';
import { QrCodeBoxComponent } from './qr-code-box/qr-code-box.component';
import { SharedModule } from '../shared/shared.module';
import { ImageDisplayComponent } from './image-display/image-display.component';

@NgModule({
  declarations: [
    ActionComponent,
    DonutChartComponent,
    CorrectDonutChartComponent,
    PrintHelpComponent,
    QuestionAnswersComponent,
    EmbeddedVideoComponent,
    TruncatePipe,
    PrintFlashCardStackComponent,
    TtsButtonComponent,
    QrModalComponent,
    QrCodeBoxComponent,
    ImageDisplayComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    MarkdownKatexModule,
    MatButtonModule,
    MatCheckboxModule,
    MatIconModule,
    MatRadioModule,
    MatRippleModule,
    TranslateModule,
    MatProgressSpinnerModule,
    MatFormFieldModule,
    FormsModule,
    MatSelectModule,
    MatMenuModule,
    MatTooltipModule,
  ],
  exports: [
    ActionComponent,
    DonutChartComponent,
    CorrectDonutChartComponent,
    PrintHelpComponent,
    QuestionAnswersComponent,
    EmbeddedVideoComponent,
    TruncatePipe,
    TranslateModule,
    PrintFlashCardStackComponent,
    TtsButtonComponent,
    QrModalComponent,
    QrCodeBoxComponent,
    ImageDisplayComponent,
  ],
})
export class WidgetModule {}
