<div mat-dialog-title class="main-header-box">
  <div class="cross-icon close-icon">
    <mat-icon mat-dialog-close>clear</mat-icon>
  </div>
</div>
<mat-dialog-content class="dialog-contents">
  <div class="dialog-container">
    <div class="text" [innerHTML]="data.infoData"></div>
    <div class="tip-container" *ngIf="data.tipData">
      <span class="tip-title"
        >{{ 'liveEvents.setup.label.tip' | translate }} :
      </span>
      <span class="tip-text" [innerHTML]="data.tipData"></span>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end" *ngIf="data?.closeButton">
  <qa-button
    [isRaisedButton]="true"
    [label]="data.closeButton"
    [mat-dialog-close]="true"
  ></qa-button>
</mat-dialog-actions>
