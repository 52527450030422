import {
  Component,
  ElementRef,
  Inject,
  ViewEncapsulation,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { passwordValidator } from './auth.types';

export interface NewPasswordDialogData {
  email: string;
  password: string;
}

@Component({
  selector: 'qa-new-password-dialog',
  templateUrl: './new-password-dialog.component.html',
  styleUrls: ['./new-password-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class NewPasswordDialogComponent {
  errorMessage: string;
  form: UntypedFormGroup;

  constructor(
    @Inject(MAT_DIALOG_DATA) data: NewPasswordDialogData,
    private dialogRef: MatDialogRef<NewPasswordDialogComponent>,
    elementRef: ElementRef<HTMLElement>,
    formBuilder: UntypedFormBuilder
  ) {
    elementRef.nativeElement.classList.add('qa-new-password-dialog');
    this.form = formBuilder.group({
      password: [data.password, [Validators.required]],
      newPassword: ['', [Validators.required, passwordValidator]],
    });
  }

  submit() {
    this.form.disable();
    const rawValue = this.form.getRawValue();
    this.dialogRef.close(rawValue);
  }
}
