<h1>{{ 'category.title' | translate }}</h1>
<div class="category-panel">
  <div class="panel-level" *ngFor="let parentId of openPanels; let i = index">
    <ng-container *ngIf="findEntryFor(parentId) as parent">
      <div class="panel-header">
        <span>{{ parent.name }}</span>
        <div class="actions">
          <button mat-icon-button (click)="createCategory(parentId)">
            <mat-icon>add</mat-icon>
          </button>
        </div>
      </div>
      <ng-scrollbar>
        <div
          class="entry"
          *ngFor="let entry of findChildrenFor(parentId)"
          (click)="
            findChildrenFor(entry.id).length
              ? selectPanel(entry, i + 1)
              : edit($event, entry)
          "
          [class.selected]="openPanels.includes(entry.id)"
        >
          <mat-icon class="example-icon">{{
            entry.icon_code || 'check_box_outline_blank'
          }}</mat-icon>
          <span class="item-id">{{ entry.id }}</span>
          <span class="name">{{ entry.name }}</span>
          <mat-icon
            class="chevron-suffix"
            *ngIf="findChildrenFor(entry.id).length"
            >chevron_right</mat-icon
          >
          <div class="actions">
            <button mat-icon-button (click)="edit($event, entry)">
              <mat-icon>edit</mat-icon>
            </button>
            <button mat-icon-button (click)="createCategory(entry.id)">
              <mat-icon>add</mat-icon>
            </button>
          </div>
        </div>
      </ng-scrollbar>
    </ng-container>
  </div>
</div>
