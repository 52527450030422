export class KahootImporter {
  static isKahoot(csvArr: string[][]): boolean {
    const firstrow = csvArr?.length ? csvArr[0] : [];
    return (
      firstrow
        .join(',')
        .replace(/\n|\r\n|[\s]/g, '')
        .match(
          /QuestionNumber,Question,Answer1,Answer2,Answer3,Answer4,Answer5,Answer6,CorrectAnswers(.*)/g
        ) !== null
    );
  }

  static importKahoot(csvArr: string[][]) {
    const buffer = {};
    let outArr = [];
    for (const row of csvArr.slice(1)) {
      if (row.length <= 0) {
        continue;
      }
      if (Object.keys(buffer).includes(row[0])) {
        continue;
      }
      let ansAmount = 0;
      buffer[row[0]] = [row[1], ''];
      for (let i = 2; i < 8; i++) {
        if (
          (row[i] && row[i].match(/^[\s]+$/g)) ||
          row[i] === '' ||
          row[i] === '&nbsp;'
        ) {
          buffer[row[0]].push('');
          buffer[row[0]].push('');
        } else {
          buffer[row[0]].push(row[i]);
          if (
            row[8] &&
            row[8]
              .replace(/[\s]+/g, ' ')
              .includes(row[i].replace(/[\s]+/g, ' '))
          ) {
            buffer[row[0]].push('correct');
            ansAmount++;
          } else {
            buffer[row[0]].push('incorrect');
          }
        }
      }
      buffer[row[0]][1] = ansAmount > 1 ? 'Multiple Choice' : 'Single Choice';
      outArr.push(row[0]);
    }
    outArr = outArr.map((name) => {
      return buffer[name];
    });
    return outArr;
  }
}
