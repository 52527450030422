import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
} from '@angular/core';

@Component({
  selector: 'qa-import-csv-navigation',
  templateUrl: './import-csv-navigation.component.html',
  styleUrls: ['./import-csv-navigation.component.scss'],
})
export class ImportCsvNavigationComponent implements OnChanges {
  @Input() itemCount: number;
  @Input() currentItemPosition = 1;
  @Input() isImport: boolean;
  @Output() navigation = new EventEmitter();
  currentItemNumber = 1;

  constructor() {}

  next() {
    this.currentItemPosition++;
    this.currentItemNumber = this.currentItemPosition;
    this.navigation.emit(this.currentItemPosition - 1);
  }

  previous() {
    if (this.currentItemPosition > 1) {
      this.currentItemPosition--;
      this.currentItemNumber = this.currentItemPosition;
      this.navigation.emit(this.currentItemPosition - 1);
    }
  }

  onChange() {
    if (this.currentItemNumber && this.currentItemNumber <= this.itemCount) {
      this.currentItemPosition = this.currentItemNumber;
      this.navigation.emit(this.currentItemPosition - 1);
    } else {
      this.currentItemNumber = this.currentItemPosition;
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes) {
      if (
        changes.currentItemPosition &&
        changes.currentItemPosition.currentValue
      ) {
        this.currentItemPosition = changes.currentItemPosition.currentValue;
        this.currentItemNumber = this.currentItemPosition;
      }
    }
  }

  getPercentage(total, current) {
    return current ? (current * 100) / total : 0;
  }
}
