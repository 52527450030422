import { Injectable, OnDestroy } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import * as fileSaver from 'file-saver';
import { cloneDeep, flatMap, uniq } from 'lodash';
import {
  BehaviorSubject,
  forkJoin,
  fromEvent,
  Observable,
  of,
  throwError,
} from 'rxjs';
import { catchError, filter, first, switchMap } from 'rxjs/operators';
import {
  AnswerModel,
  CourseModel,
  QuestionModel,
  QuizModel,
  Exam,
} from 'src/app/api/models';
import * as XMLJSONConverter from 'xml-js';
import { DialogService } from '../common/dialog.service';
import { EditService } from '../edit/edit.service';
import {
  EmbedDialogComponent,
  EmbedDialogData,
} from './embed-dialog.component';
import {
  ImportResultBottomSheetComponent,
  ImportResultBottomSheetData,
} from './import-result-bottom-sheet.component';
import { Panel, PanelEntity, PanelEntityType, SortAction } from './panel.types';
import { QuestionType } from 'src/app/app.types';
import { ImportSnackBarComponent } from './import-snack-bar.component';
import { TranslateService } from '@ngx-translate/core';
import { ErrorImportCourseJsonDialogComponent } from './error-import-course-json-dialog.component';
import { CSVExportService } from '../common/csv-export.service';
import * as _ from 'lodash';
import { AuthService } from 'src/app/auth/auth.service';
import { ListDataSource } from '../list/list-data-source';
import { XLSXReader } from '../common/import-helpers/xlsxReader';
import { QuestionColumnNames, QuestionTopRow } from 'src/app/utility/app.utils';
import { KahootImporter } from '../common/import-helpers/kahootImporter';
import * as _sanitizeHTML from 'sanitize-html';
import { environment } from 'src/environments/environment';
import {
  ChatGPTPanelType,
  ChatgptImportDialogComponent,
  ChatgptImportDialogComponentData,
} from '../common/dialogs/chatgpt-import-dialog/chatgpt-import-dialog.component';
const sanitizeHTML = (_sanitizeHTML as any).default || _sanitizeHTML;

@Injectable()
export class PanelService implements OnDestroy {
  importFailedItems: Array<{ item: any; type: PanelEntityType; error: any }>;
  importProgress = { current: 0, total: 0 };
  initialized$ = new BehaviorSubject<boolean>(false);
  importComplete$ = new BehaviorSubject<boolean>(false);
  importChatGPT$ = new BehaviorSubject<boolean>(false);
  panels: Panel[] = [];
  params: { [name: string]: string };
  userId: string;
  isBook = false;

  constructor(
    private dialog: DialogService,
    private editService: EditService,
    private matBottomSheet: MatBottomSheet,
    private matDialog: MatDialog,
    private matSnackBar: MatSnackBar,
    private router: Router,
    auth: AuthService,
    private translate: TranslateService,
    private activatedRoute: ActivatedRoute,
    private translator: TranslateService,
    private csvExport: CSVExportService
  ) {
    this.userId = auth.currentUserInfo$.value.sub;
  }

  ngOnDestroy() {
    this.panels.forEach((panel) => {
      panel.onDestroy();
    });
  }

  async addParam(type: PanelEntityType, id: number, queryParams = {}) {
    const previousId = +this.activatedRoute.snapshot.params[type];
    if (previousId) {
      await this.removeParam(type);
    }
    this.params[type] = `${id}`;
    const params = cloneDeep(this.params);
    this.isBook = Boolean(this.params.book);
    const idNum = this.params.id
      ? this.params.id
      : this.params.course ?? this.params.book;
    if (params.id) {
      delete params.id;
    }
    delete params.id;
    await this.router.navigate(
      [
        this.isBook ? `/cms/books/edit/${idNum}` : `/cms/courses/edit/${idNum}`,
        params,
      ],
      { queryParams }
    );
    const panel = this.getPanel(type);
    if (panel?.childTypes) {
      this.checkActiveCourse(panel);
      panel?.childTypes.forEach((t) => this.fetchItems(this.getPanel(t)));
    } else {
      let item: PanelEntity;
      item = panel.list.find((item) => item.id === id);
      if (!item && panel.filteredList?.length) {
        item = panel.filteredList.find((item) => item.id === id);
      }
      this.editItem(panel, item);
    }
  }

  sortPanelListItems(panel: Panel) {
    const sort = panel.selectedSortAction.sort;
    panel.list = _.orderBy(
      panel.list,
      sort.option,
      sort.direction === 'ASC' ? 'desc' : 'asc'
    );
    panel.selectedSortAction.sort.direction =
      sort.direction === 'ASC' ? 'DESC' : 'ASC';
    this.setSortOption(panel, panel.selectedSortAction);
  }

  checkActiveCourse(panel: Panel) {
    if (panel.type === PanelEntityType.Course) {
      const requests = panel?.childTypes
        .filter((type) => type !== PanelEntityType.Exam)
        .map((childEntity) => this.fetchItems(this.getPanel(childEntity)));
      forkJoin(requests).subscribe((results) => {
        const activeEntities = results.map((entities) => {
          return entities.filter((entity: any) => {
            return entity.active === true;
          });
        });
        let totalItems = 0;
        activeEntities.forEach((result) => (totalItems += result.length));
        if (totalItems < 1) {
          this.matSnackBar.open(panel.translations['emptyCourse']);
        }
      });
    }
  }

  changeItemStatus(panel: Panel, id: number, active: boolean) {
    const parentId = this.getParentItemId(panel.type);
    panel
      .changeItemStatus(id, parentId, active)
      .pipe(switchMap(() => this.fetchItems(panel)))
      .subscribe(() => {
        const message =
          panel.translations[
            active ? 'changeItemStatusEnabled' : 'changeItemStatusDisabled'
          ];
        this.matSnackBar.open(message);
      });
  }

  private countItemsRecursively(panel: Panel, item: PanelEntity): number {
    if (!panel?.childTypes || !panel?.childTypes.length) {
      return 1;
    }
    return panel?.childTypes.reduce((total, childType) => {
      const childPanel = this.getPanel(childType);
      if (!childPanel) {
        return total;
      }
      const childItems = item[childPanel.parentKey];
      if (!childItems || !childItems.length) {
        return total;
      }
      return (
        total +
        childItems.reduce((total2: number, childItem: PanelEntity) => {
          return (total2 += this.countItemsRecursively(childPanel, childItem));
        }, 0)
      );
    }, 1);
  }

  deleteItem(panel: Panel, id: number) {
    this.dialog
      .confirmDelete(
        panel.translations.deleteItemTitle,
        panel.translations.deleteItemText
      )
      .pipe(
        filter((isConfirmed) => isConfirmed),
        switchMap(() => {
          return panel.deleteItem(id).pipe(
            // tap(() => {throw new Error('error')}),
            catchError((error) => {
              this.matSnackBar.open(panel.translations.deleteItemError);
              return throwError(error);
            })
          );
        }),
        switchMap(() => this.fetchItems(panel))
      )
      .subscribe(() => {
        this.matSnackBar.open(
          this.getPanel(panel.type).translations.deleteItemSuccess
        );
        this.removeParam(panel.type);
      });
  }

  async editItem(panel: Panel, item?: PanelEntity) {
    if (!panel) {
      return;
    }
    if (panel?.editConfigService) {
      let params = {};
      if (panel.editConfigServiceParams) {
        params = panel.editConfigServiceParams(
          item,
          this.getParentItemId(panel.type)
        );
      }
      await this.editService.open(panel.editConfigService, item, params);
      this.removeParam(panel.type);
      this.fetchItems(panel);
      return;
    }
    const parentId = this.getParentItemId(panel.type);
    const learnUnitId = this.activatedRoute.snapshot.params.id;
    if (!panel?.editDialogComponent) {
      let queryParams = {};
      if (learnUnitId) {
        if (this.isBook) {
          queryParams = { book: learnUnitId };
        } else {
          queryParams = { course: learnUnitId };
        }
      }
      if (
        parentId &&
        [PanelEntityType.Question, PanelEntityType.FlashCard].includes(
          panel.type
        )
      ) {
        const type = Object.keys(this.activatedRoute.snapshot.params)[0];
        queryParams = { [type]: parentId };
      }
      for (let idx = 1; idx <= 2; idx++) {
        if (
          this.router.url.split(';').length &&
          this.router.url.split(';')[idx]
        ) {
          const val = this.router.url.split(';')[idx].split('?')[0];
          queryParams[val.split('=')[0]] = val.split('=')[1];
        }
      }
      if (item) {
        if (panel.type === PanelEntityType.FlashCard && !item['accepted']) {
          const maxPositionItem = panel.list.reduce((prev, current) =>
            prev['position'] > current['position'] ? prev : current
          );
          queryParams['total-accepted-flashCards'] =
            maxPositionItem['position'];
        }
        this.router.navigate([`/cms/edit/${panel.type}`, item.id], {
          queryParams,
        });
      } else {
        this.router.navigate([`/cms/add/${panel.type}`], { queryParams });
      }
    } else {
      this.matDialog
        .open(panel.editDialogComponent, {
          data: panel.editDialogInput(item, parentId),
          panelClass: panel.editDialogClass,
          autoFocus: false,
        })
        .afterClosed()
        .subscribe(() => {
          this.removeParam(panel.type);
          this.fetchItems(panel);
        });
    }
  }

  embedItem(panel: Panel, item: PanelEntity) {
    const data: Partial<EmbedDialogData> = {};
    if (panel.type === PanelEntityType.Course) {
      data.coursePin = (item as CourseModel).pin_code;
    } else {
      const selectedCourseId =
        +this.activatedRoute.snapshot.params[PanelEntityType.Course] ||
        +this.activatedRoute.snapshot.params[PanelEntityType.Book];
      const courses = this.getPanel(PanelEntityType.Course).list;
      const selectedCourse: CourseModel = courses.find(
        (c) => c.id === selectedCourseId
      ) as CourseModel;
      data.coursePin = selectedCourse.pin_code;
      data.entity = Object.keys(this.activatedRoute.snapshot.params)[0];
      if (panel.type === PanelEntityType.Quiz) {
        data.quizId = item.id;
      } else if (panel.type === PanelEntityType.Exam) {
        data.examPin = (item as Exam).pin_code;
      } else {
        data.cardStackId = item.id;
      }
    }
    this.matDialog.open<unknown, EmbedDialogData>(EmbedDialogComponent, {
      data: data as EmbedDialogData,
    });
  }

  async exportItemAsJSON(panel: Panel, id: number) {
    let fullItem: any;
    if (panel && panel.type === PanelEntityType.Course) {
      fullItem = await panel.exportItems(id).toPromise();
      fullItem = fullItem[0];
      fullItem.quizzes.forEach((quiz) => {
        delete quiz.id;
        quiz.questions.forEach((question) => {
          delete question.id;
        });
      });
      fullItem.card_stacks.forEach((stack) => {
        delete stack.id;
        stack.cards.forEach((card) => {
          delete card.id;
        });
      });
      fullItem.exams.forEach((exam) => {
        delete exam.id;
        exam.questions.forEach((question) => {
          delete question.id;
        });
      });
      fullItem.events.forEach((event) => {
        delete event.id;
        event.questions.forEach((question) => {
          delete question.id;
        });
      });
    } else {
      fullItem = await panel.fetchItem(id).toPromise();
    }
    delete fullItem.id;
    fullItem = {
      ...fullItem,
      ...(await this.exportItemRecursively(panel, id)),
    };
    fullItem['meta:importType'] = panel.type;
    const file = `${JSON.stringify(fullItem)}\n`;
    const blob = new Blob([file], { type: 'application/json' });
    fileSaver.saveAs(
      blob,
      `qa-${panel.type}-${fullItem[panel.itemTextProperty]}.json`
    );
  }

  async exportItemAsCSV(panel: Panel, id: number) {
    let fullItem: any = await panel.fetchItem(id).toPromise();
    delete fullItem.id;
    fullItem = {
      ...fullItem,
      ...(await this.exportItemRecursively(panel, id)),
    };
    let csvArray;
    const tooManyAnswers = [];
    if (
      panel.type === PanelEntityType.Quiz ||
      panel.type === PanelEntityType.Exam
    ) {
      if (!fullItem.questions.length) {
        this.matSnackBar.open(
          this.translator.instant('content.question.noQuestions')
        );
        return;
      }
      for (const question of fullItem.questions) {
        if (question.answers.length) {
          let idx = 1;
          for (const answer of question.answers) {
            question['Answer' + idx] = answer.text;
            question['answerResult' + idx] = answer.is_right
              ? 'correct'
              : 'incorrect';
            idx++;
          }
          if (idx > 7) {
            tooManyAnswers.push(question);
          }
        } else {
          question['answerResult1'] = question.is_right
            ? 'correct'
            : 'incorrect';
        }
      }
      const questions = fullItem.questions.map((question) => {
        return {
          Text: question.text,
          Type: this.getQuestionTypeText(question.type),
          Answer1: question.Answer1 ? question.Answer1 : '',
          answerResult1: question.answerResult1 ? question.answerResult1 : '',
          Answer2: question.Answer2 ? question.Answer2 : '',
          answerResult2: question.answerResult2 ? question.answerResult2 : '',
          Answer3: question.Answer3 ? question.Answer3 : '',
          answerResult3: question.answerResult3 ? question.answerResult3 : '',
          Answer4: question.Answer4 ? question.Answer4 : '',
          answerResult4: question.answerResult4 ? question.answerResult4 : '',
          Answer5: question.Answer5 ? question.Answer5 : '',
          answerResult5: question.answerResult5 ? question.answerResult5 : '',
          Answer6: question.Answer6 ? question.Answer6 : '',
          answerResult6: question.answerResult6 ? question.answerResult6 : '',
          Explanation: question.explanation ? question.explanation : '',
          Weblink: question.weblink ? question.weblink : '',
          Source: question.source ? question.source : '',
          media_id: question.media ? question.media.id : '',
          media_url: question.media
            ? `https://s3.${environment.s3.mediaBucket.region}.amazonaws.com/${environment.s3.mediaBucket.name}/` +
              question.media.uuid +
              '.' +
              question.media.file_ext
            : '',
          fileExtension: question.media ? question.media.file_ext : '',
          uuid: question.media ? question.media.uuid : '',
          Tags: question.tags
            ? question.tags.map((tag) => tag.name).join(' : ')
            : [],
        };
      });
      csvArray = this.csvExport.getCsvString(questions);
    } else {
      if (!fullItem.cards.length) {
        this.matSnackBar.open(
          this.translator.instant('content.flashCard.noFlashcards')
        );
        return;
      }
      const flashCards = fullItem.cards.map((card) => {
        return {
          Text: card.text,
          Answer: card.answer,
          Explanation: card.explanation,
          Weblink: card.weblink,
          Source: card.source,
          media_id: card.media ? card.media.id : '',
          media_url: card.media
            ? `https://s3.${environment.s3.mediaBucket.region}.amazonaws.com/${environment.s3.mediaBucket.name}/` +
              card.media.uuid +
              '.' +
              card.media.file_ext
            : '',
          fileExtension: card.media ? card.media.file_ext : '',
          uuid: card.media ? card.media.uuid : '',
          Tags: card.tags ? card.tags.map((tag) => tag.name).join(' : ') : [],
        };
      });
      csvArray = this.csvExport.getCsvString(flashCards);
    }
    if (tooManyAnswers && tooManyAnswers.length > 0) {
      this.dialog
        .confirm({
          title: this.translator.instant(
            'content.question.csvExportHint.title'
          ),
          text:
            this.translator.instant('content.question.csvExportHint.text1') +
            tooManyAnswers
              .reduce((acc, q) => acc + '\n•\t' + q.text, '')
              .slice(1) +
            this.translator.instant('content.question.csvExportHint.text2'),
          buttons: [
            {
              text: this.translator.instant(
                'content.question.csvExportHint.option1'
              ),
              value: false,
            },
            {
              text: this.translator.instant(
                'content.question.csvExportHint.option2'
              ),
              value: true,
            },
          ],
        })
        .subscribe((value) => {
          if (!value) {
            return;
          }
          const blob = new Blob(['\uFEFF' + csvArray], {
            type: 'text/csv;charset=utf-8',
          });
          fileSaver.saveAs(blob, `${fullItem[panel.itemTextProperty]}.csv`);
        });
    } else {
      const blob = new Blob(['\uFEFF' + csvArray], {
        type: 'text/csv;charset=utf-8',
      });
      fileSaver.saveAs(blob, `${fullItem[panel.itemTextProperty]}.csv`);
    }
  }

  private getQuestionTypeText(questionType: QuestionType) {
    switch (questionType) {
      case QuestionType.MULTIPLE_CHOICE:
        return 'Multiple Choice';
      case QuestionType.SINGLE_CHOICE:
        return 'Single Choice';
      case QuestionType.TRUE_FALSE:
        return 'True/False';
      case QuestionType.FREETEXT:
        return 'Free Text';
      default:
        return '';
    }
  }

  private async exportItemRecursively(
    panel: Panel,
    id: number
  ): Promise<{ key?: PanelEntity[] }> {
    const partial = {};
    if (!panel?.childTypes || panel.type === PanelEntityType.Course) {
      return partial;
    }
    const setChildren = panel?.childTypes.map(async (childType) => {
      const childPanel = this.getPanel(childType);
      const childItems = await childPanel.fetchItems(id, true).toPromise();
      const resolveChildItems = childItems.map(async (item) => {
        const childItemId = item.id;
        delete item.id;
        return {
          ...item,
          ...(await this.exportItemRecursively(childPanel, childItemId)),
        };
      });
      const fullChildItems = await Promise.all(resolveChildItems);
      partial[childPanel.parentKey] = fullChildItems;
    });
    await Promise.all(setChildren);
    return partial;
  }

  fetchItems<T>(panel: Panel, activeEntityId?: number): Observable<T[]> {
    if (panel) {
      if (
        !this.getParentItemId(panel.type) &&
        !this.params?.[PanelEntityType.Course] &&
        !this.params?.[PanelEntityType.Book]
      ) {
        return of();
      }
      const $ = panel.fetchItems(this.getParentItemId(panel.type));
      $.subscribe((entities: PanelEntity[]) => {
        if (panel.isFilteredListVisible) {
          panel.list = entities.filter((x) => panel.filterTopListBy(x));
          panel.filteredList = entities.filter((x) =>
            panel.filterBottomListBy(x)
          );
        } else {
          panel.list = entities;
        }
        if (
          panel.type !== PanelEntityType.Exam &&
          panel.type !== PanelEntityType.ExamQuestion &&
          panel.type !== PanelEntityType.Course
        ) {
          if (panel?.list?.length) {
            panel.list.forEach((e) => {
              e['isImported'] = e.created_by !== this.userId ? true : false;
              if (!e['isImported']) {
                panel.doNotshowAdd = false;
              }
            });
          }
        }
        if (
          panel.parentType === PanelEntityType.Quiz ||
          panel.parentType === PanelEntityType.Exam ||
          panel.parentType === PanelEntityType.FlashCardStack
        ) {
          if (panel?.list?.length) panel.doNotshowAdd = true;
          if (panel?.list?.length) {
            panel.list.forEach((e) => {
              if (!e['isImported']) {
                panel.doNotshowAdd = false;
              }
            });
          }
        }
        this.sortItemsFromSession(panel);
        if (activeEntityId) {
          const activeEntity = panel.list.find(
            (entity) => entity.id === activeEntityId
          );
          if (!activeEntity) {
            throw new Error(
              `Couldn\'t find item with passed currentItemId ${activeEntityId}`
            );
          }
          this.addParam(panel.type, activeEntityId);
        }
      });
      return $ as unknown as Observable<T[]>;
    } else {
      return of();
    }
  }

  sortItemsFromSession(panel: Panel) {
    let sortAction = sessionStorage.getItem('sort-panel');
    if (sortAction) {
      sortAction = JSON.parse(sortAction);
      if (sortAction[panel.type]) {
        panel.selectedSortAction = sortAction[panel.type];
        this.sortPanelItems(panel);
      }
    }
  }

  sortPanelItems(panel: Panel) {
    panel.list = _.orderBy(
      panel.list,
      panel.selectedSortAction.sort.option,
      panel.selectedSortAction.sort.direction === 'ASC' ? 'asc' : 'desc'
    );
  }

  setSortOption(panel: Panel, selectedSortAction: SortAction) {
    const sortPanel = sessionStorage.getItem('sort-panel');
    if (sortPanel) {
      const sortOption = JSON.parse(sortPanel);
      sortOption[panel.type] = selectedSortAction;
      sessionStorage.setItem('sort-panel', JSON.stringify(sortOption));
    } else {
      const sortOption = {
        [panel.type]: selectedSortAction,
      };
      sessionStorage.setItem('sort-panel', JSON.stringify(sortOption));
    }
  }

  getPanel(type: PanelEntityType | 'book') {
    if (type === 'book') {
      type = PanelEntityType.Course;
    }
    return this.panels.find((panel) => panel.type === type);
  }

  getParentItemId(type: PanelEntityType): number {
    const parentPanel = this.getPanel(this.getPanel(type).parentType);
    if (!parentPanel) {
      return null;
    }
    let targetType: PanelEntityType | 'book' = parentPanel.type;
    this.isBook = Boolean(this.activatedRoute.snapshot.params.book);
    if (
      this.isBook &&
      this.getPanel(type).parentType === PanelEntityType.Course
    ) {
      targetType = 'book';
    }
    if (type === PanelEntityType.EventQuestion) {
      const returnVal =
        this.activatedRoute.snapshot.firstChild?.firstChild?.paramMap.get(
          'liveEventId'
        );
      if (returnVal) {
        return +returnVal;
      }
    } else if (type === PanelEntityType.ExamQuestion) {
      const returnVal =
        this.activatedRoute.snapshot.firstChild?.firstChild?.paramMap.get(
          'examId'
        );
      if (returnVal) {
        return +returnVal;
      }
    }
    return +this.activatedRoute.snapshot.params[targetType];
  }

  importItems(panel: Panel) {
    this.params = { ...this.activatedRoute.snapshot.params };
    const query = { ...this.activatedRoute.snapshot.queryParams };
    const panelType = cloneDeep(panel.type);
    const parentId = this.getParentItemId(panelType) ?? null;
    const queryParams = {
      parentId,
      type: panelType,
    };
    if (this.params && Object.keys(this.params).length) {
      if (this.params.book) {
        if (this.router.url.indexOf('book') !== -1) {
          queryParams['bookId'] = this.params.book;
        }
      } else {
        if (this.router.url.indexOf('course') !== -1) {
          queryParams['courseId'] = this.params.id
            ? this.params.id
            : this.params.course;
        }
      }
    } else if (query?.courseId) {
      queryParams['courseId'] = query.courseId;
    }
    this.matDialog.closeAll();
    this.router.navigate(['/cms/import-items'], { queryParams });
  }

  async importItemAsJSON(
    panel: Panel,
    item?: PanelEntity,
    meta?: ListDataSource
  ) {
    item = item || ((await this.promptFile('json')) as PanelEntity);
    if (item['available_for_coursemarket']) {
      this.dialog
        .confirm({
          title: this.translator.instant('csvImports.course.title'),
          text: this.translator.instant('csvImports.course.text'),
          buttons: [
            {
              text: this.translator.instant('csvImports.course.button1'),
              value: false,
            },
            {
              text: this.translator.instant('csvImports.course.button2'),
              color: 'primary',
              value: true,
            },
            {
              text: this.translator.instant('csvImports.course.button3'),
              color: 'primary',
              value: null,
            },
          ],
        })
        .subscribe((value) => {
          if (value === true) {
            this.continueImportFromJson(panel, item, meta);
          } else if (value === null) {
            this.matDialog.closeAll();
            this.router.navigate([`/cms/course-market`], {
              queryParams: { searchString: item['name'] },
            });
          }
        });
      return;
    }
    this.continueImportFromJson(panel, item, meta);
  }

  continueImportFromJson(
    panel: Panel,
    item?: PanelEntity,
    meta?: ListDataSource
  ) {
    const isQuestion =
      item['meta:importType'] === 'question' &&
      (panel.type === PanelEntityType.EventQuestion ||
        panel.type === PanelEntityType.ExamQuestion);
    const type = isQuestion ? 'question' : panel.type;
    if (item['meta:importType'] && item['meta:importType'] !== type) {
      this.dialog.error(
        this.translator.instant('importResult.error'),
        this.translator
          .instant('importResult.cantImportText')
          .replace('%paneltype', panel.type)
      );
      return null;
    }
    if (panel.type === PanelEntityType.Course) {
      const jsonErrors = this.checkForValidJSON(item as CourseModel);
      if (jsonErrors) {
        const data = jsonErrors;
        this.matDialog
          .open(ErrorImportCourseJsonDialogComponent, {
            data,
            autoFocus: false,
            panelClass: panel.editDialogClass,
          })
          .afterClosed();
        return false;
      } else {
        this.matDialog.closeAll();
        this.continueImport(panel, item, meta);
      }
    } else {
      this.matDialog.closeAll();
      this.continueImport(panel, item, meta);
    }
    return null;
  }

  checkForValidJSON(course: CourseModel) {
    let errors;
    let quizErrors = [];
    let examErrors = [];
    const cardErrors = [];
    if (course && course.card_stacks && _.isArray(course.card_stacks)) {
      course.card_stacks.forEach((flashCard, cIdx) => {
        let idx = 0;
        const errorMsg = [];
        for (const card of flashCard.cards) {
          if (!card.text) {
            errorMsg.push({
              index: idx,
              message: this.translate.instant(
                'csvImports.flashCard.errorMessages.error1'
              ),
            });
          } else if (!card.answer) {
            errorMsg.push({
              index: idx,
              message: this.translate.instant(
                'csvImports.flashCard.errorMessages.error2'
              ),
            });
          }
          idx++;
        }
        if (errorMsg.length) {
          cardErrors.push({
            card: cIdx,
            flashCards: errorMsg,
          });
        }
      });
    }
    quizErrors = this.questionsValidation(course.quizzes, PanelEntityType.Quiz);
    examErrors = this.questionsValidation(course.exams, PanelEntityType.Exam);
    if (quizErrors.length || cardErrors.length || examErrors.length) {
      errors = {
        quizzes: quizErrors || [],
        exams: examErrors || [],
        card_stacks: cardErrors || [],
      };
    }
    return errors;
  }

  questionsValidation(arr, type: PanelEntityType) {
    const entityErrors = [];
    if (arr && _.isArray(arr)) {
      arr.forEach((quiz, qIdx) => {
        let idx = 0;
        const errorMsg = [];
        for (const question of quiz.questions) {
          if (!question.text) {
            break;
          }
          if (question['type'] === 1) {
            if (question['is_right'] === undefined) {
              errorMsg.push({
                index: idx,
                message: this.translate.instant(
                  'csvImports.question.errorMessages.trueFalse.error2'
                ),
              });
            }
          } else if (question['type'] === 0) {
            if (
              !question.answers.length ||
              question.answers.filter((x) => x.text).length < 2
            ) {
              errorMsg.push({
                index: idx,
                message: this.translate.instant(
                  'csvImports.question.errorMessages.multipleChoice.error1'
                ),
              });
            } else {
              for (const answer of question.answers) {
                if (answer.text && answer.is_right === undefined) {
                  errorMsg.push({
                    index: idx,
                    message: this.translate.instant(
                      'csvImports.question.errorMessages.multipleChoice.error2'
                    ),
                  });
                  break;
                }
              }
            }
          } else if (question['type'] === 2) {
            if (
              !question.answers.length ||
              question.answers.filter((x) => x.text).length < 2
            ) {
              errorMsg.push({
                index: idx,
                message: this.translate.instant(
                  'csvImports.question.errorMessages.singleChoice.error1'
                ),
              });
            } else {
              const singleArr = question.answers.filter(
                (x) => x.text && x.is_right
              );
              if (!singleArr.length) {
                errorMsg.push({
                  index: idx,
                  message: this.translate.instant(
                    'csvImports.question.errorMessages.singleChoice.error2'
                  ),
                });
              } else if (singleArr.length > 1) {
                errorMsg.push({
                  index: idx,
                  message: this.translate.instant(
                    'csvImports.question.errorMessages.singleChoice.error3'
                  ),
                });
              } else {
                for (const answer of question.answers) {
                  if (answer.text && answer.is_right === undefined) {
                    errorMsg.push({
                      index: idx,
                      message: this.translate.instant(
                        'csvImports.question.errorMessages.singleChoice.error4'
                      ),
                    });
                    break;
                  }
                }
              }
            }
          } else if (question.type === 3) {
            if (!question.max_score) {
              errorMsg.push({
                index: idx,
                message: this.translate.instant(
                  'csvImports.question.errorMessages.freeText.error1'
                ),
              });
            }
          } else {
            errorMsg.push({
              index: idx,
              message: this.translate.instant(
                'csvImports.question.errorMessages.questionTypeError'
              ),
            });
          }
          idx++;
        }
        if (errorMsg.length) {
          if (type === PanelEntityType.Quiz) {
            entityErrors.push({
              quiz: qIdx,
              questions: errorMsg,
            });
          } else {
            entityErrors.push({
              exam: qIdx,
              questions: errorMsg,
            });
          }
        }
      });
    }
    return entityErrors;
  }

  async continueImport(
    panel: Panel,
    item?: PanelEntity,
    meta?: ListDataSource
  ) {
    this.importFailedItems = [];
    this.importProgress.current = 0;
    this.importProgress.total = this.countItemsRecursively(panel, item);
    const snackBarRef = this.matSnackBar.openFromComponent(
      ImportSnackBarComponent,
      {
        data: { progress: this.importProgress },
        duration: null,
      }
    );
    await this.importItemRecursively(
      panel,
      item,
      this.getParentItemId(panel.type)
    );
    snackBarRef.dismiss();
    const bottomSheetData: ImportResultBottomSheetData = {
      info: {
        current: this.importProgress.current,
        total: this.importProgress.total,
        failedItems: this.importFailedItems,
      },
    };
    this.matBottomSheet.open(ImportResultBottomSheetComponent, {
      data: bottomSheetData,
    });
    if (meta) {
      meta.refresh();
    } else {
      this.fetchItems(panel);
    }
  }

  async importItemAsCSV(panel: Panel, isChatGPT?: boolean) {
    let id = null;
    if (this.router.url.split(';').length && this.router.url.split(';')[1]) {
      id = this.router.url.split(';')[1].split('=')[1];
    }
    const queryParams = this.activatedRoute.snapshot.params;
    if (!id) {
      if (queryParams?.course) {
        id = queryParams.course;
      }
      if (queryParams?.book) {
        id = queryParams.book;
      }
    }
    const parentId = this.getParentItemId(panel.type);
    const segment = panel.type === 'flash-card' ? panel.type : 'question';
    let params;
    if (this.router.url.indexOf('book') !== -1) {
      params = { book: id, type: panel.type };
    } else {
      params = { course: id, type: panel.type };
    }
    if (isChatGPT) {
      params.isChatGPT = true;
    }
    this.matDialog.closeAll();
    if (parentId) {
      this.router.navigate([`/cms/csv-imports/${segment}/`, parentId], {
        queryParams: params,
      });
    } else {
      this.router.navigate([`/cms/csv-imports/${segment}`], {
        queryParams: { type: panel.type },
      });
    }
  }

  async importFromChatGPT(
    panel: Panel,
    callback?: () => void,
    typeOverride: ChatGPTPanelType | null = null
  ) {
    if (
      !panel ||
      (panel.parentType !== PanelEntityType.Quiz &&
        panel.parentType !== PanelEntityType.Exam &&
        panel.parentType !== PanelEntityType.Event &&
        panel.parentType !== PanelEntityType.FlashCardStack)
    ) {
      const dialogData: ChatgptImportDialogComponentData = {
        name: null,
        type: typeOverride ?? null,
        onImport: () => {
          if (callback) {
            callback();
            this.matDialog.closeAll();
          }
        },
      };
      this.openChatGprDialog(dialogData);
      return;
    }
    const parentItemId = +this.activatedRoute.snapshot.paramMap.get(
      panel.parentType
    );
    const courseId = +this.activatedRoute.snapshot.paramMap.get('course');
    const courseName = (
      this.panels
        ?.find((p) => p.type == 'course')
        ?.list?.find((q) => q.id === courseId) as any
    )?.name;
    const parentName = (
      this.panels
        ?.find((p) => p.type == panel.parentType)
        ?.list?.find((q) => q.id === parentItemId) as any
    )?.name;
    const dialogData: ChatgptImportDialogComponentData = {
      name: courseName
        ? parentName
          ? `${courseName} - ${parentName}`
          : courseName
        : parentName
        ? parentName
        : undefined,
      type: panel.parentType,
      onImport: () => {
        if (callback) {
          callback();
          this.matDialog.closeAll();
        } else {
          this.importItemAsCSV(panel, false);
        }
      },
    };
    this.openChatGprDialog(dialogData);
  }

  openChatGprDialog(dialogData: ChatgptImportDialogComponentData) {
    this.matDialog.open<
      ChatgptImportDialogComponent,
      ChatgptImportDialogComponentData
    >(ChatgptImportDialogComponent, {
      data: dialogData,
      width: '600px',
    });
  }

  redirectToImportItems(url: string, queryParams: object, closeModal: boolean) {
    this.router.navigate([url], { queryParams }).then(() => {
      if (closeModal) {
        this.matDialog.closeAll();
      }
    });
  }

  async getJSONFromXML() {
    try {
      const XML = (await this.promptFile('xml')) as string;
      const xmlAsJSON = XMLJSONConverter.xml2js(XML);
      const quiz: Partial<QuizModel> = {
        name: this.translator.instant('content.message.quizFromMoodle'),
      };
      type El = XMLJSONConverter.Element;
      const courseTextElement = xmlAsJSON.elements[0].elements
        .find((el: El) => el.attributes && el.attributes.type === 'category')
        .elements.find((el: El) => el.name === 'category').elements[0]
        .elements[0];
      const courseName = courseTextElement.text || courseTextElement.cdata;
      quiz.name = courseName.split('/').slice(-1)[0];
      const quizDescriptionHTML: string = xmlAsJSON.elements[0].elements
        .find((el: El) => el.attributes && el.attributes.type === 'category')
        .elements.find((el: El) => el.name === 'info').elements[0]
        .elements[0].cdata;
      quiz.description = this.stripHTMLTags(quizDescriptionHTML);
      quiz.questions = xmlAsJSON.elements[0].elements
        .filter(
          (el: El) => el.attributes && el.attributes.type === 'multichoice'
        )
        .map((el: El) => {
          const question: Partial<QuestionModel> = {};
          const single =
            el.elements.find((qel) => qel.name === 'single').elements[0]
              .text === 'true';
          const answerEls = el.elements.filter((qel) => qel.name === 'answer');
          question.type = !single ? 0 : 2;
          question.answers = answerEls.map((answer) => {
            const answerTextHTML = answer.elements.find(
              (ael: El) => ael.name === 'text'
            ).elements[0].cdata;
            return {
              is_right: +answer.attributes.fraction > 0,
              text: this.stripHTMLTags(answerTextHTML),
            } as AnswerModel;
          });
          const questionTextHTML = el.elements.find(
            (qel) => qel.name === 'questiontext'
          ).elements[0].elements[0].cdata;
          question.text = this.stripHTMLTags(questionTextHTML);
          return question;
        });
      return quiz;
    } catch (error) {
      this.matSnackBar.open(this.translate.instant('quiz.message.invalidXml'));
      return null;
    }
  }

  async importItemAsMoodleXML(panel: Panel) {
    if (panel.type !== PanelEntityType.Quiz) {
      throw new Error('Moodle XML import is only available for quizzes.');
    }
    const quiz = await this.getJSONFromXML();
    this.importItemAsJSON(panel, quiz);
  }

  protected async importItemRecursively(
    panel: Panel,
    item: PanelEntity,
    parentId?: number
  ) {
    const thisItem = cloneDeep(item);
    if (panel?.childTypes) {
      panel?.childTypes.forEach((childType) => {
        const childPanel = this.getPanel(childType);
        if (childPanel) {
          delete thisItem[childPanel.parentKey];
        }
      });
    }
    try {
      const responseItems = await panel
        .createItemsFn([thisItem], parentId)
        .toPromise();
      this.importProgress.current += 1;
      if (panel?.childTypes) {
        const createdItem = responseItems.slice(-1)[0];
        for (const childType of panel?.childTypes) {
          const childPanel = this.getPanel(childType);
          const childItems: PanelEntity[] = item[childPanel.parentKey];
          if (!childItems) return;
          for (const childItem of childItems) {
            await this.importItemRecursively(
              childPanel,
              childItem,
              createdItem.id
            );
          }
        }
      }
    } catch (error) {
      this.importFailedItems.push({
        error,
        item,
        type: panel.type,
      });
    }
  }

  init(panels: Panel[], activatedRoute?: ActivatedRoute) {
    this.panels = panels;
    if (activatedRoute) {
      this.activatedRoute = activatedRoute;
      this.initParams();
      this.fetchItems(this.panels[0]);
    }
  }

  async initParams() {
    this.params = { ...this.activatedRoute.snapshot.params };
    const panelTypesToFetch: PanelEntityType[] = [];
    Object.keys(this.params).forEach((type: PanelEntityType | 'book') => {
      if (type === 'book') type = PanelEntityType.Course;
      const panel = this.getPanel(type);
      if (!panel?.childTypes || !panel?.childTypes.length) {
        return;
      }
      panelTypesToFetch.push(type, ...panel?.childTypes);
    });
    await Promise.all(
      uniq(panelTypesToFetch).map((type) => {
        return this.fetchItems(this.getPanel(type)).toPromise();
      })
    );
    Object.keys(this.params).forEach((type: PanelEntityType) => {
      const panel = this.getPanel(type);
      if (!panel?.childTypes) {
        const item = panel?.list.find((item) => item.id === +this.params[type]);
        this.editItem(panel, item);
      } else {
        panel.open$.next();
      }
    });
    this.initialized$.next(true);
  }

  public async promptFile(extension: string): Promise<unknown> {
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = `.${extension}`;
    const changeEvent = fromEvent(input, 'change').pipe(first());
    input.click();
    await changeEvent.toPromise();
    const reader = new FileReader();
    const readerResult = fromEvent(reader, 'load').pipe(first());
    reader.readAsText(input.files[0]);
    await readerResult.toPromise();
    if (
      extension === 'json' &&
      reader &&
      reader.result &&
      (reader.result[0] === '{' || reader.result[0] === '[')
    ) {
      return JSON.parse(reader.result as string);
    }
    return reader.result as string;
  }

  public async selectExcelFileAsBinary(): Promise<unknown> {
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = `xlsx`;
    const changeEvent = fromEvent(input, 'change').pipe(first());
    input.click();
    await changeEvent.toPromise();
    const reader = new FileReader();
    const readerResult = fromEvent(reader, 'load').pipe(first());
    reader.readAsArrayBuffer(input.files[0]);
    await readerResult.toPromise();
    return reader.result as string;
  }

  async removeParam(type: PanelEntityType) {
    const typesToRemove = [];
    let currentTypes = [type];
    while (currentTypes.length) {
      typesToRemove.push(...currentTypes);
      currentTypes = flatMap(currentTypes, (t) => {
        const panel = this.getPanel(t);
        return panel?.childTypes || [];
      });
    }
    typesToRemove.forEach((t) => {
      const panel = this.getPanel(t);
      if (panel?.selectedItemIds) {
        panel.selectedItemIds.clear();
      }
      delete this.params[t];
    });
    const params = cloneDeep(this.params);
    this.isBook = Boolean(this.params.book);
    const id = this.params.id
      ? this.params.id
      : this.params.course ?? this.params.book;
    if (params.id) {
      delete params.id;
    }

    await this.router.navigate([
      this.isBook ? `/cms/books/edit/${id}` : `/cms/courses/edit/${id}`,
      params,
    ]);
  }

  private stripHTMLTags(html: string): string {
    const div = document.createElement('div');
    div.innerHTML = html;
    return div.innerText;
  }

  unlinkItems(panel: Panel, itemIds: number | number[]) {
    this.dialog
      .confirm({
        title: panel.translations.unlinkItemConfirmTitle,
        text: panel.translations.unlinkItemConfirmText,
        buttons: [
          {
            color: 'primary',
            text: this.translator.instant('common.button.abort'),
          },
          {
            color: 'warn',
            text: this.translator.instant('common.button.remove'),
            value: true,
          },
        ],
      })
      .pipe(
        filter((isConfirmed) => isConfirmed),
        switchMap(() => {
          itemIds = itemIds instanceof Array ? itemIds : [itemIds];
          const parentId = this.getParentItemId(panel.type);
          return panel.unlinkItems(itemIds, parentId).pipe(
            catchError((error) => {
              this.matSnackBar.open(panel.translations.unlinkItemError);
              return throwError(error);
            })
          );
        }),
        switchMap(() => this.fetchItems(panel))
      )
      .subscribe(() => {
        this.removeParam(panel.type);
        this.matSnackBar.open(panel.translations.unlinkItemSuccess);
      });
  }

  async duplicateEntity(panel: Panel, panelEntity: PanelEntity) {
    try {
      this.matSnackBar.open(panel.translations.duplicateEntity);
      const parentId = this.getParentItemId(panel.type);
      const entity = await panel
        .createEntity(panelEntity, parentId)
        .toPromise();
      if (entity) {
        this.matSnackBar.open(panel.translations.linkChild);
        const childData = await panel.fetchChild(panelEntity.id).toPromise();
        if (childData) {
          panel.linkChildToEntity(entity.id, childData).subscribe(() => {
            this.matSnackBar.open(panel.translations.duplicateEntitySuccess);
            this.fetchItems(panel);
          });
        }
      }
    } catch (error) {
      this.matSnackBar.open(error);
    }
  }

  importQuestionsFromKahoot(excelFile) {
    let data = this.arrayFromHTMLData(XLSXReader.excelToHTML(excelFile));
    data = this.convertKahoot(data);
    const tableData = [];
    const columnNames = QuestionColumnNames;
    for (let i = 0; i < data.length; i++) {
      if (columnNames && columnNames.length) {
        const buffer = {};
        for (const cell of columnNames) {
          if (cell === 'number') {
            buffer[cell] = i + 1;
          } else {
            buffer[cell] = '';
          }
        }
        tableData.push(buffer);
      }
    }
    const questionsInEvent = this.insertDataFromSource(data, tableData);
    return questionsInEvent || [];
  }

  arrayFromHTMLData(html: string) {
    const data = new DOMParser().parseFromString(html, 'text/html');
    const tabledata = data.querySelector('table');
    if (!tabledata) {
      return null;
    }
    const out = [];
    Array.prototype.forEach.call(tabledata.querySelectorAll('tr'), (row) => {
      const buffer = [];
      Array.prototype.forEach.call(row.children, (cell) => {
        const txt = document.createElement('textarea');
        cell.innerHTML = cell.innerHTML
          .replace(/[\n|\r\n]*/g, '')
          .replace(/\<br[\s]*\/?\>[\s]*/gi, '\n');
        txt.innerHTML = cell.innerHTML;
        buffer.push(txt.value);
      });
      out.push(buffer);
    });
    return out;
  }

  convertKahoot(csvArr) {
    if (KahootImporter.isKahoot(csvArr)) {
      csvArr = KahootImporter.importKahoot(csvArr);
    }
    return csvArr;
  }

  insertDataFromSource(source, tableData) {
    const columnNames = QuestionColumnNames;
    const topRow = QuestionTopRow;
    source = source
      ? source.filter((row) => !row.every((cell) => cell === ''))
      : [];
    const currentcell = { c: 1, r: 1 };
    for (const row of source) {
      if (
        row.slice(0, 17).join(';') === topRow.split(';').slice(0, 17).join(';')
      ) {
        continue;
      }
      currentcell.c = 1;
      for (const cell of row) {
        if (row.length === 18 && currentcell.c === 18) {
          currentcell.c = 22;
        }
        this.setTableValue(
          currentcell.r - 1,
          columnNames[currentcell.c],
          sanitizeHTML(cell, {
            allowedTags: ['br'],
            allowedAttributes: {
              '*': [],
            },
          }),
          tableData
        );
        currentcell.c += 1;
      }
      currentcell.r += 1;
    }
    return tableData;
  }

  setTableValue(rownum: number, colname: string, value: any, tableData) {
    const columnNames = QuestionColumnNames;
    const curdata = tableData;
    if (columnNames && columnNames.length && tableData.length > rownum) {
      if (columnNames.includes(colname)) {
        curdata[rownum][colname] = value;
      }
    }
    tableData = [...curdata];
  }
}
