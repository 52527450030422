import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import * as _sanitizeHTML from 'sanitize-html';

const sanitizeHTML = (_sanitizeHTML as any).default || _sanitizeHTML;

export interface InfoDialogComponentDataButton {
  text: string;
  value?: unknown;
  icon?: string;
  color?: string;
}

export interface InfoDialogComponentData {
  title: string;
  text?: string[];
  buttons?: InfoDialogComponentDataButton[];
}
@Component({
  selector: 'qa-info-dialog',
  templateUrl: './info-dialog.component.html',
  styleUrls: ['./info-dialog.component.scss'],
})
export class InfoDialogComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<InfoDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: InfoDialogComponentData,
    private translator: TranslateService,
    private domSanitizer: DomSanitizer
  ) {}

  ngOnInit(): void {
    if (this.data && !this.data.buttons) {
      this.data.buttons = [
        {
          text: this.translator.instant('common.button.abort'),
          color: 'primary',
        },
        {
          text: this.translator.instant('common.button.confirm'),
          color: 'primary',
          value: true,
        },
      ];
    }
  }

  parse(text: string) {
    const input = text.replace(
      /\[((?:https?\:\/\/)?(www\.)?(.*)\.(?:de|com|net|org)(.*))\]/gm,
      (match, group1) => {
        return `<a target="_blank" href="${group1}">${match}</a>`;
      }
    );
    const sanitized = sanitizeHTML(input, {
      allowedTags: ['a'],
      allowedAttributes: {
        '*': ['class', 'style', 'id', 'href', 'download', 'size', 'target'],
      },
    });
    return this.domSanitizer.bypassSecurityTrustHtml(sanitized);
  }
}
