import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class CSVExportService {
  constructor(private translate: TranslateService) {}

  getCsvString(csvData): string {
    let lang = this.translate.getBrowserLang();
    if (!(lang === 'de' || lang === 'en')) {
      lang = 'de';
    }
    const seperator = lang === 'de' ? ';' : ',';
    const array = [Object.keys(csvData[0])].concat(csvData);
    const values = [];
    array.forEach((element, index) => {
      values.push(index ? Object.values(element) : element);
    });
    return values
      .map((card) => {
        return card
          .map((str) => {
            if (str && str.toString().includes('"')) {
              const count =
                str.split(',').length -
                1 +
                (str.split(';').length - 1) +
                (str.split('"').length - 1);
              if (count > 1) {
                str = str.replace(/"/g, '""');
              }
            }
            if (
              str &&
              (str.toString().includes('\n') ||
                str.toString().includes('\r') ||
                str.toString().includes(';') ||
                str.toString().includes(','))
            ) {
              str = str.split('\r').join('\n');
              str = `"${str}"`;
              return str;
            } else {
              return str ? str : ' ';
            }
          })
          .join(seperator);
      })
      .join('\r\n');
  }
}
