import { Component, Input, HostBinding } from '@angular/core';
import { trigger, style, animate, transition } from '@angular/animations';

@Component({
  selector: 'qa-content-spinner',
  templateUrl: './content-spinner.component.html',
  styleUrls: ['./content-spinner.component.scss'],
  animations: [
    trigger('fade', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('200ms 500ms linear', style({ opacity: 1 })),
      ]),
      transition(':leave', [animate('200ms linear', style({ opacity: 0 }))]),
    ]),
  ],
})
export class ContentSpinnerComponent {
  @HostBinding('class.active')
  @Input()
  active = false;
  @Input() loadingText: string = '';

  constructor() {}
}
