<div class="quetion-panel-wrapper" [class.folded]="foldedSidebar">
  <div class="question-list">
    <ng-scrollbar>
      <qa-content-spinner [active]="isLoadingLeft">
        <div class="left flex-grow flex-align-items-start">
          <div class="questions-header">
            <div class="questions-header-content">
              <div class="questions-header-text">
                {{ 'common.label.questions' | translate }}
              </div>
              <div class="questions-header-actions">
                <qa-button
                  [matMenuTriggerFor]="questionmenu"
                  #menuTrigger="matMenuTrigger"
                  [isRaisedButton]="true"
                  [isIconButton]="true"
                  [isRoundedButton]="true"
                  icon="add"
                  type="button"
                ></qa-button>
                <mat-menu #questionmenu="matMenu">
                  <button mat-menu-item (click)="addQuestion()">
                    {{
                      translationKey !== 'exam'
                        ? ('liveEvents.setup.label.addNormalQuestion'
                          | translate)
                        : ('content.exam.addNormalQuestion' | translate)
                    }}
                  </button>
                  <button
                    *ngIf="allowPollQuestions"
                    mat-menu-item
                    (click)="addQuestion(true)"
                  >
                    {{ 'liveEvents.setup.label.addPollQuestion' | translate }}
                  </button>
                  <button mat-menu-item (click)="importItem()">
                    {{ 'liveEvents.setup.label.import' | translate }}
                  </button>
                </mat-menu>
              </div>
            </div>
          </div>

          <div class="questions-body question-body-style">
            <div @fade class="questions-body-content">
              <div class="questions-body-top-list">
                <ng-container
                  [ngTemplateOutlet]="listTemplate"
                  [ngTemplateOutletContext]="{
                    list: questions
                  }"
                >
                </ng-container>
              </div>
            </div>
          </div>

          <ng-template #listTemplate let-list="list">
            <div class="no-content" *ngIf="!list || !list?.length">
              <mat-icon>manage_search</mat-icon>
              <p>
                {{ 'common.list.noContent' | translate }}
                <ng-container *ngIf="translationKey !== 'exam'; else exam">
                  {{ 'liveEvents.setup.questionInLiveevent' | translate }}.
                </ng-container>
                <ng-template #exam>
                  {{ 'exam.setup.questionInExam' | translate }}.
                </ng-template>
              </p>
              <div class="btn-container" *ngIf="!list?.length">
                <qa-button
                  [isRaisedButton]="true"
                  (submitted)="addQuestion()"
                  [label]="
                    translationKey !== 'exam'
                      ? 'liveEvents.setup.label.addNormalQuestion'
                      : 'common.button.createNew'
                  "
                  icon="add"
                  [minWidth]="200"
                  [showIconAtEnd]="true"
                ></qa-button>
                <qa-button
                  *ngIf="allowPollQuestions"
                  [isRaisedButton]="true"
                  (submitted)="addQuestion(true)"
                  label="liveEvents.setup.label.addPollQuestion"
                  icon="add"
                  [minWidth]="200"
                  [showIconAtEnd]="true"
                ></qa-button>
                <qa-button
                  [isRaisedButton]="true"
                  (submitted)="importItem()"
                  label="common.button.add"
                  icon="file_upload"
                  [minWidth]="200"
                  [showIconAtEnd]="true"
                ></qa-button>
              </div>
              <div class="chat-gpt" *ngIf="showChatGpt" (click)="openGPT()">
                <img src="assets/icons/chat-gpt.svg" alt="gpt" />
                <div>{{ 'chatgptImports.title' | translate }}</div>
                <mat-icon>chevron_right</mat-icon>
              </div>
            </div>
            <mat-list
              (cdkDropListDropped)="afterItemDropped($event, list)"
              cdkDropList
              cdkDropListLockAxis="y"
            >
              <mat-list-item
                (click)="onselectQuestion(item)"
                class="question-list-items"
                [cdkDragData]="item"
                *ngFor="let item of list; let i = index"
                cdkDrag
                matRipple
                [ngClass]="{
                  'question-selected': i === selectedQuestionid,
                  'is-poll': item.is_poll_question
                }"
              >
                <mat-icon
                  cdkDragHandle
                  class="drag-indicator drag-icon-indicator"
                  >drag_indicator</mat-icon
                >
                <div *cdkDragPlaceholder class="drag-placeholder"></div>
                <div class="item-position question-position">
                  {{ i + 1 }}
                </div>

                <div class="item-text question-text">
                  <span
                    class="question-type-short"
                    *ngIf="item.is_poll_question"
                  >
                    {{ 'question.label.surveyQuestionShort' | translate }} ({{
                      questionTypeDisplayFn(item.type, true)
                    }})
                  </span>
                  <span
                    class="question-type-short"
                    *ngIf="!item.is_poll_question"
                  >
                    <span
                      >{{ 'question.label.quizQuestionShort' | translate }} ({{
                        questionTypeDisplayFn(item.type, true)
                      }})</span
                    >
                  </span>
                  <span> {{ item.text }}</span>
                </div>

                <div class="item-actions question-action-button">
                  <div class="item-actions">
                    <button
                      (pointerdown)="itemActionMenuTrigger.openMenu()"
                      (click)="
                        $event.stopPropagation(); $event.preventDefault()
                      "
                      [matMenuTriggerFor]="itemActionMenu"
                      #itemActionMenuTrigger="matMenuTrigger"
                      color="primary"
                      mat-icon-button
                      type="button"
                    >
                      <mat-icon>more_vert</mat-icon>
                    </button>

                    <mat-menu class="content-menu" #itemActionMenu="matMenu">
                      <button
                        (click)="exportItemAsJSON(item)"
                        mat-menu-item
                        type="button"
                      >
                        <mat-icon>code</mat-icon>
                        {{ 'content.question.actionMenu.item2' | translate }}
                      </button>
                      <button
                        (click)="removeQuestion(item.id)"
                        mat-menu-item
                        type="button"
                      >
                        <mat-icon>delete</mat-icon>
                        {{
                          translationKey + '.label.removeButtonLabel'
                            | translate
                        }}
                      </button>
                      <button
                        (click)="deleteQuestion(item.id)"
                        mat-menu-item
                        type="button"
                      >
                        <mat-icon>delete</mat-icon>
                        {{
                          translationKey + '.label.deleteButtonLabel'
                            | translate
                        }}
                      </button>
                    </mat-menu>
                  </div>
                  <div class="fold-in-button right-arrow-button">
                    <button
                      mat-icon-button
                      (click)="foldSidebar($event)"
                      *ngIf="!foldedSidebar && i === selectedQuestionid"
                    >
                      <mat-icon>chevron_right</mat-icon>
                    </button>
                  </div>
                </div>
              </mat-list-item>
            </mat-list>
          </ng-template>
        </div>
      </qa-content-spinner>
    </ng-scrollbar>
  </div>
  <div class="edit-entiity">
    <button
      mat-icon-button
      class="mobile-back-button"
      (click)="
        selectedQuestion = undefined;
        foldedSidebar = true;
        selectedQuestionid = undefined
      "
      *ngIf="!!selectedQuestion"
    >
      <mat-icon>arrow_back</mat-icon>
    </button>
    <qa-question-edit
      *ngIf="selectedQuestion"
      (closeSideMenu)="closeSideMenu($event)"
      (updatedQuestion)="updateQuestions()"
      [questionId]="selectedQuestion?.id"
      [isLiveEvent]="queryParams.liveEventId"
    ></qa-question-edit>
  </div>
</div>
