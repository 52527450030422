<div class="row">
  <div class="tabs-container">
    <h1 class="title">{{ matTabsConfig.title | translate }}</h1>
    <div class="tab-container">
      <mat-tab-group
        [selectedIndex]="
          matTabsConfig?.selectedTabIndex ? matTabsConfig?.selectedTabIndex : 0
        "
      >
        <mat-tab *ngFor="let tab of matTabsConfig.tabs">
          <ng-template mat-tab-label>
            {{ tab.label | translate }}
            <div *ngIf="tab.badge | async" [matBadge]="tab.badge | async"></div>
          </ng-template>
          <ng-template matTabContent>
            <qa-grid-data [config]="tab.config"></qa-grid-data>
          </ng-template>
        </mat-tab>
      </mat-tab-group>
    </div>
  </div>
</div>
