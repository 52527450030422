import { Component, HostListener, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export interface ImageDialogComponentData {
  url: string;
}

@Component({
  selector: 'qa-image-dialog',
  templateUrl: './image-dialog.component.html',
  styleUrls: ['./image-dialog.component.scss'],
})
export class ImageDialogComponent {
  zoomBounds = { min: 0.25, max: 4.0 };
  zoomLevel = 0.75;
  zoomSpeed = 0.25;
  zoomMove = { x: 0, y: 0 };

  eventCache: PointerEvent[] = [];
  prevDiff = -1;
  prevPos = null;

  constructor(
    public dialogRef: MatDialogRef<ImageDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ImageDialogComponentData
  ) {}

  zoom(event): void {
    this.zoomLevel = Math.max(
      this.zoomBounds.min,
      Math.min(
        this.zoomLevel - Math.sign(event.deltaY) * this.zoomSpeed,
        this.zoomBounds.max
      )
    );
  }

  down(event: PointerEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.eventCache.push(event);
  }

  drag(event: PointerEvent) {
    event.preventDefault();
    event.stopPropagation();
    if (!this.eventCache || this.eventCache.length <= 0) {
      return;
    }
    for (let i = 0; i < this.eventCache.length; i++) {
      if (event.pointerId === this.eventCache[i].pointerId) {
        this.eventCache[i] = event;
        break;
      }
    }

    if (this.eventCache.length === 2) {
      const curDiff = Math.sqrt(
        Math.pow(this.eventCache[0].clientX - this.eventCache[1].clientX, 2) +
          Math.pow(this.eventCache[0].clientY - this.eventCache[1].clientY, 2)
      );

      if (this.prevDiff > 0) {
        this.zoomLevel = Math.max(
          this.zoomBounds.min,
          Math.min(
            this.zoomLevel * (curDiff / this.prevDiff),
            this.zoomBounds.max
          )
        );
      }
      this.prevDiff = curDiff;
    }

    const curPos = {
      x:
        this.eventCache.reduce((prev, cur) => prev + cur.clientX, 0) /
        this.eventCache.length,
      y:
        this.eventCache.reduce((prev, cur) => prev + cur.clientY, 0) /
        this.eventCache.length,
    };
    if (this.prevPos) {
      this.zoomMove.x += curPos.x - this.prevPos.x;
      this.zoomMove.y += curPos.y - this.prevPos.y;
    }
    this.prevPos = curPos;
  }

  @HostListener('pointerup')
  @HostListener('pointercancel')
  @HostListener('pointerout')
  @HostListener('pointerleave')
  up(_event: PointerEvent) {
    this.eventCache = [];
    this.prevDiff = -1;
    this.prevPos = null;
  }

  @HostListener('pointerdown')
  onClick() {
    this.dialogRef.close();
  }
}
