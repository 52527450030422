<div class="confirmation">
  <div class="icon" *ngIf="!data?.icon">
    <span>!</span>
    <img src="assets/icons/Path-33.png" alt="" />
  </div>
  <mat-icon *ngIf="data?.icon">{{ data?.icon }}</mat-icon>
  <div class="confirm-message">
    <ng-container *ngFor="let message of data?.messages">
      <span [innerHTML]="message"></span> <br /><br />
    </ng-container>
  </div>
  <div class="action" *ngIf="data?.buttonLabels">
    <button
      type="button"
      mat-button
      *ngIf="data?.buttonLabels?.cancel"
      mat-dialog-close
      class="btn-confirm"
    >
      {{ data?.buttonLabels?.cancel }}
    </button>
    <button
      *ngIf="data?.buttonLabels?.confirm"
      mat-button
      (click)="confirm()"
      class="cancel"
      type="button"
    >
      {{ data?.buttonLabels?.confirm }}
    </button>
  </div>
</div>
