<div class="ai-import-questions">
  <qa-content-spinner
    [active]="loading"
    [loadingText]="'AIQuestionImports.loadingText' | translate"
  >
    <div class="header">
      <h1 class="dialog-title">
        {{ 'AIQuestionImports.mainHeader' | translate }}
      </h1>
      <div class="close-icon" mat-dialog-close><mat-icon>close</mat-icon></div>
    </div>
    <div>
      <ng-content *ngTemplateOutlet="actions"></ng-content>
    </div>
  </qa-content-spinner>

  <ng-template #actions>
    <div class="generate-question-info">
      {{ 'AIQuestionImports.ourProposal' | translate }}
    </div>
    <div class="keyword">
      <qa-form-field-input
        [control]="explanation"
        [multi]="true"
        label="{{ 'exam.result.reply' | translate }}"
      >
      </qa-form-field-input>
    </div>
    <div class="generate-btn generate-question-btn">
      <qa-button
        label="{{ 'AIQuestionImports.cancel' | translate }}"
        mat-dialog-close
      ></qa-button>
      <qa-button
        [isRaisedButton]="true"
        label="{{ 'AIQuestionImports.acceptProposal' | translate }}"
        (submitted)="importAIQuestions()"
      ></qa-button>
    </div>
  </ng-template>
</div>
