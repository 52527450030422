import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  CommentModel,
  FlashCardModel,
  QuestionModel,
} from 'src/app/api/models';
import { FlashCardsService, QuestionsService } from 'src/app/api/services';

export interface CommentDetailsDialogData {
  comment: CommentModel;
}

@Component({
  selector: 'qa-comment-details-dialog',
  templateUrl: './comment-details-dialog.component.html',
  styleUrls: ['./comment-details-dialog.component.scss'],
})
export class CommentDetailsDialogComponent implements OnInit {
  entity: QuestionModel | FlashCardModel;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: CommentDetailsDialogData,
    private questions: QuestionsService,
    private flashcards: FlashCardsService
  ) {}

  ngOnInit() {
    const questionId = this.data.comment.question_id;
    const flashCardId = this.data.comment.flash_card_id;
    if (questionId) {
      this.questions
        .getQuestionById({ id: questionId })
        .subscribe((question: QuestionModel) => {
          this.entity = question;
        });
    } else if (flashCardId) {
      this.flashcards
        .getFlashCardById({ id: flashCardId })
        .subscribe((card: FlashCardModel) => {
          this.entity = card;
        });
    }
  }
}
