import { Component, OnInit, Inject } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatDialog,
} from '@angular/material/dialog';
import { CommentModel } from 'src/app/api/models';
import { getCommentListConfig } from './comment-list.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  CommentsService,
  FlashCardsService,
  QuestionsService,
} from 'src/app/api/services';
import { DatePipe } from '@angular/common';
import { DialogService } from '../common/dialog.service';
import { TranslateService } from '@ngx-translate/core';
import { ListConfig } from './list.types';

export interface CommentListDialogData {
  questionId: number;
  flashCardId: number;
  comments?: CommentModel[];
}

@Component({
  selector: 'qa-comment-list-dialog',
  templateUrl: './comment-list-dialog.component.html',
  styleUrls: ['./comment-list-dialog.component.scss'],
})
export class CommentListDialogComponent implements OnInit {
  config: ListConfig<CommentModel>;

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: CommentListDialogData,
    private dialogRef: MatDialogRef<CommentListDialogComponent>,
    private commentsService: CommentsService,
    private date: DatePipe,
    private dialog: DialogService,
    private matDialog: MatDialog,
    private matSnackBar: MatSnackBar,
    private questions: QuestionsService,
    private translator: TranslateService,
    private flashCards: FlashCardsService
  ) {}

  ngOnInit() {
    this.dialogRef.updateSize('960px');

    const config = getCommentListConfig(
      this.commentsService,
      this.questions,
      this.matDialog,
      this.dialog,
      this.matSnackBar,
      this.date,
      true,
      this.data.questionId,
      null,
      this.translator,
      null,
      this.data.flashCardId ? true : false,
      this.flashCards,
      this.data.flashCardId
    );

    config.activeActionNames = ['delete'];
    config.hideTopBar = true;
    config.columns.forEach((c) => {
      if (c.name === 'comment') {
        delete c.click;
      }
    });

    this.config = config;
  }
}
