import { jsPDF } from 'jspdf';
import { LatoFont } from './pdf-fonts/Lato-Regular-normal';
import { environment } from 'src/environments/environment';

export abstract class PDFGenerator {
  protected lastPos = 0;

  protected WIDTH = 210;
  protected HEIGHT = 297;

  protected margin = [15, 20];

  protected qaLogo = null;

  protected colorCode = null;

  protected doc = new jsPDF({
    format: 'a4',
    unit: 'mm',
    orientation: 'p',
    compress: true,
  });

  constructor() {
    this.resetDoc();
  }

  resetDoc() {
    this.doc = new jsPDF({
      format: 'a4',
      unit: 'mm',
      orientation: 'p',
      compress: true,
    });
    this.lastPos = 0;
    this.doc.addFileToVFS('Lato-Regular-normal.ttf', LatoFont);
    this.doc.addFont('Lato-Regular-normal.ttf', 'Lato-Regular', 'normal');
    this.doc.setFont('Lato-Regular');
  }

  protected async loadImage(url: string): Promise<HTMLImageElement> {
    return new Promise((resolve) => {
      try {
        fetch(url + '?' + Date.now().toString())
          .then((response) => response.blob())
          .then((imageBlob) => {
            const img = new Image();
            img.src = URL.createObjectURL(imageBlob);
            img.onload = () => {
              resolve(img);
            };
          });
      } catch {}
    });
  }

  protected async image(url: string, width: number): Promise<void> {
    const img = await this.loadImage(url);
    const aspect = img.width / img.height;
    const height = width / aspect;
    this.doc.addImage(
      img,
      'image/jpeg',
      this.WIDTH / 2 - width / 2,
      this.lastPos,
      width,
      height
    );
    this.lastPos += height;
  }

  protected text(text: string, fontSize = 15): void {
    this.doc.setFontSize(fontSize || 15);
    this.doc.text(text, this.WIDTH / 2, this.lastPos, {
      align: 'center',
      maxWidth: this.WIDTH - this.margin[1] * 2,
    });
    this.lastPos += this.doc.getTextDimensions(text, {
      maxWidth: this.WIDTH - this.margin[1] * 2,
    }).h;
  }

  space(amount: number): void {
    this.lastPos += amount;
  }

  protected break() {
    this.doc.addPage();
    this.lastPos = this.margin[0];
  }

  private showPageNum(num: number) {
    this.doc.setFontSize(10);
    this.doc.setTextColor('#4a4a4a');
    this.doc.text(
      num.toString(),
      this.WIDTH - this.margin[1],
      this.HEIGHT - this.margin[0] / 2,
      {
        align: 'right',
      }
    );
  }

  protected async pageDecoration(pageCallback?: () => Promise<void>) {
    const pages = this.doc.getNumberOfPages();
    for (let j = 1; j < pages + 1; j++) {
      this.doc.setPage(j);
      this.showPageNum(j);
      this.doc.setFillColor(this.colorCode || environment.colors.primary);
      this.doc.rect(0, 0, this.WIDTH, 1.5, 'F');
      this.doc.rect(0, this.HEIGHT - 1.5, this.WIDTH, 1.5, 'F');
      if (!this.qaLogo) {
        this.qaLogo = await this.loadImage('/assets/logo.png');
      }
      const height = (20 * this.qaLogo.height) / this.qaLogo.width;
      this.doc.addImage(
        this.qaLogo,
        'image/jpeg',
        this.margin[1],
        this.HEIGHT - this.margin[0] / 2 - height,
        20,
        height
      );
      if (pageCallback) {
        await pageCallback();
      }
    }
  }

  abstract generate(): Promise<jsPDF | Array<{ name: string; data: Blob }>>;
}
