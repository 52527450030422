<div class="chart" *ngIf="dataDone">
  <svg [attr.height]="dim.w" [attr.width]="dim.w">
    <circle
      [attr.stroke]="'#e4e4e4'"
      [style.strokeDashoffset]="'0'"
      [attr.cx]="dim.w / 2"
      [attr.cy]="dim.w / 2"
      fill="transparent"
      [attr.r]="dim.r"
      [attr.stroke-width]="dim.s"
    ></circle>
    <circle
      *ngFor="let section of activeSections.slice().reverse(); let i = index"
      [style.stroke-dashoffset]="dashoffsets[activeSections.length - 1 - i]"
      [attr.stroke]="section?.color || color(section)"
      [attr.cx]="dim.w / 2"
      [attr.cy]="dim.w / 2"
      fill="transparent"
      [attr.r]="dim.r"
      [attr.stroke-width]="dim.s"
    ></circle>
  </svg>
  <ng-container *ngIf="featureValue !== undefined">
    <div class="percentage">
      {{ featureValue || 0 }}
    </div>
    <div class="correct">
      {{ featureLabel || 'chart.label.correct' | translate }}
    </div>
  </ng-container>
</div>
<div class="legend">
  <div class="flex-column">
    <div
      class="line"
      *ngFor="let section of activeSections"
      [class.clickable]="section.click"
      (click)="section.click ? section.click() : null"
    >
      <div
        class="dot"
        [style.backgroundColor]="section?.color || color(section)"
      ></div>
      <span>{{ section.label }}</span>
    </div>
  </div>
</div>
