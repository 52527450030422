import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UntypedFormControl } from '@angular/forms';
import {
  AiService,
  CoursesService,
  ExamsService,
  LiveEventsService,
  QuizzesService,
} from 'src/app/api/services';
import { PanelService } from 'src/app/cms/panel/panel.service';
import { Router } from '@angular/router';
import { PanelEntityType } from 'src/app/cms/panel/panel.types';
import {
  AiQuestionProposal,
  AiQuestionQuery,
  AnswerModel,
  QuestionModel,
} from 'src/app/api/models';
import { QuestionType } from 'src/app/app-constants';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';

export interface ImportQuestionType {
  type: string;
}

@Component({
  selector: 'qa-import-ai-question',
  templateUrl: './import-ai-question.component.html',
  styleUrls: ['./import-ai-question.component.scss'],
})
export class ImportAiQuestionComponent implements OnInit {
  showInfo = true;
  showGeneratedQuestion = false;
  generatedQuestionResult: AiQuestionProposal[] = [];
  keywords = new UntypedFormControl(null);
  loading = false;
  course: any;
  quiz: any;
  liveEventInfo;
  examInfo;
  creatingQuestion = false;

  constructor(
    public dialogRef: MatDialogRef<ImportAiQuestionComponent>,
    private matSnackBar: MatSnackBar,
    private router: Router,
    private aiService: AiService,
    private quizzes: QuizzesService,
    private panels: PanelService,
    private translator: TranslateService,
    private coursesService: CoursesService,
    private liveEventService: LiveEventsService,
    private exams: ExamsService,
    @Inject(MAT_DIALOG_DATA) public data: ImportQuestionType
  ) {}

  ngOnInit(): void {
    if (this.data.type === 'quiz') {
      const routeParts = this.router.url.split('/');
      const idPart = routeParts[routeParts.length - 1];
      const idParts = idPart.split(';');
      const cId = parseInt(idParts[1].split('=')[1]);
      const qId = parseInt(idParts[2].split('=')[1]);
      const cPanel = this.panels.panels.find(
        (p) => p.type === PanelEntityType.Course
      );
      const course = cPanel.list.find((c) => c.id === cId);
      this.course = course;
      const qPanel = this.panels.panels.find(
        (p) => p.type === PanelEntityType.Quiz
      );
      const quiz = qPanel.list.find((c) => c.id === qId);
      this.quiz = quiz;
    } else {
      const url = this.router.url;
      let id;
      if (url.includes('courseId')) {
        id = parseInt(url.match(/\/(\d+)\?/)[1]);
        const courseId = parseInt(url.match(/courseId=(\d+)/)[1]);
        this.getCourseById(courseId);
      } else {
        const idRegex = /\/(\d+)$/;
        const match = url.match(idRegex);
        if (match) {
          id = match[1];
        }
      }
      if (id && !isNaN(id)) {
        if (this.data.type === 'event-question') {
          this.getLiveEventById(id);
        }
        if (this.data.type === 'exam-question') {
          this.getExamById(id);
        }
      }
    }
  }

  generateAIQuestions() {
    this.loading = true;
    this.showInfo = false;
    this.showGeneratedQuestion = true;
    this.generateQuestion();
  }

  generateAIQuestionAgain() {
    this.loading = true;
    this.generateQuestion();
  }

  generateQuestion() {
    const obj: AiQuestionQuery = {};
    if (this.data.type === 'quiz') {
      obj.quiz_id = this.quiz?.id;
    }
    if (this.data.type === 'event-question') {
      obj.live_event_id = this.liveEventInfo?.id;
    }
    if (this.data.type === 'exam-question') {
      obj.exam_id = this.examInfo?.id;
    }
    if (this.keywords.value) {
      const keywords = this.keywords.value
        .map((obj) => `${obj.name}`)
        .join(', ');
      obj.keywords = keywords;
    }
    this.aiService
      .generateQuestion({
        body: obj,
      })
      .subscribe((data) => {
        this.generatedQuestionResult = data;
        this.loading = false;
      });
  }

  importAIQuestions() {
    let request$;
    this.creatingQuestion = true;
    if (this.data.type === 'quiz') {
      request$ = this.quizzes.copyQuestionToQuiz({
        id: this.quiz.id,
        body: [this.buildQuestion()],
      });
    }
    if (this.data.type === 'event-question') {
      request$ = this.liveEventService.copyQuestionToEvent({
        id: this.liveEventInfo.id,
        body: [this.buildQuestion()],
      });
    }
    if (this.data.type === 'exam-question') {
      request$ = this.exams.copyQuestionToExam({
        id: this.examInfo.id,
        body: [this.buildQuestion()],
      });
    }
    request$.subscribe(() => {
      this.creatingQuestion = false;
      this.dialogRef.close(true);
      this.matSnackBar.open(
        this.translator.instant('AIQuestionImports.successToast')
      );
    });
  }

  saveQuestionAndNext() {
    let request$;
    this.creatingQuestion = true;
    if (this.data.type === 'quiz') {
      request$ = this.quizzes.copyQuestionToQuiz({
        id: this.quiz.id,
        body: [this.buildQuestion()],
      });
    }
    if (this.data.type === 'event-question') {
      request$ = this.liveEventService.copyQuestionToEvent({
        id: this.liveEventInfo.id,
        body: [this.buildQuestion()],
      });
    }
    if (this.data.type === 'exam-question') {
      request$ = this.exams.copyQuestionToExam({
        id: this.examInfo.id,
        body: [this.buildQuestion()],
      });
    }
    request$.subscribe(() => {
      this.creatingQuestion = false;
      this.showInfo = true;
      this.showGeneratedQuestion = false;
      this.matSnackBar.open(
        this.translator.instant('AIQuestionImports.successToast')
      );
    });
  }

  buildQuestion(): QuestionModel {
    const question = this.generatedQuestionResult[0];
    return {
      text: question.question,
      type: QuestionType.MultipleChoice,
      answers: question.answers.map((a) => {
        return {
          text: a['text'],
          is_right: a.correct,
        } as AnswerModel;
      }),
    };
  }

  retry() {
    this.showInfo = true;
    this.showGeneratedQuestion = false;
  }

  getCourseById(id: number) {
    this.coursesService
      .getCourseById({ id, full: false })
      .subscribe((course) => {
        this.course = course;
      });
  }

  getLiveEventById(id: number) {
    this.liveEventService.getLiveEventById({ id: id }).subscribe((event) => {
      this.liveEventInfo = event;
    });
  }

  getExamById(id: number) {
    this.exams.getExamById({ id: id }).subscribe((exam) => {
      this.examInfo = exam;
    });
  }
}
