import { Action, createReducer, on } from '@ngrx/store';
import { SidebarActions } from './sidebar.actions';
import { Sidebar } from './sidebar.types';

export function sidebarReducer(state: Sidebar.State, action: Action) {
  return reducer(state, action);
}

const reducer = createReducer<Sidebar.State>(
  { isVisible: true },
  on(SidebarActions.hideSidebar, () => {
    return { isVisible: false };
  }),
  on(SidebarActions.showSidebar, () => {
    return { isVisible: true };
  })
);
