import { Injectable } from '@angular/core';
import { UsersService } from '../api/services';
import { AuthService } from '../auth/auth.service';
import dayjs from 'dayjs';
import * as _ from 'lodash';
import { debounceTime, lastValueFrom, Subject, takeUntil } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DestroyNotifier } from '../destroy-notifier';

const SESSION_STORAGE_KEY = 'qa-user-config';

/**
 * The time in hours after which the stored state becomes stale
 * and needs to be refreshed.
 */
const STALENESS_DURATION = 1;

@Injectable({
  providedIn: 'root',
})
export class UserConfigService extends DestroyNotifier {
  private userConfig: { [key: string]: any } | undefined = undefined;

  public onUpdate$ = new Subject<void>();

  private onSave$ = new Subject<void>();

  constructor(
    private usersService: UsersService,
    private auth: AuthService,
    private snackBar: MatSnackBar
  ) {
    super();
    this.onSave$
      .pipe(takeUntil(this.destroy$), debounceTime(250))
      .subscribe(() => {
        this.storeUserSettings();
      });
  }

  private async fetchUserSettings(): Promise<void> {
    if (!this.auth.currentUserInfo$?.value?.sub) {
      this.error();
      return;
    }
    const user = await lastValueFrom(
      this.usersService.getUserById({
        id: this.auth.currentUserInfo$.value.sub,
      })
    );
    this.userConfig = user.config ?? {};
    sessionStorage.setItem(
      SESSION_STORAGE_KEY,
      JSON.stringify({
        updatedAt: dayjs().format('YYYY-MM-DDTHH:mm:ss'),
        config: this.userConfig,
      })
    );
  }

  private storeUserSettings() {
    if (!this.auth.currentUserInfo$?.value?.sub) {
      this.error();
    } else {
      this.usersService
        .updateUser({
          id: this.auth.currentUserInfo$.value.sub,
          body: {
            id: this.auth.currentUserInfo$.value.sub,
            config: this.userConfig,
          },
        })
        .subscribe(() => {
          this.fetchUserSettings();
          this.onUpdate$.next();
        });
    }
  }

  public async activate() {
    if (!this.auth.currentUserInfo$?.value?.sub) {
      this.error();
      return;
    }
    if (this.userConfig) return;
    let inSessionStorage = undefined;
    try {
      inSessionStorage = sessionStorage.getItem(SESSION_STORAGE_KEY);
    } catch {
      inSessionStorage = undefined;
    }
    if (inSessionStorage) {
      try {
        const parsed = JSON.parse(inSessionStorage);
        if (parsed && !_.isNil(parsed.config) && !_.isNil(parsed?.updatedAt)) {
          if (
            Math.abs(dayjs(parsed.updatedAt).diff(dayjs(), 'hours')) <=
            STALENESS_DURATION
          ) {
            this.userConfig = parsed.config;
            return;
          }
        }
      } catch {}
    }
    await this.fetchUserSettings();
  }

  public async preloadConfig(): Promise<void> {
    if (_.isNil(this.userConfig)) {
      await this.activate();
    }
    return;
  }

  public getSync(path: string): any | undefined {
    if (!this.auth.currentUserInfo$?.value?.sub) {
      this.error();
      return;
    }
    if (_.isNil(this.userConfig)) {
      throw new Error(
        'User config not yet loaded. Call "preloadConfig" when using sync operators.'
      );
    }
    return _.get(this.userConfig, path);
  }

  public async get(path: string): Promise<any | undefined> {
    if (!this.auth.currentUserInfo$?.value?.sub) {
      this.error();
      return;
    }
    if (_.isNil(this.userConfig)) {
      await this.activate();
    }
    return _.get(this.userConfig, path);
  }

  public async set(path: string, value: any) {
    if (!this.auth.currentUserInfo$?.value?.sub) {
      this.error();
      return;
    }
    if (_.isNil(this.userConfig)) {
      await this.activate();
    }
    _.set(this.userConfig, path, value);
    this.onSave$.next();
  }

  public async remove(path: string) {
    if (!this.auth.currentUserInfo$?.value?.sub) {
      this.error();
      return;
    }
    if (_.isNil(this.userConfig)) {
      await this.activate();
    }
    _.unset(this.userConfig, path);
    this.onSave$.next();
  }

  private error() {
    this.snackBar.open('User is not currently logged in!');
  }

  public async reset() {
    if (!this.auth.currentUserInfo$?.value?.sub) {
      this.error();
      return;
    }
    if (_.isNil(this.userConfig)) {
      await this.activate();
    }
    this.userConfig = {};
    this.onSave$.next();
  }
}
