<h3 mat-dialog-title *ngIf="data?.type !== 'flash-card-stack'">
  {{ 'chatgptImports.mainHeader' | translate }}
</h3>
<h3 mat-dialog-title *ngIf="data?.type === 'flash-card-stack'">
  Karteikarten mit ChatGPT generieren lassen
</h3>
<mat-dialog-content>
  <div class="description">
    <p>{{ 'chatgptImports.contentPara' | translate }}</p>
    <ol>
      <li>{{ 'chatgptImports.list.listItemOne' | translate }}</li>
      <li>
        {{ 'chatgptImports.list.listItemTwo' | translate }}
        <a href="http://chat.openai.com" target="_blank">ChatGPT</a>
        {{ 'chatgptImports.list.ein' | translate }}
      </li>
      <li>{{ 'chatgptImports.list.listItemThree' | translate }}</li>
      <li>{{ 'chatgptImports.list.listItemFour' | translate }}</li>
    </ol>
    <p>
      {{ 'chatgptImports.websiteLink.desc' | translate }}
      <a
        href="https://quizacademy.de/akademie/inhalte-erstellen-lassen/#ChatGPT"
        target="_blank"
        >{{ 'chatgptImports.websiteLink.website' | translate }}</a
      >
      {{ 'chatgptImports.websiteLink.an' | translate }}
    </p>
  </div>
  <qa-form-field-input
    type="text"
    [label]="'chatgptImports.topic' | translate"
    [control]="form.get('topic')"
    *ngIf="!data?.name?.length"
  ></qa-form-field-input>
  <qa-form-field-input
    type="number"
    min="1"
    max="25"
    [step]="1"
    [label]="'chatgptImports.count' | translate"
    [control]="form.get('count')"
  ></qa-form-field-input>
  <qa-form-field-select
    [control]="form.get('difficulty')"
    (ValueChanged)="updateDifficulty($event)"
    [displayFn]="getDifficulty"
    label="{{ 'chatgptImports.Difficulty' | translate }}"
    [options]="difficultyOptions"
    required="true"
  >
  </qa-form-field-select>
  <div class="prompt-out">
    <mat-form-field style="flex: 1 1 0">
      <mat-label> Fragentext </mat-label>
      <textarea
        [formControl]="form.get('prompt')"
        rows="7"
        matInput
        name="verification-code"
      ></textarea>
    </mat-form-field>
    <button mat-icon-button (click)="copyText()">
      <mat-icon>content_copy</mat-icon>
    </button>
  </div>
  <div class="buttons">
    <qa-button
      [isRaisedButton]="true"
      label="Zu ChatGPT"
      icon="open_in_new"
      (submitted)="openChatGpt()"
    ></qa-button>
    <qa-button
      [isRaisedButton]="true"
      label="Zum Import"
      icon="open_in_new"
      (submitted)="goToImport()"
    ></qa-button>
  </div>
</mat-dialog-content>

<mat-dialog-actions>
  <button mat-button color="warn" mat-dialog-close>
    {{ 'common.button.shutdown' | translate }}
  </button>
</mat-dialog-actions>
