import { Injectable } from '@angular/core';
import { Edit } from '../edit.types';
import { CategoryModel, CourseModel } from 'src/app/api/models';
import { CategoriesService, CoursesService } from 'src/app/api/services';
import { map, tap } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import * as dayjs from 'dayjs';
import { UserJourneyService } from '../../pages/onboarding-steps/user-journey.service';
import { SubscriptionLimitService } from '../../common/dialogs/subscription-limit-warning/subscription-limit.service';
import { SubscriptionLimit } from '../../common/dialogs/subscription-limit-warning/subscription-limit-warning.component';

@Injectable()
export class CourseEditConfigService extends Edit.ConfigService<CourseModel> {
  constructor(
    api: CategoriesService,
    courses: CoursesService,
    translator: TranslateService,
    userJourney: UserJourneyService,
    subscriptionLimit: SubscriptionLimitService
  ) {
    super({
      itemName: translator.instant('common.label.course'),
      id: 'course',
      deleteConfirmText: translator.instant('course.deleteConfirmText'),
      deleteConfirmTitle: translator.instant('course.deleteConfirmTitle'),
      fields: [
        {
          key: 'name',
          label: translator.instant('course.form.name'),
          required: true,
        },
        {
          key: 'description',
          label: translator.instant('course.form.description'),
          multiLine: true,
          hideIf: () => true,
        },
        {
          key: 'responsible',
          label: translator.instant('course.form.responsible'),
          hideIf: () => true,
        },
        {
          key: 'category',
          label: translator.instant('course.form.category'),
          // required: true,
          hideIf: () => true,
          select: {
            getOptions: () => {
              return api
                .getCategories()
                .pipe(
                  map((categories) => {
                    return categories.map<Edit.Option<CategoryModel>>((c) => {
                      return {
                        value: c,
                        text: c.name,
                      };
                    });
                  })
                )
                .toPromise();
            },
          },
        },
      ],
      handlers: {
        update: (c) => {
          c.exam = dayjs(c.exam).format();
          return courses
            .updateCourse({ id: c.id, body: c })
            .pipe(
              map((c) => {
                c.exam = dayjs(c.exam).toISOString();
                return c;
              })
            )
            .toPromise();
        },
        create: (c) => {
          c.exam = dayjs(c.exam).format();
          if (!c.organization) {
            c.organization = { id: 1 };
          }
          return courses
            .createCourse({ body: c })
            .pipe(
              tap(() => {
                userJourney.set('createdCourse');
              }),
              map((c) => {
                c.exam = dayjs(c.exam).toISOString();
                return c;
              })
            )
            .toPromise();
        },
        delete: (c) => courses.deleteCourse({ id: c.id }).toPromise(),
        init: async (initialValue) => {
          if (initialValue) {
            initialValue.exam = dayjs(initialValue.exam).toISOString();
          }
          return initialValue;
        },
      },
      afterOpened: () =>
        subscriptionLimit.openDialog(SubscriptionLimit.COURSE_COUNT),
    });
  }
}
