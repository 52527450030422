import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { from, Observable, throwError } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { AuthService } from './auth.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    public matSnackBar: MatSnackBar,
    public router: Router,
    private auth: AuthService,
    private translator: TranslateService
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return from(this.auth.getJwtToken()).pipe(
      map((token: string) => {
        if (!token || request.url.indexOf('googleapis') !== -1) {
          return request;
        }
        return request.clone({
          setHeaders: { Authorization: token },
        });
      }),
      switchMap((req: HttpRequest<any>) => next.handle(req)),
      catchError((error: any) => {
        if (request.url.indexOf('me/payment-details') !== -1) {
          console.log(error);
        }
        if (error.status === 401) {
          this.router.navigateByUrl('/login');
          this.matSnackBar.open(
            this.translator.instant('auth.message.forwardToLogin')
          );
        }
        return throwError(error);
      })
    );
  }
}
