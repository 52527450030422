import { Component, Input } from '@angular/core';

@Component({
  selector: 'qa-expandable-info',
  templateUrl: './expandable-info.component.html',
  styleUrls: ['./expandable-info.component.scss'],
})
export class ExpandableInfoComponent {
  @Input() info: string;
  @Input() icon: string;
  @Input() title: string;
  explanationOpen = false;
}
