<div class="import-content-dialog">
  <div class="header">
    <div class="title">
      {{ title }}
    </div>
    <div class="close-icon" mat-dialog-close><mat-icon>close</mat-icon></div>
  </div>
  <div class="import-content-container">
    <div
      class="import-content"
      [class.highlight]="item.highlight"
      *ngFor="let item of imports"
    >
      <div class="import-content-new" *ngIf="item.highlight">
        {{ 'common.label.new' | translate }}
      </div>
      <div class="import-content-title">{{ item.title }}</div>
      <div class="import-content-info">
        <div>
          <div class="import-content-description">{{ item.description }}</div>
          <a class="cursor-pointer"
            ><div
              class="import-content-extra"
              *ngIf="item?.extra"
              (click)="item.extra.click()"
            >
              {{ item.extra?.name }}<mat-icon>{{ item.extra?.icon }}</mat-icon>
            </div></a
          >
        </div>
        <div class="action">
          <qa-button
            *ngIf="item?.action"
            [isRaisedButton]="true"
            [label]="item?.action.text"
            [minWidth]="198"
            (submitted)="item.action.click(dataSource)"
          ></qa-button>
        </div>
      </div>
    </div>
  </div>
</div>
