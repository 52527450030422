import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';

export interface ShowInfoItem {
  modelTitle: string;
  modelInfo?: string;
  modelType?: string;
  confirmButtonText: string;
}

@Component({
  selector: 'qa-confirm-convert-from-quiz',
  templateUrl: './confirm-convert-from-quiz.component.html',
  styleUrls: ['./confirm-convert-from-quiz.component.scss'],
})
export class ConfirmConvertFromQuizComponent {
  form: UntypedFormGroup;
  constructor(
    public dialogRef: MatDialogRef<ConfirmConvertFromQuizComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ShowInfoItem
  ) {
    this.form = new UntypedFormGroup({
      convert_from_quiz: new UntypedFormControl(false),
    });
  }

  convertQuiz() {
    this.dialogRef.close(this.form.value);
  }
}
