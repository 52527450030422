import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { filter, switchMap } from 'rxjs/operators';
import {
  Exam,
  LiveEvent,
  QuestionModel,
  QuizModel,
  QuizQuery,
} from 'src/app/api/models';
import {
  ExamsService,
  LiveEventsService,
  QuizzesService,
} from 'src/app/api/services';
import { DialogService } from 'src/app/cms/common/dialog.service';
import { ListActionMeta, ListConfig, ListQuery } from './list.types';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { getQuizImports } from '../panel/panels/quiz-panel';
import { PanelService } from '../panel/panel.service';
import { PanelEntityType } from '../panel/panel.types';
import * as fileSaver from 'file-saver';
import { cloneDeep } from 'lodash';
import { multiSelectAction } from './grid-data/grid-data.component';
import {
  ConfirmConvertFromQuizComponent,
  ShowInfoItem,
} from '../common/dialogs/confirm-convert-from-quiz/confirm-convert-from-quiz.component';
import { EditService } from '../edit/edit.service';
import { QuizEditConfigService } from '../edit/configs/quiz.service';
import { XLSXReader } from '../common/import-helpers/xlsxReader';
import { settingAnswers, settingData } from 'src/app/utility/app.utils';

@Component({
  selector: 'qa-quiz-list',
  templateUrl: './quiz-list.component.html',
  styleUrls: ['./quiz-list.component.scss'],
})
export class QuizListComponent implements OnInit {
  config: ListConfig<QuizModel>;
  navGroups = [];
  constructor(
    private quizzes: QuizzesService,
    private panelService: PanelService,
    private dialog: DialogService,
    private matDialog: MatDialog,
    private matSnackBar: MatSnackBar,
    private translator: TranslateService,
    private router: Router,
    private exams: ExamsService,
    private events: LiveEventsService,
    private editService: EditService
  ) {}

  ngOnInit() {
    const config = getQuizListConfig(
      this.quizzes,
      this.matDialog,
      this.dialog,
      this.matSnackBar,
      this.translator,
      true,
      this.router,
      this.editService,
      this.exams,
      this.events
    );
    this.setImports(config);
    this.config = config;
  }

  setImports(config) {
    const imports = getQuizImports(this.translator, null, null, false);
    imports[0].action.click = async (dataSource) => {
      const item = (await this.panelService.promptFile('json')) as QuizModel;
      this.matDialog.closeAll();
      dataSource.loading = true;
      this.quizzes
        .createQuiz({
          body: item,
        })
        .subscribe(
          (quiz) => {
            this.importQuestions(item?.questions, quiz.id, dataSource);
          },
          () => (dataSource.loading = false)
        );
    };
    imports[1].action.click = async (dataSource) => {
      const item = (await this.panelService.getJSONFromXML()) as QuizModel;
      this.matDialog.closeAll();
      dataSource.loading = true;
      this.quizzes
        .createQuiz({
          body: item,
        })
        .subscribe(
          (quiz) => {
            this.importQuestions(item?.questions, quiz.id, dataSource);
          },
          () => (dataSource.loading = false)
        );
    };
    imports.push({
      title: this.translator.instant('content.quiz.importfromKahootTitle'),
      description: this.translator.instant('content.quiz.ImportFromKahootDesc'),
      action: {
        text: this.translator.instant('content.quiz.ImportFromKahoot'),
        icon: 'description',
        click: () => {
          this.importFromKahoot();
        },
      },
      extra: {
        name: this.translator.instant('content.quiz.Extra'),
        type: 'link',
        icon: 'keyboard_arrow_right',
        click: () => {
          window.open(
            'https://quizacademy.de/tipps/kahoot-inhalte-importieren/',
            '_blank'
          );
        },
      },
    });
    config.imports = imports;
  }

  async importFromKahoot() {
    const excelFile = await this.panelService.selectExcelFileAsBinary();
    const file = XLSXReader.excelToArray(excelFile);
    const name = file[0][0];
    if (!name || !name.trim()) {
      this.matSnackBar.open(
        this.translator.instant('content.quiz.importFromExcel.text1')
      );
      return;
    }
    let quiz;
    const snackbar = this.matSnackBar.open(
      this.translator.instant('content.quiz.importFromExcel.text2')
    );
    const questions = this.panelService.importQuestionsFromKahoot(excelFile);
    let questionArr = settingData(questions);
    settingAnswers(questionArr);
    questionArr.forEach((question) => {
      if (question['type'] === 'True/False') {
        question['type'] = 1;
        if (question['answers'].length && question['answers'][0]['is_right']) {
          question['is_right'] = question['answers'][0]['is_right'];
        }
        delete question['answers'];
      } else if (question['type'] === 'Multiple Choice') {
        question['type'] = 0;
      } else if (question['type'] === 'Single Choice') {
        question['type'] = 2;
      }
    });
    questionArr = questionArr.filter(
      (q) => q.type === 1 || (q.type !== 1 && q.answers?.length)
    );
    if (name) {
      quiz = await this.quizzes
        .createQuiz({
          body: {
            name: name,
          } as QuizModel,
        })
        .toPromise();
      this.matSnackBar.open(
        this.translator.instant('content.quiz.importFromExcel.text3')
      );
      await this.quizzes
        .copyQuestionToQuiz({
          id: quiz.id,
          body: questionArr,
        })
        .toPromise();
      snackbar.dismiss();
      this.matSnackBar.open(
        this.translator.instant('content.quiz.importFromExcel.text4')
      );
      this.matDialog.closeAll();
      this.router.navigate(['cms', 'content', quiz.id, 'questions']);
    }
  }

  async importQuestions(items: QuestionModel[], parentId: number, dataSource) {
    if (items?.length) {
      await this.quizzes
        .copyQuestionToQuiz({ id: parentId, body: items })
        .toPromise();
    }
    dataSource.refresh();
  }
}

export function getQuizListConfig(
  quizzes: QuizzesService,
  matDialog: MatDialog,
  dialog: DialogService,
  matSnackBar: MatSnackBar,
  translator: TranslateService,
  showMultiselect: boolean,
  router: Router,
  editService: EditService,
  exams?: ExamsService,
  events?: LiveEventsService
): ListConfig<QuizModel> {
  async function exportAsJson(meta: ListActionMeta<QuizModel>) {
    meta.dataSource.loading = true;
    let fullItem: QuizModel;
    fullItem = cloneDeep(meta.row);
    const questions = await quizzes
      .getQuestionsForQuiz({
        id: fullItem.id,
        embed_answers: true,
        embed_tags: true,
      })
      .toPromise();
    questions.forEach((q) => delete q.id);
    delete fullItem.id;
    fullItem.questions = questions;
    fullItem['meta:importType'] = PanelEntityType.Quiz;
    const file = `${JSON.stringify(fullItem)}\n`;
    const blob = new Blob([file], { type: 'application/json' });
    fileSaver.saveAs(blob, `qa-${PanelEntityType.Quiz}-${fullItem.name}.json`);
    meta.dataSource.loading = false;
  }
  const ret: ListConfig<QuizModel> = {
    actions: [
      {
        click: ({ row }) => {
          router.navigate([`/cms/content/${row.id}/questions`], {
            queryParams: { showStatistics: true },
          });
        },
        icon: 'timeline',
        name: 'stats',
        label: 'quiz.tooltip.statistics',
        tooltip: 'content.quiz.actionMenu.item3',
        type: 'button',
      },
      {
        click: ({ row, dataSource }) => {
          dialog
            .confirmDelete()
            .pipe(
              filter((isConfirmed) => isConfirmed),
              switchMap(() => quizzes.deleteQuiz({ id: row.id }))
            )
            .subscribe({
              next: () => {
                matSnackBar.open(
                  translator.instant('content.quiz.deleteItem.success')
                );
                dataSource.refresh();
              },
              error: () => {
                matSnackBar.open(
                  translator.instant('content.quiz.deleteItem.error')
                );
              },
            });
        },
        icon: 'delete',
        name: 'delete',
        tooltip: 'quiz.tooltip.delete',
        label: 'quiz.tooltip.delete',
        type: 'button',
      },
    ],
    actionsMenu: [
      {
        icon: 'question_answer',
        name: 'edit',
        label: translator.instant('liveEvent.setup.label.manageQuestions'),
        type: 'button',
        click: (meta) => {
          router.navigateByUrl(
            `/cms/content/${meta.row.id}/questions?from=quiz`
          );
        },
      },
      {
        icon: 'save_alt',
        name: 'download',
        label: translator.instant('content.quiz.actionMenu.item6'),
        type: 'button',
        click: (meta) => exportAsJson(meta),
      },
      {
        icon: 'play_arrow',
        name: 'convert',
        label: translator.instant('content.quiz.actionMenu.item5'),
        type: 'button',
        click: (meta) => {
          const dialogRef = matDialog.open<
            ConfirmConvertFromQuizComponent,
            ShowInfoItem
          >(ConfirmConvertFromQuizComponent, {
            data: {
              modelTitle: translator.instant(
                'course.confirmAction.convertContentQuizToEvent'
              ),
              confirmButtonText: translator.instant(
                'course.confirmAction.convertToEvent'
              ),
            },
            width: '576px',
            height: 'auto',
            autoFocus: false,
            panelClass: 'confirm-convert-quiz-modal',
          });
          dialogRef.afterClosed().subscribe((resp) => {
            if (resp) {
              convertQuizToEvent(
                meta.row,
                quizzes,
                events,
                matSnackBar,
                translator,
                router
              );
            }
          });
        },
      },
      {
        icon: 'transform',
        name: 'convert',
        label: translator.instant('content.quiz.actionMenu.item10'),
        type: 'button',
        click: (meta) => {
          const dialogRef = matDialog.open<
            ConfirmConvertFromQuizComponent,
            ShowInfoItem
          >(ConfirmConvertFromQuizComponent, {
            data: {
              modelTitle: translator.instant(
                'course.confirmAction.convertContentQuizToExam'
              ),
              confirmButtonText: translator.instant(
                'course.confirmAction.convertToExam'
              ),
            },
            width: '576px',
            height: 'auto',
            autoFocus: false,
            panelClass: 'confirm-convert-quiz-modal',
          });
          dialogRef.afterClosed().subscribe((resp) => {
            if (resp) {
              convertToExam(
                meta.row,
                quizzes,
                exams,
                matSnackBar,
                translator,
                router
              );
            }
          });
        },
      },
    ],
    activeActionNames: ['edit', 'stats', 'delete'],
    activeColumnNames: ['id', 'name', 'num_questions'],
    columns: [
      {
        label: 'ID',
        name: 'id',
        sortable: true,
        width: '64px',
        click: (meta) =>
          router.navigateByUrl(`/cms/content/${meta.row.id}/questions`),
        filter: (value) => {
          return {
            id: Number(value),
          };
        },
      },
      {
        click: (meta) =>
          router.navigateByUrl(`/cms/content/${meta.row.id}/questions`),
        label: 'quiz.table.name',
        name: 'name',
        sortable: true,
        primary: true,
        filter: (value) => {
          return {
            name: value,
          };
        },
      },
      {
        label: 'quiz.table.questions',
        name: 'num_questions',
        sortable: true,
        width: '80px',
        click: (meta) =>
          router.navigateByUrl(`/cms/content/${meta.row.id}/questions`),
        filter: (value) => {
          return {
            num_questions: Number(value),
          };
        },
      },
    ],
    createButton: {
      options: [
        {
          text: translator.instant('content.quiz.addMenu.item1'),
          icon: 'add',
          click: async (dataSource) => {
            await editService.open(QuizEditConfigService);
            dataSource?.refresh();
          },
        },
      ],
    },
    fetch: (queryParams?: ListQuery<QuizModel>) => {
      return quizzes.queryQuiz({ body: queryParams as unknown as QuizQuery });
    },
    query: {
      initial: {
        sorting: [
          {
            option: 'id',
            direction: 'DESC',
          },
        ],
      },
      searchColumnName: 'name',
    },
    name: 'quizList',
    title: 'Quiz',
  };

  async function convertQuizToEvent(
    quiz: QuizModel,
    quizzesService: QuizzesService,
    eventsService: LiveEventsService,
    matSnackBar: MatSnackBar,
    translator: TranslateService,
    router: Router
  ) {
    const questions = await quizzesService
      .getQuestionsForQuiz({
        id: quiz.id,
      })
      .toPromise();
    const event = await eventsService
      .createLiveEvent({
        body: {
          name: quiz.name,
        } as LiveEvent,
      })
      .toPromise();
    await eventsService
      .linkQuestionToEvent({
        id: event.id,
        body: questions.map((q) => ({ id: q.id } as QuestionModel)),
      })
      .toPromise();
    matSnackBar.open(translator.instant('content.quiz.convertedEvent'));
    router.navigate(['/cms/live-events', event.id]);
  }

  function convertToExam(
    quiz: QuizModel,
    quizes: QuizzesService,
    exams: ExamsService,
    matSnackBar,
    translator,
    router: Router
  ) {
    matSnackBar.open(translator.instant('content.quiz.convertingExam'));
    const exam = {
      name: quiz.name,
      scoring_schema: 'DEFAULT',
    };
    let req$: any;
    req$ = exams.createExam({
      body: exam as Exam,
    });
    req$.subscribe(
      (response: Exam) => {
        linkQuestions(
          quizes,
          quiz.id,
          response.id,
          exams,
          matSnackBar,
          translator,
          router
        );
      },
      () => {
        matSnackBar.open(translator.instant('content.quiz.convertExamError'));
      }
    );
  }

  function linkQuestions(
    quizes: QuizzesService,
    quizId: number,
    examId: number,
    exams: ExamsService,
    matSnackBar,
    translator,
    router: Router
  ) {
    matSnackBar.open(translator.instant('content.quiz.linkingQuestion'));
    quizes
      .getQuestionsForQuiz({ embed_answers: false, id: quizId })
      .subscribe((questions) => {
        const quetionIds = questions.map((q) => {
          return { id: q.id } as QuestionModel;
        });
        exams
          .linkQuestionToExam({
            id: examId,
            body: quetionIds,
          })
          .subscribe(
            () => {
              matSnackBar.open(
                translator.instant('content.quiz.converrtedExam')
              );
              router.navigate(['/cms/exams', examId]);
            },
            () => {
              matSnackBar.open(
                translator.instant('content.quiz.linkingQuestionsError')
              );
            }
          );
      });
  }

  if (showMultiselect) {
    ret.multiselectActions = [
      {
        icon: 'delete',
        name: 'question.tooltip.delete',
        type: 'button',
        click: (meta) => {
          return new Promise((resolve) => {
            dialog
              .confirmDelete(
                null,
                translator.instant('common.message.deleteMulti', {
                  count: meta.rows.length,
                })
              )
              .pipe(filter((isConfirmed) => isConfirmed))
              .subscribe(async () => {
                await multiSelectAction(
                  meta.rows,
                  async (item: any) => {
                    await quizzes.deleteQuiz({ id: item.id }).toPromise();
                    return true;
                  },
                  `${translator.instant(
                    'common.label.deleting'
                  )} ${translator.instant('common.label.quiz')}`,
                  matDialog
                );
                meta.dataSource.refresh();
                resolve();
              });
          });
        },
      },
    ];
  }

  return ret;
}
