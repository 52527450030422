import {
  UntypedFormArray,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import {
  AnswerModel,
  CourseModel,
  FlashCardModel,
  QuestionModel,
} from 'src/app/api/models';
import {
  QuestionsService,
  QuizzesService,
  ExamsService,
  CoursesService,
  FlashCardsService,
  LiveEventsService,
  BooksService,
} from 'src/app/api/services';
import {
  QuestionType,
  QuizQuestionType,
  PollQuestionType,
  getQuestionTypeOptions,
} from 'src/app/app.types';
import { cloneDeep, sortBy } from 'lodash';
import { Observable, Subscription, forkJoin } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { questionTypeDisplayFn } from 'src/app/app.types';
import { AbstractEditComponent } from 'src/app/cms/common/abstract-edit.component';
import { DialogService } from 'src/app/cms/common/dialog.service';
import {
  OnInit,
  OnDestroy,
  Component,
  Input,
  OnChanges,
  SimpleChanges,
  Output,
  EventEmitter,
} from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { generatingQuestionForm } from 'src/app/utility/app.utils';
import { ActivatedRoute, Router } from '@angular/router';
import { CancelConfirmationComponent } from '../common/dialogs/cancel-confirmation.component';
import { AuthService } from 'src/app/auth/auth.service';
import { Location } from '@angular/common';
import { ImageEditDialogComponent } from './image-edit-dialog/image-edit-dialog.component';
import * as SCTemplate from './templates/question/qa-question-singe-choice.json';
import * as TFTemplate from './templates/question/qa-question-true-false.json';
import * as MCTemplate from './templates/question/qa-question-multiple-choice.json';
import { templates as pollTemplates } from './templates/polls/poll-templates';
import {
  ShowInfoDialogComponent,
  ShowInfoItem,
} from '../common/show-info-dialog/show-info-dialog.component';
import * as fileSaver from 'file-saver';
import { PanelEntityType } from '../panel/panel.types';
import {
  CommentListDialogComponent,
  CommentListDialogData,
} from '../list/comment-list-dialog.component';
import { MatDialogConfig } from '@angular/material/dialog';
import { CustomTab } from 'src/app/shared/components/custom-tabs/custom-tabs.component';

@Component({
  selector: 'qa-question-edit',
  templateUrl: './question-edit.component.html',
  styleUrls: ['./question-edit.component.scss'],
})
export class QuestionEditComponent
  extends AbstractEditComponent
  implements OnInit, OnChanges, OnDestroy
{
  @Input() questionId: number;
  @Input() isLiveEvent? = false;
  @Output() updatedQuestion = new EventEmitter<QuestionModel>();
  @Output() closeSideMenu = new EventEmitter<boolean>();
  loading = true;
  parentExamId: number;
  examPlayId: number;
  parentQuizId: number;
  commentId: number;
  questionTypeEnum;
  radioRightAnswerControl = new UntypedFormControl();
  radioValueChanges: Subscription;
  typeValueChanges: Subscription;
  formValueChanges: Subscription;
  courseData: CourseModel;
  question: QuestionModel;
  userId: string;
  courseId: number;
  bookId: number;
  id: number;
  flashCardId: number;
  questionIdFromContent: number;
  duplicateQuestionId: number;
  showForm = false;
  currentItemIndex = 0;
  allQuestions: QuestionModel[];
  liveEventId: number;
  isPollQuestion = false;
  questionsTypes: CustomTab[] = [];
  templates = [];
  hasChange: boolean = false;
  hasComments = false;
  showMobilePreview = false;
  QuestionTypeDisplayFn = questionTypeDisplayFn;

  get hasAtLeastOneAnswer(): boolean {
    return this.form
      .get('answers')
      .value.some((answer: AnswerModel) => !!answer.text);
  }

  constructor(
    private dialog: DialogService,
    private exams: ExamsService,
    private matDialog: MatDialog,
    private matSnackBar: MatSnackBar,
    private flashCards: FlashCardsService,
    private questions: QuestionsService,
    private quizzes: QuizzesService,
    private liveEventService: LiveEventsService,
    private route: ActivatedRoute,
    private location: Location,
    private router: Router,
    auth: AuthService,
    private coursesService: CoursesService,
    private booksService: BooksService,
    private translator: TranslateService
  ) {
    super();
    this.userId = auth.currentUserInfo$.value.sub;
    this.route.queryParams.subscribe((params) => {
      if (params.isPoll) {
        this.isPollQuestion = true;
      }
      if (params.course || params.courseId) {
        this.courseId = params.course || params.courseId;
      }
      if (this.route?.snapshot?.params?.course) {
        this.courseId = this.route.snapshot.params.course;
      }
      if (params.book) {
        this.bookId = params.book;
      }
      if (params.commentId) {
        this.commentId = params.commentId;
      }
      if (this.route.snapshot.params.quiz) {
        this.parentQuizId = this.route.snapshot.params.quiz;
      }
      if (params.quiz) {
        this.parentQuizId = params.quiz;
      }
      if (params.exam) {
        this.parentExamId = params.exam;
      }
      if (this.route.snapshot.params.examId) {
        this.examPlayId = this.route.snapshot.params.examId;
      }
      if (params.liveEventId) {
        this.liveEventId = params.liveEventId;
      }
      if (params.events) {
        this.liveEventId = params.events;
      }
      if (params.flashCardId) {
        this.flashCardId = Number(params.flashCardId);
        this.getFlashCard(this.flashCardId);
      }
      if (params.questionId) {
        this.duplicateQuestionId = Number(params.questionId);
        this.getAllQuestions(this.duplicateQuestionId);
      }
      if (params.contentQuestionId && !(params.course || params.courseId)) {
        if (localStorage.getItem('content-question-list')) {
          this.questionIdFromContent = Number(params.contentQuestionId);
          const questions = localStorage.getItem('content-question-list');
          this.allQuestions = JSON.parse(questions).data;
          const idx = this.allQuestions.findIndex(
            (question) => question.id === Number(params.contentQuestionId)
          );
          this.currentItemIndex = idx > -1 ? idx : 0;
        }
      }
    });
    this.route.params.subscribe((params) => {
      if (params.id) {
        this.id = params.id;
        this.getAllQuestions(params.id);
      } else if (!this.flashCardId && !this.duplicateQuestionId) {
        this.loading = false;
      }
      if (params.quiz) {
        this.parentQuizId = params.quiz;
      }
      if (params.examId) {
        this.parentExamId = params.examId;
      }
      if (params.liveEventId) {
        this.liveEventId = params.liveEventId;
      }
    });
  }

  ngOnInit() {
    this.setQuestionEnums();
    this.getQuestionInfo();
    this.form =
      this.form ||
      new UntypedFormGroup({
        id: new UntypedFormControl(),
        text: new UntypedFormControl(),
        type: new UntypedFormControl(),
        max_score: new UntypedFormControl(1),
        max_answer_submissions: new UntypedFormControl(),
        max_num_characters: new UntypedFormControl(),
        is_right: new UntypedFormControl(),
        answers: new UntypedFormArray([]),
        weblink: new UntypedFormControl(),
        source: new UntypedFormControl(),
        tags: new UntypedFormControl([]),
        explanation: new UntypedFormControl(),
        display_in_order: new UntypedFormControl(),
        media: new UntypedFormGroup({
          id: new UntypedFormControl(),
          file_ext: new UntypedFormControl(),
          uuid: new UntypedFormControl(),
        }),
        sample_answer: new UntypedFormControl(),
      });
    if (!this.id && !this.flashCardId && !this.duplicateQuestionId) {
      generatingQuestionForm(
        { answers: [] },
        this.form,
        this.radioRightAnswerControl,
        this.radioValueChanges,
        this.typeValueChanges
      );
      if (this.isPollQuestion) {
        this.form.patchValue({ display_in_order: true });
      }
      this.showForm = true;
    }

    this.form.get('type').valueChanges.subscribe((value) => {
      if (value !== null) {
        if (value === QuestionType.FREETEXT) {
          this.setQuestionEnums();
          if (!this.isPollQuestion) {
            this.form.get('max_score').setValidators([Validators.required]);
            this.form.get('max_score').valueChanges.subscribe((value) => {
              if (value && value < 1) {
                this.form.get('max_score').setValue(1);
              }
            });
          } else {
            this.form
              .get('max_answer_submissions')
              .setValidators([Validators.required]);
            this.form
              .get('max_answer_submissions')
              .valueChanges.subscribe((value) => {
                if (!Number.isNaN(parseInt(value)) && value < 1) {
                  this.form.get('max_answer_submissions').setValue(null);
                }
              });
          }
        } else {
          this.form.get('max_answer_submissions').reset();
          this.form.get('max_answer_submissions').clearValidators();
          this.form.get('max_score').clearValidators();
          if (value === QuestionType.SINGLE_CHOICE) {
            const formArrayAnswers = this.form.get(
              'answers'
            ) as UntypedFormArray;
            formArrayAnswers.controls.forEach((control, index) => {
              control.patchValue({ is_right: index === 0 ? true : false });
            });
            this.form.get('answers').updateValueAndValidity();
          }
        }
        this.form.get('max_score').updateValueAndValidity();
        this.form.get('max_answer_submissions').updateValueAndValidity();
      }
    });
    if (!this.questionId) {
      this.toggleSidebar(false);
    }
    if (this.id) {
      this.getComments();
    }
  }

  isAllAnswerUnchecked(): boolean {
    if (this.form?.value.type !== this.questionTypeEnum.MULTIPLE_CHOICE) {
      return false;
    }
    const controls = this.form.get('answers')['controls'];
    const isChecked = controls.find((control) => control?.value?.is_right);
    return !!!isChecked;
  }

  getComments() {
    this.questions
      .getCommentsForQuestion({
        id: this.id,
      })
      .subscribe((response) => {
        this.hasComments = !!response.length;
      });
  }

  showComments() {
    const id = this.question?.id || this.questionId;
    this.matDialog.open(CommentListDialogComponent, {
      data: {
        questionId: id,
      },
      autoFocus: false,
    } as MatDialogConfig<CommentListDialogData>);
  }

  openDuplicateSuccessfulDialog() {
    const buttons = [
      {
        color: 'primary',
        text: this.translator.instant(
          this.parentExamId
            ? 'question.backToExam'
            : this.parentQuizId
            ? 'question.backToQuiz'
            : 'question.backToEvent'
        ),
        value: false,
      },
      {
        color: 'primary',
        text: this.translator.instant('flashCard.goToQuestionOverview'),
        value: true,
      },
    ];
    if (!this.parentExamId && !this.parentQuizId && !this.liveEventId) {
      buttons.splice(0, 1);
    } else {
      buttons.splice(1, 1);
    }
    this.dialog
      .confirm(
        {
          title: this.translator.instant('question.duplicatedTitle'),
          text: this.translator.instant('question.duplicatedMessage'),
          buttons,
        },
        {
          autoFocus: false,
          disableClose: true,
        }
      )
      .subscribe((val) => {
        if (val) {
          this.router.navigate(['/cms/content/questions']);
        } else if (val === false) {
          if (this.courseId) {
            this.redirectBasedOnParent('courses', 'course', this.courseId);
          } else if (this.bookId) {
            this.redirectBasedOnParent('books', 'book', this.bookId);
          } else {
            this.onClose();
          }
        }
      });
  }

  onValueChange(e) {
    setTimeout(() => {
      this.showMobilePreview = e;
    }, 100);
  }

  onCreateGroupFormValueChange() {
    const initialValue = this.form.value;
    this.formValueChanges = this.form.valueChanges.subscribe(() => {
      this.hasChange = Object.keys(initialValue).some(
        (key) => this.form.value[key] != initialValue[key]
      );
    });
  }

  exportAsJson() {
    let fullItem: QuestionModel;
    fullItem = cloneDeep(this.question);
    delete fullItem.id;
    fullItem['meta:importType'] = PanelEntityType.Question;
    const file = `${JSON.stringify(fullItem)}\n`;
    const blob = new Blob([file], { type: 'application/json' });
    fileSaver.saveAs(
      blob,
      `qa-${PanelEntityType.Question}-${fullItem.text}.json`
    );
  }

  openConvertedQuestionDialog() {
    this.dialog
      .confirm(
        {
          title: this.translator.instant('question.convertedTitle'),
          text: this.translator.instant('question.convertedMessage'),
          buttons: [
            {
              color: 'primary',
              text: this.translator.instant('flashCard.goToQuestionOverview'),
              value: true,
            },
          ],
        },
        {
          autoFocus: false,
          disableClose: true,
        }
      )
      .subscribe(() => {
        this.router.navigate(['/cms/content/questions']);
      });
  }

  confirmSave(changes) {
    this.dialog
      .confirm({
        title: '',
        text: this.translator.instant('common.message.confirmEdit'),
        buttons: [
          { text: this.translator.instant('common.button.abort') },
          {
            color: 'primary',
            text: this.translator.instant('common.button.save'),
            value: true,
          },
        ],
      })
      .subscribe((val) => {
        if (!val) {
          this.loadQuestion(changes);
        } else {
          this.hasChange = false;
          this.onSubmit();
        }
      });
  }

  toggleSidebar(show: boolean) {
    const sidebar = document.getElementsByClassName('qa-sidebar-inner');
    if (sidebar && sidebar.length) {
      const sidenav = sidebar[0] as HTMLElement;
      sidenav.style.display = show ? 'flex' : 'none';
    }
  }

  async ngOnChanges(changes: SimpleChanges) {
    if (changes && changes.questionId.currentValue) {
      if (this.hasChange) {
        await this.confirmSave(changes);
      } else {
        this.loadQuestion(changes);
      }
    }
  }

  loadQuestion(changes) {
    this.showForm = false;
    this.showMobilePreview = false;
    if (this.formValueChanges) {
      this.formValueChanges.unsubscribe();
    }
    this.questionId = changes.questionId.currentValue;
    this.id = changes.questionId.currentValue;
    this.getAllQuestions(this.id);
  }

  setQuestionEnums() {
    if (this.parentQuizId || this.liveEventId || this.isLiveEvent) {
      this.questionTypeEnum = QuizQuestionType;
    } else {
      this.questionTypeEnum = QuestionType;
    }
    if (this.isPollQuestion) {
      this.questionTypeEnum = PollQuestionType;
    }
    const qTypes = getQuestionTypeOptions(this.questionTypeEnum);
    const questionsTypes = [];
    qTypes.forEach((q) => {
      questionsTypes.push({
        name: questionTypeDisplayFn(q.label),
        value: q.value,
      });
    });
    this.questionsTypes = questionsTypes;
    this.setTemplates();
  }

  setTemplates() {
    if (this.isPollQuestion) {
      this.templates = pollTemplates.map((t) => {
        t.template['default'] = t.template;
        return t;
      });
    } else {
      this.templates = [
        {
          title: 'Single Choice',
          template: SCTemplate,
        },
        {
          title: 'Multiple Choice',
          template: MCTemplate,
        },
        {
          title: 'True / False',
          template: TFTemplate,
        },
      ];
    }
  }

  duplicatedQuestion() {
    if (this.question) {
      const obj = { questionId: this.question.id.toString() };
      if (this.isPollQuestion) {
        obj['isPoll'] = true;
      }
      if (this.courseId) {
        obj['course'] = this.courseId;
      }
      if (this.parentQuizId) {
        obj['quiz'] = this.parentQuizId;
      }
      if (this.parentExamId) {
        obj['exam'] = this.parentExamId;
      }
      if (this.liveEventId) {
        obj['liveEventId'] = this.liveEventId;
      }
      this.router.navigate(['/cms/add/question'], { queryParams: obj });
    }
  }

  getFlashCard(id) {
    this.flashCards
      .getFlashCardById({
        id,
      })
      .subscribe((flashCard: FlashCardModel) => {
        this.convertQuestion(flashCard);
      });
  }

  convertToFlashcard() {
    this.router.navigate(['/cms/add/flash-card'], {
      queryParams: { questionId: this.question.id.toString() },
    });
  }

  convertQuestion(card: FlashCardModel) {
    const question: QuestionModel = {
      text: card.text,
      media: card.media ? card.media : null,
      tags: card.tags ? card.tags : null,
      explanation: card.explanation ? card.explanation : null,
      weblink: card.weblink ? card.weblink : null,
      source: card.source ? card.source : null,
      answers: [],
      type: null,
    };
    for (const propName in question) {
      if (question[propName] === null || question[propName] === undefined) {
        delete question[propName];
      }
    }
    if (card.answer) {
      const answers = card.answer.split(/\r?\*/);
      if (answers.length > 1 && card.answer.charAt(0) === '*') {
        question.type = QuestionType.MULTIPLE_CHOICE;
        answers.forEach((answer) => {
          if (answer) {
            question.answers.push({
              text: answer.trim(),
              is_right: true,
              question_id: null,
              num_selected: null,
            });
          }
        });
        generatingQuestionForm(
          question,
          this.form,
          this.radioRightAnswerControl,
          this.radioValueChanges,
          this.typeValueChanges
        );
        setTimeout(() => {
          this.showForm = true;
          this.loading = false;
          this.form.markAsDirty();
        }, 200);
      } else {
        question.type = QuestionType.SINGLE_CHOICE;
        question.answers = [
          {
            text: card.answer,
            is_right: true,
            question_id: null,
            num_selected: null,
          },
          {
            text: this.translator.instant('question.label.wrongAnswer'),
            is_right: false,
            question_id: null,
            num_selected: null,
          },
        ];

        generatingQuestionForm(
          question,
          this.form,
          this.radioRightAnswerControl,
          this.radioValueChanges,
          this.typeValueChanges
        );
        setTimeout(() => {
          this.showForm = true;
          this.loading = false;
          this.form.markAsDirty();
        }, 200);
      }
    }
  }

  getAllQuestions(questionId: number) {
    if (this.parentQuizId || this.parentExamId) {
      this.loading = true;
      let getQuestions$: Observable<QuestionModel[]>;
      if (this.parentQuizId) {
        getQuestions$ = this.quizzes.getQuestionsForQuiz({
          id: this.parentQuizId,
        });
      } else if (this.parentExamId) {
        getQuestions$ = this.exams.getQuestionsForExam({
          id: this.parentExamId,
        });
      }
      getQuestions$.subscribe(
        (response) => {
          this.allQuestions = response;
          const idx = this.allQuestions.findIndex(
            (question) => question.id === Number(questionId)
          );
          this.currentItemIndex = idx > -1 ? idx : 0;
          this.questions
            .getQuestionById({ id: questionId })
            .subscribe((question: QuestionModel) => {
              if (this.duplicateQuestionId) {
                delete question.id;
              }
              this.question = question;
              this.isPollQuestion = question.is_poll_question;
              this.setQuestionEnums();
              generatingQuestionForm(
                this.question,
                this.form,
                this.radioRightAnswerControl,
                this.radioValueChanges,
                this.typeValueChanges
              );
              if (question.type === QuestionType.FREETEXT) {
                if (question.answers?.length) {
                  this.form
                    .get('sample_answer')
                    .setValue(question.answers[0].text);
                }
              }
              setTimeout(() => {
                this.showForm = true;
                this.loading = false;
                this.hasChange = false;
                if (this.duplicateQuestionId) {
                  this.form.markAsDirty();
                }
                this.onCreateGroupFormValueChange();
              }, 200);
            });
        },
        () => (this.loading = false)
      );
    } else {
      this.loading = true;
      this.questions.getQuestionById({ id: questionId }).subscribe(
        (question: QuestionModel) => {
          if (this.duplicateQuestionId) {
            delete question.id;
          }
          this.question = question;
          this.isPollQuestion = question.is_poll_question;
          this.setQuestionEnums();
          generatingQuestionForm(
            this.question,
            this.form,
            this.radioRightAnswerControl,
            this.radioValueChanges,
            this.typeValueChanges
          );
          if (question.type === QuestionType.FREETEXT) {
            if (question.answers?.length) {
              this.form.get('sample_answer').setValue(question.answers[0].text);
            }
          }
          setTimeout(() => {
            this.showForm = true;
            this.loading = false;
            if (this.duplicateQuestionId) {
              this.form.markAsDirty();
            }
          }, 100);
        },
        () => (this.loading = false)
      );
    }
  }

  get rightAnswerIndex() {
    return this.form.value.answers.findIndex(
      (answer) => answer.is_right === true
    );
  }

  onChangeRadio(event) {
    if (
      this.form.value.type === QuestionType.SINGLE_CHOICE &&
      (event.value || event.value === 0)
    ) {
      const radioControls = (this.form.get('answers') as UntypedFormArray)
        .controls;
      radioControls.forEach((control, i) => {
        control.get('is_right').setValue(event.value === i);
      });
      this.form.markAsDirty();
    }
  }

  getQuestionInfo() {
    const requestArray = [];
    if (this.courseId) {
      requestArray.push(
        this.coursesService.getCourseById({ id: this.courseId, full: false })
      );
    }
    if (this.bookId) {
      requestArray.push(
        this.booksService.getBookById({ id: this.bookId, full: false })
      );
    }
    if (this.parentExamId && !isNaN(this.parentExamId)) {
      requestArray.push(this.exams.getExamById({ id: this.parentExamId }));
    }
    if (this.parentQuizId) {
      requestArray.push(this.quizzes.getQuizById({ id: this.parentQuizId }));
    }
    forkJoin(requestArray).subscribe((results) => {
      if (results && results.length) {
        const course = results.find((result) => result.type === 'course');
        if (course) {
          this.courseData = course;
        }
      }
    });
  }

  ngOnDestroy() {
    this.toggleSidebar(true);
    if (this.typeValueChanges) this.typeValueChanges.unsubscribe();
    if (this.radioValueChanges) this.radioValueChanges.unsubscribe();
  }

  onAddAnswer(): void {
    const answer = new UntypedFormGroup({
      is_right: new UntypedFormControl(),
      text: new UntypedFormControl(null, [Validators.required]),
    });
    (this.form.get('answers') as UntypedFormArray).push(answer);
    this.form.get('answers').markAsDirty();
    this.form.get('answers').markAsTouched();
  }

  onRemoveAnswer(index: number): void {
    const length = this.form.get('answers')['controls'].length;
    if (length <= 2) {
      this.matSnackBar.open(
        this.translator.instant('content.question.deleteItem.minimumAnswer')
      );
      return;
    }
    (this.form.get('answers') as UntypedFormArray).removeAt(index);
    this.form.get('answers').markAsDirty();
    this.form.get('answers').markAsTouched();
  }

  onDelete(): void {
    this.dialog
      .confirmDelete()
      .pipe(filter((isConfirmed) => isConfirmed))
      .subscribe(() => {
        this.loading = true;
        const questionId = this.form.value.id as number;
        this.questions.deleteQuestion({ id: questionId }).subscribe({
          next: () => {
            this.form.markAsUntouched();
            this.matSnackBar.open(
              this.translator.instant('content.question.deleteItem.success')
            );
            this.onClose(true);
          },
          error: () => {
            this.loading = false;
            this.matSnackBar.open(
              this.translator.instant('content.question.deleteItem.error')
            );
          },
        });
      });
  }

  checkForSampleAnswer() {
    this.matDialog
      .open<ShowInfoDialogComponent, ShowInfoItem>(ShowInfoDialogComponent, {
        data: {
          infoData: this.translator.instant('question.form.sampleAnswer'),
          closeButton: 'question.form.tagHint.understood',
        },
        width: '576px',
        height: 'auto',
        autoFocus: false,
        panelClass: 'tooltip-modal',
      })
      .afterClosed()
      .subscribe((r) => {
        if (r) {
          localStorage.setItem('sample-answer-log', 'true');
        }
      });
  }

  onSubmit(createAnother = false, makeDuplicate?: boolean): void {
    const showDialog = localStorage.getItem('sample-answer-log');
    if (
      !showDialog &&
      this.form.get('type').value === QuestionType.FREETEXT &&
      !this.form.get('sample_answer').value
    ) {
      this.checkForSampleAnswer();
      return;
    }
    this.loading = true;
    const newQuestion: QuestionModel = this.form.getRawValue();
    if (newQuestion.media && !newQuestion.media.id) {
      newQuestion.media = null;
    }
    if (newQuestion.type === QuestionType.TRUE_FALSE) {
      delete newQuestion.answers;
      newQuestion.is_right = !!newQuestion.is_right;
    } else if (newQuestion.type === QuestionType.FREETEXT) {
      delete newQuestion.answers;
      delete newQuestion.is_right;
      if (!this.isPollQuestion) {
        if (this.form.get('sample_answer').value) {
          newQuestion.answers = [
            {
              text: this.form.get('sample_answer').value,
              is_right: true,
            } as AnswerModel,
          ];
        }
      }
      newQuestion.max_score = Number(newQuestion.max_score);
      if (newQuestion.max_num_characters !== null) {
        newQuestion.max_num_characters = Number(newQuestion.max_num_characters);
      }
      if (
        newQuestion.max_answer_submissions &&
        Number(newQuestion.max_answer_submissions)
      ) {
        newQuestion.max_answer_submissions = Number(
          newQuestion.max_answer_submissions
        );
      } else {
        newQuestion.max_answer_submissions = null;
      }
      delete newQuestion['sample_answer'];
    } else {
      newQuestion.answers = newQuestion.answers.map((answer) => {
        answer.is_right = Boolean(answer.is_right);
        return answer;
      });
    }
    let saveQuestion$: Observable<QuestionModel>;
    if (newQuestion.id && this.userId === this.question.created_by) {
      newQuestion.is_poll_question = !!this.isPollQuestion;
      saveQuestion$ = this.questions.updateQuestion({
        id: newQuestion.id,
        body: newQuestion,
      });
    } else if (
      this.parentExamId &&
      !(this.courseData && this.courseData.ref_course_id && newQuestion.id)
    ) {
      delete newQuestion.id;
      saveQuestion$ = this.exams
        .copyQuestionToExam({
          id: this.parentExamId,
          body: [newQuestion],
        })
        .pipe(
          map((questions) => {
            sortBy(questions, 'id');
            return questions[questions.length - 1];
          })
        );
    } else if (
      this.parentQuizId &&
      !(this.courseData && this.courseData.ref_course_id && newQuestion.id)
    ) {
      delete newQuestion.id;
      saveQuestion$ = this.quizzes
        .copyQuestionToQuiz({
          id: this.parentQuizId,
          body: [newQuestion],
        })
        .pipe(
          map((questions) => {
            sortBy(questions, 'id');
            return questions[questions.length - 1];
          })
        );
    } else if (this.liveEventId) {
      newQuestion.is_poll_question = !!this.isPollQuestion;
      saveQuestion$ = this.liveEventService
        .copyQuestionToEvent({ id: this.liveEventId, body: [newQuestion] })
        .pipe(
          map((questions) => {
            sortBy(questions, 'id');
            return questions[questions.length - 1];
          })
        );
    } else {
      newQuestion.is_poll_question = !!this.isPollQuestion;
      saveQuestion$ = this.questions.createQuestion({ body: newQuestion });
    }
    saveQuestion$.subscribe({
      next: (question) => {
        this.hasChange = false;
        this.form.markAsUntouched();
        this.form.markAsPristine();
        if (
          newQuestion.id &&
          !(this.courseData && this.courseData.ref_course_id)
        ) {
          this.matSnackBar.open(
            this.translator.instant('question.message.edit')
          );
          this.updatedQuestion.emit(question);
          if (this.liveEventId) {
            this.onClose();
          }
        } else {
          if (!this.duplicateQuestionId) {
            this.matSnackBar.open(
              this.translator.instant('question.message.create')
            );
          }
          if (createAnother) {
            this.question = undefined;
            this.showForm = false;
            setTimeout(() => {
              this.ngOnInit();
              this.loading = false;
            }, 10);
            return;
          } else if (!makeDuplicate) {
            if (this.duplicateQuestionId) {
              this.openDuplicateSuccessfulDialog();
            } else if (this.flashCardId) {
              this.openConvertedQuestionDialog();
            } else {
              this.onClose();
            }
          }
        }
        this.loading = false;
      },
      error: () => {
        this.loading = false;
        this.matSnackBar.open(
          this.translator.instant('question.message.editFail')
        );
      },
    });
  }

  onNavigate(questionIndex: number) {
    this.showForm = false;
    if (!this.allQuestions) {
      return;
    }
    if (questionIndex < this.allQuestions.length) {
      this.question = this.allQuestions[questionIndex];
      if (this.parentQuizId) {
        if (this.courseId) {
          this.router.navigate(['/cms/edit/question', this.question.id], {
            queryParams: { course: this.courseId, quiz: this.parentQuizId },
          });
        } else if (this.bookId) {
          this.router.navigate(['/cms/edit/question', this.question.id], {
            queryParams: { book: this.bookId, quiz: this.parentQuizId },
          });
        }
      } else {
        if (this.courseId) {
          this.router.navigate(['/cms/edit/question', this.question.id], {
            queryParams: { course: this.courseId, exam: this.parentExamId },
          });
        } else if (this.bookId) {
          this.router.navigate(['/cms/edit/question', this.question.id], {
            queryParams: { book: this.bookId, exam: this.parentExamId },
          });
        } else if (this.questionIdFromContent) {
          this.router.navigate(['/cms/edit/question', this.question.id], {
            queryParams: { contentQuestionId: this.question.id },
          });
        }
      }
      generatingQuestionForm(
        this.question,
        this.form,
        this.radioRightAnswerControl,
        this.radioValueChanges,
        this.typeValueChanges
      );
    }
  }

  getTypeVal(index) {
    this.form.get('type').patchValue(index);
    this.form.markAsDirty();
  }
  unlinkQuestion() {
    this.matDialog
      .open(CancelConfirmationComponent, {
        autoFocus: false,
        data: {
          messages: [
            this.translator.instant('question.form.unlinkSaveDialog.message1'),
            this.translator.instant('question.form.unlinkSaveDialog.message2'),
          ],
          buttonLabels: {
            confirm: this.translator.instant(
              'question.form.unlinkSaveDialog.copyQuestion'
            ),
            cancel: this.translator.instant('common.button.abort'),
          },
        },
      })
      .afterClosed()
      .subscribe((isConfirmed) => {
        if (isConfirmed) {
          this.onSubmit();
        }
      });
  }

  redirectBasedOnParent(segment, param, id) {
    if (this.parentQuizId) {
      this.router.navigate([
        `/cms/${segment}/edit/${id}`,
        { [param]: id, quiz: this.parentQuizId },
      ]);
    } else if (this.liveEventId) {
      this.router.navigate([`/cms/live-events/${this.liveEventId}`], {
        queryParams: { courseId: id },
      });
    } else {
      this.router.navigate([
        `/cms/${segment}/edit/${id}`,
        { [param]: id, exam: this.parentExamId },
      ]);
    }
    return;
  }

  onClose(removeQuestionFromList = false) {
    if (this.questionId) {
      this.closeSideMenu.emit(removeQuestionFromList);
      return;
    }
    if (this.courseId) {
      this.redirectBasedOnParent('courses', 'course', this.courseId);
    } else if (this.bookId) {
      this.redirectBasedOnParent('books', 'book', this.bookId);
    } else if (this.liveEventId) {
      this.router.navigate(['/cms/live-events', this.liveEventId]);
    } else if (
      this.flashCardId ||
      this.duplicateQuestionId ||
      this.liveEventId
    ) {
      this.location.back();
    } else if (this.parentQuizId) {
      this.router.navigateByUrl(`/cms/content/${this.parentQuizId}/questions`);
    } else if (this.commentId) {
      this.router.navigate(['/cms/content/comments']);
    } else if (this.parentExamId) {
      if (this.route.snapshot.queryParams?.from === 'play') {
        this.router.navigateByUrl(`/cms/exams/${this.parentExamId}`);
      } else {
        this.router.navigateByUrl(`/cms/exam/${this.parentExamId}/questions`);
      }
    } else {
      const query = { showPollQuestion: true };
      this.router.navigate(['/cms/content/questions'], {
        queryParams: this.isPollQuestion ? query : {},
      });
    }
  }

  explanationSuggestion() {
    if (this.form.value.type === this.questionTypeEnum.TRUE_FALSE) {
      return `**Diese Aussage ist ${
        this.form.get('is_right').value
          ? ' richtig.'
          : ' falsch. Die richtige Aussage lautet:'
      }**\n${
        this.form.get('is_right').value
          ? this.form.get('text').value
          : '[Hier bitte die richtige Aussage eintragen. **Empfehlung**:' +
            ' Die wichtigen Stellen der richtigen Aussage können hier mit **fettem Text** hervorgehoben werden.]'
      }\n\n**Weiterführende Informationen:**\n[Hier bitte weiterführende Informationen zu der Frage oder zu dem Thema hinzufügen.]`;
    } else if (this.form.value.type === this.questionTypeEnum.MULTIPLE_CHOICE) {
      let allcorransw = this.form
        .get('answers')
        .value.filter((val) => val.is_right === true);
      allcorransw = allcorransw.map((answ) => answ.text);
      return (
        `**Korrekte Antwort(en):**\n${allcorransw
          .map((ans) => `* ${ans}`)
          .join('\n')}\n\n**Weiterführende Informationen:**\n` +
        `[Hier bitte weiterführende Informationen zu der Frage oder zu dem Thema hinzufügen.]`
      );
    } else if (this.form.value.type === this.questionTypeEnum.SINGLE_CHOICE) {
      return (
        `**Korrekte Antwort:**\n* ${
          this.form.get('answers').value[this.rightAnswerIndex]
            ? this.form.get('answers').value[this.rightAnswerIndex].text
            : ''
        }\n\n**Weiterführende Informationen:**\n` +
        `[Hier bitte weiterführende Informationen zu der Frage oder zu dem Thema hinzufügen.]`
      );
    }
    return '';
  }

  editImagePreUpload(img: File): Promise<File> {
    const matchArr = img.name.match(/^(.*)\.(?:.+)$/);
    return new Promise<File>((resolve) => {
      const dialogRef = this.matDialog.open(ImageEditDialogComponent, {
        panelClass: 'edit-image-dialog-panel',
        data: {
          file: img,
          name: matchArr ? matchArr[1] : img.name,
        },
      });
      dialogRef.afterClosed().subscribe((img) => {
        resolve(img);
      });
    });
  }

  applyTemplate(template: any): void {
    this.showForm = false;
    // Reset answer form array
    const formArrayAnswers = this.form.get('answers') as UntypedFormArray;
    formArrayAnswers.clear();
    for (
      let eachAnswerControl = 0;
      eachAnswerControl < template.default.answers.length;
      eachAnswerControl++
    ) {
      const answerFormGroup = new UntypedFormGroup({
        is_right: new UntypedFormControl(false),
        text: new UntypedFormControl(),
        id: new UntypedFormControl(),
      });
      (this.form.get('answers') as UntypedFormArray).push(answerFormGroup);
    }
    this.form.patchValue(template.default);
    template.default.answers.forEach(
      (answer, index) =>
        (this.form.value.answers[index].is_right = answer.is_right)
    );
    window.setTimeout(() => {
      this.showForm = true;
      this.form.markAsDirty();
    }, 0);
  }

  openDialog() {
    const message = this.isPollQuestion
      ? 'question.form.fixAnswerTooltip.poll'
      : 'question.form.fixAnswerTooltip.quiz';
    this.matDialog.open<ShowInfoDialogComponent, ShowInfoItem>(
      ShowInfoDialogComponent,
      {
        data: {
          infoData: this.translator.instant(message),
        },
        width: '576px',
        height: 'auto',
        panelClass: 'tooltip-modal',
      }
    );
  }
}
