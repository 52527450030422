import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { environment } from 'src/environments/environment';

export interface Edition {
  name: string;
  baseUrl: string;
  icon: string;
  id: number;
}

@Component({
  selector: 'qa-edition-selector',
  templateUrl: './edition-selector.component.html',
  styleUrls: ['./edition-selector.component.scss'],
})
export class EditionSelectorComponent implements OnChanges {
  @Input() editionName: string;
  @Output() editionNameChange = new EventEmitter<string>();

  @Output() editionSelect = new EventEmitter<Edition>();

  allEditions: Edition[] = [
    {
      name: 'go',
      baseUrl: 'https://go.quizacademy.io',
      icon: 'work',
      id: 3,
    },
    {
      name: 'school',
      baseUrl: 'https://school.quizacademy.io',
      icon: 'square_foot',
      id: 1,
    },
    {
      name: 'university',
      baseUrl: 'https://university.quizacademy.io',
      icon: 'school',
      id: 2,
    },
  ];

  currentEdition: string = undefined;

  constructor(elementRef: ElementRef) {
    elementRef.nativeElement.classList.add('qa-register');
    this.currentEdition = this.allEditions.find(
      (e) => e.name === environment.edition
    )?.name;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.editionName && !!this.editionName?.length) {
      const ed = this.allEditions.find((e) => e.name === this.editionName);
      this.currentEdition = ed?.name;
      this.editionSelect.emit(ed);
    }
  }

  selectEdition(edition: Edition) {
    this.currentEdition = edition.name;
    this.editionSelect.emit(edition);
    this.editionName = edition.name;
    this.editionNameChange.emit(this.editionName);
  }
}
