<div class="card-statistics" *ngIf="courseId">
  <div class="card-statistics-container">
    <div class="datepicker date-picker-container">
      <qa-button
        (submitted)="downloadExcel()"
        label="content.flashCardStack.cardStatistics.downloadAsExcel"
      ></qa-button>
      <qa-custom-range-picker> </qa-custom-range-picker>
    </div>
    <div [class.faded]="loading" class="chart-container">
      <qa-chart
        [data]="chartData"
        [options]="chartOptions"
        *ngIf="chartData"
        chartType="line"
        #chart
      >
      </qa-chart>
      <div class="entity-stats">
        <div class="quiz-stat-container">
          <div class="card-statistics-title">
            {{
              'content.flashCardStack.cardStatistics.cardStatisticTitle'
                | translate
            }}
          </div>
          <div class="card-statistics-table">
            <div class="table">
              <div class="tab-header list">
                <div class="header-item">{{ 'Nr.' | translate }}</div>
                <div class="header-item">
                  {{ 'content.flashCardStack.cardStatistics.card' | translate }}
                </div>
                <div class="header-item right-or-wrong">
                  {{ 'question.label.rightWrong' | translate }}
                </div>
                <div class="header-item"></div>
                <div class="header-item"></div>
              </div>
              <div
                class="list table-border"
                *ngFor="let stat of cardStatistics; let i = index"
              >
                <div class="cell-item">{{ i + 1 }}</div>
                <div class="cell-item">{{ stat?.card?.text }}</div>
                <div
                  class="cell-item right-or-wrong flex-row flex-justify-content-space-between flex-align-items-center"
                >
                  <div class="bar">
                    <div
                      [style.width]="getCorrectRatio(stat)"
                      class="right-ans"
                    ></div>
                  </div>
                </div>
                <div class="cell-item">
                  {{ stat.num_correct }}/{{ stat.num_answers }}
                </div>
                <div class="cell-item">
                  {{ getCorrectRatio(stat) }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
