<mat-form-field
  [floatLabel]="!label ? 'never' : true"
  appearance="outline"
  subscriptSizing="dynamic"
  [class.no-label]="!label"
>
  <mat-label>
    {{ label }}
  </mat-label>
  <mat-select
    (selectionChange)="onChange($event)"
    [compareWith]="compareFn"
    [formControl]="displayControl"
    [required]="required"
    *ngIf="!multiple; else multipleBlock"
  >
    <mat-option
      [value]="option"
      [matTooltip]="option?.tooltip ? option.tooltip : null"
      matTooltipClass="edit-tooltip"
      matTooltipPosition="right"
      *ngFor="let option of options"
    >
      {{ displayFn(option) | translate }}
    </mat-option>
  </mat-select>
  <ng-template #multipleBlock>
    <mat-label>
      {{ label }}
    </mat-label>
    <mat-select
      [compareWith]="compareFn"
      [formControl]="displayControl"
      [required]="required"
      multiple
    >
      <mat-option [value]="option" *ngFor="let option of options">
        {{ displayFn(option) }}
      </mat-option>
    </mat-select>
  </ng-template>
  <ng-container *ngIf="control">
    <mat-error *ngFor="let errorKey of objectKeys(control.errors || {})">
      {{ errorDisplayFunction(errorKey) | translate }}
    </mat-error>
  </ng-container>
</mat-form-field>
