/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { BaseQueryResponse } from '../models/base-query-response';
import { FlashCardModel } from '../models/flash-card-model';
import { FlashCardStackModel } from '../models/flash-card-stack-model';
import { FlashCardStackQuery } from '../models/flash-card-stack-query';
import { PositionChangeModel } from '../models/position-change-model';
import { StackStatistics } from '../models/stack-statistics';

@Injectable({
  providedIn: 'root',
})
export class FlashCardStacksService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation createFlashCardStack
   */
  static readonly CreateFlashCardStackPath = '/flash_card_stacks';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createFlashCardStack()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createFlashCardStack$Response(params: {
    cognitouserid?: string;
    cognitousergroup?: string;
    context?: HttpContext
    body: FlashCardStackModel
  }
): Observable<StrictHttpResponse<FlashCardStackModel>> {

    const rb = new RequestBuilder(this.rootUrl, FlashCardStacksService.CreateFlashCardStackPath, 'post');
    if (params) {
      rb.header('cognitouserid', params.cognitouserid, {});
      rb.header('cognitousergroup', params.cognitousergroup, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<FlashCardStackModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `createFlashCardStack$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createFlashCardStack(params: {
    cognitouserid?: string;
    cognitousergroup?: string;
    context?: HttpContext
    body: FlashCardStackModel
  }
): Observable<FlashCardStackModel> {

    return this.createFlashCardStack$Response(params).pipe(
      map((r: StrictHttpResponse<FlashCardStackModel>) => r.body as FlashCardStackModel)
    );
  }

  /**
   * Path part for operation queryFlashCardStack
   */
  static readonly QueryFlashCardStackPath = '/flash_card_stacks/query';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `queryFlashCardStack()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  queryFlashCardStack$Response(params: {
    cognitouserid?: string;
    cognitousergroup?: string;
    context?: HttpContext
    body: FlashCardStackQuery
  }
): Observable<StrictHttpResponse<BaseQueryResponse>> {

    const rb = new RequestBuilder(this.rootUrl, FlashCardStacksService.QueryFlashCardStackPath, 'post');
    if (params) {
      rb.header('cognitouserid', params.cognitouserid, {});
      rb.header('cognitousergroup', params.cognitousergroup, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BaseQueryResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `queryFlashCardStack$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  queryFlashCardStack(params: {
    cognitouserid?: string;
    cognitousergroup?: string;
    context?: HttpContext
    body: FlashCardStackQuery
  }
): Observable<BaseQueryResponse> {

    return this.queryFlashCardStack$Response(params).pipe(
      map((r: StrictHttpResponse<BaseQueryResponse>) => r.body as BaseQueryResponse)
    );
  }

  /**
   * Path part for operation getFlashCardStackById
   */
  static readonly GetFlashCardStackByIdPath = '/flash_card_stacks/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getFlashCardStackById()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFlashCardStackById$Response(params: {
    cognitouserid?: string;
    cognitousergroup?: string;
    id: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<FlashCardStackModel>> {

    const rb = new RequestBuilder(this.rootUrl, FlashCardStacksService.GetFlashCardStackByIdPath, 'get');
    if (params) {
      rb.header('cognitouserid', params.cognitouserid, {});
      rb.header('cognitousergroup', params.cognitousergroup, {});
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<FlashCardStackModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getFlashCardStackById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFlashCardStackById(params: {
    cognitouserid?: string;
    cognitousergroup?: string;
    id: number;
    context?: HttpContext
  }
): Observable<FlashCardStackModel> {

    return this.getFlashCardStackById$Response(params).pipe(
      map((r: StrictHttpResponse<FlashCardStackModel>) => r.body as FlashCardStackModel)
    );
  }

  /**
   * Path part for operation updateFlashCardStack
   */
  static readonly UpdateFlashCardStackPath = '/flash_card_stacks/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateFlashCardStack()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateFlashCardStack$Response(params: {
    cognitouserid?: string;
    cognitousergroup?: string;
    id: number;
    context?: HttpContext
    body: FlashCardStackModel
  }
): Observable<StrictHttpResponse<FlashCardStackModel>> {

    const rb = new RequestBuilder(this.rootUrl, FlashCardStacksService.UpdateFlashCardStackPath, 'put');
    if (params) {
      rb.header('cognitouserid', params.cognitouserid, {});
      rb.header('cognitousergroup', params.cognitousergroup, {});
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<FlashCardStackModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateFlashCardStack$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateFlashCardStack(params: {
    cognitouserid?: string;
    cognitousergroup?: string;
    id: number;
    context?: HttpContext
    body: FlashCardStackModel
  }
): Observable<FlashCardStackModel> {

    return this.updateFlashCardStack$Response(params).pipe(
      map((r: StrictHttpResponse<FlashCardStackModel>) => r.body as FlashCardStackModel)
    );
  }

  /**
   * Path part for operation deleteFlashCardStack
   */
  static readonly DeleteFlashCardStackPath = '/flash_card_stacks/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteFlashCardStack()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteFlashCardStack$Response(params: {
    cognitouserid?: string;
    cognitousergroup?: string;
    id: number;
    delete_cards?: boolean;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<FlashCardStackModel>> {

    const rb = new RequestBuilder(this.rootUrl, FlashCardStacksService.DeleteFlashCardStackPath, 'delete');
    if (params) {
      rb.header('cognitouserid', params.cognitouserid, {});
      rb.header('cognitousergroup', params.cognitousergroup, {});
      rb.path('id', params.id, {});
      rb.query('delete_cards', params.delete_cards, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<FlashCardStackModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteFlashCardStack$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteFlashCardStack(params: {
    cognitouserid?: string;
    cognitousergroup?: string;
    id: number;
    delete_cards?: boolean;
    context?: HttpContext
  }
): Observable<FlashCardStackModel> {

    return this.deleteFlashCardStack$Response(params).pipe(
      map((r: StrictHttpResponse<FlashCardStackModel>) => r.body as FlashCardStackModel)
    );
  }

  /**
   * Path part for operation getStackStatistics
   */
  static readonly GetStackStatisticsPath = '/flash_card_stacks/{id}/stats';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getStackStatistics()` instead.
   *
   * This method doesn't expect any request body.
   */
  getStackStatistics$Response(params: {
    cognitouserid?: string;
    cognitousergroup?: string;
    id: number;
    start?: string;
    end?: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<StackStatistics>> {

    const rb = new RequestBuilder(this.rootUrl, FlashCardStacksService.GetStackStatisticsPath, 'get');
    if (params) {
      rb.header('cognitouserid', params.cognitouserid, {});
      rb.header('cognitousergroup', params.cognitousergroup, {});
      rb.path('id', params.id, {});
      rb.query('start', params.start, {});
      rb.query('end', params.end, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StackStatistics>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getStackStatistics$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getStackStatistics(params: {
    cognitouserid?: string;
    cognitousergroup?: string;
    id: number;
    start?: string;
    end?: string;
    context?: HttpContext
  }
): Observable<StackStatistics> {

    return this.getStackStatistics$Response(params).pipe(
      map((r: StrictHttpResponse<StackStatistics>) => r.body as StackStatistics)
    );
  }

  /**
   * Path part for operation getCardsInFlashCardStack
   */
  static readonly GetCardsInFlashCardStackPath = '/flash_card_stacks/{id}/cards';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCardsInFlashCardStack()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCardsInFlashCardStack$Response(params: {
    cognitouserid?: string;
    cognitousergroup?: string;
    id: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<FlashCardModel>>> {

    const rb = new RequestBuilder(this.rootUrl, FlashCardStacksService.GetCardsInFlashCardStackPath, 'get');
    if (params) {
      rb.header('cognitouserid', params.cognitouserid, {});
      rb.header('cognitousergroup', params.cognitousergroup, {});
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<FlashCardModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getCardsInFlashCardStack$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCardsInFlashCardStack(params: {
    cognitouserid?: string;
    cognitousergroup?: string;
    id: number;
    context?: HttpContext
  }
): Observable<Array<FlashCardModel>> {

    return this.getCardsInFlashCardStack$Response(params).pipe(
      map((r: StrictHttpResponse<Array<FlashCardModel>>) => r.body as Array<FlashCardModel>)
    );
  }

  /**
   * Path part for operation linkCardsToFlashCardStack
   */
  static readonly LinkCardsToFlashCardStackPath = '/flash_card_stacks/{id}/cards';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `linkCardsToFlashCardStack()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  linkCardsToFlashCardStack$Response(params: {
    cognitouserid?: string;
    cognitousergroup?: string;
    id: number;
    context?: HttpContext
    body: Array<FlashCardModel>
  }
): Observable<StrictHttpResponse<Array<FlashCardModel>>> {

    const rb = new RequestBuilder(this.rootUrl, FlashCardStacksService.LinkCardsToFlashCardStackPath, 'put');
    if (params) {
      rb.header('cognitouserid', params.cognitouserid, {});
      rb.header('cognitousergroup', params.cognitousergroup, {});
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<FlashCardModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `linkCardsToFlashCardStack$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  linkCardsToFlashCardStack(params: {
    cognitouserid?: string;
    cognitousergroup?: string;
    id: number;
    context?: HttpContext
    body: Array<FlashCardModel>
  }
): Observable<Array<FlashCardModel>> {

    return this.linkCardsToFlashCardStack$Response(params).pipe(
      map((r: StrictHttpResponse<Array<FlashCardModel>>) => r.body as Array<FlashCardModel>)
    );
  }

  /**
   * Path part for operation copyCardsToFlashCardStack
   */
  static readonly CopyCardsToFlashCardStackPath = '/flash_card_stacks/{id}/cards';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `copyCardsToFlashCardStack()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  copyCardsToFlashCardStack$Response(params: {
    cognitouserid?: string;
    cognitousergroup?: string;
    id: number;
    context?: HttpContext
    body: Array<FlashCardModel>
  }
): Observable<StrictHttpResponse<Array<FlashCardModel>>> {

    const rb = new RequestBuilder(this.rootUrl, FlashCardStacksService.CopyCardsToFlashCardStackPath, 'post');
    if (params) {
      rb.header('cognitouserid', params.cognitouserid, {});
      rb.header('cognitousergroup', params.cognitousergroup, {});
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<FlashCardModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `copyCardsToFlashCardStack$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  copyCardsToFlashCardStack(params: {
    cognitouserid?: string;
    cognitousergroup?: string;
    id: number;
    context?: HttpContext
    body: Array<FlashCardModel>
  }
): Observable<Array<FlashCardModel>> {

    return this.copyCardsToFlashCardStack$Response(params).pipe(
      map((r: StrictHttpResponse<Array<FlashCardModel>>) => r.body as Array<FlashCardModel>)
    );
  }

  /**
   * Path part for operation removeCardsFromFlashCardStack
   */
  static readonly RemoveCardsFromFlashCardStackPath = '/flash_card_stacks/{id}/cards';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `removeCardsFromFlashCardStack()` instead.
   *
   * This method doesn't expect any request body.
   */
  removeCardsFromFlashCardStack$Response(params: {
    cognitouserid?: string;
    cognitousergroup?: string;
    id: number;
    card_ids?: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, FlashCardStacksService.RemoveCardsFromFlashCardStackPath, 'delete');
    if (params) {
      rb.header('cognitouserid', params.cognitouserid, {});
      rb.header('cognitousergroup', params.cognitousergroup, {});
      rb.path('id', params.id, {});
      rb.query('card_ids', params.card_ids, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `removeCardsFromFlashCardStack$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  removeCardsFromFlashCardStack(params: {
    cognitouserid?: string;
    cognitousergroup?: string;
    id: number;
    card_ids?: string;
    context?: HttpContext
  }
): Observable<void> {

    return this.removeCardsFromFlashCardStack$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation getCardInFlashCardStack
   */
  static readonly GetCardInFlashCardStackPath = '/flash_card_stacks/{id}/cards/{card_id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCardInFlashCardStack()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCardInFlashCardStack$Response(params: {
    cognitouserid?: string;
    cognitousergroup?: string;
    id: number;
    card_id: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<FlashCardModel>> {

    const rb = new RequestBuilder(this.rootUrl, FlashCardStacksService.GetCardInFlashCardStackPath, 'get');
    if (params) {
      rb.header('cognitouserid', params.cognitouserid, {});
      rb.header('cognitousergroup', params.cognitousergroup, {});
      rb.path('id', params.id, {});
      rb.path('card_id', params.card_id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<FlashCardModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getCardInFlashCardStack$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCardInFlashCardStack(params: {
    cognitouserid?: string;
    cognitousergroup?: string;
    id: number;
    card_id: number;
    context?: HttpContext
  }
): Observable<FlashCardModel> {

    return this.getCardInFlashCardStack$Response(params).pipe(
      map((r: StrictHttpResponse<FlashCardModel>) => r.body as FlashCardModel)
    );
  }

  /**
   * Path part for operation repositionCardsInFlashCardStack
   */
  static readonly RepositionCardsInFlashCardStackPath = '/flash_card_stacks/{id}/cards/{card_id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `repositionCardsInFlashCardStack()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  repositionCardsInFlashCardStack$Response(params: {
    cognitouserid?: string;
    cognitousergroup?: string;
    id: number;
    card_id: number;
    context?: HttpContext
    body: PositionChangeModel
  }
): Observable<StrictHttpResponse<FlashCardModel>> {

    const rb = new RequestBuilder(this.rootUrl, FlashCardStacksService.RepositionCardsInFlashCardStackPath, 'patch');
    if (params) {
      rb.header('cognitouserid', params.cognitouserid, {});
      rb.header('cognitousergroup', params.cognitousergroup, {});
      rb.path('id', params.id, {});
      rb.path('card_id', params.card_id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<FlashCardModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `repositionCardsInFlashCardStack$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  repositionCardsInFlashCardStack(params: {
    cognitouserid?: string;
    cognitousergroup?: string;
    id: number;
    card_id: number;
    context?: HttpContext
    body: PositionChangeModel
  }
): Observable<FlashCardModel> {

    return this.repositionCardsInFlashCardStack$Response(params).pipe(
      map((r: StrictHttpResponse<FlashCardModel>) => r.body as FlashCardModel)
    );
  }

}
