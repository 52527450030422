import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'qa-markdown-info-dialog',
  templateUrl: './markdown-info-dialog.component.html',
  styleUrls: ['./markdown-info-dialog.component.scss'],
})
export class MarkdownInfoDialogComponent {
  constructor(private translator: TranslateService) {}
  links = [
    {
      textKey: this.translator.instant('markdown.links.text1'),
      url: 'https://guides.github.com/features/mastering-markdown',
    },
    {
      textKey: this.translator.instant('markdown.links.text2'),
      url: 'https://www.tablesgenerator.com/markdown_tables',
    },
    {
      textKey: this.translator.instant('markdown.links.text3'),
      url: 'https://katex.org/docs/supported.html',
    },
    {
      textKey: this.translator.instant('markdown.links.text4'),
      url: 'https://www.zahlen-kern.de/editor/',
    },
    {
      textKey: this.translator.instant('markdown.links.text5'),
      url: 'https://www.deepl.com/de/translator',
    },
  ];
}
