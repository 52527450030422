import { map, switchMap } from 'rxjs';
import { LiveEvent, QuestionModel, QuizModel } from 'src/app/api/models';
import { LiveEventsService, QuizzesService } from 'src/app/api/services';

export const convertQuizToEvent = (
  quiz: QuizModel,
  quizzesService: QuizzesService,
  eventsService: LiveEventsService
) => {
  return quizzesService
    .getQuestionsForQuiz({
      id: quiz.id,
    })
    .pipe(
      switchMap((questions) =>
        eventsService
          .createLiveEvent({
            body: {
              name: quiz.name,
            } as LiveEvent,
          })
          .pipe(map((event) => ({ event, questions })))
      ),
      switchMap(({ questions, event }) => {
        return eventsService
          .linkQuestionToEvent({
            id: event.id,
            body: questions.map((q) => ({ id: q.id } as QuestionModel)),
          })
          .pipe(map((questions) => ({ event, questions })));
      })
    );
};
