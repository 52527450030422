import {
  AfterViewInit,
  Component,
  HostBinding,
  Input,
  OnChanges,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation,
  ElementRef,
  ChangeDetectorRef,
  Output,
  EventEmitter,
  OnDestroy,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatInput } from '@angular/material/input';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ActivatedRoute, Router } from '@angular/router';
import {
  DEFAULT_PAGE_SIZE,
  ListAction,
  ListColumn,
  ListConfig,
} from '../list.types';
import { ListDataSource } from '../list-data-source';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Filter, FilterService } from '../../common/filter.service';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatDialog } from '@angular/material/dialog';
import { CsvImportProgressDialogComponent } from '../../common/dialogs/csv-import-progress-dialog.component';
import { Observable, Subject, Subscription } from 'rxjs';
import {
  take,
  scan,
  debounceTime,
  switchMap,
  distinctUntilChanged,
  tap,
} from 'rxjs/operators';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import {
  HelpVideoDialogComponent,
  HelpVideoDialogData,
} from '../../common/help-video-dialog.component';
import { BehaviorSubject } from 'rxjs';
import {
  ImportContentDialogComponent,
  ImportContentDialogData,
} from '../../common/import-content-dialog/import-content-dialog.component';
import { TranslateService } from '@ngx-translate/core';
import { PanelEntity } from '../../panel/panel.types';
import { ChipType } from 'src/app/shared/components/custom-chip/custom-chip.component';
import * as _ from 'lodash';
import { AppState } from 'src/app/app.types';
import { Store } from '@ngrx/store';
import { SidebarActions } from 'src/app/sidebar/sidebar.actions';
import { SubscriptionLimitService } from '../../common/dialogs/subscription-limit-warning/subscription-limit.service';
import { PaginationResponse } from 'src/app/api/models';

@Component({
  selector: 'qa-grid-data',
  templateUrl: './grid-data.component.html',
  styleUrls: ['./grid-data.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class GridDataComponent implements OnChanges, OnDestroy, AfterViewInit {
  @Input()
  config: ListConfig;
  @ViewChild(MatSort)
  private matSort: MatSort;
  @ViewChild(MatPaginator)
  private matPaginator: MatPaginator;
  @ViewChild('searchInput', { read: MatInput })
  private searchInput: MatInput;

  @ViewChild('addButton', { read: ElementRef }) addButton: ElementRef;
  @Output() selectedEntities = new EventEmitter<any>();

  filterTargetControl = new UntypedFormControl('');
  possibleFilters: Array<ListColumn<any>> = [];

  filterAutoCompleteOptions = [];

  showColumns = [];

  activeFilters: any[] = [];
  subscription: Subscription;

  @Input() selectedRows: PanelEntity[] = [];
  @Input() disabledRows: PanelEntity[] = [];
  @Input() isSingleSelection = false;

  allChecked = false;
  searchTextChanged = new Subject<{ value: string; filter: ListColumn<any> }>();
  searchValue: string;

  dragDisabled = true;
  menuShow: boolean = false;

  dataSource: ListDataSource;
  DEFAULT_PAGE_SIZE = DEFAULT_PAGE_SIZE;
  searchControl = new UntypedFormControl();
  treeControl = new UntypedFormControl({
    id: 'all',
    parent: null,
    name: this.translator.instant('courseMarket.allCategories'),
    icon_code: 'category',
  });
  ChipType = ChipType;

  pagination: PaginationResponse;
  options = new BehaviorSubject<any[]>([]);
  options$: Observable<any[]>;
  loadingOptions = false;

  compareObjects(o1: any, o2: any): boolean {
    return o1 && o2 && o1.name === o2.name;
  }

  compareTypeObjects(o1: any, o2: any): boolean {
    return o1 && o2 && o1.value === o2.value && o1.group === o2.group;
  }

  @HostBinding('class.hide-wrapper')
  get hideWrapper(): boolean {
    return this.config && this.config.hideWrapper;
  }
  get activeActions(): ListAction[] {
    return this.config.actions.filter((action) =>
      this.config.activeActionNames.includes(action.name)
    );
  }
  get activeActionsMenuLength(): boolean {
    return !!this.config.actionsMenu.filter((eachData) => {
      return !eachData.hide || (eachData.hide && !eachData.hide());
    }).length;
  }
  get activeDisabledActionsMenuLength(): boolean {
    return !!this.config.disabledActionsMenu.filter((eachData) => {
      return !eachData.hide || (eachData.hide && !eachData.hide());
    }).length;
  }
  get actionsColumnWidth(): number {
    return (
      this.activeActions.filter((eachData) => {
        return !eachData.hide || (eachData.hide && !eachData.hide());
      }).length * 48
    );
  }
  navGroups = [];
  isContent = false;

  get displayedColumnNames(): string[] {
    const displayedColumnNames = (
      this.config.columns.map((col) => col.name) as string[]
    ).filter((col) => this.showColumns.includes(col));
    if (this.config.chip && !displayedColumnNames.includes('chip')) {
      displayedColumnNames.push('chip');
    }
    if (this.config.actions && !displayedColumnNames.includes('actions')) {
      displayedColumnNames.push('actions');
    }
    if (
      this.config?.multiselectActions &&
      !displayedColumnNames.includes('multiselectActions')
    ) {
      displayedColumnNames.unshift('multiselectActions');
    }
    if (
      !this.config?.multiselectActions &&
      displayedColumnNames.includes('multiselectActions')
    ) {
      displayedColumnNames.shift();
    }
    if (!this.config.actions && displayedColumnNames.includes('actions')) {
      displayedColumnNames.pop();
    }

    if (this.config?.onPositionDrag) {
      displayedColumnNames.unshift('drag');
    }
    return displayedColumnNames;
  }

  constructor(
    private router: Router,
    private matDialog: MatDialog,
    private route: ActivatedRoute,
    private filterService: FilterService,
    private matSnackBar: MatSnackBar,
    private cdkRef: ChangeDetectorRef,
    private translator: TranslateService,
    elementRef: ElementRef<HTMLElement>,
    private store: Store<AppState>,
    private subscriptionLimit: SubscriptionLimitService
  ) {
    elementRef.nativeElement.classList.add('qa-grid-data');
  }

  ngAfterViewInit() {
    setTimeout(() => {
      if (this.searchInput) {
        this.searchInput.focus();
      }
      this.init();
    });
    this.isContent = this.router.url.indexOf('content') !== -1;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.config && changes.config.currentValue) {
      this.init();
    }
    if (changes.selectedRows && changes.selectedRows.currentValue) {
      this.selectedRows = changes.selectedRows.currentValue;
      this.selectedRows.forEach((row) => {
        this.checkRow(row, { checked: true } as MatCheckboxChange);
      });
    }
    if (changes.disabledRows && changes.disabledRows.currentValue) {
      this.disabledRows = JSON.parse(
        JSON.stringify(changes.selectedRows.currentValue)
      );
    }
  }

  ngOnDestroy(): void {
    this.filterService.applyFilters(null);
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  changeFilter(target) {
    this.filterTargetControl.setValue(target);
    this.pagination = null;
    this.options.next([]);
    if (!target?.filterOptionsByGroup && !target?.filterOptionsMultiSelect) {
      this.clearSearch();
    }
    this.updateInputValue(target);
  }

  updateInputValue(target) {
    if (target?.filterOptionsByGroup) {
      const filters = this.checkFiltersInSession('type');
      if (filters) {
        if (target.name === 'type') {
          const obj = [];
          Object.keys(filters).forEach((key) => {
            filters[key].forEach((k) => {
              obj.push({
                value: k,
                group: key,
              });
            });
          });
          this.searchControl.setValue(obj);
        }
      }
    }
  }

  openVideo(videoName = 'teaser', skipIfAlreadyShown?: boolean) {
    this.matDialog.open<HelpVideoDialogComponent, HelpVideoDialogData>(
      HelpVideoDialogComponent,
      {
        data: {
          skipIfAlreadyShown,
          videoName,
        },
      }
    );
  }

  clearSearch() {
    this.searchControl.reset();
  }

  isFunction(inp: any): boolean {
    return typeof inp === 'function';
  }

  private init() {
    if (!this.matPaginator || !this.matSort) {
      return;
    }
    const sessionStorageKey = `${this.router.url}#${this.config.name}`;
    this.dataSource = new ListDataSource(
      this.config.fetch,
      {
        ...{ initial: {}, isLocal: false, searchColumnName: null },
        ...(this.config.query || {}),
      },
      sessionStorageKey,
      this.matPaginator,
      this.matSort,
      this.matSnackBar
    );
    this.cdkRef.detectChanges();
    this.subscription = this.filterService.filter$.subscribe({
      next: (filters: Filter) => {
        if (filters) {
          if (this.config.name === filters.forName) {
            this.dataSource.filter.next(filters.filters);
          }
        }
      },
    });
    this.dataSource.filter.subscribe((filters) => {
      this.activeFilters = Object.keys(filters)
        .map((key) => {
          const col = this.config.columns.find((col) => col.name === key);
          if (col) {
            return {
              _ref: key,
              name: col?.label || key,
              value: col?.filterValue
                ? col?.filterValue(filters[key]) || filters[key]
                : filters[key],
            };
          }
          return null;
        })
        .filter((_) => _ && _.value !== null);
    });
    const storedShowColumns = JSON.parse(
      localStorage.getItem(
        `${this.route.snapshot.url}#${this.config.name}_showColumns`
      ) || 'null'
    );
    if (storedShowColumns) {
      this.showColumns = storedShowColumns;
    } else {
      this.showColumns = this.config.activeColumnNames;
    }
    this.possibleFilters = this.config.columns.filter((col) => col.filter);
    if (this.possibleFilters?.length > 0) {
      this.filterTargetControl.setValue(
        this.possibleFilters.find(
          (filter) => filter.name === this.config.query.searchColumnName
        )
      );
    }
    this.handleSearch();
  }

  openLink(link: string) {
    window.open(link);
  }

  someChecked(): boolean {
    return (
      this.selectedRows &&
      this.dataSource?.length > this.selectedRows.length &&
      this.selectedRows.length > 0
    );
  }

  checkRow(row: any, state: MatCheckboxChange): void {
    this.selectedRows = this.selectedRows ?? [];
    if (this.selectedRows.find((r) => r.id === row.id)) {
      this.selectedRows.splice(this.selectedRows.indexOf(row), 1);
    }
    if (!this.isSingleSelection) {
      if (state.checked) {
        this.selectedRows.push(row);
      }
      if (
        this.dataSource &&
        this.selectedRows.length === this.dataSource.length
      ) {
        this.allChecked = true;
      } else {
        this.allChecked = false;
      }
    } else {
      if (state.checked) {
        this.selectedRows = [row];
      }
    }
    this.selectedEntities.emit(this.selectedRows);
  }

  checkAll(completed: boolean) {
    this.allChecked = completed;
    if (completed) {
      this.dataSource
        .getData()
        .pipe(take(1))
        .subscribe((data) => {
          this.selectedRows = data.filter((el: PanelEntity) => {
            return this.disabledRows?.length
              ? this.disabledRows.map((i) => i.id).indexOf(el.id) < 0
              : true;
          });
          this.selectedEntities.emit(this.selectedRows);
        });
    } else {
      this.selectedRows = [];
      this.selectedEntities.emit(this.selectedRows);
    }
  }

  isChecked(row: any) {
    return this.selectedRows?.length
      ? this.selectedRows.find((r) => r.id === row.id)
      : false;
  }

  isDisabled(row: any) {
    return this.disabledRows && this.disabledRows?.length
      ? this.disabledRows.find((r) => r.id === row.id)
      : null;
  }

  multiSelectClick(click: (meta: any) => Promise<any>) {
    click({ rows: this.selectedRows, dataSource: this.dataSource }).then(() => {
      this.selectedRows = [];
    });
  }

  addFilter(): void {
    const value = this.searchControl.value;
    const target = this.filterTargetControl.value;
    if (value === null || value.length === 0) {
      return;
    }
    const filters = this.dataSource.filter.value || {};
    let filtered;
    if (target?.filterOptionsByGroup) {
      filtered = _.mapValues(_.groupBy(value, 'group'), (cList) =>
        cList.map((g) => g.value)
      );
      filtered = target ? target.filter(filtered) : {};
    } else {
      filtered = target ? target.filter(value) : {};
    }
    this.filterService.applyFilters({
      forName: this.config.name,
      filters: { ...filters, ...filtered },
    });
    this.matPaginator.firstPage();
    if (!target?.filterOptionsByGroup && !target?.filterOptionsMultiSelect) {
      this.searchControl.reset();
    }
  }

  changeCategory(category) {
    if (category) {
      this.searchControl.setValue([category.value]);
      this.addFilter();
    }
  }

  removeMultiselectFilter(column: ListColumn<any>, select) {
    const filter = this.activeFilters.find((af) => column.name === af._ref);
    if (filter) {
      this.removeFilter(filter);
      select.close();
    } else {
      this.clearSearch();
    }
  }

  removeFilter(filter): void {
    const filters = this.dataSource.filter.value || {};
    delete filters[filter._ref];
    this.filterService.applyFilters({
      forName: this.config.name,
      filters,
    });
    this.matPaginator.firstPage();
    const targetInput = this.filterTargetControl.value;
    if (filter._ref === targetInput.name) {
      this.searchControl.reset();
    }
  }

  removeFilterItem(item, filter) {
    const filters = this.dataSource.filter.value || {};
    if (filter._ref === 'tags') {
      filters[filter._ref] = filters[filter._ref].filter(
        (tag) => tag.name !== item
      );
      const selectedVal = this.searchControl.value;
      const targetInput = this.filterTargetControl.value;
      if (selectedVal?.length && targetInput.name === 'tags') {
        this.searchControl.setValue(
          selectedVal.filter((sv) => sv.name !== item)
        );
      }
    }
    if (filter._ref === 'type') {
      const target = this.possibleFilters.find(
        (col) => col.name === filter._ref
      );
      const selectedVal = this.searchControl.value;
      const targetInput = this.filterTargetControl.value;
      if (selectedVal?.length && targetInput.name === 'type') {
        let findSlug;
        target.filterOptionsByGroup.forEach((fo) => {
          fo.group.forEach((g) => {
            if (g.slug === item) {
              findSlug = { group: fo.id, value: g.value };
            }
          });
        });
        const setValue = selectedVal.filter(
          (sv) =>
            sv.group !== findSlug.group ||
            (sv.group === findSlug.group && sv.value !== findSlug.value)
        );
        this.searchControl.setValue(setValue);
      }
      Object.keys(filters[filter._ref]).forEach((key) => {
        filters[filter._ref][key] = filters[filter._ref][key].filter((type) => {
          const group = target.filterOptionsByGroup.find(
            (group) => group.id === key
          );
          const slug = group.group.find((o) => o.value === type);
          return slug.slug !== item;
        });
      });
    }
    this.filterService.applyFilters({
      forName: this.config.name,
      filters,
    });
    this.matPaginator.firstPage();
  }

  translateFilterValue(value, key) {
    const target = this.possibleFilters.find((col) => col.name === key);
    if (target?.filterOptions) {
      if (target?.filtersCanStack) {
        return target.filterOptions
          .filter((opt) => value.includes(opt.value))
          .map((val) => val.name);
      } else if (target.showTreeSelect) {
        return value[0]?.name || '';
      } else {
        const findTarget = target.filterOptions.find(
          (opt) => JSON.stringify(opt.value) === JSON.stringify(value)
        );
        return findTarget ? findTarget.name : '';
      }
    } else if (target?.filterOptionsByGroup) {
      if (target?.filtersCanStack) {
        const options = target?.filterOptionsByGroup;
        let activeFilters = [];
        Object.keys(value).forEach((k) => {
          const hasFilter = options.find((o) => o.id === k);
          if (hasFilter) {
            const filtered = hasFilter.group.filter((g) =>
              value[k].includes(g.value)
            );
            activeFilters = [...activeFilters, ...filtered];
          }
        });
        return activeFilters.map((af) => af.slug);
      }
    } else {
      return value;
    }
  }

  isStringValue(filter) {
    return (
      typeof this.translateFilterValue(filter.value, filter._ref) === 'string'
    );
  }

  setCurrentFilterAutocomplete(
    fun: (value) => Observable<string[]>,
    searchString: string
  ) {
    fun(searchString || '').subscribe((val) => {
      this.filterAutoCompleteOptions = val;
    });
  }

  selectAutoCompleteFilterOption(event): void {
    this.searchControl.setValue(event.option.value || '');
    this.addFilter();
  }

  setShowColumn(event, column) {
    event.stopPropagation();
    event.preventDefault();

    if (this.showColumns.includes(column.name)) {
      this.showColumns.splice(this.showColumns.indexOf(column.name), 1);
    } else {
      this.showColumns.push(column.name);
    }

    localStorage.setItem(
      `${this.route.snapshot.url}#${this.config.name}_showColumns`,
      JSON.stringify(this.showColumns)
    );
  }

  afterItemDropped(event: CdkDragDrop<any>) {
    if (!this.config?.onPositionDrag) {
      return;
    }
    const list = this.dataSource.getData().value;
    moveItemInArray(list, event.previousIndex, event.currentIndex);
    this.dataSource.getData().next(list);
    this.config
      .onPositionDrag(event.item.data, event.currentIndex + 1)
      .subscribe(() => {
        this.dataSource.refresh();
      });
  }

  importContent() {
    const dialog = this.matDialog.open<
      ImportContentDialogComponent,
      ImportContentDialogData
    >(ImportContentDialogComponent, {
      autoFocus: false,
      data: {
        imports: this.config.imports,
        dataSource: this.dataSource,
        title: this.getTitle(),
      },
      maxHeight: '90vh',
    });
    dialog.afterOpened().subscribe(() => {
      this.subscriptionLimit.openDialog(this.config.subscriptionLimit);
    });
    dialog.afterClosed().subscribe(() => {
      this.dataSource.refresh();
    });
  }

  getTitle() {
    switch (this.config.name) {
      case 'courseList':
        return this.translator.instant('common.label.addCourse');
      case 'bookList':
        return this.translator.instant('common.label.addBook');
      case 'liveEventsList':
        return this.translator.instant('common.label.AddLiveEvent');
      case 'examList':
        return this.translator.instant('common.label.Addexam');
      case 'quizList':
        return this.translator.instant('common.label.Addquiz');
      case 'questionList':
        return this.translator.instant('common.label.Addquestions');
      case 'flashCardStackList':
        return this.translator.instant('common.label.Addstack');
      case 'flashCardList':
        return this.translator.instant('common.label.Addflashcards');
      default:
        return '';
    }
  }

  toggleEdit(row) {
    const rows = this.dataSource.getData().value;
    rows.forEach((r) => {
      if (r['id'] === row.id) {
        row.isEdit = true;
      } else {
        r['isEdit'] = false;
      }
    });
  }

  blueEditField(meta, column) {
    column.editableField.submit(meta);
  }

  blurEdit(meta) {
    meta.row.isEdit = false;
  }

  onChangeEdit(newValue, meta, column) {
    if (newValue && newValue.trim() !== meta.row.name) {
      meta.row.name = newValue;
      this.blueEditField(meta, column);
    }
  }

  toggleMenu() {
    this.menuShow = !this.menuShow;
    if (this.menuShow) {
      this.store.dispatch(SidebarActions.showSidebar());
    } else {
      this.store.dispatch(SidebarActions.hideSidebar());
    }
  }

  fetchMultipleOptions(filter: ListColumn<any>) {
    if (!this.pagination) {
      this.pagination = {
        has_next_page: true,
        page: 0,
      } as PaginationResponse;
      this.getNextBatch(filter);
    }
  }

  getNextBatch(filter: ListColumn<any>) {
    if (this.pagination?.has_next_page && !this.loadingOptions) {
      this.loadingOptions = true;
      this.pagination.page++;
      filter
        .filterOptionsMultiSelect({
          name: this.searchValue,
          page: this.pagination.page,
        })
        .subscribe((response) => {
          this.loadingOptions = false;
          if (response?.data) {
            this.pagination = response.pagination;
            this.options.next(response.data);
            this.setMultiselectValue(response.data);
          }
        });
    }
  }

  setMultiselectValue(tags) {
    const filters = this.checkFiltersInSession('tags');
    if (filters) {
      const value = this.searchControl.value ?? [];
      const selected = value?.length ? value.map((v) => v.name) : [];
      filters.forEach((tag) => {
        const findTag = tags.find((t) => tag.name === t.name);
        if (findTag && !selected.includes(tag.name)) {
          value.push(findTag);
          this.searchControl.setValue(value);
        }
      });
    }
  }

  checkFiltersInSession(key: string) {
    const sessionStorageKey = `${this.router.url}#${this.config.name}`;
    const session = sessionStorage.getItem(sessionStorageKey);
    if (session) {
      const filters = JSON.parse(session).filters;
      return Object.keys(filters).length && filters[key] ? filters[key] : null;
    } else {
      return null;
    }
  }

  search(filter: ListColumn<any>, $event) {
    this.searchTextChanged.next({ value: $event.target.value, filter });
  }

  handleSearch() {
    this.options$ = this.options.asObservable().pipe(
      scan((acc, curr) => {
        return this.pagination?.page && this.pagination?.page > 1
          ? [...acc, ...curr]
          : curr;
      }, [])
    );
    this.searchTextChanged
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        tap((data) => {
          this.loadingOptions = true;
          this.searchValue = data.value;
          this.pagination = null;
          this.options.next([]);
        }),
        switchMap((data) => {
          return data.filter.filterOptionsMultiSelect({
            name: data.value,
            page: 1,
          });
        })
      )
      .subscribe((response) => {
        this.loadingOptions = false;
        if (response?.data) {
          this.pagination = response.pagination;
          this.options.next(response.data);
          this.setMultiselectValue(response.data);
        }
      });
  }
}

export async function multiSelectAction(
  items: any[],
  callback: (item: any) => Promise<boolean>,
  label: string,
  matDialog: MatDialog
) {
  let success = 0;
  let failed = 0;
  const getData = () => ({
    total: items.length,
    success,
    failed,
    isProgress: success + failed !== items.length,
    isComplete: success + failed === items.length,
    label: {
      progress: label,
      success: 'Done!',
    },
  });
  const dialogRef = matDialog.open(CsvImportProgressDialogComponent, {
    disableClose: true,
    data: getData(),
  });
  for (const item of items) {
    const result = await callback(item);
    if (result) {
      success++;
    } else {
      failed++;
    }
    dialogRef.componentInstance.data = getData();
  }
  dialogRef.close();
}
