<div
  class="count-num"
  *ngIf="!isExplanationPanelOpen && itemCount && itemCount > 0"
>
  {{ 'common.label.question' | translate }}
  {{ currentItemPosition | number : '2.0-0' }} <span>/</span>
  {{ itemCount | number : '2.0-0' }}
</div>
<qa-mock-phone [class.isExplanationPanelOpen]="isExplanationPanelOpen">
  <div [class.shifted]="showExplanation" class="view-container">
    <div>
      <ng-scrollbar>
        <div
          [class.text-container-expanded]="isTextContainerExpanded"
          class="app-bar mat-elevation-z1"
        >
          <mat-icon>arrow_back</mat-icon>
          <div class="title">
            {{ 'questionMockPhone.courseName' | translate }}
          </div>
          <div class="right-buttons">
            <mat-icon>assessment</mat-icon>
            <mat-icon>more_vert</mat-icon>
          </div>
        </div>
        <div
          (click)="isTextContainerExpanded = !isTextContainerExpanded"
          class="text-container"
          matTooltipPosition="above"
        >
          <div class="quiz-title flex-row flex-align-items-center"></div>
          <img
            src="https://s3.{{ 's3.mediaBucket.region' | env }}.amazonaws.com/{{
              's3.mediaBucket.name' | env
            }}/{{ question.media.uuid }}.{{ question.media.file_ext }}"
            *ngIf="question?.media?.uuid"
          />
          <div class="question-text">
            <qa-markdown-katex [data]="question.text"> </qa-markdown-katex>
          </div>
        </div>
        <div class="hint">
          <ng-container *ngIf="question.type === questionTypeEnum.TRUE_FALSE">
            {{ 'question.hint.chooseStatement' | translate }}
          </ng-container>
          <ng-container
            *ngIf="
              !isPollQuestion &&
              question.type === questionTypeEnum.MULTIPLE_CHOICE
            "
          >
            {{ 'question.hint.chooseAll' | translate }}
          </ng-container>
          <ng-container
            *ngIf="
              !isPollQuestion &&
              question.type === questionTypeEnum.SINGLE_CHOICE
            "
          >
            {{ 'question.hint.chooseAns' | translate }}
          </ng-container>
          <ng-container
            *ngIf="
              isPollQuestion &&
              (question.type === questionTypeEnum.MULTIPLE_CHOICE ||
                question.type === questionTypeEnum.SINGLE_CHOICE)
            "
          >
            {{ 'question.hint.yourAnswer' | translate }}
          </ng-container>
          <ng-container
            *ngIf="
              isPollQuestion && question.type === questionTypeEnum.FREETEXT
            "
          >
            {{ 'question.hint.typeInYour' | translate }}
          </ng-container>
          <ng-container
            *ngIf="isExam && question.type === questionTypeEnum.FREETEXT"
          >
            {{ 'question.hint.typeIn' | translate }}
          </ng-container>
        </div>
        <div class="answer-container">
          <div
            class="scroll-container"
            [class.trueFalse]="question.type === questionTypeEnum.TRUE_FALSE"
          >
            <div
              class="question-type"
              *ngIf="question.type === questionTypeEnum.FREETEXT"
            >
              {{ getQuestionTypeText(question.type) }}
            </div>
            <ng-template #answerContainer let-answer="answer">
              <div
                [class.correct]="answer.is_right"
                class="answer flex-row flex-align-items-center"
              >
                <div
                  class="radio-checkbox"
                  [class.multi]="
                    question.type === questionTypeEnum.MULTIPLE_CHOICE
                  "
                >
                  <mat-icon *ngIf="answer.is_right">
                    {{
                      question.type === questionTypeEnum.MULTIPLE_CHOICE
                        ? 'check_box'
                        : 'radio_button_checked'
                    }}
                  </mat-icon>
                  <div class="answer-button"></div>
                </div>
                <div class="text flex-grow">
                  <qa-markdown-katex [data]="answer.text"> </qa-markdown-katex>
                </div>
              </div>
            </ng-template>
            <ng-container *ngIf="question.type === questionTypeEnum.TRUE_FALSE">
              <ng-container
                *ngTemplateOutlet="
                  answerContainer;
                  context: { answer: trueFalseAnswerTrue }
                "
              >
              </ng-container>
              <ng-container
                *ngTemplateOutlet="
                  answerContainer;
                  context: { answer: trueFalseAnswerFalse }
                "
              >
              </ng-container>
            </ng-container>
            <ng-container
              *ngIf="
                question.type !== questionTypeEnum.TRUE_FALSE &&
                  question.type === questionTypeEnum.FREETEXT;
                else otherQuetion
              "
            >
              <div
                class="answer flex-row flex-align-items-center correct freetext"
              >
                <div class="text flex-grow">
                  {{ 'question.form.maxScore' | translate }}:
                  {{ question.max_score }}
                </div>
              </div>
            </ng-container>
            <ng-template #otherQuetion>
              <ng-container *ngFor="let answer of question.answers">
                <ng-container
                  *ngTemplateOutlet="
                    answerContainer;
                    context: { answer: answer }
                  "
                >
                </ng-container>
              </ng-container>
            </ng-template>
          </div>
        </div>
      </ng-scrollbar>
      <div
        @flyInOutHorizontal
        (click)="openExplainPanel($event, true)"
        *ngIf="!isExplanationPanelOpen"
        class="check-button"
      >
        {{ 'question.form.explanation' | translate }}

        <mat-icon>arrow_back_ios</mat-icon>
      </div>
      <div
        class="explanation-panel qa-theme-inverted"
        [class.isPanelOpen]="isExplanationPanelOpen"
      >
        <div class="wrapper-exp" *ngIf="isExplanationPanelOpen">
          <div class="exapantion-title">
            {{ 'question.form.explanation' | translate }}
          </div>
          <button
            (click)="openExplainPanel($event, true)"
            class="toggle-explanation-button"
            color="primary"
            mat-button
            type="button"
          >
            <mat-icon>arrow_back_ios</mat-icon>
          </button>
          <ng-scrollbar class="scroll-container">
            <qa-markdown-katex
              [data]="question.explanation"
              *ngIf="question.explanation; else noExplanationPlaceholder"
            >
            </qa-markdown-katex>
            <ng-template #noExplanationPlaceholder>
              <div class="placeholder">
                {{ 'questionMockPhone.noExplanation' | translate }}
              </div>
            </ng-template>
          </ng-scrollbar>
        </div>
        <div class="actions">
          <a
            *ngIf="getWeblink()"
            [href]="getWeblink()"
            color="primary"
            mat-button
            target="_blank"
            type="button"
          >
            <mat-icon>open_in_new</mat-icon>
            {{ 'question.openWebLink' | translate }}
          </a>
        </div>
      </div>
    </div>
    <div>
      <div class="app-bar">
        <mat-icon
          (click)="isWeblinkTabActive = false; showExplanation = false"
          matTooltip="{{
            'questionMockPhone.tooltip.backToQuestion' | translate
          }}"
          class="back-to-question-button"
          matTooltipPosition="right"
        >
          arrow_back
        </mat-icon>
        <div class="title">
          {{ 'questionMockPhone.explanation' | translate }}
        </div>
      </div>
      <div class="tabs-container">
        <div class="tabs">
          <div (click)="isWeblinkTabActive = false" matRipple>
            {{ 'questionMockPhone.explanation' | translate }}
          </div>
          <div (click)="isWeblinkTabActive = true" matRipple>Weblink</div>
        </div>
        <div [class.shifted]="isWeblinkTabActive" class="ink-bar"></div>
      </div>
      <div [class.shifted]="isWeblinkTabActive" class="tab-content-container">
        <div class="explanation-tab-content">
          <qa-markdown-katex
            [data]="question.explanation"
            *ngIf="question.explanation; else noExplanationPlaceholder"
          >
          </qa-markdown-katex>
          <ng-template #noExplanationPlaceholder>
            <div class="placeholder">
              {{ 'questionMockPhone.noExplanation' | translate }}
            </div>
          </ng-template>
        </div>
        <div class="weblink-tab-content">
          <div>
            {{ 'questionMockPhone.weblinkText' | translate }}
          </div>
        </div>
      </div>
    </div>
  </div>
</qa-mock-phone>
