<mat-dialog-content>
  <p>{{ 'editionSelect.upperText' | translate }}</p>
  <ng-container *ngFor="let edition of data?.editions">
    <a
      [mat-dialog-close]
      href="{{ edition.base_url_ui.replace('business', 'go') }}/{{ pathUrl }}"
    >
      {{ edition.name }} Edition
    </a>
  </ng-container>
  <div
    class="below-text"
    innerHTML="{{ 'editionSelect.belowText' | translate }}"
  ></div>
</mat-dialog-content>
