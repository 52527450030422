<div class="language-wrapper" #languageDiv *ngIf="!auth?.isLoggedIn()">
  <div
    class="languages"
    [@enterAnimation]
    (click)="showLang = true"
    *ngIf="!showLang"
  >
    {{ translator?.currentLang }}
  </div>
  <div class="language-container" *ngIf="showLang" [@enterAnimation]>
    <div class="language">
      <div class="current-lang">
        {{ translator?.currentLang }}
      </div>
      <div class="all-language">
        <span
          *ngFor="let lang of languages"
          [class.active]="translator?.currentLang === lang"
          (click)="updateLanguage(lang)"
        >
          {{ lang }}
        </span>
      </div>
    </div>
  </div>
</div>
<div class="sidebar-main-flex">
  <qa-sidebar *ngIf="auth.isLoggedIn() && editionAccess"> </qa-sidebar>
  <main
    class="router-outlet-wrapper"
    [attr.lockmobile]="!isCms ? 'true' : null"
  >
    <router-outlet> </router-outlet>
  </main>
</div>
