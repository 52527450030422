import { animate, style, transition, trigger } from '@angular/animations';
import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { QuestionModel } from 'src/app/api/models';
import { QuestionType } from 'src/app/app.types';
import { TranslateService } from '@ngx-translate/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

@Component({
  selector: 'qa-question-mock-phone',
  templateUrl: './question-mock-phone.component.html',
  styleUrls: ['./question-mock-phone.component.scss'],
  animations: [
    trigger('flyInOutVertical', [
      transition(':enter', [
        style({ bottom: '-150px' }),
        animate('200ms ease-out', style({ bottom: '44px' })),
      ]),
      transition(':leave', [
        animate('200ms ease-out', style({ bottom: '-150px' })),
      ]),
    ]),
    trigger('flyInOutHorizontal', [
      transition(':enter', [
        style({ right: '-248px' }),
        animate('250ms ease-out', style({ right: '0px' })),
      ]),
      transition(':leave', [
        animate('250ms ease-out', style({ right: '-248px' })),
      ]),
    ]),
  ],
})
export class QuestionMockPhoneComponent implements OnChanges {
  constructor(
    private translator: TranslateService,
    private domSanitizer: DomSanitizer
  ) {}
  @Input() question: QuestionModel;
  @Input() itemCount: number;
  @Input() isExam: boolean;
  @Input() isPollQuestion: boolean;
  @Input() currentItemPosition = 1;
  isAnswerContainerExpanded = false;
  isExplanationTooltipVisible = false;
  isTextContainerExpanded = false;
  isWeblinkTabActive = false;
  questionTypeEnum = QuestionType;
  showExplanation = false;
  isExplanationPanelOpen = false;

  get trueFalseAnswerTrue() {
    return {
      is_right: this.question.is_right,
      text: this.translator.instant('questionMockPhone.correct'),
    };
  }
  get trueFalseAnswerFalse() {
    return {
      is_right: !this.question.is_right,
      text: this.translator.instant('questionMockPhone.inCorrect'),
    };
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.currentItemPosition =
      changes?.currentItemPosition?.currentValue || null;
  }

  getQuestionTypeText(questionType: QuestionType) {
    switch (questionType) {
      case QuestionType.MULTIPLE_CHOICE:
        return this.translator.instant('questionMockPhone.multiChoice');
      case QuestionType.SINGLE_CHOICE:
        return this.translator.instant('questionMockPhone.singleChoice');
      case QuestionType.TRUE_FALSE:
        return this.translator.instant('questionMockPhone.trueOrFalse');
      case QuestionType.FREETEXT:
        return this.translator.instant('questionMockPhone.freeText');
      default:
        return '';
    }
  }

  openExplainPanel(event, open): void {
    if (open) {
      event.stopPropagation();
      this.isExplanationPanelOpen = !this.isExplanationPanelOpen;
    }
  }

  getWeblink(): SafeUrl {
    let weblink = this.question.weblink;
    if (!weblink) {
      return null;
    }
    if (!weblink.includes('http')) {
      weblink = `http://${weblink}`;
    }
    return this.domSanitizer.bypassSecurityTrustUrl(weblink);
  }
}
