<qa-content-spinner [active]="loading">
  <div class="course-export-wrapper">
    <h1>{{ 'menu.courseExports' | translate }}</h1>
    <mat-form-field [appearance]="'fill'">
      <input
        [(ngModel)]="courseIds"
        matInput
        autocomplete="off"
        placeholder="{{ 'courseExports.placeholder' | translate }}"
        type="text"
      />
    </mat-form-field>
    <div class="submit">
      <qa-button
        [disabled]="!courseIds"
        [isRaisedButton]="true"
        label="common.button.submit"
        (submitted)="onExport()"
      ></qa-button>
    </div>
  </div>
</qa-content-spinner>
