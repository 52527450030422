import { Component, Optional, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'qa-import-csv-error-dialog',
  templateUrl: './import-csv-error-dialog.component.html',
  styleUrls: ['./import-csv-error-dialog.component.scss'],
})
export class ImportCsvErrorDialogComponent {
  constructor(
    @Optional() public dialogRef: MatDialogRef<ImportCsvErrorDialogComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  parseError() {
    if (!this.data || !this.data.error) {
      return null;
    }
    switch (this.data.error.code) {
      case 0:
        return `Error: No data provided.`;
      case 1:
        return `Error in line ${this.data.error.pos}: Text or answer missing`;
      default:
        return null;
    }
  }
}
