import {
  ChangeDetectionStrategy,
  Component,
  Input,
  ViewChild,
  OnInit,
  Output,
  EventEmitter,
  OnChanges,
  ElementRef,
  ChangeDetectorRef,
  HostListener,
} from '@angular/core';
import { UntypedFormControl, FormGroupDirective, NgForm } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { MarkdownInfoDialogComponent } from './markdown-info-dialog.component';
import { FormField } from './form-field';
import { errorDisplayFunction } from 'src/app/app.types';
import { MatInput } from '@angular/material/input';
import { TranslateService } from '@ngx-translate/core';
import * as SimpleMDE from 'simplemde';
import { MathfieldElement } from 'mathlive';

export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(
    control: UntypedFormControl | null,
    form: FormGroupDirective | NgForm | null
  ): boolean {
    // Error when invalid control is dirty, touched, or submitted
    const isSubmitted = form && form.submitted;
    return !!(
      control.invalid &&
      (control?.dirty || control?.touched || isSubmitted)
    );
  }
}

@Component({
  selector: 'qa-form-field-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormInputComponent extends FormField implements OnInit, OnChanges {
  @Input() disableFloatingLabel = false;
  @Input() markdownHint = false;
  @Input() maxRows = 100;
  @Input() minRows = 3;
  @Input() min = 0;
  @Input() max = 1000000;
  @Input() step = 1;
  @Input() multi = false;
  @Input() type = 'text';
  @Input() placeholder = '';
  @Input() suggestion = '';
  @Input() isAlphaNumeric = false;
  @Input() hideLabel = false;
  @Input() suffix: string;
  @Input() hint: string;
  @Input() readonly = false;
  @Output() enterEvent = new EventEmitter<boolean>();
  @Output() keyUpEvent = new EventEmitter<boolean>();
  @Output() pasteEvent = new EventEmitter<boolean>();
  @Output() blurEvent = new EventEmitter<boolean>();
  blurInput: boolean;
  onHover: boolean;
  onMarkDown: boolean;
  showSuggestion = false;
  errorDisplayFunction = errorDisplayFunction;
  errorStateMatcher = new MyErrorStateMatcher();
  @ViewChild('inputField') inputField: MatInput;
  @ViewChild('textArea') textArea: MatInput;
  @ViewChild('textArea', { read: ElementRef })
  textAreaRef: ElementRef<HTMLTextAreaElement>;
  @ViewChild('formulaEditorContainer')
  formulaEditorContainerRef: ElementRef<HTMLDivElement>;

  insertFormulaAt: SimpleMDE = null;

  private mathInput: MathfieldElement;

  @HostListener('paste', ['$event']) blockPaste(event: KeyboardEvent) {
    if (this.isAlphaNumeric) {
      this.validateFields(event);
    }
  }

  validateFields(event) {
    setTimeout(() => {
      this.inputField.value = this.inputField.value
        .replace(/[^A-Za-z0-9 ]/g, '')
        .replace(/\s/g, '');
      this.control.patchValue(this.inputField.value);
      event.preventDefault();
    }, 1);
  }

  constructor(
    private matDialog: MatDialog,
    private translator: TranslateService,
    private cd: ChangeDetectorRef
  ) {
    super();
  }

  ngOnInit() {
    if (
      this.control.value &&
      this.control.value.toString().trim() &&
      !this.control.disabled
    ) {
      this.onBlur();
    }
    window.setTimeout(() => {
      if (!this.blurInput) {
        this.setupMarkdownEditor();
      }
      if (this.markdownHint && this.formulaEditorContainerRef?.nativeElement) {
        this.setupFormulaEditor();
      }
    }, 0);
  }

  ngOnChanges() {
    this.onBlur();
  }

  openMarkdownDialog() {
    this.onMarkDown = true;
    this.matDialog.open(MarkdownInfoDialogComponent, { width: '640px' });
  }

  keyPress(event) {
    if (!this.isAlphaNumeric) {
      return true;
    }
    const k = event.charCode;
    return (
      (k > 64 && k < 91) ||
      (k > 96 && k < 123) ||
      k == 8 ||
      k == 32 ||
      (k >= 48 && k <= 57)
    );
  }

  setupFormulaEditor() {
    this.mathInput = new MathfieldElement({
      virtualKeyboardMode: 'manual',
      virtualKeyboards: 'numeric symbols',
      soundsDirectory: '/assets/mathlive/sounds',
    });
    this.mathInput.addEventListener('input', () => {
      const replaced = this.mathInput.value.replace(/\$/gm, '\\$');
      const formula = replaced?.length ? `$${replaced}$` : '';
      if (this.insertFormulaAt) {
        const doc = this.insertFormulaAt.codemirror.getDoc();
        const cursor = doc.getCursor('from');
        doc.replaceSelection(formula);
        doc.setSelection(cursor, { ...cursor, ch: cursor.ch + formula.length });
      }
    });

    this.mathInput.addEventListener('blur', () => {
      this.insertFormulaAt = null;
      this.cd.detectChanges();
    });

    this.formulaEditorContainerRef.nativeElement.appendChild(this.mathInput);
  }

  onBlur() {
    if (this.control.value && !this.control.value.toString().trim()) {
      this.control.patchValue(this.control.value.toString().trim());
    }
    this.blurInput =
      this.control.value &&
      this.control.value.toString().trim() &&
      !this.onMarkDown
        ? true
        : false;
    this.onMarkDown = false;

    if (this.showSuggestion) {
      this.textArea.value = '';
      this.showSuggestion = false;
    }
    this.blurEvent.emit(this.control.value);
  }

  onEdit() {
    this.blurInput = false;
    this.onHover = false;
    setTimeout(() => {
      if (this.markdownHint && this.textAreaRef?.nativeElement) {
        this.setupMarkdownEditor(true);
      } else {
        if (this.inputField) {
          this.inputField.focus();
        }
        if (this.textArea) {
          this.textArea.focus();
        }
      }
    }, 0);
  }

  setupMarkdownEditor(focus = false) {
    if (this.markdownHint && this.textAreaRef?.nativeElement) {
      const markdownEditor = new SimpleMDE({
        element: this.textAreaRef.nativeElement,
        status: false,
        toolbar: [
          'bold',
          'italic',
          'heading',
          '|',
          'quote',
          'code',
          'link',
          '|',
          'unordered-list',
          'ordered-list',
          '|',
          'table',
          {
            name: 'formula',
            action: (editor) => {
              this.insertFormulaAt = editor;
              this.mathInput.setValue('');
              this.mathInput.focus();
              this.mathInput.executeCommand('toggleVirtualKeyboard');
            },
            spellChecker: false,
            className: 'add-formula-icon',
            title: 'Formula',
          },
        ],
      });
      markdownEditor.codemirror.on('change', () => {
        this.control.setValue(markdownEditor.value());
        this.control.markAsDirty();
      });

      markdownEditor.codemirror.on('focus', (codemirror) => {
        if (
          codemirror &&
          !this.showSuggestion &&
          codemirror.getValue() === '' &&
          this.suggestion !== ''
        ) {
          codemirror.setValue(
            `${this.translator.instant('course.form.suggestionEnter')}\n${
              this.suggestion
            }`
          );
          this.showSuggestion = true;
        }

        if (this.showSuggestion) {
          codemirror.execCommand('selectAll');
        }
      });

      markdownEditor.codemirror.on('inputRead', this.onInput.bind(this));
      markdownEditor.codemirror.on('mousedown', (_, event) => {
        this.onPointerDown(event);
      });
      markdownEditor.codemirror.on('keydown', (codemirror, event) => {
        this.keyUpEvent.emit(event);
        if (event.keyCode === 13 && this.showSuggestion) {
          event.preventDefault();
          codemirror.setValue(this.suggestion);
          this.showSuggestion = false;
          this.control.setValue(this.suggestion);
        }
      });

      markdownEditor.codemirror.on('blur', (codemirror) => {
        if (!!this.insertFormulaAt) {
          return;
        }
        if (this.showSuggestion) {
          codemirror.setValue('');
          this.showSuggestion = false;
        }
        const shouldBlur =
          this.control.value &&
          this.control.value.toString().trim() &&
          !this.onMarkDown
            ? true
            : false;
        if (shouldBlur) {
          markdownEditor.toTextArea();
        }
        this.blurInput = shouldBlur;
        // if (this.blurInput) {
        this.blurEvent.emit(this.control.value);
        this.cd.detectChanges();
        // }
      });
      markdownEditor.gui.toolbar.querySelectorAll('a').forEach((link) => {
        link.addEventListener('mousedown', (event) => {
          event.preventDefault();
          event.stopPropagation();
        });
      });
      if (focus) {
        markdownEditor.codemirror.focus();
      }
    }
  }

  handleSuggestion(event) {
    if (
      this.textArea &&
      !this.showSuggestion &&
      this.textArea.value === '' &&
      this.suggestion !== ''
    ) {
      this.textArea.value = `${this.translator.instant(
        'course.form.suggestionEnter'
      )}\n${this.suggestion}`;
      this.showSuggestion = true;
    }

    if (this.showSuggestion) {
      event.target.select();
    }
  }

  onPointerDown(event) {
    if (this.showSuggestion) {
      event.preventDefault();
    }
  }

  onInput() {
    if (this.showSuggestion) {
      this.showSuggestion = false;
    }
  }

  onEnterInput() {
    if (this.control.value && this.control.value.trim()) {
      this.enterEvent.emit(true);
    }
  }

  inputKeyUp(event) {
    this.keyUpEvent.emit(event);
  }

  inputPaste(event) {
    this.pasteEvent.emit(event);
  }

  onKeyDown(event) {
    this.keyUpEvent.emit(event);
    if (event.keyCode === 13 && this.showSuggestion) {
      event.preventDefault();
      this.textArea.value = '';
      this.showSuggestion = false;
      this.textArea.value = this.suggestion;
      this.control.setValue(this.suggestion);
    }
  }
}
