import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import {
  ConfirmDialogComponentData,
  ConfirmDialogComponent,
  ConfirmDialogComponentDataButton,
} from './dialogs/confirm-dialog.component';
import {
  ImageDialogComponent,
  ImageDialogComponentData,
} from './dialogs/image-dialog.component';
import { TextInputDialogComponent } from './dialogs/text-input-dialog.component';
import { UntypedFormControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import {
  SelectDialogComponent,
  SelectDialogComponentData,
} from './dialogs/select-dialog.component';
import {
  EmbedDialogComponent,
  EmbedDialogData,
} from '../panel/embed-dialog.component';

@Injectable({
  providedIn: 'root',
})
export class DialogService {
  constructor(
    private matDialog: MatDialog,
    private translator: TranslateService
  ) {}

  confirm(
    data: ConfirmDialogComponentData,
    config?: MatDialogConfig
  ): Observable<boolean> {
    return this.matDialog
      .open(
        ConfirmDialogComponent,
        Object.assign({}, config, { data }, { width: '512px' })
      )
      .afterClosed();
  }

  confirmDelete(
    customTitle?: string,
    customText?: string,
    buttons?: ConfirmDialogComponentDataButton[]
  ): Observable<boolean> {
    return this.confirm({
      title: customTitle || this.translator.instant('common.dialogTitle.clear'),
      text: customText || this.translator.instant('common.message.clear'),
      buttons: buttons || [
        {
          text: this.translator.instant('common.button.abort'),
          value: false,
          color: 'primary',
        },
        {
          text: this.translator.instant('common.button.clear'),
          value: true,
          color: 'warn',
        },
      ],
    });
  }

  textInput(
    control: UntypedFormControl,
    customTitle?: string,
    customText?: string
  ): Observable<boolean> {
    return this.matDialog
      .open(
        TextInputDialogComponent,
        Object.assign(
          {},
          {
            data: {
              title: customTitle || 'Components.dialog.textInput.title',
              text: customText || 'Components.dialog.textInput.text',
              control,
              buttons: [
                {
                  text: this.translator.instant('common.button.abort'),
                  value: false,
                  icon: 'cancel',
                },
                {
                  text: this.translator.instant('common.button.save'),
                  value: true,
                  color: 'primary',
                  icon: 'done',
                },
              ],
            },
          },
          { minWidth: '30rem', minHeight: '20rem' }
        )
      )
      .afterClosed()
      .pipe(filter((result: boolean) => result));
  }

  error(title: string, text?: string, config?: MatDialogConfig) {
    const o = Object.assign(
      {},
      config,
      {
        data: {
          title,
          text,
          buttons: [
            {
              text: this.translator.instant('common.button.understood'),
              value: true,
              color: 'warn',
            },
          ],
        },
      },
      { minWidth: '30rem' }
    );
    return this.matDialog.open(ConfirmDialogComponent, o);
  }

  showImage(imageSource: string) {
    this.matDialog.open<ImageDialogComponent, ImageDialogComponentData>(
      ImageDialogComponent,
      {
        data: {
          url: imageSource,
        },
        panelClass: 'image-zoom-panel',
      }
    );
  }

  select(data: SelectDialogComponentData, config?: MatDialogConfig) {
    return this.matDialog
      .open<SelectDialogComponent, SelectDialogComponentData>(
        SelectDialogComponent,
        Object.assign({}, config, { data }, { width: '600px' })
      )
      .afterClosed();
  }

  openEmbedModel(data: EmbedDialogData) {
    this.matDialog.open<unknown, EmbedDialogData>(EmbedDialogComponent, {
      data,
      autoFocus: false,
    });
  }
}
