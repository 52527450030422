export const blockyTheme = {
  name: 'Blocky',
  bpm: 120,
  resources: [
    {
      id: 'start',
      path: 'theme01_start.mp3',
      length: 2,
    },
    {
      id: 'idle01',
      path: 'theme01_idle.mp3',
      loop: true,
      length: 1,
    },
    {
      id: 'idle02',
      path: 'theme01_idle2.mp3',
      loop: true,
      length: 3,
    },
    {
      id: 'transition',
      path: 'theme01_transition.mp3',
      length: 4,
    },
    {
      id: 'clock',
      path: 'clock01_120bpm.mp3',
      length: 2,
      loop: true,
    },
    {
      id: 'fasterclock',
      path: 'clock02_120bpm.mp3',
      length: 1,
      loop: true,
    },
    {
      id: 'fastclock',
      path: 'clock03_120bpm.mp3',
      length: 1,
      loop: true,
    },
    {
      id: 'extremeclock',
      path: 'clock04_120bpm.mp3',
      length: 1,
      loop: true,
    },
  ],
  timelines: [
    [
      {
        id: 'start',
        pos: 0,
      },
      {
        id: '$FILL',
        options: [
          {
            id: 'idle01',
            minInARow: 4,
            maxInARow: 4,
          },
        ],
      },
      {
        id: '$FILL',
        options: [
          {
            id: 'idle02',
            minInARow: 1,
            maxInARow: 1,
          },
        ],
      },
      {
        id: '$FILL',
        options: [
          {
            id: 'idle01',
            minInARow: 999,
            maxInARow: 999,
          },
        ],
      },
      {
        id: 'transition',
        pos: -4,
      },
      {
        id: 'start',
        pos: -2,
      },
    ],
    [
      {
        id: '$FILL',
        options: [
          {
            id: 'clock',
            minInARow: 999,
            maxInARow: 999,
          },
        ],
      },
      {
        id: 'fasterclock',
        pos: -4,
      },
      {
        id: 'fastclock',
        pos: -2,
      },
      {
        id: 'extremeclock',
        pos: -1,
      },
    ],
  ],
};
