import {
  Component,
  HostBinding,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import * as _ from 'lodash';
import LanguageDetect from 'languagedetect';
import Speech from 'speak-tts';

interface Language {
  name: string;
  flag: string;
  ttsId: string;
}

const mapDetectedToTTSid = {
  arabic: 'ar-SA',
  czech: 'cs-CZ',
  danish: 'da-DK',
  dutch: 'nl-NL',
  english: 'en-US',
  finnish: 'fi-FI',
  french: 'fr-FR',
  german: 'de-DE',
  hindi: 'hi-IN',
  hungarian: 'hu-HU',
  indonesian: 'id-ID',
  italian: 'it-IT',
  norwegian: 'nb-NO',
  polish: 'pl-PL',
  portuguese: 'pt-PT',
  romanian: 'ro-RO',
  russian: 'ru-RU',
  slovak: 'sk-SK',
  spanish: 'es-ES',
  swedish: 'sv-SE',
  turkish: 'tr-TR',
};

@Component({
  selector: 'qa-tts-button',
  templateUrl: './tts-button.component.html',
  styleUrls: ['./tts-button.component.scss'],
})
export class TtsButtonComponent implements OnChanges {
  supportedLanguages: Language[] = [];

  @Input() text: string;

  selectedLanguage?: Language = undefined;

  speech: Speech;

  @HostBinding('class.hidden')
  supportsSpeech: boolean;

  constructor() {
    this.speech = new Speech();
    new Promise<void>((resolve) => {
      try {
        this.speech
          .init({
            volume: 1,
            rate: 1,
            pitch: 1,
            splitSentences: true,
          })
          .then(() => {
            this.supportsSpeech = true;
            resolve();
          })
          .catch(() => {
            this.supportsSpeech = false;
            resolve();
          });
      } catch {
        this.supportsSpeech = false;
        resolve();
      }
    }).then(() => {
      this.initializeSupportedLanguages();
    });
  }

  initializeSupportedLanguages() {
    let allVoices = [];
    try {
      allVoices = window.speechSynthesis?.getVoices();
    } catch {}

    if (allVoices) {
      this.supportedLanguages = _.uniqBy(
        allVoices
          .map((voice) => ({
            name: `languages.${voice.lang}`,
            flag: voice.lang.split('-')[1]?.toLowerCase() ?? 'unknown',
            ttsId: voice.lang,
          }))
          .filter((voice) =>
            Object.values(mapDetectedToTTSid).includes(voice.ttsId)
          ),
        'ttsId'
      );
    }
    this.findLanguageFor(this.text);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.text) {
      this.findLanguageFor(changes.text.currentValue);
    }
  }

  private findLanguageFor(text: string) {
    const detectedLanguage = this.detectLanguage(text);
    if (this.supportedLanguages.length) {
      this.selectedLanguage =
        this.supportedLanguages.find(
          (lang) => lang.ttsId === detectedLanguage
        ) ?? this.supportedLanguages[0];
    }
  }

  detectLanguage(text: string) {
    const detector = new LanguageDetect();
    const detect = detector.detect(text, 1);
    let mostLikelyLanguage;
    if (detect[0] && detect[0][0]) {
      mostLikelyLanguage = detect[0][0];
    }

    if (mostLikelyLanguage) {
      return mapDetectedToTTSid[mostLikelyLanguage] ?? undefined;
    } else {
      return undefined;
    }
  }

  playTTS() {
    if (!this.supportsSpeech) {
      return;
    }
    if (this.speech.speaking()) {
      this.speech.cancel();
      return;
    }

    if (this.selectedLanguage) {
      this.speech.setLanguage(this.selectedLanguage.ttsId);
    }

    if (this.text) {
      this.speech.speak({ text: this.text });
    }
  }
}
