import {
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { AuthService } from 'src/app/auth/auth.service';

import { StatsService } from 'src/app/api/services';
import { NavigationBreadcrumb } from '../../common/navigation-breadcrumbs/navigation-breadcrumbs.component';
import { NavigationEnd, Router } from '@angular/router';
import { MediaMatcher } from '@angular/cdk/layout';
@Component({
  selector: 'qa-content-listing',
  templateUrl: './content-listing.component.html',
  styleUrls: ['./content-listing.component.scss'],
})
export class ContentListingComponent implements OnInit, OnDestroy {
  @ViewChild('sidenav') sidenav: any;
  navGroups = [];
  showSidebar = true;
  private _mobileQueryListener: () => void;
  mobileQuery: MediaQueryList;

  navigationBreadcrumb: NavigationBreadcrumb = {
    homeUrl: '/cms/dashboard',
    currentPage: null,
    breadcrumbs: [],
  };

  constructor(
    changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher,
    public auth: AuthService,
    private statsService: StatsService,
    private router: Router
  ) {
    this.mobileQuery = media.matchMedia('(max-width: 1350px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.toggleSidenav();
        this.showSidebar = router.url.split('/').length === 4 ? true : false;
      }
    });
  }

  ngOnInit() {
    this.getItemCount();
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
  }

  toggleSidenav() {
    if (this.sidenav && this.mobileQuery?.matches) {
      this.sidenav.toggle();
    }
  }

  getItemCount() {
    this.statsService.statsControllerGetStats().subscribe((res) => {
      if (res) {
        this.setNavBar(res);
      }
    });
  }

  setNavBar(res) {
    this.navGroups = [
      {
        active: () => this.auth.isLoggedIn(),
        expandable: true,
        icon: 'view_list',
        items: [
          {
            label: 'menu.quiz',
            link: 'quizzes',
            disabledTip: 'menu.cantAccess.quiz',
            itemCount: res.num_quizzes,
          },
          {
            label: 'common.label.questions',
            link: 'questions',
            disabledTip: 'menu.cantAccess.questions',
            itemCount: res.num_questions,
          },
          {
            label: 'common.label.flashCardStack',
            link: 'flash-card-stacks',
            disabledTip: 'menu.cantAccess.flashStacks',
            itemCount: res.num_flash_card_stacks,
          },
          {
            label: 'common.label.quizFlashCard',
            link: 'flash-cards',
            disabledTip: 'menu.cantAccess.flashcards',
            itemCount: res.num_flash_cards,
          },
          {
            label: 'menu.comments',
            link: 'comments',
            disabledTip: 'menu.cantAccess.comments',
          },
          {
            label: 'menu.tags',
            link: 'tags',
            disabledTip: 'menu.cantAccess.tags',
          },
        ],
        label: 'menu.allContent',
      },
    ];
  }
}
