import { Component, Inject } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { PanelEntityType } from './panel.types';

export interface ImportResultBottomSheetData {
  info: {
    current: number;
    total: number;
    failedItems: Array<{ item: any; type: PanelEntityType; error: any }>;
  };
}

@Component({
  template: `
    <div class="mat-headline-6">
      {{ info.current }} {{ 'importResult.from' | translate }} {{ info.total }}
      {{ ' Inhalten wurden erfolgreich importiert.' }}
    </div>
    <ng-container *ngIf="info.failedItems?.length">
      <div class="mat-h3">{{ 'importResult.notImported' | translate }}:</div>
      <div *ngFor="let failedItem of info.failedItems" class="failed-item">
        <div class="mat-caption">
          {{ 'importResult.content' | translate }}
        </div>
        <div>
          {{ failedItem.item.text || failedItem.item.name | truncate : 20 }}
        </div>
        <div class="mat-caption">
          {{ 'importResult.error' | translate }}
        </div>
        <div>
          {{
            failedItem.error?.error?.message ||
              failedItem.error?.message ||
              (failedItem.error | json)
          }}
        </div>
      </div>
    </ng-container>
  `,
})
export class ImportResultBottomSheetComponent {
  get info() {
    return this.data.info;
  }

  constructor(
    @Inject(MAT_BOTTOM_SHEET_DATA) private data: ImportResultBottomSheetData
  ) {}
}
