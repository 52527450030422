import { Injectable } from '@angular/core';
import { Edit } from '../edit.types';
import { TranslateService } from '@ngx-translate/core';
import { Observable, forkJoin, map, of, switchMap } from 'rxjs';
import { UserJourneyService } from '../../pages/onboarding-steps/user-journey.service';
import { LiveEvent } from '../../../api/models';
import {
  BooksService,
  CoursesService,
  LiveEventsService,
} from 'src/app/api/services';
import { Router } from '@angular/router';
import { SubscriptionLimitService } from '../../common/dialogs/subscription-limit-warning/subscription-limit.service';
import { SubscriptionLimit } from '../../common/dialogs/subscription-limit-warning/subscription-limit-warning.component';

@Injectable()
export class LiveEventEditConfigService extends Edit.ConfigService<LiveEvent> {
  constructor(
    liveEventsService: LiveEventsService,
    translator: TranslateService,
    coursesService: CoursesService,
    booksService: BooksService,
    userJourney: UserJourneyService,
    router: Router,
    subscriptionLimit: SubscriptionLimitService
  ) {
    super({
      itemName: translator.instant('common.label.liveEvent'),
      id: 'live-event',
      deleteConfirmText: translator.instant('exam.deleteConfirmText'),
      deleteConfirmTitle: translator.instant('exam.deleteConfirmTitle'),
      fields: [
        {
          key: 'name',
          label: 'Name',
          required: true,
        },
      ],
      handlers: {
        update: (newValue) => {
          return liveEventsService
            .updateLiveEvent({
              id: newValue.id,
              body: newValue,
            })
            .toPromise();
        },
        create: (newValue) => {
          return liveEventsService
            .createLiveEvent({
              body: newValue,
            })
            .pipe(
              switchMap((liveEvent) => {
                userJourney.set('createdExam');
                const courseId = this.config?.params?.courseId;
                const isBook = router.url?.indexOf('book') !== -1;
                let saveRequest$: Observable<LiveEvent | void>;
                if (courseId && !isBook) {
                  saveRequest$ = coursesService.addEventToCourse({
                    id: courseId,
                    body: { ...newValue, id: liveEvent.id },
                  });
                } else if (courseId && isBook) {
                  saveRequest$ = booksService.addEventToBook({
                    id: courseId,
                    body: { ...newValue, id: liveEvent.id },
                  });
                }
                if (courseId) {
                  return forkJoin([of(liveEvent), saveRequest$]);
                } else {
                  return forkJoin([of(liveEvent), of(null)]);
                }
              }),
              map(([liveEvent, _]) => liveEvent)
            )
            .toPromise();
        },
        delete: (newValue) => {
          return liveEventsService
            .deleteLiveEvent({ id: newValue.id })
            .toPromise();
        },
      },
      afterOpened: () =>
        subscriptionLimit.openDialog(SubscriptionLimit.LIVE_EVENT_COUNT),
    });
  }
}
