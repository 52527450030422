<h1 mat-dialog-title>{{ 'imageCrop.title' | translate }}</h1>
<mat-dialog-content>
  <div class="menu-bar">
    <div class="menu-bar-group">
      <div class="cropper-container">
        <span
          *ngIf="cropper"
          (focusout)="baseCropperAspect = cropperAspect.x / cropperAspect.y"
        >
          <input matInput type="number" min="1" [(ngModel)]="cropperAspect.x" />
          <span>:</span>
          <input matInput type="number" min="1" [(ngModel)]="cropperAspect.y" />
        </span>
        <button mat-button (click)="lockAspect()">
          <mat-icon *ngIf="aspectLocked">lock</mat-icon>
          <mat-icon *ngIf="!aspectLocked">lock_open</mat-icon>
        </button>
      </div>
    </div>
    <div class="menu-bar-group">
      <div class="recommendedAspects">
        <span style="opacity: 0.8"
          >{{ 'common.label.recommendedAspects' | translate }}:</span
        >
        <button
          mat-button
          (click)="aspectLocked = true; baseCropperAspect = 1 / 1"
        >
          1:1
        </button>
        <button
          mat-button
          (click)="aspectLocked = true; baseCropperAspect = 4 / 3"
        >
          4:3
        </button>
        <button
          mat-button
          (click)="aspectLocked = true; baseCropperAspect = 16 / 9"
        >
          16:9
        </button>
        <button
          mat-button
          (click)="aspectLocked = true; baseCropperAspect = 18 / 13"
        >
          18:13
        </button>
      </div>
    </div>
  </div>
  <image-cropper
    [imageFile]="data.file"
    [maintainAspectRatio]="aspectLocked"
    [aspectRatio]="baseCropperAspect"
    (imageCropped)="
      croppedImage = $event.base64; cropper = $event.cropperPosition
    "
    (pointerup)="setCropperAspect()"
    (cropperReady)="setCropperAspect()"
    format="png"
    #cropperElement
  >
  </image-cropper>
</mat-dialog-content>
<mat-dialog-actions>
  <qa-button label="common.button.abort" mat-dialog-close></qa-button>
  <qa-button label="imageCrop.dontCrop" (submitted)="dontCrop()"></qa-button>
  <qa-button
    [isRaisedButton]="true"
    label="imageCrop.crop"
    (submitted)="crop()"
  ></qa-button>
</mat-dialog-actions>
