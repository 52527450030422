/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { TagModel } from '../models/tag-model';
import { TagQuery } from '../models/tag-query';
import { TagQueryResponse } from '../models/tag-query-response';

@Injectable({
  providedIn: 'root',
})
export class TagsService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation tagControllerGetStats
   */
  static readonly TagControllerGetStatsPath = '/tags/query';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `tagControllerGetStats()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  tagControllerGetStats$Response(params: {
    cognitouserid?: string;
    cognitousergroup?: string;
    context?: HttpContext
    body: TagQuery
  }
): Observable<StrictHttpResponse<TagQueryResponse>> {

    const rb = new RequestBuilder(this.rootUrl, TagsService.TagControllerGetStatsPath, 'post');
    if (params) {
      rb.header('cognitouserid', params.cognitouserid, {});
      rb.header('cognitousergroup', params.cognitousergroup, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TagQueryResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `tagControllerGetStats$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  tagControllerGetStats(params: {
    cognitouserid?: string;
    cognitousergroup?: string;
    context?: HttpContext
    body: TagQuery
  }
): Observable<TagQueryResponse> {

    return this.tagControllerGetStats$Response(params).pipe(
      map((r: StrictHttpResponse<TagQueryResponse>) => r.body as TagQueryResponse)
    );
  }

  /**
   * Path part for operation updateUserTags
   */
  static readonly UpdateUserTagsPath = '/tags/updateUserTags';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateUserTags()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateUserTags$Response(params: {
    cognitouserid?: string;
    cognitousergroup?: string;
    context?: HttpContext
    body: TagModel
  }
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, TagsService.UpdateUserTagsPath, 'post');
    if (params) {
      rb.header('cognitouserid', params.cognitouserid, {});
      rb.header('cognitousergroup', params.cognitousergroup, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateUserTags$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateUserTags(params: {
    cognitouserid?: string;
    cognitousergroup?: string;
    context?: HttpContext
    body: TagModel
  }
): Observable<void> {

    return this.updateUserTags$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation detachUserTag
   */
  static readonly DetachUserTagPath = '/tags/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `detachUserTag()` instead.
   *
   * This method doesn't expect any request body.
   */
  detachUserTag$Response(params: {
    id: number;
    cognitouserid?: string;
    cognitousergroup?: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, TagsService.DetachUserTagPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
      rb.header('cognitouserid', params.cognitouserid, {});
      rb.header('cognitousergroup', params.cognitousergroup, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `detachUserTag$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  detachUserTag(params: {
    id: number;
    cognitouserid?: string;
    cognitousergroup?: string;
    context?: HttpContext
  }
): Observable<void> {

    return this.detachUserTag$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

}
