<div mat-dialog-title class="main-header-box">
  <div class="cross-icon close-icon">
    <mat-icon mat-dialog-close>clear</mat-icon>
  </div>
</div>
<mat-dialog-content class="dialog-contents">
  <form [formGroup]="form" class="dialog-form">
    <div class="model-header m-bottom" [innerHTML]="data.modelTitle"></div>
    <ng-container *ngIf="data.modelType">
      <mat-checkbox
        class="m-bottom"
        formControlName="convert_from_quiz"
        color="primary"
      >
        {{ 'common.label.yes' | translate }}
      </mat-checkbox>
      <div
        class="model-header m-bottom"
        *ngIf="data.modelInfo"
        [innerHTML]="data.modelInfo"
      ></div>
    </ng-container>
  </form>
</mat-dialog-content>
<mat-dialog-actions class="button-container">
  <qa-button
    label="course.confirmAction.cancelCreation"
    mat-dialog-close
  ></qa-button>
  <qa-button
    [isRaisedButton]="true"
    label="{{ data.confirmButtonText }}"
    (submitted)="convertQuiz()"
  ></qa-button>
</mat-dialog-actions>
