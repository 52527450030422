<qa-content-spinner [active]="loading">
  <div class="answer-wrapper">
    <div class="answer-stats-wrapper" [class.loader]="loading">
      <table
        class="user-overview"
        [class.freeText]="question.type === questionType.FREETEXT"
      >
        <tr>
          <td>
            <div class="question-stat-data">
              <button mat-button (click)="goBackToResult()">
                <mat-icon class="back-btn">keyboard_backspace</mat-icon>
                {{ 'exam.result.backToOverView' | translate }}
              </button>
              <div class="question-data">
                <ng-container
                  *ngIf="
                    question?.type !== questionType.FREETEXT &&
                      !question?.is_poll_question;
                    else freeTextHint
                  "
                >
                  <div
                    class="right-or-wrong flex-row flex-justify-content-space-between flex-align-items-center"
                  >
                    <div class="bar">
                      <div
                        [style.width]="getRightResult(question)"
                        class="right-ans"
                      ></div>
                      <div
                        [style.width]="getWrongResult(question)"
                        class="wrong-ans"
                      ></div>
                    </div>
                  </div>
                  <div>
                    {{ question.num_correct_answers }}/{{
                      question.total_result - question.num_correct_answers
                    }}{{ !hideUnAnswered ? '/' + question.num_unanswered : '' }}
                  </div>
                  <div
                    *ngIf="
                      question?.type !== questionType.FREETEXT &&
                      !question?.is_poll_question
                    "
                  >
                    {{ getCorrectRatio(question) }}
                  </div>
                </ng-container>
                <ng-template #freeTextHint>
                  <span class="open-question-indicator">
                    {{
                      'exam.result.numOfAnswer'
                        | translate : { count: question.total_num_answers }
                    }}
                  </span>
                </ng-template>
              </div>
            </div>
          </td>
          <td>
            <qa-import-exam-navigation
              [currentItemPosition]="currentItemIndex + 1"
              [itemCount]="allQuestions?.length"
              (navigation)="onNavigate($event)"
            >
            </qa-import-exam-navigation>
          </td>
        </tr>
      </table>
      <div class="visualization-selector" *ngIf="question?.is_poll_question">
        <mat-button-toggle-group
          [value]="selectedVisualization"
          (change)="selectedVisualization = $event.value"
        >
          <mat-button-toggle
            value="cloud"
            *ngIf="question?.type === questionType.FREETEXT"
            ><mat-icon>abc</mat-icon></mat-button-toggle
          >
          <mat-button-toggle value="list"
            ><mat-icon>view_list</mat-icon></mat-button-toggle
          >
          <mat-button-toggle value="donut"
            ><mat-icon>donut_large</mat-icon></mat-button-toggle
          >
        </mat-button-toggle-group>
      </div>
      <div class="exam-name">
        <qa-image-display
          [model]="question"
          *ngIf="question?.media?.uuid"
        ></qa-image-display>
        <qa-markdown-katex class="question-over-text" [data]="question.text">
        </qa-markdown-katex>
        <div
          class="info-containers"
          [class.hasExplanation]="question?.has_explanation"
        >
          <div
            class="sample-answer"
            *ngIf="question?.type === questionType.FREETEXT"
          >
            <qa-expandable-info
              [info]="sampleAnswer"
              icon="info"
              [title]="'question.label.sampleAnswer' | translate"
            ></qa-expandable-info>
          </div>
          <qa-expandable-info
            [info]="question?.explanation"
            icon="help"
            [title]="'question.form.explanation' | translate"
          ></qa-expandable-info>
        </div>
      </div>
      <div class="answers-wrapper">
        <ng-container
          *ngIf="
            question?.is_poll_question &&
              question?.type === questionType.FREETEXT &&
              selectedVisualization === 'cloud';
            else donutVisualization
          "
        >
          <qa-word-cloud-visualization
            [words]="mapToStrings()"
          ></qa-word-cloud-visualization>
        </ng-container>
        <ng-template #donutVisualization>
          <ng-container
            *ngIf="
              selectedVisualization === 'donut' && question.is_poll_question;
              else normalAnswer
            "
          >
            <qa-donut-chart
              [sections]="answerSections"
              [sort]="true"
              [large]="true"
            >
            </qa-donut-chart>
          </ng-container>
        </ng-template>
        <ng-template #normalAnswer>
          <ng-container
            *ngIf="
              question.type !== questionType.FREETEXT ||
                (question.type === questionType.FREETEXT &&
                  question.is_poll_question);
              else freetextResults
            "
          >
            <div
              *ngFor="let answerStat of question?.answers"
              [class.correct]="answerStat?.is_right"
              [class.isPoll]="question?.is_poll_question"
              class="answer"
            >
              <div class="radio-checkbox" *ngIf="!question?.is_poll_question">
                <mat-checkbox
                  disabled
                  [checked]="answerStat?.is_right"
                  [ngClass]="{
                    'disabled-checkbox': answerStat?.is_right === false
                  }"
                  *ngIf="question.type === questionType.MULTIPLE_CHOICE"
                ></mat-checkbox>
                <div
                  class="radio-button"
                  *ngIf="question.type !== questionType.MULTIPLE_CHOICE"
                >
                  <div class="mat-radio-inner-circle-style"></div>
                </div>
              </div>
              <div class="answer-text">
                <div class="top">
                  <qa-markdown-katex
                    [data]="
                      question.type === questionType.TRUE_FALSE
                        ? translateTrueFalse(answerStat.text)
                        : answerStat.text
                    "
                    class="text"
                  >
                  </qa-markdown-katex>
                  <div class="amount">
                    {{ answerStat.num_selected }}
                  </div>
                </div>
                <div class="bar">
                  <div
                    [style.width]="getAnswerBarWidth(answerStat.num_selected)"
                    class="piece"
                  ></div>
                </div>
              </div>
            </div>
          </ng-container>
        </ng-template>
        <ng-template #freetextResults>
          <div class="freetext-answers">
            <div class="header">
              <span class="name">{{ 'Name' | translate }}</span>
              <span class="text">{{ 'question.form.answer' | translate }}</span>
              <span class="state">
                <qa-custom-chip [label]="labelAnswers"></qa-custom-chip>
              </span>
              <span class="grade">{{ 'exam.result.points' | translate }}</span>
            </div>
            <div
              class="freetext-answer"
              *ngFor="let submission of question?.results"
            >
              <div class="name">{{ submission.user?.username }}</div>
              <div class="text">
                <div class="user-answer-container">
                  <div class="user-answer">
                    <div class="text-shorten">
                      {{ submission?.free_text_answer }}
                    </div>
                  </div>
                  <div class="reply" [class.active]="submission?.addReply">
                    <mat-form-field appearance="fill">
                      <mat-label>{{
                        'exam.result.reply' | translate
                      }}</mat-label>
                      <textarea
                        matInput
                        type="text"
                        rows="10"
                        [(ngModel)]="submission.free_text_feedback"
                      ></textarea>
                    </mat-form-field>
                    <div class="comment-submit-actions">
                      <qa-button
                        label="common.button.abort"
                        (submitted)="submission.addReply = !submission.addReply"
                        type="submit"
                      ></qa-button>
                      <qa-button
                        [isRaisedButton]="true"
                        label="common.button.save"
                        (submitted)="setFeedback.emit(submission)"
                        type="submit"
                      ></qa-button>
                    </div>
                  </div>
                </div>
                <button
                  mat-icon-button
                  class="add-reply"
                  [matTooltip]="'Antworten'"
                  (click)="submission.addReply = !submission.addReply"
                >
                  <mat-icon
                    [class.material-icons]="submission.free_text_feedback"
                    >comment</mat-icon
                  >
                </button>
                <button
                  mat-icon-button
                  *ngIf="isExam && isAdmin"
                  class="add-reply"
                  [matTooltip]="'AIQuestionImports.evalProposal' | translate"
                  (click)="generateExplanation(submission)"
                >
                  <img
                    src="assets/icons/chat-gpt.svg"
                    alt="gpt"
                    class="ng-tns-c551-113"
                  />
                </button>
              </div>
              <div
                class="state"
                [class.open]="
                  submission?.score === null || submission?.score === undefined
                "
                [class.done]="
                  submission?.score !== undefined &&
                  submission?.score !== null &&
                  submission?.score !== 0
                "
                [class.close]="submission?.score === 0"
              >
                <button mat-icon-button>
                  <mat-icon
                    *ngIf="
                      submission?.score !== undefined &&
                      submission?.score !== null &&
                      submission?.score !== 0
                    "
                    >done</mat-icon
                  >
                  <mat-icon *ngIf="submission?.score === 0">close</mat-icon>
                  <mat-icon
                    *ngIf="
                      submission?.score === null ||
                      submission?.score === undefined
                    "
                    >question_mark</mat-icon
                  >
                </button>
              </div>
              <div class="grade">
                <span
                  class="grade-select"
                  [matMenuTriggerFor]="gradeSelectMenu"
                  [class.freeText]="question.type === questionType.FREETEXT"
                >
                  {{ submission?.score ?? '---' }}
                </span>
                <mat-menu #gradeSelectMenu="matMenu">
                  <button
                    mat-menu-item
                    (click)="selectScoreQuestion({submission, score: null})"
                  >
                    ---
                  </button>
                  <button
                    mat-menu-item
                    *ngFor="
                      let scoreStep of scoreSteps(question.max_score ?? 0)
                    "
                    (click)="selectScoreQuestion({submission, score: scoreStep})"
                  >
                    {{ scoreStep }}
                  </button>
                </mat-menu>
                <span class="total-score">/ {{ question.max_score ?? 0 }}</span>
              </div>
            </div>
          </div>
        </ng-template>
      </div>
    </div>
  </div>
</qa-content-spinner>
