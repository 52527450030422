<div class="nav-container">
  <div class="navigation-arrows">
    <qa-button
      [isRaisedButton]="true"
      [disabled]="currentItemPosition === 1"
      [isIconButton]="true"
      icon="arrow_back"
      (submitted)="previous()"
    ></qa-button>
    <div>
      {{ currentItemNumber | number : '2.0-0' }} <span>/</span>
      {{ itemCount | number : '2.0-0' }}
    </div>
    <qa-button
      [isRaisedButton]="true"
      [disabled]="currentItemPosition === itemCount && !isImport"
      [isIconButton]="true"
      icon="arrow_forward"
      (submitted)="next()"
    ></qa-button>
  </div>
  <div class="progress-container">
    <div class="progress-border">
      <div
        class="progress"
        [ngStyle]="{
          width: getPercentage(itemCount, currentItemPosition) + '%'
        }"
      ></div>
    </div>
  </div>
</div>
