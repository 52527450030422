import { Injectable } from '@angular/core';
import { QuestionModel } from 'src/app/api/models';
import { QuestionsService, LiveEventsService } from 'src/app/api/services';
import { questionTypeDisplayFn } from 'src/app/app.types';
import { PanelService } from '../panel.service';
import { Panel, PanelEntityType } from '../panel.types';
import { TranslateService } from '@ngx-translate/core';
import { ImportEntity } from '../../list/list.types';
import { MatDialog } from '@angular/material/dialog';
import {
  ImportAiQuestionComponent,
  ImportQuestionType,
} from '../../common/dialogs/import-ai-question/import-ai-question.component';
import { AuthService } from 'src/app/auth/auth.service';

export const getEventQuestionImports = (
  translator: TranslateService,
  panelService: PanelService,
  matDialog: MatDialog,
  panel: Panel<QuestionModel>,
  courseContext = false,
  liveEventId?: number,
  isAdmin = false
): ImportEntity<QuestionModel>[] =>
  [
    isAdmin
      ? {
          id: 'chat-gpt',
          title: translator.instant(
            'content.question.description.aiImortTitle'
          ),
          description: translator.instant(
            'content.question.description.aiImportDescription'
          ),
          highlight: true,
          action: {
            icon: 'code',
            click: () => {
              matDialog
                .open<ImportAiQuestionComponent, ImportQuestionType>(
                  ImportAiQuestionComponent,
                  {
                    data: {
                      type: 'event-question',
                    },
                    width: '600px',
                    maxHeight: '90vh',
                  }
                )
                .afterClosed()
                .subscribe((r) => {
                  if (r) {
                    matDialog.closeAll();
                    panelService.importChatGPT$.next(true);
                  }
                });
            },
            text: translator.instant('content.question.addMenu.item9'),
          },
        }
      : null,
    {
      title: translator.instant('content.examQuestion.title.item4'),
      description: translator.instant('content.question.description.item6'),
      action: {
        icon: 'code',
        click: () => {
          if (courseContext) {
            panelService.importFromChatGPT(panel);
          } else {
            const callback = () => {
              const queryParams = {
                type: PanelEntityType.EventQuestion,
              };
              panelService.redirectToImportItems(
                `/cms/csv-imports/question/${liveEventId}`,
                queryParams,
                true
              );
            };
            panelService.importFromChatGPT(panel, () => callback());
          }
        },
        text: translator.instant('content.question.addMenu.item9'),
      },
    },
    {
      title: translator.instant('content.question.title.item1'),
      description: translator.instant('content.question.description.item1'),
      action: {
        icon: 'move_to_inbox',
        click: () => {
          if (!courseContext && liveEventId) {
            const queryParams = {
              parentId: liveEventId,
              type: PanelEntityType.EventQuestion,
            };
            panelService.redirectToImportItems(
              `/cms/import-items`,
              queryParams,
              true
            );
          } else {
            panelService.importItems(panel);
          }
        },
        text: translator.instant('content.question.addMenu.item2'),
      },
    },
    {
      title: translator.instant('content.question.title.item3'),
      description: translator.instant('content.question.description.item3'),
      action: {
        icon: 'description',
        click: () => {
          if (!courseContext && liveEventId) {
            const queryParams = {
              type: PanelEntityType.EventQuestion,
            };
            panelService.redirectToImportItems(
              `/cms/csv-imports/question/${liveEventId}`,
              queryParams,
              true
            );
          } else {
            panelService.importItemAsCSV(panel);
          }
        },
        text: translator.instant('content.question.addMenu.item4'),
      },
      extra: {
        name: translator.instant('content.question.title.extra'),
        type: 'link' as 'link',
        icon: 'download',
        click: () => {
          window.open(
            'assets/import-csv-templates/Sample-import-questions.csv',
            '_blank'
          );
        },
      },
    },
    {
      id: 'import-json',
      title: translator.instant('content.question.title.item2'),
      description: translator.instant('content.question.description.item2'),
      action: {
        icon: 'code',
        click: () => panelService.importItemAsJSON(panel),
        text: translator.instant('content.question.addMenu.item3'),
      },
    },
  ].filter((n) => n);

@Injectable()
export class EventQuestionPanel extends Panel<QuestionModel> {
  constructor(
    panelService: PanelService,
    questions: QuestionsService,
    matDialog: MatDialog,
    events: LiveEventsService,
    translator: TranslateService,
    auth: AuthService
  ) {
    super();
    super.init({
      addMenuItems: [
        {
          icon: 'add',
          onClick: () => panelService.editItem(this),
          text: translator.instant('content.question.addMenu.item1'),
        },
      ],
      showChatGptAction:
        auth.currentUserHasGroup('Admin') ||
        auth.currentUserHasGroup('ContentManager'),
      imports: getEventQuestionImports(
        translator,
        panelService,
        matDialog,
        this,
        true,
        null,
        auth.currentUserHasGroup('Admin') ||
          auth.currentUserHasGroup('ContentManager')
      ),
      changeItemPosition: (questionId, eventId, position) => {
        return events.repositionQuestionInEvent({
          id: eventId,
          question_id: questionId,
          body: { position },
        });
      },
      createItemsFn: (items, parentId) =>
        events.copyQuestionToEvent({
          id: parentId,
          body: items,
        }),
      deleteItem: (questionId) => questions.deleteQuestion({ id: questionId }),
      editDialogInput: (question, eventId) => ({
        enableNavigation: true,
        question,
        eventId,
      }),
      fetchItem: (id) => questions.getQuestionById({ id }),
      fetchItems: (eventId, additionalData) =>
        additionalData
          ? events.getQuestionsForEvent({
              id: eventId,
              embed_answers: true,
              embed_tags: true,
            })
          : events.getQuestionsForEvent({
              id: eventId,
            }),
      headerIcon: 'question_answers',
      itemActions: [
        {
          icon: 'edit',
          label: translator.instant('common.tooltip.edit'),
          onClick: (question) => panelService.editItem(this, question),
        },
        {
          icon: 'code',
          label: translator.instant('content.question.actionMenu.item2'),
          onClick: (item) => panelService.exportItemAsJSON(this, item.id),
        },
        {
          icon: 'link_off',
          label: translator.instant('content.question.actionMenu.item3'),
          disabled: (question) => question['isImported'],
          tooltip: (question) =>
            question['isImported']
              ? translator.instant('content.quiz.notYourQuetion')
              : null,
          onClick: (question) => panelService.unlinkItems(this, question.id),
        },
        {
          icon: 'delete',
          label: translator.instant('content.question.actionMenu.item4'),
          disabled: (question) => question['isImported'],
          tooltip: (question) =>
            question['isImported']
              ? translator.instant('content.quiz.notYourQuetion')
              : null,
          onClick: (question) => panelService.deleteItem(this, question.id),
        },
      ],
      itemSuffixes: (question) => {
        const type = questionTypeDisplayFn(question.type, true) ?? '?';
        const suffixes = [{ text: type, icon: null }];
        return suffixes;
      },
      itemTextProperty: 'text',
      parentKey: 'questions',
      parentType: PanelEntityType.Event,
      selectedItemActions: [
        {
          icon: 'link_off',
          label: translator.instant('content.question.selectedItem.label'),
          onClick: (selectedItemIds) =>
            panelService.unlinkItems(this, selectedItemIds),
        },
      ],
      translations: {
        addItem: translator.instant('content.question.tooltip'),
        deleteItemError: translator.instant(
          'content.question.deleteItem.error'
        ),
        deleteItemSuccess: translator.instant(
          'content.question.deleteItem.success'
        ),
        deleteItemText: translator.instant('content.question.deleteItem.text'),
        deleteItemTitle: translator.instant(
          'content.question.deleteItem.title'
        ),
        header: translator.instant('common.label.questions'),
        unlinkItemConfirmText: translator.instant(
          'content.question.unlinkItem.text'
        ),
        unlinkItemConfirmTitle: translator.instant(
          'content.question.unlinkItem.title'
        ),
        unlinkItemError: translator.instant(
          'content.question.unlinkItem.error'
        ),
        unlinkItemSuccess: translator.instant(
          'content.question.unlinkItem.success'
        ),
      },
      type: PanelEntityType.EventQuestion,
      unlinkItems: (questionIds, eventId) =>
        events.removeQuestionFromEvent({
          id: eventId,
          question_ids: questionIds.join(','),
        }),
    });
  }
}
