<div class="edit-flash-card" [class.fromCourse]="flashcardId">
  <form [formGroup]="form">
    <qa-content-spinner
      [active]="loading"
      [class.preventOverflow]="flashcardId"
    >
      <div class="content-flashcard">
        <div class="main-flex flex-row flex-auto-margin" *ngIf="showForm">
          <mat-card
            appearance="outlined"
            [ngClass]="{ create: !courseId }"
            *ngIf="!showMobilePreview"
          >
            <div
              class="flashcard-header"
              *ngIf="
                (!flashCard && !duplicateFlashCardId) ||
                (flashCard && duplicateFlashCardId) ||
                (!flashCard && !form.value.id && !questionId)
              "
            >
              <h1
                *ngIf="!flashCard && !duplicateFlashCardId"
                class="create-title"
              >
                {{
                  (form.value.id
                    ? 'flashCard.editTitle'
                    : questionId
                    ? 'common.button.convertToFlashCard'
                    : 'flashCard.addTitle'
                  ) | translate
                }}
              </h1>
              <h1
                *ngIf="flashCard && duplicateFlashCardId"
                class="create-title"
              >
                {{ 'flashCard.duplicateFlashCardTitle' | translate }}
              </h1>
              <div
                class="template-select"
                *ngIf="!flashCard && !form.value.id && !questionId"
              >
                <button
                  mat-button
                  (click)="$event.stopPropagation(); $event.preventDefault()"
                  [matMenuTriggerFor]="templateSelectMenu"
                >
                  {{ 'common.button.applyTemplate' | translate
                  }}<mat-icon>play_circle</mat-icon>
                </button>
                <mat-menu #templateSelectMenu="matMenu">
                  <button
                    mat-menu-item
                    *ngFor="let template of templates"
                    (click)="applyTemplate(template.template)"
                  >
                    {{ template.title }}
                  </button>
                </mat-menu>
              </div>
            </div>
            <p class="explanation" *ngIf="questionId">
              {{ 'common.message.conversionHint.flashCard' | translate }}
              <b>{{
                'common.message.conversionHint.flashCard2' | translate
              }}</b>
              {{ 'common.message.conversionHint.flashCard3' | translate }}
              <a
                target="_blank"
                href="https://quizacademy.de/tipps/content-konvertieren/"
              >
                {{ 'common.message.conversionHint.moreInfoLink' | translate }}.
              </a>
            </p>
            <p class="explanation" *ngIf="duplicateFlashCardId">
              {{ 'common.message.duplicateHint.flashCard' | translate }}
            </p>
            <ng-scrollbar>
              <div class="flash-card-form">
                <div class="preview">
                  <qa-form-field-toggle
                    *ngIf="flashcardId"
                    (valueChanged)="onValueChange($event)"
                    [label]="
                      'exam.users.accessRestriction.generateDialog.preview'
                        | translate
                    "
                  >
                  </qa-form-field-toggle>
                </div>
                <div class="attach-media">
                  <ng-container
                    *ngIf="
                      !form?.value?.media.id &&
                        !form?.value?.youtube_link_question;
                      else fileInfo
                    "
                  >
                    <button
                      (click)="openDialog()"
                      class="attach-media-button"
                      color="primary"
                      mat-button
                      tabIndex="-1"
                      type="button"
                    >
                      <mat-icon class="material-icons">upload</mat-icon>
                      {{ 'flashCard.form.attachMedia' | translate }}
                    </button>
                    <div class="que-toggle" *ngIf="form?.value?.media?.id">
                      <span
                        [class.active]="!form.value.video_question_or_answer"
                        >{{ 'common.label.question' | translate }}</span
                      >
                      <qa-form-field-toggle
                        [control]="form.get('video_question_or_answer')"
                      >
                      </qa-form-field-toggle>
                      <span
                        [class.active]="form.value.video_question_or_answer"
                        >{{ 'flashCard.form.answer' | translate }}</span
                      >
                      <mat-icon
                        class="info-tool-tip cursor-pointer"
                        (click)="openInfoDialog()"
                        >info</mat-icon
                      >
                    </div>
                  </ng-container>
                  <ng-template #fileInfo>
                    <div class="flex-row flex-align-items-end image-preview">
                      <div
                        class="image-name flex-grow"
                        *ngIf="!form?.value?.youtube_link_question"
                      >
                        <img
                          src="https://s3.{{
                            's3.mediaBucket.region' | env
                          }}.amazonaws.com/{{ 's3.mediaBucket.name' | env }}/{{
                            form.value.media.uuid
                          }}.{{ form.value.media.file_ext }}"
                          *ngIf="form?.value?.media?.id"
                          class="image"
                        />
                      </div>
                      <div
                        class="link flex-grow"
                        *ngIf="form?.value?.youtube_link_question"
                      >
                        {{ form?.value?.youtube_link_question }}
                      </div>
                      <button
                        (click)="removeImage()"
                        [matTooltip]="'Löschen'"
                        mat-icon-button
                        tabindex="-1"
                        type="button"
                      >
                        <mat-icon class="material-icons image-upload-icon"
                          >delete_outline</mat-icon
                        >
                      </button>
                      <button
                        (click)="showImage()"
                        *ngIf="!form?.value?.youtube_link_question"
                        mat-icon-button
                        tabindex="-1"
                        type="button"
                      >
                        <mat-icon class="material-icons image-upload-icon"
                          >zoom_out_map</mat-icon
                        >
                      </button>
                    </div>
                    <div
                      class="que-toggle question-answer-contents"
                      *ngIf="form?.value?.media?.id"
                    >
                      <span
                        [class.active]="!form.value.video_question_or_answer"
                        class="question-text"
                        >{{ 'common.label.question' | translate }}</span
                      >
                      <qa-form-field-toggle
                        [control]="form.get('video_question_or_answer')"
                      >
                      </qa-form-field-toggle>
                      <span
                        [class.active]="form.value.video_question_or_answer"
                        >{{ 'flashCard.form.answer' | translate }}</span
                      >
                      <mat-icon
                        class="info-tool-tip cursor-pointer info-icon"
                        (click)="openInfoDialog()"
                        >info</mat-icon
                      >
                    </div>
                  </ng-template>
                </div>
                <qa-form-field-input
                  [control]="form.get('text')"
                  [label]="'Text'"
                  [markdownHint]="true"
                  [multi]="true"
                  required="true"
                >
                </qa-form-field-input>
                <qa-form-field-input
                  [control]="form.get('answer')"
                  label="{{ 'flashCard.form.answer' | translate }}"
                  [markdownHint]="true"
                  [multi]="true"
                  required="true"
                >
                </qa-form-field-input>
                <qa-form-field-input
                  [control]="form.get('explanation')"
                  label="{{ 'flashCard.form.explanation' | translate }}"
                  [markdownHint]="true"
                  [multi]="true"
                >
                </qa-form-field-input>
                <qa-form-field-input
                  [control]="form.get('weblink')"
                  [label]="'Weblink'"
                >
                </qa-form-field-input>
                <qa-form-field-input
                  [control]="form.get('source')"
                  label="{{ 'question.form.source' | translate }}"
                >
                </qa-form-field-input>
                <qa-tag-input
                  *ngIf="!loading"
                  [control]="form.get('tags')"
                  label="{{ 'Tags' | translate }}"
                >
                </qa-tag-input>
                <div
                  class="hint-text"
                  [innerHTML]="'question.form.tagHint.hintText' | translate"
                ></div>
              </div>
            </ng-scrollbar>
            <div
              class="navigations"
              *ngIf="courseId && flashCard && form.value.id"
            >
              <button
                mat-mini-fab
                class="navigate-back"
                [disabled]="currentItemIndex == 0"
                (click)="onNavigate(currentItemIndex - 1)"
              >
                <mat-icon>arrow_back</mat-icon>
              </button>
              <button
                mat-mini-fab
                class="navigate-forward"
                [disabled]="currentItemIndex == allFlashcards.length - 1"
                (click)="onNavigate(currentItemIndex + 1)"
              >
                <mat-icon>arrow_forward</mat-icon>
              </button>
            </div>
          </mat-card>

          <div
            class="mock-phone-container"
            [class.showMobilePreview]="showMobilePreview"
            *ngIf="!flashcardId || (flashcardId && showMobilePreview)"
          >
            <div class="preview mobile" *ngIf="flashcardId">
              <qa-form-field-toggle
                (valueChanged)="onValueChange($event)"
                [checked]="true"
                [label]="
                  'exam.users.accessRestriction.generateDialog.preview'
                    | translate
                "
              >
              </qa-form-field-toggle>
            </div>
            <ng-scrollbar>
              <div
                class="mock-phone"
                [ngClass]="{ create: !courseId }"
                *ngIf="!loading"
              >
                <qa-flash-card-mock-phone
                  [currentItemPosition]="currentItemIndex + 1"
                  [itemCount]="allFlashcards?.length"
                  [flashCard]="form.value"
                >
                </qa-flash-card-mock-phone>
              </div>
            </ng-scrollbar>
            <div
              class="flex-column flex-align-items-center flex-justify-content-center"
              *ngIf="!showMobilePreview"
            >
              <qa-import-csv-navigation
                *ngIf="
                  (courseId || cardIdFromContent) && flashCard && form.value.id
                "
                [currentItemPosition]="currentItemIndex + 1"
                [itemCount]="allFlashcards?.length"
                (navigation)="onNavigate($event)"
                [isImport]="false"
              >
              </qa-import-csv-navigation>
            </div>
          </div>
        </div>
      </div>
    </qa-content-spinner>
    <div class="actions" *ngIf="!loading">
      <div class="action-container">
        <div class="left-actions">
          <ng-container *ngIf="!flashcardId">
            <qa-button
              *ngIf="flashCard && !duplicateFlashCardId"
              label="common.button.duplicate"
              (submitted)="duplicatedFlashCard()"
            ></qa-button>
            <qa-button
              *ngIf="id && flashCard.accepted"
              label="common.button.convertToQuestion"
              (submitted)="convertToQuestion()"
            ></qa-button>
          </ng-container>
          <qa-button
            *ngIf="id"
            [matMenuTriggerFor]="moreMenu"
            [isIconButton]="true"
            icon="more_vert"
          ></qa-button>
          <mat-menu
            class="material-menu"
            [overlapTrigger]="true"
            #moreMenu="matMenu"
          >
            <ng-container *ngIf="flashcardId">
              <button
                *ngIf="flashCard && !duplicateFlashCardId"
                (click)="duplicatedFlashCard()"
                mat-menu-item
                type="button"
              >
                <mat-icon>copy</mat-icon>
                {{ 'common.button.duplicate' | translate }}
              </button>
              <button
                *ngIf="id && flashCard.accepted"
                (click)="convertToQuestion()"
                mat-menu-item
                type="button"
              >
                <mat-icon>crop</mat-icon>
                {{ 'common.button.convertToQuestion' | translate }}
              </button>
            </ng-container>
            <button
              (click)="showComments()"
              *ngIf="hasComments"
              mat-menu-item
              type="button"
            >
              <mat-icon>comment</mat-icon>
              {{ 'comment.label.showComment' | translate }}
            </button>
            <button (click)="exportAsJson()" mat-menu-item type="button">
              <mat-icon>download</mat-icon>
              {{ 'content.course.actionMenu.item4' | translate }}
            </button>
            <button
              (click)="onDelete()"
              *ngIf="form.value.id"
              mat-menu-item
              type="button"
            >
              <mat-icon>delete</mat-icon>
              {{ 'common.button.clear' | translate }}
            </button>
          </mat-menu>
        </div>
        <div class="flex-align-right">
          <qa-button
            label="common.button.shutdown"
            (submitted)="onClose()"
          ></qa-button>
          <ng-container *ngIf="flashCard || questionId">
            <qa-button
              *ngIf="
                (flashCard &&
                  flashCard?.accepted &&
                  userId === flashCard?.created_by) ||
                !flashCard
              "
              [disabled]="form.invalid || form.pristine"
              [isRaisedButton]="true"
              label="common.button.save"
              (submitted)="onSubmit()"
            ></qa-button>
          </ng-container>
          <qa-button
            *ngIf="flashCard && !flashCard?.accepted"
            [disabled]="form.invalid || form.pristine"
            [isRaisedButton]="true"
            label="{{
              flashCardStackId
                ? 'common.button.saveWithoutAddToStack'
                : 'common.button.save'
            }}"
            (submitted)="onSubmit()"
          ></qa-button>
          <qa-button
            *ngIf="id && !flashCard?.accepted"
            [disabled]="form.invalid"
            [isRaisedButton]="true"
            label="{{
              flashCardStackId
                ? 'common.button.addFlashCardToStack'
                : 'common.button.acceptFlashCard'
            }}"
            (submitted)="addFlashCardToStack()"
          ></qa-button>
          <qa-button
            #addButton
            *ngIf="!flashCard && !questionId"
            [matMenuTriggerFor]="addMenu"
            [disabled]="form.invalid || form.pristine"
            [isRaisedButton]="true"
            label="common.button.save"
          ></qa-button>
          <mat-menu
            class="material-menu"
            [overlapTrigger]="true"
            #addMenu="matMenu"
          >
            <ng-container *ngIf="!flashcardId">
              <button
                (click)="onSubmit(true)"
                [disabled]="form.invalid || form.pristine"
                *ngIf="!form.value.id && !duplicateFlashCardId && !questionId"
                mat-menu-item
                type="button"
              >
                <mat-icon>add</mat-icon>
                {{ 'common.button.saveAndNew' | translate }}
              </button>
              <button
                (click)="onSubmit(true, true)"
                [disabled]="form.invalid || form.pristine"
                *ngIf="!form.value.id && !duplicateFlashCardId && !questionId"
                mat-menu-item
                type="button"
              >
                <mat-icon>copy</mat-icon>
                {{ 'common.button.saveAndDuplicate' | translate }}
              </button>
            </ng-container>
            <button
              [disabled]="form.invalid || form.pristine"
              (click)="onSubmit()"
              *ngIf="
                (flashCard &&
                  flashCard?.accepted &&
                  userId === flashCard?.created_by) ||
                !flashCard
              "
              mat-menu-item
              type="button"
            >
              <mat-icon>check</mat-icon>
              {{ 'common.button.saveAndClose' | translate }}
            </button>
            <button
              [disabled]="form.invalid || form.pristine"
              matTooltipClass="preline"
              matTooltip="{{ 'flashCard.form.unlinkSaveTooltip' | translate }}"
              (click)="unlinkCard()"
              *ngIf="flashCard && userId !== flashCard?.created_by"
              mat-menu-item
              type="button"
            >
              <mat-icon class="material-icons">shopping_cart</mat-icon>
              {{ 'question.form.unlinkSave' | translate }}
            </button>
          </mat-menu>
        </div>
      </div>
    </div>
  </form>
</div>
