<mat-dialog-content
  (mousewheel)="zoom($event)"
  (pointerdown)="down($event)"
  (pointermove)="drag($event)"
  [style.transform]="
    'translate(' +
    zoomMove.x +
    'px, ' +
    zoomMove.y +
    'px)scale(' +
    zoomLevel +
    ')'
  "
>
  <img [src]="data.url" />
</mat-dialog-content>

<mat-dialog-actions align="end">
  <qa-button
    [isRaisedButton]="true"
    label="common.button.shutdown"
    [mat-dialog-close]="true"
  ></qa-button>
</mat-dialog-actions>
