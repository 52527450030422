import { Component } from '@angular/core';
import * as Tone from 'tone';

@Component({
  selector: 'qa-volume-control',
  template: `<!-- TODO: The 'vertical' property no longer exists -->
    <mat-slider step="0.05" min="0" max="1" color="primary" #ngSlider
      ><input
        matSliderThumb
        [value]="volume"
        (change)="
          setVolume({
            source: ngSliderThumb,
            parent: ngSlider,
            value: ngSliderThumb.value
          })
        "
        #ngSliderThumb="matSliderThumb"
    /></mat-slider>
    <button mat-icon-button (click)="toggleMute()">
      <mat-icon *ngIf="!muted">volume_up</mat-icon>
      <mat-icon *ngIf="muted">volume_off</mat-icon>
    </button>`,
  styles: [
    `
      :host {
        background: white;
        box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.3);
        padding: 1rem 0.5rem;
        border-radius: 0.2rem;
        display: flex;
        flex-direction: column;
        align-items: center;
      }
    `,
  ],
})
export class AudioVolumeComponent {
  minVol = -20;

  constructor() {}

  setVolume(event) {
    Tone.getDestination().volume.value = this.minVol * (1 - event.value);
    if (event.value === 0) {
      Tone.getDestination().mute = true;
    } else {
      Tone.getDestination().mute = false;
    }
  }

  get volume() {
    return 1 - Tone.getDestination().volume.value / this.minVol;
  }

  get muted() {
    return Tone.getDestination().mute === true;
  }

  toggleMute() {
    Tone.getDestination().mute = !Tone.getDestination().mute;
  }
}
