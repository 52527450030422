import {
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { CoursesService } from 'src/app/api/services';
import { DateRangePickerService } from '../../common/form-field/date-range-picker.service';
import { TranslateService } from '@ngx-translate/core';
import * as dayjs from 'dayjs';
import { ChartComponent } from '../../common/ui/charts/chart.component';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import {
  CourseStatistics,
  DailyUsageStatistic,
  QuizSummaryStatistics,
  StackSummaryStatistics,
} from 'src/app/api/models';
import { DateRange } from 'src/app/app.types';

@Component({
  selector: 'qa-course-statistics',
  templateUrl: './course-statistics.component.html',
  styleUrls: ['./course-statistics.component.scss'],
})
export class CourseStatisticsComponent implements OnInit, OnDestroy {
  @Input() courseId: number = null;
  chartData: any;
  chartOptions: {};
  dateRangeSubscription: Subscription;
  quizStatistic: QuizSummaryStatistics[];
  cardStatistic: StackSummaryStatistics[];
  loading = false;
  rows: Array<{ x: string; y: number; id: number }> = [];

  @ViewChild('chart') chartRef: ChartComponent;

  constructor(
    private courses: CoursesService,
    private router: Router,
    private dateRangePickerService: DateRangePickerService,
    elementRef: ElementRef<HTMLElement>,
    private translator: TranslateService
  ) {
    elementRef.nativeElement.classList.add('qa-course-statistics');
  }

  async ngOnInit() {
    this.dateRangeSubscription =
      this.dateRangePickerService.dateRange.subscribe(async (dateRange) => {
        this.loading = true;
        const stats = await this.courses
          .getCourseStatistics({
            id: this.courseId,
            end: dateRange?.to
              ? dayjs.utc(dateRange.to).format('YYYY-MM-DD') + 'T23:59:59'
              : null,
            start: dateRange?.from
              ? dayjs.utc(dateRange.from).utc().format('YYYY-MM-DD') +
                'T00:00:00'
              : null,
          })
          .toPromise();
        this.quizStatistic = stats.quiz_statistics;
        this.cardStatistic = stats.stack_statistics;
        await this.updateChart(stats, dateRange);
        this.loading = false;
      });
  }

  ngOnDestroy() {
    this.dateRangeSubscription.unsubscribe();
  }

  async updateChart(statistics: CourseStatistics, dateRange: DateRange) {
    const fromDate = dayjs(dateRange.from);
    const toDate = dayjs(dateRange.to).add(1, 'days');
    const table = {};
    const difference = toDate.diff(fromDate, 'days');
    for (let i = 0; i < difference; i++) {
      const date = fromDate.clone().add(i, 'days').format('YYYY-MM-DD');
      table[date] = {
        questionStat: 0,
        cardStat: 0,
      };
    }
    statistics.questions_usage.forEach((row: DailyUsageStatistic) => {
      const date = dayjs.utc(row.day).format('YYYY-MM-DD');
      if (table[date]) {
        table[date].questionStat = row.value;
      }
    });
    statistics.card_usage.forEach((row: DailyUsageStatistic) => {
      const date = dayjs.utc(row.day).format('YYYY-MM-DD');
      if (table[date]) {
        table[date].cardStat = row.value;
      }
    });
    const rows = Object.keys(table).map((date) => {
      return {
        x: dayjs(date),
        y: table[date].questionStat,
        z: table[date].cardStat,
      };
    });

    this.chartOptions = {
      legend: { display: false },
      scales: {
        x: {
          ticks: {
            autoSkip: true,
            maxRotation: 0,
            minRotation: 0,
            maxTicksLimit: 11,
          },
          grid: {
            display: false,
            z: -1,
          },
        },
        y: {
          scaleLabel: {
            display: false,
            labelString: this.translator.instant('statistics.graph.title'),
          },
          ticks: {
            min: 0,
            precision: 0,
            beginAtZero: true,
          },
          gridLines: {
            drawBorder: false,
          },
        },
      },
      maintainAspectRatio: false,
    };
    this.chartData = {
      labels: rows.map((r) => this.formatDate(r.x)),
      datasets: [
        {
          label: this.translator.instant('question.title'),
          backgroundColor: environment.colors.primary,
          borderColor: environment.colors.primary,
          hoverBackgroundColor: environment.colors.primaryLight,
          data: rows.map((r) => r.y),
          borderWidth: 2,
          pointBorderWidth: 0,
          pointHoverBackgroundColor: '#fff',
          pointBackgroundColor: environment.colors.primary,
          pointBorderColor: environment.colors.primary,
          fill: false,
          hidden: false,
        },
        {
          label: this.translator.instant('Cards'),
          backgroundColor: '#51B2FD',
          borderColor: '#51B2FD',
          hoverBackgroundColor: '#51B2FD',
          data: rows.map((r) => r.z),
          borderWidth: 2,
          pointBorderWidth: 0,
          pointHoverBackgroundColor: '#fff',
          pointBackgroundColor: '#51B2FD',
          pointBorderColor: '#51B2FD',
          fill: false,
          hidden: false,
        },
      ],
    };
  }

  private formatDate(date: dayjs.Dayjs): string {
    return dayjs(date || '01.01.1970').format('DD MMM YYYY');
  }

  chartClicked(event) {
    if (event.active && event.active.length) {
      const dataIndex = event.active[0].index;
      if (this.rows[dataIndex]) {
        this.router.navigate([`/cms/courses/edit`, this.rows[dataIndex].id]);
      }
    }
  }
}
