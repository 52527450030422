<div [class.faded]="loading" class="chart-container">
  <div class="statistics-title-container">
    <div class="statistics-title">
      {{ 'course.statistics.courseStatistic' | translate }}
    </div>
    <div class="bullets">
      <div class="bullet">
        <span></span>
        {{ 'question.title' | translate }}
      </div>
      <div class="bullet">
        <span></span>
        {{ 'course.statistics.card' | translate }}
      </div>
    </div>
  </div>
  <qa-chart
    [data]="chartData"
    [options]="chartOptions"
    (chartClick)="chartClicked($event)"
    *ngIf="chartData"
    chartType="line"
    #chart
  >
  </qa-chart>
  <div class="entity-stats">
    <div class="quiz">
      <qa-quiz-stats
        [stats]="quizStatistic"
        [courseId]="courseId"
      ></qa-quiz-stats>
    </div>
    <div class="quiz">
      <qa-stack-stats
        [stats]="cardStatistic"
        [courseId]="courseId"
      ></qa-stack-stats>
    </div>
  </div>
</div>
