export const templates = [
  {
    title: 'Single Choice - 5 bis 1 Sterne',
    template: {
      answers: [
        {
          id: 59863,
          text: '⭐⭐⭐⭐⭐',
          is_right: true,
        },
        {
          id: 59864,
          text: '⭐⭐⭐⭐',
          is_right: false,
        },
        {
          id: 59865,
          text: '⭐⭐⭐',
          is_right: false,
        },
        {
          id: 59866,
          text: '⭐⭐',
          is_right: false,
        },
        {
          id: 59867,
          text: '⭐',
          is_right: false,
        },
      ],
      tags: [
        {
          id: 1153,
          name: 'Musterfrage',
        },
        {
          id: 2293,
          name: 'Umfragen/Evaluationen',
        },
      ],
      text: 'Bewertungs-Frage - 5 bis 1 Sterne (Single Choice)',
      display_in_order: true,
      is_poll_question: true,
      explanation:
        'Bitte wähle einen Wert auf der Skala von 5 für den höchsten Wert bis 54für den niedrigsten Wert.',
      type: 2,
      created_at: '2022-07-25T08:00:06.000Z',
      created_by: '6597857a-4ee4-4b6e-9808-2a4bf79ae07b',
      updated_at: '2022-07-25T08:22:31.000Z',
      updated_by: '6597857a-4ee4-4b6e-9808-2a4bf79ae07b',
      position: 2,
      num_answers: 5,
      'meta:importType': 'question',
    },
  },
  {
    title: 'Single Choice - Ja / Nein',
    template: {
      answers: [
        {
          id: 59873,
          text: 'Ja',
          is_right: true,
        },
        {
          id: 59874,
          text: 'Nein',
          is_right: false,
        },
      ],
      tags: [
        {
          id: 1153,
          name: 'Musterfrage',
        },
        {
          id: 2293,
          name: 'Umfragen/Evaluationen',
        },
      ],
      text: 'Bewertungs-Frage - Ja / Nein (Single Choice)',
      display_in_order: true,
      is_poll_question: true,
      type: 2,
      created_at: '2022-07-25T08:07:13.000Z',
      created_by: '6597857a-4ee4-4b6e-9808-2a4bf79ae07b',
      updated_at: '2022-07-25T08:07:13.000Z',
      position: 3,
      num_answers: 2,
      'meta:importType': 'question',
    },
  },
  {
    title: 'Single Choice - 1 Trifft völlig zu bis 5 Trifft gar nicht zu',
    template: {
      answers: [
        {
          id: 59868,
          text: '1 Trifft völlig zu',
          is_right: true,
        },
        {
          id: 59869,
          text: '2',
          is_right: false,
        },
        {
          id: 59870,
          text: '3',
          is_right: false,
        },
        {
          id: 59871,
          text: '4',
          is_right: false,
        },
        {
          id: 59872,
          text: '5 Trifft gar nicht zu',
          is_right: false,
        },
      ],
      tags: [
        {
          id: 1153,
          name: 'Musterfrage',
        },
        {
          id: 2293,
          name: 'Umfragen/Evaluationen',
        },
      ],
      text: 'Meinungs-Frage - 1 Trifft völlig zu - 5 Trifft gar nicht zu (Single Choice)',
      display_in_order: true,
      is_poll_question: true,
      explanation:
        'Bitte wähle einen Wert auf der Skala von 1 für den höchsten Wert bis 5 für den niedrigsten Wert.',
      type: 2,
      created_at: '2022-07-25T08:02:03.000Z',
      created_by: '6597857a-4ee4-4b6e-9808-2a4bf79ae07b',
      updated_at: '2022-07-25T08:05:32.000Z',
      updated_by: '6597857a-4ee4-4b6e-9808-2a4bf79ae07b',
      position: 4,
      num_answers: 5,
      'meta:importType': 'question',
    },
  },
  {
    title: 'Multiple Choice',
    template: {
      answers: [
        {
          id: 59880,
          text: 'Antwort 1',
          is_right: true,
        },
        {
          id: 59881,
          text: 'Antwort 2',
          is_right: false,
        },
        {
          id: 59882,
          text: 'Antwort 3',
          is_right: false,
        },
        {
          id: 59883,
          text: 'Antwort 4',
          is_right: false,
        },
        {
          id: 59884,
          text: 'Antwort 5',
          is_right: false,
        },
      ],
      tags: [
        {
          id: 1153,
          name: 'Musterfrage',
        },
        {
          id: 2293,
          name: 'Umfragen/Evaluationen',
        },
      ],
      text: 'Meinungs-Frage (Multiple Choice)',
      is_poll_question: true,
      type: 0,
      created_at: '2022-07-25T08:14:14.000Z',
      created_by: '6597857a-4ee4-4b6e-9808-2a4bf79ae07b',
      updated_at: '2022-07-25T08:14:14.000Z',
      position: 5,
      num_answers: 5,
      'meta:importType': 'question',
    },
  },
  {
    title: 'Freitext - WordCloud',
    template: {
      answers: [],
      tags: [
        {
          id: 1153,
          name: 'Musterfrage',
        },
        {
          id: 2293,
          name: 'Umfragen/Evaluationen',
        },
      ],
      text: 'Stimmungs-Frage - Wordcloud (Freitext)',
      display_in_order: false,
      is_poll_question: true,
      explanation:
        'Als Antwort können Sie offene Kommentare hinzufügen, die der/dem Moderierenden als Word Cloud dargestellt werden.',
      type: 1,
      created_at: '2022-07-25T08:03:53.000Z',
      created_by: '6597857a-4ee4-4b6e-9808-2a4bf79ae07b',
      updated_at: '2022-07-25T08:06:10.000Z',
      updated_by: '6597857a-4ee4-4b6e-9808-2a4bf79ae07b',
      position: 1,
      max_num_characters: 100,
      media: {
        id: 852,
        url: 'https://s3.eu-central-1.amazonaws.com/media.quizacademy.io/4dbd1f90-0bf0-11ed-9983-c151dfdf57d3.jpeg',
        uuid: '4dbd1f90-0bf0-11ed-9983-c151dfdf57d3',
        file_ext: 'jpeg',
      },
      num_answers: 1,
      'meta:importType': 'question',
    },
  },
];
