<ng-container *ngIf="question">
  <qa-countdown
    *ngIf="question.isCountingDown && !question.model.is_poll_question"
  >
  </qa-countdown>
  <qa-poll-announcement *ngIf="question.model.is_poll_question">
  </qa-poll-announcement>
  <div *ngIf="!question.isCountingDown" class="inner">
    <div class="left flex-column">
      <ng-scrollbar data-check="true" class="text">
        <div
          class="visualization-selector"
          *ngIf="question.model.is_poll_question"
        >
          <mat-button-toggle-group
            [value]="selectedVisualization"
            (change)="selectedVisualization = $event.value"
          >
            <mat-button-toggle
              value="cloud"
              *ngIf="question.model.type === questionType.FreeText"
              ><mat-icon>abc</mat-icon></mat-button-toggle
            >
            <mat-button-toggle value="list"
              ><mat-icon>view_list</mat-icon></mat-button-toggle
            >
            <mat-button-toggle value="donut"
              ><mat-icon>donut_large</mat-icon></mat-button-toggle
            >
          </mat-button-toggle-group>
        </div>
        <qa-image-display
          [model]="question.model"
          *ngIf="question?.model?.media?.uuid"
        ></qa-image-display>
        <qa-markdown-katex [data]="question.model.text"> </qa-markdown-katex>
        <div class="answers" [class.poll]="question.model.is_poll_question">
          <ng-container
            *ngIf="!question.model.is_poll_question; else liveResult"
          >
            <qa-question-answers
              [disabled]="
                answersDisabled ||
                question.isAnswered ||
                question.isCompleted ||
                question.isDisabled
              "
              [control]="answerSelectionControl"
              [question]="question.model"
              [showCorrectAnswers]="question.isCompleted"
              [trueFalseOrderInverted]="question.trueFalseOrderInverted"
            >
            </qa-question-answers>
          </ng-container>
          <ng-container *ngIf="!answersDisabled">
            <button
              (click)="submitAnswer()"
              [disabled]="isSubmitAnswerDisabled"
              *ngIf="!question.isAnswered && !question.isCompleted"
              class="submit-answer-button"
              color="primary"
              mat-button
              type="button"
            >
              <mat-icon> done </mat-icon>
              {{ 'question.label.submitAns' | translate }}
            </button>
            <button
              (click)="retractAnswer()"
              *ngIf="
                !isAnswerRetractionDisabled &&
                question.isAnswered &&
                !question.isCompleted
              "
              class="retract-answer-button"
              color="primary"
              mat-button
              type="button"
            >
              <mat-icon> edit </mat-icon>
              {{ 'question.label.editAns' | translate }}
            </button>
          </ng-container>
        </div>
      </ng-scrollbar>
    </div>
    <div class="right">
      <ng-scrollbar>
        <div
          class="play-timer-container"
          [class.poll-question]="question.model.is_poll_question"
        >
          <qa-play-timer [showLargeTimer]="true"></qa-play-timer>
          <qa-play-timer class="small-mobile-only"></qa-play-timer>
        </div>
        <div class="submitted-answers">
          <div class="progress">
            <div class="top">
              <div class="position">
                {{ answerSubmitted | number : '2.0-0' }} /
                {{ numOfUsers | number : '2.0-0' }}
                {{ 'question.label.answersSubmitted' | translate }}
              </div>
            </div>
            <mat-progress-bar
              [value]="getProgressAnswer(answerSubmitted, numOfUsers)"
              color="primary"
            >
            </mat-progress-bar>
          </div>
        </div>
      </ng-scrollbar>
    </div>
    <div *ngIf="isCompleted" class="explanation-panel qa-theme-inverted">
      <ng-scrollbar class="scroll-container">
        <div class="correct-incorrect">
          <mat-icon>
            {{ isAnsweredCorrectly ? 'done' : 'clear' }}
          </mat-icon>
          {{
            (isAnsweredCorrectly
              ? 'question.label.correct'
              : isAnsweredIncorrectly
              ? 'question.label.inCorrect'
              : 'question.label.notAnswered'
            ) | translate
          }}
        </div>
        <ng-container *ngIf="question.isExplanationPanelOpen">
          <div class="correct-answer-text">{{ getCorrectAnswerText() }}</div>
          <ng-container *ngIf="question.model.type !== questionType.TrueFalse">
            <ul>
              <li *ngFor="let correctAnswer of getCorrectAnswers()">
                <qa-markdown-katex [data]="correctAnswer.text">
                </qa-markdown-katex>
              </li>
            </ul>
          </ng-container>
          <qa-markdown-katex [data]="question.model.explanation">
          </qa-markdown-katex>
        </ng-container>
      </ng-scrollbar>
      <div class="actions">
        <a
          *ngIf="!data.hideWeblink && getWeblink()"
          [href]="getWeblink()"
          color="primary"
          mat-button
          target="_blank"
          type="button"
        >
          <mat-icon>open_in_new</mat-icon>
          {{ 'question.openWebLink' | translate }}
        </a>
        <button
          (click)="
            question.isExplanationPanelOpen = !question.isExplanationPanelOpen
          "
          class="toggle-explanation-button"
          color="primary"
          mat-button
          type="button"
        >
          <mat-icon>arrow_back_ios</mat-icon>
          <span class="bttntext">{{
            (question.isExplanationPanelOpen
              ? 'question.label.showQuestion'
              : 'question.label.showExplanation'
            ) | translate
          }}</span>
        </button>
      </div>
    </div>
  </div>
  <div [matRippleDisabled]="true" #submitAnswerRipple matRipple></div>
</ng-container>

<ng-template #liveResult>
  <ng-container
    *ngIf="
      question.model.type === questionType.FreeText &&
        selectedVisualization === 'cloud';
      else normalAnswer
    "
  >
    <qa-word-cloud-visualization
      [words]="mapToStrings(displayedAnswerStats)"
    ></qa-word-cloud-visualization>
  </ng-container>
  <ng-template #normalAnswer>
    <ng-container
      *ngIf="
        !question.model.is_poll_question || selectedVisualization === 'list';
        else donutVisualization
      "
    >
      <div *ngFor="let answerStat of displayedAnswerStats" class="answer">
        <div class="answer-text">
          <div class="top">
            <qa-markdown-katex [data]="answerStat.answer.text" class="text">
            </qa-markdown-katex>
            <div class="amount">
              {{ answerStat.answers_total }}
            </div>
          </div>
          <div class="bar">
            <div
              [style.width]="getAnswerBarWidth(answerStat.answers_total)"
              class="piece"
            ></div>
          </div>
        </div>
      </div>
    </ng-container>
    <ng-template #donutVisualization>
      <qa-donut-chart [sections]="answerSections" [sort]="true" [large]="true">
      </qa-donut-chart>
    </ng-template>
  </ng-template>
</ng-template>
