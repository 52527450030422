import {
  ConnectedPosition,
  Overlay,
  OverlayConfig,
} from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import * as Tone from 'tone';
import { AudioVolumeComponent } from './audio-volume.component';
import { blockyTheme } from './blocky.audio-theme';
import { jazzyTheme } from './jazzy.audio-theme';
import { qaTheme } from './quizacademy.audio-theme';
import { spyTheme } from './spy.audio-theme';
import { christmasTheme } from './christmas.audio-theme';

@Injectable({
  providedIn: 'root',
})
export class InteractiveAudioService {
  scoreboardPlayer = null;
  currentlyStopping = false;

  currentEnsemble = [];

  private knownThemes = [
    qaTheme,
    blockyTheme,
    jazzyTheme,
    spyTheme,
    christmasTheme,
  ];

  private _playerbuffer = [];

  constructor(private overlay: Overlay) {}

  activateAudioBuffer() {
    Tone.start();
  }

  sfx(name: string) {
    const player = new Tone.Player({
      url: `/assets/audio/${name}01.mp3`,
      onload: () => {
        player.start();
      },
    }).toDestination();
  }

  playScoreboardTheme() {
    if (this.scoreboardPlayer) {
      this.scoreboardPlayer.stop();
      this.scoreboardPlayer = null;
    }
    this.scoreboardPlayer = new Tone.Player({
      url: '/assets/audio/scoreboard01.mp3',
      fadeIn: 1,
      volume: -12,
      loop: true,
      onload: () => {
        if (this.scoreboardPlayer) {
          this.scoreboardPlayer.start();
        }
      },
    }).toDestination();
  }

  stopScoreboardTheme() {
    if (!this.scoreboardPlayer) {
      return;
    }
    this.scoreboardPlayer.stop();
    this.scoreboardPlayer = null;
  }

  play(id: string, duration = 20) {
    const theme = this.knownThemes.find((theme) => theme.name === id);
    if (!theme) {
      throw new Error(`No theme named ${id} could be found.`);
    }
    Tone.Transport.stop();
    Tone.Transport.cancel(0);
    Tone.Transport.bpm.value = theme.bpm;
    Tone.Transport.seconds = 0;
    const previouslyMuted = this.isMuted();

    const masterFade = new Tone.Volume(0).toDestination();
    Tone.getDestination().volume.value = -10;
    if (previouslyMuted) {
      Tone.getDestination().mute = true;
    }

    const totalBars = this.secondsToBars(duration);

    this.scheduleSounds(theme, masterFade, totalBars);

    const timesoversound = new Tone.Player(
      '/assets/audio/timesup01.mp3'
    ).connect(masterFade);
    timesoversound.sync().start(`${totalBars}:0:0`);
    this._playerbuffer.push(timesoversound);

    Tone.loaded().then(() => {
      const totalLength = this.barsToSeconds(totalBars);
      Tone.Transport.seconds = totalLength - duration;
      if (totalLength - duration > 0.01) {
        masterFade.volume.value = -21;
        masterFade.volume.rampTo(0, 1);
      }
      Tone.Transport.start();
      this.currentlyStopping = false;
      window.setTimeout(() => {
        this.currentlyStopping = true;
      }, (duration - 1) * 1000);
      Tone.Transport.schedule(() => {
        masterFade.volume.value = 0;
        masterFade.volume.rampTo(-20, 1);
      }, `${totalBars}:0:0`);
      Tone.Transport.schedule(() => {
        Tone.Transport.stop();
      }, `${totalBars + 2}:0:0`);
    });
  }

  private scheduleSounds(theme, masterFade, totalBars) {
    for (const timeline of theme.timelines) {
      let lastEnd = 0;
      for (let i = 0; i < timeline.length; i++) {
        const t = timeline[i];
        const startBar = t.pos
          ? t.pos >= 0
            ? t.pos
            : Math.max(totalBars + t.pos, 0.0)
          : lastEnd;
        let stopBar;
        if (t.id === '$FILL') {
          const nextOne = timeline.find((b, index) => index > i && b.pos);
          if (nextOne) {
            stopBar = nextOne.pos >= 0 ? nextOne.pos : totalBars + nextOne.pos;
          } else {
            stopBar = totalBars;
          }
          if (stopBar <= startBar) {
            continue;
          }
          lastEnd = this.scheduleAllSounds(
            theme,
            t,
            masterFade,
            startBar,
            stopBar
          );
        } else {
          const resource = this.loadSoundResource(theme, t.id);
          stopBar = Math.min(startBar + resource.length, totalBars);
          if (resource.loop) {
            const nextOne = timeline.find((b, index) => index > i && b.pos);
            if (nextOne) {
              stopBar =
                nextOne.pos >= 0 ? nextOne.pos : totalBars + nextOne.pos;
            } else {
              stopBar = totalBars;
            }
          }
          if (
            t.notBefore !== null &&
            (t.notBefore < 0
              ? Math.max(totalBars + t.notBefore, 0.0)
              : t.notBefore) > startBar
          ) {
            continue;
          }
          if (
            t.notAfter !== null &&
            stopBar >
              (t.notAfter < 0
                ? Math.max(totalBars + t.notAfter, 0.0)
                : t.notAfter)
          ) {
            continue;
          }
          if (
            t.latestStart !== null &&
            (t.latestStart < 0
              ? Math.max(totalBars + t.latestStart, 0.0)
              : t.latestStart) < startBar
          ) {
            continue;
          }
          this.scheduleOneSound(resource, masterFade, startBar, stopBar);
          lastEnd = stopBar;
        }
      }
    }
  }

  private loadSoundResource(theme, id: string) {
    const res = theme.resources.find((r) => r.id === id);
    if (!res) {
      throw new Error(`Resource with id ${id} could not be found`);
    }
    return res;
  }

  private scheduleAllSounds(theme, block, masterFade, start, end): number {
    // Method returns last end point of repetition
    let currentStart = start;
    let lastSelection = null;
    while (currentStart < end) {
      let currentBlock =
        block.options[Math.floor(Math.random() * block.options.length)];
      if (block.options.length === 1 && currentStart !== start) {
        return currentStart;
      }
      while (
        lastSelection === currentBlock ||
        (currentBlock.notAfter &&
          currentBlock.notAfter.includes(lastSelection?.id))
      ) {
        currentBlock =
          block.options[Math.floor(Math.random() * block.options.length)];
      }
      lastSelection = currentBlock;
      const resource = this.loadSoundResource(theme, currentBlock.id);
      let bars =
        (Math.floor(
          Math.random() * (currentBlock.maxInARow - currentBlock.minInARow)
        ) +
          currentBlock.minInARow) *
        resource.length;
      bars = Math.min(bars, end - currentStart);
      if (currentBlock.preShift) {
        currentStart += currentBlock.preShift;
      }
      this.scheduleOneSound(
        resource,
        masterFade,
        currentStart,
        currentStart + bars
      );
      currentStart += bars;
    }
    return end;
  }

  private scheduleOneSound(resource, masterFade, start, end) {
    const startTime = `${start}:0:0`;
    const stopTime = `${end}:0:0`;
    const player = new Tone.Player(`/assets/audio/${resource.path}`).connect(
      masterFade
    );
    if (resource.loop) {
      player.loop = true;
      player.loopStart = 0;
      player.loopEnd = this.barsToSeconds(resource.length);
    }
    player.sync().start(startTime).stop(stopTime);
    this._playerbuffer.push(player);
  }

  stopTransport() {
    if (this.currentlyStopping) {
      this.currentlyStopping = false;
      return;
    }
    this._playerbuffer.forEach((p) => {
      try {
        p.stop();
        p.dispose();
      } catch (err) {}
    });
    this._playerbuffer = [];
    Tone.Transport.stop();
  }

  private barsToSeconds(bars: number) {
    const sig: number = Tone.Transport.timeSignature as number;
    return ((sig * bars) / Tone.Transport.bpm.value) * 60;
  }

  private secondsToBars(duration: number) {
    const sig: number = Tone.Transport.timeSignature as number;
    return Math.ceil(((Tone.Transport.bpm.value / 60) * duration) / sig);
  }

  getThemes() {
    return this.knownThemes.map((t) => t.name);
  }

  toggleMute() {
    Tone.getDestination().mute = !Tone.getDestination().mute;
  }

  isMuted(): boolean {
    return Tone.getDestination().mute;
  }

  showVolumeOverlay(referenceElement: Element): void {
    const positionStrategy = this.overlay
      .position()
      .flexibleConnectedTo(referenceElement)
      .withPositions([
        {
          originX: 'center',
          originY: 'top',
          overlayX: 'center',
          overlayY: 'bottom',
        } as ConnectedPosition,
      ])
      .withPush(true);

    const overlayConfig = new OverlayConfig({
      scrollStrategy: this.overlay.scrollStrategies.block(),
      hasBackdrop: true,
      positionStrategy,
    });

    const overlayRef = this.overlay.create(overlayConfig);
    overlayRef.backdropClick().subscribe(() => {
      overlayRef.dispose();
    });

    const compPortal = new ComponentPortal(AudioVolumeComponent);

    overlayRef.attach(compPortal);
  }
}
