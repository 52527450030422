import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

export interface CustomTab {
  name: string;
  value: number;
}

@Component({
  selector: 'qa-custom-tabs',
  templateUrl: './custom-tabs.component.html',
  styleUrls: ['./custom-tabs.component.scss'],
})
export class CustomTabsComponent implements OnInit {
  @Output() tabSelected = new EventEmitter<number>();
  @Input() tabs: CustomTab[];
  @Input() defaultSelected: number;
  selectedTab: CustomTab;

  ngOnInit(): void {
    if (this.defaultSelected || this.defaultSelected === 0) {
      this.selectedTab = this.tabs.find(
        (t) => t.value === this.defaultSelected
      );
    }
  }

  selectTab(tab: CustomTab) {
    this.selectedTab = tab;
    this.tabSelected.emit(tab.value);
  }
}
