<div class="hint">
  {{ hint }}
</div>
<div *ngIf="question.type === questionType.MultipleChoice">
  <mat-checkbox
    (ngModelChange)="updateSelection(answer.id, $event)"
    [disabled]="disabled"
    [ngModel]="isAnswerSelected(answer.id)"
    *ngFor="let answer of question.answers; trackBy: questionAnswer"
    #checkbox
    class="answer {{
      showCorrectAnswers ? (answer.is_right ? 'correct' : 'incorrect') : ''
    }}"
    color="primary"
  >
    <div
      [matRippleTrigger]="ripple.closest('.mat-checkbox')"
      #ripple
      matRipple
    ></div>
    <qa-markdown-katex [data]="answer.text"> </qa-markdown-katex>
    <div class="answerflare" *ngIf="showCorrectAnswers">
      <mat-label *ngIf="!isAnswerSelected(answer.id) && answer.is_right">
        {{ 'question.label.answerMissing' | translate }}
      </mat-label>
      <mat-label *ngIf="isAnswerSelected(answer.id) && !answer.is_right">
        {{ 'question.label.answerWrong' | translate }}
      </mat-label>
      <mat-label *ngIf="isAnswerSelected(answer.id) && answer.is_right">
        {{ 'question.label.rightAnswer' | translate }}
      </mat-label>
      <mat-icon
        *ngIf="
          (isAnswerSelected(answer.id) && answer.is_right) ||
          (!isAnswerSelected(answer.id) && !answer.is_right)
        "
      >
        done
      </mat-icon>
      <mat-icon
        *ngIf="
          (!isAnswerSelected(answer.id) && answer.is_right) ||
          (isAnswerSelected(answer.id) && !answer.is_right)
        "
      >
        clear
      </mat-icon>
    </div>
  </mat-checkbox>
</div>
<mat-radio-group
  (ngModelChange)="updateSelection($event)"
  [ngModel]="control?.value"
  *ngIf="question.type !== questionType.MultipleChoice"
>
  <mat-radio-button
    [disabled]="disabled"
    [value]="aSingleChoiceAnswer.value"
    *ngFor="let aSingleChoiceAnswer of singleChoiceAnswers"
    #radioButton
    class="answer {{
      showCorrectAnswers
        ? aSingleChoiceAnswer.value == singleChoiceAnswer
          ? 'correct'
          : 'incorrect'
        : ''
    }}"
    color="primary"
  >
    <div
      [matRippleTrigger]="ripple.closest('.mat-radio-button')"
      #ripple
      matRipple
    ></div>
    <qa-markdown-katex [data]="aSingleChoiceAnswer.text"> </qa-markdown-katex>
    <div class="answerflare" *ngIf="showCorrectAnswers">
      <mat-label
        *ngIf="
          control?.value != aSingleChoiceAnswer.value &&
          aSingleChoiceAnswer.value == singleChoiceAnswer
        "
      >
        {{ 'question.label.missingAnswer' | translate }}
      </mat-label>
      <mat-label
        *ngIf="
          control?.value == aSingleChoiceAnswer.value &&
          aSingleChoiceAnswer.value != singleChoiceAnswer
        "
      >
        {{ 'question.label.wrongAnswer' | translate }}
      </mat-label>
      <mat-label
        *ngIf="
          control?.value == aSingleChoiceAnswer.value &&
          aSingleChoiceAnswer.value == singleChoiceAnswer
        "
      >
        {{ 'question.label.rightAnswer' | translate }}
      </mat-label>
      <mat-icon
        *ngIf="
          (aSingleChoiceAnswer.value == singleChoiceAnswer &&
            control?.value == aSingleChoiceAnswer.value) ||
          (aSingleChoiceAnswer.value != singleChoiceAnswer &&
            control?.value != aSingleChoiceAnswer.value)
        "
      >
        done
      </mat-icon>
      <mat-icon
        *ngIf="
          !(
            (aSingleChoiceAnswer.value == singleChoiceAnswer &&
              control?.value == aSingleChoiceAnswer.value) ||
            (aSingleChoiceAnswer.value != singleChoiceAnswer &&
              control?.value != aSingleChoiceAnswer.value)
          )
        "
      >
        clear
      </mat-icon>
    </div>
  </mat-radio-button>
</mat-radio-group>
