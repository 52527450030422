/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { EditionModel } from '../models/edition-model';
import { OrganizationModel } from '../models/organization-model';
import { User } from '../models/user';
import { UserPaymentDetails } from '../models/user-payment-details';

@Injectable({
  providedIn: 'root',
})
export class UsersService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getUserById
   */
  static readonly GetUserByIdPath = '/users/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getUserById()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUserById$Response(params: {
    cognitouserid?: string;
    cognitousergroup?: string;
    id: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<User>> {

    const rb = new RequestBuilder(this.rootUrl, UsersService.GetUserByIdPath, 'get');
    if (params) {
      rb.header('cognitouserid', params.cognitouserid, {});
      rb.header('cognitousergroup', params.cognitousergroup, {});
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<User>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getUserById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUserById(params: {
    cognitouserid?: string;
    cognitousergroup?: string;
    id: string;
    context?: HttpContext
  }
): Observable<User> {

    return this.getUserById$Response(params).pipe(
      map((r: StrictHttpResponse<User>) => r.body as User)
    );
  }

  /**
   * Path part for operation updateUser
   */
  static readonly UpdateUserPath = '/users/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateUser()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateUser$Response(params: {
    cognitouserid?: string;
    cognitousergroup?: string;
    id: string;
    validate_email?: boolean;
    context?: HttpContext
    body: User
  }
): Observable<StrictHttpResponse<User>> {

    const rb = new RequestBuilder(this.rootUrl, UsersService.UpdateUserPath, 'put');
    if (params) {
      rb.header('cognitouserid', params.cognitouserid, {});
      rb.header('cognitousergroup', params.cognitousergroup, {});
      rb.path('id', params.id, {});
      rb.query('validate_email', params.validate_email, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<User>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateUser$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateUser(params: {
    cognitouserid?: string;
    cognitousergroup?: string;
    id: string;
    validate_email?: boolean;
    context?: HttpContext
    body: User
  }
): Observable<User> {

    return this.updateUser$Response(params).pipe(
      map((r: StrictHttpResponse<User>) => r.body as User)
    );
  }

  /**
   * Path part for operation getPaymentDetailsForUser
   */
  static readonly GetPaymentDetailsForUserPath = '/users/me/payment-details';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPaymentDetailsForUser()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPaymentDetailsForUser$Response(params?: {
    cognitouserid?: string;
    cognitousergroup?: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<UserPaymentDetails>> {

    const rb = new RequestBuilder(this.rootUrl, UsersService.GetPaymentDetailsForUserPath, 'get');
    if (params) {
      rb.header('cognitouserid', params.cognitouserid, {});
      rb.header('cognitousergroup', params.cognitousergroup, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UserPaymentDetails>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getPaymentDetailsForUser$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPaymentDetailsForUser(params?: {
    cognitouserid?: string;
    cognitousergroup?: string;
    context?: HttpContext
  }
): Observable<UserPaymentDetails> {

    return this.getPaymentDetailsForUser$Response(params).pipe(
      map((r: StrictHttpResponse<UserPaymentDetails>) => r.body as UserPaymentDetails)
    );
  }

  /**
   * Path part for operation hasActiveSubscription
   */
  static readonly HasActiveSubscriptionPath = '/users/me/payment-status';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `hasActiveSubscription()` instead.
   *
   * This method doesn't expect any request body.
   */
  hasActiveSubscription$Response(params?: {
    cognitouserid?: string;
    cognitousergroup?: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, UsersService.HasActiveSubscriptionPath, 'get');
    if (params) {
      rb.header('cognitouserid', params.cognitouserid, {});
      rb.header('cognitousergroup', params.cognitousergroup, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `hasActiveSubscription$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  hasActiveSubscription(params?: {
    cognitouserid?: string;
    cognitousergroup?: string;
    context?: HttpContext
  }
): Observable<boolean> {

    return this.hasActiveSubscription$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation getOrganizationsForUser
   */
  static readonly GetOrganizationsForUserPath = '/users/{id}/organizations';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getOrganizationsForUser()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOrganizationsForUser$Response(params: {
    cognitouserid?: string;
    cognitousergroup?: string;
    id: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<OrganizationModel>>> {

    const rb = new RequestBuilder(this.rootUrl, UsersService.GetOrganizationsForUserPath, 'get');
    if (params) {
      rb.header('cognitouserid', params.cognitouserid, {});
      rb.header('cognitousergroup', params.cognitousergroup, {});
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OrganizationModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getOrganizationsForUser$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOrganizationsForUser(params: {
    cognitouserid?: string;
    cognitousergroup?: string;
    id: string;
    context?: HttpContext
  }
): Observable<Array<OrganizationModel>> {

    return this.getOrganizationsForUser$Response(params).pipe(
      map((r: StrictHttpResponse<Array<OrganizationModel>>) => r.body as Array<OrganizationModel>)
    );
  }

  /**
   * Path part for operation getEditionsForUser
   */
  static readonly GetEditionsForUserPath = '/users/{id}/editions';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getEditionsForUser()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEditionsForUser$Response(params: {
    cognitouserid?: string;
    cognitousergroup?: string;
    id: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<EditionModel>>> {

    const rb = new RequestBuilder(this.rootUrl, UsersService.GetEditionsForUserPath, 'get');
    if (params) {
      rb.header('cognitouserid', params.cognitouserid, {});
      rb.header('cognitousergroup', params.cognitousergroup, {});
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<EditionModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getEditionsForUser$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEditionsForUser(params: {
    cognitouserid?: string;
    cognitousergroup?: string;
    id: string;
    context?: HttpContext
  }
): Observable<Array<EditionModel>> {

    return this.getEditionsForUser$Response(params).pipe(
      map((r: StrictHttpResponse<Array<EditionModel>>) => r.body as Array<EditionModel>)
    );
  }

}
