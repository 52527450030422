export const spyTheme = {
  name: 'Agent',
  bpm: 120,
  resources: [
    {
      id: 'start_long',
      path: 'theme04_start_long.mp3',
      length: 12,
    },
    {
      id: 'start_medium',
      path: 'theme04_start_medium.mp3',
      length: 6,
    },
    {
      id: 'start_short',
      path: 'theme04_start_short.mp3',
      length: 4,
    },
    {
      id: 'idle01',
      path: 'theme04_idle01.mp3',
      loop: true,
      length: 4,
    },
    {
      id: 'idle02',
      path: 'theme04_idle02.mp3',
      loop: true,
      length: 4,
    },
    {
      id: 'idle03',
      path: 'theme04_idle03.mp3',
      loop: true,
      length: 4,
    },
    {
      id: 'idle04',
      path: 'theme04_idle04.mp3',
      loop: true,
      length: 4,
    },
    {
      id: 'clock',
      path: 'clock01_120bpm.mp3',
      length: 2,
      loop: true,
    },
    {
      id: 'fasterclock',
      path: 'clock02_120bpm.mp3',
      length: 1,
      loop: true,
    },
    {
      id: 'fastclock',
      path: 'clock03_120bpm.mp3',
      length: 1,
      loop: true,
    },
  ],
  timelines: [
    [
      {
        id: 'start_long',
        pos: 0,
        notAfter: -4,
        latestStart: 0,
      },
      {
        id: 'start_medium',
        notAfter: -4,
        latestStart: 0,
      },
      {
        id: 'start_short',
        pos: 0,
        latestStart: 0,
      },
      {
        id: '$FILL',
        options: [
          {
            id: 'idle01',
            minInARow: 1,
            maxInARow: 4,
          },
          {
            id: 'idle02',
            minInARow: 1,
            maxInARow: 1,
          },
          {
            id: 'idle03',
            minInARow: 1,
            maxInARow: 2,
          },
          {
            id: 'idle03',
            minInARow: 1,
            maxInARow: 4,
          },
        ],
      },
      {
        id: '$FILL',
        pos: -4,
        options: [
          {
            id: 'idle01',
            minInARow: 1,
            maxInARow: 1,
          },
          {
            id: 'idle03',
            minInARow: 1,
            maxInARow: 1,
          },
          {
            id: 'idle04',
            minInARow: 1,
            maxInARow: 1,
          },
        ],
      },
    ],
    [
      {
        id: 'clock',
        pos: -3,
      },
      {
        id: 'fasterclock',
        pos: -2,
      },
      {
        id: 'fastclock',
        pos: -1,
      },
    ],
  ],
};
