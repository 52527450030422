import { createAction, props } from '@ngrx/store';
import { QuestionModel } from '../api/models';
import * as Event from './event.types';

export namespace EventActions {
  export const closeExplanationPanel = createAction(
    '[Event] Close Explanation Panel',
    props<{ questionId: number }>()
  );

  export const completeQuestion = createAction(
    '[Event] Complete Question',
    props<{ questionId: number }>()
  );

  export const destroy = createAction('[Event] Destroy');

  export const init = createAction(
    '[Event] Init',
    props<{
      answersDisabled?: boolean;
      isAnswerRetractionDisabled?: boolean;
      questions: QuestionModel[];
      eventId: number;
    }>()
  );

  export const openExplanationPanel = createAction(
    '[Event] Open Explanation Panel',
    props<{ questionId: number }>()
  );

  export const retractAnswer = createAction(
    '[Event] Retract Answer',
    props<{ questionId: number }>()
  );

  export const setEndTime = createAction(
    '[Event] Set End Time',
    props<{ questionId: number }>()
  );

  export const setStartTime = createAction(
    '[Event] Set Start Time',
    props<{ questionId: number }>()
  );

  export const showQuestion = createAction(
    '[Event] Show Question',
    props<{ questionId: number; useCountdown?: boolean }>()
  );

  export const startCountdown = createAction(
    '[Event] Start Countdown',
    props<{ questionId: number }>()
  );

  export const stopCountdown = createAction(
    '[Event] Stop Countdown',
    props<{ questionId: number }>()
  );

  export const storeAnswer = createAction(
    '[Event] Store Answer',
    props<{
      isAnsweredCorrectly: boolean;
      questionId: number;
      dontCallApi?: boolean;
    }>()
  );

  export const submitAnswer = createAction(
    '[Event] Submit Answer',
    props<{ questionId: number }>()
  );

  export const updateAnswerSelection = createAction(
    '[Event] Update Answer Selection',
    props<{ questionId: number; selection: Event.AnswerSelection }>()
  );

  export const updateQuestion = createAction(
    '[Event] Update Question',
    props<{ question: Event.Question }>()
  );
}
