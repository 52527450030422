export class SubscriptionViewData {
  free: boolean | undefined = undefined;
  active: boolean | undefined = undefined;
  multilicense: boolean | undefined = undefined;
  title: string | undefined = undefined;
  status: string | undefined = undefined;
  type: string | undefined = undefined;
  licenseType: string | undefined = undefined;
  buttonLabel: string | undefined = undefined;
  price: string | undefined = undefined;
  nextPayment: string | undefined = undefined;
  cycle: string | undefined = undefined;
  paymentMethod: string | undefined = undefined;
  name: string | undefined = undefined;
  adress: string | undefined = undefined;
  zipcode: string | undefined = undefined;
  city: string | undefined = undefined;
}
