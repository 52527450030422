import { Injectable, ErrorHandler } from '@angular/core';
import { environment } from '../environments/environment';
import * as Sentry from '@sentry/browser';
import { AuthService } from './auth/auth.service';

@Injectable()
export class SentryErrorHandler implements ErrorHandler {
  all400Errors = [401, 402, 403, 0];
  all400ErrorsText = [
    '"status":403',
    '"status":402',
    '"status":401',
    '"status":0',
  ];

  constructor(private auth: AuthService) {}

  handleError(error: any) {
    //FIXME: temporarily disable supported browser check
    // const isBrowserSupported = [
    //   'MSIE ',
    //   'Trident/',
    //   'Edge/',
    //   'Mobile Safari/',
    //   'iPhone OS',
    //   'SamsungBrowser/',
    // ].every((ua) => !navigator.userAgent.includes(ua));
    const chunkFailedMessage = /Loading chunk [\d]+ failed/;
    if (chunkFailedMessage.test(error.message)) {
      window.location.reload();
      return;
    }
    if (error && error.toString().indexOf('scrollTop') !== -1) {
      return;
    }
    if (
      error &&
      (error.status || error.status === 0) &&
      this.all400Errors.includes(error.status)
    ) {
      return;
    }
    if (
      error &&
      error.status === 404 &&
      error.url.includes('users/me/payment-details')
    ) {
      return;
    }
    if (
      error.toString() &&
      this.all400ErrorsText.filter((eachText) => {
        return error.toString().includes(eachText);
      }).length
    ) {
      return;
    } else if (
      //FIXME: temporarily disable supported browser check
      //isBrowserSupported &&
      environment.sentry.dsn &&
      environment.production
    ) {
      if (
        !(
          /windows phone/i.test(navigator.userAgent) ||
          /android/i.test(navigator.userAgent) ||
          /iPad|iPhone|iPod/i.test(navigator.userAgent)
        )
      ) {
        Sentry.captureException(
          (error as { originalError: unknown }).originalError || error,
          {
            user: {
              email: this.auth?.currentUserInfo$?.value?.email,
              id: this.auth?.currentUserInfo$?.value?.sub,
            },
          }
        );
      }
    } else {
      console.error(error);
    }
  }
}
