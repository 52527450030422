<mat-form-field
  [floatLabel]="!label ? 'never' : true"
  appearance="outline"
  subscriptSizing="dynamic"
  [class.no-label]="!label"
  (pointerdown)="$event.preventDefault(); showSelectOverlay()"
  #formElement
>
  <mat-label> {{ label }} </mat-label>
  <input
    matInput
    [attr.required]="required"
    placeholder="Search"
    [formControl]="displayControl"
  />
  <mat-icon class="arrow-down" matSuffix (pointerdown)="closeDropDown($event)"
    >arrow_drop_down</mat-icon
  >
</mat-form-field>

<ng-template #treeOverlayTemplate>
  <div class="tree-select-overlay">
    <ng-scrollbar>
      <ng-container
        *ngFor="let node of tree"
        [ngTemplateOutlet]="nodeTemplate"
        [ngTemplateOutletContext]="{ node: node }"
      >
      </ng-container>
    </ng-scrollbar>
  </div>
</ng-template>

<ng-template #nodeTemplate let-node="node">
  <div class="tree-node" [class.isChild]="node.children?.length <= 0">
    <span class="spacer" *ngIf="node.children?.length <= 0"></span>
    <button mat-button class="node-info" (pointerdown)="selectElement(node)">
      <mat-icon
        (pointerdown)="toggleCategory($event, node)"
        *ngIf="node.children?.length > 0 && !node.openedChildren"
        >chevron_right</mat-icon
      >
      <mat-icon
        (pointerdown)="toggleCategory($event, node)"
        *ngIf="node.children?.length > 0 && node.openedChildren"
        >expand_more</mat-icon
      >
      <mat-icon *ngIf="node.icon">{{ node.icon }}</mat-icon>
      {{ node.name }}
    </button>
    <div
      class="node-children"
      *ngIf="node.children?.length > 0 && node.openedChildren"
    >
      <ng-container
        *ngFor="let child of node.children"
        [ngTemplateOutlet]="nodeTemplate"
        [ngTemplateOutletContext]="{ node: child }"
      >
      </ng-container>
    </div>
  </div>
</ng-template>
