<div
  class="language-select"
  [matMenuTriggerFor]="languageMenu"
  [class.disabled]="!supportedLanguages?.length"
>
  <mat-icon class="language-icon">language</mat-icon>
  <span>{{ selectedLanguage?.flag | uppercase }}</span>
  <mat-icon>arrow_drop_down</mat-icon>
</div>
<mat-menu #languageMenu="matMenu">
  <button
    (click)="selectedLanguage = language"
    mat-menu-item
    *ngFor="let language of supportedLanguages"
    [class.selectedLanguage]="selectedLanguage === language"
  >
    <span [ngClass]="['fi', 'fi-' + language.flag]"></span>
    {{ language.name | translate }}
  </button>
</mat-menu>
<button
  mat-icon-button
  class="tts-init"
  (click)="playTTS()"
  matTooltip="{{ 'common.label.readForMe' | translate }}"
>
  <mat-icon *ngIf="!speech?.speaking()">volume_up</mat-icon>
  <mat-icon *ngIf="speech?.speaking()">stop</mat-icon>
</button>
