<form class="flex-row flex-auto-margin flex-align-items-center">
  <mat-form-field appearance="outline">
    <mat-date-range-input
      [formGroup]="rangeControl"
      [rangePicker]="fromDatepicker"
      [max]="oneYearAfterFromDate"
    >
      <input
        matStartDate
        [placeholder]="'common.label.from' | translate"
        formControlName="start"
      />
      <input
        matEndDate
        [placeholder]="'common.label.to' | translate"
        formControlName="end"
      />
    </mat-date-range-input>
    <mat-datepicker-toggle [for]="fromDatepicker" matSuffix>
      <mat-icon matDatepickerToggleIcon>calendar_today</mat-icon>
    </mat-datepicker-toggle>
    <mat-date-range-picker #fromDatepicker panelClass="datepickerOne">
    </mat-date-range-picker>
  </mat-form-field>
</form>
