<div class="countdown-hint" *ngIf="countdownHint">
  <span class="main-title">{{ countdownHint.title }}</span>
  <span class="details">{{ countdownHint.details }}</span>
</div>
<ng-content></ng-content>
<div class="logo-container" *ngIf="state?.liveEvent?.logo_url">
  <img class="logo" [src]="state?.liveEvent?.logo_url" />
</div>
<div class="logo-container" *ngIf="state?.cmsLiveEvent?.liveEvent?.logo_url">
  <img class="logo" [src]="state?.cmsLiveEvent?.liveEvent?.logo_url" />
</div>
<div
  class="logo-container"
  *ngIf="
    !state?.cmsLiveEvent?.liveEvent?.logo_url && !state?.liveEvent?.logo_url
  "
>
  <img class="logo" src="../assets/logo.png" />
</div>
