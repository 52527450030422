import {
  Component,
  ElementRef,
  ViewEncapsulation,
  HostListener,
  ViewChild,
  Renderer2,
} from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { Router, NavigationEnd } from '@angular/router';
import { trigger, style, animate, transition } from '@angular/animations';
import { AuthService } from './auth/auth.service';
import { Meta } from '@angular/platform-browser';
import { UsersService } from './api/services';
import { DynamicCourseStyleService } from './styles/dynamic-course-style.service';
import { IosInfoDialogComponent } from './ios-info-dialog/ios-info-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { HttpStatusCode } from '@angular/common/http';

export function editionPrefix() {
  return environment.edition === 'go'
    ? 'B'
    : environment.edition.slice(0, 1).toUpperCase();
}

export function asShortLink(url: string, withProtocol = true) {
  return `${withProtocol ? 'https://' : ''}${
    environment.edition === 'go' ? 'go' : environment.edition.slice(0, 1)
  }.quizacademy.io/${url}`;
}

@Component({
  selector: 'qa-app',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [
    trigger('enterAnimation', [
      transition(':enter', [
        style({ transform: 'translateX(100%)', opacity: 0 }),
        animate('300ms', style({ transform: 'translateX(0)', opacity: 1 })),
      ]),
      transition(':leave', [
        style({ transform: 'translateX(0)', opacity: 1 }),
        animate('300ms', style({ transform: 'translateX(100%)', opacity: 0 })),
      ]),
    ]),
  ],
  encapsulation: ViewEncapsulation.None,
})
export class AppComponent {
  compatibilityWarningMessage: string;
  hideCompatibilityWarning = false;
  appStoreLink = environment.appStoreLink;
  playStoreLink = environment.playStoreLink;
  show = false;
  showLang = false;
  isCms = false;
  editionAccess = false;
  languages = ['de', 'en'];
  @ViewChild('languageDiv') languageDiv: ElementRef;

  constructor(
    elementRef: ElementRef<HTMLElement>,
    private router: Router,
    public auth: AuthService,
    public users: UsersService,
    matIconRegistry: MatIconRegistry,
    private meta: Meta,
    renderer: Renderer2,
    public translator: TranslateService,
    private courseStyle: DynamicCourseStyleService,
    private matDialog: MatDialog
  ) {
    this.meta.addTag({
      name: 'apple-itunes-app',
      content: `app-id=${environment.appStoreId}`,
    });
    elementRef.nativeElement.classList.add('qa-app');
    matIconRegistry.setDefaultFontSetClass('material-icons-outlined');
    this.updateCompatibilityWarning();

    router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        this.isCms =
          router.url.indexOf('/cms') !== -1 &&
          router.url.indexOf('/cms/organizations') === -1 &&
          router.url.indexOf('/cms/users') === -1
            ? true
            : false;
        const isLiveEvent = router.url.indexOf('/cms/live-events') !== -1;
        this.courseStyle.apply(val);
        if (document.body) {
          document.body.setAttribute(
            'data-show-chat',
            this.isCms ? 'true' : 'false'
          );
          document.body.setAttribute(
            'data-hide-contact',
            isLiveEvent ? 'true' : 'false'
          );
        }
      }
    });

    this.auth.currentUserInfo$.subscribe((userInfo) => {
      if (userInfo) {
        this.getEditions(userInfo.sub);
      }
    });
    renderer.listen('window', 'click', (e: Event) => {
      if (
        this.languageDiv &&
        !this.languageDiv.nativeElement.contains(e.target)
      ) {
        this.showLang = false;
      }
    });

    this.openIOSInfoDialog();
  }

  openIOSInfoDialog() {
    try {
      const isSaved = sessionStorage.getItem('ios-checked');
      if (this.isIOS && !isSaved && !this.auth.isLoggedIn()) {
        this.openIosDialog();
      }
    } catch {}
  }

  openIosDialog(): void {
    this.matDialog.open(IosInfoDialogComponent, {
      data: {},
      autoFocus: false,
      panelClass: 'ios-main-model',
    });
  }

  isIEOrEdge() {
    return ['MSIE ', 'Trident/', 'Edge/'].some((a) =>
      window.navigator.userAgent.includes(a)
    );
  }

  redirectToContact() {
    this.router.navigateByUrl('/cms/help/contact');
  }

  @HostListener('window:resize')
  updateCompatibilityWarning() {
    if (window.innerWidth < 1060 && this.isCms) {
      this.compatibilityWarningMessage = this.translator.instant(
        'common.compatibility.message.smallScreen'
      );
      this.compatibilityWarningMessage += this.translator.instant(
        'common.compatibility.message.useLargeScreen'
      );
      this.compatibilityWarningMessage += this.translator.instant(
        'common.compatibility.message.apps'
      );
    } else {
      this.compatibilityWarningMessage = null;
    }
    if (this.isIEOrEdge()) {
      this.compatibilityWarningMessage = this.translator.instant(
        'common.compatibility.message.notOptimized'
      );
      this.compatibilityWarningMessage += this.translator.instant(
        'common.compatibility.message.optimize'
      );
    }
    const doc = document.documentElement;
    doc.style.setProperty('--mobile-app-height', `${window.innerHeight}px`);
  }

  getEditions(id) {
    this.users.getEditionsForUser({ id }).subscribe(
      (res) => {
        const editionsName = res.map((x) => x.name.toLowerCase());
        if (
          editionsName.includes(
            environment.production
              ? environment.edition.replace('go', 'business')
              : 'development'
          )
        ) {
          this.editionAccess = true;
        } else {
          this.editionAccess = false;
        }
      },
      (error) => {
        const errorMessage = 'Request contains no cognito user role.';
        if (
          error.status === HttpStatusCode.Forbidden &&
          error.error.message.includes(errorMessage)
        ) {
          this.router.navigate(['/login'], {
            queryParams: { noRole: true },
          });
        }
      }
    );
  }

  updateLanguage(language) {
    this.translator.use(language);
    localStorage.setItem('current-language', language);
    location.reload();
  }

  get isIOS() {
    return (
      [
        'iPad Simulator',
        'iPhone Simulator',
        'iPod Simulator',
        'iPad',
        'iPhone',
        'iPod',
      ].includes(navigator.platform) ||
      // iPad on iOS 13 detection
      (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
    );
  }
}
