export const christmasTheme = {
  name: 'Christmas',
  bpm: 120,
  resources: [
    {
      id: 'start',
      path: 'christmas_start.mp3',
      length: 2.25,
    },
    {
      id: 'beat',
      path: 'christmas_beat01.mp3',
      length: 1,
      loop: true,
    },
    {
      id: 'fill01',
      path: 'christmas_fill01.mp3',
      length: 4,
    },
    {
      id: 'fill02',
      path: 'christmas_fill02.mp3',
      length: 4,
    },
    {
      id: 'fill03',
      path: 'christmas_fill03.mp3',
      length: 4,
    },
    {
      id: 'variation01',
      path: 'christmas_variation01.mp3',
      length: 4.25,
    },
    {
      id: 'variation02',
      path: 'christmas_variation02.mp3',
      length: 6.25,
    },
    {
      id: 'variation03',
      path: 'christmas_variation03.mp3',
      length: 6.25,
    },
    {
      id: 'variation04',
      path: 'christmas_variation04.mp3',
      length: 7,
    },
    {
      id: 'variation05',
      path: 'christmas_variation05.mp3',
      length: 7.125,
    },
    {
      id: 'clock',
      path: 'clock01_120bpm.mp3',
      length: 2,
      loop: true,
    },
    {
      id: 'fasterclock',
      path: 'clock02_120bpm.mp3',
      length: 1,
      loop: true,
    },
    {
      id: 'fastclock',
      path: 'clock03_120bpm.mp3',
      length: 1,
      loop: true,
    },
  ],
  timelines: [
    [
      {
        id: 'start',
        pos: 0,
      },
      {
        id: 'fill01',
        latestStart: 0,
      },
      {
        id: '$FILL',
        options: [
          {
            id: 'fill02',
            minInARow: 1,
            maxInARow: 1,
          },
          {
            id: 'fill03',
            minInARow: 1,
            maxInARow: 1,
          },
          {
            id: 'variation01',
            minInARow: 1,
            maxInARow: 1,
            preShift: -0.25,
            notAfter: [
              'variation02',
              'variation03',
              'variation04',
              'variation05',
            ],
          },
          {
            id: 'variation02',
            minInARow: 1,
            maxInARow: 1,
            preShift: -0.25,
            notAfter: [
              'variation01',
              'variation03',
              'variation04',
              'variation05',
            ],
          },
          {
            id: 'variation03',
            minInARow: 1,
            maxInARow: 1,
            preShift: -0.25,
            notAfter: [
              'variation02',
              'variation01',
              'variation04',
              'variation05',
            ],
          },
          {
            id: 'variation04',
            minInARow: 1,
            maxInARow: 1,
            preShift: 0,
            notAfter: [
              'variation02',
              'variation03',
              'variation01',
              'variation05',
            ],
          },
          {
            id: 'variation05',
            minInARow: 1,
            maxInARow: 1,
            preShift: -0.125,
            notAfter: [
              'variation02',
              'variation03',
              'variation04',
              'variation01',
            ],
          },
        ],
      },
    ],
    [
      {
        id: '$FILL',
        pos: 0,
        options: [
          {
            id: 'beat',
            minInARow: 0,
            maxInARow: 9999,
          },
        ],
      },
    ],
    [
      {
        id: 'clock',
        pos: -3,
      },
      {
        id: 'fasterclock',
        pos: -2,
      },
      {
        id: 'fastclock',
        pos: -1,
      },
    ],
  ],
};
