import { Component } from '@angular/core';

@Component({
  template: `
    <div mat-dialog-title>
      {{ 'common.dialogTitle.discardChanges' | translate }}
    </div>
    <mat-dialog-content>
      {{ 'common.message.discardChanges' | translate }}
    </mat-dialog-content>
    <mat-dialog-actions align="end">
      <button color="primary" mat-button mat-dialog-close type="button">
        {{ 'common.button.abort' | translate }}
      </button>
      <button [mat-dialog-close]="true" color="warn" mat-button type="button">
        {{ 'common.button.discard' | translate }}
      </button>
    </mat-dialog-actions>
  `,
})
export class EditConfirmCloseDialogComponent {}
