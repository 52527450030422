<div class="summary-container">
  <div class="questions-status">
    <div class="questions-answered p-style">
      <div class="icon-container">
        <mat-icon>show_chart</mat-icon>
      </div>
      <div>
        {{ correctAnswers }}
      </div>
      <div *ngIf="!isFlashCard">
        {{ 'quiz.summary.rightAnsweredCount' | translate }}
      </div>
      <div *ngIf="isFlashCard">
        {{ 'flashCardStack.answerKnown' | translate }}
      </div>
    </div>
    <div class="wrong-questions p-style">
      <div>
        {{ inCorrectAnswers }}
      </div>
      <div *ngIf="!isFlashCard">
        {{ 'quiz.summary.wrongAnsweredCount' | translate }}
      </div>
      <div *ngIf="isFlashCard">
        {{ 'flashCardStack.answernotKnown' | translate }}
      </div>
    </div>
    <div class="not-attended-questions p-style">
      <div>
        {{ totalQuestions - (correctAnswers + inCorrectAnswers) }}
      </div>
      <div>{{ 'quiz.summary.notAnsweredCount' | translate }}</div>
    </div>
  </div>
</div>
