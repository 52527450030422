<mat-dialog-content>
  <div class="progress-container" *ngIf="data?.isProgress">
    <div>
      {{ data?.label.progress }} <b>{{ data?.success | number : '2.0-0' }}</b> /
      <b>{{ data?.total | number : '2.0-0' }}</b
      >...
    </div>
    <div class="progress-border">
      <div
        class="progress"
        [ngStyle]="{ width: getPercentage(data?.total, data?.success) + '%' }"
      ></div>
    </div>
    <span>Please wait...</span>
  </div>
  <div class="result" *ngIf="data?.isComplete">
    <div class="info">
      <span class="success" *ngIf="!data?.failed && data?.isComplete">
        {{ data?.label?.success }}
      </span>
      <ng-container *ngIf="data?.failed && data?.isComplete">
        <span class="total">
          {{ 'common.label.total' | translate }} :
          {{ data?.total }}
        </span>
        <span class="success">
          {{ 'common.label.success' | translate }} : {{ data?.success }}
        </span>
        <span class="fail">
          {{ 'common.label.fail' | translate }} : {{ data?.failed }}
        </span>
      </ng-container>
    </div>
    <qa-button
      [isRaisedButton]="true"
      label="Finish Import"
      mat-dialog-close
    ></qa-button>
  </div>
</mat-dialog-content>
