import { SortOption, BaseQueryResponse } from 'src/app/api/models';
import { Observable } from 'rxjs';
import { ListDataSource } from './list-data-source';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { ChipType } from 'src/app/shared/components/custom-chip/custom-chip.component';
import { SelectGroup } from 'src/app/utility/app.utils';
import { SubscriptionLimit } from '../common/dialogs/subscription-limit-warning/subscription-limit-warning.component';

export const DEFAULT_PAGE_SIZE = 25;

export interface ListAction<T = {}> {
  click?: (meta: ListActionMeta<T>) => void | Promise<void>;
  icon?: string | ((meta: ListActionMeta<T>) => string);
  isDisabled?: (meta: ListActionMeta<T>) => boolean;
  isChecked?: (meta: ListActionMeta<T>) => boolean;
  name: string;
  label?: string;
  link?: ListLink<T>;
  tooltip?: string;
  type: 'button' | 'link' | 'chip';
  hide?: (meta?: ListActionMeta<T>) => boolean;
  class?: string;
}

export interface ListActionMeta<T> {
  dataSource: ListDataSource;
  index?: number;
  row?: T;
  rows?: T[];
}

export interface ImportEntity<T> {
  id?: string;
  title: string;
  description: string;
  highlight?: boolean;
  action: CreateButtonOptions;
  extra?: ListAction<T>;
}

export interface ListColumn<T> {
  accessor?: (row: T) => string;
  click?: (meta: ListActionMeta<T>) => void;
  label: string;
  link?: ListLink<T>;
  name: keyof T;
  sortable?: boolean;
  showTreeSelect?: boolean;
  isClickable?: (meta: ListActionMeta<T>) => boolean;
  toggle?: {
    action: (event: MatSlideToggleChange, meta: ListActionMeta<T>) => void;
    isDisabled?: (meta: ListActionMeta<T>) => boolean;
    isCheckToggle?: boolean;
  };
  image?: boolean;
  tooltip?: (meta: ListActionMeta<T>) => string;
  width?: string;
  primary?: boolean;
  hideLabel?: boolean;
  hideOnMobile?: boolean;
  icon?: (meta: ListActionMeta<T>) => string;
  filter?: (value: any) => any;
  filterValue?: (value: any) => string;
  filterOptions?: Array<{
    name: string;
    value: any;
  }>;
  filterOptionsByGroup?: SelectGroup[];
  filterAutoComplete?: (value: any) => Observable<string[]>;
  filterOptionsMultiSelect?: (query: {
    name: string;
    page: number;
  }) => Observable<any>;
  filtersCanStack?: boolean;
  alwaysHide?: boolean;
  editableField?: {
    field: string;
    submit: (meta: ListActionMeta<T>) => void;
  };
}

export interface CreateButtonOptions {
  icon?: string;
  hidden?: boolean;
  text: string;
  options?: Array<CreateButtonOptions>;
  click?: (dataSource?: ListDataSource) => void;
}

export interface ListConfig<T = {}> {
  activeActionNames: string[];
  activeColumnNames: Array<keyof T>;
  chip?: {
    hide?: (meta?: ListActionMeta<T>) => boolean;
    label: (meta: ListActionMeta<T>) => string;
    type: (meta: ListActionMeta<T>) => ChipType;
    icon?: (meta: ListActionMeta<T>) => string;
  };
  actions?: Array<ListAction<T>>;
  actionsMenu?: Array<ListAction<T>>;
  disabledActionsMenu?: Array<ListAction<T>>;
  columns: Array<ListColumn<T>>;
  createButton?: {
    click?: (dataSource: ListDataSource) => void;
    options?: Array<CreateButtonOptions>;
  };
  tutorial?: string;
  extraTutorial?: string;
  tutorialLabel?: string;
  extraTutorialLabel?: string;
  fetch: (
    queryParams: ListQuery<T>
  ) => Observable<BaseQueryResponse | ListResponse<T> | T[]>;
  fetchRowItemById?: (id: number) => Observable<T>;
  hideWrapper?: boolean;
  hideTopBar?: boolean;
  name: string;
  query?: {
    initial?: ListQuery<T>;
    isLocal?: boolean;
    searchColumnName: keyof T;
  };
  title: string;
  selectedEntityId?: number;
  showNumberOfItems?: boolean;
  multiselectActions?: Array<ListAction<T>>;
  hidePaginator?: boolean;
  isDisabled?: (meta: T) => boolean;
  showMessageInActionColumn?: (meta: T) => string;
  showMessageInActionTooltip?: string;
  showChip?: boolean;
  actionColumnWidth?: number;
  onPositionDrag?: (element: T, position: number) => Observable<T>;
  imports?: Array<ImportEntity<T>>;
  showSubscriptionIndicator?: boolean;
  subscriptionLimit?: SubscriptionLimit;
  parentActions?: Array<{
    icon?: string;
    text: string;
    click: () => void;
  }>;
}

export interface ListLink<T> {
  routerLink: (meta: ListActionMeta<T>) => unknown[] | string;
  queryParams?: (meta: ListActionMeta<T>) => { [k: string]: unknown };
}

export interface ListResponse<T = {}> {
  pagination_token?: string;
  pagination: { total: number };
  sorting: unknown;
  data: T[];
}

export interface ListQuery<T = {}> {
  pagination_token?: string;
  paging?: any;
  sorting?: SortOption[];
  filters?: Partial<T>;
}

export interface MatTabGroupConfig<T = {}> {
  title: string;
  tabs: MatTabList<T>[];
  selectedTabIndex?: number;
}

export interface MatTabList<T = {}> {
  label: string;
  badge?: Observable<number>;
  config: ListConfig<T>;
}

export interface ConfigOption {
  label: string;
  value: string;
  config: ListConfig;
}
