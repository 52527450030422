import {
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnInit,
  ViewChild,
  ChangeDetectionStrategy,
} from '@angular/core';
import { FormField } from './form-field';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'qa-color-picker',
  templateUrl: './color-picker.component.html',
  styleUrls: ['./color-picker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ColorPickerComponent
  extends FormField
  implements OnInit, OnChanges
{
  @Input() disableFloatingLabel = false;
  @Input() control: FormControl;

  blurInput: any;
  showSuggestion = false;
  @ViewChild('inputField') inputField: ElementRef;

  constructor() {
    super();
  }

  ngOnInit() {
    if (
      this.control?.value &&
      this.control?.value.toString().trim() &&
      !this.control?.disabled
    ) {
      if (!this.control.value.hex && this.control.value[0] === '#') {
        const col = this.hexToRgb(this.control.value);
        this.control.setValue(
          `#${col.r.toString(16).padStart(2, '0')}${col.g
            .toString(16)
            .padStart(2, '0')}${col.b.toString(16).padStart(2, '0')}`
        );
      }
      this.onBlur();
    }
  }

  hexToRgb(hex: string) {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result
      ? {
          r: parseInt(result[1], 16),
          g: parseInt(result[2], 16),
          b: parseInt(result[3], 16),
        }
      : null;
  }

  ngOnChanges() {
    this.onBlur();
  }

  onEdit() {
    this.blurInput = false;
    setTimeout(() => {
      if (this.inputField) {
        this.inputField.nativeElement.focus();
      }
      if (!this.control.value) {
        this.control.setValue('#ff0000');
      }
    }, 0);
  }

  onBlur() {
    if (this.control?.value && !this.control?.value.toString().trim()) {
      this.control.patchValue(this.control.value.toString().trim());
    }
    this.blurInput =
      this.control?.value && this.control?.value.toString().trim()
        ? true
        : false;
  }

  indicatorColor() {
    return this.control?.value || null;
  }

  onColorChange(event: any) {
    this.control.setValue(event); // Update the control value with the new color
    if (this.inputField && this.inputField.nativeElement) {
      this.inputField.nativeElement.value = event; // Update the input field with the new color
    }
  }
}
