import { Component } from '@angular/core';
import { environment } from 'src/environments/environment';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'qa-ios-info-dialog',
  templateUrl: './ios-info-dialog.component.html',
  styleUrls: ['./ios-info-dialog.component.scss'],
})
export class IosInfoDialogComponent {
  appStoreLink = environment.appStoreLink;

  constructor(private dialogRef: MatDialogRef<IosInfoDialogComponent>) {}

  setSession() {
    sessionStorage.setItem('ios-checked', 'true');
    this.dialogRef.close();
  }

  openLink() {
    window.open(this.appStoreLink, '_blank');
    this.dialogRef.close();
  }
}
