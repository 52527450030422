import { Component, ViewChild } from '@angular/core';
import { DateRangePickerService } from '../date-range-picker.service';
import * as dayjs from 'dayjs';
import {
  DaterangepickerDirective,
  LocaleConfig,
} from 'ngx-daterangepicker-material';
import { TranslateService } from '@ngx-translate/core';
import * as localization from 'dayjs/locale/de';
import * as utc from 'dayjs/plugin/utc';

@Component({
  selector: 'qa-custom-range-picker',
  templateUrl: './custom-range-picker.component.html',
  styleUrls: ['./custom-range-picker.component.scss'],
})
export class CustomRangePickerComponent {
  ranges: any = {};
  maxDate;
  selected: any;
  locale: LocaleConfig = {};
  @ViewChild(DaterangepickerDirective, { static: false })
  picker: DaterangepickerDirective;
  constructor(
    private dateRangePickerService: DateRangePickerService,
    public translate: TranslateService
  ) {
    dayjs.extend(utc);
    this.maxDate = dayjs().toDate().toISOString();
    const today = new Date();
    if (this.translate.currentLang === 'de') {
      this.locale = {
        daysOfWeek: localization.weekdaysMin,
        monthNames: localization.monthsShort,
        firstDay: localization.weekStart,
        cancelLabel: this.translate.instant('calendar.cancelLabel'),
        applyLabel: this.translate.instant('calendar.applyLabel'),
        format: 'DD.MM.YYYY',
      };
    } else {
      this.locale = {
        cancelLabel: this.translate.instant('calendar.cancelLabel'),
        applyLabel: this.translate.instant('calendar.applyLabel'),
        format: 'DD.MM.YYYY',
      };
    }

    const rangeKeys = [
      this.translate.instant('calendar.last7Days'),
      this.translate.instant('calendar.last30Days'),
      this.translate.instant('calendar.thisMonth'),
      this.translate.instant('calendar.lastMonth'),
      this.translate.instant('calendar.thisYear'),
      this.translate.instant('calendar.lastYear'),
    ];
    this.ranges[rangeKeys[0]] = [this.addDays(today, -6), today];
    this.ranges[rangeKeys[1]] = [this.addDays(today, -29), today];
    this.ranges[rangeKeys[2]] = [
      new Date(today.getFullYear(), today.getMonth(), 1),
      today,
    ];
    this.ranges[rangeKeys[3]] = [
      new Date(today.getFullYear(), today.getMonth() - 1, 1),
      new Date(today.getFullYear(), today.getMonth(), 0),
    ];
    this.ranges[rangeKeys[4]] = [
      new Date(today.getFullYear(), 0, 1),
      new Date(today.getFullYear(), 11, 31),
    ];
    this.ranges[rangeKeys[5]] = [
      new Date(today.getFullYear() - 1, 0, 1),
      new Date(today.getFullYear() - 1, 11, 31),
    ];

    this.dateRangePickerService.dateRange.subscribe((dateRange) => {
      if (dateRange) {
        this.selected = {
          startDate: dayjs.utc(dateRange.from).format('DD.MM.YYYY'),
          endDate: dayjs.utc(dateRange.to).format('DD.MM.YYYY'),
        };
      }
    });
  }

  addDays(date: Date, days: number): Date {
    const result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  }

  calendarApplied(event: any) {
    if (event.startDate && event.endDate) {
      const startDate = event.startDate;
      const endDate = event.endDate;
      const fromFormat = dayjs(startDate);
      const toFormat = dayjs(endDate);
      if (
        fromFormat.diff(toFormat, 'days') > -365 &&
        fromFormat.diff(toFormat, 'days') <= 0
      ) {
        this.dateRangePickerService.updateDateRange({
          ...this.dateRangePickerService.dateRange.value,
          from: dayjs(startDate).toDate().toISOString(),
          to: dayjs(endDate).toDate().toISOString(),
        });
      }
    }
  }

  open() {
    this.picker.open();
  }
}
