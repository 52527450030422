<qa-auth-flow-wrapper>
  <ng-container left>
    <h2>{{ 'login.asParticipant' | translate }}</h2>
    <span>{{ 'login.noAuthNeeded' | translate }}</span>
    <!-- ToDo: Join as participant -->
    <qa-button
      [label]="'login.toParticipantsView' | translate"
      type="button"
      icon="open_in_new"
      (submitted)="toParticipantsView()"
    ></qa-button>
  </ng-container>
  <ng-container right>
    <h1>{{ 'login.forTeachers' | translate }}</h1>
    <form (ngSubmit)="login()" [formGroup]="form">
      <div
        *ngIf="
          errorMessage && errorMessage !== 'Incorrect username or password.'
        "
        [innerHTML]="'login.' + errorMessage | translate"
        class="mat-error"
      ></div>
      <div class="error-spacing">
        <span
          *ngIf="
            errorMessage && errorMessage === 'Incorrect username or password.'
          "
          [innerHTML]="'login.' + errorMessage | translate"
          class="mat-error forgot-password-firstSent"
        ></span
        ><span
          *ngIf="
            errorMessage && errorMessage === 'Incorrect username or password.'
          "
          class="mat-error forgot-password"
          (click)="forgotPassword()"
          >{{ 'login.button.forgotPass' | translate }}</span
        ><span
          *ngIf="
            errorMessage && errorMessage === 'Incorrect username or password.'
          "
          [innerHTML]="'login.contactByUs' | translate"
          class="mat-error forgot-password-secondSent"
        ></span>
      </div>
      <mat-form-field hideRequiredMarker appearance="outline">
        <mat-label> E-Mail </mat-label>
        <input
          formControlName="email"
          matInput
          type="email"
          (keyup)="$event.target.value = $event.target.value.toLowerCase()"
          data-cy="login-email"
        />
        <mat-error
          *ngIf="form.get('email').invalid && form.get('email').touched"
        >
          {{ 'login.error.invalidEmail' | translate }}
        </mat-error>
      </mat-form-field>
      <mat-form-field hideRequiredMarker appearance="outline">
        <mat-label>
          {{ 'login.label.password' | translate }}
        </mat-label>
        <input
          #passwordInput
          formControlName="password"
          matInput
          type="password"
          data-cy="login-password"
        />
        <button
          (click)="
            passwordInput.type =
              passwordInput.type === 'text' ? 'password' : 'text'
          "
          matTooltip="{{
            (passwordInput.type === 'text'
              ? 'login.tooltip.hidePassword'
              : 'login.tooltip.showPassword'
            ) | translate
          }}"
          color="primary"
          mat-icon-button
          matSuffix
          type="button"
        >
          <mat-icon>
            {{
              passwordInput.type === 'text' ? 'visibility_off' : 'visibility'
            }}
          </mat-icon>
        </button>
      </mat-form-field>
      <a
        class="forgot-password"
        (click)="forgotPassword()"
        *ngIf="!form.disabled"
        color="primary"
        matTooltip="{{ 'login.tooltip.forgotPassword' | translate }}"
        type="button"
      >
        {{ 'login.button.forgotPass' | translate }}
      </a>
      <div class="actions">
        <qa-button
          [disabled]="form.invalid || form.disabled"
          [isRaisedButton]="true"
          label="Login"
          data-cy="login-submit"
          type="submit"
        ></qa-button>
        <qa-button
          [isRaisedButton]="false"
          (submitted)="toRegistration()"
          label="{{ 'login.register' | translate }}"
          data-cy="register-submit"
          type="button"
        ></qa-button>
      </div>
    </form>
    <p class="help">
      {{ 'login.havingProblems' | translate }}<br />
      <span [innerHTML]="'login.contactSupport' | translate"></span>
    </p>
  </ng-container>
</qa-auth-flow-wrapper>
