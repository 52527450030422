<div class="organization-for-course">
  <div class="main-header-box">
    <h1></h1>
    <div class="cross-icon close-icon">
      <mat-icon mat-dialog-close>clear</mat-icon>
    </div>
  </div>

  <mat-dialog-content>
    <div class="content-exam-import">
      <div class="import-info">
        {{ 'courseMarket.selectOrganization' | translate }}:
      </div>
      <div class="organization-container">
        <qa-form-field-select
          [control]="organizationControl"
          [displayFn]="organizationDisplayFn"
          label="{{ 'course.form.organization' | translate }}"
          [options]="data"
          *ngIf="data"
        >
        </qa-form-field-select>
        <mat-icon class="info-icon" (click)="openDialog()"> info </mat-icon>
      </div>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions>
    <qa-button mat-dialog-close label="common.button.cancel"></qa-button>
    <qa-button
      [disabled]="!organizationControl.value"
      [isRaisedButton]="true"
      label="liveEvents.setup.label.select"
      (submitted)="selectCourse()"
    ></qa-button>
  </mat-dialog-actions>
</div>
