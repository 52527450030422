import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { QuestionType } from 'src/app/app.types';
import { sortQuestionBy } from '../../../exam/result/helpers';
import { QuestionModel } from 'src/app/api/models';

export type QuestionsOverview = QuestionModel & {
  openQuestionAnsweredCount?: number;
  num_correct_answers: number;
  num_incorrect_answer: number;
  num_unanswered?: number;
  total_result: number;
  total_num_answers?: number;
};

@Component({
  selector: 'qa-question-overview-list',
  templateUrl: './question-overview-list.component.html',
  styleUrls: ['./question-overview-list.component.scss'],
})
export class QuestionOverviewListComponent implements OnInit {
  @Input() questions: QuestionsOverview[];
  @Input() isLiveEvent = false;
  @Input() hideUnAnswered = false;
  @Output() selectQuestionResult = new EventEmitter<{
    result: QuestionsOverview;
    index: number;
  }>();
  questionType = QuestionType;
  loading = false;
  sortDir: string;
  currentSorting;
  allQuestions: QuestionsOverview[];

  constructor() {}

  ngOnInit(): void {
    if (!this.allQuestions && this.questions?.length) {
      this.allQuestions = JSON.parse(JSON.stringify(this.questions));
    }
  }

  getQuestionResult(result: QuestionsOverview, questionIndex) {
    this.selectQuestionResult.emit({
      result,
      index: questionIndex,
    });
  }

  getCorrectRatio(stat: QuestionsOverview) {
    return stat && stat.total_result > 0
      ? `${((stat.num_correct_answers * 100) / stat.total_result).toFixed(2)}%`
      : '0%';
  }

  getRightResult(question: QuestionsOverview) {
    const total =
      question.num_correct_answers +
      question.num_incorrect_answer +
      question.num_unanswered;
    const quotient = question.num_correct_answers / total || 0;
    return `${Math.round(quotient * 100)}%`;
  }

  getWrongResult(question: QuestionsOverview) {
    const total =
      question.num_correct_answers +
      question.num_incorrect_answer +
      question.num_unanswered;
    const quotient = question.num_incorrect_answer / total || 0;
    return `${Math.round(quotient * 100)}%`;
  }

  sort(sortBy: string) {
    if (this.currentSorting !== sortBy) {
      this.sortDir = null;
    }
    this.sortDir = !this.sortDir
      ? 'ASC'
      : this.sortDir === 'ASC'
      ? 'DESC'
      : null;
    if (this.sortDir) {
      this.sortQuestions(sortBy, this.sortDir);
    } else {
      this.questions = JSON.parse(JSON.stringify(this.allQuestions));
    }
  }

  sortQuestions(sortby = 'alphabetical', sortDir?: string): void {
    this.currentSorting = sortby;
    this.questions = sortQuestionBy(this.questions, sortby, sortDir);
  }
}
