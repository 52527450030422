<mat-form-field appearance="outline" [class.containChips]="tags?.length">
  <mat-label *ngIf="label">
    {{ label }}
  </mat-label>
  <mat-chip-grid #chipList aria-label="Tag selection">
    <mat-chip-row
      *ngFor="let tag of tags"
      [selectable]="selectable"
      [removable]="removable"
      (removed)="remove(tag)"
      [style.color]="getColorSchema(tag)"
      (mousedown)="onEdit()"
    >
      {{ tag.name }}
      <mat-icon
        matChipRemove
        *ngIf="removable"
        [style.color]="getColorSchema(tag)"
        >close</mat-icon
      >
    </mat-chip-row>
    <input
      [placeholder]="placeholder"
      matInput
      #tagInput
      autocomplete="off"
      [matAutocomplete]="auto"
      (keyup)="updateAvailableTags($event)"
      (keydown)="onKeyDown($event)"
      (blur)="onBlur()"
      [formControl]="viewControl"
      [required]="required"
      [matChipInputFor]="chipList"
      [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
      (matChipInputTokenEnd)="add($event)"
    />
  </mat-chip-grid>
</mat-form-field>

<mat-autocomplete #auto="matAutocomplete">
  <mat-option
    *ngFor="let option of availableTags"
    [value]="option.id"
    (click)="addFromAutocomplete(option.name)"
  >
    {{ option.name }}
  </mat-option>
</mat-autocomplete>
<mat-hint *ngIf="limitCharacters">
  {{ 'AIQuestionImports.TagValidation' | translate }}
</mat-hint>
