/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { CategoryModel } from '../models/category-model';
import { CategoryQuery } from '../models/category-query';
import { CategoryQueryResponse } from '../models/category-query-response';

@Injectable({
  providedIn: 'root',
})
export class CategoriesService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getCategories
   */
  static readonly GetCategoriesPath = '/categories';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCategories()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCategories$Response(params?: {
    cognitouserid?: string;
    cognitousergroup?: string;
    parent_id?: number;
    as_tree?: boolean;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<CategoryModel>>> {

    const rb = new RequestBuilder(this.rootUrl, CategoriesService.GetCategoriesPath, 'get');
    if (params) {
      rb.header('cognitouserid', params.cognitouserid, {});
      rb.header('cognitousergroup', params.cognitousergroup, {});
      rb.query('parent_id', params.parent_id, {});
      rb.query('as_tree', params.as_tree, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<CategoryModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getCategories$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCategories(params?: {
    cognitouserid?: string;
    cognitousergroup?: string;
    parent_id?: number;
    as_tree?: boolean;
    context?: HttpContext
  }
): Observable<Array<CategoryModel>> {

    return this.getCategories$Response(params).pipe(
      map((r: StrictHttpResponse<Array<CategoryModel>>) => r.body as Array<CategoryModel>)
    );
  }

  /**
   * Path part for operation createCategory
   */
  static readonly CreateCategoryPath = '/categories';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createCategory()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createCategory$Response(params: {
    cognitouserid?: string;
    cognitousergroup?: string;
    context?: HttpContext
    body: CategoryModel
  }
): Observable<StrictHttpResponse<CategoryModel>> {

    const rb = new RequestBuilder(this.rootUrl, CategoriesService.CreateCategoryPath, 'post');
    if (params) {
      rb.header('cognitouserid', params.cognitouserid, {});
      rb.header('cognitousergroup', params.cognitousergroup, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CategoryModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `createCategory$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createCategory(params: {
    cognitouserid?: string;
    cognitousergroup?: string;
    context?: HttpContext
    body: CategoryModel
  }
): Observable<CategoryModel> {

    return this.createCategory$Response(params).pipe(
      map((r: StrictHttpResponse<CategoryModel>) => r.body as CategoryModel)
    );
  }

  /**
   * Path part for operation queryCategories
   */
  static readonly QueryCategoriesPath = '/categories/query';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `queryCategories()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  queryCategories$Response(params: {
    cognitouserid?: string;
    cognitousergroup?: string;
    context?: HttpContext
    body: CategoryQuery
  }
): Observable<StrictHttpResponse<CategoryQueryResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CategoriesService.QueryCategoriesPath, 'post');
    if (params) {
      rb.header('cognitouserid', params.cognitouserid, {});
      rb.header('cognitousergroup', params.cognitousergroup, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CategoryQueryResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `queryCategories$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  queryCategories(params: {
    cognitouserid?: string;
    cognitousergroup?: string;
    context?: HttpContext
    body: CategoryQuery
  }
): Observable<CategoryQueryResponse> {

    return this.queryCategories$Response(params).pipe(
      map((r: StrictHttpResponse<CategoryQueryResponse>) => r.body as CategoryQueryResponse)
    );
  }

  /**
   * Path part for operation getCategoryById
   */
  static readonly GetCategoryByIdPath = '/categories/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCategoryById()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCategoryById$Response(params: {
    cognitouserid?: string;
    cognitousergroup?: string;
    id: number;
    as_tree?: boolean;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<CategoryModel>> {

    const rb = new RequestBuilder(this.rootUrl, CategoriesService.GetCategoryByIdPath, 'get');
    if (params) {
      rb.header('cognitouserid', params.cognitouserid, {});
      rb.header('cognitousergroup', params.cognitousergroup, {});
      rb.path('id', params.id, {});
      rb.query('as_tree', params.as_tree, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CategoryModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getCategoryById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCategoryById(params: {
    cognitouserid?: string;
    cognitousergroup?: string;
    id: number;
    as_tree?: boolean;
    context?: HttpContext
  }
): Observable<CategoryModel> {

    return this.getCategoryById$Response(params).pipe(
      map((r: StrictHttpResponse<CategoryModel>) => r.body as CategoryModel)
    );
  }

  /**
   * Path part for operation updateCategory
   */
  static readonly UpdateCategoryPath = '/categories/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateCategory()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateCategory$Response(params: {
    cognitouserid?: string;
    cognitousergroup?: string;
    id: number;
    context?: HttpContext
    body: CategoryModel
  }
): Observable<StrictHttpResponse<CategoryModel>> {

    const rb = new RequestBuilder(this.rootUrl, CategoriesService.UpdateCategoryPath, 'put');
    if (params) {
      rb.header('cognitouserid', params.cognitouserid, {});
      rb.header('cognitousergroup', params.cognitousergroup, {});
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CategoryModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateCategory$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateCategory(params: {
    cognitouserid?: string;
    cognitousergroup?: string;
    id: number;
    context?: HttpContext
    body: CategoryModel
  }
): Observable<CategoryModel> {

    return this.updateCategory$Response(params).pipe(
      map((r: StrictHttpResponse<CategoryModel>) => r.body as CategoryModel)
    );
  }

  /**
   * Path part for operation deleteCategory
   */
  static readonly DeleteCategoryPath = '/categories/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteCategory()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteCategory$Response(params: {
    cognitouserid?: string;
    cognitousergroup?: string;
    id: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<CategoryModel>> {

    const rb = new RequestBuilder(this.rootUrl, CategoriesService.DeleteCategoryPath, 'delete');
    if (params) {
      rb.header('cognitouserid', params.cognitouserid, {});
      rb.header('cognitousergroup', params.cognitousergroup, {});
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CategoryModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteCategory$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteCategory(params: {
    cognitouserid?: string;
    cognitousergroup?: string;
    id: number;
    context?: HttpContext
  }
): Observable<CategoryModel> {

    return this.deleteCategory$Response(params).pipe(
      map((r: StrictHttpResponse<CategoryModel>) => r.body as CategoryModel)
    );
  }

}
