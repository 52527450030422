import { HttpClient } from '@angular/common/http';
import {
  Component,
  Inject,
  OnInit,
  Optional,
  ViewEncapsulation,
} from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import * as dayjs from 'dayjs';
import * as duration from 'dayjs/plugin/duration';
import { debounceTime, last } from 'rxjs/operators';
import { Media } from 'src/app/api/models';
import {
  InfoDialogComponent,
  InfoDialogComponentData,
} from '../../common/dialogs/info-dialog.component';
import { MediaService } from '../../common/media.service';
import { ImageEditDialogComponent } from '../image-edit-dialog/image-edit-dialog.component';

@Component({
  selector: 'qa-attach-media-dialog',
  templateUrl: './attach-media-dialog.component.html',
  styleUrls: ['./attach-media-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AttachMediaDialogComponent implements OnInit {
  link: UntypedFormControl;
  selectedImage;
  imageUrl;
  youtubeLink: SafeResourceUrl;
  updatedLink: string;
  formGroup: UntypedFormGroup;
  defaultStartTime = [0, 0, 0];
  defaultToTime = [0, 0, 0];
  isLoading = false;
  fromErrorMsg;
  startTimeErrorMsg;
  endTimeErrorMsg;
  videoLengthInSec = 0;
  validateMessage;

  maxW = 1024;
  maxH = 768;

  constructor(
    private http: HttpClient,
    private translator: TranslateService,
    private matDialog: MatDialog,
    private media: MediaService,
    @Optional() public dialogRef: MatDialogRef<AttachMediaDialogComponent>,
    private domSanitizer: DomSanitizer,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    dayjs.extend(duration);
  }

  ngOnInit(): void {
    this.link = new UntypedFormControl(null);
    this.formGroup = new UntypedFormGroup({
      from: new UntypedFormControl(null, [Validators.required]),
      to: new UntypedFormControl(null, [Validators.required]),
    });
    this.formGroup.valueChanges.pipe(debounceTime(200)).subscribe(() => {
      this.fromTimeValidation();
      this.exceedMaxLength();
    });
  }

  confirm() {
    this.dialogRef.close(true);
  }

  limitImageDimensions(file): Promise<Blob> {
    const img = new Image();
    img.src = URL.createObjectURL(file);
    return new Promise((resolve) => {
      img.onload = () => {
        const aspect = Math.min(this.maxW / img.width, this.maxH / img.height);
        const c = document.createElement('canvas');
        const ctx = c.getContext('2d');
        c.width = aspect * img.width;
        c.height = aspect * img.height;
        ctx.drawImage(
          img,
          0,
          0,
          img.width,
          img.height,
          0,
          0,
          c.width,
          c.height
        );
        c.toBlob(resolve);
      };
    });
  }

  imageToFile(blob: any, fileName: string): File {
    if (blob) {
      blob.lastModifiedDate = new Date();
      blob.name = fileName;
    }
    return blob as File;
  }

  async onSelectFile(files) {
    if (!files || !files.length) {
      return;
    }
    const prevSelectedFile = this.selectedImage;
    this.selectedImage = undefined;
    let file = files[0];
    if (!file) {
      return;
    }
    const fileType = file.type;
    if (['image/png', 'image/jpeg'].includes(fileType))
      file = await this.editImagePreUpload(files[0], prevSelectedFile);
    if (!file) {
      return;
    }
    if (fileType !== 'image/gif' && file) {
      const imageBlob = await this.limitImageDimensions(file);
      file = this.imageToFile(imageBlob, file.name);
    }
    const messages = [];
    if (file.size > 2000000) {
      messages.push(this.translator.instant('question.message.imageRes_m2'));
      if (fileType === 'image/gif') {
        messages.push(this.translator.instant('question.message.imageRes_m5'));
      }
    }
    if (messages.length) {
      const data: InfoDialogComponentData = {
        title: this.translator.instant('question.message.imageRes_m1'),
        text: messages,
        buttons: [
          {
            text: this.translator.instant('common.button.ok'),
          },
        ],
      };
      this.matDialog.open(InfoDialogComponent, { data, width: '30vw' });
    } else {
      this.selectedImage = file;
    }
  }

  sanitizeYoutubeLink(initialLink: string): string {
    return `https://www.youtube-nocookie.com/embed/${this.getYoutubeId(
      initialLink
    )}?`;
  }

  onEnterLink(e) {
    if (e) {
      this.validateMessage = undefined;
      this.getVideoData();
      const youtubeUrl = this.sanitizeYoutubeLink(this.link.value);
      this.youtubeLink =
        this.domSanitizer.bypassSecurityTrustResourceUrl(youtubeUrl);
    }
  }

  checkIfLinkCorrect(e) {
    if (!e || !this.link.value) {
      return false;
    }
    this.onEnterLink(e);
    return true;
  }

  matchYoutubeUrlEmbed(url) {
    const p = /^https:\/\/(?:www\.)?youtube.com\/embed\/[A-z0-9]+/;
    return url.match(p);
  }

  getVideoData() {
    const url = `https://content-youtube.googleapis.com/youtube/v3/videos?id=${this.getYoutubeId(
      this.link.value
    )}&part=snippet,contentDetails,statistics&key=AIzaSyCFRpqaQkpltr5LZgO38_oonGD6hWim-7k`;
    this.http.get(url).subscribe((res: any) => {
      if (res && res.items && res.items.length) {
        let duration = res.items[0].contentDetails.duration;
        duration = duration.replace('PT', '');
        this.defaultToTime = [
          0,
          Number(duration.split('M')[0]),
          Number(
            duration.split('M')[1] ? duration.split('M')[1].replace('S', '') : 0
          ),
        ];
        const fromDate = new Date();
        fromDate.setHours(0, 0, 0, 0);
        const toDate = new Date();
        toDate.setHours(
          0,
          Number(duration.split('M')[0]),
          Number(
            duration.split('M')[1] ? duration.split('M')[1].replace('S', '') : 0
          )
        );
        this.formGroup.patchValue({ from: fromDate, to: toDate });
        this.videoLengthInSec =
          Number(duration.split('M')[0]) * 60 +
          Number(
            duration.split('M')[1] ? duration.split('M')[1].replace('S', '') : 0
          );
      }
    });
  }

  getTime(date) {
    return dayjs(date).format('mm:ss');
  }

  getYoutubeId(url) {
    const regExp =
      /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
    const match = url.match(regExp);
    return match && match[7].length === 11 ? match[7] : false;
  }

  uploadImage(file) {
    this.isLoading = true;
    this.media
      .create(file)
      .pipe(last())
      .subscribe(
        (savedMedia: Media) => {
          this.isLoading = false;
          this.dialogRef.close(savedMedia);
          window.dispatchEvent(new Event('resize'));
        },
        (error) => {
          this.isLoading = false;
          throw error;
        }
      );
  }

  fromTimeValidation() {
    const formdata = this.formGroup.value;
    const duration = dayjs.duration(
      dayjs(formdata.to).diff(dayjs(formdata.from))
    );
    const seconds = duration.asSeconds();
    this.fromErrorMsg =
      seconds < 1
        ? this.translator.instant('flashCard.attachMedia.errorMessage.message1')
        : null;
  }

  exceedMaxLength() {
    const formdata = this.formGroup.value;
    const start =
      dayjs(formdata.from).minute() * 60 + dayjs(formdata.from).second();
    const end = dayjs(formdata.to).minute() * 60 + dayjs(formdata.to).second();
    this.startTimeErrorMsg =
      start > this.videoLengthInSec
        ? this.translator.instant('flashCard.attachMedia.errorMessage.message2')
        : null;
    this.endTimeErrorMsg =
      end > this.videoLengthInSec
        ? this.translator.instant('flashCard.attachMedia.errorMessage.message3')
        : null;
  }

  save() {
    if (this.selectedImage) {
      this.uploadImage(this.selectedImage);
    } else if (this.youtubeLink) {
      const formdata = this.formGroup.value;
      const start =
        dayjs(formdata.from).minute() * 60 + dayjs(formdata.from).second();
      const end =
        dayjs(formdata.to).minute() * 60 + dayjs(formdata.to).second();
      this.dialogRef.close(
        this.updatedLink
          ? this.updatedLink
          : this.sanitizeYoutubeLink(this.link.value) +
              `?start=${start}&end=${end}`
      );
    }
  }

  get isInvalid(): any {
    return (!this.selectedImage && !this.link.value) || this.isLoading;
  }

  get isError(): any {
    return (
      this.youtubeLink &&
      (this.fromErrorMsg || this.startTimeErrorMsg || this.endTimeErrorMsg)
    );
  }

  updateLink(youtubeUrl: string) {
    this.youtubeLink =
      this.domSanitizer.bypassSecurityTrustResourceUrl(youtubeUrl);
  }

  onMenuClose() {
    if (!this.isInvalid && !this.isError) {
      const formdata = this.formGroup.value;
      const start =
        dayjs(formdata.from).minute() * 60 + dayjs(formdata.from).second();
      const end =
        dayjs(formdata.to).minute() * 60 + dayjs(formdata.to).second();
      let link;
      link =
        this.sanitizeYoutubeLink(this.link.value) +
        `?start=${start}&end=${end}`;

      this.updatedLink = link;
      this.updateLink(link);
    }
  }

  editImagePreUpload(img: File, imageSelected): Promise<File> {
    return new Promise<File>((resolve) => {
      const dialogRef = this.matDialog.open(ImageEditDialogComponent, {
        panelClass: 'edit-image-dialog-panel',
        autoFocus: false,
        data: {
          file: img,
          name: img.name.match(/^(.*)\.(?:.+)$/)[1] || img.name,
        },
      });
      dialogRef.afterClosed().subscribe((img) => {
        if (!img) {
          this.selectedImage = imageSelected;
        }
        resolve(img);
      });
    });
  }
}
