<div class="hint-trays">
  <ng-scrollbar data-check="true">
    <div class="hint-tray-content">
      <div class="hint" *ngFor="let hint of data.hints">
        <mat-icon *ngIf="hint?.icon">{{ hint.icon }}</mat-icon>
        <div class="hint-text">
          <h2>{{ hint.title }}</h2>
          <p [innerHTML]="hint.parsedContent"></p>
        </div>
        <button mat-icon-button (click)="markAsSeen(hint)">
          <mat-icon>close</mat-icon>
        </button>
      </div>
    </div>
  </ng-scrollbar>
</div>
