import { Injectable, Injector, Type } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { EditDialogComponent, EditDialogData } from './edit-dialog.component';

@Injectable()
export class EditService {
  constructor(private injector: Injector, private matDialog: MatDialog) {}

  open(
    configService: any,
    value?: {},
    params?: { [key: string]: any },
    initialValue?: { [key: string]: any }
  ) {
    const injectedConfigService = this.injector.get(configService as Type<any>);
    const config = injectedConfigService.config;
    config.params = params;
    Object.keys(initialValue ?? {}).forEach((key) => {
      config.fields.find((k) => k.key === key).initialValue = initialValue[key];
    });
    const dialogConfig = {
      data: { config, value },
      disableClose: true,
      autoFocus: false,
      width: '512px',
    };
    const dialog = this.matDialog.open<unknown, EditDialogData>(
      EditDialogComponent,
      dialogConfig
    );
    if (config.afterOpened) {
      dialog.afterOpened().subscribe(config.afterOpened());
    }
    return dialog.afterClosed().toPromise();
  }
}
