import { Injectable } from '@angular/core';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';
import { Exam, QuestionModel } from 'src/app/api/models';
import { CoursesService, ExamsService } from 'src/app/api/services';
import { PanelService } from '../panel.service';
import { Panel, PanelEntityType } from '../panel.types';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { forkJoin, of, throwError } from 'rxjs';
import * as _ from 'lodash';
import { UserJourneyService } from '../../pages/onboarding-steps/user-journey.service';
import { ExamService } from '../../edit/configs/exam.service';
import { SubscriptionLimitService } from '../../common/dialogs/subscription-limit-warning/subscription-limit.service';
import { SubscriptionLimit } from '../../common/dialogs/subscription-limit-warning/subscription-limit-warning.component';

@Injectable()
export class ExamPanel extends Panel<Exam> {
  constructor(
    courses: CoursesService,
    exams: ExamsService,
    panelService: PanelService,
    router: Router,
    translator: TranslateService,
    userJourney: UserJourneyService,
    subscriptionLimit: SubscriptionLimitService
  ) {
    super({
      addMenuItems: [
        {
          icon: 'add',
          onClick: () => panelService.editItem(this),
          text: translator.instant('content.exam.addMenu.item0'),
        },
      ],
      imports: [
        {
          title: translator.instant('content.exam.title.item3'),
          description: translator.instant('content.exam.description.item3'),
          action: {
            icon: 'move_to_inbox',
            click: () => panelService.importItems(this),
            text: translator.instant('content.exam.addMenu.item3'),
          },
        },
        {
          id: 'import-json',
          title: translator.instant('content.exam.title.item2'),
          description: translator.instant('content.exam.description.item2'),
          action: {
            icon: 'code',
            click: () => panelService.importItemAsJSON(this),
            text: translator.instant('content.exam.addMenu.item2'),
          },
        },
      ],
      childTypes: [PanelEntityType.ExamQuestion],
      createItemsFn: (exams, courseId) => {
        return courses
          .createExamInCourse({
            id: courseId,
            body: exams[0],
          })
          .pipe(
            map((exam) => [exam]),
            tap(() => {
              userJourney.set('importedExam');
            })
          );
      },
      createEntity: (exam, courseId) => {
        return courses
          .createExamInCourse({
            id: courseId,
            body: {
              name: exam.name,
              description: exam.description ? exam.description : null,
              scoring_schema: 'DEFAULT',
              submission_message: exam.submission_message
                ? exam.submission_message
                : '',
            } as Exam,
          })
          .pipe(
            map((exam) => exam),
            tap(() => {
              userJourney.set('importedExam');
            }),
            catchError(() => {
              return throwError(
                translator.instant('content.exam.examCopyError')
              );
            })
          );
      },
      editConfigService: ExamService,
      editConfigServiceParams: (_, courseId) => ({ courseId }),
      editDialogInput: (exam, courseId) => ({ exam, courseId }),
      fetchItem: (id) =>
        id && !isNaN(id) ? exams.getExamById({ id }) : of(null),
      fetchItems: (courseId) => {
        return courseId && !_.isNaN(courseId)
          ? courses.getExamsInCourse({ id: courseId }).pipe(
              tap((response) => {
                subscriptionLimit.subscriptionLimit.EXAM_COUNT =
                  response.length;
              }),
              mergeMap((examsArray: Exam[]) => {
                if (
                  !examsArray ||
                  !examsArray.length ||
                  !_.isArray(examsArray)
                ) {
                  return of([]);
                }
                return of(examsArray);
              })
            )
          : of(null);
      },
      fetchChild: (id) => {
        return exams.getQuestionsForExam({ id });
      },
      linkChildToEntity: (id, questions: QuestionModel[]) => {
        return exams.linkQuestionToExam({ id, body: questions }).pipe(
          map((exam) => exam),
          catchError(() => {
            return throwError(
              translator.instant('content.exam.linkQuestionsError')
            );
          })
        );
      },
      headerIcon: 'assignment',
      itemOnHoverActions: [
        {
          icon: 'play_circle',
          label: translator.instant('content.exam.actionMenu.item1'),
          onClick: (exam) => {
            router.navigate([`/cms/exams/${exam.id}`]);
          },
        },
      ],
      itemActions: [
        {
          icon: 'print',
          label: translator.instant('content.exam.actionMenu.item2'),
          onClick: ({ id }) => {
            window.open(
              `${window.location.origin}/cms/exams/print/${id}`,
              '_blank'
            );
          },
        },
        {
          icon: 'copy',
          label: translator.instant('content.exam.actionMenu.item6'),
          onClick: (exam) => panelService.duplicateEntity(this, exam),
        },
        {
          icon: 'code',
          label: translator.instant('content.exam.actionMenu.item7'),
          onClick: (item) => panelService.embedItem(this, item),
        },
        {
          icon: 'description',
          label: translator.instant('content.exam.actionMenu.item5'),
          onClick: (item) => panelService.exportItemAsCSV(this, item.id),
        },
        {
          icon: 'save_alt',
          label: translator.instant('content.exam.actionMenu.item3'),
          onClick: (exam) => panelService.exportItemAsJSON(this, exam.id),
        },
        {
          icon: 'link_off',
          label: translator.instant('content.quiz.actionMenu.item7'),
          onClick: (exam) => panelService.unlinkItems(this, exam.id),
        },
      ],
      unlinkItems: (itemIds, courseId) =>
        forkJoin(
          itemIds.map((id) =>
            courses.removeExamFromCourse({
              id: courseId,
              exam_id: id,
            })
          )
        ),
      itemTextProperty: 'name',
      parentKey: 'exams',
      parentType: PanelEntityType.Course,
      subscriptionLimitType: SubscriptionLimit.EXAM_COUNT,
      selectedItemActions: [
        {
          icon: 'link_off',
          label: translator.instant('content.question.selectedItem.label'),
          onClick: (selectedItemIds) =>
            panelService.unlinkItems(this, selectedItemIds),
        },
      ],
      translations: {
        addItem: translator.instant('content.exam.tooltip'),
        deleteItemError: translator.instant('content.exam.deleteItem.error'),
        deleteItemSuccess: translator.instant(
          'content.exam.deleteItem.success'
        ),
        deleteItemText: translator.instant('content.exam.deleteItem.text'),
        deleteItemTitle: translator.instant('content.exam.deleteItem.title'),
        header: translator.instant('common.label.exams'),
        duplicateEntity: translator.instant('content.exam.copyExam'),
        duplicateEntitySuccess: translator.instant('content.exam.examCopied'),
        linkChild: translator.instant('content.exam.linkQuestions'),
        unlinkItemConfirmText: translator.instant(
          'content.exam.unlinkItem.text'
        ),
        unlinkItemConfirmTitle: translator.instant(
          'content.exam.unlinkItem.title'
        ),
        unlinkItemError: translator.instant('content.exam.unlinkItem.error'),
        unlinkItemSuccess: translator.instant(
          'content.exam.unlinkItem.success'
        ),
      },
      type: PanelEntityType.Exam,
    });
  }
}
