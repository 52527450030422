<div class="label">
  {{ 'findContent.enterPin' | translate }}
</div>
<div [@invalidPinAnimation]="showInvalidPinMessage" class="input-container">
  <div class="pin-char-prefix">
    {{ editionPrefix }}
  </div>
  <span class="pin-char-hyphen"> - </span>
  <input
    (keypress)="keyPress($event)"
    (keyup)="keyUp(index, $event)"
    [class]="'input-' + index"
    [id]="'input-' + index"
    *ngFor="let index of [0, 1, 2, 3, 4, 5]"
    #input
    autofocus
    maxlength="1"
    type="text"
  />
</div>
<div *ngIf="showInvalidPinMessage" class="invalid-message">
  {{ 'findContent.invalidPin' | translate }}
</div>
