import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SubscriptionViewData } from './subscription-view-data';
import dayjs from 'dayjs';

@Injectable()
export class SubscriptionViewDataService {
  constructor(public translator: TranslateService) {}

  //FIXME: adjust paymentInfos model for type safety
  getSubscriptionViewData(paymentInfos: any): SubscriptionViewData {
    const subscriptionViewData = new SubscriptionViewData();

    if (paymentInfos) {
      // subscription type
      if (
        paymentInfos?.stripe_status &&
        paymentInfos.stripe_status.toUpperCase().includes('ACTIVE')
      ) {
        subscriptionViewData.multilicense = false;
        subscriptionViewData.type = this.translator.instant(
          'profile.subscription.label.premium'
        );
        subscriptionViewData.licenseType = this.translator.instant(
          'profile.subscription.label.singlelicense'
        );
      } else if (
        paymentInfos?.stripe_status &&
        paymentInfos.stripe_status.toUpperCase() == 'PAID BY ORGANIZATION'
      ) {
        subscriptionViewData.multilicense = true;
        subscriptionViewData.type = this.translator.instant(
          'profile.subscription.label.businessPlan'
        );
        subscriptionViewData.licenseType = this.translator.instant(
          'profile.subscription.label.multilicense'
        );
      } else {
        subscriptionViewData.free = true;
        subscriptionViewData.multilicense = false;
        subscriptionViewData.licenseType = this.translator.instant(
          'profile.subscription.label.singlelicense'
        );
        subscriptionViewData.type = this.translator.instant(
          'profile.subscription.label.basis'
        );
      }
      // subscription status
      if (!subscriptionViewData.free && paymentInfos.subscriptions) {
        if (paymentInfos.subscriptions[0].status == 'active') {
          subscriptionViewData.active = true;
          subscriptionViewData.status = this.translator.instant(
            'profile.subscription.label.active'
          );
        } else if (paymentInfos.subscriptions[0].status == 'paused') {
          subscriptionViewData.status = this.translator.instant(
            'profile.subscription.label.paused'
          );
        } else {
          subscriptionViewData.status = this.translator.instant(
            'profile.subscription.label.canceled'
          );
        }
      }
    } else {
      // workaround when no payment details found -> free acount
      subscriptionViewData.free = true;
      subscriptionViewData.multilicense = false;
      subscriptionViewData.licenseType = this.translator.instant(
        'profile.subscription.label.singlelicense'
      );
      subscriptionViewData.type = this.translator.instant(
        'profile.subscription.label.basis'
      );
    }

    // manage subscription button
    subscriptionViewData.buttonLabel = subscriptionViewData.free
      ? this.translator.instant('profile.subscription.label.upgradePremium')
      : this.translator.instant(
          'profile.subscription.label.manageSubscription'
        );

    // price and payment interval
    if (paymentInfos && paymentInfos.subscriptions) {
      var price =
        paymentInfos.subscriptions[0].items.data[0].price.unit_amount_decimal /
        100;
      const discount =
        paymentInfos.subscriptions[0].discount?.coupon?.percent_off;
      if (discount) {
        price -= (price * discount) / 100;
      }
      subscriptionViewData.price = price.toFixed(2) + ' €';
    } else {
      subscriptionViewData.price = '--';
    }

    if (!paymentInfos || !paymentInfos?.subscriptions) {
      subscriptionViewData.cycle = '--';
    } else if (
      paymentInfos.subscriptions[0].items.data[0].plan.interval == 'month'
    ) {
      subscriptionViewData.cycle = this.translator.instant(
        'profile.subscription.label.monthly'
      );
    } else {
      subscriptionViewData.cycle = this.translator.instant(
        'profile.subscription.label.yearly'
      );
    }

    // next payment date
    const nextPaymentTimestamp =
      paymentInfos?.subscriptions?.[0]?.current_period_end;
    if (nextPaymentTimestamp) {
      subscriptionViewData.nextPayment = dayjs(
        nextPaymentTimestamp * 1000
      ).format('MMMM DD, YYYY');
    } else {
      subscriptionViewData.nextPayment = '--';
    }

    // payment method
    const defaultPaymentMethod =
      paymentInfos?.invoice_settings?.default_payment_method;
    subscriptionViewData.paymentMethod = defaultPaymentMethod
      ? defaultPaymentMethod
      : '--';
    subscriptionViewData.name = paymentInfos ? paymentInfos.name : '--';
    subscriptionViewData.zipcode = '--';
    subscriptionViewData.city = '--';

    return subscriptionViewData;
  }
}
