import { AnimationPlayer } from '@angular/animations';
import {
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { Store } from '@ngrx/store';
import * as dayjs from 'dayjs';
import { filter, takeUntil } from 'rxjs/operators';
import { AppState } from '../app.types';
import { DestroyNotifier } from '../destroy-notifier';
import { UtilityService } from '../utility/utility.service';

@Component({
  selector: 'qa-play-timer',
  templateUrl: './play-timer.component.html',
  styleUrls: ['./play-timer.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class PlayTimerComponent
  extends DestroyNotifier
  implements OnInit, OnDestroy
{
  @Input() showLargeTimer: boolean;
  @Output() timeOut = new EventEmitter<boolean>();
  @HostBinding('class.is-active') isActive = false;
  animationPlayer: AnimationPlayer;
  cleanUpTimeoutId: number;
  delayTimeoutId: number;
  intervalId: number;
  previousFrom: string;
  previousTo: string;
  circumference;
  remainingSeconds: number;
  dashoffset: number;

  constructor(
    elementRef: ElementRef<HTMLElement>,
    private utilityService: UtilityService,
    private store: Store<AppState>
  ) {
    super();
    elementRef.nativeElement.classList.add('qa-play-timer');
  }

  ngOnInit() {
    const radius = this.showLargeTimer ? 100 : 16;
    this.circumference = 2 * Math.PI * radius;
    this.store
      .pipe(
        takeUntil(this.destroy$),
        filter(({ play }) => {
          if (!play.timerFrom || !play.timerTo) {
            if (this.isActive) this.cancel();
            return false;
          }
          const datesDiffer =
            this.previousFrom !== play.timerFrom ||
            this.previousTo !== play.timerTo;
          return datesDiffer;
        })
      )
      .subscribe((state) => this.handleStateChange(state));
  }

  get formattedTime() {
    return this.remainingSeconds > 0
      ? this.utilityService.toHHMMSS(this.remainingSeconds)
      : '00:00';
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.cancel();
  }

  cancel() {
    window.clearTimeout(this.delayTimeoutId);
    window.clearInterval(this.intervalId);
    window.clearTimeout(this.cleanUpTimeoutId);
    if (this.animationPlayer) {
      this.animationPlayer.destroy();
      this.animationPlayer = null;
    }
    this.isActive = false;
  }

  handleStateChange({ play }: AppState) {
    if (this.isActive) this.cancel();
    this.previousFrom = play.timerFrom;
    this.previousTo = play.timerTo;
    const from = dayjs(play.timerFrom);
    const to = dayjs(play.timerTo);
    let delay = 0;
    let durationSeconds = to.diff(from, 's');
    if (from.isAfter(dayjs())) {
      delay = from.diff(dayjs(), 'ms');
    } else {
      durationSeconds = Math.round(to.diff(dayjs(), 'ms') / 1000);
    }
    this.delayTimeoutId = window.setTimeout(() => {
      this.start(durationSeconds);
    }, delay);
  }

  start(durationSeconds: number) {
    this.isActive = true;
    this.remainingSeconds = durationSeconds;
    this.intervalId = window.setInterval(() => {
      if (this.remainingSeconds === 0) {
        this.isActive = false;
        window.clearInterval(this.intervalId);
        this.timeOut.emit(true);
        this.cleanUpTimeoutId = window.setTimeout(() => {
          if (!this.animationPlayer) return;
          this.animationPlayer.destroy();
          this.animationPlayer = null;
        }, 200);
        return;
      }
      this.remainingSeconds--;
      this.progress(this.getPercentage(this.remainingSeconds, durationSeconds));
    }, 1000);
  }

  getPercentage(currentTime: number, total: number) {
    return (currentTime * 100) / total;
  }

  private progress(value: number) {
    const progress = value / 100;
    this.dashoffset = this.circumference * (1 - progress);
  }
}
