import { Injectable } from '@angular/core';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { LiveEvent, QuestionModel } from 'src/app/api/models';
import {
  BooksService,
  CoursesService,
  LiveEventsService,
} from 'src/app/api/services';
import { PanelService } from '../panel.service';
import { Panel, PanelEntityType } from '../panel.types';
import { TranslateService } from '@ngx-translate/core';
import { forkJoin, of, throwError } from 'rxjs';
import * as _ from 'lodash';
import { Router } from '@angular/router';
import { UserJourneyService } from '../../pages/onboarding-steps/user-journey.service';
import { LiveEventEditConfigService } from '../../edit/configs/live-event.service';
import { XLSXReader } from '../../common/import-helpers/xlsxReader';
import { settingAnswers, settingData } from 'src/app/utility/app.utils';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { SubscriptionLimitService } from '../../common/dialogs/subscription-limit-warning/subscription-limit.service';
import { SubscriptionLimit } from '../../common/dialogs/subscription-limit-warning/subscription-limit-warning.component';

@Injectable()
export class EventsPanel extends Panel<LiveEvent> {
  isBook = false;

  constructor(
    private courses: CoursesService,
    private books: BooksService,
    private events: LiveEventsService,
    private panelService: PanelService,
    private translator: TranslateService,
    private router: Router,
    private userJourney: UserJourneyService,
    private matSnackBar: MatSnackBar,
    private matDialog: MatDialog,
    private subscriptionLimit: SubscriptionLimitService
  ) {
    super();
    this.initializePanel();
  }

  initializePanel() {
    this.isBook =
      location?.pathname?.split('/')?.[3] === 'book' ||
      this.router.url.indexOf('/book') !== -1;
    super.init({
      addMenuItems: [
        {
          icon: 'add',
          onClick: () => this.panelService.editItem(this),
          text: this.translator.instant('liveEvents.list.create'),
        },
      ],
      imports: [
        {
          title: this.translator.instant('content.event.title.item1'),
          description: this.translator.instant(
            'content.event.description.item1'
          ),
          action: {
            icon: 'move_to_inbox',
            click: () => this.panelService.importItems(this),
            text: this.translator.instant('content.event.addMenu.item1'),
          },
        },
        {
          title: this.translator.instant('content.event.title.item2'),
          description: this.translator.instant(
            'content.event.description.item2'
          ),
          action: {
            icon: 'code',
            click: () => this.panelService.importItemAsJSON(this),
            text: this.translator.instant('content.event.addMenu.item2'),
          },
        },
        {
          title: this.translator.instant('liveEvents.list.importfromKahoot'),
          description: this.translator.instant(
            'liveEvents.description.ImportFromKahoot'
          ),
          action: {
            text: this.translator.instant(
              'liveEvents.liveEventDialogTitles.ImportFromKahoot'
            ),
            icon: 'description',
            click: () => {
              this.importFromKahoot();
            },
          },
          extra: {
            name: this.translator.instant(
              'liveEvents.liveEventDialogTitles.Extra'
            ),
            type: 'link',
            icon: 'keyboard_arrow_right',
            click: () => {
              window.open(
                'https://quizacademy.de/tipps/kahoot-inhalte-importieren/',
                '_blank'
              );
            },
          },
        },
      ],
      childTypes: [PanelEntityType.EventQuestion],
      createItemsFn: (items, courseId) => {
        return this.events
          .createLiveEvent({
            body: items[0],
          })
          .pipe(
            switchMap((event) => {
              this.userJourney.set('importedLiveEvent');
              if (courseId) {
                const query = {
                  id: courseId,
                  body: event,
                };
                const op = this.isBook
                  ? this.books.addEventToBook(query)
                  : this.courses.addEventToCourse(query);
                return forkJoin([of(event), op]);
              } else {
                return forkJoin([of(event), of(null)]);
              }
            }),
            map(([event, _]) => [event])
          );
      },
      editConfigService: LiveEventEditConfigService,
      editConfigServiceParams: (_, courseId) => ({ courseId }),
      editDialogInput: (liveEvent, courseId) => ({ liveEvent, courseId }),
      fetchItem: (id) => this.events.getLiveEventById({ id }),
      fetchItems: (courseId) => {
        return (
          this.isBook
            ? this.books.getEventsInBook({ id: courseId })
            : !_.isNaN(courseId)
            ? this.courses.getEventsInCourse({ id: courseId })
            : of([])
        ).pipe(
          mergeMap((eventsArray: LiveEvent[]) => {
            if (
              !eventsArray ||
              !eventsArray.length ||
              !_.isArray(eventsArray)
            ) {
              this.subscriptionLimit.subscriptionLimit.LIVE_EVENT_COUNT = 0;
              return of([]);
            }
            this.subscriptionLimit.subscriptionLimit.LIVE_EVENT_COUNT =
              eventsArray.length;
            return of(eventsArray);
          })
        );
      },
      fetchChild: (id) => {
        return this.events.getQuestionsForEvent({ id });
      },
      linkChildToEntity: (id, questions: QuestionModel[]) => {
        return this.events.linkQuestionToEvent({ id, body: questions }).pipe(
          map((event) => event),
          catchError(() => {
            return throwError(
              this.translator.instant('content.exam.linkQuestionsError')
            );
          })
        );
      },
      headerIcon: 'live_tv',
      itemOnHoverActions: [
        {
          icon: 'play_circle',
          label: this.translator.instant('content.exam.actionMenu.item1'),
          onClick: (event) => {
            this.router.navigate([`/cms/live-events/${event.id}`]);
          },
        },
      ],
      itemActions: [
        {
          icon: 'print',
          label: this.translator.instant('content.exam.actionMenu.item2'),
          onClick: ({ id }) => {
            window.open(
              `${location.origin}/cms/live-events/print/${id}`,
              '_blank'
            );
          },
        },
        {
          icon: 'code',
          label: this.translator.instant('content.exam.actionMenu.item7'),
          onClick: (item) => this.panelService.embedItem(this, item),
        },
        {
          icon: 'link_off',
          label: this.isBook
            ? this.translator.instant('content.quiz.actionMenu.item7Book')
            : this.translator.instant('content.quiz.actionMenu.item7'),
          onClick: (event) => this.panelService.unlinkItems(this, event.id),
        },
      ],
      unlinkItems: (itemIds, courseId) =>
        forkJoin(
          itemIds.map((id) =>
            this.isBook
              ? this.books.removeEventFromBook({
                  id: courseId,
                  event_id: id,
                })
              : this.courses.removeEventFromCourse({
                  id: courseId,
                  event_id: id,
                })
          )
        ),
      itemTextProperty: 'name',
      parentKey: 'events',
      parentType: PanelEntityType.Course,
      subscriptionLimitType: SubscriptionLimit.LIVE_EVENT_COUNT,
      selectedItemActions: [
        {
          icon: 'link_off',
          label: this.translator.instant('content.question.selectedItem.label'),
          onClick: (selectedItemIds) =>
            this.panelService.unlinkItems(this, selectedItemIds),
        },
      ],
      translations: {
        addItem: this.translator.instant('content.event.tooltip'),
        deleteItemError: this.translator.instant(
          'content.exam.deleteItem.error'
        ),
        deleteItemSuccess: this.translator.instant(
          'content.exam.deleteItem.success'
        ),
        deleteItemText: this.translator.instant('content.exam.deleteItem.text'),
        deleteItemTitle: this.translator.instant(
          'content.exam.deleteItem.title'
        ),
        header: 'Live-Events',
        duplicateEntity: this.translator.instant('content.exam.copyExam'),
        duplicateEntitySuccess: this.translator.instant(
          'content.exam.examCopied'
        ),
        linkChild: this.translator.instant('content.exam.linkQuestions'),
        unlinkItemConfirmText: this.isBook
          ? this.translator.instant('content.event.unlinkItem.textBook')
          : this.translator.instant('content.event.unlinkItem.text'),
        unlinkItemConfirmTitle: this.isBook
          ? this.translator.instant('content.event.unlinkItem.titleBook')
          : this.translator.instant('content.event.unlinkItem.title'),
        unlinkItemError: this.isBook
          ? this.translator.instant('content.event.unlinkItem.errorBook')
          : this.translator.instant('content.event.unlinkItem.error'),
        unlinkItemSuccess: this.isBook
          ? this.translator.instant('content.event.unlinkItem.successBook')
          : this.translator.instant('content.event.unlinkItem.success'),
      },
      type: PanelEntityType.Event,
    });
  }

  async importFromKahoot() {
    const excelFile = await this.panelService.selectExcelFileAsBinary();
    const file = XLSXReader.excelToArray(excelFile);
    const name = file[0][0];
    if (!name || !name.trim()) {
      this.matSnackBar.open(
        this.translator.instant('liveEvents.list.importFromExcel.text1')
      );
      return;
    }
    let liveEvent;
    const snackbar = this.matSnackBar.open(
      this.translator.instant('liveEvents.list.importFromExcel.text2')
    );
    const questions = this.panelService.importQuestionsFromKahoot(excelFile);
    let questionArr = settingData(questions);
    settingAnswers(questionArr);
    questionArr.forEach((question) => {
      if (question['type'] === 'True/False') {
        question['type'] = 1;
        if (question['answers'].length && question['answers'][0]['is_right']) {
          question['is_right'] = question['answers'][0]['is_right'];
        }
        delete question['answers'];
      } else if (question['type'] === 'Multiple Choice') {
        question['type'] = 0;
      } else if (question['type'] === 'Single Choice') {
        question['type'] = 2;
      }
    });
    questionArr = questionArr.filter(
      (q) => q.type === 1 || (q.type !== 1 && q.answers?.length)
    );
    if (name) {
      const parentId =
        this.panelService.getParentItemId(PanelEntityType.Event) ?? null;
      liveEvent = await this.events
        .createLiveEvent({
          body: {
            name: name,
          } as LiveEvent,
        })
        .pipe(
          switchMap((event) => {
            this.userJourney.set('importedLiveEvent');
            if (parentId) {
              const query = {
                id: parentId,
                body: event,
              };
              const op = this.isBook
                ? this.books.addEventToBook(query)
                : this.courses.addEventToCourse(query);
              return forkJoin([of(event), op]);
            } else {
              return forkJoin([of(event), of(null)]);
            }
          }),
          map(([event, _]) => [event])
        )
        .toPromise();
      this.matSnackBar.open(
        this.translator.instant('liveEvents.list.importFromExcel.text3')
      );
      this.userJourney.set('importedLiveEvent');
      await this.events
        .copyQuestionToEvent({
          id: liveEvent[0].id,
          body: questionArr,
        })
        .toPromise();
      snackbar.dismiss();
      this.matSnackBar.open(
        this.translator.instant('liveEvents.list.importFromExcel.text4')
      );
      this.matDialog.closeAll();
      // router.navigate(['cms', 'live-events', liveEvent.id]);
    }
  }
}
