import {
  AfterViewInit,
  Component,
  ElementRef,
  Inject,
  OnDestroy,
  QueryList,
  ViewChildren,
  ViewEncapsulation,
} from '@angular/core';
import {
  UntypedFormControl,
  FormControlDirective,
  FormControlName,
  UntypedFormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { MatDatepicker } from '@angular/material/datepicker';
import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  filter,
  takeUntil,
  debounceTime,
  switchMap,
  tap,
} from 'rxjs/operators';
import { DestroyNotifier } from '../common/destroy-notifier';
import { DialogService } from '../common/dialog.service';
import { EditConfirmCloseDialogComponent } from './edit-confirm-close-dialog.component';
import { Edit } from './edit.types';
import { TranslateService } from '@ngx-translate/core';
import {
  EmailDialogComponent,
  EmailDialogData,
} from 'src/app/auth/email-dialog.component';
import { AuthService } from 'src/app/auth/auth.service';
import icons from './templates/material-icon-list';

export interface EditDialogData {
  config: Edit.Config;
  value?: {};
}

const originFormControlNgOnChanges = FormControlDirective.prototype.ngOnChanges;
FormControlDirective.prototype.ngOnChanges = function () {
  if (this.valueAccessor && this.valueAccessor._elementRef && this.form) {
    this.form.nativeElement = this.valueAccessor._elementRef.nativeElement;
  } else {
    return;
  }
  return originFormControlNgOnChanges.apply(this, arguments);
};

const originFormControlNameNgOnChanges = FormControlName.prototype.ngOnChanges;
FormControlName.prototype.ngOnChanges = function () {
  const result = originFormControlNameNgOnChanges.apply(this, arguments);
  if (!this.valueAccessor.autocomplete) {
    if (this.valueAccessor._elementRef) {
      this.control.nativeElement = this.valueAccessor._elementRef.nativeElement;
    }
  }
  return result;
};

@Component({
  templateUrl: './edit-dialog.component.html',
  styleUrls: ['./edit-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class EditDialogComponent
  extends DestroyNotifier
  implements AfterViewInit, OnDestroy
{
  @ViewChildren(MatDatepicker)
  datepickerComponents: QueryList<MatDatepicker<any>>;
  datepickers: { [fieldKey: string]: MatDatepicker<any> } = {};

  autoCompleteOptions: any[] = [];

  defaultCompareWith = (v1: any, v2: any) => {
    return typeof v1 === 'object' ? v1 && v2 && v1.id === v2.id : v1 === v2;
  };

  formGroup: UntypedFormGroup;
  options: { [fieldKey: string]: Edit.Option[] } = {};

  get config() {
    return this.data.config;
  }

  get currentValue() {
    return this.formGroup.getRawValue();
  }

  get initialValue() {
    return this.data.value;
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: EditDialogData,
    private dialogRef: MatDialogRef<EditDialogComponent>,
    private dialogService: DialogService,
    private matDialog: MatDialog,
    private matSnackBar: MatSnackBar,
    elementRef: ElementRef<HTMLElement>,
    private translator: TranslateService,
    private authService: AuthService
  ) {
    super();
    elementRef.nativeElement.classList.add('qa-edit-dialog');
    this.dialogRef
      .backdropClick()
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => this.close());
    this.initFormGroup();
  }

  get isGerman() {
    return this.translator.currentLang === 'de';
  }

  displayFn(option): string {
    return option ? option.name : '';
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.config.fields
        .filter((f) => f.enableDatepicker)
        .forEach((f, i) => {
          this.datepickers[f.key as string] =
            this.datepickerComponents.toArray()[i];
        });
    });
  }

  ngOnDestroy() {
    this.config.fields.forEach((field) => (field['blurInput'] = false));
  }

  onBlur(field: any, event) {
    field['blurInput'] = this.formGroup.controls[field.key].value
      ? true
      : false;

    if (field.showSuggestion) {
      event.target.value = '';
      field.showSuggestion = false;
    }
  }

  onEdit(field: any) {
    field['blurInput'] = false;
    field['onHover'] = false;
    setTimeout(() => {
      (this.formGroup.get(field.key) as any).nativeElement.focus();
    }, 100);
  }

  async close() {
    if (this.formGroup.dirty) {
      const isConfirmed = await this.matDialog
        .open(EditConfirmCloseDialogComponent, { width: '400px' })
        .afterClosed()
        .toPromise();
      if (!isConfirmed) return;
    }
    this.dialogRef.close();
  }

  async delete() {
    const isConfirmed = await this.dialogService
      .confirmDelete(
        this.config.deleteConfirmTitle,
        this.config.deleteConfirmText
      )
      .pipe(filter((isConfirmed) => isConfirmed))
      .toPromise();
    if (!isConfirmed) return;
    this.formGroup.disable();
    this.matSnackBar.open(
      `${this.config.itemName} ` +
        this.translator.instant('common.message.delete')
    );
    await this.config.handlers.delete(this.initialValue);
    this.dialogRef.close();
    this.matSnackBar.open(
      `${this.config.itemName} ` +
        this.translator.instant('common.message.deleted'),
      undefined,
      { duration: 3000 }
    );
  }

  enableFormGroup() {
    this.formGroup.enable();
    this.config.fields
      .filter((f) => f.disabled || (f.select && !this.options[f.key as string]))
      .forEach((f) => this.formGroup.get(f.key as string).disable());
  }

  getSelectTriggerText(field: Edit.Field): string {
    if (!this.options[field.key as string]) return '';
    const fieldValue = this.formGroup.get(field.key as string).value;
    const selectedValues: any[] = [];
    if (fieldValue) {
      const wrappedValues = Array.isArray(fieldValue)
        ? fieldValue
        : [fieldValue];
      selectedValues.push(...wrappedValues);
    }
    const compare = (o1: any, o2: any) => {
      if (field.select.compareWith) {
        return field.select.compareWith(o1, o2);
      } else {
        return this.defaultCompareWith(o1, o2);
      }
    };
    return selectedValues
      .map((v) => {
        const option = this.options[field.key as string].find((o) => {
          return o.value === v || compare(o.value, v);
        });
        if (!option) return this.translator.instant('common.message.unknown');
        return option.text || option.value;
      })
      .join(', ');
  }

  async initFormGroup() {
    this.formGroup = new UntypedFormGroup({});
    this.data.config.fields.forEach((field) => {
      const validators: ValidatorFn[] = [];
      if (field.required) {
        validators.push(Validators.required);
      }
      if (field.validators) {
        validators.push(...field.validators);
      }
      let initialValue = this.initialValue
        ? this.initialValue[field.key]
        : undefined;
      if (field.initialValue) {
        initialValue = field.initialValue;
      }
      const control = new UntypedFormControl(
        field.autocomplete ? '' : initialValue,
        validators
      );
      this.formGroup.addControl(field.key as string, control);
      if (field.select) {
        field.select.getOptions().then((options) => {
          this.options[field.key as string] = options;
          if (!field.disabled) control.enable();
        });
      }
      if (field.autocomplete) {
        if (this.formGroup.controls[field.key as string]) {
          this.formGroup.controls[field.key as string].valueChanges
            .pipe(
              debounceTime(400),
              tap(() => {
                this.autoCompleteOptions = [];
              }),
              switchMap((value) => {
                return field.autocomplete.getAutoCompleteOptions(value);
              })
            )
            .subscribe((data) => {
              if (data.length) {
                this.autoCompleteOptions = data;
              }
            });
        }
      }
      field['blurInput'] = this.formGroup.controls[field.key as string].value
        ? true
        : false;
    });
    this.formGroup.disable();
    if (this.config.handlers.init) {
      this.data.value = await this.config.handlers.init(this.data.value);
    }
    if (this.initialValue) {
      this.patchForm();
      this.data.config.fields.forEach((field) => {
        field['blurInput'] = this.formGroup.controls[field.key as string].value
          ? true
          : false;
      });
    }
    this.enableFormGroup();
  }

  async save() {
    const updatedValue = { ...this.initialValue, ...this.formGroup.value };
    const createOrUpdate = this.initialValue
      ? this.config.handlers.update
      : this.config.handlers.create;
    const saveVerb = this.initialValue
      ? this.translator.instant('common.message.saved')
      : this.translator.instant('common.message.created');
    const itemName = this.config.itemName;
    this.formGroup.disable();
    this.matSnackBar.open(
      `${itemName} ` +
        this.translator.instant('common.message.becomes') +
        `
      ${saveVerb}...`,
      null,
      { duration: 2000 }
    );
    try {
      const storedValue = await createOrUpdate(updatedValue, this.initialValue);
      if (this.initialValue) {
        this.data.value = storedValue;
        this.patchForm();
        this.formGroup.markAsPristine();
        this.formGroup.markAsUntouched();
      } else {
        this.dialogRef.close(storedValue);
      }
    } catch (error) {
      if (itemName === 'User' && error.error.statusCode === 400) {
        this.matSnackBar.open(error.error.message, undefined, {
          duration: 5000,
        });
      } else {
        if (this.config.id === 'profile') {
          this.dialogService
            .confirm({
              title: this.translator.instant('common.label.important'),
              text: this.translator.instant('profile.warning'),
              icon: 'warning',
              buttons: [
                {
                  text: this.translator.instant('common.button.abort'),
                  color: 'warn',
                },
                {
                  text: this.translator.instant('common.button.continue'),
                  color: 'primary',
                  value: true,
                },
              ],
            })
            .subscribe(async (value) => {
              if (value) {
                updatedValue.validateEmail = false;
                const storedValue = await createOrUpdate(
                  updatedValue,
                  this.initialValue
                );
                this.data.value = storedValue;
                this.patchForm();
                this.formGroup.markAsPristine();
                this.formGroup.markAsUntouched();
                this.matSnackBar.open(`${itemName} ${saveVerb}`, undefined, {
                  duration: 3000,
                });
              }
            });
        } else {
          this.matSnackBar.open(
            `${itemName} ` +
              this.translator.instant('common.message.couldNot') +
              ` ${saveVerb} `,
            undefined,
            { duration: 5000 }
          );
        }
      }
      throw error;
    } finally {
      this.enableFormGroup();
    }
    this.matSnackBar.open(`${itemName} ${saveVerb}`, undefined, {
      duration: 3000,
    });
  }

  handleSuggestion(event, field) {
    const element = event.target;
    if (
      !field.showSuggestion &&
      element.value === '' &&
      field.suggestion &&
      field.suggestion !== ''
    ) {
      element.value = `${field.suggestion}`;
      field.showSuggestion = true;
    }

    if (field.showSuggestion) {
      event.target.select();
    }
  }

  onPointerDown(event, field) {
    if (field.showSuggestion) {
      event.preventDefault();
    }
  }

  onInput(field) {
    if (field.showSuggestion) {
      field.showSuggestion = false;
    }
  }

  onKeyDown(event, field, controlName) {
    const element = event.target;
    if (event.keyCode === 13 && field.showSuggestion) {
      event.preventDefault();
      element.value = '';
      field.showSuggestion = false;
      element.value = field.suggestion;
      this.formGroup.controls[controlName].setValue(field.suggestion);
    }
  }

  onPaste(event, field) {
    let pastedData = event.clipboardData.getData('text/plain');
    if (this.data.config.id === 'user' && field.key === 'email') {
      if (!pastedData.includes('\t') && !pastedData.includes(',')) {
        return;
      }
      let exceldata = pastedData.split('\t');
      if (exceldata.length !== 4) {
        exceldata = pastedData.split(/[\s]*,[\s]*/);
        if (exceldata.length !== 4) {
          return;
        }
      }
      event.preventDefault();
      this.formGroup.controls['email']['nativeElement'].click();
      this.formGroup.controls['email'].setValue(exceldata[0]);
      this.formGroup.controls['firstname']['nativeElement'].click();
      this.formGroup.controls['firstname'].setValue(exceldata[1]);
      this.formGroup.controls['lastname']['nativeElement'].click();
      this.formGroup.controls['lastname'].setValue(exceldata[2]);
      this.formGroup.controls['password']['nativeElement'].click();
      this.formGroup.controls['password'].setValue(
        this.config.fields.filter((f) => f.key === 'password')[0].suggestion
      );
      this.formGroup.controls['groups'].setValue(['CourseManager']);
      const orgfield: HTMLElement = document.querySelector(
        `[fieldname='organization']`
      );
      orgfield.click();
      orgfield['value'] = exceldata[3];
    } else if (this.data.config.id === 'organization' && field.key === 'name') {
      if (this.formGroup.controls['name'].value) {
        return;
      }

      event.preventDefault();

      if (
        event.clipboardData.getData('text/html') !== null &&
        event.clipboardData.getData('text/html') !== '' &&
        event.clipboardData.getData('text/html').includes('table')
      ) {
        pastedData = event.clipboardData.getData('text/html');
        const data = document.createElement('html');
        data.innerHTML = pastedData;
        const tabledata = data.querySelector('table');
        if (!tabledata) {
          return;
        }
        pastedData = [];
        Array.prototype.forEach.call(
          tabledata.querySelectorAll('tr'),
          (row) => {
            const buffer = [];
            Array.prototype.forEach.call(row.children, (cell) => {
              const txt = document.createElement('textarea');
              cell.innerHTML = cell.innerHTML
                .replace(/[\n|\r\n]*/g, '')
                .replace(/\<br[\s]*\/?\>[\s]*/gi, '\n');
              txt.innerHTML = cell.innerHTML;
              buffer.push(txt.value);
            });
            pastedData.push(buffer);
          }
        );
        this.formGroup.controls['name'].setValue(
          pastedData[0][0] && pastedData[0][0] !== 'NULL'
            ? pastedData[0][0]
            : ''
        );
        this.formGroup.controls['short_name']['nativeElement'].click();
        this.formGroup.controls['short_name'].setValue(
          pastedData[0][1] && pastedData[0][1] !== 'NULL'
            ? pastedData[0][1]
            : ''
        );
        this.formGroup.controls['address']['nativeElement'].click();
        this.formGroup.controls['address'].setValue(
          pastedData[0][2] && pastedData[0][2] !== 'NULL'
            ? pastedData[0][2]
            : ''
        );
        this.formGroup.controls['city']['nativeElement'].click();
        this.formGroup.controls['city'].setValue(
          pastedData[0][3] && pastedData[0][3] !== 'NULL'
            ? pastedData[0][3]
            : ''
        );
        this.formGroup.controls['zipcode']['nativeElement'].click();
        this.formGroup.controls['zipcode'].setValue(
          pastedData[0][4] && pastedData[0][4] !== 'NULL'
            ? pastedData[0][4]
            : ''
        );
        this.formGroup.controls['country']['nativeElement'].click();
        this.formGroup.controls['country'].setValue(
          pastedData[0][5] && pastedData[0][5] !== 'NULL'
            ? pastedData[0][5]
            : ''
        );
        this.formGroup.controls['weblink']['nativeElement'].click();
        this.formGroup.controls['weblink'].setValue(
          pastedData[0][13] && pastedData[0][13] !== 'NULL'
            ? pastedData[0][13]
            : ''
        );
        this.formGroup.controls['email']['nativeElement'].click();
        this.formGroup.controls['email'].setValue(
          pastedData[0][12] && pastedData[0][12] !== 'NULL'
            ? pastedData[0][12]
            : ''
        );
        this.formGroup.controls['phone']['nativeElement'].click();
        this.formGroup.controls['phone'].setValue(
          pastedData[0][14] && pastedData[0][14] !== 'NULL'
            ? pastedData[0][14]
            : ''
        );
        this.formGroup.controls['fax']['nativeElement'].click();
        this.formGroup.controls['fax'].setValue(
          pastedData[0][15] && pastedData[0][15] !== 'NULL'
            ? pastedData[0][15]
            : ''
        );
        this.formGroup.controls['description']['nativeElement'].click();
        this.formGroup.controls['description'].setValue(
          pastedData[0][6] && pastedData[0][6] !== 'NULL'
            ? pastedData[0][6]
            : ''
        );
        this.formGroup.controls['student_count']['nativeElement'].click();
        this.formGroup.controls['student_count'].setValue(
          pastedData[0][16] && pastedData[0][16] !== 'NULL'
            ? pastedData[0][16]
            : ''
        );
        return;
      }

      pastedData = pastedData.replace(/\s\|\s/g, '\n');
      const telRegex = /^(?:(?:Tel\.|Telefon|Phone|Fon|\+)\:?[^\d\+(]*)(.*)$/gm;
      const faxRegex = /^(?:(?:Fax|Telefax)\:?[^\d\+(]*)(.*)$/gm;
      const mailRegex =
        /[\w\.\_\-\(\)]*(?:\@|at|ät|\|at\|)[\w\.\_\-\(\)]*\.(?:de|com|net|org)/gm;
      const iaddrRegex = /(?:www\.)[\w-\.\_\(\)]*(?:\.de|\.com)/gm;
      const plzRegex = /^(?:D-|DE-)?([\d]{4,5})/gm;
      const placeRegex = /^(?:(?:D-|DE-)?[\d]{4,5})[\s]*(.*)$/gm;
      const addrRegex =
        /\[BREAK\](((?!\[BREAK\]).)*)(?:\[BREAK\])*(?:(?:D-|DE-)?[\d]{4,5})/gm;

      const city = placeRegex.exec(pastedData);
      this.formGroup.controls['city']['nativeElement'].click();
      if (city && city[1] && !this.formGroup.controls['city'].value) {
        this.formGroup.controls['city'].setValue(city[1]);
      }

      const zipcode = plzRegex.exec(pastedData);
      this.formGroup.controls['zipcode']['nativeElement'].click();
      if (zipcode && zipcode[1] && !this.formGroup.controls['zipcode'].value) {
        this.formGroup.controls['zipcode'].setValue(zipcode[1]);
      }

      const mail = mailRegex.exec(pastedData);
      this.formGroup.controls['email']['nativeElement'].click();
      if (mail && mail[0] && !this.formGroup.controls['email'].value) {
        this.formGroup.controls['email'].setValue(mail[0]);
      }

      const phone = telRegex.exec(pastedData);
      this.formGroup.controls['phone']['nativeElement'].click();
      if (phone && phone[1] && !this.formGroup.controls['phone'].value) {
        this.formGroup.controls['phone'].setValue(
          phone[0].startsWith('+') ? phone[0] : phone[1]
        );
      }

      const fax = faxRegex.exec(pastedData);
      this.formGroup.controls['fax']['nativeElement'].click();
      if (fax && fax[1] && !this.formGroup.controls['fax'].value) {
        this.formGroup.controls['fax'].setValue(fax[1]);
      }

      const address = addrRegex.exec(
        pastedData.replace(/(\r\n|\n|\r)/g, '[BREAK]')
      );
      this.formGroup.controls['address']['nativeElement'].click();
      if (address && address[1] && !this.formGroup.controls['address'].value) {
        this.formGroup.controls['address'].setValue(address[1]);
      }

      const iaddr = iaddrRegex.exec(pastedData);
      this.formGroup.controls['weblink']['nativeElement'].click();
      if (iaddr && iaddr[0] && !this.formGroup.controls['weblink'].value) {
        this.formGroup.controls['weblink'].setValue(iaddr[0]);
      }

      this.formGroup.controls['country']['nativeElement'].click();
      if (!this.formGroup.controls['country'].value) {
        this.formGroup.controls['country'].setValue('Deutschland');
      }

      this.formGroup.controls['name']['nativeElement'].click();
      this.formGroup.controls['name'].setValue(pastedData.split('\n')[0]);
      if (
        pastedData.split('\n') &&
        pastedData.split('\n').length > 1 &&
        pastedData.split('\n')[1].replace(/(\r\n|\n|\r)/g, '') !== '' &&
        pastedData.split('\n')[1].replace(/(\r\n|\n|\r)/g, '') !== address[1]
      ) {
        this.formGroup.controls['name'].setValue(
          this.formGroup.controls['name'].value +
            ' ' +
            pastedData.split('\n')[1]
        );
      }
    }
  }

  patchForm() {
    this.data.config.fields.forEach(async (field) => {
      if (!field.autocomplete && this.initialValue) {
        this.formGroup.patchValue(
          { [field.key]: this.initialValue[field.key] },
          { emitEvent: false }
        );
      } else {
        this.data.value[field.key] = await field.autocomplete.getInitialValue(
          this.initialValue[field.key]
        );
        this.autoCompleteOptions.push(this.data.value[field.key][0]);
        if (this.data.value[field.key][0]) {
          this.formGroup.patchValue({
            [field.key]: this.data.value[field.key][0].value,
          });
        }
      }
    });
  }

  forgotPassword() {
    const dialogConfig: MatDialogConfig<EmailDialogData> = {
      data: {
        email: this.data.value['email'],
        isReset: true,
      },
    };
    this.matDialog
      .open(EmailDialogComponent, dialogConfig)
      .afterClosed()
      .pipe(filter((email) => !!email))
      .subscribe((email) => this.authService.forgotPassword(email));
  }

  viewDPA() {
    window.open(
      'https://quizacademy.de/wp-content/uploads/AVV_DE__tom_angepasst_final.pdf',
      '_blank'
    );
  }

  iconNames(field: UntypedFormControl): string[] {
    if (!field.value || !field.value.length) {
      return icons;
    }
    const compareTo = field.value?.toLowerCase();
    return icons.filter((i) => i.includes(compareTo) || compareTo.includes(i));
  }

  personalizeDPA() {
    this.dialogService
      .confirm({
        title: this.translator.instant('common.label.important'),
        text: this.translator.instant('profile.dpa.hint'),
        buttons: [
          {
            text: this.translator.instant('common.button.abort'),
            color: 'warn',
          },
          {
            text: this.translator.instant('profile.dpa.toWebsite'),
            color: 'primary',
            value: true,
          },
        ],
      })
      .subscribe((value) => {
        if (value) {
          window.open(
            'https://quizacademy.de/auftragsverarbeitungsvertrag/',
            '_blank'
          );
        }
      });
  }
}
