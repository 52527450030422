import { CommonModule } from '@angular/common';
import { NgModule, inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import {
  MatPaginatorIntl,
  MatPaginatorModule,
} from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { PaginatorIntlService } from './paginator-intl-service';
import { CmsCommonModule } from '../common/common.module';
import { CategoryListComponent } from './category-list.component';
import { CommentDetailsDialogComponent } from './comment-details-dialog.component';
import { CommentListComponent } from './comment-list.component';
import { CourseListComponent } from './course-list.component';
import { FlashCardListComponent } from './flash-card-list.component';
import { FlashCardStackListComponent } from './flash-card-stack-list.component';
import { CourseMarketListComponent } from './course-market/course-market-list/course-market-list.component';
import { FindOutCourseComponent } from './course-market/find-out-course/find-out-course.component';
import { QuestionListComponent } from './question-list.component';
import { QuizListComponent } from './quiz-list.component';
import { CommentListDialogComponent } from './comment-list-dialog.component';
import { TranslateService } from '@ngx-translate/core';
import { CourseExportsComponent } from './course-exports.component';
import { CourseMarketComponent } from './course-market/course-market.component';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { TagListComponent } from './tag-list.component';
import { MatTabsModule } from '@angular/material/tabs';
import { TabListComponent } from './tab-list.component';
import { MatBadgeModule } from '@angular/material/badge';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { BookListComponent } from './book-list.component';
import { GridDataComponent } from './grid-data/grid-data.component';
import { MatListModule } from '@angular/material/list';
import { ContentListingComponent } from './content-listing/content-listing.component';
import { OrganizationForCourseComponent } from './course-market/organization-for-course/organization-for-course.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { NoEntityInfoComponent } from './no-entity-info/no-entity-info.component';

@NgModule({
  declarations: [
    CategoryListComponent,
    CommentDetailsDialogComponent,
    CommentListComponent,
    CommentListDialogComponent,
    CourseListComponent,
    FlashCardListComponent,
    FlashCardStackListComponent,
    CourseMarketListComponent,
    QuestionListComponent,
    QuizListComponent,
    CourseExportsComponent,
    CourseMarketComponent,
    TagListComponent,
    TabListComponent,
    BookListComponent,
    GridDataComponent,
    ContentListingComponent,
    FindOutCourseComponent,
    OrganizationForCourseComponent,
    NoEntityInfoComponent,
  ],
  providers: [
    {
      provide: MatPaginatorIntl,
      useFactory: () => new PaginatorIntlService(inject(TranslateService)),
    },
  ],
  imports: [
    CmsCommonModule,
    CommonModule,
    SharedModule,
    MatButtonModule,
    MatCardModule,
    MatDialogModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatPaginatorModule,
    MatProgressSpinnerModule,
    MatSlideToggleModule,
    MatSortModule,
    MatTableModule,
    MatTooltipModule,
    MatSidenavModule,
    MatTabsModule,
    RouterModule,
    CmsCommonModule,
    MatCheckboxModule,
    MatMenuModule,
    MatSelectModule,
    MatAutocompleteModule,
    MatBadgeModule,
    DragDropModule,
    MatListModule,
  ],
  exports: [
    GridDataComponent,
    CourseMarketListComponent,
    FindOutCourseComponent,
    NoEntityInfoComponent,
  ],
})
export class ListModule {}
