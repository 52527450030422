import { Injectable } from '@angular/core';
import { Edit } from '../edit.types';
import { TranslateService } from '@ngx-translate/core';
import { Observable, map, tap } from 'rxjs';
import { UserJourneyService } from '../../pages/onboarding-steps/user-journey.service';
import { FlashCardStackModel } from '../../../api/models';
import { BooksService, CoursesService } from 'src/app/api/services';
import { Router } from '@angular/router';
import { sortBy } from 'lodash';
import { FlashCardStacksService } from '../../../api/services';

@Injectable({
  providedIn: 'root',
})
export class StackEditConfigService extends Edit.ConfigService<FlashCardStackModel> {
  constructor(
    stackService: FlashCardStacksService,
    translator: TranslateService,
    coursesService: CoursesService,
    booksService: BooksService,
    userJourney: UserJourneyService,
    router: Router
  ) {
    super({
      itemName: translator.instant('common.label.flashCardStack'),
      id: 'stack',
      deleteConfirmText: translator.instant('exam.deleteConfirmText'),
      deleteConfirmTitle: translator.instant('exam.deleteConfirmTitle'),
      fields: [
        {
          key: 'name',
          label: 'Name',
          required: true,
        },
      ],
      handlers: {
        update: (newValue) => {
          return stackService
            .updateFlashCardStack({
              id: newValue.id,
              body: newValue,
            })
            .toPromise();
        },
        create: (newValue) => {
          const courseId = this.config?.params?.courseId;
          const isBook = router.url?.indexOf('book') !== -1;
          let saveRequest$: Observable<FlashCardStackModel>;
          if (!courseId) {
            saveRequest$ = stackService.createFlashCardStack({
              body: newValue,
            });
          } else {
            const query = {
              id: courseId,
              body: [newValue],
              copyCards: false,
            };
            saveRequest$ = (
              isBook
                ? booksService.copyCardStackToBook(query)
                : coursesService.copyCardStackToCourse(query)
            ).pipe(
              map((stacks) => {
                sortBy(stacks, 'id');
                return stacks[stacks.length - 1];
              })
            );
          }
          return saveRequest$
            .pipe(
              tap(() => {
                userJourney.set('createdStack');
              }),
              map((quiz) => {
                return quiz;
              })
            )
            .toPromise();
        },
        delete: (newValue) => {
          return stackService
            .deleteFlashCardStack({ id: newValue.id })
            .toPromise();
        },
      },
    });
  }
}
