import { Pipe, PipeTransform } from '@angular/core';
import { environment } from 'src/environments/environment';

@Pipe({
  name: 'env',
})
export class EnvPipe implements PipeTransform {
  transform(variable: string): any {
    const keys = variable.split('.');
    let recentVal = '';
    keys.map((eachkey, index) => {
      if (index == 0) {
        recentVal = environment[eachkey];
      } else {
        recentVal = recentVal[eachkey];
      }
    });
    return recentVal;
  }
}
