import { read, WorkBook, utils, WorkSheet } from 'xlsx';
type AOA = any[][];

export class XLSXReader {
  static excelToHTML(data) {
    const workbook: WorkBook = read(data, { type: 'array' });
    let targetSheet = null;
    if (workbook.SheetNames.includes('RawReportData Data')) {
      targetSheet = workbook.Sheets['RawReportData Data'];
    } else {
      targetSheet = workbook.Sheets[workbook.SheetNames[0]];
    }
    this.update_sheet_range(targetSheet);
    return utils.sheet_to_html(targetSheet);
  }

  static excelToArray(data) {
    const wb: WorkBook = read(data, { type: 'binary' });
    const wsname: string = wb.SheetNames[0];
    const ws: WorkSheet = wb.Sheets[wsname];
    return <AOA>utils.sheet_to_json(ws, { header: 1 });
  }

  // Source: https://github.com/SheetJS/sheetjs/wiki/General-Utility-Functions#updating-worksheet-range
  private static update_sheet_range(ws: WorkSheet) {
    const range = { s: { r: Infinity, c: Infinity }, e: { r: 0, c: 0 } };
    Object.keys(ws)
      .filter((x) => x.charAt(0) !== '!')
      .map(utils.decode_cell)
      .forEach((x) => {
        range.s.c = Math.min(range.s.c, x.c);
        range.s.r = Math.min(range.s.r, x.r);
        range.e.c = Math.max(range.e.c, x.c);
        range.e.r = Math.max(range.e.r, x.r);
      });
    ws['!ref'] = utils.encode_range(range);
  }
}
