import {
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { CoursesService } from 'src/app/api/services';
import { DateRangePickerService } from '../common/form-field/date-range-picker.service';
import { TranslateService } from '@ngx-translate/core';
import * as dayjs from 'dayjs';
import { ChartComponent } from '../common/ui/charts/chart.component';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'qa-courses-statistics',
  templateUrl: './courses-statistics.component.html',
  styleUrls: ['./courses-statistics.component.scss'],
})
export class CoursesStatisticsComponent implements OnInit, OnDestroy {
  @Input() global = false;
  chartData: any;
  chartOptions: {};
  dateRangeSubscription: Subscription;
  loading = false;
  rows: Array<{ x: string; y: number; id: number }> = [];

  @ViewChild('chart') chartRef: ChartComponent;

  constructor(
    private courses: CoursesService,
    private router: Router,
    private dateRangePickerService: DateRangePickerService,
    elementRef: ElementRef<HTMLElement>,
    private translator: TranslateService
  ) {
    elementRef.nativeElement.classList.add('qa-courses-statistics');
  }

  ngOnInit() {
    this.dateRangeSubscription =
      this.dateRangePickerService.dateRange.subscribe(async (dateRange) => {
        this.loading = true;
        const stats = await this.courses
          .getStatistics({
            global: this.global,
            end: dateRange?.to
              ? dayjs.utc(dateRange.to).format('YYYY-MM-DD') + 'T23:59:59'
              : null,
            start: dateRange?.from
              ? dayjs.utc(dateRange.from).utc().format('YYYY-MM-DD') +
                'T00:00:00'
              : null,
          })
          .toPromise();
        await this.updateChart(stats);
        this.loading = false;
      });
  }

  ngOnDestroy() {
    this.dateRangeSubscription.unsubscribe();
  }

  async updateChart(statistics: any[]) {
    this.rows = statistics.map((row: any) => {
      return { x: row.name, y: row.num_played, id: row.id } as {
        x: string;
        y: number;
        id: number;
      };
    });
    this.rows = this.rows.map((row) => {
      if (row.x.length > 23) {
        row.x = row.x.slice(0, 20) + '...';
      }
      return row;
    });

    this.chartOptions = {
      legend: {
        display: false,
      },
      indexAxis: 'y',
      scales: {
        x: {
          title: {
            display: true,
            text: this.translator.instant('statistics.graph.title'),
          },
          ticks: {
            min: 0,
            beginAtZero: true,
          },
        },
        y: {
          grid: {
            display: false,
            z: -1,
          },
        },
      },
      maintainAspectRatio: false,
    };

    this.chartData = {
      labels: this.rows.map((r) => r.x),
      datasets: [
        {
          label: this.translator.instant('statistics.graph.title'),
          backgroundColor: environment.colors.primary,
          hoverBackgroundColor: environment.colors.primaryLight,
          data: this.rows.map((r) => r.y),
        },
      ],
    };
  }

  chartClicked(event) {
    if (event.active && event.active.length) {
      const dataIndex = event.active[0].index;
      if (this.rows[dataIndex]) {
        this.router.navigate([`/cms/courses/edit`, this.rows[dataIndex].id]);
      }
    }
  }
}
