<mat-form-field appearance>
  <mat-label>
    {{ label }}
  </mat-label>
  <mat-select [formControl]="displayControl" [required]="required">
    <ng-container *ngFor="let optionKey of displayOptionsKeys; let i = index">
      <mat-option [value]="i" *ngIf="displayFn(displayOptions[i])">
        {{ displayFn(displayOptions[i]) }}
      </mat-option>
    </ng-container>
  </mat-select>
  <mat-error *ngFor="let errorName of control.errors | keys">
    {{ errorDisplayFunction(errorName) | translate }}
  </mat-error>
</mat-form-field>
