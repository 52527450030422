<div class="videocontainer">
  <iframe
    [src]="videolink"
    frameborder="0"
    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
    allowfullscreen
  ></iframe>
</div>

<div class="description" [class.empty]="!description">
  <p *ngIf="description">
    {{ description }}
  </p>
  <div class="actions">
    <button
      mat-button
      color="primary"
      *ngIf="!watchLater()"
      (click)="bookmark()"
    >
      <mat-icon>bookmark</mat-icon>
      {{ 'embeddedVideo.saveForLater' | translate }}
    </button>
    <button
      mat-button
      class="saved"
      color="primary"
      *ngIf="watchLater()"
      (click)="unbookmark()"
    >
      <mat-icon>done</mat-icon>
      {{ 'embeddedVideo.savedForLater' | translate }}
    </button>
  </div>
</div>
