/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { BaseQueryResponse } from '../models/base-query-response';
import { CourseAccessRequestModel } from '../models/course-access-request-model';
import { CourseExportList } from '../models/course-export-list';
import { CourseModel } from '../models/course-model';
import { CourseQuery } from '../models/course-query';
import { CourseStatistics } from '../models/course-statistics';
import { Exam } from '../models/exam';
import { FlashCardStackModel } from '../models/flash-card-stack-model';
import { LiveEvent } from '../models/live-event';
import { PositionChangeModel } from '../models/position-change-model';
import { QuestionStats } from '../models/question-stats';
import { QuizModel } from '../models/quiz-model';
import { QuizStatistics } from '../models/quiz-statistics';
import { StackStatistics } from '../models/stack-statistics';
import { UsageStatistics } from '../models/usage-statistics';

@Injectable({
  providedIn: 'root',
})
export class CoursesService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation createCourse
   */
  static readonly CreateCoursePath = '/courses';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createCourse()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createCourse$Response(params: {
    cognitouserid?: string;
    cognitousergroup?: string;
    context?: HttpContext
    body: CourseModel
  }
): Observable<StrictHttpResponse<CourseModel>> {

    const rb = new RequestBuilder(this.rootUrl, CoursesService.CreateCoursePath, 'post');
    if (params) {
      rb.header('cognitouserid', params.cognitouserid, {});
      rb.header('cognitousergroup', params.cognitousergroup, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CourseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `createCourse$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createCourse(params: {
    cognitouserid?: string;
    cognitousergroup?: string;
    context?: HttpContext
    body: CourseModel
  }
): Observable<CourseModel> {

    return this.createCourse$Response(params).pipe(
      map((r: StrictHttpResponse<CourseModel>) => r.body as CourseModel)
    );
  }

  /**
   * Path part for operation queryCourses
   */
  static readonly QueryCoursesPath = '/courses/query';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `queryCourses()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  queryCourses$Response(params: {
    cognitouserid?: string;
    cognitousergroup?: string;
    context?: HttpContext
    body: CourseQuery
  }
): Observable<StrictHttpResponse<BaseQueryResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CoursesService.QueryCoursesPath, 'post');
    if (params) {
      rb.header('cognitouserid', params.cognitouserid, {});
      rb.header('cognitousergroup', params.cognitousergroup, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BaseQueryResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `queryCourses$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  queryCourses(params: {
    cognitouserid?: string;
    cognitousergroup?: string;
    context?: HttpContext
    body: CourseQuery
  }
): Observable<BaseQueryResponse> {

    return this.queryCourses$Response(params).pipe(
      map((r: StrictHttpResponse<BaseQueryResponse>) => r.body as BaseQueryResponse)
    );
  }

  /**
   * Path part for operation exportCourses
   */
  static readonly ExportCoursesPath = '/courses/export';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `exportCourses()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  exportCourses$Response(params: {
    cognitouserid?: string;
    export_exam?: boolean;
    export_events?: boolean;
    cognitousergroup?: string;
    context?: HttpContext
    body: CourseExportList
  }
): Observable<StrictHttpResponse<Array<CourseModel>>> {

    const rb = new RequestBuilder(this.rootUrl, CoursesService.ExportCoursesPath, 'post');
    if (params) {
      rb.header('cognitouserid', params.cognitouserid, {});
      rb.query('export_exam', params.export_exam, {});
      rb.query('export_events', params.export_events, {});
      rb.header('cognitousergroup', params.cognitousergroup, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<CourseModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `exportCourses$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  exportCourses(params: {
    cognitouserid?: string;
    export_exam?: boolean;
    export_events?: boolean;
    cognitousergroup?: string;
    context?: HttpContext
    body: CourseExportList
  }
): Observable<Array<CourseModel>> {

    return this.exportCourses$Response(params).pipe(
      map((r: StrictHttpResponse<Array<CourseModel>>) => r.body as Array<CourseModel>)
    );
  }

  /**
   * Path part for operation updateCourseAccess
   */
  static readonly UpdateCourseAccessPath = '/courses/access_request';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateCourseAccess()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateCourseAccess$Response(params: {
    cognitouserid?: string;
    cognitousergroup?: string;
    context?: HttpContext
    body: CourseAccessRequestModel
  }
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, CoursesService.UpdateCourseAccessPath, 'put');
    if (params) {
      rb.header('cognitouserid', params.cognitouserid, {});
      rb.header('cognitousergroup', params.cognitousergroup, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateCourseAccess$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateCourseAccess(params: {
    cognitouserid?: string;
    cognitousergroup?: string;
    context?: HttpContext
    body: CourseAccessRequestModel
  }
): Observable<void> {

    return this.updateCourseAccess$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation requestCourseAccess
   */
  static readonly RequestCourseAccessPath = '/courses/access_request';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `requestCourseAccess()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  requestCourseAccess$Response(params: {
    cognitouserid?: string;
    cognitousergroup?: string;
    context?: HttpContext
    body: CourseAccessRequestModel
  }
): Observable<StrictHttpResponse<CourseAccessRequestModel>> {

    const rb = new RequestBuilder(this.rootUrl, CoursesService.RequestCourseAccessPath, 'post');
    if (params) {
      rb.header('cognitouserid', params.cognitouserid, {});
      rb.header('cognitousergroup', params.cognitousergroup, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CourseAccessRequestModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `requestCourseAccess$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  requestCourseAccess(params: {
    cognitouserid?: string;
    cognitousergroup?: string;
    context?: HttpContext
    body: CourseAccessRequestModel
  }
): Observable<CourseAccessRequestModel> {

    return this.requestCourseAccess$Response(params).pipe(
      map((r: StrictHttpResponse<CourseAccessRequestModel>) => r.body as CourseAccessRequestModel)
    );
  }

  /**
   * Path part for operation getCourseAccessRequestByCourseId
   */
  static readonly GetCourseAccessRequestByCourseIdPath = '/courses/{id}/access_requests';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCourseAccessRequestByCourseId()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCourseAccessRequestByCourseId$Response(params: {
    id: number;
    cognitouserid?: string;
    cognitousergroup?: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<CourseAccessRequestModel>>> {

    const rb = new RequestBuilder(this.rootUrl, CoursesService.GetCourseAccessRequestByCourseIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.header('cognitouserid', params.cognitouserid, {});
      rb.header('cognitousergroup', params.cognitousergroup, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<CourseAccessRequestModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getCourseAccessRequestByCourseId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCourseAccessRequestByCourseId(params: {
    id: number;
    cognitouserid?: string;
    cognitousergroup?: string;
    context?: HttpContext
  }
): Observable<Array<CourseAccessRequestModel>> {

    return this.getCourseAccessRequestByCourseId$Response(params).pipe(
      map((r: StrictHttpResponse<Array<CourseAccessRequestModel>>) => r.body as Array<CourseAccessRequestModel>)
    );
  }

  /**
   * Path part for operation getStatistics
   */
  static readonly GetStatisticsPath = '/courses/stats';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getStatistics()` instead.
   *
   * This method doesn't expect any request body.
   */
  getStatistics$Response(params?: {
    global?: boolean;
    start?: string;
    end?: string;
    cognitousergroup?: Array<string>;
    cognitouserid?: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<CourseStatistics>>> {

    const rb = new RequestBuilder(this.rootUrl, CoursesService.GetStatisticsPath, 'get');
    if (params) {
      rb.query('global', params.global, {});
      rb.query('start', params.start, {});
      rb.query('end', params.end, {});
      rb.header('cognitousergroup', params.cognitousergroup, {});
      rb.header('cognitouserid', params.cognitouserid, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<CourseStatistics>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getStatistics$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getStatistics(params?: {
    global?: boolean;
    start?: string;
    end?: string;
    cognitousergroup?: Array<string>;
    cognitouserid?: string;
    context?: HttpContext
  }
): Observable<Array<CourseStatistics>> {

    return this.getStatistics$Response(params).pipe(
      map((r: StrictHttpResponse<Array<CourseStatistics>>) => r.body as Array<CourseStatistics>)
    );
  }

  /**
   * Path part for operation importFromMarket
   */
  static readonly ImportFromMarketPath = '/courses/import/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `importFromMarket()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  importFromMarket$Response(params: {
    id: number;
    cognitouserid?: string;
    cognitousergroup?: string;
    context?: HttpContext
    body: CourseModel
  }
): Observable<StrictHttpResponse<CourseModel>> {

    const rb = new RequestBuilder(this.rootUrl, CoursesService.ImportFromMarketPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.header('cognitouserid', params.cognitouserid, {});
      rb.header('cognitousergroup', params.cognitousergroup, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CourseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `importFromMarket$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  importFromMarket(params: {
    id: number;
    cognitouserid?: string;
    cognitousergroup?: string;
    context?: HttpContext
    body: CourseModel
  }
): Observable<CourseModel> {

    return this.importFromMarket$Response(params).pipe(
      map((r: StrictHttpResponse<CourseModel>) => r.body as CourseModel)
    );
  }

  /**
   * Path part for operation getCourseById
   */
  static readonly GetCourseByIdPath = '/courses/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCourseById()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCourseById$Response(params: {
    id: number;
    cognitouserid?: string;
    full: boolean;
    cognitousergroup?: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<CourseModel>> {

    const rb = new RequestBuilder(this.rootUrl, CoursesService.GetCourseByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.header('cognitouserid', params.cognitouserid, {});
      rb.query('full', params.full, {});
      rb.header('cognitousergroup', params.cognitousergroup, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CourseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getCourseById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCourseById(params: {
    id: number;
    cognitouserid?: string;
    full: boolean;
    cognitousergroup?: string;
    context?: HttpContext
  }
): Observable<CourseModel> {

    return this.getCourseById$Response(params).pipe(
      map((r: StrictHttpResponse<CourseModel>) => r.body as CourseModel)
    );
  }

  /**
   * Path part for operation updateCourse
   */
  static readonly UpdateCoursePath = '/courses/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateCourse()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateCourse$Response(params: {
    id: number;
    cognitouserid?: string;
    cognitousergroup?: string;
    context?: HttpContext
    body: CourseModel
  }
): Observable<StrictHttpResponse<CourseModel>> {

    const rb = new RequestBuilder(this.rootUrl, CoursesService.UpdateCoursePath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.header('cognitouserid', params.cognitouserid, {});
      rb.header('cognitousergroup', params.cognitousergroup, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CourseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateCourse$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateCourse(params: {
    id: number;
    cognitouserid?: string;
    cognitousergroup?: string;
    context?: HttpContext
    body: CourseModel
  }
): Observable<CourseModel> {

    return this.updateCourse$Response(params).pipe(
      map((r: StrictHttpResponse<CourseModel>) => r.body as CourseModel)
    );
  }

  /**
   * Path part for operation deleteCourse
   */
  static readonly DeleteCoursePath = '/courses/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteCourse()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteCourse$Response(params: {
    id: number;
    cognitouserid?: string;
    cognitousergroup?: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<CourseModel>> {

    const rb = new RequestBuilder(this.rootUrl, CoursesService.DeleteCoursePath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
      rb.header('cognitouserid', params.cognitouserid, {});
      rb.header('cognitousergroup', params.cognitousergroup, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CourseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteCourse$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteCourse(params: {
    id: number;
    cognitouserid?: string;
    cognitousergroup?: string;
    context?: HttpContext
  }
): Observable<CourseModel> {

    return this.deleteCourse$Response(params).pipe(
      map((r: StrictHttpResponse<CourseModel>) => r.body as CourseModel)
    );
  }

  /**
   * Path part for operation getCourseStatistics
   */
  static readonly GetCourseStatisticsPath = '/courses/{id}/stats';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCourseStatistics()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCourseStatistics$Response(params: {
    id: number;
    start?: string;
    end?: string;
    cognitouserid?: string;
    cognitousergroup?: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<CourseStatistics>> {

    const rb = new RequestBuilder(this.rootUrl, CoursesService.GetCourseStatisticsPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('start', params.start, {});
      rb.query('end', params.end, {});
      rb.header('cognitouserid', params.cognitouserid, {});
      rb.header('cognitousergroup', params.cognitousergroup, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CourseStatistics>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getCourseStatistics$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCourseStatistics(params: {
    id: number;
    start?: string;
    end?: string;
    cognitouserid?: string;
    cognitousergroup?: string;
    context?: HttpContext
  }
): Observable<CourseStatistics> {

    return this.getCourseStatistics$Response(params).pipe(
      map((r: StrictHttpResponse<CourseStatistics>) => r.body as CourseStatistics)
    );
  }

  /**
   * Path part for operation getCourseUsageStatistics
   */
  static readonly GetCourseUsageStatisticsPath = '/courses/{id}/usage';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCourseUsageStatistics()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCourseUsageStatistics$Response(params: {
    id: number;
    start?: string;
    end?: string;
    cognitouserid?: string;
    cognitousergroup?: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<UsageStatistics>>> {

    const rb = new RequestBuilder(this.rootUrl, CoursesService.GetCourseUsageStatisticsPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('start', params.start, {});
      rb.query('end', params.end, {});
      rb.header('cognitouserid', params.cognitouserid, {});
      rb.header('cognitousergroup', params.cognitousergroup, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<UsageStatistics>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getCourseUsageStatistics$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCourseUsageStatistics(params: {
    id: number;
    start?: string;
    end?: string;
    cognitouserid?: string;
    cognitousergroup?: string;
    context?: HttpContext
  }
): Observable<Array<UsageStatistics>> {

    return this.getCourseUsageStatistics$Response(params).pipe(
      map((r: StrictHttpResponse<Array<UsageStatistics>>) => r.body as Array<UsageStatistics>)
    );
  }

  /**
   * Path part for operation getQuizInCourse
   */
  static readonly GetQuizInCoursePath = '/courses/{id}/quizzes';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getQuizInCourse()` instead.
   *
   * This method doesn't expect any request body.
   */
  getQuizInCourse$Response(params: {
    id: number;
    cognitouserid?: string;
    cognitousergroup?: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<QuizModel>>> {

    const rb = new RequestBuilder(this.rootUrl, CoursesService.GetQuizInCoursePath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.header('cognitouserid', params.cognitouserid, {});
      rb.header('cognitousergroup', params.cognitousergroup, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<QuizModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getQuizInCourse$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getQuizInCourse(params: {
    id: number;
    cognitouserid?: string;
    cognitousergroup?: string;
    context?: HttpContext
  }
): Observable<Array<QuizModel>> {

    return this.getQuizInCourse$Response(params).pipe(
      map((r: StrictHttpResponse<Array<QuizModel>>) => r.body as Array<QuizModel>)
    );
  }

  /**
   * Path part for operation linkQuizInCourse
   */
  static readonly LinkQuizInCoursePath = '/courses/{id}/quizzes';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `linkQuizInCourse()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  linkQuizInCourse$Response(params: {
    id: number;
    cognitouserid?: string;
    cognitousergroup?: string;
    context?: HttpContext
    body: Array<QuizModel>
  }
): Observable<StrictHttpResponse<Array<QuizModel>>> {

    const rb = new RequestBuilder(this.rootUrl, CoursesService.LinkQuizInCoursePath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.header('cognitouserid', params.cognitouserid, {});
      rb.header('cognitousergroup', params.cognitousergroup, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<QuizModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `linkQuizInCourse$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  linkQuizInCourse(params: {
    id: number;
    cognitouserid?: string;
    cognitousergroup?: string;
    context?: HttpContext
    body: Array<QuizModel>
  }
): Observable<Array<QuizModel>> {

    return this.linkQuizInCourse$Response(params).pipe(
      map((r: StrictHttpResponse<Array<QuizModel>>) => r.body as Array<QuizModel>)
    );
  }

  /**
   * Path part for operation copyQuizToCourse
   */
  static readonly CopyQuizToCoursePath = '/courses/{id}/quizzes';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `copyQuizToCourse()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  copyQuizToCourse$Response(params: {
    id: number;
    cognitouserid?: string;
    copyQuestions: boolean;
    cognitousergroup?: string;
    context?: HttpContext
    body: Array<QuizModel>
  }
): Observable<StrictHttpResponse<Array<QuizModel>>> {

    const rb = new RequestBuilder(this.rootUrl, CoursesService.CopyQuizToCoursePath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.header('cognitouserid', params.cognitouserid, {});
      rb.query('copyQuestions', params.copyQuestions, {});
      rb.header('cognitousergroup', params.cognitousergroup, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<QuizModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `copyQuizToCourse$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  copyQuizToCourse(params: {
    id: number;
    cognitouserid?: string;
    copyQuestions: boolean;
    cognitousergroup?: string;
    context?: HttpContext
    body: Array<QuizModel>
  }
): Observable<Array<QuizModel>> {

    return this.copyQuizToCourse$Response(params).pipe(
      map((r: StrictHttpResponse<Array<QuizModel>>) => r.body as Array<QuizModel>)
    );
  }

  /**
   * Path part for operation removeQuizFromCourse
   */
  static readonly RemoveQuizFromCoursePath = '/courses/{id}/quizzes';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `removeQuizFromCourse()` instead.
   *
   * This method doesn't expect any request body.
   */
  removeQuizFromCourse$Response(params: {
    id: number;
    quiz_ids?: string;
    cognitouserid?: string;
    cognitousergroup?: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, CoursesService.RemoveQuizFromCoursePath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('quiz_ids', params.quiz_ids, {});
      rb.header('cognitouserid', params.cognitouserid, {});
      rb.header('cognitousergroup', params.cognitousergroup, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `removeQuizFromCourse$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  removeQuizFromCourse(params: {
    id: number;
    quiz_ids?: string;
    cognitouserid?: string;
    cognitousergroup?: string;
    context?: HttpContext
  }
): Observable<void> {

    return this.removeQuizFromCourse$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation repositionQuizInCourse
   */
  static readonly RepositionQuizInCoursePath = '/courses/{id}/quizzes/{quiz_id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `repositionQuizInCourse()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  repositionQuizInCourse$Response(params: {
    id: number;
    quiz_id: number;
    cognitouserid?: string;
    cognitousergroup?: string;
    context?: HttpContext
    body: PositionChangeModel
  }
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, CoursesService.RepositionQuizInCoursePath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('quiz_id', params.quiz_id, {});
      rb.header('cognitouserid', params.cognitouserid, {});
      rb.header('cognitousergroup', params.cognitousergroup, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `repositionQuizInCourse$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  repositionQuizInCourse(params: {
    id: number;
    quiz_id: number;
    cognitouserid?: string;
    cognitousergroup?: string;
    context?: HttpContext
    body: PositionChangeModel
  }
): Observable<void> {

    return this.repositionQuizInCourse$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation getQuizStatisticsInCourse
   */
  static readonly GetQuizStatisticsInCoursePath = '/courses/{id}/quizzes/{quiz_id}/stats';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getQuizStatisticsInCourse()` instead.
   *
   * This method doesn't expect any request body.
   */
  getQuizStatisticsInCourse$Response(params: {
    id: number;
    quiz_id: number;
    start?: string;
    end?: string;
    cognitouserid?: string;
    cognitousergroup?: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<QuizStatistics>> {

    const rb = new RequestBuilder(this.rootUrl, CoursesService.GetQuizStatisticsInCoursePath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('quiz_id', params.quiz_id, {});
      rb.query('start', params.start, {});
      rb.query('end', params.end, {});
      rb.header('cognitouserid', params.cognitouserid, {});
      rb.header('cognitousergroup', params.cognitousergroup, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<QuizStatistics>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getQuizStatisticsInCourse$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getQuizStatisticsInCourse(params: {
    id: number;
    quiz_id: number;
    start?: string;
    end?: string;
    cognitouserid?: string;
    cognitousergroup?: string;
    context?: HttpContext
  }
): Observable<QuizStatistics> {

    return this.getQuizStatisticsInCourse$Response(params).pipe(
      map((r: StrictHttpResponse<QuizStatistics>) => r.body as QuizStatistics)
    );
  }

  /**
   * Path part for operation getQuestionsStatisticsInCourse
   */
  static readonly GetQuestionsStatisticsInCoursePath = '/courses/{id}/quizzes/{quiz_id}/questions/{question_id}/stats';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getQuestionsStatisticsInCourse()` instead.
   *
   * This method doesn't expect any request body.
   */
  getQuestionsStatisticsInCourse$Response(params: {
    id: number;
    quiz_id: number;
    question_id: number;
    start?: string;
    end?: string;
    cognitouserid?: string;
    cognitousergroup?: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<QuestionStats>> {

    const rb = new RequestBuilder(this.rootUrl, CoursesService.GetQuestionsStatisticsInCoursePath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('quiz_id', params.quiz_id, {});
      rb.path('question_id', params.question_id, {});
      rb.query('start', params.start, {});
      rb.query('end', params.end, {});
      rb.header('cognitouserid', params.cognitouserid, {});
      rb.header('cognitousergroup', params.cognitousergroup, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<QuestionStats>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getQuestionsStatisticsInCourse$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getQuestionsStatisticsInCourse(params: {
    id: number;
    quiz_id: number;
    question_id: number;
    start?: string;
    end?: string;
    cognitouserid?: string;
    cognitousergroup?: string;
    context?: HttpContext
  }
): Observable<QuestionStats> {

    return this.getQuestionsStatisticsInCourse$Response(params).pipe(
      map((r: StrictHttpResponse<QuestionStats>) => r.body as QuestionStats)
    );
  }

  /**
   * Path part for operation getUsageStatisticsForQuizInCourse
   */
  static readonly GetUsageStatisticsForQuizInCoursePath = '/courses/{id}/quizzes/{quiz_id}/usage';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getUsageStatisticsForQuizInCourse()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUsageStatisticsForQuizInCourse$Response(params: {
    id: number;
    quiz_id: number;
    start?: string;
    end?: string;
    cognitouserid?: string;
    cognitousergroup?: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<UsageStatistics>>> {

    const rb = new RequestBuilder(this.rootUrl, CoursesService.GetUsageStatisticsForQuizInCoursePath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('quiz_id', params.quiz_id, {});
      rb.query('start', params.start, {});
      rb.query('end', params.end, {});
      rb.header('cognitouserid', params.cognitouserid, {});
      rb.header('cognitousergroup', params.cognitousergroup, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<UsageStatistics>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getUsageStatisticsForQuizInCourse$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUsageStatisticsForQuizInCourse(params: {
    id: number;
    quiz_id: number;
    start?: string;
    end?: string;
    cognitouserid?: string;
    cognitousergroup?: string;
    context?: HttpContext
  }
): Observable<Array<UsageStatistics>> {

    return this.getUsageStatisticsForQuizInCourse$Response(params).pipe(
      map((r: StrictHttpResponse<Array<UsageStatistics>>) => r.body as Array<UsageStatistics>)
    );
  }

  /**
   * Path part for operation getExamsInCourse
   */
  static readonly GetExamsInCoursePath = '/courses/{id}/exams';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getExamsInCourse()` instead.
   *
   * This method doesn't expect any request body.
   */
  getExamsInCourse$Response(params: {
    id: number;
    cognitouserid?: string;
    cognitousergroup?: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<Exam>>> {

    const rb = new RequestBuilder(this.rootUrl, CoursesService.GetExamsInCoursePath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.header('cognitouserid', params.cognitouserid, {});
      rb.header('cognitousergroup', params.cognitousergroup, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Exam>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getExamsInCourse$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getExamsInCourse(params: {
    id: number;
    cognitouserid?: string;
    cognitousergroup?: string;
    context?: HttpContext
  }
): Observable<Array<Exam>> {

    return this.getExamsInCourse$Response(params).pipe(
      map((r: StrictHttpResponse<Array<Exam>>) => r.body as Array<Exam>)
    );
  }

  /**
   * Path part for operation addExamToCourse
   */
  static readonly AddExamToCoursePath = '/courses/{id}/exams';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addExamToCourse()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addExamToCourse$Response(params: {
    id: number;
    cognitouserid?: string;
    cognitousergroup?: string;
    context?: HttpContext
    body: Exam
  }
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, CoursesService.AddExamToCoursePath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.header('cognitouserid', params.cognitouserid, {});
      rb.header('cognitousergroup', params.cognitousergroup, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `addExamToCourse$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addExamToCourse(params: {
    id: number;
    cognitouserid?: string;
    cognitousergroup?: string;
    context?: HttpContext
    body: Exam
  }
): Observable<void> {

    return this.addExamToCourse$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation createExamInCourse
   */
  static readonly CreateExamInCoursePath = '/courses/{id}/exams';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createExamInCourse()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createExamInCourse$Response(params: {
    id: number;
    cognitouserid?: string;
    cognitousergroup?: string;
    context?: HttpContext
    body: Exam
  }
): Observable<StrictHttpResponse<Exam>> {

    const rb = new RequestBuilder(this.rootUrl, CoursesService.CreateExamInCoursePath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.header('cognitouserid', params.cognitouserid, {});
      rb.header('cognitousergroup', params.cognitousergroup, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Exam>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `createExamInCourse$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createExamInCourse(params: {
    id: number;
    cognitouserid?: string;
    cognitousergroup?: string;
    context?: HttpContext
    body: Exam
  }
): Observable<Exam> {

    return this.createExamInCourse$Response(params).pipe(
      map((r: StrictHttpResponse<Exam>) => r.body as Exam)
    );
  }

  /**
   * Path part for operation removeExamFromCourse
   */
  static readonly RemoveExamFromCoursePath = '/courses/{id}/exams/{exam_id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `removeExamFromCourse()` instead.
   *
   * This method doesn't expect any request body.
   */
  removeExamFromCourse$Response(params: {
    id: number;
    exam_id: number;
    cognitouserid?: string;
    cognitousergroup?: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, CoursesService.RemoveExamFromCoursePath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('exam_id', params.exam_id, {});
      rb.header('cognitouserid', params.cognitouserid, {});
      rb.header('cognitousergroup', params.cognitousergroup, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `removeExamFromCourse$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  removeExamFromCourse(params: {
    id: number;
    exam_id: number;
    cognitouserid?: string;
    cognitousergroup?: string;
    context?: HttpContext
  }
): Observable<void> {

    return this.removeExamFromCourse$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation getEventsInCourse
   */
  static readonly GetEventsInCoursePath = '/courses/{id}/live_events';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getEventsInCourse()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEventsInCourse$Response(params: {
    id: number;
    cognitouserid?: string;
    cognitousergroup?: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<LiveEvent>>> {

    const rb = new RequestBuilder(this.rootUrl, CoursesService.GetEventsInCoursePath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.header('cognitouserid', params.cognitouserid, {});
      rb.header('cognitousergroup', params.cognitousergroup, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<LiveEvent>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getEventsInCourse$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEventsInCourse(params: {
    id: number;
    cognitouserid?: string;
    cognitousergroup?: string;
    context?: HttpContext
  }
): Observable<Array<LiveEvent>> {

    return this.getEventsInCourse$Response(params).pipe(
      map((r: StrictHttpResponse<Array<LiveEvent>>) => r.body as Array<LiveEvent>)
    );
  }

  /**
   * Path part for operation addEventToCourse
   */
  static readonly AddEventToCoursePath = '/courses/{id}/live_events';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addEventToCourse()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addEventToCourse$Response(params: {
    id: number;
    cognitouserid?: string;
    cognitousergroup?: string;
    context?: HttpContext
    body: LiveEvent
  }
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, CoursesService.AddEventToCoursePath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.header('cognitouserid', params.cognitouserid, {});
      rb.header('cognitousergroup', params.cognitousergroup, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `addEventToCourse$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addEventToCourse(params: {
    id: number;
    cognitouserid?: string;
    cognitousergroup?: string;
    context?: HttpContext
    body: LiveEvent
  }
): Observable<void> {

    return this.addEventToCourse$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation removeEventFromCourse
   */
  static readonly RemoveEventFromCoursePath = '/courses/{id}/live_events/{event_id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `removeEventFromCourse()` instead.
   *
   * This method doesn't expect any request body.
   */
  removeEventFromCourse$Response(params: {
    id: number;
    event_id: number;
    cognitouserid?: string;
    cognitousergroup?: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, CoursesService.RemoveEventFromCoursePath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('event_id', params.event_id, {});
      rb.header('cognitouserid', params.cognitouserid, {});
      rb.header('cognitousergroup', params.cognitousergroup, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `removeEventFromCourse$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  removeEventFromCourse(params: {
    id: number;
    event_id: number;
    cognitouserid?: string;
    cognitousergroup?: string;
    context?: HttpContext
  }
): Observable<void> {

    return this.removeEventFromCourse$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation getCardStacksInCourse
   */
  static readonly GetCardStacksInCoursePath = '/courses/{id}/flash_card_stacks';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCardStacksInCourse()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCardStacksInCourse$Response(params: {
    id: number;
    cognitouserid?: string;
    cognitousergroup?: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<FlashCardStackModel>>> {

    const rb = new RequestBuilder(this.rootUrl, CoursesService.GetCardStacksInCoursePath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.header('cognitouserid', params.cognitouserid, {});
      rb.header('cognitousergroup', params.cognitousergroup, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<FlashCardStackModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getCardStacksInCourse$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCardStacksInCourse(params: {
    id: number;
    cognitouserid?: string;
    cognitousergroup?: string;
    context?: HttpContext
  }
): Observable<Array<FlashCardStackModel>> {

    return this.getCardStacksInCourse$Response(params).pipe(
      map((r: StrictHttpResponse<Array<FlashCardStackModel>>) => r.body as Array<FlashCardStackModel>)
    );
  }

  /**
   * Path part for operation linkCardStackToCourse
   */
  static readonly LinkCardStackToCoursePath = '/courses/{id}/flash_card_stacks';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `linkCardStackToCourse()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  linkCardStackToCourse$Response(params: {
    id: number;
    cognitouserid?: string;
    cognitousergroup?: string;
    context?: HttpContext
    body: Array<FlashCardStackModel>
  }
): Observable<StrictHttpResponse<Array<FlashCardStackModel>>> {

    const rb = new RequestBuilder(this.rootUrl, CoursesService.LinkCardStackToCoursePath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.header('cognitouserid', params.cognitouserid, {});
      rb.header('cognitousergroup', params.cognitousergroup, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<FlashCardStackModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `linkCardStackToCourse$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  linkCardStackToCourse(params: {
    id: number;
    cognitouserid?: string;
    cognitousergroup?: string;
    context?: HttpContext
    body: Array<FlashCardStackModel>
  }
): Observable<Array<FlashCardStackModel>> {

    return this.linkCardStackToCourse$Response(params).pipe(
      map((r: StrictHttpResponse<Array<FlashCardStackModel>>) => r.body as Array<FlashCardStackModel>)
    );
  }

  /**
   * Path part for operation copyCardStackToCourse
   */
  static readonly CopyCardStackToCoursePath = '/courses/{id}/flash_card_stacks';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `copyCardStackToCourse()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  copyCardStackToCourse$Response(params: {
    id: number;
    cognitouserid?: string;
    copyCards: boolean;
    cognitousergroup?: string;
    context?: HttpContext
    body: Array<FlashCardStackModel>
  }
): Observable<StrictHttpResponse<Array<FlashCardStackModel>>> {

    const rb = new RequestBuilder(this.rootUrl, CoursesService.CopyCardStackToCoursePath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.header('cognitouserid', params.cognitouserid, {});
      rb.query('copyCards', params.copyCards, {});
      rb.header('cognitousergroup', params.cognitousergroup, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<FlashCardStackModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `copyCardStackToCourse$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  copyCardStackToCourse(params: {
    id: number;
    cognitouserid?: string;
    copyCards: boolean;
    cognitousergroup?: string;
    context?: HttpContext
    body: Array<FlashCardStackModel>
  }
): Observable<Array<FlashCardStackModel>> {

    return this.copyCardStackToCourse$Response(params).pipe(
      map((r: StrictHttpResponse<Array<FlashCardStackModel>>) => r.body as Array<FlashCardStackModel>)
    );
  }

  /**
   * Path part for operation removeCardStackFromCourse
   */
  static readonly RemoveCardStackFromCoursePath = '/courses/{id}/flash_card_stacks';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `removeCardStackFromCourse()` instead.
   *
   * This method doesn't expect any request body.
   */
  removeCardStackFromCourse$Response(params: {
    id: number;
    stack_ids?: string;
    cognitouserid?: string;
    cognitousergroup?: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, CoursesService.RemoveCardStackFromCoursePath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('stack_ids', params.stack_ids, {});
      rb.header('cognitouserid', params.cognitouserid, {});
      rb.header('cognitousergroup', params.cognitousergroup, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `removeCardStackFromCourse$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  removeCardStackFromCourse(params: {
    id: number;
    stack_ids?: string;
    cognitouserid?: string;
    cognitousergroup?: string;
    context?: HttpContext
  }
): Observable<void> {

    return this.removeCardStackFromCourse$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation repositionCardStackInCourse
   */
  static readonly RepositionCardStackInCoursePath = '/courses/{id}/flash_card_stacks/{stack_id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `repositionCardStackInCourse()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  repositionCardStackInCourse$Response(params: {
    id: number;
    stack_id: number;
    cognitouserid?: string;
    cognitousergroup?: string;
    context?: HttpContext
    body: PositionChangeModel
  }
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, CoursesService.RepositionCardStackInCoursePath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('stack_id', params.stack_id, {});
      rb.header('cognitouserid', params.cognitouserid, {});
      rb.header('cognitousergroup', params.cognitousergroup, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `repositionCardStackInCourse$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  repositionCardStackInCourse(params: {
    id: number;
    stack_id: number;
    cognitouserid?: string;
    cognitousergroup?: string;
    context?: HttpContext
    body: PositionChangeModel
  }
): Observable<void> {

    return this.repositionCardStackInCourse$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation getStackStatisticsInCourse
   */
  static readonly GetStackStatisticsInCoursePath = '/courses/{id}/flash_card_stacks/{stack_id}/stats';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getStackStatisticsInCourse()` instead.
   *
   * This method doesn't expect any request body.
   */
  getStackStatisticsInCourse$Response(params: {
    id: number;
    stack_id: number;
    start?: string;
    end?: string;
    cognitouserid?: string;
    cognitousergroup?: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<StackStatistics>> {

    const rb = new RequestBuilder(this.rootUrl, CoursesService.GetStackStatisticsInCoursePath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('stack_id', params.stack_id, {});
      rb.query('start', params.start, {});
      rb.query('end', params.end, {});
      rb.header('cognitouserid', params.cognitouserid, {});
      rb.header('cognitousergroup', params.cognitousergroup, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StackStatistics>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getStackStatisticsInCourse$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getStackStatisticsInCourse(params: {
    id: number;
    stack_id: number;
    start?: string;
    end?: string;
    cognitouserid?: string;
    cognitousergroup?: string;
    context?: HttpContext
  }
): Observable<StackStatistics> {

    return this.getStackStatisticsInCourse$Response(params).pipe(
      map((r: StrictHttpResponse<StackStatistics>) => r.body as StackStatistics)
    );
  }

}
