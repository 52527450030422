import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UntypedFormControl, Validators } from '@angular/forms';
import {
  AiService,
  FlashCardStacksService,
  FlashCardsService,
} from 'src/app/api/services';
import { PanelService } from 'src/app/cms/panel/panel.service';
import { Router } from '@angular/router';
import {
  AiFlashCardProposal,
  AiFlashCardQuery,
  FlashCardModel,
} from 'src/app/api/models';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { PanelEntityType } from 'src/app/cms/panel/panel.types';

@Component({
  selector: 'qa-import-ai-flashcard',
  templateUrl: './import-ai-flashcard.component.html',
  styleUrls: ['./import-ai-flashcard.component.scss'],
})
export class ImportAiFlashcardComponent implements OnInit {
  showInfo = true;
  showGeneratedStack = false;
  generatedStackResult: AiFlashCardProposal;
  keywords = new UntypedFormControl(null);
  loading = false;
  course: any;
  stack: any;
  creatingCard = false;

  constructor(
    public dialogRef: MatDialogRef<ImportAiFlashcardComponent>,
    private matSnackBar: MatSnackBar,
    private router: Router,
    private aiService: AiService,
    private flashCards: FlashCardsService,
    private flashCardStacks: FlashCardStacksService,
    private panels: PanelService,
    private translator: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
    const routeParts = this.router.url.split('/');
    const idPart = routeParts[routeParts.length - 1];
    const idParts = idPart.split(';');
    if (routeParts && idPart && idParts.length > 1) {
      const cId = parseInt(idParts[1].split('=')[1]);
      const sId = parseInt(idParts[2].split('=')[1]);
      const cPanel = this.panels.panels.find(
        (p) => p.type === PanelEntityType.Course
      );
      const course = cPanel.list.find((c) => c.id === cId);
      if (course) {
        this.course = course;
      }
      const sPanel = this.panels.panels.find(
        (p) => p.type === PanelEntityType.FlashCardStack
      );
      const stack = sPanel.list.find((c) => c.id === sId);
      if (stack) {
        this.stack = stack;
      }
    } else {
      this.keywords.setValidators(Validators.required);
      this.keywords.updateValueAndValidity();
    }
  }

  generateStackAICard() {
    this.loading = true;
    this.showInfo = false;
    this.showGeneratedStack = true;
    this.generateCard();
  }

  generateCard() {
    const obj: AiFlashCardQuery = {
      category: this.course?.category?.name || null,
      keywords: '',
    };
    if (this.keywords.value) {
      const keywords = this.keywords.value.map((v) => v.name).join(', ');
      obj.keywords = keywords;
    }
    this.aiService
      .generateFlashCard({
        body: obj,
      })
      .subscribe((data) => {
        this.generatedStackResult = data[0];
        this.loading = false;
      });
  }

  importAICard() {
    this.creatingCard = true;
    let request$;
    if (this.course) {
      request$ = this.flashCardStacks.copyCardsToFlashCardStack({
        id: this.stack.id,
        body: [this.buildRequest()],
      });
    } else {
      request$ = this.flashCards.createFlashCard({ body: this.buildRequest() });
    }
    request$.subscribe(() => {
      this.creatingCard = false;
      this.dialogRef.close({ refresh: true });
      this.matSnackBar.open(
        this.translator.instant('StackAIQuestion.successToast')
      );
    });
  }

  generateAICardAgain() {
    this.loading = true;
    this.generateCard();
  }

  saveCardAndNext() {
    this.creatingCard = true;
    let request$;
    if (this.course) {
      request$ = this.flashCardStacks.copyCardsToFlashCardStack({
        id: this.stack.id,
        body: [this.buildRequest()],
      });
    } else {
      request$ = this.flashCards.createFlashCard({ body: this.buildRequest() });
    }
    request$.subscribe(() => {
      this.creatingCard = false;
      this.showInfo = true;
      this.showGeneratedStack = false;
      this.matSnackBar.open(
        this.translator.instant('StackAIQuestion.successToast')
      );
    });
  }

  buildRequest(): FlashCardModel {
    const card = this.generatedStackResult;
    return {
      text: card.question,
      explanation: card.explanation,
      answer: card.answer,
    };
  }

  retry() {
    this.showInfo = true;
    this.showGeneratedStack = false;
  }
}
