<mat-form-field class="date-picker" appearance="outline">
  <mat-label>{{ label | translate }}</mat-label>
  <input
    type="text"
    matInput
    ngxDaterangepickerMd
    [linkedCalendars]="options.linkedCalendars"
    [singleDatePicker]="options.singleDatePicker"
    [locale]="locale"
    [showDropdowns]="true"
    startKey="start"
    [disabled]="disabled"
    endKey="end"
    [(ngModel)]="selected"
    (click)="click()"
    [minDate]="minDate"
    [maxDate]="maxDate"
    [showWeekNumbers]="options.showWeekNumbers"
    [showCancel]="options.showCancel"
    [showClearButton]="options.showClearButton"
    [showISOWeekNumbers]="options.showISOWeekNumbers"
    [customRangeDirection]="options.customRangeDirection"
    [lockStartDate]="options.lockStartDate"
    [closeOnAutoApply]="options.closeOnAutoApply"
    firstMonthDayClass="first-day"
    lastMonthDayClass="last-day"
    emptyWeekRowClass="empty-week-row"
    emptyWeekColumnClass="empty-week-column"
    lastDayOfPreviousMonthClass="last-previous-day"
    firstDayOfNextMonthClass="first-next-day"
    [opens]="opens"
    [drops]="drops"
    [timePicker]="timePicker"
    [timePicker24Hour]="true"
    [timePickerSeconds]="true"
    [dateLimit]="dateLimit"
    name="daterange"
    (datesUpdated)="eventClicked($event)"
    (clearClicked)="eventCleared()"
  />
  <mat-datepicker-toggle
    (click)="$event.stopPropagation(); openDatepicker()"
    matSuffix
    class="ngx-daterangepicker-action"
  >
  </mat-datepicker-toggle>
</mat-form-field>
