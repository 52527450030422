import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '../../../../auth/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'qa-subscription-indicator',
  templateUrl: './subscription-indicator.component.html',
  styleUrl: './subscription-indicator.component.scss',
})
export class SubscriptionIndicatorComponent implements OnInit {
  @Input() hideIfSubscribed: boolean = false;
  userDetails: any;
  subscriptionPlan: string | undefined = undefined;

  constructor(
    private translator: TranslateService,
    public router: Router,
    public auth: AuthService
  ) {
    this.userDetails = auth.currentUserInfo$.value;
  }

  ngOnInit(): void {
    // check cognito subscription status
    if (
      this.userDetails?.name &&
      this.userDetails.name.toUpperCase().includes('ACTIVE')
    ) {
      if (this.hideIfSubscribed) {
        this.subscriptionPlan = undefined;
      } else {
        this.subscriptionPlan = this.translator.instant(
          'profile.subscription.label.premium'
        );
      }
    } else if (this.userDetails?.name == 'PAID BY ORGANIZATION') {
      if (this.hideIfSubscribed) {
        this.subscriptionPlan = undefined;
      } else {
        this.subscriptionPlan = this.translator.instant(
          'profile.subscription.label.multilicense'
        );
      }
    } else {
      this.subscriptionPlan = this.translator.instant(
        'profile.subscription.label.basis'
      );
    }
  }
}
