/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { PositionChangeModel } from '../models/position-change-model';
import { QuestionModel } from '../models/question-model';
import { QuizModel } from '../models/quiz-model';
import { QuizQuery } from '../models/quiz-query';
import { QuizQueryResponse } from '../models/quiz-query-response';
import { QuizStatistics } from '../models/quiz-statistics';

@Injectable({
  providedIn: 'root',
})
export class QuizzesService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation createQuiz
   */
  static readonly CreateQuizPath = '/quizzes';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createQuiz()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createQuiz$Response(params: {
    cognitouserid?: string;
    cognitousergroup?: string;
    context?: HttpContext
    body: QuizModel
  }
): Observable<StrictHttpResponse<QuizModel>> {

    const rb = new RequestBuilder(this.rootUrl, QuizzesService.CreateQuizPath, 'post');
    if (params) {
      rb.header('cognitouserid', params.cognitouserid, {});
      rb.header('cognitousergroup', params.cognitousergroup, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<QuizModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `createQuiz$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createQuiz(params: {
    cognitouserid?: string;
    cognitousergroup?: string;
    context?: HttpContext
    body: QuizModel
  }
): Observable<QuizModel> {

    return this.createQuiz$Response(params).pipe(
      map((r: StrictHttpResponse<QuizModel>) => r.body as QuizModel)
    );
  }

  /**
   * Path part for operation queryQuiz
   */
  static readonly QueryQuizPath = '/quizzes/query';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `queryQuiz()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  queryQuiz$Response(params: {
    cognitouserid?: string;
    cognitousergroup?: string;
    context?: HttpContext
    body: QuizQuery
  }
): Observable<StrictHttpResponse<QuizQueryResponse>> {

    const rb = new RequestBuilder(this.rootUrl, QuizzesService.QueryQuizPath, 'post');
    if (params) {
      rb.header('cognitouserid', params.cognitouserid, {});
      rb.header('cognitousergroup', params.cognitousergroup, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<QuizQueryResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `queryQuiz$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  queryQuiz(params: {
    cognitouserid?: string;
    cognitousergroup?: string;
    context?: HttpContext
    body: QuizQuery
  }
): Observable<QuizQueryResponse> {

    return this.queryQuiz$Response(params).pipe(
      map((r: StrictHttpResponse<QuizQueryResponse>) => r.body as QuizQueryResponse)
    );
  }

  /**
   * Path part for operation getQuizById
   */
  static readonly GetQuizByIdPath = '/quizzes/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getQuizById()` instead.
   *
   * This method doesn't expect any request body.
   */
  getQuizById$Response(params: {
    cognitouserid?: string;
    cognitousergroup?: string;
    id: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<QuizModel>> {

    const rb = new RequestBuilder(this.rootUrl, QuizzesService.GetQuizByIdPath, 'get');
    if (params) {
      rb.header('cognitouserid', params.cognitouserid, {});
      rb.header('cognitousergroup', params.cognitousergroup, {});
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<QuizModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getQuizById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getQuizById(params: {
    cognitouserid?: string;
    cognitousergroup?: string;
    id: number;
    context?: HttpContext
  }
): Observable<QuizModel> {

    return this.getQuizById$Response(params).pipe(
      map((r: StrictHttpResponse<QuizModel>) => r.body as QuizModel)
    );
  }

  /**
   * Path part for operation updateQuiz
   */
  static readonly UpdateQuizPath = '/quizzes/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateQuiz()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateQuiz$Response(params: {
    cognitouserid?: string;
    cognitousergroup?: string;
    id: number;
    context?: HttpContext
    body: QuizModel
  }
): Observable<StrictHttpResponse<QuizModel>> {

    const rb = new RequestBuilder(this.rootUrl, QuizzesService.UpdateQuizPath, 'put');
    if (params) {
      rb.header('cognitouserid', params.cognitouserid, {});
      rb.header('cognitousergroup', params.cognitousergroup, {});
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<QuizModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateQuiz$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateQuiz(params: {
    cognitouserid?: string;
    cognitousergroup?: string;
    id: number;
    context?: HttpContext
    body: QuizModel
  }
): Observable<QuizModel> {

    return this.updateQuiz$Response(params).pipe(
      map((r: StrictHttpResponse<QuizModel>) => r.body as QuizModel)
    );
  }

  /**
   * Path part for operation deleteQuiz
   */
  static readonly DeleteQuizPath = '/quizzes/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteQuiz()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteQuiz$Response(params: {
    cognitouserid?: string;
    cognitousergroup?: string;
    id: number;
    delete_questions?: boolean;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<QuizModel>> {

    const rb = new RequestBuilder(this.rootUrl, QuizzesService.DeleteQuizPath, 'delete');
    if (params) {
      rb.header('cognitouserid', params.cognitouserid, {});
      rb.header('cognitousergroup', params.cognitousergroup, {});
      rb.path('id', params.id, {});
      rb.query('delete_questions', params.delete_questions, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<QuizModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteQuiz$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteQuiz(params: {
    cognitouserid?: string;
    cognitousergroup?: string;
    id: number;
    delete_questions?: boolean;
    context?: HttpContext
  }
): Observable<QuizModel> {

    return this.deleteQuiz$Response(params).pipe(
      map((r: StrictHttpResponse<QuizModel>) => r.body as QuizModel)
    );
  }

  /**
   * Path part for operation getQuizStatistics
   */
  static readonly GetQuizStatisticsPath = '/quizzes/{id}/stats';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getQuizStatistics()` instead.
   *
   * This method doesn't expect any request body.
   */
  getQuizStatistics$Response(params: {
    cognitouserid?: string;
    cognitousergroup?: string;
    id: number;
    start?: string;
    end?: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<QuizStatistics>> {

    const rb = new RequestBuilder(this.rootUrl, QuizzesService.GetQuizStatisticsPath, 'get');
    if (params) {
      rb.header('cognitouserid', params.cognitouserid, {});
      rb.header('cognitousergroup', params.cognitousergroup, {});
      rb.path('id', params.id, {});
      rb.query('start', params.start, {});
      rb.query('end', params.end, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<QuizStatistics>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getQuizStatistics$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getQuizStatistics(params: {
    cognitouserid?: string;
    cognitousergroup?: string;
    id: number;
    start?: string;
    end?: string;
    context?: HttpContext
  }
): Observable<QuizStatistics> {

    return this.getQuizStatistics$Response(params).pipe(
      map((r: StrictHttpResponse<QuizStatistics>) => r.body as QuizStatistics)
    );
  }

  /**
   * Path part for operation getQuestionsForQuiz
   */
  static readonly GetQuestionsForQuizPath = '/quizzes/{id}/questions';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getQuestionsForQuiz()` instead.
   *
   * This method doesn't expect any request body.
   */
  getQuestionsForQuiz$Response(params: {
    cognitouserid?: string;
    cognitousergroup?: string;
    id: number;
    embed_answers?: boolean;
    embed_tags?: boolean;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<QuestionModel>>> {

    const rb = new RequestBuilder(this.rootUrl, QuizzesService.GetQuestionsForQuizPath, 'get');
    if (params) {
      rb.header('cognitouserid', params.cognitouserid, {});
      rb.header('cognitousergroup', params.cognitousergroup, {});
      rb.path('id', params.id, {});
      rb.query('embed_answers', params.embed_answers, {});
      rb.query('embed_tags', params.embed_tags, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<QuestionModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getQuestionsForQuiz$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getQuestionsForQuiz(params: {
    cognitouserid?: string;
    cognitousergroup?: string;
    id: number;
    embed_answers?: boolean;
    embed_tags?: boolean;
    context?: HttpContext
  }
): Observable<Array<QuestionModel>> {

    return this.getQuestionsForQuiz$Response(params).pipe(
      map((r: StrictHttpResponse<Array<QuestionModel>>) => r.body as Array<QuestionModel>)
    );
  }

  /**
   * Path part for operation linkQuestionToQuiz
   */
  static readonly LinkQuestionToQuizPath = '/quizzes/{id}/questions';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `linkQuestionToQuiz()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  linkQuestionToQuiz$Response(params: {
    cognitouserid?: string;
    cognitousergroup?: string;
    id: number;
    context?: HttpContext
    body: Array<QuestionModel>
  }
): Observable<StrictHttpResponse<Array<QuestionModel>>> {

    const rb = new RequestBuilder(this.rootUrl, QuizzesService.LinkQuestionToQuizPath, 'put');
    if (params) {
      rb.header('cognitouserid', params.cognitouserid, {});
      rb.header('cognitousergroup', params.cognitousergroup, {});
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<QuestionModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `linkQuestionToQuiz$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  linkQuestionToQuiz(params: {
    cognitouserid?: string;
    cognitousergroup?: string;
    id: number;
    context?: HttpContext
    body: Array<QuestionModel>
  }
): Observable<Array<QuestionModel>> {

    return this.linkQuestionToQuiz$Response(params).pipe(
      map((r: StrictHttpResponse<Array<QuestionModel>>) => r.body as Array<QuestionModel>)
    );
  }

  /**
   * Path part for operation copyQuestionToQuiz
   */
  static readonly CopyQuestionToQuizPath = '/quizzes/{id}/questions';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `copyQuestionToQuiz()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  copyQuestionToQuiz$Response(params: {
    cognitouserid?: string;
    cognitousergroup?: string;
    id: number;
    context?: HttpContext
    body: Array<QuestionModel>
  }
): Observable<StrictHttpResponse<Array<QuestionModel>>> {

    const rb = new RequestBuilder(this.rootUrl, QuizzesService.CopyQuestionToQuizPath, 'post');
    if (params) {
      rb.header('cognitouserid', params.cognitouserid, {});
      rb.header('cognitousergroup', params.cognitousergroup, {});
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<QuestionModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `copyQuestionToQuiz$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  copyQuestionToQuiz(params: {
    cognitouserid?: string;
    cognitousergroup?: string;
    id: number;
    context?: HttpContext
    body: Array<QuestionModel>
  }
): Observable<Array<QuestionModel>> {

    return this.copyQuestionToQuiz$Response(params).pipe(
      map((r: StrictHttpResponse<Array<QuestionModel>>) => r.body as Array<QuestionModel>)
    );
  }

  /**
   * Path part for operation removeQuestionFromQuiz
   */
  static readonly RemoveQuestionFromQuizPath = '/quizzes/{id}/questions';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `removeQuestionFromQuiz()` instead.
   *
   * This method doesn't expect any request body.
   */
  removeQuestionFromQuiz$Response(params: {
    cognitouserid?: string;
    cognitousergroup?: string;
    id: number;
    question_ids?: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, QuizzesService.RemoveQuestionFromQuizPath, 'delete');
    if (params) {
      rb.header('cognitouserid', params.cognitouserid, {});
      rb.header('cognitousergroup', params.cognitousergroup, {});
      rb.path('id', params.id, {});
      rb.query('question_ids', params.question_ids, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `removeQuestionFromQuiz$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  removeQuestionFromQuiz(params: {
    cognitouserid?: string;
    cognitousergroup?: string;
    id: number;
    question_ids?: string;
    context?: HttpContext
  }
): Observable<void> {

    return this.removeQuestionFromQuiz$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation getQuestionInQuiz
   */
  static readonly GetQuestionInQuizPath = '/quizzes/{id}/questions/{question_id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getQuestionInQuiz()` instead.
   *
   * This method doesn't expect any request body.
   */
  getQuestionInQuiz$Response(params: {
    cognitouserid?: string;
    cognitousergroup?: string;
    id: number;
    question_id: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<QuestionModel>> {

    const rb = new RequestBuilder(this.rootUrl, QuizzesService.GetQuestionInQuizPath, 'get');
    if (params) {
      rb.header('cognitouserid', params.cognitouserid, {});
      rb.header('cognitousergroup', params.cognitousergroup, {});
      rb.path('id', params.id, {});
      rb.path('question_id', params.question_id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<QuestionModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getQuestionInQuiz$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getQuestionInQuiz(params: {
    cognitouserid?: string;
    cognitousergroup?: string;
    id: number;
    question_id: number;
    context?: HttpContext
  }
): Observable<QuestionModel> {

    return this.getQuestionInQuiz$Response(params).pipe(
      map((r: StrictHttpResponse<QuestionModel>) => r.body as QuestionModel)
    );
  }

  /**
   * Path part for operation repositionQuestionInQuiz
   */
  static readonly RepositionQuestionInQuizPath = '/quizzes/{id}/questions/{question_id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `repositionQuestionInQuiz()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  repositionQuestionInQuiz$Response(params: {
    cognitouserid?: string;
    cognitousergroup?: string;
    id: number;
    question_id: number;
    context?: HttpContext
    body: PositionChangeModel
  }
): Observable<StrictHttpResponse<QuestionModel>> {

    const rb = new RequestBuilder(this.rootUrl, QuizzesService.RepositionQuestionInQuizPath, 'patch');
    if (params) {
      rb.header('cognitouserid', params.cognitouserid, {});
      rb.header('cognitousergroup', params.cognitousergroup, {});
      rb.path('id', params.id, {});
      rb.path('question_id', params.question_id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<QuestionModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `repositionQuestionInQuiz$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  repositionQuestionInQuiz(params: {
    cognitouserid?: string;
    cognitousergroup?: string;
    id: number;
    question_id: number;
    context?: HttpContext
    body: PositionChangeModel
  }
): Observable<QuestionModel> {

    return this.repositionQuestionInQuiz$Response(params).pipe(
      map((r: StrictHttpResponse<QuestionModel>) => r.body as QuestionModel)
    );
  }

}
